import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Notification } from 'src/app/core/models/notification.model';

@Component({
  selector: 'app-notification-row-item',
  templateUrl: './notification-row-item.component.html',
  styleUrls: ['./notification-row-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationRowItemComponent implements OnInit {
  @Input() notification = {} as Notification;
  @Input() slimStyle = false;
  @Input() textSizeClass = 'font-sm';
  @Output() removeNotification = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
  }

  getNotifIcon(type: string) {
    return '/assets/icons/notifications/' + type + '.svg';
  }

  onNotificationIconError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = '/assets/icons/notifications/notif-check.svg';
  }

  onRemoveNotification(notificationId: number) {
    this.removeNotification.emit(notificationId);
  }
}
