import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class LoggedInGuard  {
  constructor(private authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    this.authService.saveLeadDynoAffiliateIdFromRoute(next);
    // Assuming you have an AuthService with getIsAuth() method
    const isAuth = this.authService.getIsAuth();
    if (isAuth) {
      this.router.navigate(['/dashboard']);
      this.authService.setAuthStatusListener(true);
      return false; // Returning false to prevent navigation to the original requested route
    } else {
      this.authService.setAuthStatusListener(false);
    }
    return true;
  }
}
