import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
type ColorType = 'default' | 'primary' | 'trial' | 'trial-completed' | 'disabled';
@Component({
	selector: 'g-button',
	templateUrl: './g-button.component.html',
	styleUrls: ['./g-button.component.scss']
})
export class GButtonComponent implements OnInit {
  	public buttonText = '';

	@Input()
	set text(name: string) {
		this.buttonText = name;
	}
	get name(): string {
		return this.buttonText;
	}

	@Input() color: ColorType = 'default';
	@Input() type: string = 'button';
	@Output() btnClick = new EventEmitter();
	@Input() isDisabled = false;
	@Input() class = '';

	get buttonClass() {
		return `btn ${this.class} ${this.color}-gradient-lesson-bg`;
	}


	constructor() {}

	onClick() {
		this.btnClick.emit();
	}
  
  ngOnInit(): void {}
}