import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general.service';

@Component({
  selector: 'app-text-call-to-action-button',
  templateUrl: './text-call-to-action-button.component.html',
  styleUrls: ['./text-call-to-action-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextCallToActionButtonComponent implements OnInit {

  constructor(public generalService: GeneralService) { }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  
  ngOnInit(): void {
  }

}
