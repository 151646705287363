import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { TeacherStatus, TeacherStatusResponse } from "../models/teacher.model";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { map } from "rxjs/operators";
import { UserStatus } from "../models/user.model";
@Injectable()

export class TeacherGuard  {
    constructor(
        private authService: AuthService,
        private router: Router,
    ) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        const user = this.authService.getLoggedInUser();
        const userRole = this.authService.getUserRole();
        let accept: boolean = true;

        if (userRole && userRole.toLowerCase() === 'teacher') {

            
            console.log(state.url);
            // if (state.url.includes("dashboard")) {
            //     this.router.navigateByUrl('/teacher/status', { replaceUrl: true });
            //     accept = false;
            //     return true;
            // }
            // teacher status is active

            return this.authService.getTeacherStatus().pipe(
                map(teacherStatus => {
                    if (teacherStatus) {
                        this.authService.setTeacherStatus(teacherStatus);
                        let statusText = teacherStatus.status.toLowerCase();

                        console.log(state.url);
                        // all other urls except teacher urls
                        // TODO remove profile checks and possible move them to another guard service
                        if (!state.url.includes("teacher/")) {
                            if (statusText.toLowerCase() !== TeacherStatus.APPROVED.toLowerCase()) {
                                this.router.navigateByUrl('/teacher/status', { replaceUrl: true });
                                accept = false;
                                return true;
                            }
                            if (user.status.toLowerCase(), UserStatus.ACTIVE.toLowerCase()) {
                                return true;
                            }
                            if (statusText === TeacherStatus.WITHDRAWN.toLowerCase() && !state.url.includes("profile") && !state.url.includes(":status")) {
                                this.router.navigateByUrl('/teacher/status', { replaceUrl: true });
                                accept = false;
                                return true;
                            }
                            if (statusText === TeacherStatus.INCOMPLETE.toLowerCase() && !state.url.includes("profile") && state.url.includes(":status")) {
                                accept = false;

                                this.router.navigateByUrl('/teacher/status', { replaceUrl: true });
                                return false;
                            }
                            if (statusText === TeacherStatus.INCOMPLETE.toLowerCase() && !state.url.includes("profile") && !state.url.includes(":status")) {
                                // return true;
                                this.router.navigateByUrl('/teacher/status', { replaceUrl: true });
                                accept = true;
                                return false;
                            }
                            if (statusText === TeacherStatus.CONSIDERATION.toLowerCase() && !state.url.includes("profile") && !state.url.includes(":status")) {
                                this.router.navigateByUrl('/teacher/status', { replaceUrl: true });
                                accept = false;
                                return true;
                            }
                            if (statusText === TeacherStatus.CONSIDERATION.toLowerCase() && !state.url.includes(":status")) {
                                accept = true;
                            }
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                    return accept//allow to go to router if true
                })
            );

            // let teacherStatus = (await this.authService.getTeacherStatus().toPromise() as TeacherStatusResponse)

        } else {
            if (state.url.includes("teacher/")) {
                accept = true;
            }
        }

        return accept//allow to go to router if true

    }
}    