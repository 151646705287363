import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { Country, Language, Timezone } from 'src/app/core/models/general.model';
import { Teacher, TeacherApplicationStep1, TeacherApplicationStep1Native } from 'src/app/core/models/teacher.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { TeacherApplicationService } from 'src/app/core/services/teacher-application.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
})
export class GeneralInfoComponent implements OnInit {
  @ViewChild('opportunityForm', { static: true }) ngForm: NgForm = {} as NgForm;
  private subs = new SubSink();
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public isTablet: boolean = false;
  public timezones: Timezone[] = this.generalService.getTimezones();
  public countries: Country[] = this.generalService.getCountries();
  public phoneCodes: any[] = this.generalService.getPhoneCodes();
  public languages: Language[] = this.generalService.languages;
  public selectedOriginCountry?: Country;
  public selectedTimezone?: Timezone;
  public selectedLanguage?: Language;
  public tryToSave: boolean = false;
  public isLoading: boolean = true;
  public teacher?: Teacher;
  public teacherStep1?: TeacherApplicationStep1;
  public selectedCountry: string = "";
  public maxBirthdateTime = new Date();
  public formattedBirthDate: string = "";
  private nativeLanguagesModel: { name: string }[] = [];
  private formChanged = false;
  public selectedPhoneCode: any | undefined = {} as any | undefined;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private generalService: GeneralService,
    private teacherService: TeacherApplicationService,
    private authService: AuthService,
    private toast: ToastService,
    private confirmationService: ConfirmationService
  ) { }

  get nativeLanguages(): UntypedFormArray {
    return this.form.get('nativeLanguages') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.subs.add(this.teacherService.submitOnMenuClickListener.subscribe(res => {
      if (res) {
        this.onSubmit();
      }
    }));


    console.log(this.maxBirthdateTime);
    const date = new Date();

    const newDate = (this.maxBirthdateTime.setFullYear(this.maxBirthdateTime.getFullYear() - 18));
    this.maxBirthdateTime = new Date(newDate);
    this.teacherService.setCurrentStepIndex(0);
    this.teacher = this.teacherService.dummyTeacher;

    this.subs.add(this.generalService.deviceKind.subscribe(res => {
      this.isTablet = res.is1024;
    }));

    this.subs.add(this.teacherService.getTeacherApplicationStepsStatus().subscribe(stepsStatuses => {
      if (!stepsStatuses.step1) {
        this.prepareSelectedPhoneCodeFromIP();
      } 
    }));

    this.subs.add(this.teacherService.getTeacherApplicationStep1(this.authService.getUserId()!).subscribe(res => {
      console.log(res);
      this.updateStep1TeacherInfoFormValues(res);
      this.initFormChangedListener();
      this.isLoading = false;
    }));

    // updating title for mobile header text
    // this.generalService.updateMobileMenuTitle(this.teacherService.getStepsTitle(0));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.teacherService.setSubmitOnMenuClickListener(false);
  }

  onSubmit() {
    this.tryToSave = true;

    let invalidInputs = '';
    for (const controlName in this.form.controls) {
      if (this.form.controls.hasOwnProperty(controlName)) {
        const control = this.form.controls[controlName];
        if (control.invalid) {
          invalidInputs += `${controlName}, `;
        }
      }
    }

    let detailMessage = 'Please enter all required fields to continue.';
    if (invalidInputs !== '') {
      invalidInputs = invalidInputs.slice(0, -2); // Remove the trailing comma and space

      if (invalidInputs !== '') {
        detailMessage += `Invalid input for the following fields: ${invalidInputs}`;
      }
    }
    if (!this.form.valid) {
      this.toast.setShowToastmessage({
        severity: 'warn',
        summary: '',
        detail: detailMessage
      });
      return;
    }
    this.formChanged = false;
    const teacherApplicationStep1FormData: TeacherApplicationStep1 = {
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      prefferedName: this.form.value.prefferedName,
      countryOrigin: { name: this.form.value.countryOrigin.name, flagUrl: this.form.value.countryOrigin.image },
      countryResidency: { name: this.form.value.countryResidency.name, flagUrl: this.form.value.countryResidency.image },
      nativeLanguages: this.form.value.nativeLanguages,
      timeZone: this.form.value.timeZone?.utc[0],
      birth: this.generalService.convertDateStringToIsoFormatWithZeroTime(this.formattedBirthDate),
      email: this.form.value.email,
      phone: this.form.value.phone,
      phoneCode: this.form.value.phoneCode.code,
      skype: this.form.value.skype,
    }
    this.subs.add(this.teacherService.updateAPITeacherApplicationStep1(teacherApplicationStep1FormData).subscribe(res => {
      if (res) {
        this.toast.setShowToastmessage({ severity: 'success', summary: '', detail: 'Your General details were saved.' });
        // this.toastr.success("Your general info were updated.")
        this.router.navigateByUrl('/teacher/education', { replaceUrl: true });
      }
    }));

  }

  updateStep1TeacherInfoFormValues(teacherStep1Object: TeacherApplicationStep1) {

    if (this.isValidDate(teacherStep1Object.birth)) {
      this.formattedBirthDate = this.generalService.formatDateToDMY(teacherStep1Object.birth);
    } else {
      this.formattedBirthDate = this.generalService.formatDateToDMY(this.maxBirthdateTime.toString());
    }

    if (!this.generalService.isNullishObject(teacherStep1Object.phoneCode)) {
      this.selectedPhoneCode = this.phoneCodes.filter(el => el.code === teacherStep1Object.phoneCode)[0];
    }

    this.form = new UntypedFormGroup({
      birth: new UntypedFormControl(this.formattedBirthDate, {
        validators: [Validators.required]
      }),
      prefferedName: new UntypedFormControl(teacherStep1Object.prefferedName, {
        validators: [Validators.required]
      }),
      firstName: new UntypedFormControl(teacherStep1Object.firstName, {
        validators: [Validators.required]
      }),
      lastName: new UntypedFormControl(teacherStep1Object.lastName, {
        validators: [Validators.required]
      }),
      email: new UntypedFormControl(teacherStep1Object.email, {
        validators: [Validators.required, Validators.email]
      }),
      phoneCode: new UntypedFormControl(this.selectedPhoneCode, {
        validators: [Validators.required]
      }),
      phone: new UntypedFormControl(teacherStep1Object.phone, {
        validators: [Validators.required]
      }),
      skype: new UntypedFormControl(teacherStep1Object.skype || '-', {
        validators: [Validators.required]
      }),
      nativeLanguages: new UntypedFormArray([], {
        validators: [Validators.required]
      }),
      timeZone: new UntypedFormControl(
        this.timezones.find(el => el.utc.includes(teacherStep1Object.timeZone!)), {
        validators: [Validators.required]
      }),
      countryOrigin: new UntypedFormControl(
        !this.generalService.isNullishObject(teacherStep1Object.countryOrigin) ?
          this.countries.filter(el => el.name === teacherStep1Object.countryOrigin.name)[0] : null, {
        validators: [Validators.required]
      }),
      countryResidency: new UntypedFormControl(
        !this.generalService.isNullishObject(teacherStep1Object.countryResidency) ?
          this.countries.filter(el => el.name === teacherStep1Object.countryResidency.name)[0] : null, {
        validators: [Validators.required]
      }),
    });

    if (!this.generalService.isNullishObject(teacherStep1Object.nativeLanguages)) {
      teacherStep1Object.nativeLanguages.forEach(element => {
        this.addnativeLanguage(element.native);
      });
    } else {
      this.nativeLanguagesModel.forEach(element => {
        this.nativeLanguages.push(this.fb.group(element))
      });

      if (this.nativeLanguagesModel.length === 0) {
        this.addnativeLanguage({})
      }
    }

  }

  onBirthDateSelected(event: any) {
    console.log(event);
    let d = new Date(Date.parse(event));
    this.formattedBirthDate = this.generalService.formatDateToDMY(event);
  }

  isConfirm(field: string) {
    return ((this.form.get(field)?.invalid && this.form.get(field)?.touched) || (this.tryToSave && this.form.get(field)?.invalid) || (this.form.value.password !== this.form.value.confirm && this.form.get(field)?.touched))
  }

  onOriginChange(event: any) {
    setTimeout(() => {
      this.selectedLanguage = event.value
    }, 100);
  }

  onCountryChange(event: any) {
    setTimeout(() => {
      this.selectedCountry = event.value
    }, 100);
  }

  onTimeZoneChange(event: any) {
    setTimeout(() => {
      this.selectedTimezone = event.value
    }, 100);
  }

  onPhoneCodeChange(event: any) {
    setTimeout(() => {
      this.selectedPhoneCode = event.value;
      console.log(this.selectedPhoneCode);
    }, 100);
  }

  initFormChangedListener() {
    this.subs.add(this.form.valueChanges.subscribe(val => {
      if (this.form.dirty) {
        this.formChanged = true;
      }
    }));
  }

  ifFieldValid(field: string) {
    this.teacherService.setStepValid(0, this.form, 'teacher-info-route')
    return ((this.form.get(field)?.invalid && this.form.get(field)?.touched) || (this.tryToSave && this.form.get(field)?.invalid))
  }

  ifNativeLanguagesFieldValid(i: number) {
    this.teacherService.setStepValid(0, this.form, 'teacher-info-route');
    return ((this.tryToSave && (<UntypedFormArray>this.form.get('nativeLanguages')).controls[i].invalid))
  }

  addnativeLanguage(objectValue: object) {
    if (this.nativeLanguages.length < 3) {
      const group = new UntypedFormGroup({
        native: new UntypedFormControl(objectValue, Validators.required),
      });
      this.nativeLanguages.push(group);
    }
  }

  removeNativeLanguage(index: number) {
    this.nativeLanguages.removeAt(index);
  }

  isValidDate(dateValue: string) {
    const date = new Date(dateValue);
    if (isNaN(date.getTime()) || date.getFullYear() < 1000) {
      return false;
    } else {
      return true;
    }
  }

  findCountryImage(phoneCode: string) {
    return this.generalService.findCountryImage(phoneCode);
  }

  private prepareSelectedPhoneCodeFromIP() {
    this.generalService.getCountryCode().subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.selectedPhoneCode = this.phoneCodes.filter(el => el.iso === res.country_code)[0];
      }
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.formChanged) {
      return new Observable((observer: any) => {
        this.confirmationService.confirm({
          header: '',
          key: 'stepLeaveConfirmation',
          message: 'Are you sure you want to leave this page? Your changes will be lost.',
          accept: () => {
            observer.next(true);
            observer.complete();
          },
          reject: () => {
            observer.next(false);
            observer.complete();
          }
        });
      });
    } else {
      return (true);
    }
  }
}

