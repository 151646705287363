import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToastService } from "../services/toast.service";
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private toastService: ToastService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        if (req.headers.has('X-Bypass-Error-Interceptor')) {
            return next.handle(req);
        }

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                console.log(error)
                let errorMessage = "An unknown error occured";
                if (error.error && error.error.error && error.error.error.message) {
                    errorMessage = error.error.error.message;
                }
                if (error.error && error.error.message) {
                    errorMessage = error.error.message;
                }
                this.toastService.setShowToastmessage({
                    severity: 'error',
                    summary: '',
                    detail: errorMessage
                });
                return throwError(error);
            })
        );
    }
}