import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfirmComponent } from './confirm/confirm.component';

import { ForgotComponent } from './forgot/forgot.component';
import {ButtonModule} from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import { LoaderComponent } from 'src/app/shared/loader/loader.component';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    ConfirmComponent,
    ForgotComponent,
  ],
  imports: [
    LoaderComponent,
    CommonModule,
    FormsModule,
    AuthRoutingModule,
    MatProgressSpinnerModule,
    ButtonModule,
    CheckboxModule,
    RecaptchaModule,
  ],
  providers: [
  //TODO GOOGLE LOGIN

    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorHandlerService,
    //   multi: true
    // },
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: GoogleLoginProvider.PROVIDER_ID,
    //         provider: new GoogleLoginProvider(
    //           '883654869277-m2mlgsroh8vsmt0tg82lmo4cnt0cuqs3.apps.googleusercontent.com'
    //         )
    //       },
    //     ],
    //   } as SocialAuthServiceConfig
    // }
  ]
})
export class AuthModule { }
