<p-toast position="top-right" key="tc" styleClass="toast-custom" [showTransformOptions]="'translateX(100%)'">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column justify-content-between align-items-center">
            <div class="p-text-center flex align-items-center">
                <img src="/assets/icons/toast-{{message.severity}}-icon.svg" class="pr-3" />
                <h4>{{ message.summary}}</h4>
                <p>{{ message.detail}}</p>
            </div>
        </div>
    </ng-template>
</p-toast>