<div class="section">
    <div class="section-title gradient-bg w-100" [ngStyle]="{'background-image': 'url(' + backgroundImage + ')'}">
        <span class="text-white font-3xl">{{title}}</span>
        <div class="toggle-section" (click)="toggleSection(aSection, arrowSrc)">
            <img #arrowSrc src="/assets/icons/toogle-section.svg" class="section-arrow">
        </div>
    </div>
    <div #aSection [attr.id]="elementTitle" [attr.open]="isOpen" class="section-content" style="overflow: inherit">
        <div [hidden]="!isOpen">
            <ng-content></ng-content>
        </div>
    </div>
</div>