<p-confirmDialog #cd [key]="dialogKey" class="cd-confirm-dialog" defaultFocus="none" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
[style]="width" [baseZIndex]="10000" rejectIcon="pi pi-times" [styleClass]="headerClass" [closable]="true">
<ng-container [ngSwitch]="dialogType">
  <ng-template [ngSwitchCase]="'header-with-image'">
    <ng-template pTemplate="header">
      <div class="text-center flex align-items-center flex-column">
        <img [src]="confirmIcon && showConfirmIcon ? confirmIcon : '/assets/icons/warning-sign.svg'" [class]="headerText ? '' : ''" />
        <h3 *ngIf="headerText">{{headerText}}</h3>
      </div>
      <div class="p-dialog-header-icons close-icon"><button (click)="onClearSelected()" type="button" class="p-dialog-header-icon p-dialog-header-close p-link"><span class="pi pi-times-circle"></span>
      </button></div>
    </ng-template>
  </ng-template>
  <ng-template [ngSwitchCase]="'header-with-lottie'">
    <ng-template pTemplate="header">
      <div class="text-center flex align-items-center flex-row" [ngClass]="headerClass">

        <div #lottieAnim></div>
        <div class="flex flex-column justify-content-start text-left">
            <h3 class="m-0 text-base text-primary font-bold">{{headerText}}</h3>
            <p class="m-0 mb-2 text-base text-primary">{{confirmMessage}}</p>
            <div class="block">
            <button pButton type="button" label="Go Back" icon="pi pi-chevron-left" iconPos="left" (click)="cd.accept()"
            class="p-button-outlined p-button-rounded p-2 p-button-sm font-bold"></button>
        </div>
    </div>
      </div>
    </ng-template>
  </ng-template>
  <ng-template [ngSwitchDefault]>
    <ng-template pTemplate="header">
      <div class="text-center flex align-items-center flex-column">
        <h3 *ngIf="headerText">{{headerText}}</h3>
      </div>
      
    </ng-template>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button *ngIf="rejectBtnLabel" [styleClass]="rejectBtnClass" 
      [icon]="rejectBtnIcon ? rejectBtnIcon : null" [label]="rejectBtnLabel"
      [iconPos]="rejectBtnIcon ? 'left' : ''"
      (click)="cd.reject()"></p-button>
    <p-button *ngIf="acceptBtnLabel" [styleClass]="acceptBtnClass + ' cd-dialog-btn-accept ' + acceptBtnImage ? 'cd-dialog-btn-accept flex flex-row gap-1 align-items-center' : ''" 
      [icon]="acceptBtnIcon ? acceptBtnIcon : null" [label]="acceptBtnLabel"  [iconPos]="acceptBtnIcon ? 'right' : ''"
      (click)="cd.accept()"><img height="22" *ngIf="acceptBtnImage" src="{{ acceptBtnImage }}" alt="Button Image" /></p-button>
  </ng-template>
</ng-container>
</p-confirmDialog>