<ng-container *ngIf="this.dialogData">
  <app-block-viewer [header]="this.dialogData.lesson.status"
    headerBackgroundImage="/assets/images/dashboard/calendar/calendar-dialog-lesson-info-{{getLessonStatusGradImage()}}.png"
    blockClass="border-radius-bottom-10" headerBlockClass="py-1 border-round-lg bg-cover "
    containerClass="bg-white px-3 py-2 relative" [headerClass]="'justify-content-center my-0'"
    [headerTextClass]="'font-base font-semibold justify-content-center capitalize'" [showCloseDialogIcon]="true"
    (closeDialogEvent)="onDialogClose()">

    <div class="img-container">

      <img class=" relative" 
      src="/assets/images/dashboard/calendar/{{getLessonStatusGradImage()}}-gradient.png" />
    </div>
    <ul class="lesson-info-list list-none px-0 m-0 mb-1 info-list-middle mx-3 py-1">
      <ng-container *ngIf="role === UserRoles.TEACHER">
        <li class="flex align-items-center px-2 surface-border flex-wrap font-xs" *ngIf="dialogData.classroom && dialogData.classroom.classroomStudents">
          <div class="w-6 md:w-6 text-right lg:text-center">Participants</div>
          <div class="pl-2 md:w-6 md:flex-order-0 flex-order-1 font-semiBold">
            {{classroomService.getFullStudentNames(dialogData.classroom.classroomStudents)}}</div>
        </li>
      </ng-container>
      <ng-container *ngIf="role === UserRoles.STUDENT">

      <li class="flex align-items-center px-2 surface-border flex-wrap font-xs" *ngIf="dialogData.classroom.teacher">
        <div class="w-6 md:w-6 text-right lg:text-center">Teacher</div>
        <div class="pl-2 md:w-6 md:flex-order-0 flex-order-1 font-semiBold">
          {{dialogData.classroom.teacher.firstName}} {{dialogData.classroom.teacher.lastName}}</div>
      </li>
      </ng-container>
      <li class="flex align-items-center px-2 surface-border flex-wrap font-xs">
        <div class="w-6 md:w-6 text-right lg:text-center">Language</div>
        <div class="pl-2 md:w-6 md:flex-order-0 flex-order-1 font-semiBold capitalize">{{dialogData.classroom.language ? dialogData.classroom.language : dialogData.lesson.classroom.language}}</div>
      </li>
      <li *ngIf="!classroomService.isTrialClassroom(this.dialogData.classroom)" class="flex align-items-center px-2 surface-border font-xs flex-wrap">
        <div class="w-6 md:w-6 text-right lg:text-center">Level</div>
        <div class="pl-2 md:w-6 md:flex-order-0 flex-order-1 font-semiBold capitalize">{{dialogData.classroom.activeLevel ? dialogData.classroom.activeLevel : dialogData.lesson.classroom.activeLevel}}</div>
      </li>
      <li class="flex align-items-center px-2 surface-border font-xs flex-wrap">
        <div class="w-6 md:w-6 text-right lg:text-center">Starts at</div>
        <div class="pl-2 md:w-6 md:flex-order-0 flex-order-1 font-semiBold">{{ generalService.getLessonTime(dialogData.lesson.startingDate) }}</div>
      </li>
      <li class="flex align-items-center px-2 surface-border font-xs flex-wrap">
        <div class="w-6 md:w-6 text-right lg:text-center">Duration</div>
        <div class="pl-2 md:w-6 md:flex-order-0 flex-order-1 font-semiBold">
          {{generalService.convertHoursToMinutesWithSuffix(dialogData.lesson.duration)}}</div>
      </li>
      <li class="flex align-items-center px-2 surface-border font-xs flex-wrap">
        <div class="w-6 md:w-6 text-right lg:text-center">Date</div>
        <div class="pl-2 md:w-6 md:flex-order-0 flex-order-1 font-semiBold">
          {{ dialogData.lesson.startingDate | date:'dd/MM/yyyy' }}</div>
      </li>
    </ul>

    <div class="grid relative">
      <div class="col-8 mx-auto">
        <!-- <ng-container *ngIf="this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) === lessonStatus.ARRANGED">
          <div class="flex justify-content-evenly mb-1">
            <button pbutton="" type="button"
              class="lesson-btn join-lesson-student p-element p-button-rounded p-button p-button-xs gray-border-button p-component w-full">
              <div class="flex align-items-center gap-1 jus ju">
                <span class="font-xs">Join Lesson</span>
              </div>
            </button>
          </div>
        </ng-container> -->
        <ng-container *ngIf="[lessonStatus.ARRANGED_TRIAL, lessonStatus.ARRANGED].includes(this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status))">
       
          <div class="flex justify-content-evenly mb-1">
          <button pbutton="" type="button"
          [disabled]="!generalService.isDifferenceWithin30Minutes(this.dialogData.lesson.startingDate.toString(), 
                            this.generalService.getCurrentTimeInTimezone(this.user.timeZone!), this.dialogData.lesson)"
          [pTooltip]="!generalService.isDifferenceWithin30Minutes(this.dialogData.lesson.startingDate.toString(), 
                            this.generalService.getCurrentTimeInTimezone(this.user.timeZone!), this.dialogData.lesson) ? 'You can join your lesson 30 minutes before the scheduled time' : ''"
          tooltipPosition="top"
          (click)="generalService.goToBellbirdLink(this.dialogData.lesson)"
            class=" p-element p-button-rounded p-button-xs p-button p-component gray-border-button join-lesson-btn p-button-rounded text-white w-full"
            [ngClass]="{
            'disabled': !generalService.isDifferenceWithin30Minutes(this.dialogData.lesson.startingDate.toString(), 
                            this.generalService.getCurrentTimeInTimezone(this.user.timeZone!), this.dialogData.lesson) }"
            >
            <div class="flex align-items-center gap-1 jus ju">
              <span class="font-xs  flex gap-2 justify-content-center align-items-center text-white">
                JOIN LESSON</span>
            </div>
          </button>

          <!-- <button (click)="generalService.goToJitsiMeetLink(todayLessons[0])" pButton
                                class=" p-button-xs p-button p-button-rounded w-full gray-border-button max-w-max"
                                [ngClass]="{'text-white': generalService.isDifferenceWithin30Minutes(this.dialogData.lesson.startingDate.toString(), 
                            this.generalService.getCurrentTimeInTimezone(this.user.timeZone!)), 'p-button-outlined': !generalService.isDifferenceWithin30Minutes(this.dialogData.lesson.startingDate.toString(), 
                            this.generalService.getCurrentTimeInTimezone(this.user.timeZone!))}">
                                JOIN LESSON</button> -->
          </div>
        </ng-container>
                                
        <div class="flex justify-content-evenly mb-1">
          <button pbutton="" type="button"
          (click)="onGoToClassroomSelected()"
            class="p-button-outlined p-element p-button-rounded p-button-xs p-button p-component gray-border-button p-button-rounded w-full">
            <div class="flex align-items-center gap-1 jus ju">
              <span class="font-xs  flex gap-2 justify-content-center align-items-center">
                Go to Classroom</span>
            </div>
          </button>
        </div>
        <!-- 
        This ng-container will only render its content if all of the following conditions are met:
        1. The lesson status is "COMPLETED"
        2. The classroom is not a trial classroom
        3. The completed lesson has a rating
        -->
        <ng-container *ngIf="this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) === lessonStatus.COMPLETED
        && !this.classroomService.isTrialClassroom(this.dialogData.classroom) && completedLessonHasRating">
          <div class="flex justify-content-evenly mb-1">
            <button pbutton="" type="button"
            (click)="openLessonRatingDialog('view')"
              class="p-button-outlined p-element p-button-rounded p-button-xs p-button p-component gray-border-button p-button-rounded w-full">
              <div class="flex align-items-center gap-1 jus ju">
                <span class="font-xs  flex gap-2 justify-content-center align-items-center">
                  <img src="/assets/images/dashboard/calendar/rating.svg" height="14" />
                  View Rating</span>
              </div>
            </button>
          </div>
        </ng-container>
        <!-- 
        This ng-container will only render its content if all of the following conditions are met:
        1. The lesson status is "COMPLETED"
        2. The classroom is not a trial classroom
        3. The completed lesson has a rating
        4. The user's role is "TEACHER"
        -->
        <ng-container *ngIf="this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) === lessonStatus.COMPLETED
        && !this.classroomService.isTrialClassroom(this.dialogData.classroom) && completedLessonHasRating && role === UserRoles.TEACHER">
          <div class="flex justify-content-evenly mb-1">
            <button pbutton="" type="button"
            (click)="openLessonRatingDialog('edit')"
              class="p-button-outlined p-element p-button-rounded p-button-xs p-button p-component gray-border-button p-button-rounded w-full">
              <div class="flex align-items-center gap-1 jus ju">
                <span class="font-xs  flex gap-2 justify-content-center align-items-center">
                  <img src="/assets/images/dashboard/calendar/edit.svg" height="14" />
                  Edit Rating</span>
              </div>
            </button>
          </div>
        </ng-container>
        <!-- 
        This ng-container will only render its content if all of the following conditions are met:
        1. The lesson status is either "CANCELED" or "NO_SHOW"
        2. The classroom is not a trial classroom
        3. The user's role is "TEACHER"
        -->
        <ng-container *ngIf="
        (this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) === lessonStatus.CANCELED.toLowerCase() || 
        this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) === lessonStatus.NO_SHOW.toLowerCase()) &&
        !classroomService.isTrialClassroom(this.dialogData.classroom) && role === UserRoles.TEACHER">
          <div class="flex justify-content-evenly mb-1"
          >
                <div [ngTemplateOutlet]="chargeTimeSpan"
                [ngTemplateOutletContext]="{ lesson: this.dialogData.lesson }"></div>
          </div>
        </ng-container>

        <!-- 
        This ng-container will only render its content if all of the following conditions are met:
        1. The lesson status is either "CANCELED" or "NO_SHOW"
        2. The classroom is not a trial classroom
        3. The user's role is "STUDENT"
        4. The lesson has a charge time
        -->
        <ng-container *ngIf="
        (this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) === lessonStatus.CANCELED.toLowerCase() || 
        this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) === lessonStatus.NO_SHOW.toLowerCase()) &&
        !classroomService.isTrialClassroom(this.dialogData.classroom) && role === UserRoles.STUDENT">
        <div [ngTemplateOutlet]="chargeTimeSpan"
        [ngTemplateOutletContext]="{ lesson: this.dialogData.lesson }"></div>
        </ng-container>

        <ng-template #chargeTimeSpan let-lesson="lesson">
          <button pbutton="" [disabled]="lesson.chargeTime || role === UserRoles.STUDENT"
            [pTooltip]="lesson.chargeTime ? 'This lesson has already been charged.' : ''"
            tooltipPosition="top"
            (click)="!lesson.chargeTime && role === UserRoles.TEACHER ? chargeTimeSelected() : null" type="button"
              class="p-element p-button-rounded p-button-xs p-button p-component gray-border-button p-button-rounded w-full"
              [ngClass]="{'disabled-button-charge' : lesson.chargeTime,
              'disabled-button-not-charge' : !lesson.chargeTime && role === UserRoles.STUDENT}">
              <div class="flex align-items-center gap-1 jus ju">
                <span class="font-xs flex gap-2 justify-content-center align-items-center"
                      [ngClass]="{'text-white': !lesson.chargeTime && role === UserRoles.TEACHER }">
                  <img src="/assets/images/dashboard/calendar/hourglass-white.svg" height="14" />
                  <span>{{!lesson.chargeTime && role === UserRoles.TEACHER ? 'Charge Time' : 
                  !lesson.chargeTime && role === UserRoles.STUDENT ? 'Not Charged' :
                  'Already Charged' }}
                  </span>
                </span>
              </div>
            </button>
         </ng-template>
        <!-- <ng-container *ngIf="(this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) === lessonStatus.COMPLETED) && role === UserRoles.TEACHER">
          <div class="flex justify-content-evenly mb-1">
            <button pbutton="" type="button"
              class="lesson-btn edit-color p-button-outlined p-element p-button-rounded p-button p-button-xs gray-border-button p-component w-full">
              <div class="flex align-items-center gap-1">
                <span class="font-xs flex gap-2 justify-content-center align-items-center">
                  <img src="/assets/images/dashboard/calendar/edit.svg" height="14" /> Edit lesson</span>
              </div>
            </button>
          </div>
        </ng-container> -->

        <!-- 
          Conditions When can show button for "Edit Lesson":
        if the lesson status is either "REQUESTED", "ARRANGED_TRIAL", or "ARRANGED"
        -->
        <ng-container *ngIf="[lessonStatus.REQUESTED, lessonStatus.ARRANGED_TRIAL, lessonStatus.ARRANGED].includes(this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status))">
          <div class="flex justify-content-evenly mb-1">
            <button pbutton="" type="button"
            (click)="onRescheduleSelected()"
              class="lesson-btn reschedule p-button-outlined p-element p-button-rounded p-button-xs p-button p-component gray-border-button p-button-rounded w-full">
              <div class="flex align-items-center gap-1 jus ju">
                <span class="font-xs text-primary flex gap-2 justify-content-center align-items-center">

                  <img src="/assets/images/dashboard/calendar/reschedule-calendar-icon.svg" width="12" />
                  Edit Lesson</span>
              </div>
            </button>
          </div>
        </ng-container>
        <!-- <ng-container *ngIf="(this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) === lessonStatus.COMPLETED) && role === UserRoles.TEACHER">
          <div class="flex justify-content-evenly mb-1">
            <button pbutton="" type="button"
              class="lesson-btn reschedule p-button-outlined p-element p-button-rounded p-button-xs p-button p-component gray-border-button p-button-rounded w-full">
              <div class="flex align-items-center gap-1 jus ju">
                <span class="font-xs text-primary flex gap-2 justify-content-center align-items-center">
                  <img src="/assets/images/dashboard/calendar/forward-dark-blue.svg" width="12" /> Rearrange</span>
              </div>
            </button>
          </div>
        </ng-container> -->
        <!-- <ng-container *ngIf="(this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) === lessonStatus.COMPLETED) && role === UserRoles.TEACHER">
          <div class="flex justify-content-evenly mb-1">
            <button pbutton="" type="button"
              class="p-button-outlined p-element p-button-rounded p-button-xs p-button p-component gray-border-button p-button-rounded w-full">
              <div class="flex align-items-center gap-1 jus ju">
                <span class="font-xs">Rearrange</span>
              </div>
            </button>
          </div>
        </ng-container> -->

        <!-- 
          Conditions When can show button for "Cancel":
          1. The lesson status is "ARRANGED"
          2. The user's role is "STUDENT"
        -->
        <ng-container *ngIf="this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) === lessonStatus.ARRANGED && role === UserRoles.STUDENT">
          <div class="flex justify-content-evenly mb-1">
            <button pbutton=""(click)="onStudentCancelSelected()" type="button"
              class="p-element p-button-rounded p-button-xs p-button p-component gray-border-button p-button-rounded w-full">
              <div class="flex align-items-center gap-1 jus ju">
                <span class="font-xs text-white flex gap-2 justify-content-center align-items-center">
                  <img src="/assets/images/dashboard/calendar/close-white.svg" height="14" />
                  Cancel</span>
              </div>
            </button>
          </div>
        </ng-container>
        <!-- 
          Conditions When can show button for "Approve":
          1. The lesson status is "REQUESTED"
          2. The user's role is "TEACHER"
        -->
        <ng-container *ngIf="this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) === lessonStatus.REQUESTED && role === UserRoles.TEACHER">
          <div class="flex justify-content-evenly mb-1">
            <button pbutton=""(click)="onLessonAcceptedClicked()" type="button"
              class="p-element p-button-rounded p-button-xs p-button p-component gray-border-button p-button-rounded w-full">
              <div class="flex align-items-center gap-1 jus ju w-4rem">
                <span class="font-xs text-white flex gap-2 justify-content-center align-items-center">
                  <img src="/assets/icons/check-white.svg" height="10" />
                  Approve</span>
              </div>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) === lessonStatus.REQUESTED">
          <div class="flex justify-content-evenly mb-1">
            <button pbutton="" type="button"
            (click)="onLessonRejectedClicked()"
              class="p-button-outlined p-element p-button-rounded p-button-xs p-button p-component gray-border-button p-button-rounded w-full">
              <div class="flex align-items-center gap-1 jus ju w-4rem">
                <span class="font-xs  flex gap-2 justify-content-center align-items-center">
                 <i class="pi pi-times icon-btn font-xs"></i> {{role === UserRoles.TEACHER ? 'Reject' : 'Remove'}}</span>
              </div>
            </button>
          </div>
        </ng-container> 

        <ng-container *ngIf="role === UserRoles.TEACHER">
          <div class="flex justify-content-evenly mb-1">
            <button pbutton=""(click)="onLessonDeleteSelected()" type="button"
              class="p-element p-button-rounded p-button-xs p-button p-component lesson-btn delete-color justify-content-center p-button-rounded w-full">
              <div class="flex align-items-center gap-1 jus tex justify-content-center">
                <span class="font-xs flex gap-2 justify-content-center align-items-center">
                  <!-- <img src="/assets/images/dashboard/calendar/close-white.svg" height="14" /> -->
                  <i class="pi pi-times-circle"></i>
                  <div>Delete</div></span>
              </div>
            </button>
          </div>
        </ng-container>

        <!-- 
          Conditions When can show button for "Change Status":
          1. The user's role is "TEACHER"
          2. The lesson status is not "COMPLETED"
          3. The lesson status is not "REQUESTED"
          4. The lesson status does not include "CANCELED"
          5. The lesson status does not include "NO_SHOW"
        -->
        <ng-container *ngIf="role === UserRoles.TEACHER && this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) !== lessonStatus.COMPLETED
        && this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status.toLowerCase()) !== lessonStatus.REQUESTED.toLowerCase()
        && !this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status.toLowerCase()).includes(lessonStatus.CANCELED.toLowerCase())
        && !this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status.toLowerCase()).includes(lessonStatus.NO_SHOW.toLowerCase())">
          <div class="flex justify-content-evenly mb-1">
            <button pbutton="" type="button" (click)="op.toggle($event)"
              class="lesson-btn edit-color p-button-outlined p-element p-button-rounded p-button p-button-xs gray-border-button p-component w-full">
              <div class="flex align-items-center gap-1 jus ju">
                <span class="font-xs flex gap-2 justify-content-center align-items-center">
                  <img src="/assets/images/dashboard/calendar/change-status-loading-icon.svg" height="14" /> Change
                  Status</span>

                <p-overlayPanel styleClass="lesson-info-change-status" #op [style]="{'border-radius': '12px'}">
                  <ng-template pTemplate="content">
                    <ul class="p-0 m-0 list-none font-xs">
                      <li disableOnClick [disableTime]="2000" class="flex align-items-center border-bottom-1 surface-border px-5 py-1 mt-1 cursor-pointer  lesson-text-item"
                      *ngIf="
                      this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) !== lessonStatus.CANCELED  && 
                      !this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status).includes(lessonStatus.NO_SHOW.toLowerCase()) && 
                      !this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status).includes(lessonStatus.COMPLETED.toLowerCase())"
                      (click)="changeLessonStatus(this.lessonStatus.COMPLETED)"><span
                          class="text-900 line-height-3 flex align-items-center justify-content-center gap-1"><img src="/assets/images/dashboard/calendar/check.svg"
                            width="14" />
                          <span class="text-700"> Complete
                          </span></span></li>
                      <li  disableOnClick [disableTime]="2000"
                      *ngIf="!this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status).includes(lessonStatus.ARRANGED)"
                      (click)="changeLessonStatus(this.lessonStatus.REARRANGE)" class="flex align-items-center border-bottom-1 surface-border px-5 py-1 mt-1 cursor-pointer lesson-text-item"><span
                          class="text-900 line-height-3 flex align-items-center justify-content-center gap-1"><img src="/assets/images/dashboard/calendar/rearrange.svg"
                            width="14" />
                          <span class="text-700"> Rearrange </span></span></li>
                      <li disableOnClick [disableTime]="2000"
                      *ngIf="
                      !this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status).includes(lessonStatus.NO_SHOW.toLowerCase())
                      && !this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status.toLowerCase()).includes(lessonStatus.CANCELED.toLowerCase())" (click)="changeLessonStatus(this.lessonStatus.NO_SHOW)" 
                      class="flex align-items-center border-bottom-1 surface-border px-5 py-1 mt-1 cursor-pointer lesson-text-item"><span
                          class="text-900 line-height-3 flex align-items-center justify-content-center gap-1"><img src="/assets/images/dashboard/calendar/hide.svg"
                            width="14" />
                          <span class="text-700"> No Show </span></span></li>
                      <li disableOnClick [disableTime]="2000" class="flex align-items-center border-bottom-1 surface-border px-5 py-1 mt-1 cursor-pointer lesson-text-item"
                      *ngIf="this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) !== lessonStatus.CANCELED"
                      (click)="changeLessonStatus(this.lessonStatus.CANCELED)"><span
                          class="text-900 line-height-3 flex align-items-center justify-content-center gap-1"><img src="/assets/images/dashboard/calendar/cancel.svg"
                            width="14" />
                          <span class="text-700"> Cancel </span></span></li>
                    </ul>
                  </ng-template>
                </p-overlayPanel>

              </div>
            </button>
          </div>
        </ng-container>
        

        <!-- TODO: add when functionality is added to change status in a completed lesson -->
        <!-- <ng-container *ngIf="role === UserRoles.TEACHER && this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status) === lessonStatus.COMPLETED">
          <div class="flex justify-content-evenly mb-1">
            <button pbutton="" type="button" (click)="op.toggle($event)"
              class="lesson-btn edit-color p-button-outlined p-element p-button-rounded p-button p-button-xs gray-border-button p-component w-full">
              <div class="flex align-items-center gap-1 jus ju">
                <span class="font-xs flex gap-2 justify-content-center align-items-center">
                  <img src="/assets/images/dashboard/calendar/change-status-loading-icon.svg" height="14" /> Change
                  Status</span>

                <p-overlayPanel styleClass="lesson-info-change-status" #op [style]="{'border-radius': '12px'}">
                  <ng-template pTemplate="content">
                    <ul class="p-0 m-0 list-none font-xs">
                      <li 
                      *ngIf="!this.lessonService.getStatusNameFromStatusString(this.dialogData.lesson.status).includes(lessonStatus.ARRANGED)"
                      (click)="changeCompletedToArrangedAndDeleteRating()" class="flex align-items-center border-bottom-1 surface-border px-5 py-1 mt-1 cursor-pointer lesson-text-item"><span
                          class="text-900 line-height-3 flex align-items-center justify-content-center gap-1"><img src="/assets/images/dashboard/calendar/rearrange.svg"
                            width="14" />
                          <span class="text-700"> Rearrange </span></span></li>
                    </ul>
                  </ng-template>
                </p-overlayPanel>

              </div>
            </button>
          </div>
        </ng-container> -->
      </div>
    </div>
  </app-block-viewer>
</ng-container>