import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Teacher, TeacherApplicationEducation, TeacherApplicationStep2, TeacherEducation } from 'src/app/core/models/teacher.model';
import { GeneralService } from 'src/app/core/services/general.service';
import { TeacherApplicationService } from 'src/app/core/services/teacher-application.service';
import { SubSink } from 'subsink';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { Observable } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {

  private subs = new SubSink();
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public isTablet: boolean = false;
  public teacher: Teacher = this.teacherService.dummyTeacher;
  tryToSave: boolean = false;
  isLoading: boolean = false;
  speakLanguageCounter: number = 0;
  public error: boolean = false;
  public UPLOAD_TEACHER_APPLICATION_FILES_URL = '/LMS/UploadTeacherApplicationFiles';
  teacherEducation: TeacherEducation[] = [];
  showFileUpload: boolean[] = [];
  formChanged = false;

  constructor(
    private generalService: GeneralService,
    private teacherService: TeacherApplicationService,
    private toastService: ToastService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.teacherService.setCurrentStepIndex(1);
    this.subs.add(this.teacherService.submitOnMenuClickListener.subscribe(res => {
      if (res) {
        this.onSubmit();
      }
    }));

    this.subs.add(this.teacherService.getTeacherApplicationStep2().subscribe(res => {
      if (res) {
        this.updateStepFormValues(res);
      }
      if (res.education === null) {
        this.addEducation({} as TeacherApplicationEducation)
      }
      
      this.initFormChangedListener();
    }));

    this.subs.add(this.generalService.deviceKind.subscribe(res => {
      this.isTablet = res.is1024;
    }));
    this.form = new UntypedFormGroup({
      education: new UntypedFormArray([]),
    });
    this.teacherEducation = this.teacher.education
    this.teacherEducation.forEach(element => {
      this.showFileUpload.push(false)
      this.education.push(this.fb.group(element))
    });

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.teacherService.setSubmitOnMenuClickListener(false);
  }

  get education(): UntypedFormArray {
    return this.form.get('education') as UntypedFormArray;
  }

  /**
Update the form values for step 2 of the teacher application
@param {TeacherApplicationStep2} teacherApplicationStep2 - The data for step 2 of the teacher application
*/
  updateStepFormValues(teacherApplicationStep2: TeacherApplicationStep2) {

    if (!this.generalService.isNullishObject(teacherApplicationStep2.education)) {
      teacherApplicationStep2.education.forEach((element, index) => {
        this.addEducation(element);
        element.fileUploads.forEach(fileUploadElement => {
          this.addEducationFileField(index, fileUploadElement.filePath);
        });
      });
      console.log(teacherApplicationStep2.education);

    }
  }

  /**
  Add an education to the form
  @param {TeacherApplicationEducation} element - The education to add to the form
  */
  addEducation(element: TeacherApplicationEducation) {
    const group = new UntypedFormGroup({
      name: new UntypedFormControl(element ? element.name : '', Validators.required),
      institute: new UntypedFormControl(element ? element.institute : '', Validators.required),
      fileUploads: this.fb.array([])
    });
    this.education.push(group);
  }

  /**
  Remove an education from the form
  @param {number} index - The index of the education to remove
  */
  removeEducation(index: number) {
    this.education.removeAt(index);
  }
  

  initFormChangedListener() {
    this.subs.add(this.form.valueChanges.subscribe(val => {
      console.log(val);
      this.formChanged = true;
    }));
  }

  /**
  Submit the form data
  */
  onSubmit() {
    this.tryToSave = true;
    if (!this.form.valid) {
      this.error = true;
      return;
    }
    this.formChanged = false;
    this.subs.add(this.teacherService.updateTeacherApplicationStep2(this.form.value).subscribe(res => {
      if (res) {
        this.toastService.setShowToastmessage({
          severity: 'success',
          summary: '',
          detail: 'Your Education info were updated.'
        });
      }
      this.router.navigateByUrl('/teacher/experience', { replaceUrl: true });
    }));
  }

  back() {
    this.location.back();
  }

  ifFieldValid(i: number) {
    this.teacherService.setStepValid(1, this.form, 'teacher-education-route');
    return ((this.tryToSave && (<UntypedFormArray>this.form.get('education')).controls[i].invalid))
  }

  goBack() {
    this.router.navigateByUrl('/teacher/info', { replaceUrl: true });
  }

  /**
 * Handles the completion of a file upload
 *
 * @param {filePath: { dbPath: string; }} event - Event object containing information about the uploaded file
 * @param {number} i - Index of the file in the array of files
 *
 */
  uploadFinished(event: { filePath: { dbPath: string; } }, i: number) {
    this.hideFileUpload(i);
    this.addEducationFileField(i, event.filePath.dbPath);
  }

  /**
   * Opens the file upload dialog
   *
   * @param {number} fileIndex - Index of the file in the array of files
   *
   */
  openFileUploadDialog(fileIndex: number) {
    this.showFileUpload[fileIndex] = true
    document.getElementById('blur_bg')?.classList.add('yes-visibility');
  }

  /**
  Hides the file upload for a specific index.
  @param {number} fileIndex - The index of the file upload to be hidden.
  */
  hideFileUpload(fileIndex: number) {
    this.showFileUpload[fileIndex] = false;
    document.getElementById('blur_bg')?.classList.remove('yes-visibility');
  }

  /**
  Get the file name from a file path
  @param {string} fileName - The file path
  @returns {string} The file name
  */
  getFileName(fileName: string): string {
    return this.generalService.getFileName2(fileName);
  }

  removeEmployee(empIndex: number) {
    this.education.removeAt(empIndex);
  }

  educationfilePaths(empIndex: number): UntypedFormArray {
    return this.education
      .at(empIndex)
      .get('fileUploads') as UntypedFormArray;
  }

  newFilePathFormGroup(name: string): UntypedFormGroup {
    return this.fb.group({
      filePath: name
    });
  }

  addEducationFileField(empIndex: number, name: string) {
    this.educationfilePaths(empIndex).push(this.newFilePathFormGroup(name));
  }

  removeEducationFileField(empIndex: number, skillIndex: number, filename: string) {
    this.educationfilePaths(empIndex).removeAt(skillIndex);
    this.subs.add(this.teacherService.deleteTeacherApplicationFiles(filename).subscribe(result => {
      console.log(result);
    }));
  }
  
  canDeactivate(): Observable<boolean> | boolean {
    if (this.formChanged) {
      return new Observable((observer: any) => {
        this.confirmationService.confirm({
          header: '',
          key: 'stepLeaveConfirmation',
          message: 'Are you sure you want to leave this page? Your changes will be lost.',
          accept: () => {
            observer.next(true);
            observer.complete();
          },
          reject: () => {
            observer.next(false);
            observer.complete();
          }
        });
      });
    } else {
      return (true);
    }
  }
}
