<div class="mobile-menu px-2">
  <div class=" ml-2">
    <a href="https://www.mylingotrip.com" target="_blank"> <img src="/assets/icons/logo/mlt-logo-white.svg"
        alt="mlt-logo-white" class="logo-img">
    </a>
  </div>
  <ng-container *ngIf="mobileMenuTitle !== ''">
    <div class="mobile-title-header">
      <!-- Application Progress -->
      <!-- {{mobileMenuTitle}} -->
    </div>
  </ng-container>

  <div class="flex ml-auto">
    <ul #myDiv class="list-none select-none flex flex-row 
    md:border-top-none">
      <!-- TODO: enable for chat -->
      @if (chatService.isChatEnabled()) {
      <li class="relative  lg:pl-3 lg:ml-2" id="chats-dropdown" style="z-index: 55">
        <a title="Chat" [routerLink]="['/chat']" class=" flex align-items-center text-indigo-100 note-badge 
hover:text-indigo-50 hover:bg-indigo-700 font-medium border-round cursor-pointer">

          <div class="notaa flex align-items-center justify-content-center mr-3"
            style="z-index: 1; pointer-events: none;">
            <i class="pi pi-comments pb-0 text-2xl note-badge " pBadge [value]="0"></i>
          </div>
        </a>
      </li>
      }
      <li class="relative  lg:pl-3 lg:ml-2" id="chats-dropdown" style="z-index: 55">
        <a title="Chat" [routerLink]="['/notifications']" class=" flex align-items-center text-indigo-100 note-badge 
hover:text-indigo-50 hover:bg-indigo-700 font-medium border-round cursor-pointer">

          <div class="notaa flex align-items-center justify-content-center mr-3"
            style="z-index: 1; pointer-events: none;">
            <i class="pi pi-bell pb-0 text-2xl note-badge " pBadge
              [value]="this.unreadNotifications.length > 0 ? this.unreadNotifications.length : 0"></i>
          </div>
        </a>
      </li>

    </ul>

    <div class="nava" [ngClass]="burgerClasses.nava">
      <div class="wpo-site-footer" [ngClass]="burgerClasses.menu">
        <div class="wpo-upper-footer">
          <div class="container">
            <div class="grid align-items-start justify-content-center">

              <!-- <div href="javascript:void(0);" class="icon" (click)="toggleMobileMenuItems()">
                        <i class="fa fa-bars" style="font-size:30px; padding: 14px; color:var(--main-color)"></i>
                    </div> -->
              <!--                     
                    <div class="col-12 sm:col-12 lg:col-2 md:col-4">
                      <div class="widget link-widget flex flex-column align-items-center justify-content-center">
                          <div class="widget-title">
                              <h3 class=" d-flex align-items-sm-center mb-2 mb-md-4"><span class="circle-singleline">1</span>Home</h3>
                          </div>
                          <ul>
                            <li><a pButton class="btn p-button-outlined p-button-raised p-button-rounded rounded-pill text-decoration-none text-white w-100 text-center mb-2" href="">
                                LingoGalaxy</a></li>
                            <li><a class="btn p-button-outlined p-button-raised p-button-rounded  rounded-pill text-decoration-none text-white w-100 text-center mb-2" href="">
                                MyLingoBusiness</a></li>
                          </ul>
                      </div>
                  </div> -->


              <div class="col-12 sm:col-12 lg:col-2 md:col-4">
                <div class="widget link-widget flex flex-column align-items-center justify-content-center">
                  <!-- <div class="widget-title">
                            <h3 class=" d-flex align-items-sm-center mb-2 mb-md-4"><span class="circle-singleline">1</span>Home</h3>
                        </div> -->
                  <ul class="pl-0">
                    <ng-container *ngFor="let item of menuItems; let i = index;">
                      <li *ngIf="item.role.includes(role)"
                        class="hover:bg-indigo-600 pointer flex align-items-center font-3xl gap-2"
                        [ngClass]="{'disabled-link': item.disabled}" (click)="navigateTo(item.url)"
                        [tabindex]="item.disabled ? -1 : null">
                        <div class="icon-container">
                          <span class="lottie-wrapper">
                            <lottie-player [src]="item.lottieIcon" background="transparent" speed="1"
                              [style.width.px]="'50'" [style.height.px]="'50'"
                              [style.transform]="'scale(' + item.scale + ')'" loop></lottie-player>
                          </span>
                          <!-- <img [src]="item.icon" class="icon"> -->
                        </div>

                        {{item.title}}
                      </li>
                    </ng-container>
                    <ng-container *ngFor="let item of extraMobileMenuItems; let i = index;">
                      <li class="hover:bg-indigo-600 pointer flex align-items-center font-3xl gap-2"
                        (click)="item.url ? navigateTo(item.url): item.method()">

                        <div class="icon-container">
                          <span class="lottie-wrapper">
                            <lottie-player [src]="item.lottieIcon" background="transparent" speed="1"
                              [style.width.px]="'50'" [style.height.px]="'50'"
                              [style.transform]="'scale(' + item.scale + ')'" loop></lottie-player>
                          </span>
                          <!-- <img [src]="item.icon" class="icon"> -->
                        </div>
                        {{item.title}}
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>

            </div>
          </div> <!-- end container -->
        </div>
        <div class="wpo-lower-footer">
          <div class="container">
            <div class="row">
              <div class="col-12 text-center">
                <p class="about-widget text-center">Let’s get social!</p>

                <ul class="flex justify-content-center align-items-center gap-1 p-0">
                  <li>
                    <a [href]="this.socialLinks.facebook" target="_blank">
                      <img src="/assets/icons/navbar/facebook.svg" alt="facebook Image">
                    </a>
                  </li>
                  <li>
                    <a [href]="this.socialLinks.instagram" target="_blank">
                      <img src="/assets/icons/navbar/instagram.svg" alt="instagram Image">
                    </a>
                  </li>
                  <li>
                    <a [href]="this.socialLinks.linkedin" target="_blank">
                      <img src="/assets/icons/navbar/linkedin.svg" alt="linkedin Image">
                    </a>
                  </li>
                  <li>
                    <a [href]="this.socialLinks.twitter" target="_blank">
                      <img src="/assets/icons/navbar/twitter.svg" alt="twitter Image">
                    </a>
                  </li>
                  <li>
                    <a [href]="this.socialLinks.youtube" target="_blank">
                      <img src="/assets/icons/navbar/youtube.svg" alt="youtube Image">
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="overlay" [ngClass]="burgerClasses.overlay"></div>


  <div class="burger" [ngClass]="burgerClasses.burger" (click)="burgerClick()">
    <span></span>
  </div>
</div>