import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-rate-question-item',
  templateUrl: './rate-question-item.component.html',
  styleUrls: ['./rate-question-item.component.scss']
})
export class RateQuestionItemComponent implements OnInit {
  @Input() questionText = '';
  @Input() questionIndexNumber = null;
  @Input() questionAttr = '';
  @Output() ratingSelected = new EventEmitter<{}>();
  @Output() afterRatingSelected = new EventEmitter<any>();
  rating: number = 3;
  constructor() { }

  ngOnInit(): void {
  }

  onRatingSelected(rating: number) {
    this.ratingSelected.emit({number: rating, attr: this.questionAttr});
    this.rating = rating;
    this.afterRatingSelected.emit(this.questionAttr);
  }

}
