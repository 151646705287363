<!-- <p-menubar [model]="items">
    <div  class="bg-indigo-500 flex align-items-center justify-content-between relative w-100 lg:fixed z-5">
    <div class="header-content mx-auto">
    <ng-template pTemplate="start">
        <img src="/assets/icons/logo-white.png" class="logo-img hvr-grow" alt="Image" height="40"
        class="mr-0 lg:mr-6">
    </ng-template>
    <ng-template pTemplate="end">
        <input type="text" pInputText placeholder="Search">
    </ng-template>
    <input type="text" pInputText placeholder="Search">
    <button pButton label="Logout" icon="pi pi-power-off"></button>
    </div>
</div>
</p-menubar> -->
<div class="header flex align-items-center justify-content-between fixed w-100 fixed">
    <div class="header-content container mx-auto" #scaleHeader>
        <a href="https://www.mylingotrip.com" target="_blank" class="flex"><img
                src="/assets/icons/logo/mlt_logo-new.svg" class="logo-img hvr-grow" alt="Image" height="40"
                class="mr-0"></a>

        <div
            class="align-items-center flex-grow-1 justify-content-between hidden md:flex md:flex-row absolute md:static w-full">
            <ul class="list-none p-0 m-0 flex md:align-items-center select-none flex-row">
                <li>
                    <a pRipple routerLink="/dashboard"
                        class="p-ripple p-element flex px-6 p-3 lg:px-3 lg:py-2 align-items-center 
                        text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                        <i class="pi pi-home mr-2"></i>
                        <span>Home</span>
                        <span class="p-ink"></span>
                    </a>
                </li>
                <!-- <li>
                    <div class="beta-chip-label border-round-2xl"
                    pTooltip="Welcome to our Beta version! Help us improve by providing your valuable feedback."
                    tooltipPosition="bottom">
                        <span class="font-sm">Beta</span>
                      </div>
                </li> -->

            </ul>

            <ul #myDiv class="list-none p-0 m-0 flex md:align-items-center select-none flex-column md:flex-row border-top-1 border-surface-400 
                md:border-top-none">
                <!-- TODO: enable for chat -->
                @if (chatService.isChatEnabled()) {
                <li class="relative  md:pl-3 md:ml-2" id="chats-dropdown" style="z-index: 55">
                    <a title="Chat" [routerLink]="['/chat']" class=" flex align-items-center text-indigo-100 note-badge 
hover:text-indigo-50 hover:bg-indigo-700 font-medium border-round cursor-pointer">

                        <div class="notaa" style="z-index: 1; pointer-events: none;">
                            <i class="pi pi-comments pb-0 md:text-xl note-badge " pBadge
                                [value]="this.chatService.unreadCount() > 0 ? this.chatService.unreadCount() : 0"></i>
                            <span class="block md:hidden font-medium">Notifications</span>
                        </div>
                    </a>
                </li>
                }

                <li class="relative notaa  md:pl-3 md:ml-2" id="notifications-dropdown" style="z-index: 55"
                    (click)="toggleNotificationOverlay($event)">
                    <a class="notaa flex align-items-center text-indigo-100 note-badge 
            hover:text-indigo-50 hover:bg-indigo-700 font-medium border-round cursor-pointer">

                        <div class="notaa" style="z-index: 1; pointer-events: none;">
                            <i class="pi pi-bell pb-0 md:text-xl note-badge " pBadge
                                [value]="this.unreadNotifications.length > 0 ? this.unreadNotifications.length : 0"></i>
                            <span class="block md:hidden font-medium">Notifications</span>
                        </div>
                    </a>

                    <div *ngIf="overlayActive" class="notifications-dropdown user-notifications-dropdown"
                        style="display: block;">
                        <div class="dropdown-caret">
                            <div class="caret-fill"></div>
                        </div>
                        <div class="dropdown-body p-2 surface-section">
                            <!-- <div class="dropdown-header clearfix">
                        <div class="float-left">
                            Notifications
                        </div>
                    </div> -->


                            <p-scrollPanel [style]="{width: '100%', height: '240px', overflow: 'auto'}"
                                styleClass="custombar1">
                                <ng-container *ngIf="(notifications.length === 0)">
                                    <div class="flex justify-content-center">
                                        <div class=" px-2 py-1 md:px-2 lg:px-4 text-center">
                                            <img src="/assets/icons/notifications/bell.png" alt="bell" width="100" />
                                            <div class="mt-3 font-bold text-xl">
                                                <span class="text-900">You don’t have any notifications yet</span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ul class="list-none p-0 m-0 inline-block w-100">

                                    <ng-container *ngFor="let notif of notifications; let i=index;">
                                        <app-notification-row-item [notification]="notif" [slimStyle]="true"
                                            [textSizeClass]="'font-xs'"
                                            (removeNotification)="removeNotification(notif.id)">
                                        </app-notification-row-item>
                                    </ng-container>
                                </ul>
                            </p-scrollPanel>
                            <hr>
                            <ul>
                                <li class="text-center text-primary text-xl py-1">
                                    <a routerLink="/notifications" class="view-all text-primary font-base">
                                        View All Notifications
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class=" border-top-1 border-indigo-400 lg:border-top-none lg:pl-3 lg:ml-2">
                    <a pRipple (click)="toggleUserMenuOverlay($event);" class="notaa2 p-ripple p-element flex  
             align-items-center flex px-3 p-3 md:pr-0 lg:my-2  font-medium border-round cursor-pointer transition-colors 
                      transition-duration-150">
                        <div class="block lg:flex">
                            <div class="text-indigo-50 font-medium mr-2">Hi,
                                {{generalService.getHeaderName(loggenInUser)}}
                            </div>
                        </div>
                        <span class="p-ink"></span>
                        <img src="{{userPhoto}}" (error)="generalService.setDefaultUserAvatar($event)"
                            class="profile-img pointer hvr-grow pointer-events-none lg:mr-0 user-icon border-circle"
                            style="max-height:32px;">
                    </a>


                    <div *ngIf="userOverlayActive"
                        class="notifications-dropdown dropdown_menu-6 user-notifications-dropdown user-menu"
                        style="display: block;">
                        <div class="dropdown-caret">
                            <div class="caret-fill"></div>
                        </div>
                        <div class="dropdown-body surface-section  max-w-13rem">

                            <ul class="user-dropdown">
                                <li class="hover:bg-indigo-600 border-round-lg" routerLink="/dashboard"> Dashboard
                                </li>
                                <li class="hover:bg-indigo-600" (click)="navigateToTeacherSection('info')"> Settings
                                </li>
                                <!-- <li class="hover:bg-indigo-600" (click)="navigateToTeacherSection('notifications')">
                                    Notifications
                                </li> -->
                                <li class="hover:bg-indigo-600 border-round-lg" (click)="logout()">
                                    <div>Logout</div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </li>
            </ul>
        </div>
    </div>
</div>



<div id="header" class="header hidden">
    <!-- <div class="cursor" onclick="document.querySelector('.dropdown').classList.toggle('active');"></div> -->
    <div class="header-content">
        <div class="header-content-logo" routerLink="/dashboard">
            <img src="/assets/icons/logo/mlt-logo-white.svg" class="logo-img hvr-grow">
        </div>
        <div class="header-content-tabs">
            <div class="tab">HOME</div>
            <div class="tab tab-m-l">COURSES</div>
            <div class="tab tab-m-l">ABOUT</div>
            <div class="tab tab-m-l">PRICING</div>
            <div class="tab tab-m-l">CONTACT</div>
            <div class="tab tab-m-l">MYLINGOBUSINESS</div>
        </div>
        <div id="profile-menu" class="header-content-profile">
            <div class="element hvr-float-shadow" [routerLink]="['/notifications']">
                <img src="/assets/icons/notification.svg" class="notification-icon">
                <div *ngIf="notifications.length > 0" class="count-indicator">{{notifications.length}}</div>
            </div>
            <!-- <div class="element hvr-float-shadow">
                <img src="/assets/icons/message.svg" class="message-icon">
                <div class="count-indicator">2</div>
            </div> -->
            <!-- (click) is for tablets -->
            <!-- (mouseenter)=dropdown() (mouseleave)=dropdown() -->
            <div id="header-username" class="element link-color" (click)=handleDropdown()>{{loggenInUser.firstName}}
                {{loggenInUser.lastName | slice:0:1}}.</div>
            <div class="element">
                <img src="{{userPhoto}}" class="profile-img pointer hvr-grow" (click)=handleDropdown()>
                <div id="profile-dropdown" class="dropdown" style="margin-top:46px">
                    <div class="drop-container cut blue-bg-gradient-1">
                        <div class="drop cut2"></div>
                    </div>
                    <div (click)="closeDropdown()" class="list">
                        <ul>
                            <!-- <li routerLink="/profile/info"> -->
                            <li (click)="navigateToTeacherSection('info')">
                                Dashboard
                            </li>
                            <li (click)="navigateToTeacherSection('settings')">
                                Settings
                            </li>
                            <!-- <li (click)="navigateToTeacherSection('notifications')">
                                Notifications
                            </li>
                            <li (click)="navigateToTeacherSection('rate-experience')">
                                Rate your Experience
                            </li> -->
                            <!-- <li [routerLink]="['/profile', { outlets: {'profile':['settings']}}]">
                                Settings
                            </li> -->
                            <li>
                                <div (click)="logout()">Logout</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>