
    <div [ngClass]="{'block-gradient-reverse': reverseGradient}">


        <div class="button-group absolute px-4 z-4" [ngStyle]="{'top': '2px'}">
            <button class="type-button" (click)="filterLessonsBy('today')" [ngClass]="{'active': getSelectedFilterType('today')}">Day</button>
            <span class="line"></span>
            <button class="type-button" (click)="filterLessonsBy('month')" [ngClass]="{'active': getSelectedFilterType('month')}">Month</button>
            <span class="line"></span>
            <button class="type-button" (click)="filterLessonsBy('upcoming')" [ngClass]="{'active': getSelectedFilterType('upcoming')}">Upcoming</button>
            <span class="line"></span>
            <button class="type-button" (click)="filterLessonsBy('past')" [ngClass]="{'active': getSelectedFilterType('past')}">Past</button>
        </div>
    </div>
    <ng-container slot="start">
        <div class="flex align-items-center justify-content-between py-2 px-2" [ngClass]="{'disabled-div': disableAddNewLessonButton}">
            <span class="text-primary">{{formattedDate}}</span>
            <button pButton (click)="onGoToBookingSystemClicked()" type="button" class="p-button-raised p-button-rounded plus-btn-circle md"
                icon="pi pi-plus"></button>
        </div>
    </ng-container>
    <p-scrollPanel #scrollPanel [style]="{width: '100%', height: scrollHeight+'px'}" styleClass="custombar1">
        <ng-container *ngIf="filteredLessons.length === 0">
            <div class="flex align-items-center justify-content-center flex-column px-2 py-2 md:px-3 lg:px-4 md:py-3 text-center">
                <img src="/assets/images/dashboard/fast-time.svg" alt="fast-time" width="90" />
                <div class="mt-3 font-bold">
                    <h5 class="text-900 font-semibold mt-1">You have no upcoming lessons yet!</h5>
                    <p class="font-medium mt-1" *ngIf="authService.isStudent">
                        Your teacher will contact you as soon as
                        possible to arrange your first lesson.
                    </p>
                </div>
            </div>
        </ng-container>
              <ng-container *ngFor="let lesson of filteredLessons; trackBy: trackById">
                <ng-container *ngIf="!generalService.isNullishObject(lesson)">
                <app-lesson-row-item [lesson]="lesson" 
                [classroom]="lesson.classroom ? lesson.classroom : classroom"
                [activeLevel]="classroom.activeLevel ? classroom.activeLevel : null"
                [title]="classroom.title ? classroom.title : classroom.language">
            </app-lesson-row-item>
                </ng-container>
            </ng-container>
    </p-scrollPanel>