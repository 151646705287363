import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/user.model';

@Component({
  selector: 'app-teacher-availability',
  templateUrl: './teacher-availability.component.html',
  styleUrls: ['./teacher-availability.component.scss']
})
export class TeacherAvailabilityComponent implements OnInit {
  @Input() user?: User

  constructor() { }

  ngOnInit(): void {
    
  }

}
