<div class="profile-info md:mt-3">
    <form [formGroup]="form" *ngIf="!isLoading">
      <div class="profile-info-section">
        <ng-container formArrayName="languagesSpeakForm">
          <div
            *ngFor="let _ of languagesSpeakForm.controls; index as i"
            style="position: relative;"
          >
            <!-- <img *ngIf="i===0" (click)="addLanguageSpeak()" src="/assets/icons/add.png" class="add hvr-grow add-btn"> -->
  
            <ng-container [formGroupName]="i">
              <div class="input-element-title"></div>
              <div class="input-fields">
                <div
                  class="input-field w-100 p-b-0 display-flex justify-space-between align-center"
                >
                  <div class="input-element-title" *ngIf="i===0">
                    Additional Languages you speak & levels *
                  </div>
                  <div
                    *ngIf="i===0"
                    class="cssCircle plusSign hvr-grow"
                    (click)="addLanguageSpeak()">
                    &#43;
                  </div>
                </div>
  
                <div
                  *ngIf="i!==0"
                  class="input-field w-100 p-b-0 display-flex justify-end align-center">
                  <div
                    class="cssCircle minusSign hvr-grow"
                    (click)="removeLanguageSpeak(i)">
                    &#8211;
                  </div>
                </div>
                <div class="input-field col--1of2">
                  <p-dropdown
                    autocomplete="off"
                    [options]="languages"
                    optionLabel="name"
                    filterBy="name"
                    (onChange)="handleSpeakLanguageChange($event, i)"
                    filter="true"
                    placeholder="Select Language"
                    formControlName="language"
                    styleClass="dropdown-blue m-t-0">
                    <ng-template let-language pTemplate="item">
                      <div class="country-item">
                        <div class="country-name">{{language.name}}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="input-field col--1of2">
                  <!-- {{filteredLanguageSpeakLevels[i] | json}} -->
                  <p-dropdown
                    autocomplete="off"
                    [options]="filteredLanguageSpeakLevels[i]"
                    [disabled]="disableSpeakLevelsDropdown[i]"
                    optionLabel="levelName"
                    filterBy="levelName"
                    [placeholder]="disableSpeakLevelsDropdown[i] ? 'Select a Language before Level' : 'Select Level'"
                    formControlName="level"
                    styleClass="dropdown-blue m-t-0">
                    <ng-template let-level pTemplate="item">
                      <div class="country-item">
                        <div class="country-name">{{level.levelName}}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <!-- <div *ngIf="i!==0" (click)="removeLanguageSpeak(i)" class="trash"><img src="/assets/icons/trash.png" style="width:30px"></div> -->
                </div>
              </div>
              <!-- <div class="input-fields text-right justify-end">
                <div class="input-field text-left">
                  <div class="">
                    <ejs-checkbox
                      cssClass="e-custom"
                      formControlName="hasBusinessLevel"
                      label="I can teach Business Level"
                    ></ejs-checkbox>
                  </div>
                </div>
              </div> -->
            </ng-container>
  
            <!-- <div *ngIf="languageSpeakError" class="input-error mt-2 font-base">* Language & Level fields are required</div> -->
          </div>
        </ng-container>
        <div *ngIf="ifFieldValid('languagesSpeakForm')" class="input-error mt-2 font-base">
          * Language & Level fields are required
        </div>
  
        <!-- <div class="form-array-title">Languages you Teach</div> -->
        <ng-container formArrayName="languagesTeachForm">
          <div
            class="m-t-15"
            *ngFor="let _ of languagesTeachForm.controls; index as i"
            style="position: relative;">
            <!-- <img *ngIf="i===0" (click)="addLanguageTeach()" src="/assets/icons/add.png" class="add hvr-grow add-btn"> -->
  
            <ng-container [formGroupName]="i">
              <div class="input-fields m-t-15">
                <div
                  class="input-field w-100 p-b-0 display-flex justify-space-between align-center">
                  <div class="input-element-title" *ngIf="i===0">
                    Languages you teach & levels *
                  </div>
                  <div
                    *ngIf="i===0"
                    class="cssCircle plusSign hvr-grow"
                    (click)="addLanguageTeach()">
                    &#43;
                  </div>
                </div>
  
                <div
                  *ngIf="i!==0"
                  class="input-field w-100 p-b-0 display-flex justify-end align-center">
                  <div
                    class="cssCircle minusSign hvr-grow"
                    (click)="removeLanguageTeach(i)">
                    &#8211;
                  </div>
                </div>
                <div class="input-field col--1of2">
                  <!-- <div class="input-element-title">* Language</div> -->
  
                  <p-dropdown
                    autocomplete="off"
                    [options]="languages"
                    optionLabel="name"
                    filterBy="name"
                    filter="true"
                    (onChange)="handleTeachLanguageChange($event, i)"
                    placeholder="Select Language"
                    formControlName="language"
                    styleClass="dropdown-blue m-t-0">
                    <ng-template let-language pTemplate="item">
                      <div class="country-item">
                        <div class="country-name">{{language.name}}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="input-field col--1of2">
                  <!-- <div class="input-element-title">* Level</div> -->
                  
                  <p-dropdown
                    autocomplete="off"
                    [options]="teachLevelOptions"
                    [disabled]="disableTeachLevelsDropdown[i]"
                    optionLabel="levelName"
                    filterBy="levelName"
                    [placeholder]="disableTeachLevelsDropdown[i] ? 'Select a Language before Level' : 'Select Level'"
                    formControlName="level"
                    styleClass="dropdown-blue m-t-0">
                    <ng-template let-level pTemplate="item">
                      <div class="country-item">
                        <div class="country-name">{{level.levelName}}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <!-- <div *ngIf="i!==0" (click)="removeLanguageTeach(i)" class="trash"><img src="/assets/icons/trash.png" style="width:30px"></div> -->
              </div>
              <div class="input-fields text-right justify-end">
                <div class="input-field text-left">
                  <p-checkbox 
                  formControlName="hasBusinessLevel" 
                  styleClass="primary-blue outlined" 
                  binary="true" 
                  label="I can teach Business Level"></p-checkbox>
                </div>
              </div>
            </ng-container>

            <!-- <div *ngIf="languageTeachError" class="input-error mt-2 font-base">* Language & Level fields are required</div> -->
          </div>
        </ng-container>
        <div *ngIf="ifFieldValid('languagesTeachForm')" class="input-error mt-2 font-base">
          * Language & Level fields are required
        </div>
  
        <div class="input-fields">
          <div class="input-field w-100">
            <div class="input-element-title">
               Describe your teaching methods *
            </div>
            <textarea
              class="input-element"
              formControlName="methods"
              type="text"
              rows="5"
            ></textarea>
            <div *ngIf="ifFieldValid('methods')" class="input-error mt-2 font-base">
              * Teaching Methods are required
            </div>
          </div>
          <div class="input-field">
            <div class="input-element-title">
               Years of experience as teacher *
            </div>
            <input
              class="input-element"
              formControlName="years"
              type="number"
              min="1"/>
            <div *ngIf="ifFieldValid('years')" class="input-error mt-2 font-base">
              * Years of experience field is required
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  
  <div class="btns md:ml-5 mt-4">
    <button pRipple class="rounded-blue-button transparent" (click)="goBack()">
      <img src="/assets/icons/arrow-left-blue.svg" /> Back
    </button>
    <button pRipple (click)="onSubmit()" class="rounded-blue-button">
      Next <img src="/assets/icons/arrow-right.svg" />
    </button>
    <!-- <div (click)="onSubmit()" class="light-purple-button">Next</div> -->
  </div>
  