<div class="profile-menu" #teacherMenu>
    <ng-container *ngIf="!showButtonsInsteadOfMenu; else templateName">
      <div class="second-section-teacher blue-bg-gradient-1" #teacherMenuItems>
        
        <div class="relative overflow-hidden">
          <a pRipple
            id="teacher-education-route"
            [class.item-active-teacher] = "routeIsActive('/teacher/info')"
            (click)="navigateToTeacherSection('info', 0, $event.target)"
            class="item inactive-item"
          >
            <div class="item-details uppercase">
              <img
                src="/assets/icons/teacher-application/general-info.svg"
                class="menu-img"
                alt="general-info-icon"
              />
              General Info
              <img
                [style.display]="currentStepsStatus.step1 ? 'block' : 'none'"
                src="/assets/icons/teacher-application/checked-teacher.svg"
                class="item-img"
              />
            </div>
          </a>
          <i class="backIconLeft pi pi-angle-right md:hidden" (click)="navigateToTeacherSection('education', 1, $event.target)" style="right:0px;">
          </i>
          
        </div>

        <div class="relative overflow-hidden">
          <i
            class="backIconLeft pi pi-angle-left md:hidden"
            (click)="navigateToTeacherSection('info', 0, $event.target)"
          ></i>
          <a pRipple
            id="teacher-education-route"
            [class.item-active-teacher] = "routeIsActive('/teacher/education')"
            (click)="navigateToTeacherSection('education', 1, $event.target)"
            class="item inactive-item"
            [ngClass]="{'disabled-link': !arePreviousStepsCompleted(this.currentStepsStatus, 'step2')}"
          >
            <div class="item-details uppercase">
              <img
                src="/assets/icons/teacher-application/education-icon.svg"
                class="menu-img"
                alt="education-icon"
              />
              Education
              <img
                [style.display]="currentStepsStatus.step2 ? 'block' : 'none'"
                src="/assets/icons/teacher-application/checked-teacher.svg"
                class="item-img"
              />
            </div>
          </a>
          <i class="backIconLeft pi pi-angle-right right-side md:hidden" (click)="navigateToTeacherSection('experience', 2, $event.target)"></i>
        </div>
        <div class="relative overflow-hidden">
          <i
            class="backIconLeft pi pi-angle-left md:hidden"
            (click)="navigateToTeacherSection('education', 1, $event.target)"></i>
  
          <a pRipple
            id="teacher-experience-route"
            (click)="navigateToTeacherSection('experience', 2, $event.target)"
            [class.item-active-teacher] = "routeIsActive('/teacher/experience')"
            class="item inactive-item"
            [ngClass]="{'disabled-link': !arePreviousStepsCompleted(this.currentStepsStatus, 'step3')}"
          >
            <div class="item-details uppercase">
              <img
                src="/assets/icons/teacher-application/exp-icon.svg"
                class="menu-img"
                alt="exp-icon"
              />
              Experience & Skills
              <img
                [style.display]="currentStepsStatus.step3 ? 'block' : 'none'"
                src="/assets/icons/teacher-application/checked-teacher.svg"
                class="item-img"
              />
            </div>
          </a>
          <i class="backIconLeft pi pi-angle-right right-side md:hidden" (click)="navigateToTeacherSection('resume', 3, $event.target)"></i>
        </div>
        <div class="relative overflow-hidden">
          <i
            class="backIconLeft pi pi-angle-left md:hidden"
            (click)="navigateToTeacherSection('experience', 2, $event.target)"
          ></i>
          <a pRipple
            id="teacher-resume-route"
            (click)="navigateToTeacherSection('resume', 3, $event.target)"
            [class.item-active-teacher] = "routeIsActive('/teacher/resume')"
            class="item inactive-item"
            [ngClass]="{'disabled-link': !arePreviousStepsCompleted(this.currentStepsStatus, 'step4')}"
          >
            <div class="item-details uppercase">
              <img
                src="/assets/icons/teacher-application/resume-icon.svg"
                class="menu-img"
                alt="exp-icon"
              />
              Resume
              <img
                [style.display]="currentStepsStatus.step4 ? 'block' : 'none'"
                src="/assets/icons/teacher-application/checked-teacher.svg"
                class="item-img"
              />
            </div>
          </a>
          <i class="backIconLeft pi pi-angle-right right-side md:hidden" (click)="navigateToTeacherSection('availability', 4, $event.target)"></i>
        </div>
        <div class="relative overflow-hidden">
          <i
            class="backIconLeft pi pi-angle-left md:hidden"
            (click)="navigateToTeacherSection('resume', 3, $event.target)"
          ></i>
          <a
            id="teacher-availability-route"
            (click)="navigateToTeacherSection('availability', 4, $event.target)"
            [class.item-active-teacher] = "routeIsActive('/teacher/availability')"
            [ngClass]="{'disabled-link': !arePreviousStepsCompleted(this.currentStepsStatus, 'step5')}"
            class="item inactive-item">
            <div class="item-details uppercase">
              <img
                src="/assets/icons/teacher-application/availability-icon.svg"
                class="menu-img"
                alt="exp-icon"
              />
              Availability
              <img
                [style.display]="currentStepsStatus.step5 ? 'block' : 'none'"
                src="/assets/icons/teacher-application/checked-teacher.svg"
                class="item-img" />
            </div>
          </a>
          <i class="backIconLeft pi pi-angle-right right-side md:hidden" (click)="navigateToTeacherSection('review', 5, $event.target)"></i>
        </div>
        <div class="relative overflow-hidden">
          <i
            class="backIconLeft pi pi-angle-left md:hidden"
            (click)="navigateToTeacherSection('availability', 4, $event.target)"
          ></i>
          <a pRipple
            id="teacher-review-route"
            (click)="navigateToTeacherSection('review', 5, $event.target)"
            [class.item-active-teacher] = "routeIsActive('/teacher/review')"
            [ngClass]="{'disabled-link': !arePreviousStepsCompleted(this.currentStepsStatus, 'step6')}"
            class="item inactive-item">
            <div class="item-details uppercase">
              <img
                src="/assets/icons/teacher-application/checked.svg"
                class="menu-img"
                alt="exp-icon"
              />
              Review & Submit
              <img
                [style.display]="currentStepsStatus.step6 ? 'block' : 'none'"
                src="/assets/icons/teacher-application/checked-teacher.svg"
                class="item-img"
              />
            </div>
          </a>
        </div>
      </div>
    </ng-container>
  
    <ng-template #templateName>
      <div class="flex flex-column gap-2">
        <button
          (click)="goToStatusPage()"
          pButton pRipple
          type="button"
          label="Your application status"
          icon="pi pi-arrow-left"
          class="p-button-raised w-100 p-button-lg side-btn light-blue-btn"></button>
        <button
          pButton pRipple
          (click)="confirmWithdraw()"
          type="button"
          label="Withdraw application"
          class="p-button-raised w-100 p-button-lg side-btn dark-blue-btn"></button>
      </div>
    </ng-template>
  </div>
  