import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'g-header-back-button',
  templateUrl: './g-header-back-button.component.html',
  styleUrls: ['./g-header-back-button.component.scss'],
})
export class GHeaderBackButtonComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  isMobile = false;
  @Input() label = 'Back'; 

  constructor(private generalService: GeneralService) { }

  ngOnInit(): void {
    this.subs.add(this.generalService.deviceKind.subscribe((res)=> {
      this.isMobile = res.w576down;
      console.log(this.isMobile );
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  buttonClicked() {

  }

}
