import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { take } from 'rxjs/operators';
import { Classroom } from 'src/app/core/models/classroom.model';
import { LibraryService } from 'src/app/core/services/library.service';
import { environment } from 'src/environments/environment';
import { LibraryFileActionsDialogComponent } from '../library-file-actions-dialog/library-file-actions-dialog.component';
import { Folder, LibraryFile } from 'src/app/core/models/library.model';
import { User } from 'src/app/core/models/user.model';
import { ToastService } from 'src/app/core/services/toast.service';
import { SubSink } from 'subsink';
import { AuthService } from 'src/app/core/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from 'src/app/core/services/general.service';

@Component({
  selector: 'app-library-file-row-item',
  templateUrl: './library-file-row-item.component.html',
  styleUrls: ['./library-file-row-item.component.scss'],
  providers: [DialogService]
})
export class LibraryFileRowItemComponent implements OnInit, OnDestroy {

  @Input() classroom = {} as Classroom;
  @Input() classroomFile: any = {} as any;
  @Input() folders: any;
  @Input() appendDialogActionsTo = 'allFiles';
  @Input() showGroupActions = false;
  @Input() hasLimitedOptions = false;
  @Input() availableActions: string[] = [] as string[];
  @Input() isSmallScreen = false;
  @Input() showSharedWithUsers = true;
  @Input() isInTab = false;
  @Input() classroomStudents: any;

  @Output() fileDeleted = new EventEmitter();
  @Output() libraryChecked = new EventEmitter();
  @Output() fileUnshared = new EventEmitter();
  libraryFiles: any;
  StudentIdsToSend: string[] = [];
  isDisabled = false;
  isDisabledWholeClass = false;
  fileURL: string | undefined;
  moveFileId = 0;
  moveFolderId = 0;
  isMoveRadioCheked = false;
  showRename: boolean = false;
  refs: DynamicDialogRef[] = [];
  private subs = new SubSink();
  checked: boolean = false;
  sharedWithUsers = [];
  menuItems: MenuItem[] = [];
  isLarge = false;
  hasMultipleCategoriesSlice = 1;
  constructor(
    public libraryService: LibraryService,
    private generalService: GeneralService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private authService: AuthService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    if (this.showSharedWithUsers) {
      this.getUsersLibraryHasBeenSharedWith();
    }
    this.subs.sink = this.libraryService.showGroupActions.subscribe(res => {
      if (!res) {
        this.checked = false;
      }
      // if (this.libraryService.isPreviewingFromChat()) {
      //   this.checked = true;
      // }
    });
    
    this.subs.add(this.generalService.deviceKind.pipe().subscribe(res => {
      if (res) {
        if (res.w768up) {
          this.isLarge = true;
          this.hasMultipleCategoriesSlice = 2;
        }
      }
    }));
    this.prepareMenuItemsForItem(this.classroomFile);

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onSelect(studentId: string) {
    this.isDisabled = false;
    if (this.StudentIdsToSend.indexOf(studentId) !== -1)
      this.StudentIdsToSend.splice(this.StudentIdsToSend.indexOf(studentId), 1);
    else
      this.StudentIdsToSend.push(studentId);

    if (this.isDisabledWholeClass && this.StudentIdsToSend.length === 0)
      this.isDisabledWholeClass = false;
    else
      this.isDisabledWholeClass = true;
  }

  showMoveFilePopup(fileId: any) {
    document.getElementById('lib-file-share-menu-' + fileId)!.style.display = 'none';
    document.getElementById('lib-file-move-menu-' + fileId)!.style.display = 'block';
    document.getElementById('lib-file-move-menu-' + fileId)!.style.top = '55%';
  }

  // openSharedByFileMenu(fileId: any) {
  //   alert(fileId);
  //   [].forEach.call(document.getElementsByClassName('lib-file-sharedby-menu'), (el: any) => {
  //     el.style.display = 'none';
  //   })
  //   setTimeout(() => {
  //     document.getElementById('lib-file-sharedby-menu-' + fileId)!.style.display = 'flex';
  //     document.getElementById('lib-file-sharedby-menu-' + fileId)!.style.top = (880 + document.getElementById('lib-file-sharedby-menu-' + fileId)!.offsetTop) + "px";
  //   }, 100);
  //   // this.showShare = true;
  // }

  // showSharedByMoveFilePopup(fileId: any) {
  //   document.getElementById('lib-file-sharedby-move-menu-' + fileId)!.style.display = 'block';
  //   document.getElementById('lib-file-sharedby-move-menu-' + fileId)!.style.top = '55%';
  // }

  getFileName(path: string) {
    if (path) {
      let n = path.lastIndexOf("/");
      if (n === -1)
        n = path.lastIndexOf("\\");
      let result = path.substring(n + 1);
      return result;
    }
    return path
  }

  prepareMenuItemsForItem(item: any) {
    let context: LibraryFile = item;

    const menuItems: MenuItem[] = [
      {
        label: 'View', icon: 'pi pi-fw pi-external-link',
        command: (event) => {
          this.openFileInNewTab(context.path);
        }
      },
      {
        label: 'Copy URL', icon: 'pi pi-fw pi-copy',
        command: (event) => {
          this.copyURL(context.path);
        }
      },
      {
        label: 'Download', icon: 'pi pi-fw pi-cloud-download',
        command: (event) => {
          this.downloadFile(context.path);
        }
      },
      {
        label: 'Share', icon: 'pi pi-fw pi-share-alt',
        command: (event) => {
          if (this.authService.isStudent) {
            this.shareWithTeacher(context);
          } else {
            const currentSelectedClassroom = this.libraryService.getCurrentSelectedClassroom()
            if (Object.keys(currentSelectedClassroom).length > 0) {
              const classroomStudents = this.libraryService.getCurrentSelectedClassroom().classroomStudents;
              // if only one student, share immediately
              if (classroomStudents.length === 1 && this.authService.isTeacher) {
                let send: any = {
                  fileIds: [context.fileId],
                  classroomId: this.libraryService.getCurrentSelectedClassroom().id,
                  studentIds: [classroomStudents[0].aspUserId]
                }
                this.sendShareToApi(send);
              }
            } else {
              this.openDialogWithComponent(LibraryFileActionsDialogComponent,
                {
                  action: 'shareWith',
                  dialogsubTitle: 'Share "' + this.getFileName(context.name) + '" with...',
                  dialogTitle: 'Share file with...',
                  libraryFile: context
                });
            }

            console.log(this.libraryService.getCurrentSelectedClassroom().classroomStudents);
            // this.openDialogWithComponent(LibraryFileActionsDialogComponent,
            //   {
            //     action: 'shareWith',
            //     dialogsubTitle: 'Share "' + this.getFileName(context.name) + '" with...',
            //     dialogTitle: 'Share file with...',
            //     libraryFile: context
            //   });
          }
        }
      },
      {
        label: 'Unshare', icon: 'pi pi-fw pi-share-alt',
        command: (event) => {
          let send: any = {
            fileId: this.classroomFile.fileId!,
            classroomId: this.libraryService.getCurrentSelectedClassroom().id,
            studentIds: [this.libraryService.getCurrentSelectedClassroom().teacher?.aspUserId]
          }
          if (this.authService.isStudent) {
            this.sendUnShareToApi(send);
          } else {
            
            this.sendUnShareToApi(send);
            // this.openDialogWithComponent(LibraryFileActionsDialogComponent,
            //   {
            //     action: 'unShareWith',
            //     dialogsubTitle: 'Share "' + this.getFileName(context.name) + '" with...',
            //     dialogTitle: 'Share file with...',
            //     libraryFile: context
            //   });
          }
        }
      },
      {
        label: 'Move', icon: 'pi pi-fw pi-folder-open',
        command: (event) => {
          this.openDialogWithComponent(LibraryFileActionsDialogComponent,
            {
              action: 'move',
              dialogsubTitle: 'Move "' + this.getFileName(context.name) + '" to folder...',
              dialogTitle: 'Move file to folder',
              libraryFile: context,
              folders: this.folders
            });
        }
      },
      {
        label: 'Edit', icon: 'pi pi-fw pi-file-edit',
        command: (event) => {
          this.generalService.openDialogWithComponent(this.dialogService, LibraryFileActionsDialogComponent, 440,
            {
              action: 'editFileLevelsCategories',
              dialogsubTitle: '',
              dialogTitle: 'Edit Library File',
              libraryFile: context,
            }, null, (result: any) => {
              console.log(result);
              if (result && result.action === 'editFileLevelsCategories') {
                if (!this.generalService.isNullishObject(result)) {

                  console.log(this.classroomFile);
                  console.log(result);
                  this.classroomFile = { ...result.result };
                  context = { ...result.result };
                }
              }
            });
        }
      },
      {
        label: 'Delete', icon: 'pi pi-fw pi-trash',
        command: (event) => {
          this.deleteFile(item.fileId);
        }
      }
    ];

    this.menuItems = menuItems.filter((menuItem: MenuItem) => {
        const menuLabel = menuItem.label as string;
        return this.availableActions.includes(menuLabel);
    });

  }

  showSharePopup(fileId: number) {
    document.getElementById('lib-file-move-menu-' + fileId)!.style.display = 'none';
    document.getElementById('lib-file-share-menu-' + fileId)!.style.display = 'block';
    document.getElementById('lib-file-share-menu-' + fileId)!.style.top = '25%';
  }

  rename(fileId: number, newName: string) {
    // const input = document.getElementById('fileName') as HTMLInputElement | null;
    // console.log(input?.value)
    // this.classroomFile.name = input!.value
    this.subs.add(this.libraryService.updateLibraryFileName(fileId, newName).pipe(take(1)).subscribe(res => {
      this.toastService.setShowToastmessage({
        severity: 'success',
        summary: '',
        detail: 'File name name has been updated.'
      });
      console.log(res)
      // this.showRename = !this.showRename
    }));
  }

  checkLibraryItem(el: any) {
    this.checked = !this.checked;
    // let bg = this.checked ? '#2d2a4b' : 'white';
    // el!.style.backgroundColor = bg;
    if (this.checked) {
      this.libraryService.checkedLibraries.push(this.classroomFile);
      this.libraryChecked.emit({ checked: true, file: this.classroomFile })
    } else {
      this.libraryService.checkedLibraries = this.libraryService.checkedLibraries.filter((el: LibraryFile) => {
        return !(el.path == this.classroomFile.path)
      });
      this.libraryChecked.emit({ checked: false, file: this.classroomFile })
    }
  }

  getUniqueSharedUsers(): string {
    // exclude from sharedWithUsers if not in classroomStudents
    const filteredUsers = this.sharedWithUsers.filter((user: User) => {
      return this.classroomStudents.find((student: User) => {
        return student.aspUserId === user.aspUserId;
      });
    });

    const uniqueNames = Object.values(filteredUsers.reduce((acc: any, user: any) => {
      if (!acc[user.aspUserId]) {
        acc[user.aspUserId] = user.firstName;
      }
      return acc;
    }, {}));
    return uniqueNames.join(', ');
  }

  private onWholeClassSelect() {
    if (this.isDisabled)
      this.isDisabled = false;
    else
      this.isDisabled = true;

    if (this.StudentIdsToSend.length === 0) {
      this.classroom.classroomStudents.forEach((student: { id: string; }) => {
        this.StudentIdsToSend.push(student.id);
      });
    }
    else
      this.StudentIdsToSend = [];
  }

  private moveFile(libraryFile: LibraryFile, moveFolder: Folder) {
    this.subs.add(this.libraryService.moveFileToFolder(libraryFile.fileId!, moveFolder.folderId!).subscribe((response) => {
      if (!this.generalService.isNullishObject(response)) {

        this.toastService.setShowToastmessage({
          severity: 'success',
          summary: '',
          detail: 'File moved successfully.'
        });
        this.libraryService.setUpdateFolderListener(libraryFile, moveFolder);
        this.libraryService.setUpdateListener(true);
      }
    }));
    this.ngOnInit();
  }

  private copyURL(filePath: any) {
    this.fileURL = environment.apiUrl + "/" + filePath;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.fileURL;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.setShowToastmessage({
      severity: 'success',
      summary: '',
      detail: 'File path copied successfully.'
    });
  }

  private deleteFile(fileId: any) {
    this.fileDeleted.emit(this.classroomFile);
    this.ngOnDestroy();
  }

  private getUsersLibraryHasBeenSharedWith() {
    if (this.classroomFile.fileId) {
      return this.subs.add(this.libraryService.getUsersLibraryHasBeenSharedWith(this.classroomFile.fileId!).pipe(take(1)).subscribe(res => {
        this.sharedWithUsers = res
      }))
    }
    return []
  }

  private shareWithTeacher(context: any) {
    let send: any = {
      fileIds: [context.fileId],
      classroomId: this.libraryService.getCurrentSelectedClassroom().id,
      studentIds: [this.libraryService.getCurrentSelectedClassroom().teacher?.aspUserId]
    }
    this.sendShareToApi(send);
  }

  private shareLibraryFile(map: any) {
    console.log(this.classroomFile);
    map.forEach((users: User[], classroomId: number) => {
      let ids: string[] = []
      let fileIds: number[] = []
      for (let user of users) {
        ids.push(user.aspUserId!)
      }
      fileIds.push(this.classroomFile.fileId!)
      let send: any = {
        fileIds: fileIds,
        classroomId: classroomId,
        studentIds: ids
      }
      this.sendShareToApi(send);
    });
  }

  private unShareLibraryFile(map: any) {
    console.log(this.classroomFile);
    map.forEach((users: User[], classroomId: number) => {
      let ids: string[] = []
      let fileIds: number[] = []
      for (let user of users) {
        ids.push(user.aspUserId!)
      }
      fileIds.push(this.classroomFile.fileId!)
      let send: any = {
        fileId: this.classroomFile.fileId!,
        classroomId: classroomId
      }
      this.sendUnShareToApi(send);
    });
  }

  openFileInNewTab(url: string): void {
    const fileURL = environment.apiUrl + "/" + url;
    window.open(fileURL, '_blank');
  }

  private sendShareToApi(sendParams: any) {
    this.subs.add(this.libraryService.shareFileWithClassUsers(sendParams).pipe(take(1)).subscribe(res => {

      console.log(this.classroom);
      this.toastService.setShowToastmessage({
        severity: 'success',
        summary: '',
        detail: 'Library file has been shared.'
      });
      if (this.authService.isTeacher) {
        this.libraryService.setCurrentSelectedClassroomId(parseInt(sendParams.classroomId));
      }
    }));
  }

  private sendUnShareToApi(sendParams: any) {
    console.log(this.classroom);
    this.subs.add(this.libraryService.unShareLibrary(sendParams).pipe(take(1)).subscribe(res => {
      this.toastService.setShowToastmessage({
        severity: 'success',
        summary: '',
        detail: 'Library file has been unshared.'
      });
      this.fileUnshared.emit(res);
      this.libraryService.setCurrentSelectedClassroomId(parseInt(sendParams.classroomId));
    }));
  }

  private openDialogWithComponent(component: any, dialogData?: any, width = 360) {

    this.generalService.openDialogWithComponent(this.dialogService, component, 400, dialogData, null, (result: any) => {
      if (result && result.action === 'shareWith') {
        this.shareLibraryFile(result.map.map);
      } else if (result && result.action === 'unShareWith') {
        this.unShareLibraryFile(result.map.map);
      } else if (result && result.action === 'move') {
        this.moveFile(result.libraryFile, result.folder);
      }
    });

    // this.refs.forEach(ref => ref.close());
    // this.refs = [];
    // const element = document.querySelector('#' + this.appendDialogActionsTo);
    // const rect = element!.getBoundingClientRect();
    // const centerX = rect.left + rect.width / 2 - width / 2;
    // const centerY = rect.top;
    // const dialogRef = (this.dialogService.open(component, {
    //   header: '',
    //   width: width + 'px',
    //   showHeader: false,
    //   dismissableMask: true,
    //   modal: true,
    //   contentStyle: {
    //     "max-width": "100%", "max-height": "400px", "overflow": "auto", "border-radius": "10px", "padding": "0px",
    //   },
    //   style: { 'left': `${centerX}px`, 'top': `${centerY}px`, 'position': `fixed` },
    //   baseZIndex: 10000,
    //   maskStyleClass: 'transparent-mask',
    //   data: { dialogData: dialogData },
    // }));
    // this.refs.push(dialogRef);
    // dialogRef.onClose.subscribe((data: any) => {
    //   console.log('Dialog closed with data:', data);
    //   if (data) {
    //     switch (data.action) {
    //       case 'rename':
    //         this.rename(data.libraryFile.fileId, data.libraryFile.name);
    //         break;
    //       case 'shareWith':
    //         this.shareLibraryFiles(data.map.map);
    //         break;
    //       case 'move':
    //         this.moveFile(data.libraryFile.fileId, data.folder.folderId);
    //         break;

    //       default:
    //         break;
    //     }
    //   }
    // });
  }
  
  private downloadFile(url: string): void {
    const fileURL = environment.apiUrl + "/" + url;
    this.subs.add(this.http.get(fileURL, { responseType: 'blob' })
      .subscribe((blob: Blob) => { // Specify the type of blob as Blob
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.getFileName(fileURL);
        link.click();
      }));
  }

}
