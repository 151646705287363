import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general.service';

@Component({
  selector: 'g-accordion',
  templateUrl: './g-accordion.component.html',
  styleUrls: ['./g-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GAccordionComponent implements OnInit {
  @Input() title: string = '';
  @Input() elementTitle: string = '';
  @Input() isOpen = true;
  @Input() backgroundImage: string = '';

  constructor(private generalService: GeneralService) { }

  ngOnInit(): void {
  }

  toggleSection(sectionElement: any, sectionArrowImgSrc: any) {
    this.generalService.toggleSection(sectionElement, sectionArrowImgSrc, true)
  }
}
