import { Injectable, inject, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { FieldValue, serverTimestamp } from 'firebase/firestore';
import * as moment from 'moment';
import { Observable, combineLatest, defer, forkJoin, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserService } from './user.service';
import { GeneralService } from './general.service';
import { environment } from 'src/environments/environment';
const BACKEND_URL = environment.apiUrl;
const BACKEND_LMS_URL = environment.apiUrl + "/LMS/";
import { getAuth, signInAnonymously } from "firebase/auth";
import { AngularFireAuth } from '@angular/fire/compat/auth';

interface Chat {
  createdAt: string;
  lastMessage: string;
  lastMessageAt: FieldValue;
  lastMessageFrom: string;
  lastMessageFromName: string;
  lastMessageFromImage: string;
  lastMessageIsFile: boolean;
  lastMessageDeleted: boolean;
  classroomId: string;
}

export interface ChatUserStatus {
  id?: string;
  status: 'online' | 'offline';
}


export interface Message {
  senderId: string;
  content: string;
  timestamp: FieldValue;
  readBy: string[];
  isFileFromLibrary: boolean;
  isUploadFile: boolean;
  readStatus: {}
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  userService = inject(UserService);
  generalService = inject(GeneralService);
  isChatEnabled = signal(environment.isChatEnabled);
  showUploadFile = signal(false);
  unreadCount = signal(0);
  currentRoom = signal({} as any);
  showBubbleChat = signal(false);
  private dbPath = '/chats';
  chatsRef: AngularFirestoreCollection<Chat>;
  private usersRef: AngularFirestoreCollection<ChatUserStatus> = this.db.collection('users');


  constructor(private db: AngularFirestore, private afAuth: AngularFireAuth, private http: HttpClient) {
    this.chatsRef = db.collection(this.dbPath);
    this.usersRef = db.collection('/users');
  }


  deleteChatFilesFromLibraryFolder(fileUrl: any): Observable<any> {
    console.log(fileUrl)
    return this.http.post<any>(BACKEND_LMS_URL + "DeleteChatFilesFromLibraryFolder?fileUrl=" + fileUrl, {})
  }

  // Sign in anonymously
  signInAnonymously(): Observable<any> {
    return new Observable(observer => {
      this.afAuth.signInAnonymously()
        .then(userCredential => {
          console.log('Signed in anonymously:', userCredential.user);
          observer.next(userCredential);
          observer.complete();
        })
        .catch(error => {
          console.error('Error signing in anonymously:', error);
          observer.error(error);
        });
    });
  }

  signInAnonym() {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  }

  getLastMessage(classroomId: string): Observable<Message> {
    const chatRef = this.chatsRef.doc<Message>(`${classroomId}/`);

    return chatRef.snapshotChanges().pipe(
      map(action => {
        const data = action.payload.data() as Message;
        const id = action.payload.id;
        return { id, ...data };
      })
    );
  }

  // Fetch all chats for a classroom
  getClassroomChats(classroomId: string): Observable<Chat[]> {
    const chatRef = this.chatsRef.doc(`${classroomId}`);
    return this.chatsRef.doc(classroomId).collection<Chat>('chats')
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Chat;
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      );
  }

  // Fetch messages for a specific chat room
  getChatMessages(classroomId: string): Observable<Message[]> {
    return this.db.collection<Message>(`${this.dbPath}/${classroomId}/messages`, ref =>
      ref.orderBy('timestamp', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Message;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  // Create a new chat room
  createChatRoom(classroomId: string, participants: string[]) {
    const chat: Chat = {
      classroomId: classroomId,
      createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
      lastMessage: '',
      lastMessageAt: serverTimestamp(),
      lastMessageFrom: '',
      lastMessageFromName: '',
      lastMessageFromImage: '',
      lastMessageIsFile: false,
      lastMessageDeleted: false
    };
    return this.chatsRef.add(chat);
  }

  // Add a new message to a chat room
  addMessage(classroomId: string, message: Message, user: any): Promise<void> {
    const chatRef = this.chatsRef.doc(`${classroomId}`);
    const messageRef = chatRef.collection('messages').doc();

    return chatRef.get().toPromise().then(snapshot => {
      const updateData: any = {
        createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        lastMessage: message.content,
        lastMessageAt: serverTimestamp(),
        classroomId: classroomId,
        lastMessageFrom: message.senderId,
        lastMessageFromName: this.generalService.getPersonFullName(user),
        lastMessageFromImage: this.userService.getUserPhoto(user?.avatarUrl),
        lastMessageIsFile: message.isFileFromLibrary || message.isUploadFile,
        lastMessageDeleted: false
      };

      if (!snapshot!.exists) {
        // Retrieve users in the chat, including teacher and students
        const usersInChat = [this.currentRoom().teacher, ...this.currentRoom().classroomStudents];
        const updatedUnreadCounts: any = {};
        usersInChat.forEach((chatUser: any) => {
          if (chatUser.aspUserId !== message.senderId) {
            updatedUnreadCounts[chatUser.aspUserId] = 1; // Initialize unread count to 1 for new chat
          }
        });
        updateData.unreadCounts = updatedUnreadCounts;

        return chatRef.set(updateData).then(() => {
          return messageRef.set(message);
        });
      } else {
        // Existing chat room, update last message and unread counts
        return this.updateLastMessage(chatRef, message, user).then(() => {
          return messageRef.set(message);
        });
      }
    });
  }

  editMessage(classroomId: string, messageId: string, updatedMessage: Message, user: any, updateLastMessage = false): Promise<void> {
    const chatRef = this.chatsRef.doc(`${classroomId}`);
    const messageRef = chatRef.collection('messages').doc(`${messageId}`);
    const messagesRef = chatRef.collection('messages', ref => ref.orderBy('timestamp', 'desc').limit(1));

    const lastMessageDoc = messagesRef.ref.doc();
    const lastMessageId = lastMessageDoc.id;
    console.log(lastMessageId);
    return messageRef.get().toPromise().then(snapshot => {
      if (snapshot!.exists) {


        return messageRef.update({
          ...updatedMessage,
        }).then(() => {
          if (updateLastMessage) {
            return this.updateLastMessage(chatRef, updatedMessage, user);
          }
          return Promise.resolve();
        });
      } else {
        throw new Error('Message does not exist');
      }
    });
  }

  private updateLastMessage(chatRef: AngularFirestoreDocument<any>, updatedMessage: Message, user: any, isLastMessageDeleted = false): Promise<void> {
    const updateData: any = {
      lastMessage: updatedMessage.content,
      lastMessageAt: serverTimestamp(),
      lastMessageFrom: updatedMessage.senderId,
      lastMessageFromImage: this.userService.getUserPhoto(user?.avatarUrl),
      lastMessageFromName: this.generalService.getPersonFullName(user),
      lastMessageIsFile: updatedMessage.isFileFromLibrary || updatedMessage.isUploadFile,
      lastMessageDeleted: isLastMessageDeleted,
    };

    return chatRef.get().toPromise().then(snapshot => {
      console.log('current room', this.currentRoom());
      const updatedUnreadCounts: any = {};
      // Retrieve users in the chat, including teacher and students
      const usersInChat = [this.currentRoom().teacher, ...this.currentRoom().classroomStudents];

      usersInChat.forEach((chatUser: any) => {
        if (chatUser.aspUserId !== updatedMessage.senderId) {
          updatedUnreadCounts[chatUser.aspUserId] = (snapshot!.exists ? (snapshot!.data() as any).unreadCounts?.[chatUser.aspUserId] || 0 : 0) + 1;
        }
      });

      updateData.unreadCounts = updatedUnreadCounts;
      return chatRef.update(updateData);
    });
  }

  getLastUnreadMessagesCountForUser(classroomId: string, userId: string): Observable<number> {
    const chatRef = this.chatsRef.doc(`${classroomId}`);

    return chatRef.valueChanges().pipe(
      map(data => {
        const unreadCounts = (data as any)?.unreadCounts;
        return unreadCounts ? unreadCounts[userId] || 0 : 0;
      })
    );
  }

  markLastMessagesAsRead(classroomId: string, userId: string): Promise<void> {
    const chatRef = this.chatsRef.doc(`${classroomId}`);
    return this.markLastMessagesAsReadInLastMessage(chatRef, userId);
  }

  markLastMessagesAsReadInLastMessage(chatRef: AngularFirestoreDocument<any>, userId: string): Promise<void> {
    return chatRef.get().toPromise().then(snapshot => {
      if (snapshot!.exists) {
        const data = snapshot!.data() as any;
        const updatedUnreadCounts = { ...data.unreadCounts, [userId]: 0 };

        return chatRef.update({ unreadCounts: updatedUnreadCounts });
      }
      return Promise.resolve(); // If the document doesn't exist, there's nothing to update.
    });
  }

  // Delete a specific message from a chat room
  deleteMessage(classroomId: string, message: any, user: any, updateLastMessage = false): Promise<void> {
    console.log(classroomId);
    console.log(message);
    const chatRef = this.chatsRef.doc(`${classroomId}`);
    const messageRef = chatRef.collection('messages').doc(message.id);
    if (updateLastMessage) {
      message.content = 'Message Deleted';
      return this.updateLastMessage(chatRef, message, user, true).then(() => {
        return messageRef.delete();
      });
    } else {
      return messageRef.delete();
    }
  }

  // Update a chat room document
  updateChatRoom(classroomId: string, data: Partial<Chat>): Promise<void> {
    return this.chatsRef.doc(classroomId).update(data);
  }

  // Delete a chat room document
  deleteChatRoom(classroomId: string): Promise<void> {
    return this.chatsRef.doc(classroomId).delete();
  }

  markMessagesAsRead(classroomId: string, userId: string): Promise<void> {
    return this.db.collection(`${this.dbPath}/${classroomId}/messages`).get().toPromise().then(querySnapshot => {
      const batch = this.db.firestore.batch();

      querySnapshot!.forEach(doc => {
        const data = doc.data();

        // Ensure readStatus is a valid map or initialize it
        const readStatusUpdate = (data as any).readStatus || {};

        // Add or update the userId in the readStatus map
        readStatusUpdate[userId] = true;

        // Update the document with the new or modified readStatus map
        batch.update(doc.ref, { readStatus: readStatusUpdate });
      });

      return batch.commit();
    });
  }

  // Mark messages as read
  markAllMessagesAsRead(classroomId: string): Promise<void> {
    return this.db.collection(`${this.dbPath}/${classroomId}/messages`).get().toPromise().then(querySnapshot => {
      const batch = this.db.firestore.batch();
      querySnapshot!.forEach(doc => {
        const messageData = doc.data() as Message;
        const updatedReadStatus = {};

        // Set readStatus for all users in the message's readStatus map to true
        for (const userId in messageData.readStatus) {
          if (messageData.readStatus.hasOwnProperty(userId)) {
            const a = `readStatus.${userId}`;
            (updatedReadStatus as any)[a] = true;
          }
        }

        batch.update(doc.ref, updatedReadStatus);
      });
      return batch.commit();
    });
  }

  // Get unread messages count for a user in a classroom
  getUnreadMessagesCount(classroomId: string, userId: string): Observable<number> {
    return this.db.collection<Message>(`${this.dbPath}/${classroomId}/messages`).snapshotChanges().pipe(
      map(actions => actions.filter(action => {
        const data = action.payload.doc.data() as Message;
        return !data.readStatus.hasOwnProperty(userId);
      }).length)
    );
  }


  // Get total unread messages count for a user across all classrooms
  getUnreadMessagesCountForUser(userId: string): Observable<number> {
    return this.chatsRef.snapshotChanges().pipe(
      switchMap(chatSnapshots => {
        const chatObservables = chatSnapshots.map(snapshot => {
          const chatId = snapshot.payload.doc.id;
          return this.getUnreadMessagesCount(chatId, userId);
        });
        return combineLatest(chatObservables);
      }),
      map(unreadCounts => unreadCounts.reduce((acc, count) => acc + count, 0))
    );
  }

  // Create Firebase Account and return as Observable
  createFirebaseUser(email: string, password: string): Observable<any> {
    return from(this.afAuth.createUserWithEmailAndPassword(email, password));
  }

  // Firebase Sign in as Observable
  signInFirebaseUser(email: string, password: string): Observable<any> {
    return from(this.afAuth.signInWithEmailAndPassword(email, password));
  }

  checkFirebaseUserExistsByEmail(email: string) {
    return this.afAuth.fetchSignInMethodsForEmail(email)
      .then((signInMethods) => {
        console.log('Sign-in methods:', signInMethods);
        return signInMethods.length > 0;
      })
      .catch((error) => {
        console.error('Error checking email:', error);
        return false;
      });
  }

  signOutFromFirebase() {
    this.afAuth.signOut().then(() => {
      console.log('User signed out');
    }).catch((error) => {
      console.error('Error signing out:', error);
    });
  }

  // Method to check if user is logged in
  isLoggedInInFireBase(): Observable<boolean> {
    return this.afAuth.authState.pipe(
      map(user => !!user) // map user object to boolean (true if user exists, false otherwise)
    );
  }

  // Or, to get the user object directly
  getFireBaseUser(): Observable<any> {
    return this.afAuth.authState;
  }

  setUserStatus(userId: string, status: 'online' | 'offline'): Promise<void> {
    const userRef = this.usersRef.doc(userId);
    return userRef.set({ status }, { merge: true });
  }

  getUserStatus(userId: string): Observable<'online' | 'offline'> {
    return this.usersRef.doc<ChatUserStatus>(userId).valueChanges().pipe(
      map(user => user?.status || 'offline')
    );
  }

  getOnlineUsers(): Observable<ChatUserStatus[]> {
    return this.db.collection<ChatUserStatus>('/users', ref => ref.where('status', '==', 'online'))
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as ChatUserStatus;
          const id = a.payload.doc.id;
          return { ...data, id }; // Corrected merge of id and data
        }))
      );
  }
}
