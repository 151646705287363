import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TeacherApplicationService } from 'src/app/core/services/teacher-application.service';
import { switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { TeacherApplicationStepsStatus } from 'src/app/core/models/teacher.model';

@Component({
  selector: 'app-teacher-menu',
  templateUrl: './teacher-menu.component.html',
  styleUrls: ['./teacher-menu.component.scss']
})
export class TeacherMenuComponent implements OnInit {
  private subs = new SubSink();
  private currentIndex: number = 0;
  public currentStepsStatus = {} as TeacherApplicationStepsStatus;
  public showButtonsInsteadOfMenu = false;
  @ViewChild('teacherMenuItems') public teacherMenuItems: ElementRef = {} as ElementRef;
  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private teacherService: TeacherApplicationService
  ) { }

  ngOnInit(): void {

    this.subs.add(this.teacherService.currentStepIndex.pipe(switchMap(ev => {
      this.currentIndex = ev;
      return this.teacherService.getTeacherApplicationStepsStatus();
    })
    ).subscribe(stepsStatus => {
      this.currentStepsStatus = stepsStatus;
      this.teacherService.setStepStatuses(stepsStatus);
      console.log(this.currentStepsStatus);
    }));

    this.route.queryParams.subscribe(
      (queryparams: Params) => {
        // check if has to show buttons instead of sidebar menu items
        if (queryparams && queryparams.buttons) {
          if (queryparams.buttons === '1') {
            this.showButtonsInsteadOfMenu = true;
          } else {
            this.showButtonsInsteadOfMenu = false;
          }
        }
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  /**
  Navigates to a specific teacher section and sets the step.
  @param {string} section The section to navigate to.
  @param {number} stepIndex The step index.
  @param {*} el The HTML element.
  @memberof TeacherApplicationMenuComponent
  */
  navigateToTeacherSection(section: string, stepIndex: number, el: any) {
    let stepsValid = this.teacherService.stepsValid;
    // if (stepsValid[this.currentIndex]) {
    this.teacherService.setStep(section);
    // this.teacherService.setSubmitOnMenuClickListener(true);
    this.router.navigateByUrl('/teacher/' + section, { replaceUrl: true });

  }

  /**
  Navigates to the previous page.
  @memberof TeacherApplicationMenuComponent
  */
  backClicked() {
    this.location.back();
  }

  /**
  Handles the go back icon event.
  @param {*} event The event.
  @memberof TeacherApplicationMenuComponent
  */
  goBackIcon(event: any) {
    console.log(event);
  }

  /**
  Confirms the teacher's intent to withdraw their application.
  @memberof TeacherApplicationMenuComponent
  */
  confirmWithdraw() {
    this.teacherService.setShowWithdrawConfirmationDialog(true);
    // this.goToStatusPage();
  }

  /**
   * Navigates to the status page of the teacher's application
   */
  goToStatusPage() {
    this.showButtonsInsteadOfMenu = false;
    this.router.navigateByUrl('/teacher/status', { replaceUrl: true });
  } 

  routeIsActive(routePath: string) {
    return this.router.url == routePath;
  }

  arePreviousStepsCompleted(steps: { [key: string]: boolean }, currentStep: string): boolean {
    const previousSteps = Object.keys(steps).slice(0, Object.keys(steps).indexOf(currentStep));
    return previousSteps.every(step => steps[step]);
  }

}
