import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationPost } from '../models/notification.model';
const BACKEND_URL = environment.apiUrl;
const BACKEND_LMS_URL = environment.apiUrl + "/LMS/";
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient
  ) { }

  create(notification: NotificationPost) {
    return this.http.post(BACKEND_LMS_URL + "CreateNotification", notification)
  }

  get() {
    return this.http.get(BACKEND_LMS_URL + "GetNotifications")
  }

  updateRead(id: any) {
    return this.http.post(BACKEND_LMS_URL + "UpdateNotificationRead?id=" + id, {})
  }

  markAsReadBulk(ids: any[]) {
    return this.http.post(BACKEND_LMS_URL + "UpdateNotificationsRead", ids)
  }

  delete(id: number) {
    return this.http.post(BACKEND_LMS_URL + "DeleteNotification?id=" + id, {});
  }
}
