<div class="block-header justify-content-center  lg:flex">
    <span class="block-title">
        <span class="text-0 text-center">Lesson History</span>
    </span>
</div>
<div class="surface-section px-2 lg:px-4 py-3 border-noround-top border-round-2xl">

    <div class="calendar-lessons">
        <div class="header">
            <app-classroom-level-filters (levelSelected)="onLevelChanged($event)" [size]="'xs'"
                [showFirstTitle]="false"></app-classroom-level-filters>
            <div class="flex align-items-center gap-2 justify-content-end">
                <app-classroom-status-filters [isLessonsFilter]="true" [switches]="switches"
                    (switchToggled)="toggleSwitch($event)"></app-classroom-status-filters>

            </div>

        </div>
        <div class="content">
            <p-scrollPanel *ngIf="lessons.length > 0" [style]="{height: wrapperScrollHeight}"
                styleClass="custombar1">
            <div *ngIf="showListView" id="lessons-list">
                    <ng-container *ngFor="let lesson of filteredLessons; let i = index">
                        <app-lesson-row-item [lesson]="lesson" [title]="''" [mode]="'full'"></app-lesson-row-item>
                    </ng-container>
            </div>
            </p-scrollPanel>

            <!-- <div *ngIf="showGridView" class="grid">
                <p-scrollPanel [style]="{width: '100%', height: wrapperScrollHeight + 'px'}" styleClass="custombar1">
                    <div class="grid">
                        <ng-container *ngFor="let lesson of lessons">
                            <div class="col-12 lg:col-3 md:col-4 sm:col-6">
                                <app-mini-lesson-info-card cardType="lesson"
                                    [lesson]="lesson"></app-mini-lesson-info-card>
                            </div>
                        </ng-container>
                    </div>
                </p-scrollPanel>
            </div> -->

            <div *ngIf="lessons.length == 0" class="no-data">
                <div class="title">
                    There are no lessons for this section.
                </div>
                <img src="/assets/icons/empty-classroom.png">
            </div>
            <!-- <div class="footer">
                <div class="btn hvr-grow" (click)="gridView()">
                    <img [src]="gridSrc" style="width:25px;">
                    <div class="m-t-10">
                        Grid view
                    </div>
                </div>
                <div class="btn hvr-grow" (click)="listView()">
                    <img [src]="listSrc" style="width:25px;">
                    <div class="m-t-10">
                        List view
                    </div>
                </div>

            </div> -->
        </div>
    </div>

</div>