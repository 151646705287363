import { Lesson } from "./lesson.model"
import { Package } from "./package.model"
import { User } from "./user.model"

export interface Classroom {
    id: string,
    title: string,
    status: Status,
    totalHours: number,
    hoursLeft: number,
    activeLevel: Level.A1 | Level.A2 | Level.B1 | Level.B2 | Level.C1 | Level.C2 | Level.NT21 | Level.NT22 | Level.TBD | Level.BS,
    users: User[],
    classroomStudents: User[],
    type: ClassroomType.PAID | ClassroomType.TRIAL,
    language?: string
    teacher?: User,
    activePackage?: Package,
    lessons?: Lesson[],
    packages?: Package[]
}

export enum ClassroomType {
    PAID = 'Paid',
    TRIAL = 'Trial'
}

export interface ClassroomStudentsResponse {
    errorMessage: string,
    usersDTOResult: User[]
}

export interface StatusClassroomsStats {
    total: number,
    inActive: number,
    onHold: number,
    ongoing: number,
    pending: number,
    expired: number,
}

export interface ClassroomSizesStats {
    total: number,
    group_of_one: number,
    group_of_two: number,
    group_of_three: number,
    group_of_four: number,
}

export interface LevelClassroomsStats {
    studentCount: number,
    hoursCount: number,
    level: Level,
    errorMessage: string
}

export interface CertificateClassroomsStats {

}

export interface PackageClassroomsStats {
    packageHours: number,
    packageCount: number,
    errorMessage: string
}

export enum Level {
    A1 = "A1",
    A2 = "A2",
    B1 = "B1",
    B2 = "B2",
    C1 = "C1",
    C2 = "C2",
    NT21 = "NT2.1", // DUTCH
    NT22 = "NT2.2", // DUTCH
    N5 = "N5", // JAPANESE
    N4 = "N4", // JAPANESE
    N3 = "N3", // JAPANESE
    N2 = "N2", // JAPANESE
    N1 = "N1", // JAPANESE
    HSK1 = "HSK1", // CHINESE
    HSK2 = "HSK2", // CHINESE
    HSK3 = "HSK3", // CHINESE
    HSK4 = "HSK4", // CHINESE
    HSK5 = "HSK5", // CHINESE
    HSK6 = "HSK6", // CHINESE
    TOPIK1 = "TOPIK1", // KOREAN
    TOPIK2 = "TOPIK2", // KOREAN
    TOPIK3 = "TOPIK3", // KOREAN
    TOPIK4 = "TOPIK4", // KOREAN
    TOPIK5 = "TOPIK5", // KOREAN
    TOPIK6 = "TOPIK6", // KOREAN
    BS = "BS", // Business
    TBD = "TBD", // To Be Determined
    ALL = "All"
}



export enum Status {
    EXPIRED = "Expired",
    INACTIVE = "Inactive",
    ON_GOING = "Ongoing",
    ON_HOLD = "On Hold",
    PENDING = "Pending",
    TRIAL = "Trial",
    COMPLETED = "Completed",
    COMPLETED_TRIAL = "Trial Completed",
    PENDING_TRIAL = "Trial Pending",
    ARRANGED_TRIAL = "Trial Arranged",
    DISMISSED_TRIAL = "Trial Dismissed",
    ONGOING_TRIAL = "Trial Ongoing",
    ARRANGED_NO_SHOW = "Trial No Show",
    DISMISSED = "Dismissed",
    ALL = "All"
}

export interface ClassroomsLevelFilter {
    level: string,
    domLevelID: string
}

export interface ClassroomsStatusFilter {
    status: string,
    domStatusID: string
}

export interface StudentHoursStatsOnEachLevelStats {
    a1: {
        students: number,
        hours: number
    },
    a2: {
        students: number,
        hours: number
    },
    b1: {
        students: number,
        hours: number
    },
    b2: {
        students: number,
        hours: number
    },
    c1: {
        students: number,
        hours: number
    },
    c2: {
        students: number,
        hours: number
    }
}

export interface ClassroomTeacherRating {
    classroomId: string,
    teacherId: string,
    rateText: string,
    level: Level,
    createdAt: Date
    overall?: RateValue, // TODO THIS IS NOT REQUIRED
    organized: RateValue | null,
    discipline: RateValue | null,
    productive: RateValue | null,
    assignments: RateValue | null,
    learnSubject: RateValue | null,
    explanation: RateValue | null,
    performanceEvaluation: RateValue | null,
    variety: RateValue | null,
    area: RateValue | null,
    challenges: RateValue | null,
    free: RateValue | null,
    encouragement: RateValue | null,
    enthusiastic: RateValue | null,
}

export interface TrialTeacherRating {
    classroomId: string | null,
    teacherId: string | null,
    level: Level | RateValue,
    friendly: RateValue | null,
    polite: RateValue | null,
    focused: RateValue | null,
    explain: RateValue | null,
    preferences: RateValue | null,
    interest: RateValue | null,
    efficiently: RateValue | null,
    evaluate: RateValue | null,
    schedule: RateValue | null,
    [key: string]: RateValue | string | null,
    
}

export interface RequestNewTeacher {
    studentId: string,
    teacherId: string,
    reason: string
}

export enum RateValue {
    VERY_BAD = "Very Bad",
    BAD = "Bad",
    NEUTRAL = "Neutral",
    GOOD = "Good",
    VERY_GOOD = "Very Good"
}