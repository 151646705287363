import { Directive, HostListener, Input, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: '[disableOnClick]'
})
export class DisableOnClickDirective {
  @Input() disableTime!: number; // Disable time in milliseconds
  @Input() disableFlag = false; // Flag to indicate whether the element is disabled

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('click', ['$event']) onClick(event: Event) {
    event.preventDefault();

    if (!this.disableFlag) {
      this.disableElement();
      if (this.disableTime) {
        setTimeout(() => {
          this.enableElement();
        }, this.disableTime);
      }
    }
  }

  private disableElement() {
    this.disableFlag = true;
    this.renderer.setProperty(this.el.nativeElement, 'disabled', true);
    this.renderer.addClass(this.el.nativeElement, 'disabled');
    this.renderer.addClass(this.el.nativeElement, 'disabled-div');
  }

  private enableElement() {
    this.disableFlag = false;
    this.renderer.setProperty(this.el.nativeElement, 'disabled', false);
    this.renderer.removeClass(this.el.nativeElement, 'disabled');
    this.renderer.removeClass(this.el.nativeElement, 'disabled-div');
  }
}
