<div class="md:mt-3"></div>
<div class="profile-info">
    <ng-container *ngIf="!isLoading">
    <form [formGroup]="form">
        <div class="profile-info-section">
            <!-- <div class="title">
                <div class="title-text">* Degrees / Diplomas</div>
                <img (click)="addEducation()" src="/assets/icons/add.png" class="add hvr-grow" style="width:20px">
                <div (click)="addEducation()" class="link-purple-color" style="margin-left:10px; font-weight:bold;">Add
                    New</div>
            </div> -->
            <div>
                <ng-container formArrayName="education">
                    <div *ngFor="let _ of education.controls; index as i" style="position: relative;">
                        <ng-container [formGroupName]="i">

                            <div class="input-fields" [ngClass]="{'more-height-div': educationfilePaths(i).length > 0}">
                                <div class="input-field col--1of3">
                                    <div class="input-element-title">Degrees / Diplomas *
                                        <div *ngIf="i === 0" class="hvr-grow">
                                            <button pButton type="button" (click)="addEducation()" class="p-button-raised p-button-rounded plus-btn-circle" icon="pi pi-plus"></button>
                                        </div>
                                        <div *ngIf="i > 0" class="hvr-grow">
                                            <button pButton type="button" (click)="removeEducation(i)" class="p-button-raised p-button-rounded minus-btn-circle" icon="pi pi-minus"></button>
                                        </div>
                                    </div>
                                    <input class="input-element" formControlName="name" type="text"
                                        placeholder="e.g. Bachelor of Spanish">
                                    <div *ngIf="ifFieldValid(i)" class="input-error">* Degrees / Diplomas is required
                                    </div>
                                </div>

                                <div class="input-field col--1of3">
                                    <div class="input-element-title">Institute *</div>
                                    <input class="input-element" formControlName="institute" type="text"
                                        placeholder="e.g. Aristotle University">
                                    <div *ngIf="ifFieldValid(i)" class="input-error">Institute is required
                                    </div>
                                </div>
                        <div class="input-field col--1of3" formArrayName="fileUploads">
                            <div class="input-element-title">Upload Degree/Diploma</div>
                            <div *ngIf="educationfilePaths(i).controls.length === 0; else tplwithFilePaths">
                                <div (click)="openFileUploadDialog(i)" class="rounded-blue-button upload-button transparent">Upload File
                                    <img (click)="hideFileUpload(i)" src="/assets/icons/cloud-icon.svg" alt="cloud-icon" />
                                </div>
                                <div #fileUpload *ngIf="educationfilePaths(i).controls.length === 0 && showFileUpload[i]" class="modal"
                                    #taskFiles>
                                    <div class="popup-title">
                                        <div>Add files</div>
                                        <img (click)="hideFileUpload(i)" src="/assets/icons/close.png" class="close-img close-img-abs hvr-glow">
                                    </div>
                                    <div>
                                        Upload Degree/Diploma
                                    </div>
                                    <app-upload-files [dndUI]="true" [multiple]="false" title="Degree/Diploma Paper"
                                    [isTeacherApplication]="true"
                                        [postUrl]="UPLOAD_TEACHER_APPLICATION_FILES_URL" (onUploadFinished)="uploadFinished($event, i)">
                                    </app-upload-files>
                                </div>
                            </div>
                            <ng-template #tplwithFilePaths>
                                <div *ngFor="let skill of educationfilePaths(i).controls; let skillIndex=index">
                                    <div [formGroupName]="skillIndex">
                                        <div *ngIf="form.value.education[i].fileUploads.length === 1" (click)="openFileUploadDialog(i)"
                                            class="rounded-blue-button upload-button transparent">Upload File
                                            <img (click)="hideFileUpload(i)" src="/assets/icons/cloud-icon.svg" alt="cloud-icon" />
                                        </div>
                                        <!-- *ngIf="form.value.education[i].filePath!==''"  -->
                                        <div *ngIf="form.value.education[i].fileUploads[skillIndex].length !== 1" class="file-path">
                                            <img src="/assets/icons/education-degree-white.svg" class="close-img close-img-abs hvr-glow">
                                            <span class="file-path-text">{{getFileName(form.value.education[i].fileUploads[skillIndex].filePath)}}</span>
                                            <img src="/assets/icons/trash-white.svg" alt="trash-white.svg" class="delete-file-icon-img hvr-glow"
                                                (click)="removeEducationFileField(i, skillIndex, getFileName(form.value.education[i].fileUploads[skillIndex].filePath))">
                                        </div>
                                        <div #fileUpload *ngIf="showFileUpload[skillIndex]" class="modal" #taskFiles>
                                            <div class="popup-title">
                                                <div>Add files</div>
                                                <img (click)="hideFileUpload(skillIndex)" src="/assets/icons/close.png"
                                                    class="close-img close-img-abs hvr-glow">
                                            </div>
                                            <div>
                                                Upload Degree/Diploma
                                            </div>
                                            <app-upload-files [dndUI]="true" [multiple]="false" title="Degree/Diploma Paper"
                                                [postUrl]="UPLOAD_TEACHER_APPLICATION_FILES_URL"
                                                (onUploadFinished)="uploadFinished($event, skillIndex)">
                                            </app-upload-files>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        </div>
                        <!-- <div *ngIf="ifFieldValid(i)" class="input-error">* Input field and file upload are required
                        </div> -->
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
    </ng-container>
</div>
<div class="btns md:ml-5 mt-4">
    <button pRipple class="rounded-blue-button transparent" (click)="goBack()"> <img src="/assets/icons/arrow-left-blue.svg" />
        Back</button>
    <button pRipple (click)="onSubmit()" class="rounded-blue-button">Next <img src="/assets/icons/arrow-right.svg" /></button>
    <!-- <div (click)="onSubmit()" class="light-purple-button">Next</div> -->
</div>