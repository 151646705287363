<div class="modal">
    <div class="classrooms-kinds">
        <div id="all-levels" level="All" class="level classrooms-kinds-active" style="margin-left:0"
            (click)="filterLevel($event.target)">All</div>
        <div id="a1-level" level="A1" class="level" (click)="filterLevel($event.target)">A1</div>
        <div id="a2-level" level="A2" class="level" (click)="filterLevel($event.target)">A2</div>
    </div>
    <div class="classrooms-kinds">
        <div id="b1-level" level="B1" class="level" style="margin-left:0" (click)="filterLevel($event.target)">B1</div>
        <div id="b2-level" level="B2" class="level" (click)="filterLevel($event.target)">B2</div>
        <div id="c1-level" level="C1" class="level" (click)="filterLevel($event.target)">C1</div>
        <div id="c2-level" level="C2" class="level" (click)="filterLevel($event.target)">C2</div>
    </div>
    <div class="seperator"></div>
    <div class="classrooms-kinds">
        <div id="ongoing" status="Ongoing" class="status" style="margin-left:0" (click)="filterStatus($event.target)">On
            Going</div>
        <div id="pending" status="Pending" class="status" (click)="filterStatus($event.target)">Pending</div>
        <div id="expired" status="Expired" class="status" (click)="filterStatus($event.target)">Expired</div>
    </div>
    <div class="classrooms-kinds">
        <div id="onhold" status="On Hold" class="status" style="margin-left:0" (click)="filterStatus($event.target)">On
            Hold</div>
        <div id="dismissed" status="Inactive" class="status" (click)="filterStatus($event.target)">Inactive</div>
        <div id="all-kinds" status="All" class="status classrooms-status-active" (click)="filterStatus($event.target)">All
        </div>
    </div>
    <div class="seperator"></div>
    <div class="btns">
        <div class="white-button" style="border:0px;" (click)="closeMe()">close</div>
        <div class="white-button" (click)="confirm()">confirm</div>
    </div>
</div>