

<div class="w-full h-full block-gradient">

<div class="abs-centered justify-content-center align-items-center flex flex-column">
  <div class="not-found-wrapper margin-auto text-center">
    <div #notFoundEl></div>

    <button pripple="" [routerLink]="'/dashboard/'" class="p-ripple p-element p-button p-component" type="button">
      <span class="p-button-label ng-star-inserted"><i class="pi pi-home"></i> Go back to home</span>
    </button>
  </div>
</div>
</div>