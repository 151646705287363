import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TeacherApplicationDeactivateGuard } from 'src/app/core/guards/teacher-application.guard';
import { TopMenuContainerLayoutComponent } from 'src/app/shared/layout/top-menu-container-layout/top-menu-container-layout.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { EducationComponent } from './education/education.component';
import { ExperienceComponent } from './experience/experience.component';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { ResumeComponent } from './resume/resume.component';
import { ReviewComponent } from './review/review.component';
import { StatusComponent } from './status/status.component';
import { TeacherApplicationComponent } from './teacher-application.component';
import { TeacherAvailabilityComponent } from './teacher-availability/teacher-availability.component';


const routes: Routes = [
    { path: '', component: TopMenuContainerLayoutComponent,
    
    children: [
      {
        path: '',
        component: TeacherApplicationComponent,
        
    children: [
      {
        path: 'info',
        component: GeneralInfoComponent,
        canDeactivate: [TeacherApplicationDeactivateGuard],
      },
        { path: 'contact', component: ContactInfoComponent, },
        { path: 'education', component: EducationComponent,
        canDeactivate: [TeacherApplicationDeactivateGuard] },
        { path: 'experience', component: ExperienceComponent,
        canDeactivate: [TeacherApplicationDeactivateGuard], },
        { path: 'resume', component: ResumeComponent,
        canDeactivate: [TeacherApplicationDeactivateGuard] },
        { path: 'availability', component: TeacherAvailabilityComponent, },
        { path: 'review', component: ReviewComponent, },
        { path: 'status', component: StatusComponent, },
    ]
      },
    ]
  },
];
// const routes: Routes = [
//     { path: 'info', component: GeneralInfoComponent, },
//     { path: 'contact', component: ContactInfoComponent, },
//     { path: 'education', component: EducationComponent, },
//     { path: 'experience', component: ExperienceComponent, },
//     { path: 'resume', component: ResumeComponent, },
//     { path: 'availability', component: TeacherAvailabilityComponent, },
//     { path: 'review', component: ReviewComponent, },
//     { path: 'status', component: StatusComponent, },
// ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TeacherApplicationRoutingModule { }