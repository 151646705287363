<div #createGoal id="create-goal-2" class="create-goal">
    <app-circle-line *ngIf="currentStep<6" [circles]="stepCircleLines"></app-circle-line>

    <div class="choose-level">
        <div class="choose-level-title">
            <span *ngIf="currentStep==0">
                <div *ngIf="goals.length > 0">
                    <!-- Click next to edit goals to choose goal to edit -->
                    <div *ngIf="goals.length > 0" style="display:flex; justify-content: center;">
                        <p-dropdown placeholder="Choose Goal" [options]="goals" (onChange)="changeIndex($event)" optionLabel="language">
                            <ng-template let-goal pTemplate="item">
                                <div class="country-item">
                                    <div class="country-name">{{goal.language}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <div *ngIf="showConfirm" style="display:flex; align-items: center; margin-left:15px">
                            Save current edit?
                            <div class="light-purple-button" style="margin-left:15px" (click)="answerConfirm(false)">
                                no
                            </div>
                            <div class="main-color-button" style="margin-left:15px" (click)="answerConfirm(true)">
                                yes
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="goals.length == 0">
                    Choose classroom you want to create a goal for
                </div>
            </span>
            <span *ngIf="currentStep==1">
                Choose level desired to reach
            </span>
            <span *ngIf="currentStep==2">
                When would you like to achieve your goal?
            </span>
            <span *ngIf="currentStep==3">
                How many lesson hours would you like to take per week?
            </span>
            <span *ngIf="currentStep==4">
                Why do you want to study?
            </span>
            <span *ngIf="currentStep==5">
                Which areas would you like to improve?
            </span>
        </div>
        <div *ngIf="currentStep==0" class="desired-date">
            <div *ngIf="goals.length == 0">
                <p-dropdown placeholder="Choose Language" [options]="userClassrooms"
                    (onChange)="chooseClassroom($event)" optionLabel="language">
                    <ng-template let-classroom pTemplate="item">
                        <div class="country-item">
                            <div class="country-name">{{classroom.language}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>

        <app-circle-line *ngIf="currentStep==1" (circleChoice)="desiredLevelChoice($event)" [allowHoverMain]="true"
            [circles]="currentLevelCircleLines"></app-circle-line>

        <div *ngIf="currentStep==2" class="desired-date">
            <p-dropdown placeholder="Choose Month" [options]="months" [(ngModel)]="monthSelected"
                (onChange)="chooseMonth($event)" optionLabel="month">
                <ng-template let-month pTemplate="item">
                    <div class="country-item">
                        <div class="country-name">{{month.month}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <p-dropdown placeholder="Choose Year" [options]="years" [(ngModel)]="yearChosen"
                (onChange)="chooseYear($event)">
            </p-dropdown>
        </div>

        <div *ngIf="currentStep==3" class="desired-date">
            <p-dropdown placeholder="Choose Hours" [options]="hours" [(ngModel)]="hoursWeeklyChosen"
                (onChange)="chooseHours($event)">
            </p-dropdown>
        </div>

        <div *ngIf="currentStep==4" class="choose-reason">
            <div *ngFor="let reason of reasons">
                <ejs-checkbox (change)="chooseReason(reason)" [checked]="getIsReasonChecked(reason)"></ejs-checkbox>
                {{reason}}
            </div>
        </div>

        <div *ngIf="currentStep==5" class="choose-area">
            <!-- <div class="choose-area"> -->
            <div *ngFor="let area of areas">
                <ejs-checkbox (change)="chooseArea(area)" [checked]="getIsAreaChecked(area)"></ejs-checkbox> {{area}}
            </div>
        </div>
    </div>

    <div class="btns" *ngIf="currentStep < 6">
        <div [ngClass]="currentStep>0? 'link-main-color': 'inactive'" (click)="changeStep(false)">
            BACK
        </div>
        <div *ngIf="currentStep<5" class='link-main-color' (click)="changeStep(true)">
            NEXT
        </div>
        <div *ngIf="currentStep==5" class='light-purple-button' (click)="submit()">
            SUBMIT
        </div>
    </div>

    <div *ngIf="currentStep==6" class="end">
        <img src="/assets/icons/big-check.svg">
        <div class="end-msg">
            All set! Start Learning!
        </div>
    </div>
</div>