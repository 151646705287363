import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LeftMenuItem } from '../models/general.model';
import { AuthService } from './auth.service';
import { StudentTrialService } from './student-trial.service';
import { Classroom, ClassroomType } from '../models/classroom.model';

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  menuItems: LeftMenuItem[] = [
    {
      title: "Dashboard",
      url: "/dashboard",
      icon: "/assets/icons/dashboard-icon.svg",
      lottieIcon: "https://assets8.lottiefiles.com/packages/lf20_h3TUuoD3DG.json",
      disabled: false,
      role: ["Student", "Teacher"],
      disableOnStudentTrial: false,
    },
    {
      title: "Classrooms",
      url: "/dashboard/classrooms",
      icon: "/assets/icons/classroom.svg",
      lottieIcon: "https://assets8.lottiefiles.com/packages/lf20_mflokyg2.json",
      disabled: false,
      role: ["Student", "Teacher"],
      scale: 0.8,
      disableOnStudentTrial: true,
    },
    {
      title: "Trials",
      url: "/dashboard/classrooms/trials/page",
      icon: "/assets/icons/classroom.svg",
      lottieIcon: "https://assets4.lottiefiles.com/packages/lf20_voBLTvIbhG.json",
      disabled: false,
      role: ["Teacher"],
      scale: 0.8,
      disableOnStudentTrial: true,
    },
    // { title: 'Trials', url: '/dashboard/trials', icon: '/assets/icons/trial.svg', role:  ['Teacher']},
    {
      title: "Calendar",
      url: "/dashboard/calendar",
      icon: "/assets/icons/calendar.svg",
      lottieIcon: "https://assets8.lottiefiles.com/packages/lf20_G2wmKm6yAG.json",
      disabled: false,
      role: ["Student", "Teacher"],
      scale: 2.3,
      disableOnStudentTrial: true,
    },
    {
      title: "Library",
      url: "/dashboard/library",
      icon: "/assets/icons/library.svg",
      lottieIcon: "https://assets4.lottiefiles.com/packages/lf20_e6hgcbdn.json",
      disabled: false,
      scale: 2.1,
      role: ["Student", "Teacher"],
      disableOnStudentTrial: true,
    },
    // { title: 'Notes', url: '/dashboard/notes', icon: '/assets/icons/notes.svg', role: ['Student', 'Teacher']},
    {
      title: "Notes",
      url: "/dashboard/notes",
      icon: "/assets/icons/library.svg",
      lottieIcon: "https://assets10.lottiefiles.com/private_files/lf30_cldvedro.json",
      disabled: true,
      role: ["Teacher", "Student"],
      disableOnStudentTrial: true,
    },
    {
      title: "AI Chat",
      url: "/dashboard/ai-chat",
      icon: "/assets/icons/certificate.svg",
      lottieIcon: "/assets/icons/lottie/ai-chat-2.json",
      disabled: true,
      scale: 1.3,
      role: ["Student"],
      disableOnStudentTrial: true,
    },
    {
      title: "Homework",
      url: "/dashboard/homework",
      icon: "/assets/icons/homework.svg",
      lottieIcon: "https://assets8.lottiefiles.com/packages/lf20_hylaaytn.json",
      disabled: true,
      role: ["Student", "Teacher"],
      disableOnStudentTrial: true,
    },
    {
      title: "Certificates",
      url: "/dashboard/certificates",
      icon: "/assets/icons/certificate.svg",
      lottieIcon: "/assets/icons/lottie/certificates.json",
      disabled: true,
      role: ["Student"],
      disableOnStudentTrial: true,
    },
    // {
    //   title: "Guides",
    //   url: "/dashboard/guides",
    //   icon: "/assets/icons/certificate.svg",
    //   lottieIcon: "/assets/icons/lottie/guides.json",
    //   disabled: false,
    //   role: ["Student", "Teacher"],
    //   disableOnStudentTrial: true,
    // },
    {
      title: "Packages",
      url: "/dashboard/my-packages",
      icon: "/assets/icons/certificate.svg",
      lottieIcon: "/assets/icons/lottie/packages.json",
      disabled: false,
      scale: 1.5,
      role: ["Student"],
      disableOnStudentTrial: false,
    },
  ];

  
  extraMobileMenuItems: LeftMenuItem[] = [
    {
      title: "Settings",
      url: "/user-profile/info",
      icon: "/assets/icons/dashboard-icon.svg",
      lottieIcon: "https://assets8.lottiefiles.com/packages/lf20_wcdzldes.json",
      disabled: false,
      role: ["Student", "Teacher"],
      disableOnStudentTrial: false,
    },
    // {
    //   title: "Notifications",
    //   url: "/notifications",
    //   icon: "/assets/icons/dashboard-icon.svg",
    //   lottieIcon: "https://assets8.lottiefiles.com/packages/lf20_daV8h3.json",
    //   disabled: false,
    //   role: ["Student", "Teacher"],
    //   disableOnStudentTrial: false,
    // },
    {
      title: "Logout",
      url: "",
      icon: "/assets/icons/dashboard-icon.svg",
      lottieIcon: "https://assets9.lottiefiles.com/datafiles/xjFX0JCCOm2Lhp1/data.json",
      disabled: false,
      role: ["Student", "Teacher"],
      disableOnStudentTrial: false,
      method: () => {
        this.authService.logout()
      }
    },
    
  ]

  private currentMenuIndex$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  public readonly currentMenuIndex: Observable<number> =
    this.currentMenuIndex$.asObservable();

  private sideMenuHeight$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  public readonly sideMenuHeight: Observable<number> =
    this.sideMenuHeight$.asObservable();

  constructor(
    private authService: AuthService,
    private studentTrialService: StudentTrialService
  ) {
  }

  public setCurrentMenuIndex(index: number) {
    this.currentMenuIndex$.next(index);
  }

  public setSideMenuHeight(height: number) {
    this.sideMenuHeight$.next(height);
  }

  public updateMenuItemDisabledStatus(title: string, disabled: boolean) {
    const menuItem = this.menuItems.find(item => item.title === title);
    if (menuItem) {
      menuItem.disabled = disabled;
    }
  }

  public updateAllMenuItemsDisabledStatus(disabled: boolean) {
    this.menuItems.forEach(item => {
      if (item.title !== "Homework" && item.title !== "Guides" && item.title !== "Certificates") {
        item.disabled = disabled;
      }
    });
  }

  public updateMenuItemClassroomToTrialsForStudent(classrooms: Classroom[]) {
    this.menuItems.forEach(item => {
      if (item.title === 'Classrooms') 
      {
        if (classrooms) {
          const numberOfPaidClassrooms = classrooms.filter(classroom => classroom.type === ClassroomType.PAID).length;
          console.log(`The user has ${numberOfPaidClassrooms} paid classrooms.`);
          if (numberOfPaidClassrooms > 0) {
            item.url = "/dashboard/classrooms"
          } else {
            item.url = "/dashboard/classrooms/trials/true"
          }
        }
      }
    });
  }
  
  public getMenuItems(): LeftMenuItem[] {
    const menuItemsCopy = this.menuItems.slice();
    const role = this.authService.getUserRole();
    for (let i = 0; i < menuItemsCopy.length; i++) {
      if (role === "Student") {
        if (this.studentTrialService.hasTrialRequested() && menuItemsCopy[i].disableOnStudentTrial) {
          menuItemsCopy[i].disabled = true;
        }
        if (menuItemsCopy[i].title === "Guides") {
    menuItemsCopy[i].disabled = true;
    }
      } else {
        if (menuItemsCopy[i].title === "Guides") {
        menuItemsCopy[i].disabled = true;
        }
      }
    }
    return menuItemsCopy;
  }

  public getExtraMobileMenuItems(): LeftMenuItem[] {
    return this.extraMobileMenuItems;
  }
}
