import {
    transition,
    trigger,
    query,
    style,
    animate,
    group,
    animateChild
 } from '@angular/animations';
 export const slideInAnimation = trigger('routeAnimations', [
    transition('* => *', [
      query(':enter', [style({ opacity: 0, position: 'absolute' })], {
        optional: true,
      }),
      query(
        ':leave',
        [
          style({ opacity: 1 }),
          animate('0.3s', style({ opacity: 0, position: 'absolute' })),
        ],
        { optional: true }
      ),
      query(
        ':enter',
        [
          style({ opacity: 0 }),
          animate('0.3s', style({ opacity: 1, position: 'relative' })),
        ],
        { optional: true }
      ),
    ]),
  ]);