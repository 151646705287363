import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/core/services/general.service';

@Component({
  selector: 'app-prime-input-dropdown',
  templateUrl: './prime-input-dropdown.component.html',
  styleUrls: ['./prime-input-dropdown.component.scss'],
})
export class PrimeInputDropdownComponent implements OnInit {
  @Input() countries: any[] = [];
  @Input() placeholder!: string;
  @Input() inputName!: string;
  @Input() optionLabel!: string;
  @Input() filterBy!: string;
  @Input() className: string = 'dropdown-blue';
  @Input() isInvalidControl!: boolean;
  @Input() isValidControl!: boolean;
  @Input() styleClass = 'dropdown-blue white-bg rounded-less with-icon';
  @Input() parentForm!: FormGroup;
  @Input() templateValue!: any;
  @Input() selectedItemValue!: any;
  @Input() filter = true;
  @Input() withFlags = false;
  @Input() flagFilter!: any;
  @Input() showTextAfterImage = true;

  @Output() valueSelected = new EventEmitter<any>();

  onValueChange(value: any) {
    this.valueSelected.emit(value);
  }

  constructor(private fb: FormBuilder,
    public generalService: GeneralService
  ) { }

  ngOnInit(): void {
    this.parentForm.addControl(this.inputName, new FormControl('', Validators.required));
  }

  findCountryImage(value: string) {
    let values = '';
    switch (this.flagFilter) {
      case 'code':
        values = this.generalService.findCountryImage(value);
        break;
      case 'name':
        values = this.generalService.findCountryImageByName(value);
        break;
      default:
        values = this.generalService.findCountryImageByName(value);
        break;
    }

    return values;
  }

}
