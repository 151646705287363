import { Classroom, Level } from "./classroom.model";
import { Language } from "./general.model";
import { User } from "./user.model";

export interface Package {
    id: string,
    lessons_level: PackageLessonsLevel[],
    totalHours: number,
    classroomId: string,
    classroomTeacher?: string,
    currentPackageLevel: string,
    packageLevel: string,
    hoursLeft: number,
    type: PackageType.REGULAR | PackageType.FLEX | PackageType.BUSINESS | PackageType.GIFT | PackageType.TRIAL | PackageType.PREMIUM,
    expiresOn: Date,
    stratedAt: Date,
    purchasedAt: Date,
    state: PackageState,
    extensions: PackageExtention[]
}

export interface PackageExtention {
    fromDate: Date,
    toDate: Date,
    reason: string,
    requested_by: User
}

export interface PackageLessonsLevel {
    level: string,
    hoursCompleted: number,
    lessonsStarted: Date,
    lessonsEnded: Date | 'present'
}

export interface PackageFilterHelper {
    totalHours: (string | number)[],
    hoursLeft: (string | number)[],
    level: (string | Level)[],
    type: (string | PackageType)[],
    state: (string | PackageState)[],
    hasExtention: string[]
}

export interface PackageFilter {
    totalHours: (string | number),
    hoursLeft: (string | number),
    level: (string | Level),
    type: (string | PackageType),
    state: (string | PackageState),
    hasExtention: string,
    expiresOn_from: Date,
    expiresOn_to: Date,
    stratedAt_to: Date,
    stratedAt_from: Date,
    purchasedAt_from: Date,
    purchasedAt_to: Date,
}

export interface PackageToExtend {
    extendTime: string,
    price: number
}

export interface PackageToBuy {
    Id: number,
    type: PackageType,
    costPlus: number,
    expiresIn: string,
    expiresPlus: string,
    cancelation: string,
    pause: string,
    priceHourly: PackagePriceHourly[]
}

export interface PackageToBuyNew {
    id: number,
    hours: number,
    price: number,
    perHour: number,
    language: string,
    level: string,
    numberOfStudents: string
}

export interface mltPackages {
    type: PackageType,
    costPlus: number,
    expiresIn: PackagePriceHourly[],
    expiresPlus: string,
    cancelation: string,
    pause: string,
}

export interface PackagePriceHourly {
    hours: number,
    price: number,
    perHour: number,
    expirationMonths: number
}

export interface BuyerUserDetails {
    fname: string,
    lname: string,
    company: string,
    profession: string,
    country: string,
    streetName: string,
    streetNumber: string,
    city: string,
    postcode: string,
    tax: string,
    email: string,
    phone: string,
    tin: string,
}

export interface PackageToBuyOrderDeails {
    isNew: boolean,
    selectedClassroom: Classroom | null,
    selectedLevel: string,
    selectedLanguage: Language,
    selectedNumberOfStudents: string,
    selectedPackageToBuy: PackageToBuyNew,
    buyerUserDetails: BuyerUserDetails
}

export interface SplitPackagesToBuyResponse {
    splitPaymentsId: number;
    hours: number;
    companyFee: number;
    language: string;
    level: string;
    numberOfStudents: string;
  }

export enum PackageType {
    REGULAR = "Regular",
    FLEX = "Flexible",
    PREMIUM = "Premium",
    BUSINESS = "Business",
    GIFT = "Gift",
    TRIAL = "Trial",
    PAID = "Paid"
}

export const PackageDurationHours: Record<string, number> = {
    [PackageType.REGULAR]: 24,
    [PackageType.FLEX]: 12,
    [PackageType.PREMIUM]: 4,
}

export enum PackageTypeNew {
    REGULAR = "Regular",
    FLEX = "Flexible",
    PREMIUM = "Premium",
}

export enum PackageState {
    ACTIVE = "Active",
    ONGOING = "ongoing",
    COMPLETED = "Completed",
    PAUSED = "Paused",
    EXPIRED = "Expired",
    CANCELED = "Canceled",
    CANCELLED = "Cancelled",
    PURCHASED = "Purchased",
    REFUNDED = "Refunded",
    TRANSFERED = "Transferred"
}

export class PackagesModel {
    static getDefaultPackages(): mltPackages[] {
      const defaultPackages: mltPackages[] = [
        {
          type: PackageType.REGULAR,
          costPlus: 0,
          expiresIn: [{ hours: 5, price: 0, perHour: 0, expirationMonths: 1 },
          { hours: 10, price: 0, perHour: 0, expirationMonths: 2 },
          { hours: 15, price: 0, perHour: 0, expirationMonths: 3 },
          { hours: 20, price: 0, perHour: 0, expirationMonths: 4 },
          { hours: 50, price: 0, perHour: 0, expirationMonths: 10 },],
          expiresPlus: "",
          cancelation: "24",
          pause: "",
        },
        {
          type: PackageType.FLEX,
          costPlus: 30,
          expiresIn: [{ hours: 5, price: 0, perHour: 0, expirationMonths: 3 },
          { hours: 10, price: 0, perHour: 0, expirationMonths: 4 },
          { hours: 15, price: 0, perHour: 0, expirationMonths: 5 },
          { hours: 20, price: 0, perHour: 0, expirationMonths: 6 },
          { hours: 50, price: 0, perHour: 0, expirationMonths: 12 },],
          expiresPlus: "2 months",
          cancelation: "12",
          pause: "", // TODO: when freeze package is added
        },
        {
          type: PackageType.PREMIUM,
          costPlus: 80,
          expiresIn: [{ hours: 5, price: 0, perHour: 0, expirationMonths: 5 },
            { hours: 10, price: 0, perHour: 0, expirationMonths: 6 },
            { hours: 15, price: 0, perHour: 0, expirationMonths: 7 },
            { hours: 20, price: 0, perHour: 0, expirationMonths: 8 },
            { hours: 50, price: 0, perHour: 0, expirationMonths: 14 },],
          expiresPlus: "4 months",  
          cancelation: "4",
          pause: "", // TODO: when freeze package is added
        },
      ];
  
      return defaultPackages;
    }
  }
