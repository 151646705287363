<div class="profile-info-section">
<form [formGroup]="form">
    <div class="availability grid grid-nogutter">
        <div *ngIf="!isDialog" class="col-12 md:col-3 lg:col-3">
        <div class="availability-title">
            Choose date & time available
        </div>
        </div>
        
        <div class="col-12 availability-wrapper" [ngClass]="{'md:col-9 lg:col-9': !isDialog, 'narrow': isDialog}">
        <div class="availability-row  hidden md:flex" style="font-weight: bold; text-align: left;">
            <div class="availability-column-1 title-color">
                Day
            </div>
            <div class="availability-column-1 title-color text-center">
                Active
            </div>
            
            <div class="availability-column-2" style="display: flex; flex-wrap: wrap;">
                <div class="column-title title-color text-center">
                    Starts
                </div>
                <div class="column-title title-color text-center">
                    Ends
                </div>
            </div>
        </div>
        <div class="availability-row">
            <div class="availability-column-1 day">
                <div class="day-title" [ngClass]="{'font-xs': isDialog, 'font-base': !isDialog}">
                    Monday
                </div>
            </div>
            <div class="availability-column-1 checkbox-wrap">
                <div class="flex align-items-center justify-content-center">
                    <p-checkbox name="mondayActive" formControlName="mondayActive" styleClass="primary-blue outlined" binary="true" label="" (onChange)="initCheckboxChangedListener($event, mon, 'mon')"></p-checkbox>
                </div>
            </div>
            <div class="availability-column-2">
                <div class="profile-info"
                [ngClass]="{'disabled-div': !this.form.get('mondayActive')?.value}">
                    <div class="profile-info-section" style="padding-top:0">
                        <ng-container formArrayName="mon">
                            <div *ngFor="let _ of mon.controls; index as i" style="position: relative;">
                                <ng-container [formGroupName]="i">
                                    <div class="input-fields" [ngClass]="{'flex-nowrap gap-1': isDialog}">
                                        <div class="input-field" [ngClass]="{'m-0': isDialog}">
                                            
                                            <img *ngIf="i > 0" class="more-arrow" src="/assets/icons/availability-day-more-arrow.svg" 
                                            alt="availability-day-more" />
                                            <p-calendar
                                            [inputStyleClass]="!isDialog ? 'input-element' : 'm-0 dialog-time-input'"
                                            [stepMinute]="15" readonlyInput="true" (onSelect)="timePickChanged($event, this.form.controls.mon, i, 'from')" 
                                            [timeOnly]="true" formControlName="from"
                                            [defaultDate]="defaultDate"
                                            placeholder="e.g. 11:00"></p-calendar>
                                        </div>
                                        <div class="input-field" [ngClass]="{'m-0': isDialog}">
                                            <p-calendar
                                            [inputStyleClass]="!isDialog ? 'input-element' : 'm-0 dialog-time-input'"
                                            [stepMinute]="15" readonlyInput="true" (onSelect)="timePickChanged($event, this.form.controls.mon, i, 'to')" 
                                            [timeOnly]="true" formControlName="to"
                                            [defaultDate]="defaultDate"
                                            placeholder="e.g. 14:00"></p-calendar>
                                        </div>            
                                        <button *ngIf="i === 0" pButton type="button" (click)="addMon()"
                                        [ngClass]="{'sm bottom-0': isDialog}"
                                            class="p-button-raised p-button-rounded plus-btn-circle addDayIcon" icon="pi pi-plus">
                                        </button>
                                        <button *ngIf="i > 0" pButton type="button" (click)="removeMon(i)"
                                            [ngClass]="{'sm bottom-0': isDialog}"
                                            class="p-button-raised p-button-rounded minus-btn-circle addDayIcon" icon="pi pi-minus"></button>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="ifFieldValid('mon')" class="input-error">* Invalid Format
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="availability-row">
            <div class="availability-column-1 day">
                <div class="day-title" [ngClass]="{'font-xs': isDialog, 'font-base': !isDialog}">
                    Tuesday
                </div>
            </div>
            <div class="availability-column-1 checkbox-wrap">
                <div class="flex align-items-center justify-content-center">
                    <p-checkbox name="tuesdayActive" formControlName="tuesdayActive" styleClass="primary-blue outlined" (onChange)="initCheckboxChangedListener($event, tue, 'tue')" binary="true" label=""></p-checkbox>
                </div>
            </div>
            <div class="availability-column-2"
            [pTooltip]="!this.form.get('tuesdayActive')?.value ? 'Tuesday must be active to edit time.' : ''">
                <div class="profile-info">
                    <div class="profile-info-section" [ngClass]="{'disabled-div': !this.form.get('tuesdayActive')?.value}" style="padding-top:0">
                        <ng-container formArrayName="tue">
                            <div *ngFor="let _ of tue.controls; index as i" style="position: relative;">
                                <ng-container [formGroupName]="i">
                                    <div class="input-fields" [ngClass]="{'flex-nowrap gap-1': isDialog}">
                                        <div class="input-field" [ngClass]="{'m-0': isDialog}">
                                            
                                            <img *ngIf="i > 0" class="more-arrow" src="/assets/icons/availability-day-more-arrow.svg" 
                                            alt="availability-day-more" />
                                            <p-calendar
                                            [inputStyleClass]="!isDialog ? 'input-element' : 'm-0 dialog-time-input'"
                                            [stepMinute]="15" readonlyInput="true" (onSelect)="timePickChanged($event, this.form.controls.tue, i, 'from')" 
                                            [timeOnly]="true" formControlName="from"
                                            [defaultDate]="defaultDate"
                                            placeholder="e.g. 11:00"></p-calendar>
                                        </div>
                                        <div class="input-field" [ngClass]="{'m-0': isDialog}">
                                            <p-calendar
                                            [inputStyleClass]="!isDialog ? 'input-element' : 'm-0 dialog-time-input'"
                                            [stepMinute]="15" readonlyInput="true" (onSelect)="timePickChanged($event, this.form.controls.tue, i, 'to')" 
                                            [timeOnly]="true" formControlName="to"
                                            [defaultDate]="defaultDate"
                                            placeholder="e.g. 14:00"></p-calendar>
                                        </div>
                                        <button *ngIf="i === 0" pButton type="button" (click)="addTue()"
                                        [ngClass]="{'sm bottom-0': isDialog}"
                                            class="p-button-raised p-button-rounded plus-btn-circle addDayIcon" icon="pi pi-plus">
                                        </button>
                                        <button *ngIf="i > 0" pButton type="button" (click)="removeTue(i)"
                                            [ngClass]="{'sm bottom-0': isDialog}"
                                            class="p-button-raised p-button-rounded minus-btn-circle addDayIcon" icon="pi pi-minus">
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="ifFieldValid('tue')" class="input-error">* Invalid Format
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="availability-row">
            <div class="availability-column-1 day">
                <div class="day-title" [ngClass]="{'font-xs': isDialog, 'font-base': !isDialog}">
                    Wednesday
                </div>
            </div>
            <div class="availability-column-1 checkbox-wrap">
                <div class="flex align-items-center justify-content-center">
                    <p-checkbox name="wednesdayActive" formControlName="wednesdayActive" styleClass="primary-blue outlined" (onChange)="initCheckboxChangedListener($event, wed, 'wed')" binary="true" label=""></p-checkbox>
                </div>
            </div>
            <div class="availability-column-2" [ngClass]="{'disabled-div': !this.form.get('wednesdayActive')?.value}">
                <div class="profile-info">
                    <div class="profile-info-section" style="padding-top:0">
                        <ng-container formArrayName="wed">
                            <div *ngFor="let _ of wed.controls; index as i" style="position: relative;">
                                <ng-container [formGroupName]="i">
                                    <div class="input-fields" [ngClass]="{'flex-nowrap gap-1': isDialog}">
                                        <div class="input-field" [ngClass]="{'m-0': isDialog}">
                                            <img *ngIf="i > 0" class="more-arrow" src="/assets/icons/availability-day-more-arrow.svg" 
                                            alt="availability-day-more" />
                                            <p-calendar
                                            [inputStyleClass]="!isDialog ? 'input-element' : 'm-0 dialog-time-input'"
                                            [stepMinute]="15" readonlyInput="true" (onSelect)="timePickChanged($event, this.form.controls.wed, i, 'from')" 
                                            [timeOnly]="true" formControlName="from"
                                            [defaultDate]="defaultDate"
                                            placeholder="e.g. 11:00"></p-calendar>
                                        </div>
                                        <div class="input-field" [ngClass]="{'m-0': isDialog}">
                                            <p-calendar
                                            [inputStyleClass]="!isDialog ? 'input-element' : 'm-0 dialog-time-input'"
                                            [stepMinute]="15" readonlyInput="true" (onSelect)="timePickChanged($event, this.form.controls.wed, i, 'to')" 
                                            [timeOnly]="true" formControlName="to"
                                            [defaultDate]="defaultDate"
                                            placeholder="e.g. 14:00"></p-calendar>
                                        </div>
                                        <button *ngIf="i === 0" pButton type="button" (click)="addWed()"
                                        [ngClass]="{'sm bottom-0': isDialog}"
                                            class="p-button-raised p-button-rounded plus-btn-circle addDayIcon" icon="pi pi-plus">
                                        </button>
                                        <button *ngIf="i > 0" pButton type="button" (click)="removeWed(i)"
                                            [ngClass]="{'sm bottom-0': isDialog}"
                                            class="p-button-raised p-button-rounded minus-btn-circle addDayIcon" icon="pi pi-minus">
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="ifFieldValid('wed')" class="input-error">* Invalid Format
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="availability-row">
            <div class="availability-column-1 day">
                <div class="day-title" [ngClass]="{'font-xs': isDialog, 'font-base': !isDialog}">
                    Thursday
                </div>
            </div>
            <div class="availability-column-1 checkbox-wrap">
                <div class="flex align-items-center justify-content-center">
                    <p-checkbox name="thursdayActive" formControlName="thursdayActive" styleClass="primary-blue outlined" (onChange)="initCheckboxChangedListener($event, thu, 'thu')" binary="true" label=""></p-checkbox>
                </div>
            </div>
            <div class="availability-column-2" [ngClass]="{'disabled-div': !this.form.get('thursdayActive')?.value}">
                <div class="profile-info">
                    <div class="profile-info-section" style="padding-top:0">
                        <ng-container formArrayName="thu">
                            <div *ngFor="let _ of thu.controls; index as i" style="position: relative;">
                                <ng-container [formGroupName]="i">
                                    <div class="input-fields" [ngClass]="{'flex-nowrap gap-1': isDialog}">
                                        <div class="input-field" [ngClass]="{'m-0': isDialog}">
                                            <img *ngIf="i > 0" class="more-arrow" src="/assets/icons/availability-day-more-arrow.svg" 
                                            alt="availability-day-more" />
                                            
                                            <p-calendar
                                            [inputStyleClass]="!isDialog ? 'input-element' : 'm-0 dialog-time-input'"
                                            [stepMinute]="15" readonlyInput="true" (onSelect)="timePickChanged($event, this.form.controls.thu, i, 'from')" 
                                            [timeOnly]="true" formControlName="from"
                                            [defaultDate]="defaultDate"
                                            placeholder="e.g. 11:00"></p-calendar>
                                        </div>
                                        <div class="input-field" [ngClass]="{'m-0': isDialog}">
                                            <p-calendar
                                            [inputStyleClass]="!isDialog ? 'input-element' : 'm-0 dialog-time-input'"
                                            [stepMinute]="15" readonlyInput="true" (onSelect)="timePickChanged($event, this.form.controls.thu, i, 'to')" 
                                            [timeOnly]="true" formControlName="to"
                                            [defaultDate]="defaultDate"
                                            placeholder="e.g. 14:00"></p-calendar>
                                        </div>
                                        <button *ngIf="i === 0" pButton type="button" (click)="addThu()"
                                        [ngClass]="{'sm bottom-0': isDialog}"
                                            class="p-button-raised p-button-rounded plus-btn-circle addDayIcon" icon="pi pi-plus">
                                        </button>
                                        <button *ngIf="i > 0" pButton type="button" (click)="removeThu(i)"
                                            [ngClass]="{'sm bottom-0': isDialog}"
                                            class="p-button-raised p-button-rounded minus-btn-circle addDayIcon" icon="pi pi-minus">
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="ifFieldValid('thu')" class="input-error">* Invalid Format
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="availability-row">
            <div class="availability-column-1 day">
                <div class="day-title" [ngClass]="{'font-xs': isDialog, 'font-base': !isDialog}">
                    Friday
                </div>
            </div>
            <div class="availability-column-1 checkbox-wrap">
                <div class="flex align-items-center justify-content-center">
                    <p-checkbox name="fridayActive" formControlName="fridayActive" styleClass="primary-blue outlined" (onChange)="initCheckboxChangedListener($event, fri, 'fri')" binary="true" label=""></p-checkbox>
                </div>
            </div>
            <div class="availability-column-2" [ngClass]="{'disabled-div': !this.form.get('fridayActive')?.value}">
                <div class="profile-info">
                    <div class="profile-info-section" style="padding-top:0">
                        <ng-container formArrayName="fri">
                            <div *ngFor="let _ of fri.controls; index as i" style="position: relative;">
                                <ng-container [formGroupName]="i">
                                    <div class="input-fields" [ngClass]="{'flex-nowrap gap-1': isDialog}">
                                        <div class="input-field" [ngClass]="{'m-0': isDialog}">
                                            <img *ngIf="i > 0" class="more-arrow" src="/assets/icons/availability-day-more-arrow.svg" 
                                            alt="availability-day-more" />
                                            
                                            <p-calendar
                                            [inputStyleClass]="!isDialog ? 'input-element' : 'm-0 dialog-time-input'"
                                            [stepMinute]="15" readonlyInput="true" (onSelect)="timePickChanged($event, this.form.controls.fri, i, 'from')" 
                                            [timeOnly]="true" formControlName="from"
                                            [defaultDate]="defaultDate"
                                            placeholder="e.g. 11:00"></p-calendar>
                                        </div>
                                        <div class="input-field" [ngClass]="{'m-0': isDialog}">
                                            <p-calendar
                                            [inputStyleClass]="!isDialog ? 'input-element' : 'm-0 dialog-time-input'"
                                            [stepMinute]="15" readonlyInput="true" (onSelect)="timePickChanged($event, this.form.controls.fri, i, 'to')" 
                                            [timeOnly]="true" formControlName="to"
                                            [defaultDate]="defaultDate"
                                            placeholder="e.g. 14:00"></p-calendar>
                                        </div>
                                        <button *ngIf="i === 0" pButton type="button" (click)="addFri()"
                                        [ngClass]="{'sm bottom-0': isDialog}"
                                            class="p-button-raised p-button-rounded plus-btn-circle addDayIcon" icon="pi pi-plus">
                                        </button>
                                        <button *ngIf="i > 0" pButton type="button" (click)="removeFri(i)"
                                            [ngClass]="{'sm bottom-0': isDialog}"
                                            class="p-button-raised p-button-rounded minus-btn-circle addDayIcon" icon="pi pi-minus">
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="ifFieldValid('fri')" class="input-error">* Invalid Format
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="availability-row">
            <div class="availability-column-1 day">
                <div class="day-title" [ngClass]="{'font-xs': isDialog, 'font-base': !isDialog}">
                    Saturday
                </div>
            </div>
            <div class="availability-column-1 checkbox-wrap">
                <div class="flex align-items-center justify-content-center">
                    <p-checkbox name="saturdayActive" formControlName="saturdayActive" styleClass="primary-blue outlined" (onChange)="initCheckboxChangedListener($event, sat, 'sat')" binary="true" label=""></p-checkbox>
                </div>
            </div>
            <div class="availability-column-2" [ngClass]="{'disabled-div': !this.form.get('saturdayActive')?.value}">
                <div class="profile-info">
                    <div class="profile-info-section" style="padding-top:0">
                        <ng-container formArrayName="sat">
                            <div *ngFor="let _ of sat.controls; index as i" style="position: relative;">
                                <ng-container [formGroupName]="i">
                                    <div class="input-fields" [ngClass]="{'flex-nowrap gap-1': isDialog}">
                                        <div class="input-field" [ngClass]="{'m-0': isDialog}">
                                            <img *ngIf="i > 0" class="more-arrow" src="/assets/icons/availability-day-more-arrow.svg" 
                                            alt="availability-day-more" />
                                            <p-calendar
                                            [inputStyleClass]="!isDialog ? 'input-element' : 'm-0 dialog-time-input'"
                                            [stepMinute]="15" readonlyInput="true" (onSelect)="timePickChanged($event, this.form.controls.sat, i, 'from')" 
                                            [timeOnly]="true" formControlName="from"
                                            [defaultDate]="defaultDate"
                                            placeholder="e.g. 11:00"></p-calendar>
                                        </div>
                                        <div class="input-field" [ngClass]="{'m-0': isDialog}">
                                            <p-calendar
                                            [inputStyleClass]="!isDialog ? 'input-element' : 'm-0 dialog-time-input'"
                                            [stepMinute]="15" readonlyInput="true" (onSelect)="timePickChanged($event, this.form.controls.sat, i, 'to')" 
                                            [timeOnly]="true" formControlName="to"
                                            [defaultDate]="defaultDate"
                                            placeholder="e.g. 14:00"></p-calendar>
                                        </div>
                                        <button *ngIf="i === 0" pButton type="button" (click)="addSat()"
                                        [ngClass]="{'sm bottom-0': isDialog}"
                                            class="p-button-raised p-button-rounded plus-btn-circle addDayIcon" icon="pi pi-plus">
                                        </button>
                                        <button *ngIf="i > 0" pButton type="button" (click)="removeSat(i)"
                                            [ngClass]="{'sm bottom-0': isDialog}"
                                            class="p-button-raised p-button-rounded minus-btn-circle addDayIcon" icon="pi pi-minus">
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="ifFieldValid('sat')" class="input-error">* Invalid Format
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="availability-row">
            <div class="availability-column-1 day">
                <div class="day-title" [ngClass]="{'font-xs': isDialog, 'font-base': !isDialog}">
                    Sunday
                </div>
            </div>
            <div class="availability-column-1 checkbox-wrap">
                <div class="flex align-items-center justify-content-center">
                    <p-checkbox name="sundayActive" formControlName="sundayActive" styleClass="primary-blue outlined" (onChange)="initCheckboxChangedListener($event, sun, 'sun')" binary="true" label=""></p-checkbox>
                </div>
            </div>
            <div class="availability-column-2" [ngClass]="{'disabled-div': !this.form.get('sundayActive')?.value}">
                <div class="profile-info">
                    <div class="profile-info-section" style="padding-top:0">
                        <ng-container formArrayName="sun">
                            <div *ngFor="let _ of sun.controls; index as i" style="position: relative;">
                                <ng-container [formGroupName]="i">
                                    <div class="input-fields" [ngClass]="{'flex-nowrap gap-1': isDialog}">
                                        <div class="input-field" [ngClass]="{'m-0': isDialog}">
                                            <img *ngIf="i > 0" class="more-arrow" src="/assets/icons/availability-day-more-arrow.svg" 
                                            alt="availability-day-more" />
                                            <p-calendar
                                            [inputStyleClass]="!isDialog ? 'input-element' : 'm-0 dialog-time-input'"
                                            [stepMinute]="15" readonlyInput="true" (onSelect)="timePickChanged($event, this.form.controls.sun, i, 'from')" 
                                            [timeOnly]="true" formControlName="from"
                                            [defaultDate]="defaultDate"
                                            placeholder="e.g. 11:00"></p-calendar>
                                        </div>
                                        <div class="input-field" [ngClass]="{'m-0': isDialog}">
                                            <p-calendar
                                            [inputStyleClass]="!isDialog ? 'input-element' : 'm-0 dialog-time-input'"
                                            [stepMinute]="15" readonlyInput="true" (onSelect)="timePickChanged($event, this.form.controls.sun, i, 'to')" 
                                            [timeOnly]="true" formControlName="to"
                                            [defaultDate]="defaultDate"
                                            placeholder="e.g. 14:00"></p-calendar>
                                        </div>
                                        <button *ngIf="i === 0" pButton type="button" (click)="addSun()"
                                        [ngClass]="{'sm bottom-0': isDialog}"
                                            class="p-button-raised p-button-rounded plus-btn-circle addDayIcon" icon="pi pi-plus">
                                        </button>
                                        <button *ngIf="i > 0" pButton type="button" (click)="removeSun(i)"
                                            [ngClass]="{'sm bottom-0': isDialog}"
                                            class="p-button-raised p-button-rounded minus-btn-circle addDayIcon" icon="pi pi-minus">
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="ifFieldValid('sun')" class="input-error">* Invalid Format
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>

    <div class="availability grid" *ngIf="showHours">
    <div class="col-12 md:col-3 lg:col-3">
        <div class="availability-title">
            Choose hours per week *
        </div>
        </div>
        <div class="col-12 md:col-9 lg:col-9">
    <div class="input-fields my-dropdown">
        <div class="input-field">
            <p-dropdown (onChange)="onHoursChange($event)" autocomplete="off" [options]="hourOptions" placeholder="-"
                formControlName="hours"
                styleClass="dropdown-blue hours-dropdown m-t-0">
                <ng-template let-time pTemplate="item">
                    <div class="country-item">
                        <div class="country-name">{{time}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <div *ngIf="ifFieldValid('hours')" class="input-error">* Hours per week is required</div>
        </div>
    </div>
</div>
</div>
</form>


<ng-container *ngIf="isDialog">
    <div class="flex justify-content-center my-3">
        <button (click)="onSubmit()" icon="pi pi-calendar" iconPos="left" class="mt-2 p-button-sm" pButton type="button"
            label="Schedule"></button>
    </div>
</ng-container>
<ng-container *ngIf="!isDialog">
<div class="btns md:ml-5 md:mt-4">
    <button pRipple (click)="goBack()" class="rounded-blue-button transparent"> <img src="/assets/icons/arrow-left-blue.svg" />
        Back</button>
    <button pRipple (click)="onSubmit()" class="rounded-blue-button">
        <span *ngIf="!user">
            Next
        </span>
        <span *ngIf="user">
            Save
        </span>
        <img src="/assets/icons/arrow-right.svg" /></button>
</div>
</ng-container>
</div>