
<div id="left-menu" class="menu">
    <div class="first-section">
        <div class="first-section-content avatar-circle">

            
            <img  [src]="this.userService.getUserPhoto(this.authService.getLoggedInUser())"
            (error)="generalService.setDefaultUserAvatar($event)" class="menu-img-profile min-h-4rem">
            <!-- <div class="language-text">Teaching Language:</div> -->
            <!-- <div class="language-teach">Spanish</div> -->
        </div>
    </div>
    <div *ngIf="authService.isTeacher" class="flex-column  hidden lg:block align-items-center justify-content-center below-profile__lang-text">
        <p class="mb-1 text-center">{{authService.isStudent ? 'Learning' : 'Teaching'}} Language:</p>
        <p class="flex font-bold justify-content-center align-items-center gap-1"><i class="pi pi-flag"></i> 
            {{authService.isStudent ? 'Learning' : authService.teachingLanguage}}
        </p>
    </div>
    <div class="my-hr">
        <div class="my-hr-content"></div>
    </div>
    <div class="second-section">
        <ng-container *ngFor="let item of menuItems; let i = index">
           
        <div   *ngIf="item.role.includes(role)" [pTooltip]="item.disabled ? 'Access is unavailable at the moment.' : ''">
        <a class="second-section-item  hoverEffects relative"
         [class.disabled]="item.disabled" [tabindex]="item.disabled ? -1 : null"  [routerLink]="item.url" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="is-active">
         <div class="icon-container">
                <span  class="lottie-wrapper">
                <lottie-player [src]="item.lottieIcon"  background="transparent"  speed="1"  [style.transform]="'scale(' + item.scale + ')'" loop ></lottie-player>
                </span>
                <!-- <img [src]="item.icon" class="icon"> -->
            </div>
            <div class="text-container-tablet">{{item.title}}</div>
            <div class="text-container">{{item.title}}</div>
            <img *ngIf="item.title === 'AI Chat'" src="/assets/images/dashboard/new.svg" class="new-label">
        </a>
    </div>
    </ng-container>
        <!-- <a class="second-section-item" routerLinkActive="is-active" routerLink="/classrooms" [ngClass]="!isTrial && isClassroom? 'is-active': ''">
            <div class="icon-container">
                <img src="/assets/icons/classroom.svg" class="icon">
                <div class="text-container-tablet">Classrooms</div>
            </div>
            <div class="text-container">
                Classrooms
            </div>
        </a>
        <a *ngIf="role=='Teacher'" class="second-section-item" routerLink="/trials" [ngClass]="isTrial || isTrialRoute? 'is-active': ''">
            <div class="icon-container">
                <img src="/assets/icons/trial.svg" class="icon">
                <div class="text-container-tablet">Trials</div>
            </div>
            <div class="text-container">
                Trials
            </div>
        </a>
        <a class="second-section-item" routerLink="/calendar" routerLinkActive="is-active">
            <div class="icon-container">
                <img src="/assets/icons/calendar.svg" class="icon">
                <div class="text-container-tablet">Calendar</div>
            </div>
            <div class="text-container">
                Calendar
            </div>
        </a>
        <a class="second-section-item" routerLink="/notes" routerLinkActive="is-active">
            <div class="icon-container">
                <img src="/assets/icons/notes.svg" class="icon">
                <div class="text-container-tablet">Notes</div>
            </div>
            <div class="text-container">
                Notes
            </div>
        </a>
        <a class="second-section-item" routerLink="/library" routerLinkActive="is-active">
            <div class="icon-container">
                <img src="/assets/icons/library.svg" class="icon">
                <div class="text-container-tablet">Library</div>
            </div>
            <div class="text-container">
                Library
            </div>
        </a>
        <div class="second-section-item" routerLink="/homework" routerLinkActive="is-active">
            <div class="icon-container">
                <img src="/assets/icons/homework.svg" class="icon">
                <div class="text-container-tablet">Homework</div>
            </div>
            <div class="text-container">
                Homework
            </div>
        </div>
        <div *ngIf="role=='Teacher'" class="second-section-item" routerLink="/certificates" routerLinkActive="is-active">
            <div class="icon-container">
                <img src="/assets/icons/certificate.svg" class="icon">
                <div class="text-container-tablet">Training</div>
            </div>
            <div class="text-container">
                Training
            </div>
        </div>
        <div *ngIf="role=='Student'" class="second-section-item" routerLink="/certificates" routerLinkActive="is-active">
            <div class="icon-container">
                <img src="/assets/icons/certificate.svg" class="icon">
                <div class="text-container-tablet">Homework</div>
            </div>
            <div class="text-container">
                Certificates
            </div>
        </div>
        <div *ngIf="role=='Student'" class="second-section-item" routerLink="/certificates" routerLinkActive="is-active">
            <div class="icon-container">
                <img src="/assets/icons/certificate.svg" class="icon">
                <div class="text-container-tablet">Homework</div>
            </div>
            <div class="text-container">
                Guides
            </div>
        </div> -->
        <!-- <div class="second-section-item">
            <div class="icon-container">
                <img src="/assets/icons/dropin.svg" class="icon">
                <div class="text-container-tablet">Drop-In</div>
            </div>
            <div class="text-container" style="color: var(--light-purple)">
                Drop-In
            </div>
        </div> -->
    </div>
</div>
