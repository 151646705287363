import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Lesson, LessonStatus } from 'src/app/core/models/lesson.model';
import { GeneralService } from 'src/app/core/services/general.service';
import { DialogService } from 'primeng/dynamicdialog';
import { Classroom } from 'src/app/core/models/classroom.model';
import { LessonInfoCalendarDialogComponent } from 'src/app/modules/calendar/calendar-dialogs/lesson-info-calendar-dialog/lesson-info-calendar-dialog.component';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { LessonService } from 'src/app/core/services/lesson.service';
@Component({
  selector: 'app-lesson-row-item',
  templateUrl: './lesson-row-item.component.html',
  styleUrls: ['./lesson-row-item.component.scss'],
  providers: [DialogService]
})
export class LessonRowItemComponent implements OnInit {
  @Input() mode: string = "compact";
  @Input() lesson: Lesson = {} as Lesson;
  @Input() classroom: Classroom = {} as Classroom;
  @Input() activeLevel = '';
  @Input() title = '';
  items!: MenuItem[];
  lessonItem: Lesson = {} as Lesson;
  lessonStatus = LessonStatus;
  LessonStatus = LessonStatus;
  showRatingDiv: boolean = false;

  constructor(
    public generalService: GeneralService,
    private dialogService: DialogService,
    public classroomService: ClassroomService,
    private authService: AuthService,
    private lessonService: LessonService,
  ) { }
  rating: number = 0;
  hoverIndex = 0;

  rate(value: number) {
    this.rating = value;
    console.log('Rated: ' + value);
    // Do something with the rating value, e.g., update the backend or display a message
  }
  ngOnInit(): void {
    this.items = [
      { label: 'Add New', icon: 'pi pi-fw pi-plus' },
      { label: 'Remove', icon: 'pi pi-fw pi-minus' }
    ];
    // this.lessonItem = this.lesson[0];
    // if (this.lesson.id === 674) {
    //   this.lessonCardClicked();
    // }
  }

  onStarHover(index: number) {
    this.hoverIndex = index - 1;
  }

  onStarLeave() {
    this.hoverIndex = 0;
  }


  onMouseEnter() {
    this.showRatingDiv = true;
    // this.renderer.addClass(document.getElementById('your-element'), 'hovered');
  }

  onMouseLeave() {
    this.showRatingDiv = false;
    // this.renderer.removeClass(document.getElementById('your-element'), 'hovered');
  }

  ngOnChanges() {
  }

  getIcon(statusText: string) {
    // if (this.classroomService.isTrialClassroom(this.classroom)) return ("pi-send");
    if (statusText == null) return ("pi-check");
    switch (true) {
      case statusText.toLowerCase().includes("rearrange"):
        return "assets/icons/lessons/arranged-white.svg";
        case statusText.toLowerCase().includes("completed"):
          return "assets/icons/lessons/completed-white.svg";
      case statusText.toLowerCase().includes("arranged"):
        return "assets/icons/lessons/arranged-white.svg";
      case statusText.toLowerCase().includes("trial arranged"):
        return "assets/icons/lessons/arranged-white.svg";
      case statusText.toLowerCase().includes("canceled"):
        return "assets/icons/lessons/canceled-white.svg";
      case statusText.toLowerCase().includes("requested"):
        return "assets/icons/lessons/requested-white.svg";
      case statusText.toLowerCase().includes("no show"):
        return "assets/icons/lessons/no-show-white.svg";
      default:
        return "pi-check";
    }
  }

  getLessonStatusColor(status: LessonStatus) {
    switch (status.toLowerCase()) {
      case this.lessonStatus.COMPLETED.toLowerCase():
        return 'completed';
        case this.lessonStatus.REQUESTED.toLowerCase():
          return 'requested';
          case this.lessonStatus.REQUESTED_TRIAL.toLowerCase():
            return 'requested';
        break;
        case this.lessonStatus.COMPLETED_TRIAL.toLowerCase():
          return 'trial-completed';
          break;
          case this.lessonStatus.ARRANGED.toLowerCase():
            return 'arranged';
            break;
            case this.lessonStatus.ARRANGED_TRIAL.toLowerCase():
              return 'trial-arranged';
              break;
      case this.lessonStatus.NO_SHOW.toLowerCase():
        return 'no-show';
        break;
        case this.lessonStatus.CANCELED.toLowerCase():
          return 'canceled';
          break;
          case this.lessonStatus.CANCELED_TRIAL.toLowerCase():
            return 'canceled';
            break;
      default:
        // Do nothing if the status string is not recognized
        break;
    }
    return 'arranged';
  }

  lessonCardClicked() {
    const dData = {
      type: 'lesson',
      lesson: this.lesson,
      classroom: this.classroom,
      dialogService: this.dialogService,
    }
    this.generalService.openDialogWithComponent(this.dialogService, LessonInfoCalendarDialogComponent, 320,
      dData, null, (result: any) => {
        // if (result && result.action === 'shareWithMultiple') {
        //   this.shareLibraryFiles(result.map.map);
        // }
      });
  }

  showRating() {
    const ratingDiv = document.getElementById('ratingDiv');
    if (ratingDiv) {
      ratingDiv.classList.add('show');
    }
  }

  hideRatingDiv() {
    this.showRatingDiv = false; 
  }

  /**
 * Returns the name of the teacher or classroom students for a given classroom.
 *
 * @param {Classroom} classroom - The classroom object.
 * @returns {string} The name of the teacher or classroom students.
 */
  getLessonRowName(classroom: Classroom): string {
    if (this.authService.isStudent) {
      return this.generalService.getShortFullName(classroom.teacher);
    } else {
      return this.classroomService.getFormattedStudentNames(classroom.classroomStudents);
    }
  }

  /**
 * Returns the tooltip name of the teacher or classroom students for a given classroom.
 *
 * @param {Classroom} classroom - The classroom object.
 * @returns {string} The tooltip name of the teacher or classroom students.
 */
  getLessonRowTooltipName(classroom: Classroom): string {
    return this.lessonService.getLessonRowTooltipName(classroom);
  }

}
