import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
@Injectable()
export class AuthGuard  {
    constructor(private authService: AuthService, private router: Router) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        const isAuth = this.authService.getIsAuth();
        this.authService.saveLeadDynoAffiliateIdFromRoute(route);
        if(!isAuth){
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
        }
        return isAuth//allow to go to router if true

    }

}