<div class="library-upload-details">
    <div class="library-upload-details-right">
        <div class="library-upload-checkboxes ">
            <div class="flex w-full">
            <div class="w-5rem text-right mr-2">
                <div class="font-xs text-primary mr-2">
                    Level:
                </div>
            </div>

    <div class="w-full flex flex-wrap gap-1 align-items-center justify-content-start">
            <div class="library-upload-checkbox">
                
                <p-checkbox name="groupname" styleClass="primary-blue" labelStyleClass="primary-blue" label="A1" [ngModel]="getIsCheckedLevel('A1')" 
                [binary]="true" (onChange)="onChooseTag($event, 'A1', true, false)"></p-checkbox>
             
            </div>
            <div class="library-upload-checkbox">
                <p-checkbox name="groupname" styleClass="primary-blue" labelStyleClass="primary-blue" label="A2" [ngModel]="getIsCheckedLevel('A2')" 
                [binary]="true" (onChange)="onChooseTag($event, 'A2', true, false)"></p-checkbox>
            </div>
            <div class="library-upload-checkbox">
                <p-checkbox name="groupname" styleClass="primary-blue" labelStyleClass="primary-blue" label="B1" [ngModel]="getIsCheckedLevel('B1')" 
                [binary]="true" (onChange)="onChooseTag($event, 'B1', true, false)"></p-checkbox>
            </div>
            <div class="library-upload-checkbox">
                <p-checkbox name="groupname" styleClass="primary-blue" labelStyleClass="primary-blue" label="B2" [ngModel]="getIsCheckedLevel('B2')" 
                [binary]="true" (onChange)="onChooseTag($event, 'B2', true, false)"></p-checkbox>
            </div>
            <div class="library-upload-checkbox">
                <p-checkbox name="groupname" styleClass="primary-blue" labelStyleClass="primary-blue" label="C1" [ngModel]="getIsCheckedLevel('C1')" 
                [binary]="true" (onChange)="onChooseTag($event, 'C1', true, false)"></p-checkbox>
            </div>
            <div class="library-upload-checkbox">
                <p-checkbox name="groupname" styleClass="primary-blue" labelStyleClass="primary-blue" label="C2" [ngModel]="getIsCheckedLevel('C2')" 
                [binary]="true" (onChange)="onChooseTag($event, 'C2', true, false)"></p-checkbox>
            </div>
        </div>

    </div>

    </div>
    <!-- ends library-upload-checkboxes -->
        <div class="library-upload-checkboxes">
            
            <div class="flex w-full">
            <div class="w-5rem text-right mr-2">
                <div class="font-xs text-primary">
                    Categories:
                </div>
            </div> 

            <div class="w-full flex flex-wrap align-items-center justify-content-start">
            <div class="library-upload-checkbox">
                <p-checkbox name="groupname" styleClass="primary-blue" labelStyleClass="primary-blue" label="Reading" [ngModel]="getIsCheckedCategory('Reading')" 
                [binary]="true" (onChange)="onChooseTag($event, 'Reading', false, true)"></p-checkbox>
            </div>
            <div class="library-upload-checkbox">
                <p-checkbox name="groupname" styleClass="primary-blue" labelStyleClass="primary-blue" label="Writing" [ngModel]="getIsCheckedCategory('Writing')" 
                [binary]="true" (onChange)="onChooseTag($event, 'Writing', false, true)"></p-checkbox>
            </div>
            <div class="library-upload-checkbox">
                <p-checkbox name="groupname" styleClass="primary-blue" labelStyleClass="primary-blue" label="Listening" [ngModel]="getIsCheckedCategory('Listening')" 
                [binary]="true" (onChange)="onChooseTag($event, 'Listening', false, true)"></p-checkbox>
            </div>
            <div class="library-upload-checkbox">
                <p-checkbox name="groupname" styleClass="primary-blue" labelStyleClass="primary-blue" label="Grammar" [ngModel]="getIsCheckedCategory('Grammar')" 
                [binary]="true" (onChange)="onChooseTag($event, 'Grammar', false, true)"></p-checkbox>
            </div>
            <div class="library-upload-checkbox">
                <p-checkbox name="groupname" styleClass="primary-blue" labelStyleClass="primary-blue" label="Vocabulary" [ngModel]="getIsCheckedCategory('Vocabulary')" 
                [binary]="true" (onChange)="onChooseTag($event, 'Vocabulary', false, true)"></p-checkbox>
            </div>
            <div class="library-upload-checkbox">
                <p-checkbox name="groupname" styleClass="primary-blue" labelStyleClass="primary-blue" label="Test" [ngModel]="getIsCheckedCategory('Test')" 
                [binary]="true" (onChange)="onChooseTag($event, 'Test', false, true)"></p-checkbox>
            </div>
            <div class="library-upload-checkbox">
                <p-checkbox name="groupname" styleClass="primary-blue" labelStyleClass="primary-blue" label="Homework" [ngModel]="getIsCheckedCategory('Homework')" 
                [binary]="true" (onChange)="onChooseTag($event, 'Homework', false, true)"></p-checkbox>
            </div>
            <div class="library-upload-checkbox">
                <p-checkbox name="groupname" styleClass="primary-blue" labelStyleClass="primary-blue" label="Units" [ngModel]="getIsCheckedCategory('Units')" 
                [binary]="true" (onChange)="onChooseTag($event, 'Units', false, true)"></p-checkbox>
            </div>
        </div>
        </div>
    </div>
</div>