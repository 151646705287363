import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Lesson, LessonStatus, LessonUpdateRequest } from 'src/app/core/models/lesson.model';
import { LessonFullRating, RatingItem, dummylessonFullRatings } from 'src/app/core/models/rating.model';
import { User } from 'src/app/core/models/user.model';
import { LessonBreakdown, LessonUserRating } from 'src/app/core/models/rating.model';
import { RatingAndReportService } from 'src/app/core/services/rating-and-report.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { LessonService } from 'src/app/core/services/lesson.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastService } from 'src/app/core/services/toast.service';
import { SubSink } from 'subsink';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { Classroom, Status } from 'src/app/core/models/classroom.model';
import { ClassroomService } from 'src/app/core/services/classroom.service';

export interface Variant { rating: RatingItem, value: number, sliderRating?: number }
interface LessonBreakdownObject {
  [key: string]: number;
  // other properties here
}
@Component({
  selector: 'app-lesson-rating',
  templateUrl: './lesson-rating.component.html',
  styleUrls: ['./lesson-rating.component.scss']
})


export class LessonRatingComponent implements OnInit {
  private subs = new SubSink();
  @ViewChild('chartLessonBreakdown') chartLessonBreakdown: any;
  @ViewChild('slider', {static: false}) slider!: ElementRef;
  @Input() lesson: Lesson = {} as Lesson;
  @Input() classroom: Classroom = {} as Classroom;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Input() lessonRatings: LessonBreakdown = {} as LessonBreakdown;
  @Input() lessonUserRatings: any[] = [];
  @Input() users: User[] = [];
  @Output() ratingSubmitted = new EventEmitter();
  public ratings: RatingItem[] =  [
    { id: 'r1', color: '#362FBB', category: 'Vocabulary', checked: false },
    { id: 'r2', color: '#758BFA', category: 'Grammar', checked: false },
    { id: 'r3', color: '#00B2AE', category: 'Writing', checked: false },
    { id: 'r4', color: '#9DC6B6', category: 'Reading', checked: false },
    { id: 'r5', color: '#0076CD', category: 'Listening', checked: false },
    { id: 'r6', color: '#00639A', category: 'Speaking', checked: false },
    { id: 'r7', color: '#D094EA', category: 'Revision', checked: false },
    { id: 'r8', color: '#A44FD0', category: 'Test', checked: false }
];;
  loggedInUser: User = {} as User;
  public classRatings: any[] = dummylessonFullRatings;
  handles = [25, 50, 75]; // initial handle positions
  min = 0;
  max = 100;
  step = 1;
  count = 0;
  variants: Variant[] = [];
  public colors: any = ['transparent', 'transparent', 'transparent', 'transparent', 'transparent', 'transparent', 'transparent', 'transparent',];
  public variantsHTML: any[] = []
  public textInterval: any;
  public chooseAbsentValue: string = "no"

  public tooltipData: Object = { placement: 'Before', isVisible: true };
  public ticksData: Object = { placement: 'After', largeStep: 1 };

  public absentValue: string[] = [];

  public arrayValue: any = [];
  public sliderRatings: any = [];

  public user: User = {} as User;
  
  labels: any = []
  data: any = []
  d: any

  tooltipChangeHandler(args: any): void {
    // Weekdays Array
    let daysArr: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
    // Customizing each ticks text into weeksdays
    args.text = daysArr[parseFloat(args.value)];
  }

  renderingTicksHandler(args: any): void {
    // Customizing tooltip to display the Day (in numeric) of the week
    args.text = 'Day ' + (Number(args.value) + 1).toString();
  }

  constructor(
    private toast: ToastService,
    private authService: AuthService,
    private ratingAndReportService: RatingAndReportService,
    private lessonService: LessonService,
    private calendarService: CalendarService,
    private classroomService: ClassroomService,
    public generalService: GeneralService,
    private elementRef: ElementRef, 
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {

    if (this.authService.isStudent) {
      this.mode = 'view';
    }
    this.loggedInUser = this.authService.getLoggedInUser();
    // this.lessonUserRatings = dummylessonFullRatings[0].userRatings;
    console.log(this.lesson)
    console.log(this.lessonUserRatings)

  }


  ngAfterContentInit() {

      console.log(this.users);
      if (this.mode != 'view' && this.mode != 'edit') {
      for (let user of this.users) {
      this.arrayValue.push({
          user: user, 
          userId: user.id,
          options: ["yes", "no"], 
          absent: false,
          lessonUserRating : {
              absent: false,
              vocabulary: 0,
              listening: 0,
              grammar: 0,
              speaking: 0,
              writing: 0,
              revision: 0,
              reading: 0,
              test: 0,
              studentId: user.aspUserId,
              teacherId: this.loggedInUser.aspUserId,
              lessonId: this.lesson.id
          }
      });

        if (this.lessonUserRatings.length === 0) {
          const defaultLessonUserRating: LessonUserRating = {
            id: user.id,
            absent: false,
            vocabulary: 0,
            listening: 0,
            grammar: 0,
            speaking: 0,
            writing: 0,
            revision: 0,
            reading: 0,
            test: 0
          };
          this.lessonUserRatings.push(defaultLessonUserRating);
        }
      }
    }
      console.log(this.lessonUserRatings)
    if (this.mode != 'view') {
      console.log(this.lesson)
      for (let user of this.users) {
      }
    } else {
      console.log(this.lessonUserRatings)

      // if (this.lessonUserRatings) {

      //   for (let lessonUserRating of this.lessonUserRatings) {
      //     this.arrayValue.push({ user: this.users[0], userId: 'test', 
      //     options: ["yes", "no"], absent: lessonUserRating.absent })
      //   }
      // }
    }

    if (this.mode === 'view' || this.mode === 'edit') {
      this.subs.sink = this.ratingAndReportService.getLessonBreakDown(this.lesson.id).subscribe((res: any) => {
        
        console.log(res[0]);
        // this.lessonRatings.id = res[0].id;
        this.lessonRatings = res[0];
        this.prepareRatings();
        this.updateVariants();

      });
      for (let user of this.users) {
        this.subs.sink = this.ratingAndReportService.getUserLessonRatings(user.aspUserId!, this.lesson.id).subscribe((res: any[]) => {
          console.log(res);
          // if (res.length === 0) {
          //   return;
          // }
          if (res.length === 0) {
            return;
          }
          res.forEach((key: any, index: number) => {    
            this.arrayValue.push({
              user: user,
              userId: user.id,
              options: ["yes", "no"],
              absent: false,
              lessonUserRating: {
                absent: res[index].absent,
                id: res[index].id,
                vocabulary: res[index].vocabulary,
                listening: res[index].listening,
                grammar: res[index].grammar,
                speaking: res[index].speaking,
                writing: res[index].writing,
                revision: res[index].revision,
                reading: res[index].reading,
                test: res[index].test,
                studentId: user.aspUserId,
                teacherId: this.classroom.teacher?.aspUserId,
                lessonId: this.lesson.id
              }
            });
            // this.variants.push({ rating: this.ratings[index], value: parseFloat((100 / 5).toFixed(1)) });
            // this.showSliderValue(this.slider.nativeElement.parentElement.querySelector('.rs-bullet'), this.slider.nativeElement);

          })

            // this.variants.push({ rating: this.ratings[index], value: parseFloat((100 / 5).toFixed(1)) });


          // this.updateVariants();
          // const defaultLessonUserRating: LessonUserRating = {
          //   id: this.lesson.id.toString(),
          //   absent: false,
          //   vocabulary: res[0].vocabulary,
          //   listening: res[0].listening,
          //   grammar: res[0].grammar,
          //   speaking: res[0].speaking,
          //   writing: res[0].speaking,
          //   revision: res[0].revision,
          //   reading: res[0].reading,
          //   test: res[0].test,
          //   lessonId: this.lesson.id
          // };
          // this.lessonUserRatings.push(defaultLessonUserRating);
        }, error => {
          console.error(error);
        });
      }
    }
  }

  ngOnChanges() {
    // const sliderHandles = this.elementRef.nativeElement.querySelectorAll('.rate-slider .p-slider-handle');
    // sliderHandles.forEach((sliderHandle: HTMLElement) => {
    //   console.log(sliderHandle);
    //   const valueNow = sliderHandle.getAttribute('aria-valuenow');
    
    //   const valueSpan = this.renderer.createElement('span');
    //   const text = this.renderer.createText(valueNow!);
    //   this.renderer.appendChild(valueSpan, text);
    
    //   this.renderer.insertBefore(sliderHandle.parentNode, valueSpan, sliderHandle);
    // });

  }

  prepareRatings() {
    Object.keys(this.lessonRatings).forEach(key => {
      const ratingFound = this.ratings.find(rating => rating.category.toLowerCase() === key) as RatingItem;

      if (ratingFound) {
        const ratingVal = this.lessonRatings[key as keyof LessonBreakdown] as number;
        if (ratingVal > -1) {

          this.onCheckboxChange({ checked: true }, ratingFound);
        }

      }
    });
  }

  showSliderValue(bullet: any, slider: any) {
    bullet.innerHTML = slider.value;
    console.log(slider.value);
    var sliderWidth = slider.offsetWidth;
    var sliderMax = slider.max;
    var sliderValue = parseInt(slider.value);
    var bulletPosition = (sliderValue / sliderMax) * sliderWidth;
    const badOffset = sliderValue === 0 ? 2 : sliderValue;
    bullet.style.left = (sliderValue === 0) ? 2 + "px" : (bulletPosition - (badOffset * 1.3)) + "px";
    // bullet.style.top = "10px";
    console.log(bullet.style);
  }

  onCategoryRatingChanged($event: any, arraval: any, index: number) {
    console.log($event, index);
    this.arrayValue[index].lessonUserRating[$event.category] = $event.value;
  }


  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onCheckboxChange(event: any, rating: any) {
    console.log(this.lessonRatings);
    const index = this.ratings.findIndex(r => r.category === rating.category);
    
    this.ratings[index].checked = true;
    if (event.checked) {
      for (let lessonUserRating of this.lessonUserRatings) {
        Object.keys(lessonUserRating).forEach((key: any) => {          
          if (rating.category.toLowerCase() == key) {
            lessonUserRating[key] = 5
          }
        })
      }
      let cheat: number = 0.2;//sometimes sums to 100.2% and breaks
      for (let i = 0; i < this.count; i++) {
        if (i!== 4 && i!== 5) {
          cheat = 0;
        } else {
          cheat = 0.2;
        }
        this.variants[i].value = parseFloat(((this.variants[i].value * this.count - cheat) / (this.count + 1)).toFixed(1));
      }
      this.count++
      this.variants.push({ rating: rating, value: parseFloat((100 / this.count).toFixed(1)) });      
    } else {
      for (let lessonUserRating of this.lessonUserRatings) {
        Object.keys(lessonUserRating).forEach((key: any) => {
          if (rating.category == key) {
            lessonUserRating[key] = 0
          }
        })
      }
      this.count--;
      let valueToAdd = this.variants.find((el: any) => { return el.rating.id === rating.id })?.value! * (1 / this.count);
      this.variants = this.variants.filter((el: any) => { return el.rating.id!== rating.id })
      for (let i = 0; i < this.count; i++) {
        this.variants[i].value = parseFloat((this.variants[i].value + valueToAdd - 0.2).toFixed(1));
      }
    }
    setTimeout(() => {
      this.styleVariants();
    }, 10);
  
    // Check if the arrayValue array has an object with a lessonUserRating property that matches the rating object
    const arrayValueIndex = this.arrayValue.findIndex((obj: any) => obj.lessonUserRating && obj.lessonUserRating.id === rating.id);
    if (arrayValueIndex!== -1) {
      this.arrayValue[arrayValueIndex].lessonUserRating.checked = event.checked;
    }
    // Hide user's variant ratings when "absent" checkbox is checked
    // if (event.checked) {
    //   this.variants.forEach((variant: any) => {
    //     const userVariantRatingIndex = this.lessonUserRatings.findIndex((userRating: any) => userRating.user?.id === this.loggedInUser.id && userRating.lessonUserRating.id === variant.rating.id);
    //     if (userVariantRatingIndex!== -1) {
    //       this.lessonUserRatings.splice(userVariantRatingIndex, 1);
    //     }
    //   });
    // }
  }

  resetVariants() {
    this.count = 0;
    this.variants = [];
    this.ratings = this.ratings.map(item => ({ ...item, checked: false }));

    this.arrayValue.forEach((key: any, index: number) => {    
      // this.variants.push({ rating: this.ratings[index], value: parseFloat((100 / 5).toFixed(1)) });
      Object.keys(this.arrayValue[index].lessonUserRating).forEach(key => {
        console.log(this.arrayValue[index].lessonUserRating[key])
        if (this.arrayValue[index].lessonUserRating[key]!== 0) {
          const ratingFound = this.ratings.find(rating => rating.category.toLowerCase() === key) as RatingItem;
          // this.arrayValue[index].lessonUserRating[key] = 0;
          if (ratingFound) {

            console.log(ratingFound);
            // this.variants.push({ rating: ratingFound, value: parseFloat((100 / 3).toFixed(1)) });
            // ratingFound.checked = true;
            // this.onCheckboxChange({ checked: false }, ratingFound);
          }
        }
      });
    });
  }

  updateVariants() {
    if (this.mode === 'edit' || this.mode === 'view') {

      const updatedVariants = this.variants.map(variant => {
        const { rating } = variant;
        const { category } = rating;
        let value = this.lessonRatings[category as keyof LessonBreakdown];
        let ratingBreakdown = value;
        for (const [key, value] of Object.entries(this.lessonRatings)) {
          if (category.toLowerCase() == key) {
            ratingBreakdown = this.lessonRatings[key as keyof LessonBreakdown];
          }
        }
        value = ratingBreakdown !== -1 ? ratingBreakdown : 0;
        return {...variant, value };
      });
      this.variants = updatedVariants as Variant[];
      setTimeout(() => {
        this.styleVariants();
      }, 50);
    }
  }

  getRadioChecked(value: boolean, key: string) {
    if (value && key == 'yes') {
      return true
    }
    if (!value && key == 'no') {
      return true;
    }
    return null
  }

  getRatingValue(key: string, user: User) {
    let val = 5;

    if (this.lessonUserRatings.length > 0) {
      let userRating: LessonUserRating = dummylessonFullRatings[0].userRatings[0];
      
      console.log(userRating)
      if (key == "listening") {
        val = userRating.listening
      }
      if (key == "grammar") {
        val = userRating.grammar
      }
      if (key == "speaking") {
        val = userRating.speaking
      }
      if (key == "writing") {
        val = userRating.writing
      }
      if (key == "revision") {
        val = userRating.revision
      }
      if (key == "reading") {
        val = userRating.reading
      }
      if (key == "test") {
        val = userRating.test
      }
    }
    return val

  }

  styleVariants() {
    let i = 0;
    let width = "";
    let left = "";
    this.variantsHTML = [];
    [].forEach.call(document.getElementsByClassName('slider-handle'), (el: any) => {
      el.style.setProperty("background", "white", "important");
    });
    [].forEach.call(document.getElementsByClassName('slider'), (el: any) => {
      el.style.setProperty("color", "white", "important");
      // el.style.setProperty("text-align", "center", "important");
      el.style.setProperty("top", "-5px", "important");
      this.variantsHTML.push(el);
    })
    console.log(this.variantsHTML.length)
    this.textInterval = setInterval(() => {
      for (let i = 0; i < this.variantsHTML.length; i++) {
        if (!this.variants[i]) {
          continue;
        }
        if (i > 0) {
          width = "calc(100% - 40px)";
          left = "50px";
        } else {
          width = "100%";
          left = "0px";
        }
        this.variantsHTML[i].innerHTML = '<div style="margin-top:-30px; text-align:center; width:' + width + '; margin-left:' + left + '"><div class="font-xs" style="color: #2d2a4b; font-weight:bold;">' + this.variants[i].rating.category + '<br></div><div style="width:100%;"><div style="margin-top:9px;">' + this.variants[i].value.toFixed(0) + '%</div></div></div>';
        // this.variantsHTML[i].innerHTML = '<div style=margin-top:-30px><div style="color: #2d2a4b; font-weight:bold; margin-left:' + space + '">' + this.variants[i].rating.rating + '<br></div><div style="width:100%;"><div style="margin-top:9px; margin-left:' + space + '">' + this.variants[i].value + '%</div></div></div>';
      }
    }, 100)
  }

  chooseAbsent(event: any, index: number) {
    this.lessonUserRatings.filter((el: LessonUserRating) => {
      if (el.user?.id === event.value[1].id) {
        el.absent = event.value[0] === 'yes' ? true : false;
      }
    });
  }

  changeSlider(event: any, rating: RatingItem, user: User, variant: any) {
    let category = variant.rating.category.toLowerCase();
    let lessonUserRating = this.lessonUserRatings.filter((el: LessonUserRating) => el.user?.id === user.id)[0];
    // this.changeRating(category, lessonUserRating, event.value)
  }

  changeRating(category: string, lessonUserRating: LessonUserRating, value: number) {
    if (category == "vocabulary") {
      lessonUserRating.vocabulary = value
    }
    if (category == "listening") {
      lessonUserRating.listening = value
    }
    if (category == "grammar") {
      lessonUserRating.grammar = value
    }
    if (category == "speaking") {
      lessonUserRating.speaking = value
    }
    if (category == "writing") {
      lessonUserRating.writing = value
    }
    if (category == "revision") {
      lessonUserRating.revision = value
    }
    if (category == "reading") {
      lessonUserRating.reading = value
    }
    if (category == "test") {
      lessonUserRating.test = value
    }
  }

  saveRating() {
    this.lessonRatings.vocabulary = this.getCategoryValue("vocabulary");
    this.lessonRatings.listening = this.getCategoryValue("listening");
    this.lessonRatings.grammar = this.getCategoryValue("grammar");
    this.lessonRatings.speaking = this.getCategoryValue("speaking");
    this.lessonRatings.writing = this.getCategoryValue("writing");
    this.lessonRatings.revision = this.getCategoryValue("revision");
    this.lessonRatings.reading = this.getCategoryValue("reading");
    this.lessonRatings.test = this.getCategoryValue("test");
    this.lessonRatings.lessonId = this.lesson.id;

    console.log(this.lessonUserRatings);
    console.log(this.lessonRatings);
    const lessonUserRatings = this.arrayValue.map((obj: any) => obj.lessonUserRating);

    if (this.mode === 'create') {
      this.ratingAndReportService.createEndOfLessonRatings(lessonUserRatings, this.lessonRatings)
      .pipe(
        switchMap((res: any) => {
           let lesson: LessonUpdateRequest = {
            id: this.lesson.id,
             classroomId: this.lesson.classroomId,
             status: LessonStatus.COMPLETED,
             startingDate: this.lesson.startingDate.toString(),
             duration: this.lesson.duration,
             isRecccuring: this.lesson.isRecccuring,
             answered: "accepted"
           }
          return this.lessonService.update(lesson)
        }),
        switchMap((res: any) => {
          return of(null); //this.classroomService.changeClassroomStatus(this.lesson.classroomId, Status.COMPLETED)
        }),
      ).subscribe(res => {
        this.ratingSubmitted.emit({rated: true});
        this.toast.setShowToastmessage({
          severity: 'success',
          summary: '',
          detail: 'Lesson has been rated and stated as Completed. Students will get notifications.'
        });
          console.log(res);
          this.lessonService.setUpdateListener(true);
          this.calendarService.setUpdateListener(true);
          this.classroomService.setSelectedClassroomPackagesUpdate(true);
      })
    } else if (this.mode === 'edit') {
      // this.lessonRatings.id = this.lesson.id.toString();
      this.ratingAndReportService.updateEndOfLessonRatings(lessonUserRatings, this.lessonRatings)
      .pipe(
        switchMap((res: any) => {
           let lesson: LessonUpdateRequest = {
            id: this.lesson.id,
             classroomId: this.lesson.classroomId,
             status: LessonStatus.COMPLETED,
             startingDate: this.lesson.startingDate.toString(),
             duration: this.lesson.duration,
             isRecccuring: this.lesson.isRecccuring,
             answered: "accepted"
           }
          return this.lessonService.update(lesson)
        })
      ).subscribe(res => {
        this.ratingSubmitted.emit({rated: true});
        this.toast.setShowToastmessage({
          severity: 'success',
          summary: '',
          detail: 'Lesson has been rated and stated as Completed. Students will get notifications.'
        });
        this.lessonService.setUpdateListener(true);
        
        this.calendarService.setUpdateListener(true);
      })
    }


  }

  getCategoryValue(category: string) {
    let value = this.variants.filter((el: Variant) => el.rating.category.toLowerCase() === category)
    return value.length > 0 ? value[0].value : -1;
  }

  getLessonBreakdownOfCategory(category: string) {
    let value = this.lessonUserRatings.filter((el: LessonBreakdown) => el[category as keyof LessonBreakdown] === category)
    return value.length > 0 ? value[0].value : -1;
  }

  getVariantIsChecked(rating: RatingItem) {
    return this.variants.some(el => rating.category.toLowerCase() == el.rating.category.toLowerCase())
  }

  getGradient(chartArea: any, index: number, gradients: any[]) {
    let width, height;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradients[index] === null || width !== chartWidth || height !== chartHeight) {
      width = chartWidth;
      height = chartHeight;
    }
    return gradients[index];
  }

  getProgress(rating: number): string {
    return ((rating * 10 / 100) * 100).toString();
  }

  getProgressPercent(rating: number, copyProgress: any): string {
    let value = 100 - (rating * 10 / 100) * 100 - 5;
    if (value < 0) {
      copyProgress.remove()
    }
    return value.toFixed(0) + "%";
  }

  download(el: any) {
    this.toast.setShowToastmessage({
      severity: 'success',
      summary: '',
      detail: 'Rating will be downloaded shortly. Please wait.'
    });
    setTimeout(() => {
      html2canvas(el).then(canvas => {
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = 200;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF('p', 'mm', 'a4');
        var position = 10; // give some top padding to first page
  
        doc.addImage(imgData, 'PNG', 5, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
  
        while (heightLeft >= 0) {
          position += heightLeft - imgHeight; // top padding for other pages
          doc.addPage();
          doc.addImage(imgData, 'PNG', 5, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save("Lesson Rating for: " + this.loggedInUser.firstName + " " + this.loggedInUser.lastName);
      });
    }, 500);
  }
}
