import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, Message } from 'primeng/api';
import { Observable } from 'rxjs';
import { TeacherApplicationStep4Response } from 'src/app/core/models/teacher.model';
import { GeneralService } from 'src/app/core/services/general.service';
import { TeacherApplicationService } from 'src/app/core/services/teacher-application.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit, OnDestroy {
  public UPLOAD_TEACHER_CV_URL = '/LMS/UploadTeacherCVAsync';
  public UPLOAD_TEACHER_PICTURE_URL = '/LMS/UploadTeacherPictureAsync';
  filesUploaded: boolean = false;
  submitted: boolean = false;
  msgs: Message[] = [];
  submittedFiles: { cv: string; profilePicture: string } = {} as { cv: string; profilePicture: string };
  fileUploadChanged = false;
  private subs = new SubSink();
  constructor(
    private router: Router,
    private teacherService: TeacherApplicationService,
    private toastService: ToastService,
    private generalService: GeneralService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.teacherService.setCurrentStepIndex(3);
    this.subs.add(this.teacherService.getTeacherApplicationStep4().subscribe((res: TeacherApplicationStep4Response) => {
      this.submittedFiles = res;
      this.teacherService.setStepValidWithBoolean(3, this.areFilesSubmitted(), 'teacher-resume-route');

    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.teacherService.setSubmitOnMenuClickListener(false);
  }

  /**
  Submits the resume information.
  @returns {void}
  */
  submitResume() {
    if (!this.areFilesSubmitted()) {
      this.toastService.setShowToastmessage({
        severity: 'warn',
        summary: '',
        detail: 'Please upload both files for your Resume.'
      });
    } else {
      this.toastService.setShowToastmessage({
        severity: 'success',
        summary: '',
        detail: 'Your Resume is updated.'
      });
      this.fileUploadChanged = false;
      this.router.navigateByUrl('/teacher/availability', {
        replaceUrl: true
      });
    }
  }

  goBack() {
    this.router.navigateByUrl('/teacher/experience', { replaceUrl: true });
  }

  getFileName(fileName: string): string {
    return this.generalService.getFileName2(fileName);
  }

  /**
  @function
  @desc Handles the event when a file upload has finished.
  @param {any} event - The event object that is returned after the file upload has finished.
  @param {string} fileKind - A string indicating the type of file that was uploaded, either 'cv' or 'profilePicture'.
  @returns {void}
  */
  uploadFinished(event: any, fileKind: string) {
    console.log('',fileKind);
    switch (fileKind) {
      case 'cv':
        this.submittedFiles.cv = event.filePath.dbPath;
        break;
      case 'photo':
        console.log('',event.filePath.dbPath);
        this.submittedFiles.profilePicture = event.filePath.dbPath;
        break;
      default:
        break;
    }
    this.fileUploadChanged = true;
    this.teacherService.setStepValidWithBoolean(3, this.areFilesSubmitted(), 'teacher-resume-route');
  }

  /**
  Deletes a file from the list of submitted files.
  @param {Event} event - The event that triggers this function.
  @param {string} fileKind - The type of file to be deleted, either 'cv' or 'profilePicture'.
  @returns {void}
  */
  deleteFileClicked(event: Event, fileKind: string) {
    this.submittedFiles[fileKind === 'cv' ? 'cv' : 'profilePicture'] = '';
  }

  /**
  Check if files (CV and profile picture) are submitted
  @returns {boolean} Returns true if both CV and profile picture files are submitted, false otherwise.
  */
  areFilesSubmitted(): boolean {
    return (this.submittedFiles.cv !== '' && this.submittedFiles.profilePicture !== '');
  }
  
  canDeactivate(): Observable<boolean> | boolean {
    if (this.fileUploadChanged) {
      return new Observable((observer: any) => {
        this.confirmationService.confirm({
          header: '',
          key: 'stepLeaveConfirmation',
          message: 'Are you sure you want to leave this page? Your changes will be lost.',
          accept: () => {
            observer.next(true);
            observer.complete();
          },
          reject: () => {
            observer.next(false);
            observer.complete();
          }
        });
      });
    } else {
      return (true);
    }
  }
}
