<!-- <ng-container *ngIf="routeIsAuth">
    <div class="content content--canvas"></div>
    <div class="animated-background w-embed">
        <div class="animatedwrapper">
            <div class="dot dot--one"></div>
            <div class="dot dot--two"></div>
        </div>
    </div>
    <div class="auth-container">
        <div
            class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
            <div class="auth-route">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</ng-container> -->

<!-- <router-outlet></router-outlet> -->

<!-- {{layout$ | json}} -->

    <!-- <router-outlet></router-outlet> -->

    <!-- {{this.routeData.layout}}
    <ng-container *ngIf="this.routeData.layout">
    <ng-container [ngSwitch]="this.routeData.layout">
        <app-top-menu-container-layout *ngSwitchCase="AppLayoutType.Default"></app-top-menu-container-layout>
        <app-login-container-layout *ngSwitchCase="AppLayoutType.Center"></app-login-container-layout>
        <app-layout-sidebar *ngSwitchCase="AppLayoutType.Sidebar"></app-layout-sidebar>
        <router-outlet *ngSwitchCase="AppLayoutType.Blank"></router-outlet>
        <app-top-menu-container-layout *ngSwitchDefault></app-top-menu-container-layout>
    </ng-container>
</ng-container> -->

<router-outlet></router-outlet>

<!-- <ng-container *ngIf="!routeIsAuth">
    <app-header></app-header>
    <app-mobile-menu></app-mobile-menu>
    <div class="p-container-lg pt-8" [ngClass]="{'pt-0' : routeIsAuth}">
     

        <div class="">
            <div *ngIf="!hideLeftMenu" id="left-menu" class="app-content-menu">
                <app-menu></app-menu>
            </div>
            <router-outlet></router-outlet>
        </div>
    </div>
</ng-container> -->
<!-- 
<div *ngIf="!routeIsTeacher" id="top-left-gradient" class="top-left-gradient">
</div>
<app-mobile-menu *ngIf="!routeIsAuth"></app-mobile-menu>
<app-header *ngIf="!routeIsAuth"></app-header>
<div class="app" [ngClass]="{'pt-0' : routeIsAuth}">

    <ng-container *ngIf="routeIsAuth">
        <div class="content content--canvas"></div>
        <div class="animated-background w-embed">
            <div class="animatedwrapper">
                <div class="dot dot--one"></div>
                <div class="dot dot--two"></div>
            </div>
        </div>
    </ng-container>
    <div id="app-content" class="app-content">
        <div *ngIf="!hideLeftMenu" id="left-menu" class="app-content-menu">
            <app-menu></app-menu>
        </div>
        <div id="app-content-page" class="app-content-page" [ngClass]="{'auth-route' : routeIsAuth}">
            <div *ngIf="!routeIsProfile && !routeIsTeacher">
                <router-outlet #o="outlet" (activate)="onActivate($event)"></router-outlet>
            </div>
            <div *ngIf="routeIsProfile">
                <app-profile></app-profile>
            </div>
            <div *ngIf="routeIsTeacher">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
<app-footer *ngIf="!routeIsAuth" class="bottom-footer"></app-footer>
<div class="blur-bg no-visibility" id="blur_bg"></div>
 -->
<!-- primeng toast GLOBAL in app -->
<app-prime-toast></app-prime-toast>
<!-- primeng confirm dialog GLOBAL in app -->
<ng-container>
    <app-confirm-dialog *ngIf="confirmDialogService.isVisible" [rejectBtnIcon]="confirmDialogService.rejectBtnIcon"
        [acceptBtnIcon]="confirmDialogService.acceptBtnIcon" [confirmMessage]="confirmDialogService.confirmMessage"
        [rejectBtnLabel]="confirmDialogService.rejectBtnLabel"
        [acceptBtnLabel]="confirmDialogService.acceptBtnLabel"
        [headerText]="confirmDialogService.headerText"
        [acceptBtnImage]="confirmDialogService.acceptBtnImage"
        [acceptBtnClass]="confirmDialogService.acceptBtnClass"
        [rejectBtnClass]="confirmDialogService.rejectBtnClass"
        [confirmIcon]="confirmDialogService.confirmIcon"
        [showConfirmIcon]="confirmDialogService.showConfirmIcon"
        [extraData]="confirmDialogService.extraData"></app-confirm-dialog>
</ng-container>


<!-- TODO: enable for logged in users -->
 @if (authService.firebaseLoggedIn() && chatService.isChatEnabled()) {
    <app-chat [bubbleMode]="true"></app-chat>
 }