import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Classroom } from 'src/app/core/models/classroom.model';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-goal-steps-box',
  templateUrl: './goal-steps-box.component.html',
  styleUrls: ['./goal-steps-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoalStepsBoxComponent implements OnInit {
  showGoalSteps = false;
  private subs = new SubSink();
  @ViewChild('goalsWrapper', { static: true }) public goalsWrapper = {} as ElementRef;

  @Input() isSmall = false;
  public classrooms: Classroom[] = [];
  public loggedInUser: User = {} as User;
  @Input() studentSelectedClassroom: Classroom | null = null;

  constructor(
    private layoutService: LayoutService,
    private classroomService: ClassroomService,
    public authService: AuthService,
    public generalService: GeneralService,
  ) { }

  ngOnInit(): void {
    this.loggedInUser = this.authService.getLoggedInUser();
    // this.fetchClassrooms();
  }

  ngAfterViewInit() {
    this.subs.add(this.layoutService.sideMenuHeight.subscribe((res) => {
      if (this.goalsWrapper) {
        this.goalsWrapper.nativeElement.style.maxHeight = (res + 'px');
      }
    }));
  }

  toggleGoalSteps() {
    this.showGoalSteps = !this.showGoalSteps;
  }

  getClassrooms() {
    this.classroomService.getLMSUserClassrooms(this.loggedInUser.id).subscribe((response) => {
      this.classrooms = response.map((classroom: Classroom) => ({
        ...classroom,
        classroomLabel: classroom.teacher ? `${classroom.language} - ${classroom.teacher.firstName} ${classroom.teacher.lastName}` : ''
      }));
      console.log(this.classrooms);
      this.setInitialSelectedClassroom();
    });
  }

  setInitialSelectedClassroom() {
    if (this.classrooms && this.classrooms.length > 0) {
      console.log("setInitialSelectedClassroom");
      this.studentSelectedClassroom = this.classrooms[0];
      console.log(this.studentSelectedClassroom);
    }
  }
  private fetchClassrooms(): void {
    this.subs.add(this.classroomService.getLMSUserClassrooms(this.loggedInUser.id).pipe(
      switchMap((classrooms: any) => {
        // this.pendingRequests = [];
        // const availableTeachers: any[] = [];
        // this.notificationsNumber = 0;
        classrooms.forEach((classroom: any) => {
          classroom.lessons.forEach((lesson: any) => {
    
            const teacherName = this.generalService.getShortFullName(classroom.teacher);
          });
        });
    
        this.classrooms = classrooms.map((classroom: Classroom) => ({
          ...classroom,
          classroomLabel: classroom.teacher ? `${classroom.language} - ${classroom.teacher.firstName} ${classroom.teacher.lastName}` : ''
        }));
        // this.availableTeachers = uniqueTeachers;
        // this.selectedTeacher = this.availableTeachers[0];
        // this.showCalendar = true;
        return of(classrooms);
      }),
    ).subscribe((res: any) => {
      setTimeout(() => {
        
        this.setInitialSelectedClassroom();
      }, 5000);
      console.log(this.studentSelectedClassroom);
    }));
  }
}
