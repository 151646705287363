import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ForgotComponent } from './forgot/forgot.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {

    path: '',

    loadComponent: () =>
      import("../auth/auth.component").then(
        (m) => m.AuthComponent
      ),
    children: [
      {
        path: 'login',
        loadComponent: () =>
          import("../auth/login/login.component").then(
            (m) => m.LoginComponent
          ),
      },
      {
        path: 'register',
        loadComponent: () =>
          import("../auth/login/login.component").then(
            (m) => m.LoginComponent
          ),
      },
      { path: 'confirm', component: ConfirmComponent },
      { path: 'forgot', component: ForgotComponent },
      { path: "**", redirectTo: "/auth/login", pathMatch: "full" },
      //   {
      //     path: 'settings',
      //     component: SettingsComponent,
      //   },
      //   {
      //     path: 'package-history',
      //     component: PackageHistoryComponent,
      //   },
      //   {
      //     path: 'notifications',
      //     component: NotificationsProfileComponent,
      //   },
      //   {
      //     path: 'rate-experience',
      //     component: RateExperienceComponent,
      //   },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }