<div class="classrooms">
    <div *ngIf="isLoading" class="uploader-status">
        <mat-spinner class="spinner"></mat-spinner>
    </div>
    <div *ngIf="!isLoading && role=='Teacher'">
        <div class="classrooms-header">
            <div id="all" class="title classrooms-header-active" (click)="viewSection('all')">
                All
            </div>
            <div class="seperator"></div>
            <div id="active" class="title" (click)="viewSection('active')">
                Ongoing
            </div>
            <div class="seperator"></div>
            <div id="completed" class="title" (click)="viewSection('completed')">
                Completed
            </div>
            <div class="seperator"></div>
            <div id="dismissed" class="title" (click)="viewSection('dismissed')">
                Dismissed
            </div>
        </div>
        <app-classrooms [inTrial]="true" [@slideInOut] [filteredClassrooms]="filteredClassrooms"></app-classrooms>
    </div>
</div>