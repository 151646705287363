import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ElementRef, Injectable, inject } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, from, map, Observable, of } from 'rxjs';
import { Classroom, ClassroomType, Level } from '../models/classroom.model';
import { Country, DeviceKind, Dimentions, Language, LanguageToLearn, SocialMedia, Timezone, ToastMessage } from '../models/general.model';
import { PackageDurationHours, PackagePriceHourly, PackageToBuy, PackageType } from '../models/package.model';
import { saveAs } from 'file-saver';
import * as data from '../models/data';
import * as moment from 'moment-timezone';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { User, UserRole } from '../models/user.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Lesson } from '../models/lesson.model';
import { AuthService } from './auth.service';
const API_URL = environment.apiUrl + "/";
const IP_COUNTRY_API = "https://ipapi.co/json/";

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  authService = inject(AuthService);
  private deviceKind$: BehaviorSubject<DeviceKind> = new BehaviorSubject<DeviceKind>({ w576down: false, is576: false, is768: false, is992: false, is1024: false, is1366: false, isBig: true });
  private mobileMenuTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('test');

  public readonly deviceKind: Observable<DeviceKind> = this.deviceKind$.asObservable();
  public readonly mobileMenuTitle: Observable<string> = this.mobileMenuTitle$.asObservable();

  public readonly mltLanguages: Language[] = [
    { name: "English", code: "ENG" },
    { name: "French", code: "FR" },
    { name: "German", code: "GER" },
    { name: "Italian", code: "IT" },
    { name: "Spanish", code: "SP" },
    { name: "Greek", code: "GRE" },
    { name: "Swedish", code: "SW" },
    { name: "Dutch", code: "DUT" },
    { name: "Russian", code: "RUS" },
    { name: "Japanese", code: "JPN" },
    { name: "Korean", code: "KOR" },
    { name: "Chinese", code: "CHI" },
  ]

  public readonly mltLevels = [
    // 'Any',
    Level.A1,
    Level.A2,
    Level.B1,
    Level.B2,
    Level.C1,
    Level.C2,
    Level.NT21,
    Level.NT22,
    Level.TBD,
    Level.BS
  ];

  public readonly languagesToLearn: LanguageToLearn[] = [
    {
      title: "English",
      link: ""
    },
    {
      title: "Frensh",
      link: ""
    },
    {
      title: "German",
      link: ""
    },
    {
      title: "Italian",
      link: ""
    },
    {
      title: "Spanish",
      link: ""
    },
    {
      title: "Greek",
      link: ""
    },
    {
      title: "Swedish",
      link: ""
    },
    {
      title: "Dutch",
      link: ""
    },
    {
      title: "Russian",
      link: ""
    },
    {
      title: "Japanese",
      link: ""
    },
    {
      title: "Korean",
      link: ""
    },
    {
      title: "Chinese",
      link: ""
    }
  ]

  public readonly socialMedia: SocialMedia[] = [
    {
      icon: "facebook.svg",
      link: ""
    },
    {
      icon: "linkedin.svg",
      link: ""
    },
    {
      icon: "youtube.svg",
      link: ""
    },
    {
      icon: "instagram.svg",
      link: ""
    },
    {
      icon: "tweeter.svg",
      link: ""
    },
  ]

  public readonly languageLevels: any[] = [
    {
      levelName: "A1",
      showOnlyOnCode: [],
      visible: true,
    },
    {
      levelName: "A2",
      showOnlyOnCode: [],
      visible: true,
    },
    {
      levelName: "B1",
      showOnlyOnCode: [],
      visible: true,
    },
    {
      levelName: "B2",
      showOnlyOnCode: [],
      visible: true,
    },
    {
      levelName: "C1",
      showOnlyOnCode: [],
      visible: true,
    },
    {
      levelName: "C2",
      showOnlyOnCode: [],
      visible: true,
    },
    {
      levelName: "NT2.1",
      showOnlyOnCode: ['nl'],
      visible: false,
    },
    {
      levelName: "NT2.2",
      showOnlyOnCode: ['nl'],
      visible: false,
    }];

  public readonly packageDurationHours = PackageDurationHours;

  public readonly countries = data.countries
  public readonly timezones = data.timezones
  public readonly languages = data.languages
  public mltPackages: any;
  private refs: any[] = [];

  constructor(
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) { }

  getLanguagesToLearn(): LanguageToLearn[] {
    return this.languagesToLearn;
  }

  getSocialMedia(): SocialMedia[] {
    return this.socialMedia;
  }

  getCountries(): Country[] {
    return data.countries;
  }

  getTimezones(): Timezone[] {
    return data.timezones;
  }

  getPhoneCodes(): any[] {
    return data.phoneCodes;
  }

  getLanguageLevels(): any[] {
    return this.languageLevels;
  }

  public setDevice() {
    var w576down = window.innerWidth < Dimentions.w576;
    var w576up = window.innerWidth >= Dimentions.w576;
    var w768up = window.innerWidth >= Dimentions.w768;
    var w992up = window.innerWidth >= Dimentions.w992;
    var w1024up = window.innerWidth >= Dimentions.w1024;
    var w1366up = window.innerWidth >= Dimentions.w1366;
    var w1500up = window.innerWidth >= Dimentions.w1500;

    var w576 = window.innerWidth <= Dimentions.w576;
    var w768 = window.innerWidth <= Dimentions.w768;
    var w992 = window.innerWidth <= Dimentions.w992;
    var w1024 = window.innerWidth <= Dimentions.w1024;
    var w1366 = window.innerWidth <= Dimentions.w1366;
    var w1500 = window.innerWidth <= Dimentions.w1500;
    var isBig = window.innerWidth > Dimentions.w1366;
    this.deviceKind$.next({
      w576down: w576down,
      is576: w576,
      is768: w768,
      is992: w992,
      is1024: w1024,
      is1366: w1366,
      w576up: w576up,
      w768up: w768up,
      w992up: w992up,
      w1024up: w1024up,
      w1366up: w1366up,
      w1500up: w1500up,
      isBig: isBig
    });
  }

  public slideInElement(id: string) {
    document.getElementById(id)?.classList.remove('no-visibility');
    document.getElementById(id)?.classList.add('yes-visibility');
    document.body.style.overflowY = 'hidden';
  }

  public slideOutElement(id: string) {
    document.getElementById(id)?.classList.remove('yes-visibility');
    document.getElementById(id)?.classList.add('no-visibility');
    document.body.style.overflowY = 'scroll';
  }

  public slideInNativeElement(el: any) {
    el.classList.remove('no-visibility');
    el.classList.add('yes-visibility');
    document.body.style.overflowY = 'hidden';
  }

  public slideOutNativeElement(el: any) {
    el.classList.remove('yes-visibility');
    el.classList.add('no-visibility');
    document.body.style.overflowY = 'scroll';
  }

  getDatesDiff(date1: Date, date2: Date) {
    var diff = (date1.getTime() - date2.getTime()) / 1000;
    var months = diff / (60 * 60 * 24 * 7);
    var weeks = diff / (60 * 60 * 24 * 7);
    var days = diff / (60 * 60 * 24);
    var hours = diff / (60 * 60);
    var minutes = diff / (60);
    return {
      weeks: Math.abs(Math.round(weeks)),
      days: Math.abs(Math.round(days)),
      hours: Math.abs(Math.round(hours)),
      minutes: Math.abs(Math.round(minutes)),
    }
  }

  public hideClassElements(classElements: HTMLCollectionOf<Element>) {
    [].forEach.call(classElements, (el: any) => {
      el.style.display = 'none';
    });
  }

  public showClassElements(classElements: HTMLCollectionOf<Element>, display: string) {
    [].forEach.call(classElements, (el: any) => {
      el.style.display = display;
    });
  }

  public collapseSection(element: any) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // temporarily disable all css transitions
    var elementTransition = element.style.transition;
    element.style.transition = '';

    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we 
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function () {
      element.style.height = sectionHeight + 'px';
      element.style.transition = elementTransition;

      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(function () {
        element.style.height = 0 + 'px';
      });
    });

    // mark the section as "currently collapsed"
    element.setAttribute('data-collapsed', 'true');
  }

  public expandSection(element: any) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // have the element transition to the height of its inner content
    element.style.height = sectionHeight + 'px';

    // mark the section as "currently not collapsed"
    element.setAttribute('data-collapsed', 'false');
  }
  iv: any
  timeout: any;
  toggleSection(sectionElement: any, sectionArrowImgSrc: any, inherit: boolean, note?: { id: string, open: string }) {
    let id = sectionElement.getAttribute('id');
    let setOpen = ''
    if (!note?.id) {
      setOpen = sectionElement.getAttribute('open') === 'false' ? 'true' : 'false'
    } else {
      setOpen = note?.open
    }
    var section = document.getElementById(id);
    sectionElement.setAttribute('open', setOpen);
    if (note?.id) {
      clearInterval(this.iv)
    }
    clearTimeout(this.timeout);

    if (setOpen === 'true') {
      this.timeout = setTimeout(() => {
        if (inherit) {
          sectionElement.style.overflow = "inherit"
        }
      }, 300);
      sectionArrowImgSrc.style.transform = "rotate(180deg)"
      this.expandSection(section)
      if (note?.id) {
        this.iv = window.setInterval(() => { // this shit is to adjust the height of rte..
          if (document.getElementById('notes-section-' + note?.id) && document.getElementById('defaultRTE-2-' + note?.id))
            document.getElementById('notes-section-' + note?.id)!.style.height = document.getElementById('defaultRTE-2-' + note?.id)!.clientHeight + 50 + "px";
        }, 10)
      }
    } else {
      sectionElement.style.overflow = "hidden"
      sectionArrowImgSrc.style.transform = "rotate(0deg)"
      this.collapseSection(section);
      if (note?.id) {
        clearInterval(this.iv)
      }

    }
  }

  getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  refreshComponent(url: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }

  gradientScale(newValue: number, oldValue: number, element: string) {
    if (document.getElementById(element)) {
      let resize;
      let minus = 0;
      let divide = 1;
      if (element === 'classroom-gradient') {
        minus = 600;
        divide = 1.2;
      }
      if (element === 'tutorial-gradient') {
        minus = 1000;
        divide = 0.6;
      }
      if (element === 'guide-gradient') {
        minus = 1000;
        divide = 0.4;
      }
      if (element === 'blog-gradient') {
        minus = 1000;
        divide = 0.8
      }
      if (element === 'top-left-gradient') {
        minus = 0;
        divide = 1.5;
      }
      if (oldValue < newValue) {
        resize = document.getElementById('app-content')!.offsetWidth + document.getElementById('app-content')!.offsetWidth / divide - window.scrollY + minus;
      } else if (oldValue > newValue) {
        resize = document.getElementById('app-content')!.offsetWidth + document.getElementById('app-content')!.offsetWidth / divide - window.scrollY + minus;
      }
      // if(window.scrollY > minus){
      document.getElementById(element)!.style.width = resize + "px";
      document.getElementById(element)!.style.height = resize + "px";
      // }
    }
  }

  slideElements(showModal: boolean, id: string) {
    if (showModal) {
      this.slideInElement('blur_bg');
      this.slideInElement(id);
    } else {
      this.slideOutElement('blur_bg');
      this.slideOutElement(id);
    }
  }

  slideNativeElements(showModal: boolean, el: string) {
    if (showModal) {
      this.slideInElement('blur_bg');
      this.slideInNativeElement(el);
    } else {
      this.slideOutElement('blur_bg');
      this.slideOutNativeElement(el);
    }
  }

  toIsoString(date: Date) {
    return new Date(date.toString().split('GMT')[0] + ' UTC').toISOString()
  }

  getFileName(str: string) {
    var n = str.lastIndexOf('/');
    var n2 = str.lastIndexOf('.');
    var result = str.substring(n + 1, n2);
    return result;
  }

  getTeacherFileName(teacherFile: string) {
    if (teacherFile && teacherFile.lastIndexOf('\\')) {
      let n = teacherFile.lastIndexOf('\\');
      let result = teacherFile.substring(n + 1);
      return result;
    }
    return teacherFile
  }

  getFileName2(str: string) {
    var n = str.lastIndexOf('\\');
    var n2 = str.lastIndexOf('.');
    var result = str.substring(n + 1, n2);
    return result;
  }

  getDomainFileNamePath(str: string) {
    var n = str.lastIndexOf('\\');
    var n2 = str.lastIndexOf('.');
    var result = str.substring(n + 1, n2);
    const convertedString = str.replace(/\\/g, "/");
    return API_URL + convertedString;
  }

  getFileNameAndExtension(filePath: string): string | undefined {
    const fileName = filePath.split('\\').pop();
    return fileName;
  }

  getDayFromNumber(day: number) {
    if (day == 0) return "Sunday"
    if (day == 1) return "Monday"
    if (day == 2) return "Tuesday"
    if (day == 3) return "Wednesday"
    if (day == 4) return "Thursday"
    if (day == 5) return "Friday"
    if (day == 6) return "Saturday"
    return ""
  }

  getMonthFromNumber(day: number) {
    if (day == 0) return "January"
    if (day == 1) return "February"
    if (day == 2) return "Martch"
    if (day == 3) return "April"
    if (day == 4) return "May"
    if (day == 5) return "June"
    if (day == 6) return "July"
    if (day == 7) return "August"
    if (day == 8) return "September"
    if (day == 9) return "October"
    if (day == 10) return "November"
    if (day == 11) return "December"
    return ""
  }
  downloadLink(url: string) {
    this.http.get(url, { responseType: "blob" })
      .subscribe(blob => {
        saveAs(blob, this.getTeacherFileName(url));
      });
  }

  getColorByIndex(index: number) {
    if (index == 0) {
      return "#003f5c"
    }
    if (index == 1) {
      return "#2f4b7c"
    }
    if (index == 2) {
      return "#665191"
    }
    if (index == 3) {
      return "#a05195"
    }
    if (index == 4) {
      return "#d45087"
    }
    if (index == 5) {
      return "#f95d6a"
    }
    if (index == 6) {
      return "#ff7c43"
    }
    if (index == 7) {
      return "#ffa600"
    }
    return "white"
  }

  downLoadfiles(targetUrl: string) {
    this.http.get(targetUrl)
      .subscribe((res: any) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(res.blob());
        a.download = targetUrl;
        // start download
        a.click();
      })
  }

  updateMobileMenuTitle(targetUrl: string) {
    this.mobileMenuTitle$.next(targetUrl);
  }

  public isNullishObject(obj: {}): boolean {
    if (obj === null) {
      return true;
    }
    const isNullish = Object.values(obj).every(value => {
      if (value === null) {
        return true;
      }

      return false;
    });

    return isNullish;
  }

  formatDate(d: Date, separator: string) {
    const yyyy = d.getFullYear().toString();
    const mm = (d.getMonth() + 1).toString();
    const dd = d.getDate().toString();

    return (yyyy + separator + (mm[1] ? mm : "0" + mm[0]) + separator + (dd[1] ? dd : "0" + dd[0]));
  }

  formatDateToDMY(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });

    return formattedDate;
  }

  /**
   * Converts the given date string to the `dd/mm/yyyy` format
   * @param {string} dateString - The date string to be converted.
   * @returns {string} The converted date in `dd/mm/yyyy` format.
   */
  convertToDDMMYYYY(dateString: string): string {
    let date = new Date(dateString);
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  /**
  Converts a date string in dd/MM/yyyy format to ISO format with zero time.
  @param {string} dateString - The date string in dd/MM/yyyy format to convert.
  @returns {string} The ISO format string with zero time.
  */
  convertDateStringToIsoFormatWithZeroTime(dateString: string): string {
    let [day, month, year] = dateString.split("/");
    let isoDateString = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}T00:00:00.000`;
    return isoDateString;
  }

  /**
   * Converts a given datetime string to ISO date format
   * @param {string} dateTimeString - The datetime string to be converted
   * @returns {string} The ISO date string
   */
  convertToIsoDate(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    const formattedDate = date.toISOString();

    return this.convertWithoutTimeAndZ(formattedDate);
  }

  /**
  Converts a date string to an ISO string without time and Z
  @param {string} dateString - The date string to be converted
  @returns {string} The converted ISO string
  */
  convertWithoutTimeAndZ(dateString: string): string {
    const date = new Date(dateString);

    date.setUTCHours(0, 0, 0, 0);
    date.setDate(date.getDate());

    const isoDateString = date.toISOString().replace('Z', '');

    return isoDateString;
  }

  /**
   * Converts a date string to a formatted time string.
   *
   * @param {string} dateString - The date string to convert.
   * @returns {string} A formatted time string in 24-hour format (e.g. "14:30").
   */
  convertToTime(dateString: string) {
    const date = new Date(dateString);
    const options = { hour12: false, hour: 'numeric', minute: 'numeric' };
    return date.toLocaleTimeString('en-US', options as any);
  }

  /**
   * Converts a camelCase string into a spaced string.
   * 
   * @param {string} camelCaseString - The camelCase string to be converted.
   * @returns {string} A spaced string.
   */
  convertCamelCase(camelCaseString: string): string {
    let spacedString = '';

    for (let i = 0; i < camelCaseString.length; i++) {
      const originalChar = camelCaseString[i];
      const upperCaseChar = camelCaseString[i].toUpperCase();

      if (originalChar === upperCaseChar) {
        spacedString += ' ' + originalChar;
      } else {
        spacedString += originalChar;
      }
    }

    return spacedString;
  }

  /**
  Check if a form field is valid.
  @param form - The form to check.
  @param field - The field to check.
  @param tryToSave - Whether the form is being submitted.
  @returns Whether the field is invalid and has been touched, or if the form is being submitted and the field is invalid.
  */
  ifFieldValid(form: UntypedFormGroup, field: string, tryToSave: boolean) {
    return ((form.get(field)?.invalid && form.get(field)?.touched) || (tryToSave && form.get(field)?.invalid));
  }

  getUserLocalTime(timeZoneUTC: string, isHour12 = true) {
    if (!timeZoneUTC) {
      return;
    }

    const filteredTimezones = data.timezones.filter(timezone => timezone.text === timeZoneUTC);
    if (filteredTimezones.length === 0) {
      return;
    }

    const date = new Date();
    const options = {
      timeZone: filteredTimezones[0].utc[0],
      hour: 'numeric',
      minute: 'numeric',
      hour12: isHour12 // set to true to use 12-hour format
    };

    // Check if the current date is within DST period
    const isDstActive = () => {
      const now = new Date();
      const startDst = new Date(now.getFullYear(), 2, 31 - ((5 * now.getFullYear() / 4 + 4) % 7), 1);
      const endDst = new Date(now.getFullYear(), 9, 31 - ((5 * now.getFullYear() / 4 + 1) % 7), 1);
      return now.getTime() >= startDst.getTime() && now.getTime() < endDst.getTime();
    };

    // Adjust the offset if DST is active
    if (isDstActive()) {
      date.setHours(date.getHours() + 1);
    }

    const localTime = new Intl.DateTimeFormat('en-US', options as any).format(date);
    return localTime;
  }

  scaleElementDown(element: ElementRef, transformOrigin: string, zoomValue: number, divElement?: HTMLElement) {
    let zoom = zoomValue;
    var width = 100;
    zoom = zoom - 0.1;
    width = 100 / zoom;
    if (divElement) {

      divElement.style.transformOrigin = transformOrigin;
      divElement.style.transform = "scale(" + zoom + ")";
      divElement.style.width = width + "%";
      // divElement.style.height = width + "vh";
    } else {

      element.nativeElement.style.transformOrigin = transformOrigin;
      element.nativeElement.style.transform = "scale(" + zoom + ")";
      element.nativeElement.style.width = width + "%";
      element.nativeElement.style.height = width + "vh";
    }

  }
  scaleElementReset(element: ElementRef, divElement?: HTMLElement) {
    var zoom = 1;
    var width = 100;
    zoom = zoom;
    width = 100 / zoom;
    if (divElement) {

      divElement.style.transformOrigin = "unset";
      divElement.style.transform = "none";
      divElement.style.width = width + "%";
    } else {
      element.nativeElement.style.transformOrigin = "unset";
      element.nativeElement.style.transform = "none";
      element.nativeElement.style.width = width + "%";
    }

  }

  /**
   * Finds the image of the country with the given phone code.
   *
   * @param {string} phoneCode The phone code of the country.
   * @returns {string} The image of the country, or undefined if the country is not found.
   */
  findCountryImage(phoneCode: string) {
    const countries = this.countries;
    const phoneCodes = this.getPhoneCodes();

    const filteredCountries = countries.filter((country) => {
      const countryCode = phoneCodes.find((pc) => pc.code === phoneCode)?.iso;
      return country.code === countryCode;
    });

    const countryImage = filteredCountries[0]?.image;

    return countryImage;
  }

  findCountryImageByName(countryName: string): string {
    const country = this.getCountries().find(c => c.name === countryName);
    return country ? country.image : '';
  }

  getHeaderName(person: any): string {
    return (person.firstName === 'NotGiven') || (person.firstName === 'Not Given') ? '' : (person.firstName + " " + person.lastName.charAt(0) + ".");
  }

  getShortFullName(person: any): string {
    if (person.lastName === "") {
      return person.firstName;
    } else {
      return person.firstName + " " + person.lastName.charAt(0) + ".";
    }
  }

  /**
   * The function takes a person object and returns their full name by concatenating their first name and
   * last name.
   * @param {any} person - The `person` parameter is an object that represents a person. It should have
   * properties `firstName` and `lastName` which are strings representing the person's first name and
   * last name respectively.
   * @returns a string, which is the full name of the person.
   */
  getPersonFullName(person: any): string {
    if (person.firstName === "" && person.lastName === "") {
      return "";
    } else if (person.firstName === "") {
      return person.lastName;
    } else if (person.lastName === "") {
      return person.firstName;
    } else {
      return person.firstName + " " + person.lastName;
    }
  }

  capitalize(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * Calculates the age from a given date of birth.
   * @param dob The date of birth as a string in the format "YYYY-MM-DDTHH:mm:ss".
   * @returns The age in years.
   */
  calculateAge(dob: string): number {
    const dateOfBirth = new Date(dob);
    const ageMs = Date.now() - dateOfBirth.getTime();
    const ageDate = new Date(ageMs);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    return age;
  }

  /**
   * Sets the default user avatar image.
   * @param event The event that triggered the function.
   */
  setDefaultUserAvatar(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = 'assets/images/default-user-avatar.png';
  }

  convertMinutesToHours(minutes: number): number {
    return minutes / 60;
  }

  convertHoursToMinutesWithSuffix(hours: number, fullSuffix = false): string {
    const decimalPart = hours % 1;
    const wholeHours = Math.floor(hours);
    let minutes = wholeHours * 60;

    if (decimalPart > 0) {
      minutes += decimalPart * 60;
    }

    // Handle special cases where decimalPart is 0.25, 0.50, 0.75, etc.
    if (decimalPart % 0.25 === 0) {
      minutes += (decimalPart * 60 - Math.floor(decimalPart * 60)) * 15;
    }

    // Return zero hours if minutes is zero.
    if (minutes === 0) {
      return '0 hours';
    } else if (minutes < 60) {
      // Return minutes with suffix if less than 60.
      return `${minutes > 0 ? minutes : ''}${fullSuffix && minutes > 0 ? ' Minutes' : 'M'}`;
    } else {
      const hours = Math.floor(minutes / 60);
      const minuteSuffix = fullSuffix ? (minutes === 60 ? ' Minute' : ' Mins') : 'M';
      const hourSuffix = fullSuffix ? (hours === 1 ? ' Hour' : ' Hours') : 'H';

      // Return hours and minutes with suffix.
      if (hours === 0) {
        return `${minutes > 0 ? minutes : ''}${fullSuffix && minutes > 0 ? minuteSuffix : ''}`;
      } else {
        return `${hours}${hourSuffix}${minutes % 60 > 0 ? ` ${minutes % 60}${fullSuffix ? minuteSuffix : 'M'}` : ''}`;
      }
    }
  }

  formatSelectedDateTimeForLesson(selectedDay: Date, selectedTimeSlot: string): string {
    const selectedDateTimeString = `${selectedDay.toDateString()} ${selectedTimeSlot}`;
    const formattedDate = moment.utc(selectedDateTimeString, 'ddd MMM DD YYYY HH:mm').toISOString();
    return formattedDate;
  }

  getMinutesDifference(start: string, end: string): number {
    const format = 'HH:mm A'; // Use 24-hour clock format
    const startTime = moment(start, format);
    const endTime = moment(end, format);

    const diffInMilliseconds = endTime.diff(startTime);
    const diffInMinutes = Math.round(diffInMilliseconds / 1000 / 60);

    return diffInMinutes;
  }

  formatDateWithoutOffset(dateTimeString: string): string {
    return moment.utc(dateTimeString).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  }

  formatLessonStartingDate(dateTimeString: string): string {
    return moment.parseZone(dateTimeString).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  }

  /**
   * The function `navigateToLessonDetails` navigates to the lesson details page based on the classroom
   * type and user role.
   * @param {Classroom} classroom - The `classroom` parameter represents the classroom object that
   * contains information about the classroom, such as its id and type.
   * @param {UserRole} role - The `role` parameter represents the user role, which can be either
   * `TEACHER` or something else.
   */
  navigateToLessonDetails(classroom: Classroom, role: UserRole) {
    const navigationExtras: NavigationExtras = {
      state: { classRoom: classroom }
    };
    if (classroom.type === 'Trial') {
      if (role === UserRole.TEACHER) {
        this.router.navigate(['/dashboard/classrooms/lessons', classroom.id, 'details'], navigationExtras);
      } else {
        // TODO add real lesson
        this.router.navigate(['dashboard/classrooms/lessons', classroom.id, 'info', 'teacher'], {
          state: { classRoom: classroom }
        });
      }

    } else {
      this.router.navigate(['/dashboard/classrooms/lessons', classroom.id, 'details'], navigationExtras);
    }
  }

  /**
  Returns an array of strings that represents a link to a lesson details page.
  @param {Classroom} classroom - The classroom object that the lesson belongs to.
  @param {UserRole} role - The user's role in the classroom.
  @returns {string[]} An array of strings that represents a link to a lesson details page.
  */
  getLessonDetailsLink(classroom: Classroom, role: UserRole): string[] {
    let link: string[] = ['/dashboard/classrooms/lessons', classroom.id, 'details'];
    if (classroom.type.toLowerCase() === ClassroomType.TRIAL.toLowerCase() && role === UserRole.STUDENT) {
      link = ['/dashboard/classrooms/lessons', classroom.id, 'info', 'teacher'];
    }
    return link;
  }

  /**
  Opens a dialog box with the specified component, width, and height.
  @param {any} dialogService - The dialog service object.
  @param {any} component - The component to be displayed in the dialog box.
  @param {any} width - The width of the dialog box.
  @param {any} [dialogData] - Optional data to be passed to the dialog box component.
  @param {any} [appendDialogActionsTo] - Optional ID of an HTML element where the dialog box actions should be appended.
  @param {function} [resultHandler] - Optional function to handle the result of the dialog box.
  @param {string} [height] - Optional height of the dialog box.
  */
  public openDialogWithComponent(dialogService: any, component: any, width: any, dialogData?: any, appendDialogActionsTo?: any,
    resultHandler?: (result: any) => void, height?: string) {
    this.refs.forEach(ref => ref.close());
    this.refs = [];
    let centerX = '50%';
    let centerY = '50%';
    if (appendDialogActionsTo) {
      if (Object.keys(appendDialogActionsTo).length > 0) {
        const element = document.querySelector('#' + appendDialogActionsTo);
        const rect = element!.getBoundingClientRect();
        centerX = (rect.left + 10 + rect.width / 2 - width / 2) + 'px';
        centerY = (rect.top + window.scrollY) + 'px'; // Add window.scrollY to account for scrolling
      }
    }

    if (!width) {
      width = 290;
    }

    const dialogRef = (dialogService.open(component, {
      header: '',
      width: this.containsOnlyNumbers(width) ? width + 'px' : width,
      height: height ? height : 'auto',
      showHeader: false,
      dismissableMask: true,
      modal: true,
      contentStyle: {
        "max-width": "100%", "max-height": height ? height : "400px", "overflow": "auto", "border-radius": "10px", "padding": "0px",
      },
      style: appendDialogActionsTo ? { 'left': centerX, 'top': centerY, 'position': `fixed` } : {},
      baseZIndex: 10000,
      maskStyleClass: 'transparent-mask',
      data: { dialogData: dialogData },
    }));
    this.refs.push(dialogRef);
    dialogRef.onClose.subscribe((data: any) => {
      console.log('Dialog closed with data:', data);
      if (resultHandler) {
        resultHandler(data);
      }
    });
  }

  destroyComponent(componentRef: any) {
    if (componentRef) {
      componentRef.destroy();
    }
  }

  navigateToBookingSystem() {
    this.router.navigateByUrl('/dashboard/calendar/booking-system');
  }

  isObjectEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  isDayInThePast(dayNumber: number, currentDate: Date): boolean {
    const dateString = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + dayNumber;
    const today = new Date();
    const currentDateFormatted = new Date(dateString);

    // Set hours, minutes, seconds, and milliseconds to 0
    today.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    return currentDateFormatted < today;
  }

  getPercentScreenWidthInPixels(percentageVal = 0.8): number {
    const screenWidthInPixels = window.innerWidth;
    const percentage = percentageVal;
    const result = screenWidthInPixels * percentage;
    return result;
  }

  containsOnlyNumbers(input: string): boolean {
    // Regular expression pattern to match only numbers
    const pattern = /^[0-9]+$/;

    return pattern.test(input);
  }

  // Method to navigate to the lesson details page
  navigateToBuyPackage(classroom?: Classroom) {
    const navigationExtras: NavigationExtras = {};
    if (classroom) {
      navigationExtras.queryParams = { 'classroom': JSON.stringify(classroom) };
    }
    this.router.navigate(['dashboard/buy-package'], navigationExtras);
  }

  openExternalLink(url: string): void {
    window.open(url, "_blank");
  }

  replaceImportedWithDash(val: string): string {
    if (!val) {
      return '';
    }
    if (val === "Imported" || val.includes('Not Given')) {
      return "-";
    }

    return val;
  }

  replaceNotGivenWith(val: string, replaceStr?: string): string {
    if (!val) {
      return '';
    }
    if (val === "Imported" || val.includes('Not Given')) {
      return replaceStr ? replaceStr : "-";
    }

    return val;
  }

  getSafeSkypeUrl(url: string): SafeUrl {
    const skypeUrl = `skype:` + url;
    return this.sanitizer.bypassSecurityTrustUrl(skypeUrl);
  }

  sortByStartingDate(a: Lesson, b: Lesson): number {
    const startDateA = moment(a.startingDate);
    const startDateB = moment(b.startingDate);
    if (startDateA.isBefore(startDateB, 'minute')) {
      return -1;
    } else if (startDateA.isAfter(startDateB, 'minute')) {
      return 1;
    } else {
      return 0;
    }
  }

  sortLessonsByDate(a: Lesson, b: Lesson): number {
    const dateA = moment(a.startingDate);
    const dateB = moment(b.startingDate);
    return dateB.diff(dateA);
  }

  extractCountryPrefix(phoneNumber: string | undefined): string {
    if (!phoneNumber) {
      return '';
    }

    const parts = phoneNumber.split(' ');
    if (parts.length > 0) {
      return parts[0]; // remove the "+" prefix
    }

    return '';
  }

  getDefaultPhoneCode(): void {
    const ukPhoneCode = this.getPhoneCodes().find(el => el.iso === 'GB');
    return ukPhoneCode || this.getPhoneCodes()[0] || '';
  }

  extractPhoneNumber(phoneNumber: string | undefined): string {
    if (!phoneNumber) {
      return '';
    }

    const parts = phoneNumber.split(' ');
    if (parts.length > 1) {
      return parts[1]; // remove the "+" prefix
    }

    return '';
  }

  getLessonTime(lessonDate: string | Date, timeFormat: string = 'HH:mm') {
    let lessonTime;
    let lessonDateString = typeof lessonDate === 'string' ? lessonDate : moment(lessonDate).toISOString();

    if (lessonDateString.endsWith('Z')) {
      lessonTime = moment.utc(lessonDateString).format(timeFormat);
    } else {
      lessonTime = moment(lessonDateString).format(timeFormat);
    }
    return lessonTime;
  }

  sortLessonsByRecentFirst(a: Lesson, b: Lesson): number {
    const dateB = moment(b.startingDate);
    const dateA = moment(a.startingDate);
    return dateB.diff(dateA);
  }

  isWithinTimeRange(dateString: string, timeRange: number): boolean {
    const now = moment();
    const date = moment(dateString);
    const minutesDifference = Math.abs(now.diff(date, 'minutes'));
    const hoursDifference = minutesDifference / 60;
    console.log(hoursDifference);

    // check if the date is in the past
    const isPast = now.isAfter(date);
    console.log(isPast);

    // return true only if the date is NOT in the past and the difference in hours is within the time range
    return !isPast && (hoursDifference >= timeRange);
  }

  goToGoogleMeetLink(lesson: Lesson): void {
    if (lesson.googleMeetURL) {
      const urlPattern = /^(http|https):\/\/[^ "]+$/;
      if (urlPattern.test(lesson.googleMeetURL)) {
        const sanitizedURL = encodeURI(lesson.googleMeetURL);
        window.open(sanitizedURL, "_blank");
      } else {
        console.error("Invalid Google Meet URL for the lesson.");
      }
    } else {
      console.error("Google Meet URL is missing for the lesson.");
    }
  }

  goToBellbirdLink(lesson: Lesson): void {
    if (lesson.bellBirdMeetingURL) {
      const urlPattern = /^(http|https):\/\/[^ "]+$/;
      if (urlPattern.test(lesson.bellBirdMeetingURL!)) {
        const sanitizedURL = encodeURI(lesson.bellBirdMeetingURL!);
        window.open(sanitizedURL, "_blank");
      } else {
        console.error("Invalid Google Meet URL for the lesson.");
      }
    } else {
      console.error("Google Meet URL is missing for the lesson.");
    }
  }

  goToJitsiMeetLink(lesson: Lesson): void {
    if (lesson) {
      this.router.navigate(['/meet'], { queryParams: { id: lesson.id, classroomId: lesson.classroomId } });
    } else {
      console.error("Google Meet URL is missing for the lesson.");
    }
  }

  convertTimezoneValueToText(timezone: string) {
    return (data.timezones.find((el: Timezone) => el.utc.includes(timezone)));
  }

  isWithinMinutes(minutes: number = 15, time: string, dateTime: string): boolean {
    const currentTime = moment();
    const targetDateTime = moment(dateTime);

    // Parse the target time
    const [hours, minutesOfDay] = time.split(':').map(Number);
    const targetTime = moment().hours(hours).minutes(minutesOfDay);

    // Check if the target date is today
    if (!targetDateTime.isSame(currentTime, 'day')) {
      return false;
    }

    // Calculate the time difference in minutes
    const timeDifference = targetTime.diff(currentTime, 'minutes');

    // Check if the time difference is within the specified minutes
    return Math.abs(timeDifference) <= minutes;
  }

  getCurrentTime(timezone: string) {
    // Get the offset value from the JSON data
    const timezoneData = data.timezones.find((tz: Timezone) => tz.utc.includes(timezone));
    if (!timezoneData) {
      throw new Error('Timezone not found in data.');
    }
    const offset = timezoneData.offset;

    // Create a moment object with the current timestamp
    const currentMoment = moment();

    // Apply the offset to the moment object
    const convertedMoment = currentMoment.utcOffset(offset);

    // Format the converted moment object to display the current time
    const currentTime = convertedMoment.format('HH:mm');

    return currentTime;
  }

  getCurrentTimeInTimezone(timezone: string): string {
    const currentTime = moment(); // Use the current time
    const date = moment.tz(currentTime, timezone);
    return date.format('YYYY-MM-DDTHH:mm:ss');
}

  convertTimeToHourOfDayFormat(time: string, timezone: string): string {
    const date = moment.tz(moment(), timezone);
    return date.format('hh:mm A');
  }

  getTimeDifferenceInMinutes(date1: string, date2: string): number {
    const userTimezone = this.authService.getLoggedInUser().timeZone;
    const momentDate1 = moment.tz(date1, userTimezone); // Assuming date1 is in UTC timezone
    const momentDate2 = moment.tz(date2, userTimezone); // Assuming date2 is in UTC timezone
    const differenceInMinutes = momentDate1.diff(momentDate2, 'minutes');
    return differenceInMinutes;
}

  isDifferenceWithin30Minutes(date1: string, date2: string, lesson: Lesson): boolean {
    const endTime = moment(lesson.startingDate).add(lesson.duration, 'hours');
    const differenceInMinutes = this.getTimeDifferenceInMinutes(date1, date2);
    const lessonDurationInMinutes = lesson.duration * 60;
    // If date2 is before date1 or endTime, consider the difference as 30 minutes
    if (moment(date2).isBefore(date1) || moment(date2).isBefore(endTime)) {
      return Math.abs(differenceInMinutes) <= lessonDurationInMinutes;
    }

    // Calculate the maximum allowable time difference including the lesson duration and 30 minutes
    const maxAllowedDifference = lesson.duration * 60 + 30; // Convert lesson duration to minutes

    // Check if the absolute difference between date1 and date2 is within the allowable range
    return Math.abs(differenceInMinutes) <= maxAllowedDifference;
}



  isWithin30Mins(lesson: Lesson, user: User) {
    const userTimeZone = this.convertTimezoneValueToText(user.timeZone!).text;
    const userLocalTime = this.getUserLocalTime(this.convertTimezoneValueToText(user.timeZone!).text, false)!;

    const currentTimeInUserTimezone = this.getCurrentTime(user.timeZone!);
    const lessonStartingDateInUserTimezone = lesson.startingDate.toString();
    // console.log(this.showTimeDifference(userTimeZone, lessonStartingDateInUserTimezone));
    return this.isWithinMinutes(30, currentTimeInUserTimezone, lessonStartingDateInUserTimezone);
  }

  showTimeDifference(startTime: string, endTime: string): string {
    const startMoment = moment(startTime, 'HH:mm');
    const endMoment = moment(endTime, 'HH:mm');

    const duration = moment.duration(endMoment.diff(startMoment));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;

    return `${hours} hours and ${minutes} minutes`;
  }

  getCountryCode(): Observable<string> {
    return this.http.get<any>(IP_COUNTRY_API).pipe(
      map(response => {
        if (response) {
          return response;
        } else {
          throw new Error('Country code not found in response');
        }
      })
    );
  }

}
