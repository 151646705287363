import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-statistics',
  templateUrl: './progress-statistics.component.html',
  styleUrls: ['./progress-statistics.component.scss']
})
export class ProgressStatisticsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
