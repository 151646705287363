import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ScrollPanel } from 'primeng/scrollpanel';
import { Classroom } from 'src/app/core/models/classroom.model';
import { FilterPipe } from 'src/app/core/pipes/filter.pipe';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-library-left-sidebar',
  templateUrl: './library-left-sidebar.component.html',
  styleUrls: ['./library-left-sidebar.component.scss'],
  providers: [FilterPipe]
})
export class LibraryLeftSidebarComponent implements OnInit {
  private subs = new SubSink();
  isCollapsed = false;
  showActiveClassrooms = false;
  term: any;
  @Input() libFolders: any;
  @Input() classrooms: any;
  @Input() autoSelectFirstClassroom = false;
  @Input() leftSideHeight!: string;
  @Input() firstListItemTitle = 'My Library';
  @Input() showMyNotes = false;
  @Output() myFilesSelected = new EventEmitter();
  @Output() classroomSelected = new EventEmitter();
  @Output() collapsed = new EventEmitter();
  @ViewChild('myFilesDiv', { static: false }) public myFilesDiv = {} as ElementRef;
  @ViewChild('op', { static: false }) dayOverlayPanel: OverlayPanel = {} as OverlayPanel;
  @ViewChild('scrollPanel', { static: true }) scrollPanel: ScrollPanel = {} as ScrollPanel;
  filteredClassRooms: Classroom[] = [];
  selectedClassroom = {} as Classroom;
  isLarge = false;
  constructor(
    public classroomService: ClassroomService,
    public authService: AuthService,
    private generalService: GeneralService,
    private layoutService: LayoutService,
  ) { }

  ngOnInit(): void {
    this.subs.add(this.layoutService.sideMenuHeight.pipe().subscribe(res => {
    }));
    this.subs.add(this.generalService.deviceKind.pipe().subscribe(res => {
      if (res) {
        this.isLarge = res.w992up as boolean;
        if (res.w768up) {
          // this.renderer.addClass(this.document.getElementsByClassName('app')[0], 'h-full');
        } else {
        }
      }
    }));
  }

  ngOnChanges(): void {
    // Set the filteredClassRooms array to the classrooms array
    this.filteredClassRooms = this.classrooms;
  
    // Check if the user is a student
    if (this.authService.isStudent) {
      // Set showActiveClassrooms to true
      this.showActiveClassrooms = false;
      this.toggleSwitch({ checked: false });
    }
  
    // Check if filteredClassRooms and user is a teacher
    if (this.filteredClassRooms && this.authService.isTeacher) {
      // Sort the classrooms array by first name and assign it to filteredClassRooms
      this.filteredClassRooms = this.classroomService.sortClassroomsByFirstName(this.classrooms);
      this.toggleSwitch({ checked: false });
      // Call toggleSwitch method with checked: false
    }
  
    // Prepare the collapsed state for the student
    this.prepareCollapsedForStudent();
  
    // Check if autoSelectFirstClassroom is true
    if (this.autoSelectFirstClassroom) {
      // Check if filteredClassRooms is not null or empty
      if (this.filteredClassRooms && !this.generalService.isNullishObject(this.filteredClassRooms)) {
        // Log the first element of filteredClassRooms to the console
        console.log(this.filteredClassRooms[0]);
        // Call classRoomSelected method with the first element of filteredClassRooms
        this.classRoomSelected(this.filteredClassRooms[0]);
      }
    } else {
      // Check if the user is a teacher
      if (this.authService.isTeacher) {
        // Call addMyFilesClass method
        this.addMyFilesClass();
      }
    }
  
    // Check if scrollPanel exists
    if (this.scrollPanel) {
      // Call refresh method on scrollPanel
      this.scrollPanel.refresh();
    }
  }

  ngAfterViewInit() {
    this.prepareCollapsedForStudent();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  get hasSelectedClassroom() {
    return Object.keys(this.selectedClassroom).length > 0;
  }

  onMyFilesSelected() {
    this.addMyFilesClass();
    this.selectedClassroom = {} as Classroom;
    this.myFilesSelected.emit(true);
    if (!this.generalService.isNullishObject(this.dayOverlayPanel)) {
      this.dayOverlayPanel.hide();
    }
  }

  classRoomSelected(classroom: Classroom) {
    if (this.authService.isTeacher) {
      this.removeMyFilesClass();
    }
    this.selectedClassroom = classroom;
    this.classroomSelected.emit(classroom);
    if (!this.generalService.isNullishObject(this.dayOverlayPanel)) {
      this.dayOverlayPanel.hide();
    }
  }

  removeClass() {
    [].forEach.call(document.getElementsByClassName('classroom-title'), (el: any) => {
      el.classList.remove('link-main-color-active');
    })
  }

  addMyFilesClass() {
    if (this.myFilesDiv && this.myFilesDiv.nativeElement) {
      this.myFilesDiv.nativeElement!.classList.add('link-main-color-active')
    }
    // document.getElementById('my-files')!.classList.add('link-main-color-active');
  }

  removeMyFilesClass() {
    const myFilesElement = document.getElementById('my-files');
    if (myFilesElement && myFilesElement.classList.contains('link-main-color-active')) {
      myFilesElement.classList.remove('link-main-color-active');
    }
  }

  collapseNotesLeftSide() {
    this.isCollapsed = !this.isCollapsed;
    this.collapsed.emit(this.isCollapsed);
  }

  toggleSwitch(event: { checked: boolean }) {
    if (!event.checked) {
      this.filteredClassRooms = this.classrooms.filter((el: Classroom) => el.status.toLowerCase() === this.classroomService.ClassroomStatuses.ON_GOING.toLowerCase());
    }
    else {
      this.filteredClassRooms = this.classrooms;
    }
  }

  prepareCollapsedForStudent() {
    if (this.authService.isStudent && this.filteredClassRooms && this.filteredClassRooms.length === 1) {
      // this.isCollapsed = false;
      this.collapseNotesLeftSide();
    }
  }

  toggleDropdown(event: Event) {
    event.stopPropagation(); // prevent the click event from bubbling up to the parent element
    const dropdown = document.querySelector('.dropdown-content');
    dropdown!.classList.toggle('show');
  }

}
