import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Injector, input, Input, Output, signal } from '@angular/core';
import { toObservable } from "@angular/core/rxjs-interop";
import { PackagesModel, PackageType } from "src/app/core/models/package.model";

@Component({
    selector: 'app-buy-package-suggestion-box',
    templateUrl: './buy-package-suggestion-box.component.html',
    styleUrl: './buy-package-suggestion-box.component.css',
})
export class BuyPackageSuggestionBoxComponent {

    show = signal(true);
    public defaultPackages = PackagesModel.getDefaultPackages();
    private injector = inject(Injector);

    preselectedPackage = input(this.defaultPackages[1]);
    @Output() switchBackToPackageTypeSelected = new EventEmitter<any>();

    selectedPackageType = this.defaultPackages[0].type;
    packageTypeEnum = PackageType;

    filterOutPackageTypes = this.getFilterOutPackageTypes(this.preselectedPackage().type);

    ngOnInit() {
        this.observePreselectedPackageChanges();
    }

    public selectPackageType(type: any) {
        this.selectedPackageType = type;
    }

    getPackages(types: string[]) {
        const defaultPackages = this.defaultPackages;
        return defaultPackages.filter(p => this.filterOutPackageTypes.includes(p.type));
    }

    public getSelectedPackage() {
        return this.defaultPackages.find(pkg => pkg.type === this.selectedPackageType);
    }

    switchBackToPackageType() {
        this.switchBackToPackageTypeSelected.emit(this.getSelectedPackage());
    }

    private observePreselectedPackageChanges() {
        toObservable(this.preselectedPackage, {
            injector: this.injector
        }).subscribe({
            next: (preselectedPackage) => {
                this.filterOutPackageTypes = this.getFilterOutPackageTypes(preselectedPackage.type);
                this.selectedPackageType = this.getNextPackageType(preselectedPackage.type);
            }
        });
    }

    private getFilterOutPackageTypes(type: string): string[] {
        switch (type) {
            case this.packageTypeEnum.REGULAR:
                return [this.packageTypeEnum.FLEX, this.packageTypeEnum.PREMIUM];
            case this.packageTypeEnum.FLEX:
                return [this.packageTypeEnum.PREMIUM];
            case this.packageTypeEnum.PREMIUM:
                return [];
            default:
                return [];
        }
    }

    private getNextPackageType(type: string): PackageType {
        switch (type) {
            case this.packageTypeEnum.REGULAR:
                return this.packageTypeEnum.FLEX;
            case this.packageTypeEnum.FLEX:
                return this.packageTypeEnum.PREMIUM;
            default:
                return this.packageTypeEnum.REGULAR;
        }
    }
}

