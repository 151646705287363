import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Classroom } from 'src/app/core/models/classroom.model';
import { Lesson, LessonPostRequest, LessonStatus, LessonUpdateRequest } from 'src/app/core/models/lesson.model';
import { LessonFullRating } from 'src/app/core/models/rating.model';
import { UserRole } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { LessonService } from 'src/app/core/services/lesson.service';
import { RatingAndReportService } from 'src/app/core/services/rating-and-report.service';

@Component({
  selector: 'app-lesson-popup',
  templateUrl: './lesson-popup.component.html',
  styleUrls: ['./lesson-popup.component.scss']
})
export class LessonPopupComponent implements OnInit {
  @Input() inScheduler: boolean = false;
  @Input() lesson: Lesson = {} as Lesson;
  @Input() position: string = "";
  @Input() right: string = "";
  @Input() isScheduler: boolean = false;
  @Output() closeLessonPopup = new EventEmitter<{ show: boolean }>();
  @Output() lessonDeleted = new EventEmitter<{ lesson: Lesson }>();
  @Output() statusChanged = new EventEmitter<any>();
  @Output() reschedule = new EventEmitter<any>();
  @ViewChild('lessonRating')
  public lessonRating: any;
  public role: UserRole = {} as UserRole
  tryTodelete: boolean = false;
  tryToChangeStatus: boolean = false;
  recMsg: string = "";
  showLessonPopup: boolean = false;
  classroomId: string = "";
  classroom: Classroom = {} as Classroom;
  showRating: boolean = false;
  showRatingView: boolean = false;
  ratingMode = "create";
  @Input() showBg: boolean = true;

  constructor(
    public router: Router,
    private calendarService: CalendarService,
    private lessonService: LessonService,
    private classroomService: ClassroomService,
    private generalService: GeneralService,
    private ratingAndReportService: RatingAndReportService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.role = this.authService.getUserRole();
    this.lessonService.getLessonClassroom(this.lesson.id).pipe(take(1)).subscribe(res => {
      this.classroomId = res[0].id
      this.classroom = res[0];
    })
  }

  getLessonRating() {
  }

  closePopup() {
    this.closeLessonPopup.emit({ show: false });
  }

  toggleDelete(rec?: boolean) {
    this.tryTodelete = !this.tryTodelete
    this.recMsg = rec ? "All recurrance lessons will also be deleted" : "";
  }

  showChangeStatus() {
    this.tryToChangeStatus = !this.tryToChangeStatus
  }

  getLessonColor(status: LessonStatus): string {
    return this.calendarService.getEventGradientColor(status);
  }

  closeLessonPopupOnClickOutside() {
    document.addEventListener('click', (event: any) => {
      setTimeout(() => {// i dont know why..
        let ignoreClickOnMeElement = document.getElementById('lesson-' + this.lesson.id);
        var isClickInsideElement = ignoreClickOnMeElement?.contains(event.target);
        if (!isClickInsideElement) {
          // this.showLessonPopup = false;
          this.generalService.slideOutElement('blur_bg')
        }
      }, 0);
    });
  }

  openChangeStatusPopup(el: any) {
    el.style.display = 'flex';
  }

  closeChangeStatusPopup(el: any) {
    el.style.display = 'none';
  }

  getChangeStatuses(status: LessonStatus) {
    let statusesNamesToReturn: LessonStatus[] = [];
    if (status === LessonStatus.COMPLETED) {
      statusesNamesToReturn.push(LessonStatus.CANCELED);
      statusesNamesToReturn.push(LessonStatus.NO_SHOW);
    }
    else if (status === LessonStatus.ARRANGED) {
      statusesNamesToReturn.push(LessonStatus.COMPLETED);
      statusesNamesToReturn.push(LessonStatus.CANCELED);
      statusesNamesToReturn.push(LessonStatus.NO_SHOW);
    }
    else if (status === LessonStatus.NO_SHOW) {
      statusesNamesToReturn.push(LessonStatus.REARRANGE);
      statusesNamesToReturn.push(LessonStatus.CANCELED);
    } else {
      statusesNamesToReturn.push(LessonStatus.REARRANGE);
      statusesNamesToReturn.push(LessonStatus.NO_SHOW);
    }
    return statusesNamesToReturn;
  }

  ratingToView: LessonFullRating = {} as LessonFullRating;
  viewRating() {
    // this.lessonService.setLessonRatingListener(this.lesson)
    this.ratingAndReportService.getRatingExtras(this.lesson, this.classroom).subscribe(res => {
      console.log(res)
      this.ratingToView = res[0]
      console.log(this.ratingToView)
      this.ratingMode = "view"
      this.showRatingView = true;
      this.generalService.slideElements(true, this.lessonRating.nativeElement.id!)
    })

  }

  public changeToStatus(status: any) {
    if (status === LessonStatus.COMPLETED) {
      this.ratingAndReportService.ratingIsOpen = true;
      // if (!this.inScheduler) {
      this.ratingMode = "create"
      this.showRating = true;
      console.log(this.showBg)
      if (!this.showBg) {
        this.generalService.slideInElement(this.lessonRating.nativeElement.id!)
      } else {
        this.generalService.slideElements(true, this.lessonRating.nativeElement.id!)
      }
      // this.generalService.slideInElement(this.lessonRating.nativeElement.id!)
      // } else {
      //   console.log("in")
      // this.lessonService.setLessonRatingListener(this.lesson)
      // }
    } else if (status === LessonStatus.REARRANGE) {
      if (this.isScheduler) {
        this.reschedule.emit({ lesson: this.lesson })
      }
    } else {
      // TODO
      this.lesson.status = status;
      let lesson: LessonUpdateRequest = {
        id: this.lesson.id,
        classroomId: this.lesson.classroomId,
        status: status,
        startingDate: this.generalService.toIsoString(this.lesson.startingDate),
        duration: this.lesson.duration,
        isRecccuring: this.lesson.isRecccuring,
        answered: this.lesson.answered
      }
      this.lessonService.update(lesson).subscribe(res => {
        this.statusChanged.emit();
        this.lessonService.setUpdateListener(true);
      })
    }
  }

  join() {
    console.log(this.lesson)
    window.open(this.lesson.bbbLink, "_blank");
  }

  onReschedule() {
    if (this.isScheduler) {
      this.reschedule.emit({ lesson: this.lesson })
    } else {
      this.lessonService.lessonToReschedule = this.lesson;
      console.log(this.classroom)
      this.classroomService.classroomToArrangeLesson = this.classroom;
      this.router.navigate(['/reschedule'])
    }
  }

  deleteLesson() {
    // this.lessonService.setDeleteListener(true);
    this.toggleDelete();
    this.lessonDeleted.emit({ lesson: this.lesson })
    // this.showLessonPopup = false;
  }

  closeRating(event: any) {
    if (event.rated) {
      this.lesson.status = LessonStatus.COMPLETED
      this.closePopup()
    }
    this.ratingAndReportService.ratingIsOpen = false;
    this.generalService.slideElements(false, this.lessonRating.nativeElement.id)
  }

  submitRating() {
    this.ratingAndReportService.ratingIsOpen = false;
  }

  navigateToClassroom() {
    console.log("/classrooms/lessons/" + this.classroomId)
    this.router.navigate(["/classrooms/lessons/" + this.classroomId])
  }
}
