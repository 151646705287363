import { Injectable } from '@angular/core';
import { Certificate } from '../models/certificate.model';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User, UserAvailability, UserAvailabilityOff, UserAvailabilityType, UserStatus } from '../models/user.model';
const BACKEND_URL_LMS = environment.apiUrl + "/LMS/";
@Injectable({
  providedIn: 'root'
})
export class CertificateService {
  // public dummyCertificates: Certificate[] = [
  //   {
  //     id: "1",
  //     language: "English",
  //     level: "B1",
  //     hours: "12",
  //     type: "completion",
  //     filePath: "/assets/files/Dia.pdf"
  //   },
  //   { 
  //     id: "2",
  //     language: "English",
  //     level: "B2",
  //     hours: "12",
  //     type: "completion",
  //     filePath: "/assets/files/Dia.pdf"
  //   },
  //   {
  //     id: "3",
  //     language: "Spanish",
  //     level: "A1",
  //     hours: "2",
  //     type: "attendance",
  //     filePath: "/assets/files/Dia.pdf"
  //   },
  //   {
  //     id: "4",
  //     language: "Italian",
  //     level: "A1",
  //     hours: "4",
  //     type: "attendance",
  //     filePath: "/assets/files/Dia.pdf"
  //   },
  // ]
  constructor(private http: HttpClient) { }

  public getListOfCertifications() {
    return this.http.post<any>(BACKEND_URL_LMS + 'GenerateListOfCertifications', {});
  }

  public generateCertificationPdf(level: string, language: string, type: string) {
    const requestBody = {
      level: level,
      language: language
    };
    if (type == "completion") {
      return this.http.post<any>(BACKEND_URL_LMS + 'CreateCertOfCompletionPDFFile', requestBody, { responseType: 'text' as 'json' });
    } else {
      return this.http.post<any>(BACKEND_URL_LMS + 'CreateCertOfAttendancePDFFile', requestBody, { responseType: 'text' as 'json' });
    }
  }
}
