import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-block-viewer',
  template: `
  <div class="block-section" [class]="blockClass">
      <div [class]="headerBlockClass + 'py-2 block-header'" *ngIf="header" [ngStyle]="headerBackgroundImage && {'background-image': 'url(' + headerBackgroundImage + ')'}">
          <span class="block-title w-full align-items-center " [class]="headerClass" [ngClass]="{ 'justify-content-between':secondaryTitleRight || actionButtonText }">
              <span class="header-icon flex mr-2" *ngIf="headerIcon"><img src="/assets/icons/{{headerIcon}}.svg" height="21"></span>
              <span tooltipPosition="top" [pTooltip]="tooltipLabel ? tooltipLabel : null" [class]="headerTextClass ? headerTextClass : 'font-sm'">{{header}}</span>
              <span class=" text-base font-medium" *ngIf="secondaryTitleRight">{{secondaryTitleRight}}</span>
              <span class="badge-new" *ngIf="new">New</span>
          </span>
          <ng-container *ngIf="showCloseDialogIcon">
          <button type="button" (click)="closeDialogIconClicked()" class=" p-dialog-header-maximize p-link"><span class="p-dialog-header-close-icon pi pi-times  p-1 border-circle"></span></button>
          </ng-container>
          <div class="block-actions text-sm" *ngIf="actionButtonText">
              <a tabindex="0" (click)="actionRoute($event)" [ngClass]="{'block-action-active': true, 'disabled-link': disabledButton}"><span>{{actionButtonText}}</span></a>
          </div>

          
          <ng-container *ngIf="backButtonLabel">

          <div class="flex align-items-center justify-content-center absolute" style="z-index:4;">
          <g-header-back-button (click)="goBack()" [label]="backButtonLabel"></g-header-back-button>
        </div>
           </ng-container>

      </div>
      <ng-content select="[slot=start]"></ng-content>
      <div class="block-content">
          <div [class]="containerClass"  [ngStyle]="{'max-height.px': containerHeight}">
              <ng-content></ng-content>
          </div>
      </div>
  </div>
`,
  styleUrls: ['./block-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockViewerComponent implements OnInit {

  @Input() header!: string;
  @Input() headerBackgroundImage!: string;
  @Input() containerClass!: string;
  @Input() blockClass!: string;
  @Input() headerBlockClass!: string;
  @Input() headerClass!: string;
  @Input() headerTextClass!: string;
  @Input() previewStyle!: object;
  @Input() actionButtonText!: string;
  @Input() actionButtonRoute!: string;
  @Input() secondaryTitleRight!: string;
  @Input() new: boolean = false;
  @Input() disabledButton: boolean = false;
  @Input() containerHeight!: number;
  @Input() headerIcon!: string;
  @Input() showCloseDialogIcon = false;
  @Input() backButtonLabel = '';
  @Input() tooltipLabel = '';
  @Output() closeDialogEvent = new EventEmitter<void>();

  constructor(
    private router: Router,
    private location: Location,
    ) { }

  ngOnInit(): void {
  }

  actionRoute(event: any) {
    this.router.navigate([this.actionButtonRoute]);
  }

  closeDialogIconClicked() {
    this.closeDialogEvent.emit();
  }


  goBack() {
    this.location.back();
  }

}
