import { DOCUMENT } from '@angular/common';
import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, inject, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { LeftMenuItem } from 'src/app/core/models/general.model';
import { Notification } from 'src/app/core/models/notification.model';
import { UserRole } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit, AfterContentChecked {
  @Input() init: boolean = false;
  @Input() menuItems!: any;
  @Output() opened = new EventEmitter<any>();
  chatService = inject(ChatService);
  public role: UserRole = {} as UserRole;
  active = false;
  subs = new SubSink();
  mobileMenuTitle: string = '';
  burgerClasses = { burger: '', nava: '', overlay: '', body: '', menu: 'hidden' };
  extraMobileMenuItems = [] as LeftMenuItem[];
  unreadNotifications: Notification[] = [];
  socialLinks =
    {
      linkedin: 'https://gr.linkedin.com/company/mylingotrip',
      twitter: 'https://twitter.com/MyLingoTrip',
      facebook: 'https://www.facebook.com/mylingotrip',
      youtube: 'https://www.youtube.com/c/MyLingoTrip',
      instagram: 'https://www.instagram.com/mylingotrip/',
    };

  constructor(
    private router: Router,
    private generalService: GeneralService,
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    private layoutService: LayoutService,
    private notificationService: NotificationService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit(): void {
    // this.burgerClick();
    this.role = this.authService.getUserRole();
    this.menuItems = this.layoutService.getMenuItems();
    this.extraMobileMenuItems = this.layoutService.getExtraMobileMenuItems();

    this.subs.add(this.notificationService.get().subscribe((res: any) => {
      // Filter notifications to get only the unread ones
      this.unreadNotifications = res.filter(
        (notification: Notification) => !notification.isRead
      );
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterContentChecked(): void {
    this.subs.sink = this.generalService.mobileMenuTitle.subscribe((res: string) => {
      this.mobileMenuTitle = res;
      this.changeDetector.detectChanges();
    })
  }
  onBurgerClicked() {
    this.active = !this.active;
    this.opened.emit();
  }
  toggleMobileMenuItems() {
    var mobileMenuItems = document.getElementById('mobile-menu-items');
    if (mobileMenuItems!.clientHeight) {
      mobileMenuItems!.style.height = "0";
    } else {
      mobileMenuItems!.style.height = window!.innerHeight + "px";
    }
  }

  logout() {
    this.authService.logout();
  }

  navigateTo(route: string) {
    this.onBurgerClicked();
    this.burgerClick();
    this.router.navigateByUrl(route, { replaceUrl: true });
    // this.router.navigate([route]);
  }

  burgerClick() {
    this.burgerClasses.burger = this.burgerClasses.burger === 'clicked' ? '' : 'clicked';
    this.burgerClasses.overlay = this.burgerClasses.overlay === 'show' ? '' : 'show';
    this.burgerClasses.nava = this.burgerClasses.nava === 'show' ? '' : 'show';
    this.burgerClasses.menu = this.burgerClasses.menu === 'hidden' ? '' : 'hidden';
    this.burgerClasses.body = 'overflow';

    if (this.burgerClasses.burger === 'clicked') {

      this.renderer.addClass(this.document.body, 'overflow-hidden');
    } else {

      this.renderer.removeClass(this.document.body, 'overflow-hidden');
    }
  }
}
