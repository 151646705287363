import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

const TWITTER_SHARE_URL = "https://twitter.com/share";
const FACEBOOK_SHARE_URL = "https://www.facebook.com/sharer/sharer.php?u=";
const LINKEDIN_SHARE_URL =
  "https://www.linkedin.com/shareArticle?mini=true&summary=";
  
@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent implements OnInit {
  // @Input() type?: 'facebook' | 'twitter';
  // @Input() shareUrl?: string;
  // navUrl?: string;
  shareButtons: HTMLButtonElement[] = [];
  @Input() componentId?: string;
  // constructor() { }

  // ngOnInit(): void {
  //   this.createNavigationUrl();
  // }
  @Input() topic?: string;
  @Input() link?: string;
  @Input() type?: string;

  public safeDocumentURL?: SafeUrl;

  constructor(private DS: DomSanitizer, private elementRef: ElementRef, private renderer: Renderer2) {}
  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.setupShareButton();
  }

  private setupShareButton(): void {
    const buttonSelector = `#${this.componentId} .shareButton`;
    this.shareButtons = Array.from(this.elementRef.nativeElement.querySelectorAll(buttonSelector)) as HTMLButtonElement[];
  }

  public toggleShareButtons(): void {
    for (let i = 0; i < this.shareButtons.length; i++) {
      this.shareButtons[i].classList.toggle("open");
    }
  }

  share(type: string) {
    console.log('share');
    this.type = type;
    const shareUrl = this.href;
    // const shareUrl = 'google.com';
    console.log(shareUrl);
    window.open(shareUrl, "_blank");
    // if (shareUrl) {
    //   switch (type) {
    //     case SocialMediaType.TWITTER:
    //       window.open(shareUrl, "_blank");
    //       break;
    //     case SocialMediaType.FACEBOOK:
    //       window.open(shareUrl, "_blank");
    //       break;
    //     case SocialMediaType.LINKEDIN:
    //       window.open(shareUrl, "_blank");
    //       break;
    //   }
    // }
  }
  get href() {
    if (this.topic) {
      console.log(this.type);

      switch (this.type) {
        case 'TWITTER':
          return `${TWITTER_SHARE_URL}?text=${this.topic}&url=${this.link}`;
        case 'FACEBOOK':
          return `${FACEBOOK_SHARE_URL}${this.link}`;
        case 'LINKEDIN':
          return `${LINKEDIN_SHARE_URL}${this.topic}&title=${this.topic}&url=${this.link}`;
        default:
          return ''; // Return an empty string as the default case
      }
    } else {
      console.log("this.topic is undefined");
      return ''; // Return an empty string if this.topic is undefined
    }
  }
}
