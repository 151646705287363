<ng-container *ngIf="switchesWithLabels">
  <div class="flex flex-wrap">
    <div class="field-checkbox my-0" *ngFor="let switch of switchesWithLabels">
      <label class="lesson-filter-label font-xs mr-2">{{ switch.label }}</label>
      <p-inputSwitch class="flex small-input-switch p-mr-2" [(ngModel)]="switch.value"
        (onChange)="toggleSwitch(switch.label)"></p-inputSwitch>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isTrial && !isLessonsFilter && !switchesWithLabels">
<div class="flex flex-wrap gap-2 sm:gap-3">
  <div class="field-checkbox my-0">
    <label class="lesson-filter-label font-xs mr-2">Ongoing</label>
    <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="switches.Ongoing"
      (onChange)="toggleSwitch('Ongoing')"></p-inputSwitch>
  </div>
  <div class="field-checkbox my-0">
    <label class="lesson-filter-label font-xs mr-2">Completed</label>
    <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="switches.Completed"
      (onChange)="toggleSwitch('Completed')"></p-inputSwitch>
  </div>
  <div class="field-checkbox my-0">
    <label class="lesson-filter-label font-xs mr-2">Expired</label>
    <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="switches.Expired"
      (onChange)="toggleSwitch('Expired')"></p-inputSwitch>
  </div>
  <div class="field-checkbox my-0">
    <label class="lesson-filter-label font-xs mr-2">All</label>
    <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="switches.All"
      (onChange)="toggleSwitch('All')"></p-inputSwitch>
  </div>
  <!-- <div class="field-checkbox my-0">
    <label class="lesson-filter-label font-xs mr-2">Freeze</label>
    <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="switches.Freeze"
      (onChange)="toggleSwitch('Freeze')"></p-inputSwitch>
  </div> -->
</div>
</ng-container>

<ng-container *ngIf="isTrial">
  <div class="flex flex-wrap">
    <div class="field-checkbox my-2 sm:my-0">
      <label class="lesson-filter-label font-xs mr-2">Ongoing</label>
      <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="switches.Active"
        (onChange)="toggleSwitch('Active')"></p-inputSwitch>
    </div>
    <div class="field-checkbox my-2 sm:my-0">
      <label class="lesson-filter-label font-xs mr-2">Completed</label>
      <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="switches.Completed"
        (onChange)="toggleSwitch('Completed')"></p-inputSwitch>
    </div>
    <div class="field-checkbox my-2 sm:my-0">
      <label class="lesson-filter-label font-xs mr-2">Dismissed</label>
      <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="switches.Dismissed"
        (onChange)="toggleSwitch('Dismissed')"></p-inputSwitch>
    </div>
    <div class="field-checkbox my-2 sm:my-0">
      <label class="lesson-filter-label font-xs mr-2">All</label>
      <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="switches.All"
        (onChange)="toggleSwitch('All')"></p-inputSwitch>
    </div>
  </div>
  </ng-container>

  

<ng-container *ngIf="isLessonsFilter">
  <div class="flex flex-wrap">
    <div class="field-checkbox my-0">
      <label class="lesson-filter-label font-xs mr-1">Arranged</label>
      <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="switches.Arranged"
        (onChange)="toggleSwitch('Arranged')"></p-inputSwitch>
    </div>
    <div class="field-checkbox my-0">
      <label class="lesson-filter-label font-xs mr-1">Completed</label>
      <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="switches.Completed"
        (onChange)="toggleSwitch('Completed')"></p-inputSwitch>
    </div>
    <div class="field-checkbox my-0">
      <label class="lesson-filter-label font-xs mr-1">Canceled</label>
      <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="switches.Canceled"
        (onChange)="toggleSwitch('Canceled')"></p-inputSwitch>
    </div>
    <div class="field-checkbox my-0">
      <label class="lesson-filter-label font-xs mr-1">No Show</label>
      <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="switches.NoShow"
        (onChange)="toggleSwitch('NoShow')"></p-inputSwitch>
    </div>
    <div class="field-checkbox my-0">
      <label class="lesson-filter-label font-xs mr-1">All</label>
      <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="switches.All"
        (onChange)="toggleSwitch('All')"></p-inputSwitch>
    </div>
  </div>
  </ng-container>