import { Component, OnDestroy, OnInit } from '@angular/core';
import { TeacherApplicationService } from 'src/app/core/services/teacher-application.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { TeacherStatus, TeacherStatusResponse } from 'src/app/core/models/teacher.model';
import { User } from 'src/app/core/models/user.model';
import { NavigationExtras, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general.service';
import { SubSink } from 'subsink';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit, OnDestroy {
  public statusIcon: string = "";
  public statusCssClass: string = "";
  public statusDateText: string = '';
  public currentStatus: TeacherStatusResponse = {} as TeacherStatusResponse;
  public loggenInUser: User = {} as User;
  private subs = new SubSink();
  showReviewLink = false;
  showWithdrawLink = false;
  formattedSubmittedDate = new Date().toString() || null;
  constructor(
    private authService: AuthService,
    private teacherService: TeacherApplicationService,
    public generalService: GeneralService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.teacherService.setStep("status");
    this.teacherService.setShowApplicationProgressMenu(false);

    this.subs.add(this.authService.getTeacherStatus().subscribe((response: TeacherStatusResponse) => {
      // removing all texct after space for date string response
      this.formattedSubmittedDate = response.dateSubmited.substring(0, response.dateSubmited.indexOf(' '));

      this.setTeacherStatusBasedOnResponse(response);
    }));

    this.subs.add(this.teacherService.shouldRetrieveTeacherStatus
      .pipe(
        switchMap((shouldRetrieve: boolean) => {
          if (shouldRetrieve) {
            return this.authService.getTeacherStatus();
          }
          return of(null);
        })
      ).subscribe((res: TeacherStatusResponse | null) => {
        if (res) {
          this.setTeacherStatusBasedOnResponse(res);
        }
      }));

    this.loggenInUser = this.authService.getLoggedInUser()!;
  }

  ngOnDestroy() {
    this.teacherService.setShowWithdrawConfirmationDialog(false);
    this.subs.unsubscribe();
  }

  /**
   * Set the teacher's application status
   *
   * @async
   * @returns {void}
   */
  setTeacherStatusBasedOnResponse(teacherStatusResponse: TeacherStatusResponse) {
    this.currentStatus = teacherStatusResponse;

    switch (this.currentStatus.status.toLowerCase()) {
      case TeacherStatus.RECEIVED.toLowerCase():
        this.statusIcon = "status-received-blue.svg";
        this.statusCssClass = 'received-bottom';
        this.statusDateText = 'Applied on';
        this.showReviewLink = true;
        this.showWithdrawLink = true;
        break;
      case TeacherStatus.CONSIDERATION.toLowerCase():
        this.statusIcon = "accept.svg";
        this.statusCssClass = 'consideration';
        this.statusDateText = 'Applied on';
        this.showReviewLink = true;
        this.showWithdrawLink = true;
        break;
      case TeacherStatus.REJECTED.toLowerCase():
      case TeacherStatus.NO_LONGER_CONSIDERED.toLowerCase():
        this.statusDateText = 'Applied on';
        this.statusIcon = "reject.svg";
        this.statusCssClass = 'rejected';
        break;
      case TeacherStatus.NEW.toLowerCase():
      case TeacherStatus.INCOMPLETE.toLowerCase():
        this.statusIcon = "warning-status.svg";
        this.statusCssClass = 'received';
        this.statusDateText = 'Started on';
        this.showReviewLink = false;
        this.showWithdrawLink = false;
        break;
      case TeacherStatus.APPROVED.toLowerCase():
        this.statusIcon = "accept.svg";
        this.statusCssClass = 'consideration';
        this.statusDateText = 'Applied on';
        this.showReviewLink = true;
        break;
      case TeacherStatus.WITHDRAWN.toLowerCase():
        this.showReviewLink = false;
        this.showWithdrawLink = false;
        this.statusIcon = "reject.svg";
        this.statusCssClass = 'consideration';
        this.statusDateText = 'Withdrawn on';
        break;
      default:
        break;
    }
  }

  /**
 * Navigates to the review page for the teacher application
 *
 */
  goToReviewPage() {
    this.router.navigateByUrl('/teacher/review?buttons=1', { replaceUrl: true });
    this.teacherService.setShowApplicationProgressMenu(true);
  }

  /**
   * Calls restart Application from API & 
   * navigates to the review page for the teacher application
   */
  restartApplicationAndgoToStatusInfoPage() {
    this.subs.add(this.teacherService.restartTeacherApplication().subscribe((response: boolean) => {
      if (response) {
        this.router.navigateByUrl('/teacher/info', { replaceUrl: true });
        this.teacherService.setShowApplicationProgressMenu(true);
      }
    }));
  }

  /**
   * Navigates to the review page for the teacher application
   *
   */
  goToStatusInfoPage() {
    this.router.navigateByUrl('/teacher/info', { replaceUrl: true });
    this.teacherService.setShowApplicationProgressMenu(true);
  }

  /**
   * Handles the click event for the withdraw button
   *
   */
  handleWithdrawal() {
    this.teacherService.setShowWithdrawConfirmationDialog(true);
  }
  isEmpty(obj: Record<string, any>): boolean {
    return Object.keys(obj).length === 0;
  }

  isIncomplete(status: string) {
    return status.toLowerCase() === TeacherStatus.INCOMPLETE.toLowerCase();
  }

  isRejected(status: string) {
    return status.toLowerCase() === TeacherStatus.REJECTED.toLowerCase();
  }

  isWithdrawn(status: string) {
    return status.toLowerCase() === TeacherStatus.WITHDRAWN.toLowerCase();
  }

  isConsidered(status: string) {
    return status.toLowerCase() === TeacherStatus.CONSIDERATION.toLowerCase();
  }

  isNoLongerConsidered(status: string) {
    return status.toLowerCase() === TeacherStatus.NO_LONGER_CONSIDERED.toLowerCase();
  }

  isApproved(status: string) {
    return status.toLowerCase() === TeacherStatus.APPROVED.toLowerCase();
  }

  isReceived(status: string) {
    return status.toLowerCase() === TeacherStatus.RECEIVED.toLowerCase();
  }

  formatStatusNameText() {
    return this.generalService.convertCamelCase(this.currentStatus.status).toLowerCase() === TeacherStatus.RECEIVED.toLowerCase() ? 'Application Received' :
    this.generalService.convertCamelCase(this.currentStatus.status);
  }
}
