import { Component, OnInit, Output, EventEmitter, Input, inject } from '@angular/core';
import { HttpEventType, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { base64ToFile, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { UserService } from 'src/app/core/services/user.service';
import { SubSink } from 'subsink';
import { LibraryService } from 'src/app/core/services/library.service';
import { FocusTrap } from '@angular/cdk/a11y';
import { Homework, HomeworkTask } from 'src/app/core/models/homework.model';
import { Classroom } from 'src/app/core/models/classroom.model';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { DomSanitizer } from '@angular/platform-browser';

export interface MyFile {
  file: File,
  progress: number,
  index: number
}
@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {
  @Input() isProfile?: boolean = false;
  @Input() isTeacherApplication?: boolean = false;
  @Input() isSick?: boolean = false;
  @Input() files: any[] = [];
  @Input() teacherFilesLength: number = 0;
  @Input() postUrl: string = "";
  @Input() title: string = "";
  @Input() buttonUI: boolean = false;
  @Input() dndUI: boolean = false;
  @Input() checkboxUI: boolean = false;
  @Input() multiple: boolean = true;
  @Input() showSubmitButton: boolean = false;
  @Input() inHomeworkTask: boolean = false;
  @Input() homeworkTaskStudentId: string = "";
  @Input() assignToClassroom?: Classroom;
  @Input() assignToUser?: User;
  @Input() task?: HomeworkTask;
  @Input() filePath?: string;
  @Input() fileDropImage?: string;
  @Input() fileDropImageWidth?: number;
  @Input() fileAfterImage?: string;
  @Input() fileAfterImageWidth?: string;
  @Input() clearFiles: boolean = false;
  @Output() onUploadFinished = new EventEmitter();
  @Output() onDeleteFileClicked = new EventEmitter();
  @Output() tagChoosen = new EventEmitter();
  @Output() onSubmit = new EventEmitter();
  private subs = new SubSink();
  public progress: number[] = [];
  public message: string = "";
  public showCropper = false;
  imageChangedEvent: any;
  croppedImage: any = "";
  profileFile: File = {} as File;
  profileFileName: string = "";
  uploadedFile: any = "";
  sanitizer = inject(DomSanitizer);

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private libraryService: LibraryService,
    private toast: ToastService,
    private authService: AuthService,
    public generalService: GeneralService,
  ) { }

  ngOnInit(): void {
    // this.uploadFile([{lastModified: 1680183216555,
    //   lastModifiedDate
    //   : 
    //   'Thu Mar 30 2023 16:33:36 GMT+0300 (Eastern European Summer Time)',
    //   name  :  "calendar (-41.png",
    //   size: 323,
    //   type : "image/png",
    //   webkitRelativePath: ""}])
    // this is happening because of cropped image. we cannot imidiatly upload it until the user presses save at profile info component
    this.subs.sink = this.userService.updateProfile.subscribe(res => {
      if (res) {
        console.log(this.profileFile)
        this.uploadFile(this.profileFile);
      }
    })
    this.subs.sink = this.userService.uploadFiles.subscribe(res => {
      if (res) {
        this.files = [];
      }
    })
    this.subs.sink = this.userService.newFileUpload.subscribe(res => {
      if (res && !this.generalService.isObjectEmpty(res)) {
        console.log(res);
        this.files.push(res);
        this.upload(res as MyFile);
      }
    })
  }

  ngOnDestroy() {
    //when we land to this cocmponent the value must be false so it wont get called in ngOnInit
    this.userService.setUpdateProfile(false);
    this.subs.unsubscribe();
  }

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.uploadFile($event);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.onDeleteFileClicked.emit({ file: this.files[index] });
    this.files.splice(index, 1);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandlerProfile(event: any) {
    this.profileFileName = event.target.files[0].name;
    this.showCropper = true;
    this.imageChangedEvent = event;
    // document.getElementById('cropped-img')!.style.borderColor = "var(--main-color)";
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event: any) {
    console.log(event);
    this.uploadFile(event);
  }

  public uploadFile = (files: any) => {
    console.log(files);
    if (this.isProfile && !files.name) {
      this.onUploadFinished.emit({ filePath: "", levels: [], categories: [] });
      return;
    }
    if (!this.isProfile && files.length === 0) {
      return;
    }
    if (!this.isProfile && !this.isTeacherApplication) {
      for (const file of files) {
        let myFile: MyFile = {
          file: file,
          progress: 0,
          index: this.teacherFilesLength
        }
        this.teacherFilesLength++;
        // this.files.push(myFile);
        this.userService.setNewFileUpload(myFile);
      }
    }
    if (!this.isProfile && this.isTeacherApplication) {
      for (const file of files) {
        let myFile: MyFile = {
          file: file,
          progress: 0,
          index: this.teacherFilesLength
        }
        // this.teacherFilesLength++;
        // this.files.push(myFile);
        // this.userService.setNewFileUpload(myFile);
        this.upload(myFile)
      }
    } else {
      let myFile: MyFile = {
        file: files,
        progress: 0,
        index: this.teacherFilesLength
      }
      this.upload(myFile)
    }
  }

  upload(myFile: MyFile) {
    let headers: any;
    const formData = new FormData();
    formData.append('file', myFile.file, this.replaceSpecialCharactersForFilename(myFile.file.name));
    headers = new HttpHeaders({
      'documentType': myFile.file.name.split('.').pop()!,
      "Authorization": "Bearer " + this.authService.getToken(),
    });

    console.log(headers);
    if (this.assignToClassroom) {
      for (let user of this.assignToClassroom.users) {
        let endpointExtras = this.inHomeworkTask ? "?studentId=" + user.id : "";
        this.uploadRequest(headers, endpointExtras, formData, myFile)
      }
    } else if (this.assignToUser) {
      let endpointExtras = "?studentId=" + this.assignToUser.id!;
      console.log(endpointExtras)
      this.uploadRequest(headers, endpointExtras, formData, myFile)
    } else {
      let endpointExtras = this.inHomeworkTask ? "?studentId=" + this.homeworkTaskStudentId : "";
      this.uploadRequest(headers, endpointExtras, formData, myFile)
    }

  }

  uploadRequest(headers: HttpHeaders, endpointExtras: string, formData: FormData, myFile: MyFile) {
    this.subs.add(this.http.post(environment.apiUrl + this.postUrl + endpointExtras, formData, {
      headers: headers,
      reportProgress: true,
      observe: 'events'
    })
      .subscribe(event => {
        console.log(event)
        if (event.type === HttpEventType.UploadProgress) {
          myFile.progress = Math.round(100 * event.loaded / event.total!);
        }
        else if (event.type === HttpEventType.Response) {

          this.toast.setShowToastmessage({
            severity: 'success',
            summary: '',
            detail: 'Uploaded successfully!'
          });
          this.uploadedFile = event.body;
          if (this.isProfile) {
            console.log('GEGEG');
            this.showCropper = false;
          }
          this.onUploadFinished.emit({ filePath: event.body, task: this.task });
        } else {
        }
      }, err => {
        console.log(err)
      }));
  }

  formatBytes(bytes: any, decimals: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onChooseTag(event: any) {
    this.tagChoosen.emit(event);
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log(event);
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl || event.base64 || '');
    const file = new File([event.blob!], this.profileFileName, { type: event.blob!.type });
    this.profileFile = new File([file], this.profileFileName);
    const reader = new FileReader();
    reader.onload = () => {//async code, will take a while
      this.croppedImage = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  imageLoaded(event: any) {
    console.log(event);
    // show cropper
  }

  cropperReady(event: any) {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  submit() {
    this.onSubmit.emit();
  }

  /**
   * Replaces special characters in a given filename with a specified replacement character.
   * @param {string} fileName - The original filename.
   * @returns {string} - The modified filename with special characters replaced.
   */
  private replaceSpecialCharactersForFilename(fileName: string): string {
    const specialCharacters = /[^\w\s.-]/g;
    const replacementCharacter = "_";

    return fileName.replace(specialCharacters, replacementCharacter);
  }

}