import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { Classroom, ClassroomType } from 'src/app/core/models/classroom.model';
import { HomeworkTask } from 'src/app/core/models/homework.model';
import { Folder, FoldersWithFiles, Library, LibraryFile, UploadFilesToFolderRequest } from 'src/app/core/models/library.model';
import { User, UserRole } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { LibraryService } from 'src/app/core/services/library.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import {MessageService} from 'primeng/api';
import { forkJoin } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { DialogService } from 'primeng/dynamicdialog';
import { LibraryFileActionsDialogComponent } from '../library-file-actions-dialog/library-file-actions-dialog.component';
import { UserService } from 'src/app/core/services/user.service';
import { FilterPipe } from 'src/app/core/pipes/filter.pipe';

@Component({
  selector: 'app-single-library',
  templateUrl: './single-library.component.html',
  styleUrls: ['./single-library.component.scss'],
  providers: [DialogService, FilterPipe]
})
export class SingleLibraryComponent implements OnInit {

  @ViewChild('addLibrary', {static: true}) public addLibrary: any;
  @ViewChild('leftSide', {static: true}) public leftSide: any;
  @ViewChild('mainWrapper', {static: true}) public mainWrapper: any;
  
  @Input() showHeader = true;
  teacherClassrooms: any;
  sharedByMeLibFolders: any;
  sharedWithMeLibFolders: any;
  isMyFiles = false;
  showShare: boolean = false;
  StudentIdsToSend: string[] = [];
  folderPath: Folder[] = [this.libraryService.rootFolder];
  fileURL: string | undefined;
  classroomId: number | undefined;
  isDisabled = false;
  isDisabledWholeClass = false;
  isMoveRadioCheked = false;
  moveFileId = 0;
  moveFolderId = 0; 
  items!: MenuItem[];
  UserRoles = UserRole;
  public role: string = "";
  public loggedInUser: User = {} as User;
  @Input() inHomework: boolean = false;
  @Input() task: HomeworkTask = {} as HomeworkTask;
  @Input() classroomIdToAddLib: number = 0;
  @Input() classroomStudents: any;
  @Input() withClassroom = false;
  @Input() canUpload = true;
  @Input() canShare = true;
  @Input() hasLimitedOptions = false;
  @Input() availableActions = [];
  @Input() leftSideHeight!: number;
  @Input() libraryTitle = 'My Files';
  @Input() isInTab = false;
  @Input() extraGradientClass = 'purple';
  @Input() fileListHeight = 0;
  

  private subs = new SubSink()
  term: any;
  showFilters: boolean = false;
  showGroupActions: boolean = false;
  showAddLibrary: boolean = false;
  foldersWithFiles: FoldersWithFiles[] = [];
  @Input() allLibrariesWithFiles: FoldersWithFiles[] = [];
  @Input() title: string = "";
  currentPathLibraries: Library[] = [];
  innerFolders: Folder[] = [];
  libraryToAdd: Library = {} as Library;
  folderName: string = "";
  showInput: boolean = false;
  user: User = {} as User;
  folders: Folder[] = []
  inClassroom: boolean = true;
  showingMine: boolean = true;
  files: LibraryFile[] = [];
  filteredFiles: LibraryFile[] = [];
  libsSharedWith: FoldersWithFiles[] = [];
  libsSharedBy: FoldersWithFiles[] = [];
  studentsToShareWith: any[] = [];
  deleteFolderConfirmData = {
    showDialog: false,
    message: 'Delete folder?'
  }
  deleteFileConfirmData = {
    showDialog: false,
    message: 'Delete File?'
  }
  deleteMultipleFilesConfirmData = {
    showDialog: false,
    message: 'Delete selected files?'
  }
  isLeftsideCollapsed: any;
  checkedLibraryFiles: LibraryFile[] = [];
  isSmallScreen = false;
  currentUploadFolder = {} as Folder;
  classroomStudentsIds = [];
  constructor(
    private libraryService: LibraryService,
    private classroomService: ClassroomService,
    private generalService: GeneralService,
    public authService: AuthService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private layoutService: LayoutService,
    private dialogService: DialogService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {

    // if (this.libraryService.isPreviewingFromChat()) {
    //   this.libraryService.setGroupActions(true);
    // }
    // set unique student ids...
    if (this.classroomStudents) {
      this.classroomStudents = Array.from(new Set(this.classroomStudents.map((student: any) => student.aspUserId)))
      .map(aspUserId => this.classroomStudents.find((student: any) => student.aspUserId === aspUserId)) as User[];
      this.classroomStudentsIds = this.classroomStudents.map((student: any) => student.aspUserId);
    }
    if (this.classroomStudents && this.classroomStudents.length === 1) {
      this.setStudentsToShareWith(this.classroomStudents[0].aspUserId);
    }
    this.oncollapseNotesLeftSideChanged(true);
    console.log(this.classroomIdToAddLib);
    // this.generalService.slideNativeElements(true, this.addLibrary.nativeElement);
    this.loggedInUser = this.authService.getLoggedInUser();
    this.role = this.authService.getUserRole();
    this.items = [
      {
        label: 'Add New', icon: 'pi pi-fw pi-plus',
        command: (event) => {
        }
      },
      { label: 'Remove', icon: 'pi pi-fw pi-minus' }
    ];

    this.subs.sink = this.generalService.deviceKind.subscribe(deviceKind => {
      this.isSmallScreen = !deviceKind.w768up;
    });

    this.subs.sink = this.libraryService.showGroupActions.subscribe(res => {
      this.showGroupActions = res
    })

    this.subs.sink = this.layoutService.sideMenuHeight.subscribe((res) => {
      // this.leftSide.nativeElement.style.maxHeight = (res - 100 + 'px');
      // this.mainWrapper.nativeElement.style.height = (res -60 + 'px');

      // this.classroomWrapper.nativeElement.style.height = (res - extraHeight.wrapper + 'px');
      // this.scrollHeight = (res - extraHeight.scrollWrapper + 'px');/assets/icons/library/arrow-left.svg
    });

    this.subs.sink = this.libraryService.updateFolderListener.subscribe(res => {
      if (res.folder.folderId! > 0) {
        for (let lib of this.allLibrariesWithFiles) {
          let found: boolean = false;
          for (let file of lib.libraryFiles) {
            if (file.fileId == res.libraryFile.fileId) {
              found = true;
            }
          }
          if (found) {
            lib.folderId = res.folder.folderId!
            lib.name = res.folder.name
            lib.parent = res.folder.parent
          }
        }

        this.openFolder(this.libraryToAdd.folder);
      }
    })

    this.initOpenFolderListener();

    this.getClassRooms();

    // IRAKLIS SOURCE

    this.subs.sink = this.libraryService.updateListener.subscribe(res => {
      if (res) {
        this.getFoldersWithFiles();
      }
    })

    this.subs.sink = this.libraryService.deleteListener.subscribe(res => {
      if (res > 0) {

        for (let lib of this.allLibrariesWithFiles) {
          let found: boolean = false;
          for (let file of lib.libraryFiles) {
            if (file.fileId == res) {
              found = true;
            }
          }
          if (found) {
            lib.libraryFiles = lib.libraryFiles.filter(el => el.fileId != res)
          }
        }

        this.getFoldersWithFiles();
      }
    })
    this.libraryToAdd = this.libraryService.initializeEmptyLibraryObject()
    this.user = this.authService.getLoggedInUser();
    this.role = this.user.role!
  }

  ngOnChanges() {
    this.initFiles();
    this.getFoldersWithFiles();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.libraryService.setGroupActions(false);
  }

  openShareDialog() {
    if (this.checkedLibraryFiles.length === 0) {
      this.toastService.setShowToastmessage({
        severity: 'warn',
        summary: '',
        detail: 'Please choose at least one file!'
      });
      return;
    }
    this.libraryService.openDialogWithComponent(this.dialogService, LibraryFileActionsDialogComponent, 400, 
      {
        action: 'shareWithMultiple',
        dialogsubTitle: 'Share multiple with...',
        dialogTitle: 'Share file with...',
      }, 'allFiles', (result: any) => {
      if (result && result.action === 'shareWithMultiple') {
        this.shareLibraryFiles(result.map.map);
      }
    });
  }

  shareMultipleLibraryFiles() {
  }

  onLibraryChecked(event: any) {
    console.log(event);
    if (event.checked) {
      this.checkedLibraryFiles.push(event.file)
    } else {
      this.checkedLibraryFiles = this.checkedLibraryFiles.filter(el => el.path != event.file.path)
    }
    this.libraryService.checkedLibraryFiles.set(this.checkedLibraryFiles);
    console.log(this.checkedLibraryFiles);
  }

  shareLibraryFiles(map: any) {
    map.forEach((users: User[], classroomId: number) => {
      let ids: string[] = []
      let fileIds: number[] = []
      for (let user of users) {
        ids.push(user.aspUserId!)
      }
      for (let lib of this.checkedLibraryFiles) {
        fileIds.push(lib.fileId!)
      }
      let send: any = {
        fileIds: fileIds,
        classroomId: classroomId,
        studentIds: ids
      }
      this.sendShareToApi(send);
    });
  }

  sendShareToApi(sendParams: LibraryFile[]) {
    this.libraryService.shareFileWithClassUsers(sendParams).pipe(take(1)).subscribe(res => {
      this.toastService.setShowToastmessage({
        severity: 'success',
        summary: '',
        detail: 'Library has been sent.'
      });
    })
  }

  onShowAddLibrary() {
    this.showAddLibrary = !this.showAddLibrary
    this.libraryToAdd.folder = this.folderPath[this.folderPath.length - 1];
    // this.generalService.slideNativeElements(this.showAddLibrary, this.addLibrary.nativeElement);
  }

  deleteFolderSelected(folder: Folder) {
    this.deleteFolder(folder.folderId);
  }

  openFolderSelected(folder: Folder) {
    this.openFolder(folder);
  }

  getClassRooms(){
    this.subs.add(this.classroomService.getLMSUserClassrooms(this.loggedInUser.id).subscribe((response)=>{
      this.teacherClassrooms = response;
    }));
  }

  // createLibFolder(){
  //   this.libraryService.createFolder().subscribe( (response) => {

  //   });
  // }


  deleteLibFolder(folderId: number){
    this.libraryService.deleteFolder(folderId).subscribe( (response) => {
      this.toastService.setShowToastmessage({
        severity: 'success',
        summary: '',
        detail: 'Folder Deleted successfully.'
      });
    });
  }

  getFileName(path: string) {
    if (path) {
      let n = path.lastIndexOf("/");
      if(n === -1)
        n = path.lastIndexOf("\\");
      let result = path.substring(n + 1);
      return result;
    }
    return path
  }

  showSharePopup(fileId: number){
    document.getElementById('lib-file-move-menu-' + fileId)!.style.display = 'none';
    document.getElementById('lib-file-share-menu-' + fileId)!.style.display = 'block';
    document.getElementById('lib-file-share-menu-' + fileId)!.style.top = '25%';
  }
  
  // shareLibraryFiles(fileId: number, classroomID: number) {
  //   let fileIds = []
  //   fileIds.push(fileId)
  //   let body: any = {
  //     fileIds: fileIds,
  //     classroomId: this.classroomId,
  //     studentIds: this.StudentIdsToSend
  //   }
  //   if (this.StudentIdsToSend.length === 0){

  //   }
  //   else{
  //     this.libraryService.shareFileWithClassUsers(body).pipe(take(1)).subscribe(res => {

  //     })
  //   }
  //   this.ngOnInit();
  // }

  onFolderSelect(fileId: any, folderId: any){
    this.isMoveRadioCheked = true;
    this.moveFileId = fileId;
    this.moveFolderId = folderId;
  }

  moveFile(){
    this.moveFileAPI(this.moveFileId, this.moveFolderId);
    // this.libraryService.moveFileToFolder(this.moveFileId, this.moveFolderId).subscribe( (response) => {
    //   this.toastService.setShowToastmessage({
    //     severity: 'success',
    //     summary: '',
    //     detail: 'File moved successfully.'
    //   });
    // });
    this.ngOnInit();
  }

  // IRAKLIS SOURCE

  initFiles() {
    this.filteredFiles = [];
    let i = 0;
    for (let folderWithFiles of this.foldersWithFiles) {
      for (let libraryFile of folderWithFiles.libraryFiles) {
        libraryFile.dummyId = i;
        i++;
        this.filteredFiles.push(libraryFile)
      }
    }
    this.files = this.filteredFiles;
  }

  getFoldersWithFiles() {
    this.initializeFilesWithFolders(this.allLibrariesWithFiles);
  }

  initializeFilesWithFolders(res: FoldersWithFiles[]) {
    this.allLibrariesWithFiles = res;
    this.folders = [];
    for (const lib of this.allLibrariesWithFiles) {
      const folder: Folder = {
        name: lib.name,
        parent: lib.parent,
        folderId: lib.folderId,
      };
      if (!this.folders.some(el => el.name === folder.name && el.parent === folder.parent)) {
        this.folders.push(folder);
      }
    }
    this.foldersWithFiles = this.libraryService.getLibrariesOnRootFolders(this.allLibrariesWithFiles);
    this.initFiles();
    this.getInnerFolders('home');
  }

  getInnerFolders(parent: string) {
    this.innerFolders = []
    let copyFolders = this.allLibrariesWithFiles.filter(el => el.parent == parent)
    for (let innerFolder of copyFolders) {
      if (!this.innerFolders.some(el => el.parent == innerFolder.parent && el.name == innerFolder.name)) {
        this.innerFolders.push(innerFolder)
      }
    }
  }

  openFolder(folder: Folder) {
    if (!folder) {
      this.getInnerFolders('home')
      return;
    }

    this.libraryToAdd.folder = folder
    console.log(this.libraryToAdd.folder);
    this.foldersWithFiles = this.libraryService.getLibrariesOfFolder(folder, this.allLibrariesWithFiles);
    this.initFiles()
    if (folder.name == 'home') {
      this.getInnerFolders('home')
    } else {
      this.getInnerFolders(folder.name)
    }
    this.folderPath = this.libraryService.getFolderPathOfFolder(folder, [], this.folders);
    this.folderPath.unshift(this.libraryService.rootFolder)
    this.libraryToAdd.folder = this.folderPath[this.folderPath.length - 1];
  }

  deleteFolder(id: any) {
    const folderToDelete = this.innerFolders.filter(el => el.folderId === id);
    this.libraryService.setFolderToDelete(folderToDelete[0]);
    this.deleteFolderConfirmData.showDialog = true;
    this.deleteFolderConfirmData.message = 'Delete the folder "' + folderToDelete[0].name + '"?';
    // this.libraryService.deleteFolder(id).pipe(take(1)).subscribe(res => {
    //   this.innerFolders = this.innerFolders.filter(el => el.folderId != id)
    //   console.log(this.innerFolders)

    // })
  }

  onRejectFolderDelete(event: any) {
    this.libraryService.setFolderToDelete({} as Folder);
    this.deleteFolderConfirmData.showDialog = false;
  }

  onAcceptFolderDelete(event: any) {
    this.libraryService.deleteFolder(this.libraryService.getFolderToDelete().folderId).pipe(take(1)).subscribe(res => {
      this.innerFolders = this.innerFolders.filter(el => el.folderId != this.libraryService.getFolderToDelete().folderId)
      console.log(this.innerFolders)
      
      this.toastService.setShowToastmessage({
        severity: 'success',
        summary: '',
        detail: 'Folder has been deleted.'
      });
      this.removeFolderByFolderId(this.libraryService.getFolderToDelete())
      this.libraryService.setFolderToDelete({} as Folder);
      this.libraryService.setUpdateListener(true);
    })
    this.deleteFolderConfirmData.showDialog = false;
  }

  onTagChoosen(event: any) {
    let isLevel = event.isLevel;
    if (event.checked) {
      if (event.applyToAll) {
        for (let file of this.libraryToAdd.libraryFiles) {
          let arrLibrary: string[] = isLevel ? file.levels : file.categories;
          arrLibrary.push(event.tag)
        }
      } else {
        let file = this.libraryToAdd.libraryFiles[event.file.index]
        let arrLibrary: string[] = isLevel ? file.levels : file.categories;
        arrLibrary.push(event.tag)
      }
    } else {
      if (event.applyToAll) {
        for (let file of this.libraryToAdd.libraryFiles) {
          let arrLibrary: string[] = isLevel ? file.levels : file.categories;
          let index2 = arrLibrary.findIndex(((el: string) => el == event.tag));
          arrLibrary.splice(index2, 1);
        }
      } else {
        let file = this.libraryToAdd.libraryFiles[event.file.index]
        let arrLibrary: string[] = isLevel ? file.levels : file.categories;
        let index2 = arrLibrary.findIndex(((el: string) => el == event.tag));
        arrLibrary.splice(index2, 1);
      }
    }
  }
  onCreateFolder() {
    this.libraryService.getUserCreatedLibFolders().pipe(take(1)).subscribe(res => {
      let found = false;
      for (let r of res) {
        if (r.parent == this.folderPath[this.folderPath.length - 1].name && r.name == this.folderName) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.showInput = false;
        let newFolder: Folder = {
          parent: this.folderPath[this.folderPath.length - 1].name,
          name: this.folderName
        }
        this.libraryService.createFolder(newFolder).pipe(take(1)).subscribe(res => {
          this.innerFolders.push(res)
          this.allLibrariesWithFiles.push({
            parent: this.folderPath[this.folderPath.length - 1].name,
            name: this.folderName,
            libraryFiles: [],
            createdBy: this.user,
            folderId: 10000
          });
        this.toastService.setShowToastmessage({
          severity: 'success',
          summary: '',
          detail: 'Folder created.'
        });
        this.folderName = '';
        this.libraryService.setUpdateListener(true);
        })
      } else {
        this.toastService.setShowToastmessage({
          severity: 'warn',
          summary: '',
          detail: 'Folder already exists.'
        });
      }
    })
  }

  createFolder() {
    this.showInput = !this.showInput;
  }
  
  onShowUploadDialog() {
    const targetElementName = this.libraryService.isPreviewingFromChat() ? 'notifications-overview' : 'mainContentColumn';
    const myElement = document.getElementById(targetElementName);
    // this.libraryToAdd = this.libraryService.initializeEmptyLibraryObject();
    // this.libraryToAdd.libraryFiles = [];
    this.userService.setNewFileUpload({});
    console.log(myElement!.getBoundingClientRect().width);
    const dialogWidth = myElement!.getBoundingClientRect().width - 10;

    this.generalService.openDialogWithComponent(this.dialogService, LibraryFileActionsDialogComponent, 
      dialogWidth.toFixed(), 
      {
        action: 'upload',
        dialogsubTitle: '',
        dialogTitle: 'Upload to library...',
        folder: this.libraryToAdd.folder,
        libraryToAdd: this.libraryToAdd,
      }, targetElementName, (result: any) => {
      if (result && result.action === 'upload') {
        console.log(result);
        this.libraryToAdd.libraryFiles = result.libraryFiles;
        this.onSubmit();
        // this.moveFile(result.map.map);
      }
    }, myElement!.getBoundingClientRect().height - 90 + 'px');
  }
  
  onSubmit() {
    if (this.libraryToAdd.libraryFiles.length > 0) {
      this.libraryToAdd.classroom = this.classroomIdToAddLib;
      let toSend: UploadFilesToFolderRequest = {
        folderId: this.libraryToAdd.folder.folderId!,
        classroomId: this.classroomIdToAddLib,
        name: this.libraryToAdd.folder.name,
        parent: this.libraryToAdd.folder.parent,
        libFiles: this.libraryToAdd.libraryFiles,
        StudentIds: this.studentsToShareWith
      }

      // return;
      if (this.classroomIdToAddLib > 0) {
        this.libraryService.uploadClassroomFilesToFolder(toSend).pipe(take(1)).subscribe(res => {
          this.toastService.setShowToastmessage({
            severity: 'success',
            summary: '',
            detail: 'Your files have been uploaded to Classroom library.'
          });

          this.userService.setUploadFiles(true);
          this.mapLibraryToAddFiles(res);
          this.e()
        })
      } else {
        this.libraryService.uploadFilesToFolder(toSend).pipe(take(1)).subscribe((res: any) => {
          this.toastService.setShowToastmessage({
            severity: 'success',
            summary: '',
            detail: 'Your files have been uploaded to library folder: ' + toSend.name
          });

          this.currentUploadFolder = this.innerFolders.filter(el => el.folderId === toSend.folderId)[0]
          this.userService.setUploadFiles(true);
          this.mapLibraryToAddFiles(res);
          this.e()
          console.log(this.currentUploadFolder);
          // this.libraryService.setOpenFolderListener(currentFolder[0]);
          // this.libraryService.setUpdateFolderListener(toSend.libFiles[0], currentFolder[0])
        
        })
      }

    } else {
      this.toastService.setShowToastmessage({
        severity: 'warn',
        summary: '',
        detail: 'Please add files'
      });
    }
  }

  e() {

    this.filteredFiles.concat(this.libraryToAdd.libraryFiles)
    this.foldersWithFiles.push({
      folderId: this.libraryToAdd.folder.folderId!,
      name: this.libraryToAdd.folder.name,
      parent: this.libraryToAdd.folder.parent,
      libraryFiles: this.libraryToAdd.libraryFiles,
      createdBy: this.user
    })
    // this.getFoldersWithFiles();
    // this.showAddLibrary = !this.showAddLibrary
    // this.generalService.slideNativeElements(this.showAddLibrary, this.addLibrary.nativeElement);

    console.log(this.filteredFiles);
    this.allLibrariesWithFiles.push({
      folderId: this.libraryToAdd.folder.folderId!,
      name: this.libraryToAdd.folder.name,
      parent: this.libraryToAdd.folder.parent,
      libraryFiles: this.libraryToAdd.libraryFiles,
      createdBy: this.user
    })
    // this.files.push(this.files[this.files.length - 1]);
    this.initFiles()
    this.libraryToAdd = this.libraryService.initializeEmptyLibraryObject();
    // this.libraryToAdd.libraryFiles = [];

    // this.libraryService.setUpdateListener(true)
    this.userService.setNewFileUpload({});
  }

  // addes also fileId to a new uploaded file in the library
  mapLibraryToAddFiles(res: any) {
    this.libraryToAdd.libraryFiles = res.libFiles.map((file: {}, index: number) => ({
      ...file,
      fileId: res.fileIds![index]
    }));
  }

  uploadFinished(event: any) {
    console.log(event);
    this.libraryToAdd.libraryFiles?.push({ 
      path: event.filePath.dbPath, 
      levels: [], 
      categories: [], 
      name: event.filePath.dbPath, 
      fileId: event.filePath.dbPath });
  }

  /**
  This method is triggered when a file is deleted from the classroom files.
  It filters out the deleted file from the libraryFiles array of each folder in the allLibrariesWithFiles array.
  @param {any} event - The event object containing the ID of the deleted file.
  @returns {void}
  */
  onFileDeleted(event: any) {
    this.deleteFileConfirmData.showDialog = true;
    this.deleteFileConfirmData.message = 'Delete file "' + event.name + '"?';
    // this.allLibrariesWithFiles = this.allLibrariesWithFiles..filter(file => file.fileId !== libFile.fileId);
    this.libraryService.setLibraryFile(event);
  }

  deleteFile(fileId : any) {
    this.subs.add(this.libraryService.delete(fileId).subscribe(res => {
      this.toastService.setShowToastmessage({
        severity: 'success',
        summary: '',
        detail: 'Files has been deleted successfully.'
      });
    }));
  }

  moveMultipleFiles() {
    if (this.checkedLibraryFiles.length === 0) {
      this.toastService.setShowToastmessage({
        severity: 'warn',
        summary: '',
        detail: 'Please choose at least one file!'
      });
      return;
    }
    this.libraryService.openDialogWithComponent(this.dialogService, LibraryFileActionsDialogComponent, 400, 
      {
        action: 'moveMultiple',
        dialogsubTitle: 'Move Files to folder...',
        dialogTitle: 'Choose folder...',
        folders: this.folders
      }, null, (result: any) => {
      if (result && result.action === 'moveMultiple') {
        console.log(result);
        console.log(this.checkedLibraryFiles);
        this.checkedLibraryFiles.forEach(libFile => {
          this.moveFileAPI(libFile.fileId!, result.folder.folderId);
          this.libraryService.setUpdateListener(true)
        });
        // this.moveFile(result.map.map);
      }
    });
  }

  deleteMultipleFiles() {
    if (this.checkedLibraryFiles.length === 0) {
      this.toastService.setShowToastmessage({
        severity: 'warn',
        summary: '',
        detail: 'Please choose at least one file!'
      });
      return;
    }
    this.deleteMultipleFilesConfirmData.showDialog = true;
    this.deleteMultipleFilesConfirmData.message = 'Delete the selected files ?';
  }

  onAcceptFileDeleteMultiple(event: any) {
    const libFiles = this.checkedLibraryFiles;
    libFiles.forEach(libFile => {
      this.filterDelteFromLibraryFiles(libFile.fileId);
      this.deleteFileAPI(libFile);
    });
    this.deleteFileConfirmData.showDialog = false;
    this.deleteMultipleFilesConfirmData.showDialog = false;
    this.checkedLibraryFiles = [];
  }

  filterDelteFromLibraryFiles(...fileIds: any[]) {
    if (fileIds.length === 1) {
      this.filteredFiles = this.filteredFiles.filter(el => el.fileId !== fileIds[0]);
    } else {
      this.filteredFiles = this.filteredFiles.filter(el => !fileIds.includes(el.fileId));
    }
  }
  
  onRejectFileDelete(event: any) {
    this.deleteFileConfirmData.showDialog = false;
    this.deleteMultipleFilesConfirmData.showDialog = false;
    this.libraryService.setLibraryFile({} as LibraryFile);
  }

  onAcceptFileDelete(event: any) {
    const libFile = this.libraryService.getLibraryFile();
    this.filterDelteFromLibraryFiles(libFile.fileId);
    this.deleteFileAPI(libFile);
  }

  private deleteFileAPI(libFile: LibraryFile) {
    this.subs.add(this.libraryService.delete(libFile.fileId).subscribe((res: boolean) => {

      if (res) {
        this.deleteFileConfirmData.showDialog = false;
        this.toastService.setShowToastmessage({
          severity: 'success',
          summary: '',
          detail: 'Files has been deleted successfully..'
        });
        this.removeLibraryFileByFileId(libFile);
      }

    }));
  }

  private removeLibraryFileByFileId(libFile: LibraryFile) {
    this.allLibrariesWithFiles = this.allLibrariesWithFiles.map(file => {
      const filteredLibraryFiles = file.libraryFiles.filter(libraryFile => libraryFile.fileId !== libFile.fileId);
      return { ...file, libraryFiles: filteredLibraryFiles };
    });
  }

  private removeFolderByFolderId(folder: Folder) {
    this.allLibrariesWithFiles = this.allLibrariesWithFiles.filter(file => {
      return file.folderId !== folder.folderId;
    });
  }

  private moveFileAPI(fileId: number, moveFolderId: number) {
    this.subs.add(this.libraryService.moveFileToFolder(fileId, moveFolderId).subscribe( (response) => {
      this.toastService.setShowToastmessage({
        severity: 'success',
        summary: '',
        detail: 'File moved successfully.'
      });
      this.checkedLibraryFiles = [];
      this.showGroupActions = false;
      // this.openFolder(this.libraryToAdd.folder);
      
      this.libraryService.setUpdateListener(true)
    }));
  }

  oncollapseNotesLeftSideChanged(event: any) {
    this.isLeftsideCollapsed = event;
  }

  setGroupActions() {
    this.showGroupActions = !this.showGroupActions;
    // this.libraryService.setGroupActions(this.showGroupActions);
    setTimeout(() => {
      // this.libraryService.setGroupActions(this.showGroupActions);
    }, 300);
  }

  onTabChangeFilterFilesPerStudent(event: any) {
    if (event.index > 0) {
      this.studentsToShareWith = [];
      const currentStudent = this.classroomStudents[event.index - 1];
      this.setStudentsToShareWith(this.classroomStudents[event.index - 1].aspUserId);

      this.filteredFiles = this.files.filter(folder =>
        folder.sharedWith!.id === currentStudent.aspUserId
      );
    } else {
      this.filteredFiles = this.files;
      this.setStudentsToShareWith();
    }
  }

  setStudentsToShareWith(studentId?: string) {
    this.studentsToShareWith = studentId? [studentId] : this.classroomStudents.map((user: User) => user.aspUserId);
  }

  getFilteredFiles(unique = false) {
    if (!unique) {
      return this.filteredFiles;
    } else {
      const uniqueFilteredFiles = this.filteredFiles = this.filteredFiles.filter((file, index, self) =>
        index === self.findIndex((f) => (
          f.fileId === file.fileId
        )));
      return uniqueFilteredFiles;
    }
  }

  onFileUnshared(event: any) {
    console.log(event);
    this.libraryService.setUpdateListener(true)
  }

  initOpenFolderListener() {
    this.subs.sink = this.libraryService.libraryUpdatedListener.subscribe((res: boolean) => {
      if ((res && this.currentUploadFolder)) {
        if (!this.generalService.isNullishObject(this.currentUploadFolder)) {

          this.openFolder(this.currentUploadFolder);
          console.log(this.libraryToAdd.folder);
        }
  
      }
    });
  }

}