import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeacherApplicationComponent } from './teacher-application.component';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { TeacherApplicationRoutingModule } from './teacher-application-routing.module';
import { TeacherMenuComponent } from './teacher-menu/teacher-menu.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { EducationComponent } from './education/education.component';
import { ExperienceComponent } from './experience/experience.component';
import { ResumeComponent } from './resume/resume.component';
import { ReviewComponent } from './review/review.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DndDirective } from 'src/app/core/directives/dnd.directive';
import { SharedModule } from 'src/app/shared/shared.module';
import { TeacherAvailabilityComponent } from './teacher-availability/teacher-availability.component';
import { StatusComponent } from './status/status.component';
import {CalendarModule} from 'primeng/calendar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ButtonModule} from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import {RippleModule} from 'primeng/ripple';
import { TeacherApplicationDeactivateGuard } from 'src/app/core/guards/teacher-application.guard';
@NgModule({
  declarations: [
    TeacherApplicationComponent,
    GeneralInfoComponent,
    TeacherMenuComponent,
    ContactInfoComponent,
    EducationComponent,
    ExperienceComponent,
    ResumeComponent,
    TeacherAvailabilityComponent,
    ReviewComponent,
    StatusComponent,
  ],
  imports: [
    CommonModule,
    TeacherApplicationRoutingModule,
    ReactiveFormsModule,
    DropdownModule,
    FormsModule,
    SharedModule,
    CalendarModule,
    ProgressSpinnerModule,
    ButtonModule,
    ConfirmDialogModule,
    RippleModule,
  ],
  exports: [
    TeacherApplicationComponent,
  ],
  providers: [ConfirmationService, TeacherApplicationDeactivateGuard]
})
export class TeacherApplicationModule { }
