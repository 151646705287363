import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Classroom } from 'src/app/core/models/classroom.model';

@Component({
  selector: 'app-lesson-rating-calendar-dialog',
  templateUrl: './lesson-rating-calendar-dialog.component.html',
  styleUrls: ['./lesson-rating-calendar-dialog.component.scss']
})
export class LessonRatingCalendarDialogComponent implements OnInit, OnDestroy {

  ratingMode = 'create';
  dialogData: any = {};
  classroom: Classroom = {} as Classroom;
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
    this.dialogData = this.config.data.dialogData;
    this.classroom = this.dialogData.classroom;
    this.ratingMode = this.dialogData.ratingMode ? this.dialogData.ratingMode : this.ratingMode;
    console.log(this.ratingMode);
  }

  ngOnDestroy(): void {
    this.ref.close();
  }

  onDialogClose(data?: any) {
    this.ref.close(data);
  }

  get lessonBlockTitle() {
    switch (this.ratingMode) {
      case 'create':
        return 'Rate your lesson';
      case 'edit':
        return 'Edit your lesson rating';
      case 'view':
        return 'View your lesson rating';
    }
    return 'Rate your lesson';
  }

}
