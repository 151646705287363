import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-lesson-rating-range-slider',
  templateUrl: './lesson-rating-range-slider.component.html',
  styleUrls: ['./lesson-rating-range-slider.component.scss'],
})
export class LessonRatingRangeSliderComponent implements OnInit {

  @Input() rating!: number;
  @Input() category!: string;
  @Output() categoryRatingChanged = new EventEmitter<{}>();

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    console.log(this.category);
  }

  ngAfterViewInit() {
    const bullet = this.el.nativeElement.querySelector('.rs-bullet');
    const input = this.el.nativeElement.querySelector('.rs-range-line');
    this.showSliderValue(bullet, input);
  }

  showSliderValue(bullet: any, slider: any) {
    bullet.innerHTML = this.rating;
    var sliderWidth = slider.offsetWidth;
    var sliderMax = slider.max;
    var sliderValue = (this.rating);
    var bulletPosition = (sliderValue / sliderMax) * sliderWidth;
    let badOffset = sliderValue; //= sliderValue === 0? 2 : sliderValue;
    switch (sliderValue) {
      case 0:
        badOffset = 2;
        break;
      case 1:
        badOffset = 0;
        break;
      case 2:
        badOffset = 2;
        break;
      case 3:
        badOffset = 1;
        break;
    }
    bullet.style.left = (sliderValue === 0) ? 2 + "px" : (bulletPosition - (badOffset * 1.3)) + "px";
    // bullet.style.top = "10px";
    console.log(sliderValue, this.category);
    this.categoryRatingChanged.emit({value: sliderValue, category: this.category});
  }
}
