import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Classroom, ClassroomType, Status } from 'src/app/core/models/classroom.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { slideInOut } from 'src/app/helpers/my-animations';

@Component({
  selector: 'app-trial',
  templateUrl: './trial.component.html',
  styleUrls: ['./trial.component.scss'],
  animations: [slideInOut]
})
export class TrialComponent implements OnInit {
  filteredClassrooms: Classroom[] = [];
  isLoading: boolean = true;
  public role: string = "";
  showActive: boolean = true;
  showCompelted: boolean = false;
  showDismissed: boolean = false;
  constructor(
    private classroomService: ClassroomService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    //TODO HOW TO HANDLE TRIALS
    this.role = this.authService.getLoggedInUser().role;
    if (this.classroomService.sessionUserTrialClassrooms.length > 0) {
      this.isLoading = false;
      this.filteredClassrooms = this.classroomService.sessionUserTrialClassrooms
    } else {
      this.isLoading = true;
    }
    this.classroomService.getUserClassrooms(ClassroomType.TRIAL).pipe(take(1)).subscribe((res: Classroom[]) => {
      this.filteredClassrooms = res
      this.classroomService.sessionUserTrialClassrooms = res;
      this.isLoading = false;
    })
  }

  viewSection(section: string) {
    if (section === 'active') {
      document.getElementById('completed')?.classList.remove('classrooms-header-active');
      document.getElementById('dismissed')?.classList.remove('classrooms-header-active');
      document.getElementById('all')?.classList.remove('classrooms-header-active');
      document.getElementById('active')?.classList.add('classrooms-header-active');
      this.filteredClassrooms = this.classroomService.sessionUserTrialClassrooms.filter(el => el.status == Status.ON_GOING)
    } else if (section === 'completed') {
      document.getElementById('all')?.classList.remove('classrooms-header-active');
      document.getElementById('completed')?.classList.add('classrooms-header-active');
      document.getElementById('dismissed')?.classList.remove('classrooms-header-active');
      document.getElementById('active')?.classList.remove('classrooms-header-active');
      this.filteredClassrooms = this.classroomService.sessionUserTrialClassrooms.filter(el => el.status == Status.COMPLETED)
    } else if (section === 'all') {
      document.getElementById('all')?.classList.add('classrooms-header-active');
      document.getElementById('completed')?.classList.remove('classrooms-header-active');
      document.getElementById('dismissed')?.classList.remove('classrooms-header-active');
      document.getElementById('active')?.classList.remove('classrooms-header-active');
      this.filteredClassrooms = this.classroomService.sessionUserTrialClassrooms
    } else {
      document.getElementById('all')?.classList.remove('classrooms-header-active');
      document.getElementById('completed')?.classList.remove('classrooms-header-active');
      document.getElementById('dismissed')?.classList.add('classrooms-header-active');
      document.getElementById('active')?.classList.remove('classrooms-header-active');
      this.filteredClassrooms = this.classroomService.sessionUserTrialClassrooms.filter(el => el.status == Status.DISMISSED)
    }
  }
}
