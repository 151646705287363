import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { Classroom, ClassroomType } from 'src/app/core/models/classroom.model';
import { Lesson, LessonStatus } from 'src/app/core/models/lesson.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { LessonService } from 'src/app/core/services/lesson.service';
import { dummyLessons } from 'src/app/core/models/data';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.scss']
})
export class RescheduleComponent implements OnInit {
  private subs = new SubSink();
  public lessons: Lesson[] = [];
  public classrooms: any[] = []
  public choosenClassroom?: Classroom;
  public duration: number[] = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3]
  public lessonToReschedule: Lesson = {} as Lesson
  public role: string = ""
  durationSelect?: number;
  dummyLessons = dummyLessons;
  constructor(
    private lessonService: LessonService,
    public classroomService: ClassroomService,
    private calendarService: CalendarService,
    private location: Location,
    private authService: AuthService,
    private generalService: GeneralService,
  ) { }

  ngOnInit(): void {
    this.role = this.authService.getLoggedInUser().role!
    this.lessonToReschedule = this.lessonService.lessonToReschedule
    if (this.lessonToReschedule.id) {
      this.durationSelect = this.lessonToReschedule.duration
      this.calendarService.setDurationListener(this.durationSelect!)
    }
    this.lessons = this.lessonService.sessionAllUserLessons;
    this.lessons = this.dummyLessons;
    this.subs.sink = this.lessonService.getAllUserLessons().subscribe(res => {
      this.lessons = res;
      this.lessonService.sessionAllUserLessons = res;
    })



    this.classrooms = this.classroomService.sessionUserClassrooms;
    if (this.classrooms.length > 0) {
      if (this.classroomService.classroomToArrangeLesson.id) {
        this.choosenClassroom = this.classrooms.find(el => el.id == this.classroomService.classroomToArrangeLesson.id)!
        if (this.choosenClassroom?.type == 'Trial') {
          this.duration = [1]
        } else {
          this.duration = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3]
        }
        console.log(this.choosenClassroom)
        this.calendarService.setChooseClassroom(this.choosenClassroom!)
      } else {
        console.log(this.choosenClassroom)
        this.choosenClassroom = this.classrooms[0]
        if (this.choosenClassroom?.type == 'Trial') {
          this.duration = [1]
        } else {
          this.duration = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3]
        }
      }
    }
      console.log(this.classrooms)
      console.log(this.classroomService.classroomToArrangeLesson.id)
      if (this.classroomService.classroomToArrangeLesson.id) {
        this.choosenClassroom = this.classrooms.find(el => el.id == this.classroomService.classroomToArrangeLesson.id)!
        if (this.choosenClassroom?.type == 'Trial') {
          this.duration = [1]
        } else {
          this.duration = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3]
        }
        console.log(this.choosenClassroom)
        this.calendarService.setChooseClassroom(this.choosenClassroom!)
      } else {
        console.log(this.choosenClassroom)
        this.choosenClassroom = this.classrooms[0]
        if (this.choosenClassroom?.type == 'Trial') {
          this.duration = [1]
        } else {
          this.duration = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3]
        }
      }
      this.classroomService.sessionUserClassrooms = this.classrooms;




  }

  ngOnDestroy() {
    this.calendarService.setChooseClassroom({} as Classroom)
    this.lessonService.setAddListener(false);
    this.lessonService.setUpdateListener(false);
    this.lessonService.setDeleteListener(false);
    this.calendarService.setScheduleListener(false, false)
    this.classroomService.classroomToArrangeLesson = {} as Classroom
    this.lessonService.lessonToReschedule = {} as Lesson;
    this.subs.unsubscribe();
  }

  getLessonDate() {
    return this.calendarService.getPrettyLessonDate(this.lessonToReschedule.startingDate)
  }

  onChooseClassroomByStudent(event: any) {
    this.choosenClassroom = event.value;
    if (this.choosenClassroom?.type == 'Trial') {
      this.duration = [1]
    } else {
      this.duration = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3]
    }
    this.calendarService.setChooseClassroom(this.choosenClassroom!)
  }

  getClassroomTitle() {
    return this.classroomService.getClassroomTitle(this.choosenClassroom!)
  }

  getClassroomTitle2(classroom: Classroom) {
    return this.classroomService.getClassroomTitle(classroom)
  }

  onChooseDuration(event: any) {
    this.durationSelect = event.value
    this.calendarService.setDurationListener(this.durationSelect!)
    console.log(this.durationSelect)
  }

  schedule() {
    console.log(this.choosenClassroom)
    if (this.durationSelect && this.durationSelect > 0) {
      let isUpdating = this.lessonToReschedule.id ? true : false
      this.calendarService.setScheduleListener(true, isUpdating)
    } else {
    }
  }

  goBack() {
    this.location.back();
  }
}
