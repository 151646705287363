import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Form, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AvailabilityTime, Teacher } from 'src/app/core/models/teacher.model';
import { User, UserAvailability, UserAvailabilityHours, UserAvailabilityType } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { TeacherApplicationService } from 'src/app/core/services/teacher-application.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserService } from 'src/app/core/services/user.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit {
  @Input() user: User = {} as User;
  @Input() showHours: boolean = true;
  @Input() isDialog: boolean = false;
  private subs = new SubSink();
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public teacher?: Teacher | User;
  weekDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  public teacherAvailability?: UserAvailability;
  public teacherAvailabilityMon: { from: string, to: string }[] = [];
  public teacherAvailabilityTue: { from: string, to: string }[] = [];
  public teacherAvailabilityWed: { from: string, to: string }[] = [];
  public teacherAvailabilityThu: { from: string, to: string }[] = [];
  public teacherAvailabilityFri: { from: string, to: string }[] = [];
  public teacherAvailabilitySat: { from: string, to: string }[] = [];
  public teacherAvailabilitySun: { from: string, to: string }[] = [];

  public hourOptions: string[] = ['5-10', '10-15', '15-20', '20-25', '25-30', '30-35', '35-40']
  public selectedHours: string = "5-10";

  public tryToSave: boolean = false;
  defaultDate: Date = new Date("January 31 2024 00:00");
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private location: Location,
    private teacherService: TeacherApplicationService,
    private userService: UserService,
    private toast: ToastService,
    private authService: AuthService,
    private calendarService: CalendarService,
    private generalService: GeneralService,
  ) { }

  ngOnInit(): void {
    this.teacherService.setCurrentStepIndex(4);
    this.subs.add(this.teacherService.updateAvailabilityListener.subscribe(res => {
      this.teacherAvailabilityMon = [];
      this.teacherAvailabilityTue = [];
      this.teacherAvailabilityWed = [];
      this.teacherAvailabilityThu = [];
      this.teacherAvailabilityFri = [];
      this.teacherAvailabilitySat = [];
      this.teacherAvailabilitySun = [];
      this.teacher = this.user ? this.authService.getLoggedInUser()! : this.teacherService.dummyTeacher;
      this.teacherAvailability = this.teacher?.availability;
      this.form = new UntypedFormGroup({
        mon: new UntypedFormArray([]),
        tue: new UntypedFormArray([]),
        wed: new UntypedFormArray([]),
        thu: new UntypedFormArray([]),
        fri: new UntypedFormArray([]),
        sat: new UntypedFormArray([]),
        sun: new UntypedFormArray([]),
        mondayActive: new UntypedFormControl(false),
        tuesdayActive: new UntypedFormControl(false),
        wednesdayActive: new UntypedFormControl(false),
        thursdayActive: new UntypedFormControl(false),
        fridayActive: new UntypedFormControl(false),
        saturdayActive: new UntypedFormControl(false),
        sundayActive: new UntypedFormControl(false),
        hours: new UntypedFormControl(this.teacher?.hours_per_week ? this.teacher?.hours_per_week : this.hourOptions[0], {
          validators: [Validators.required]
        }),
      });

    }));


    this.subs.add(this.teacherService.submitOnMenuClickListener.subscribe(res => {
      if (res) {
        this.onSubmit();
      }
    }));

    if (this.isDialog) {
      this.userService.getUserAvailability(this.user.aspUserId!).subscribe((res: AvailabilityTime) => {
        if (res) {
          this.updateDaysFields(res);
        }
      });
    } else {
      this.subs.add(this.teacherService.getTeacherApplicationStep5().subscribe((res: AvailabilityTime) => {
        if (res) {
          this.updateDaysFields(res);
        }
      }));
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.teacherService.setSubmitOnMenuClickListener(false)
  }

  /**
   * Handle the change event of the time picker control
   * @param {any} $event - The change event data
   * @param {FormArray} formArray - The form array that contains the time picker control
   * @param {any} i - The index of the control in the form array
   * @param {string} property - The property name to be updated with the selected time
   */
  timePickChanged($event: any, formArray: UntypedFormArray, i: any, property: string) {
    const dd = new Date($event);

    const timeConverted: Record<string, string> = {} as Record<string, string>;
    timeConverted[property] = dd.toLocaleString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    });
    const timeArray = timeConverted[property].split(":");
    console.log(timeArray);
    if (timeArray[0] <= '23') {
      console.log(dd)
      console.log(timeConverted);
      let controls = this.form.controls;
      formArray.at(i).patchValue(
        timeConverted
      )
      
      return false;
    } else {
      formArray.at(i).patchValue(
        {from: '00:00', to: '00:'+timeArray[1]}
      )
      return false;
      
    }
  }

  updateDaysFields(availability: any) {

    this.form.get('mondayActive')?.patchValue(availability.mondayActive || !this.hasEmptyFromOrTo(availability!.mon));
    this.form.get('tuesdayActive')?.patchValue(availability.tuesdayActive || !this.hasEmptyFromOrTo(availability!.tue));
    this.form.get('wednesdayActive')?.patchValue(availability.wednesdayActive || !this.hasEmptyFromOrTo(availability!.wed));
    this.form.get('thursdayActive')?.patchValue(availability.thursdayActive || !this.hasEmptyFromOrTo(availability!.thu));
    this.form.get('fridayActive')?.patchValue(availability.fridayActive || !this.hasEmptyFromOrTo(availability!.fri));
    this.form.get('saturdayActive')?.patchValue(availability.saturdayActive || !this.hasEmptyFromOrTo(availability!.sat));
    this.form.get('sundayActive')?.patchValue(availability.sundayActive || !this.hasEmptyFromOrTo(availability!.sun));
    this.form.get('hours')?.patchValue(availability.hours);

    const mondayActive = this.form.get('mondayActive')?.value;
    const tuesdayActive = this.form.get('tuesdayActive')?.value;
    const wednesdayActive = this.form.get('wednesdayActive')?.value;
    const thursdayActive = this.form.get('thursdayActive')?.value;
    const fridayActive = this.form.get('fridayActive')?.value;
    const saturdayActive = this.form.get('saturdayActive')?.value;
    const sundayActive = this.form.get('sundayActive')?.value;


    if (availability?.mon && mondayActive) {
      for (let m of availability!.mon) {
        this.teacherAvailabilityMon.push(
          {
            from: m.from,
            to: m.to
          }
        )
      }
    }

    if (availability?.tue && tuesdayActive) {
      for (let m of availability!.tue) {
        this.teacherAvailabilityTue.push(
          {
            from: m.from,
            to: m.to
          }
        )
      }
    }

    if (availability?.wed && wednesdayActive) {
      for (let m of availability!.wed) {
        this.teacherAvailabilityWed.push(
          {
            from: m.from,
            to: m.to
          }
        )
      }
    }

    if (availability?.thu && thursdayActive) {
      for (let m of availability!.thu) {
        this.teacherAvailabilityThu.push(
          {
            from: m.from,
            to: m.to
          }
        )
      }
    }

    if (availability?.fri && fridayActive) {
      for (let m of availability!.fri) {
        this.teacherAvailabilityFri.push(
          {
            from: m.from,
            to: m.to
          }
        )
      }
    }

    if (availability?.sat && saturdayActive) {
      for (let m of availability!.sat) {
        this.teacherAvailabilitySat.push(
          {
            from: m.from,
            to: m.to
          }
        )
      }
    }

    if (availability?.sun && sundayActive) {
      for (let m of availability!.sun) {
        this.teacherAvailabilitySun.push(
          {
            from: m.from,
            to: m.to
          }
        )
      }
    }

    this.teacherAvailabilityMon?.forEach(element => {
      this.mon.push(this.fb.group(element))
    });

    if (this.teacherAvailabilityMon.length === 0) {
      this.addMon()
    }

    this.teacherAvailabilityTue?.forEach(element => {
      this.tue.push(this.fb.group(element))
    });

    if (this.teacherAvailabilityTue?.length === 0) {
      this.addTue()
    }

    this.teacherAvailabilityWed?.forEach(element => {
      this.wed.push(this.fb.group(element))
    });

    if (this.teacherAvailabilityWed?.length === 0) {
      this.addWed()
    }

    this.teacherAvailabilityThu?.forEach(element => {
      this.thu.push(this.fb.group(element))
    });

    if (this.teacherAvailabilityThu?.length === 0) {
      this.addThu()
    }

    this.teacherAvailabilityFri?.forEach(element => {
      this.fri.push(this.fb.group(element))
    });

    if (this.teacherAvailabilityFri?.length === 0) {
      this.addFri()
    }

    this.teacherAvailabilitySat?.forEach(element => {
      this.sat.push(this.fb.group(element))
    });

    if (this.teacherAvailabilitySat?.length === 0) {
      this.addSat()
    }

    this.teacherAvailabilitySun?.forEach(element => {
      this.sun.push(this.fb.group(element))
    });

    if (this.teacherAvailabilitySun.length === 0) {
      this.addSun()
    }
  }

  initCheckboxChangedListener(event: any, dayParam: any, dayActive: any) {
    if (!event.checked) {
      dayParam.controls?.forEach((element: any, index: number) => {
        console.log(element);
        const group = new UntypedFormGroup({
          from: new UntypedFormControl(''),
          to: new UntypedFormControl('')
        });
        this.form.setControl(dayActive, this.fb.array([group]));
      });

    }
  }

  removeDay(name: string, index: number) {
    switch (name) {
      case 'mon':
        this.removeMon(index);
        break;
      case 'tue':
        this.removeTue(index);
        break;
      case 'wed':
        this.removeWed(index);
        break;
      case 'thu':
        this.removeThu(index);
        break;
      case 'fri':
        this.removeFri(index);
        break;
      case 'sat':
        this.removeSat(index);
        break;
      case 'sun':
        this.removeSun(index);
        break;
    }
  }
  // TODO: unused, see if can be used, else delete
  processDay(day: string) {
    day = day.toLowerCase();
    const availability = [`teacherAvailability${day}`];
    let list: UntypedFormGroup[] = [];
    availability?.forEach(element => {
      const group = new UntypedFormGroup({
        from: new UntypedFormControl(''),
        to: new UntypedFormControl('')
      });
      list.push(this.fb.group(group));
    });
  }

  // TODO: unused, see if can be used, else delete
  add(day: string) {
    const dayString = day.toString();
    const control = this.form.get(dayString.toLowerCase()) as UntypedFormArray;
    const group = new UntypedFormGroup({
      from: new UntypedFormControl(''),
      to: new UntypedFormControl('')
    });
    (control).push(group);
  }

  addMon() {
    const group = new UntypedFormGroup({
      from: new UntypedFormControl(''),
      to: new UntypedFormControl('')
    });
    this.mon.push(group);
  }

  removeMon(index: number) {
    console.log('remove',index);
    this.mon.removeAt(index);
  }

  addTue() {
    const group = new UntypedFormGroup({
      from: new UntypedFormControl(''),
      to: new UntypedFormControl('')
    });
    this.tue.push(group);
  }

  removeTue(index: number) {
    this.tue.removeAt(index);
  }

  addWed() {
    const group = new UntypedFormGroup({
      from: new UntypedFormControl(''),
      to: new UntypedFormControl('')
    });
    this.wed.push(group);
  }

  removeWed(index: number) {
    this.wed.removeAt(index);
  }

  addThu() {
    const group = new UntypedFormGroup({
      from: new UntypedFormControl(''),
      to: new UntypedFormControl('')
    });
    this.thu.push(group);
  }

  removeThu(index: number) {
    this.thu.removeAt(index);
  }

  addFri() {
    const group = new UntypedFormGroup({
      from: new UntypedFormControl(''),
      to: new UntypedFormControl('')
    });
    this.fri.push(group);
  }

  removeFri(index: number) {
    this.fri.removeAt(index);
  }

  addSat() {
    const group = new UntypedFormGroup({
      from: new UntypedFormControl(''),
      to: new UntypedFormControl('')
    });
    this.sat.push(group);
  }

  removeSat(index: number) {
    this.sat.removeAt(index);
  }

  addSun() {
    const group = new UntypedFormGroup({
      from: new UntypedFormControl(''),
      to: new UntypedFormControl('')
    });
    this.sun.push(group);
  }

  removeSun(index: number) {
    this.sun.removeAt(index);
  }

  get mon(): UntypedFormArray {
    return this.form.get('mon') as UntypedFormArray;
  }

  get tue(): UntypedFormArray {
    return this.form.get('tue') as UntypedFormArray;
  }

  get wed(): UntypedFormArray {
    return this.form.get('wed') as UntypedFormArray;
  }

  get thu(): UntypedFormArray {
    return this.form.get('thu') as UntypedFormArray;
  }

  get fri(): UntypedFormArray {
    return this.form.get('fri') as UntypedFormArray;
  }

  get sat(): UntypedFormArray {
    return this.form.get('sat') as UntypedFormArray;
  }

  get sun(): UntypedFormArray {
    return this.form.get('sun') as UntypedFormArray;
  }

  onSubmit() {
    this.tryToSave = true;

    // if (!this.form.valid) {
    //   return;
    // }

    let availability: UserAvailability = {
      id: "string",
      type: UserAvailabilityType.AVAILABLE,
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    const activeDay = ['mondayActive', 'tuesdayActive', 'wednesdayActive', 'thursdayActive', 'fridayActive', 'saturdayActive', 'sundayActive'];
    const controls = this.form.controls;
    for (let day of days) {
      const castedDay = day as keyof UserAvailability;
      if (Array.isArray(availability[castedDay])) {
        for (let availabilitySlot of controls[day].value) {
          for (let activeDayFormValue of activeDay) {
          if (this.form.get(activeDayFormValue)!.value) {
            (availability[castedDay] as UserAvailabilityHours[]).push({ from: availabilitySlot.from, to: availabilitySlot.to });
          }
        }
        }
      }
    }

    // console.log(this.form.value);

    if (this.isDialog) {
      this.subs.add(this.teacherService.updateTeacherAvailability(this.form.value).subscribe(res => {
        if (res) {
          this.toast.setShowToastmessage({
            severity: 'success',
            summary: '',
            detail: 'Your Availability info were updated.'
          });
          this.calendarService.setToggleOverlayListener(true);
          this.calendarService.setUpdateListener(true);
        }
      }));
    } else {

      this.subs.add(this.teacherService.updateAPITeacherApplicationStep5(this.form.value).subscribe(res => {
        if (res) {
          this.toast.setShowToastmessage({
            severity: 'success',
            summary: '',
            detail: 'Your Availability info were updated.'
          });
          this.router.navigateByUrl('/teacher/review', { replaceUrl: true });
        }
        if (!res) {
          this.toast.setShowToastmessage({
            severity: 'warn',
            summary: '',
            detail: 'Previous steps must be completed first.'
          });
        }
      }));
      if (this.user) {
        this.user!.availability = availability;
        this.user!.hours_per_week = this.selectedHours
        console.log(this.user)
        this.userService.updateUserListener();
        this.subs.add(this.teacherService.updateAvailability(this.user.availability).subscribe(res => {
          localStorage.setItem("user", JSON.stringify(this.user));
          this.teacherService.setUpdateAvailabilityForSchedulerClickListener(true)
        }));
        this.toast.setShowToastmessage({
          severity: 'success',
          summary: '',
          detail: 'Availability has been updated.'
        });
      }
      else {
        this.teacher!.availability = availability;
        this.teacher!.hours_per_week = this.selectedHours
        // this.router.navigateByUrl('/teacher/(teacher:review)', { replaceUrl: true });
      }
    }
  }

  checkFormat(from: string, to: string) {
    const isFromValid = from.length === 5 && from.indexOf(":") === 2;
    const isToValid = to.length === 5 && to.indexOf(":") === 2;

    return isFromValid && isToValid;
  }

  async reload(url: string): Promise<boolean> {
    await this.router.navigateByUrl('.', { skipLocationChange: true });
    return this.router.navigateByUrl(url);
  }

  onHoursChange(event: any) {
    setTimeout(() => {
      this.selectedHours = event.value
    }, 100);
  }

  checkValidity(time: string) {
    if (time.length === 0) return 1
    let split = time.split(':');
    let hours = parseInt(split[0]);
    let minutes = parseInt(split[1]);
    return (hours && minutes);
  }

  /**
 * Check if the given field is valid or not.
 * @param {string} field - The field to check its validity.
 * @returns {boolean} - Returns `true` if the field is invalid, and `false` otherwise.
 */
  ifFieldValid(field: string): boolean {
    let control = this.form.get(field);
    let isNotValid = false;

    if ((control as UntypedFormArray).controls) {
      for (let c of (control as UntypedFormArray).controls) {
        let from = (c as UntypedFormGroup).controls.from.value.toString();
        let to = (c as UntypedFormGroup).controls.to.value.toString();
        isNotValid = isNotValid || (this.tryToSave && control!.touched && (isNaN(this.checkValidity(from)) || isNaN(this.checkValidity(to))));
      }
    } else {
      isNotValid = this.form.get(field)!.invalid && (this.tryToSave || this.form.get(field)!.touched);
    }

    if ((control as UntypedFormArray).controls?.length === 0) {
      isNotValid = false;
    }

    // if (this.router.url.includes('teacher')) {
    //   this.teacherService.setStepValidWithBoolean(4, isNotValid, 'teacher-availability-route');
    // }

    return isNotValid;
  }

  goBack() {
    this.router.navigateByUrl('/teacher/resume', { replaceUrl: true });
  }

  private hasEmptyFromOrTo(data: { from: string, to: string }[]): boolean {
    if (this.generalService.isNullishObject(data)) {
      return true;
    }
    if (data.length === 0) {
      return true; // Empty array
    }
    for (const item of data) {
      if (item.from.trim() === '' || item.to.trim() === '') {
        return true; // Empty from or to
      }
    }
    return false; // No empty from or to
  }
}
