export interface LanguageToLearn {
    title: string;
    link: string;
}

export interface Language {
    code: string;
    name: string;
    nativeName?: string;
}

export interface SocialMedia {
    icon: string;
    link: string;
}

export interface Country {
    name: string, code: string, emoji: string, unicode: string, image: string
}

export interface Timezone {
    value: string,
    abbr: string,
    offset: number,
    isdst: boolean,
    text: string,
    utc: string[],
    countries?: string[]
}

export interface DeviceKind {
    w576down: boolean,
    is576: boolean,
    is768: boolean,
    is992: boolean,
    is1024: boolean,
    is1366: boolean,
    isBig: boolean,
    w576up?: boolean,
    w768up?: boolean,
    w992up?: boolean,
    w1024up?: boolean,
    w1366up?: boolean,
    w1500up?: boolean,
}

export enum Dimentions {
    w576 = 576,
    w768 = 768,
    w992 = 992,
    w1024 = 1024,
    w1366 = 1366,
    w1500 = 1500,
    h850 = 850
}

export interface ToastMessage {
    severity: string,
    summary: string,
    detail: string,
    key?: string,
    life?: number,
}


export enum AppLayoutType {
    Default = 'default',
    Sidebar = 'sidebar', // with sidebar
    Center = 'center', // centered content, no frame
    Blank = 'blank', // no frame
    Admin = 'admin', // admin layout
}

export interface LeftMenuItem {
    title: string;
    url: string | (string | { queryParams: { inPage: string; }; })[];
    icon: string;
    lottieIcon?: string;
    disabled: boolean;
    role: string[];
    scale?: number;
    disableOnStudentTrial?: boolean;
    method?: () => void;
  }

  export interface LeadDynoAffiliate {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    affiliate_code: string;
    referring_affiliate_id?: any;
    created_at: string;
    updated_at: string;
    status: string;
    paypal_email: string;
    unsubscribed: boolean;
    archived: boolean;
    pending_approval: boolean;
    affiliate_url: string;
    affiliate_dashboard_url: string;
    compensation_tier_code: string;
    custom_fields: {};
    referring_affiliate?: any;
    total_leads: number;
    total_visitors: number;
    total_purchases: number;
  }