import { HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Classroom, Level, Status } from 'src/app/core/models/classroom.model';
import { Lesson, LessonStatus } from 'src/app/core/models/lesson.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { LessonService } from 'src/app/core/services/lesson.service';
import { SubSink } from 'subsink';

interface Switches {
  [key: string]: boolean;
}
@Component({
  selector: 'app-lessons-history',
  templateUrl: './lessons-history.component.html',
  styleUrls: ['./lessons-history.component.scss']
})
export class LessonsHistoryComponent implements OnInit {
  @Input() classroom?: Classroom;
  @Input() wrapperScrollHeight?: number;
  private subs = new SubSink();
  public filtersOpen: boolean = false;
  public lessons: Lesson[] = [];
  public isListView: boolean = true;
  public listSrc: string = '/assets/icons/list-gray.svg';
  public gridSrc: string = '/assets/icons/grid-main.svg';
  showListView: boolean = true;
  showGridView: boolean = false;
  levelFilter: Level = "All" as Level
  statusFilter: LessonStatus = "All" as LessonStatus
  levelElements?: HTMLCollectionOf<Element>;
  statusElements?: HTMLCollectionOf<Element>;
  public level: string = "All";
  public status: string = "All";
  public filteredLessons: Lesson[] = [];
  switches: Switches = {
    All: true,
    Arranged: false,
    Completed: false,
    Expired: false,
    Freeze: false,
    Active: false,
    Canceled: false,
    NoShow: false,
  };

  public maxSize: number = 10;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public responsive: boolean = false;
  public config: any = {
    id: 'advanced',
    itemsPerPage: 10,
    currentPage: 1
  };
  public config2: any = {
    id: 'advanced2',
    itemsPerPage: 10,
    currentPage: 1
  };
  public labels: any = {
    previousLabel: 'Previous',
    nextLabel: 'Next',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };

  onPageChange(number: number) {
    this.config.currentPage = number;
  }

  onPageChange2(number: number) {
    this.config2.currentPage = number;
  }

  constructor(
    private generalService: GeneralService,
    private lessonService: LessonService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    console.log(this.wrapperScrollHeight);
    this.getLessons();
    // this.subs.sink = this.lessonService.deleteListener.subscribe(res => {
    //   if (res) {
    //     this.getLessons();
    //   }
    // })
    // this.subs.sink = this.lessonService.addListener.subscribe(res => {
    //   if (res) {
    //     this.getLessons();
    //   }
    // })
    // this.subs.sink = this.lessonService.updateListener.subscribe(res => {
    //   if (res) {
    //     this.getLessons();
    //   }
    // })
    this.levelElements = document.getElementsByClassName("level");
    this.statusElements = document.getElementsByClassName("status");
  }
  getLessons() {
    if (this.classroom) {
      this.getClassroomLessons()
    } else {
      this.getAllLessons()
    }
  }

  getAllLessons() {
    const headers = new HttpHeaders({
      'X-Bypass-Error-Interceptor': 'true'
    });
    this.subs.sink = this.lessonService.getLMSAllUserLessons(headers).pipe(take(1)).subscribe(res => {
      this.lessons = res;
      this.filteredLessons = res.sort(this.generalService.sortLessonsByDate);;
      this.lessonService.sessionAllUserLessons = res;
    })
  }

  getClassroomLessons() {
    this.subs.sink = this.lessonService.getUserLessonsHistory(this.classroom?.id!).subscribe(res => {
      this.lessons = res;
      this.filteredLessons = res.sort(this.generalService.sortLessonsByDate);
    })
  }

  toggleFilters() {
    if (this.filtersOpen) {
      this.generalService.slideOutElement('blur_bg');
      this.generalService.slideOutElement('calendar-lessons-filters');
    } else {
      this.generalService.slideInElement('blur_bg');
      this.generalService.slideInElement('calendar-lessons-filters');
    }
    this.filtersOpen = !this.filtersOpen
  }

  listView() {
    this.showListView = true;
    this.showGridView = false;
    this.listSrc = '/assets/icons/list.svg';
    this.gridSrc = '/assets/icons/grid.svg';
  }

  gridView() {
    this.showListView = false;
    this.showGridView = true;
    this.listSrc = '/assets/icons/list-gray.svg';
    this.gridSrc = '/assets/icons/grid-main.svg';
  }

  openCalendarLessonBurger(el: any) {
    [].forEach.call(document.getElementsByClassName('lesson-burger'), (el: any) => {
      el.style.display = 'none';
    })
    el.style.display = 'flex';
  }
  closeCalendarLessonBurger(el: any) {
    el.style.display = 'none';
  }

  goToClassroom(id: string) {

  }

  changeLessonStatus(status: string) {

  }
  
  private resetSwitches() {
    for (const key in this.switches) {
      this.switches[key] = false;
    }
  }

  toggleSwitch(key: string) {
    this.resetSwitches();
    console.log(this.switches);
    switch (key) {
      case 'All':
      case 'Arranged':
      case 'Active':
      case 'Completed':
      case 'Expired':
      case 'Canceled':
      case 'NoShow':
        this.switches[key] = !this.switches[key];
        break;
    }
    this.status = key;
    this.filterLessons();
  }

  onLevelChanged(event: any) {
    this.level = event; 
    console.log(event);
    this.filterLessons();
  }

  filterLessons() {
    let levelFilter: any;
    if (this.level !== 'All') {
      levelFilter = (lesson: any) => lesson.classroom.activeLevel == this.level;
    }
    console.log(this.filteredLessons);
    console.log(this.switches);
    this.filteredLessons = this.lessons.filter(lesson => {
      if (this.switches.Arranged) {
        return lesson.status.toLowerCase().includes(LessonStatus.ARRANGED.toLowerCase()) && (!levelFilter || levelFilter(lesson));
      }
      if (this.switches.Completed) {
        return lesson.status.toLowerCase().includes(LessonStatus.COMPLETED.toLowerCase()) && (!levelFilter || levelFilter(lesson));
      }
      if (this.switches.Canceled) {
        return lesson.status.toLowerCase().includes(LessonStatus.CANCELED.toLowerCase()) && (!levelFilter || levelFilter(lesson));
      }
      if (this.switches.NoShow) {
        return lesson.status.toLowerCase().includes(LessonStatus.NO_SHOW.toLowerCase()) && (!levelFilter || levelFilter(lesson))
      }
      if (this.switches.Active) {
        return lesson.status.toLowerCase().toUpperCase().includes(LessonStatus.ARRANGED.toLowerCase()) && (!levelFilter || levelFilter(lesson));
        // return lesson.lessons!.filter((lesson) => lesson.status.toLowerCase().includes(LessonStatus.ARRANGED)) && (!levelFilter || levelFilter(lesson));
      }
      if (this.switches.Dismissed) {
        return lesson.status.toLowerCase().includes(Status.DISMISSED) && (!levelFilter || levelFilter(lesson));
      }
      if (this.switches.All) {
        return true && (!levelFilter || levelFilter(lesson));
      }
      return false;
    });
  }

  changeFilterDomElementClass(id: string, targetId: string, className: string) {
    if (id === targetId) {
      document.getElementById(id)?.classList.add(className);
    } else {
      document.getElementById(id)?.classList.remove(className);
    }
  }

  lessonDeleted(event: any) {
    this.lessons = this.lessons.filter(el => el.id != event.lesson.id)
    this.subs.sink = this.lessonService.delete(event.lesson.id).pipe(take(1)).subscribe(res => {
      this.lessonService.setDeleteListener(true);
    })
  }
}
