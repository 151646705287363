import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category, Homework } from 'src/app/core/models/homework.model';
import { Library, LibraryFile } from 'src/app/core/models/library.model';
import { HomeworkService } from 'src/app/core/services/homework.service';

@Component({
  selector: 'app-choose-tags',
  templateUrl: './choose-tags.component.html',
  styleUrls: ['./choose-tags.component.scss']
})
export class ChooseTagsComponent implements OnInit {
  @Input() applyToAll: boolean = false;
  @Input() file: File = {} as File;
  @Input() libraryFile: LibraryFile = {} as LibraryFile;
  @Output() chooseTag = new EventEmitter();
  @Output() tagsSubmitted = new EventEmitter();
  tagsToAll: string[] = []


  constructor(
    private homeworkService: HomeworkService
  ) { }

  ngOnInit(): void {
    console.log(this.libraryFile);
    this.homeworkService.tagToAll.subscribe(res => {
      if (res.tag !== "") {
        if (res.add) {
          this.tagsToAll.push(res.tag)
        } else {
          let taskIndex = this.tagsToAll.findIndex(((el: any) => {
            return el == res.tag
          }));
          this.tagsToAll.splice(taskIndex, 1);
        }
      }
    })
  }

  ngOnDestroy(){
    this.homeworkService.setTagToAll({ tag: "", add: false })
  }

  onChooseTag(event: any, tag: string, isLevel: boolean, isCategory: boolean) {
    this.chooseTag.emit({
      checked: event.checked,
      tag: tag,
      isLevel: isLevel,
      isCategory: isCategory,
      applyToAll: this.applyToAll,
      file: this.file
    })
    if (this.applyToAll) {
      this.homeworkService.setTagToAll({ tag: tag, add: event.checked })
    }
  }

  getIsChecked(el: string) {
    return this.tagsToAll.includes(el);
  }

  getIsCheckedCategory(el: any) {
    return this.libraryFile && this.libraryFile.categories ? this.libraryFile.categories.includes(el) : this.tagsToAll.includes(el);
  }

  getIsCheckedLevel(el: any) {
    return this.libraryFile && this.libraryFile.levels ? this.libraryFile.levels.includes(el) : this.tagsToAll.includes(el);
  }

  onTagsSubmitted() {
    this.tagsSubmitted.emit();
  }

}
