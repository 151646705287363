<div class="goals" id="goals">
    <div class="left-title-goals">
        <div *ngFor="let goal of studentGoals" class="goal">
            <div class="goal-title">
                <div>
                    <strong>Desired Level</strong>
                </div>
                <div>
                    <strong>Until: &nbsp;</strong>{{goal.desiredAchieveDate | date: 'MMMM, y'}} ({{goal.hoursWeekly}}
                    hours/week)
                </div>
            </div>
            <div>
                <app-circle-line [circles]="circleLines.get(goal.id)"></app-circle-line>
            </div>
            <div *ngIf="showDetails" class="goal-details">
                <div class="goal-areas">
                    <div class="goal-details-title">
                        What
                    </div>
                    <div *ngFor="let area of goal.areas" class="goal-detail">
                        {{area}}
                    </div>
                </div>
                <div class="goal-why">
                    <div class="goal-details-title">
                        Why
                    </div>
                    <div *ngFor="let reason of goal.reasons" class="goal-detail">
                        {{reason}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>