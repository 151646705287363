import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorComponent } from './error/error.component';
import { TestingComponent } from './testing/testing.component';
import { DropdownModule } from 'primeng/dropdown';
import { ClickOutsideDirective } from '../core/directives/click-outside.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LessonDetailsComponent } from './lesson/lesson-details/lesson-details.component';
import { LessonPopupComponent } from './lesson/lesson-popup/lesson-popup.component';
import { LessonRatingComponent } from './lesson/lesson-rating/lesson-rating.component';
import { LessonsHistoryComponent } from './lesson/lessons-history/lessons-history.component';
import { ClassroomsCheckListComponent } from './classrooms-check-list/classrooms-check-list.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { AvailabilityComponent } from './availability/availability.component';
import { DndDirective } from '../core/directives/dnd.directive';
import { ImageCropperComponent, ImageCropperModule } from 'ngx-image-cropper';
import { ChooseTagsComponent } from './upload-files/choose-tags/choose-tags.component';
import { CircleLineComponent } from './circle-line/circle-line.component';
import { GoalComponent } from './goal/goal.component';
import { CreateGoalComponent } from './goal/create-goal/create-goal.component';
import { ViewGoalsComponent } from './goal/view-goals/view-goals.component';
import { RescheduleComponent } from './calendar/reschedule/reschedule.component';
import { WindowService } from '../core/services/window.service';
import { CalendarModule } from 'primeng/calendar';
import {TooltipModule} from 'primeng/tooltip';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { ToastComponent } from './prime/toast/toast.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { ConfirmDialogComponent } from './prime/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {MenuModule} from 'primeng/menu';
import { BlockViewerComponent } from './block-viewer/block-viewer.component';
import { LessonRowItemComponent } from './lesson/lesson-row-item/lesson-row-item.component';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { PanelMenuModule } from 'primeng/panelmenu';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { BadgeModule } from 'primeng/badge';
import { TopMenuContainerLayoutComponent } from './layout/top-menu-container-layout/top-menu-container-layout.component';
import { LoginContainerLayoutComponent } from './layout/login-container-layout/login-container-layout.component';
import { SideMenuContainerLayoutComponent } from './layout/side-menu-container-layout/side-menu-container-layout.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { MobileLeftMenuComponent } from './layout/mobile-left-menu/mobile-left-menu.component';
import { DeferModule } from 'primeng/defer';
import { PrimeInputDropdownComponent } from './prime/input/prime-input-dropdown/prime-input-dropdown.component';
import { CalendarAgendaComponent } from './prime/prime/calendar-agenda/calendar-agenda.component';
import { LessonScheduleBoxComponent } from './lesson/lesson-schedule-box/lesson-schedule-box.component';
import { GoalStepsBoxComponent } from './goal/goal-steps-box/goal-steps-box.component';
import { TextCallToActionButtonComponent } from './prime/text-call-to-action-button/text-call-to-action-button.component';
import { LoaderComponent } from './loader/loader.component';
import { ClassroomCardComponent } from './classroom/classroom-card/classroom-card.component';
import { ChipModule } from 'primeng/chip';
import { MiniLessonInfoCardComponent } from './lesson/mini-lesson-info-card/mini-lesson-info-card.component';
import {CarouselModule} from 'primeng/carousel';
import {InputSwitchModule} from 'primeng/inputswitch';
import { AccordionModule } from 'primeng/accordion';
import { GButtonComponent } from './layout/g-button/g-button.component';
import { RateQuestionItemComponent } from './classroom/rate-question-item/rate-question-item.component';
import { ButtonModule } from 'primeng/button';
import { GAccordionComponent } from './layout/g-accordion/g-accordion.component';
import {ProgressBarModule} from 'primeng/progressbar';
import {TabViewModule} from 'primeng/tabview';
import { GLevelCircleComponent } from './layout/g-level-circle/g-level-circle.component';
import { TeacherClassroomMiniCardComponent } from './classroom/teacher-classroom-mini-card/teacher-classroom-mini-card.component';
import { ClassroomLevelFiltersComponent } from './classroom/classroom-level-filters/classroom-level-filters.component';
import { TagModule } from 'primeng/tag';
import { CheckboxModule } from 'primeng/checkbox';
import { StepsComponent } from '../modules/dashboard/dashboard-goals/steps/steps.component';
import { StepsModule } from 'primeng/steps';
import { DialogModule } from 'primeng/dialog';
import { SliderModule } from 'primeng/slider';
import { RadioButtonModule as PrimeNgRadioButtonModule } from 'primeng/radiobutton';
import { ClassroomStatusFiltersComponent } from './classroom/classroom-status-filters/classroom-status-filters.component';
import { ProgressStatisticsComponent } from './progress-statistics/progress-statistics.component';
// import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ShareButtonComponent } from './share-button/share-button.component';
import { GHeaderBackButtonComponent } from './layout/g-header-back-button/g-header-back-button.component';
import { GAccordionItemComponent } from './layout/g-accordion-item/g-accordion-item.component';
import { LessonRatingRangeSliderComponent } from './lesson/lesson-rating/lesson-rating-range-slider/lesson-rating-range-slider.component';
import { NotificationRowItemComponent } from './notification/notification-row-item/notification-row-item.component'
import { DisableOnClickDirective } from '../core/directives/button-disable.directive';
import { MultiHandleSliderComponent } from './multi-handle-slider/multi-handle-slider.component';
import { BuyPackageSuggestionBoxComponent } from '../modules/classroom/packages/buy-package/buy-package-suggestion-box/buy-package-suggestion-box.component';
@NgModule({
  declarations: [
    ErrorComponent,
    TestingComponent,
    ClickOutsideDirective,
    LessonPopupComponent,
    LessonRatingComponent,
    LessonDetailsComponent,
    LessonsHistoryComponent,
    ClassroomsCheckListComponent,
    UploadFilesComponent,
    AvailabilityComponent,
    DndDirective,
    ChooseTagsComponent,
    CircleLineComponent,
    GoalComponent,
    CreateGoalComponent,
    ViewGoalsComponent,
    RescheduleComponent,
    ToastComponent,
    ConfirmDialogComponent,
    BlockViewerComponent,
    LessonRowItemComponent,
    HeaderComponent,
    MenuComponent,
    TopMenuContainerLayoutComponent,
    LoginContainerLayoutComponent,
    SideMenuContainerLayoutComponent,
    MobileMenuComponent,
    MobileLeftMenuComponent,
    PrimeInputDropdownComponent,
    CalendarAgendaComponent,
    LessonScheduleBoxComponent,
    GoalStepsBoxComponent,
    TextCallToActionButtonComponent,
    ClassroomCardComponent,
    MiniLessonInfoCardComponent,
    GButtonComponent,
    RateQuestionItemComponent,
    GAccordionComponent,
    GLevelCircleComponent,
    TeacherClassroomMiniCardComponent,
    ClassroomLevelFiltersComponent,
    StepsComponent,
    ClassroomStatusFiltersComponent,
    ProgressStatisticsComponent,
    // ProgressBarComponent,
    ShareButtonComponent,
    GHeaderBackButtonComponent,
    GAccordionItemComponent,
    LessonRatingRangeSliderComponent,
    NotificationRowItemComponent,
    DisableOnClickDirective,
    MultiHandleSliderComponent,
    BuyPackageSuggestionBoxComponent,
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    CalendarModule,
    TooltipModule,
    MessageModule,
    MessagesModule,
    ToastModule,
    InputTextModule,
    RippleModule,
    ConfirmDialogModule,
    MenuModule,
    ScrollPanelModule,
    PanelMenuModule,
    OverlayPanelModule,
    BadgeModule,
    DeferModule,
    ChipModule,
    CarouselModule,
    InputSwitchModule,
    AccordionModule,
    ProgressBarModule,
    TabViewModule,
    TagModule,
    CheckboxModule,
    StepsModule,
    DialogModule,
    SliderModule,
    PrimeNgRadioButtonModule,
    InputSwitchModule,
    LoaderComponent,
  ],
  exports: [
    LessonPopupComponent,
    LessonRatingComponent,
    LessonDetailsComponent,
    LessonsHistoryComponent,
    ClassroomsCheckListComponent,
    UploadFilesComponent,
    AvailabilityComponent,
    CircleLineComponent,
    GoalComponent,
    CreateGoalComponent,
    ViewGoalsComponent,
    ToastComponent,
    ConfirmDialogComponent,
    BlockViewerComponent,
    LessonRowItemComponent,
    HeaderComponent,
    MenuComponent,
    TopMenuContainerLayoutComponent,
    LoginContainerLayoutComponent,
    SideMenuContainerLayoutComponent,
    MobileMenuComponent,
    PrimeInputDropdownComponent,
    CalendarAgendaComponent,
    LessonScheduleBoxComponent,
    GoalStepsBoxComponent,
    TextCallToActionButtonComponent,
    LoaderComponent,
    ClassroomCardComponent,
    MiniLessonInfoCardComponent,
    TestingComponent,
    GButtonComponent,
    RateQuestionItemComponent,
    ButtonModule,
    ChipModule,
    AccordionModule,
    GAccordionComponent,
    ProgressBarModule,
    TabViewModule,
    GLevelCircleComponent,
    TeacherClassroomMiniCardComponent,
    ClassroomLevelFiltersComponent,
    StepsComponent,
    DialogModule,
    CheckboxModule,
    InputSwitchModule,
    SliderModule,
    PrimeNgRadioButtonModule,
    ProgressStatisticsComponent,
    ShareButtonComponent,
    MenuModule,
    InputSwitchModule,
    ClassroomStatusFiltersComponent,
    ScrollPanelModule,
    OverlayPanelModule,
    GHeaderBackButtonComponent,
    GAccordionItemComponent,
    TooltipModule,
    ChooseTagsComponent,
    NotificationRowItemComponent,
    DisableOnClickDirective,
    MultiHandleSliderComponent,
    ImageCropperComponent,
    BuyPackageSuggestionBoxComponent,
  ],
  providers: [
    WindowService,
    MessageService,
    ConfirmationService,
  ]
})
export class SharedModule { }
