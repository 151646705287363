<div class="status" *ngIf="!isEmpty(currentStatus)">
  <div class="status-item">
    <div class="grid">
      <div class="col-12 md:col-2 lg:col-3">
        <div class="left line-height-1">
          Application
          Progress
        </div>
      </div>
      <div class="col-12 md:col-10 lg:col-9">
        <div class="right">
          <div class="section section-bg-gradient">
            <div class="user-info">
              <div class="user-info-row flex-column md:flex-row">
                <div class="user-info-row-item  font-base">
                  <div>Username</div>
                  <div class="user-info-row-item-info">
                    {{loggenInUser.email}}
                  </div>
                </div>
                <div *ngIf="statusDateText" class="user-info-row-item  font-base">
                  <div>{{statusDateText}}</div>
                  <div class="user-info-row-item-info">{{formattedSubmittedDate}}
                  </div>
                </div>
                <div class="user-info-row-item font-base">
                  <div>Application status</div>
                  <div class="user-info-row-item-info mb-3">
                    <img [ngClass]="statusCssClass" [src]="'/assets/icons/'+statusIcon" />
                    <div class="max-w-10rem capitalize" [ngClass]="statusCssClass + ' text'">
                      {{formatStatusNameText()}}
                    </div>
                  </div>
                  <div class="pt-2 flex flex-column gap-2">
                    <a *ngIf="isWithdrawn(currentStatus?.status)" (click)="restartApplicationAndgoToStatusInfoPage()"
                      class=" flex align-items-center">
                      <span class="status-link">Restart your Application</span>
                    </a>
                    <a *ngIf="isIncomplete(currentStatus?.status)" (click)="goToStatusInfoPage()"
                      class=" flex align-items-center">
                      <span class="status-link">Continue with your Application</span>
                       <i class="pl-3 pi pi-chevron-circle-right no-underline status-color cursor-pointer text-3xl"></i>
                    </a>

                    <a *ngIf="showReviewLink" (click)="goToReviewPage()"
                      class="status-link">Review your Application</a>
                    <a *ngIf="showWithdrawLink" (click)="handleWithdrawal()"
                      class="status-link">Withdraw your Application</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="status-item m-t-60">
    <div class="grid">
      <div class="col-12 md:col-2 lg:col-3">
        <div class="left" *ngIf="isConsidered(currentStatus?.status)">
          What now?</div>
      </div>
      <div class="col-12 md:col-10 lg:col-9">
        <div class="right" *ngIf="isConsidered(currentStatus?.status) ||isApproved(currentStatus?.status) || isNoLongerConsidered(currentStatus?.status) || isReceived(currentStatus?.status)">
          <div class="section section-bg-gradient">
            <div class="title-two">
              The change of your status will determine the following course of
              action.
            </div>
            <div class="section-two flex-column sm:flex-row">
              <div class="section-two-left md:col-4">
                <img class="" src="/assets/icons/status-received-blue.svg" />
                <div class="received-bottom text">Application Received</div>
              </div>
              <div class="section-two-right md:col-8">
                Your application has been successfully submitted and is currently under review by our team.
              </div>
            </div>
            <div class="section-two flex-column sm:flex-row">
              <div class="section-two-left md:col-4">
                <img class="consideration" src="/assets/icons/accept.svg" />
                <div class="consideration text">Under Consideration</div>
              </div>
              <div class="section-two-right md:col-8">
                We have completed the initial review of your application, and we will be in touch to schedule a Skype interview once suitable tutor positions become available.
              </div>
            </div>
            <div class="section-two flex-column sm:flex-row">
              <div class="section-two-left md:col-4">
                <img class="rejected" src="/assets/icons/reject.svg" />
                <div class="rejected text">No Longer Under Consideration</div>
              </div>
              <div class="section-two-right md:col-8">
                Your application is no longer under consideration. Thank you for your interest. We genuinely appreciate the time and effort you invested in the application process.
              </div>
            </div>
          </div>
        </div>
        

        <div class="px-4 py-8 md:px-6 lg:px-8 text-center"
        *ngIf="isIncomplete(currentStatus?.status) || isWithdrawn(currentStatus?.status) || isRejected(currentStatus?.status) || isNoLongerConsidered(currentStatus?.status) || isReceived(currentStatus?.status)">
          <div class="mb-4 font-bold text-xl"><span class="text-900">For any inquiries please contact us</span></div>
         <div class="text-700 mb-6">
            <button (click)="generalService.openExternalLink('https://www.mylingotrip.com/contact')" pRipple pbutton="" pripple="" label="Join Now" icon="pi pi-discord"
              class="p-element p-ripple font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap p-button p-component">
              <span
                class="p-button-icon p-button-icon-right" aria-hidden="true"></span>
                <span class="p-button-label">Get in touch</span>
              <img class="contact-icon-img" src="/assets/icons/contact-us-face-icon.svg" />
              </button>

          </div> 
        </div>

      </div>
    </div>
    <!-- ends grid -->
  </div>
</div>