import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
@Injectable()
export class UserInfoGuard  {
    constructor(
        private authService: AuthService, 
        private router: Router
        ) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        const isUserInfoFullefilled = this.authService.isUserInfoFullfileld();
        if (!isUserInfoFullefilled) {
            this.router.navigateByUrl('/profile/(profile:info)', { replaceUrl: true });
        }
        return isUserInfoFullefilled//allow to go to router if true

    }

}