import { Country, Language, Timezone } from "./general.model";
import { UserAvailability, UserAvailabilityHours } from "./user.model";

export interface Teacher {
    id: string,
    preffered_name: string,
    first_name: string,
    last_name: string,
    city?: string,
    country?: { name: string, flagUrl: string },
    country_origin: { name: string, flagUrl: string },
    country_residency: { name: string, flagUrl: string },
    native_language?: string,
    native_languages: TeacherApplicationStep1NativeLanguage[],
    birth: string,
    timezone: string,
    email: string,
    phone_code?: string,
    phone: string,
    skype: string,
    education: TeacherEducation[],
    language_speak: TeacherApplicationStep3LanguageSpeakOrTeach[],
    language_teach: TeacherApplicationStep3LanguageSpeakOrTeach[],
    teaching_methods: string,
    years_experience: number,
    cv: string,
    photo: string,
    availability?: UserAvailability,
    hours_per_week: string,
    status?: string,
    date_applied: Date
}

export interface LanguageLevel {
    language: string,
    level: any,
    hasBusinessLevel?: boolean
}

export interface TeacherEducation {
    name: string,
    institute: string,
    fileUploads: [{ name: string; }]
}

export interface TeacherStatusResponse {
    dateSubmited: string,
    status: string,
    userName: string
}

export enum TeacherStatus {
    APPROVED = "Approved",
    INCOMPLETE = "Incomplete",
    CONSIDERATION = "Under Consideration",
    NO_LONGER_CONSIDERED = "No Longer Under Consideration",
    REJECTED = "Rejected",
    NEW = "New",
    RECEIVED = "Received",
    WITHDRAWN = "Withdrawn"
}

export interface TeacherApplicationStep1 {
    firstName: string;
    lastName: string;
    prefferedName: string;
    countryOrigin: TeacherApplicationStep1Country;
    countryResidency: TeacherApplicationStep1Country;
    nativeLanguages: TeacherApplicationStep1NativeLanguage[];
    timeZone: string;
    birth: string;
    email: string;
    phone: string;
    phoneCode: string;
    skype: string;
}
export interface TeacherApplicationStep2 {
    education: TeacherApplicationEducation[];
}

export interface TeacherApplicationEducation {
    name: string;
    institute: string;
    fileUploads: TeacherApplicationFileUpload[];
}

interface TeacherApplicationFileUpload {
    filePath: string;
}

export interface TeacherApplicationStep1NativeLanguage {
    native: TeacherApplicationStep1Native;
}

export interface TeacherApplicationStep1Native {
    code: string;
    name: string;
    nativeName: string;
}

export interface TeacherApplicationStep1Country {
    name: string;
    flagUrl: string;
}

export interface TeacherApplicationStep3 {
    languageSpeak: TeacherApplicationStep3LanguageSpeakOrTeach[];
    languageTeach: TeacherApplicationStep3LanguageSpeakOrTeach[];
    yearsExperience: number;
    teachingMethods: string;
}

export interface TeacherApplicationStep3LanguageSpeakOrTeach {
    language: TeacherApplicationStep3Language;
    level: any;
    levelName?: any,
    hasBusinessLevel?: boolean;
}

export interface TeacherApplicationStep3LanguageTeach {
    levelName: string;
}

export interface TeacherApplicationStep3Level {
    levelName: string;
    visible: boolean;
    showOnlyOnCode: string[];
}

export interface TeacherApplicationStep3Language {
    code: string;
    name: string;
    nativeName: string;
}

export interface TeacherApplicationStepsStatus {
    step1: boolean;
    step2: boolean;
    step3: boolean;
    step4: boolean;
    step5: boolean;
    step6: boolean;
}

export interface AvailabilityTime {
    AvailabilityDayTime: AvailabilityDayTime[];
    mon: AvailabilityDayTime[];
    tue: AvailabilityDayTime[];
    wed: AvailabilityDayTime[];
    thu: AvailabilityDayTime[];
    fri: AvailabilityDayTime[];
    sat: AvailabilityDayTime[];
    sun: AvailabilityDayTime[];
    AvailabilityDayTimedayActive: boolean;
    tuesdayActive: boolean;
    wednesdayActive: boolean;
    thursdayActive: boolean;
    fridayActive: boolean;
    saturdayActive: boolean;
    sundayActive: boolean;
    hours: string;
}


export interface AvailabilityDayTime {
    from: string;
    to: string;
}


interface Step1 {
    firstName: string;
    lastName: string;
    prefferedName: string;
    countryOrigin: {
        name: string;
        flagUrl: string;
    };
    countryResidency: {
        name: string;
        flagUrl: string;
    };
    nativeLanguages: Array<{
        native: {
            code: string;
            name: string;
            nativeName: string;
        }
    }>;
    timeZone: string;
    birth: string;
    email: string;
    phone: string;
    phoneCode: string;
    skype: string;
}

export interface Step2 {
    education: TeacherEducation[];
}

export interface Step3 {
    languageSpeak: Array<{
        language: {
            code: string;
            name: string;
            nativeName: string;
        };
        level: {
            levelName: string;
        };
        hasBusinessLevel: boolean;
    }>;
    languageTeach: Array<{
        language: {
            code: string;
            name: string;
            nativeName: string;
        };
        level: {
            levelName: string;
        };
        hasBusinessLevel: boolean;
    }>;
    yearsExperience: number;
    teachingMethods: string;
}

export interface TeacherApplicationStep4Response {
    cv: string;
    profilePicture: string;
}

export interface TeacherApplicationReview {
    step1: Step1;
    step2: Step2;
    step3: TeacherApplicationStep3;
    step4: TeacherApplicationStep4Response;
    step5: AvailabilityTime;
}
export interface UserAvailabilityStep5 {
    [key: string]: UserAvailabilityHours[],
    
}
export interface UserAvailabilityHoursStep5 {
    from: string,
    to : string,
    hours_per_week: string
}