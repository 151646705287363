import { Component, ElementRef, HostListener, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Notification } from 'src/app/core/models/notification.model';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  chatService = inject(ChatService);
  dropdownOpen: boolean = false;
  public backend_url = environment.apiUrl
  loggenInUser: User = {} as User;
  userPhoto: string = "";
  notifications: Notification[] = [];
  unreadNotifications: Notification[] = [];
  subs = new SubSink()
  overlayActive = false;
  userOverlayActive = false;
  @ViewChild('overlayPanel', { static: false }) public overlayPanel: any;
  @ViewChild('scaleHeader', { static: true }) public ratingDetails: any;
  constructor(
    public generalService: GeneralService,
    private authService: AuthService,
    private userService: UserService,
    private notificationService: NotificationService,
    private router: Router,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
    this.closeProdileDropdownOnClickOutside();
    // this.closeNotificationsDropdownOnClickOutside();

    this.loggenInUser = this.authService.getLoggedInUser();
    this.userPhoto = this.userService.getUserPhoto(this.authService.getLoggedInUser());

    this.subs.add(this.userService.update.subscribe(res => {
      if (!res) {
        return;
      }
      this.userPhoto = this.authService.getUserPhoto()
      this.loggenInUser = this.authService.getLoggedInUser()
    }));
    this.subs.add(this.notificationService.get().subscribe((res: any) => {
      this.notifications = res.reverse();
      // Filter notifications to get only the unread ones
      this.unreadNotifications = this.notifications.filter(
        (notification) => !notification.isRead
      );
    }));

    this.subs.add(this.generalService.deviceKind.subscribe(res => {
      if (res.w992up && !res.w1366up) {

        // this.generalService.scaleElementDown(this.ratingDetails, 'unset', 1);
      } else {
        // this.generalService.scaleElementReset(this.ratingDetails);
      }
    }));
    
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  handleDropdown() {
    !this.dropdownOpen ? document.getElementById('profile-dropdown')!.style.visibility = "visible" : document.getElementById('profile-dropdown')!.style.visibility = "hidden";
    this.dropdownOpen = !this.dropdownOpen
    document.getElementById('profile-dropdown')!.classList.toggle('active');
  }

  closeDropdown() {
    if (document.getElementById('profile-dropdown')) {
      document.getElementById('profile-dropdown')!.style.visibility = "hidden";
      this.dropdownOpen = false;
      document.getElementById('profile-dropdown')!.classList.remove('active');
    }
  }

  closeProdileDropdownOnClickOutside() {
    let ignoreClickOnMeElement = document.getElementById('header');
    document.onclick = (event: any) => {
      var isClickInsideElement = ignoreClickOnMeElement?.contains(event.target!);
      if (!isClickInsideElement) {
        this.closeDropdown()
      }
    };
  }

  logout() {
    this.chatService.setUserStatus(this.loggenInUser!.aspUserId!, 'offline');
    this.authService.logout();
    
  }

  navigateToTeacherSection(section: string) {
    if (this.userOverlayActive) {
      this.userOverlayActive = false;
    }
    // this.overlayPanel.hide();
    this.router.navigateByUrl('/user-profile/' + section);
    return false;
  }

  toggleNotificationOverlay(event: any) {
    this.overlayActive = !this.overlayActive;
    const unreadNotificationIds = this.unreadNotifications.map(
      (notification) => notification.id
    );
    this.subs.sink = this.notificationService.markAsReadBulk(unreadNotificationIds).subscribe((res: any) => {
      if (res) {
        this.unreadNotifications = [];
      }
    }, (error) => {});
  }

  toggleUserMenuOverlay(event: any) {
    this.userOverlayActive = !this.userOverlayActive;
    console.log(this.userOverlayActive);
  }
  
  
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any) {
    // console.log('targetElement.classList.contains(notaa)', targetElement.classList.contains('notaa'));
    if (!targetElement.classList.contains('notaa')) {
      this.overlayActive = false;
      // this.userOverlayActive = false;
      // console.log('isClickInsideElement', targetElement);
      const clickedInside = this.elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        // Click occurred outside of the element
        // Do something here, like hide a dropdown menu or close a modal
      }
    }
    if (!targetElement.classList.contains('notaa2')) {
      // this.overlayActive = false;
      this.userOverlayActive = false;
      // console.log('isClickInsideElement', targetElement);
      const clickedInside = this.elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        // Click occurred outside of the element
        // Do something here, like hide a dropdown menu or close a modal
      }
    }

    
  }

  onClicka(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.overlayActive = false;
      this.userOverlayActive = false;
      // The click occurred outside of the element
      // Do something here, like hide a dropdown menu or close a modal
    }
  }
  closeNotificationsDropdownOnClickOutside() {
    let ignoreClickOnMeElement = document.querySelector('.note-badge');
    document.onclick = (event: any) => {
      // console.log(event.target);
      var isClickInsideElement = ignoreClickOnMeElement?.contains(event.target!);
      if (!isClickInsideElement) {
        // this.overlayActive = false;
      }
    };
  }
}
