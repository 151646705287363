import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Teacher } from 'src/app/core/models/teacher.model';
import { GeneralService } from 'src/app/core/services/general.service';
import { TeacherApplicationService } from 'src/app/core/services/teacher-application.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {
  private subs = new SubSink();
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public isTablet: boolean = false;
  public teacher: Teacher = this.teacherService.dummyTeacher;
  tryToSave: boolean = false;
  isLoading: boolean = false;


  constructor(
    private generalService: GeneralService,
    private teacherService: TeacherApplicationService,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.subs.add(this.teacherService.submitOnMenuClickListener.subscribe(res => {
      if (res) {
        this.onSubmit();
      }
    }));
    this.teacherService.setCurrentStepIndex(1);
    this.subs.add(this.generalService.deviceKind.subscribe(res => {
      this.isTablet = res.is1024;
    }));
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(this.teacher.email, {
        validators: [Validators.required]
      }),
      phone: new UntypedFormControl(this.teacher.phone, {
        validators: [Validators.required]
      }),
      skype: new UntypedFormControl(this.teacher.skype, {
        validators: [Validators.required]
      })
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.teacherService.setSubmitOnMenuClickListener(false)
  }

  onSubmit() {
    this.tryToSave = true;
    if (!this.form.valid) {
      return;
    }
    this.teacher!.email = this.form.value.email
    this.teacher!.phone = this.form.value.phone
    this.teacher!.skype = this.form.value.skype
    this.router.navigateByUrl('/teacher/education', { replaceUrl: true });
  }

  goBack() {
    this.router.navigateByUrl('/teacher/info', { replaceUrl: true });
  }

  ifFieldValid(field: string) {
    this.teacherService.setStepValid(1, this.form, 'teacher-contact-route');
    return ((this.form.get(field)?.invalid && this.form.get(field)?.touched) || (this.tryToSave && this.form.get(field)?.invalid))
  }
}
