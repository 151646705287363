import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LibraryRoutingModule } from './library-routing.module';
import { LibraryComponent } from './library/library.component';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LibraryFileRowItemComponent } from './library/components/library-file-row-item/library-file-row-item.component';
import { LibraryLeftSidebarComponent } from './library/components/library-left-sidebar/library-left-sidebar.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { LibraryFileActionsDialogComponent } from './library/components/library-file-actions-dialog/library-file-actions-dialog.component';
import { SingleLibraryComponent } from './library/components/single-library/single-library.component';
import { SingleLibraryFolderItemComponent } from './library/components/single-library-folder-item/single-library-folder-item.component';
import { FilterPipe } from 'src/app/core/pipes/filter.pipe';


@NgModule({
  declarations: [
    LibraryComponent,
    LibraryFileRowItemComponent,
    LibraryLeftSidebarComponent,
    LibraryFileActionsDialogComponent,
    SingleLibraryComponent,
    SingleLibraryFolderItemComponent,
    FilterPipe
  ],
  imports: [
    CommonModule,
    LibraryRoutingModule,
    SharedModule,
    FormsModule,
    ConfirmDialogModule,
  ],
  exports: [
    LibraryComponent,
    LibraryLeftSidebarComponent,
    SingleLibraryComponent,
    LibraryFileActionsDialogComponent,
    FilterPipe
  ],
  providers: [DynamicDialogRef]
})
export class LibraryModule { }
