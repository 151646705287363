export interface RequestTrialRequest {
  firstName: string;
  lastName: string;
  contactNumber: string;
  email: string;
  nativeLanguage: string;
  wantToLearnLanguage: string;
  country: string;
  timeZone: string;
  numberOfStudents: number;
  findUs: string;
  availability: string;
  message: string;
}

export enum StudentTrialStatus {
  NEW = "new",
  FORWARDED = "forwarded",
  ASSIGNED = "assigned",
  LOST = "lost",
  GALAXY = "galaxy",
  IGNORED = "ignored",
  HAS_PAYMENT = "hasPayment",
  NONE = "none"
}

export interface ContactUsRequest {
    userData: {
      firstName: string,
      lastName: string,
      email: string,
      phone: string
    },
    course: string,
    studentType: string,
    message: string
}
