
<div class="">
    <ng-container *ngIf="mode === 'full'">
    <ul class="list-none sm:p-0 m-0 pt-2 sm:pt-0">
        <li *ngIf="lesson" class="flex relative lesson-item-wrapper font-sm surface-border block-gradient flex align-items-center  
                flex-wrap mb-1 text-base text-primary  justify-content-between mb-2 pr-3 sm:pr-0">
            <div class="flex justify-content-start align-items-center col-3 p-0">
            <div class="text-500 font-medium">
                <div class="lesson-color-icon flex align-items-center justify-content-center"
                [ngClass]="getLessonStatusColor(lesson.status) + '-gradient-lesson-bg'">
                <img [src]="getIcon(lesson.status)" width="16" />
                    <!-- <i class="pi text-white text-lg" [ngClass]="getIcon(lesson.status)"></i> -->
                </div>
            </div>
            <div class="flex flex-column pointer" (click)="lessonCardClicked()"
            tooltipPosition="top" [pTooltip]="getLessonRowTooltipName(lesson.classroom)">
            <div class="mr-auto text-xs sm:text-base">{{ getLessonRowName(lesson.classroom) }}</div>
            </div>
            </div>
            
            <div class="absolute sm:relative sm:col-1 level-circle-wrapper">
                <g-level-circle [name]="!activeLevel ? lesson.classroom?.activeLevel : activeLevel" [size]="'sm'" ></g-level-circle>
            </div>
            <div class="col-3 text-xs sm:text-sm text-center">{{lesson.startingDate | date: 'dd/MM/yyyy'}}

                
            <span class=" text-xs sm:text-sm sm:ml-6">{{ generalService.getLessonTime(lesson.startingDate) }}</span>

            </div>
            <div class="col-1 text-xs sm:text-sm sm:ml-4">{{generalService.convertHoursToMinutesWithSuffix(lesson.duration)}}</div>
            <div class="col-2 capitalize text-xs sm:text-sm sm:ml-5">{{lesson.status}}</div>
            <div class="col-2 sm:col-1 h-full flex justify-content-start relative">
      
                <i class="ml-auto font-bold pi pi-ellipsis-h absolute lession-more-icon center pointer hvr-grow"  (click)="lessonCardClicked()"></i>
                <!-- <p-menu #menu3 popup="popup" [model]="items" appendTo="body"></p-menu> -->
            </div>
        </li>
        </ul>
    </ng-container>
    
</div>
<div class="">
    <ng-container *ngIf="mode === 'compact'">

    <ul class="list-none m-0 px-2">
        <li *ngIf="lesson" class="lesson-item-wrapper relative font-sm block-gradient surface-border flex align-items-center  
             mb-2 text-base text-primary  justify-content-start">

            <div class="rate-mask absolute border-round-xl" *ngIf="showRatingDiv">

                <div class="flex align-items-center h-full ml-6">
                <span class="font-base">Rate your Lesson</span>
                <div class="rating flex align-items-center justify-content-center w-8rem" [class.show]="showRatingDiv">
                    <span class="star" 
                          [class.filled]="rating >= 1 || hoverIndex >= 1" 
                          (mouseenter)="onStarHover(1)" 
                          (mouseleave)="onStarLeave()"
                          (click)="rate(1)">
                      <i class="pi" [ngClass]="{'pi-star': rating < 1 && hoverIndex < 1, 'pi-star-fill': rating >= 1 || hoverIndex >= 1}"></i>
                    </span>
                    <span class="star" 
                          [class.filled]="rating >= 2 || hoverIndex >= 2" 
                          (mouseenter)="onStarHover(2)" 
                          (mouseleave)="onStarLeave()"
                          (click)="rate(2)">
                      <i class="pi" [ngClass]="{'pi-star': rating < 2 && hoverIndex < 2, 'pi-star-fill': rating >= 2 || hoverIndex >= 2}"></i>
                    </span>
                    <span class="star" 
                          [class.filled]="rating >= 3 || hoverIndex >= 3" 
                          (mouseenter)="onStarHover(3)" 
                          (mouseleave)="onStarLeave()"
                          (click)="rate(3)">
                      <i class="pi" [ngClass]="{'pi-star': rating < 3 && hoverIndex < 3, 'pi-star-fill': rating >= 3 || hoverIndex >= 3}"></i>
                    </span>
                    <span class="star" 
                          [class.filled]="rating >= 4 || hoverIndex >= 4" 
                          (mouseenter)="onStarHover(4)" 
                          (mouseleave)="onStarLeave()"
                          (click)="rate(4)">
                      <i class="pi" [ngClass]="{'pi-star': rating < 4 && hoverIndex < 4, 'pi-star-fill': rating >= 4 || hoverIndex >= 4}"></i>
                    </span>
                    <span class="star" 
                          [class.filled]="rating >= 5 || hoverIndex >= 5" 
                          (mouseenter)="onStarHover(5)" 
                          (mouseleave)="onStarLeave()"
                          (click)="rate(5)">
                      <i class="pi" [ngClass]="{'pi-star': rating < 5 && hoverIndex < 5, 'pi-star-fill': rating >= 5 || hoverIndex >= 5}"></i>
                    </span>
                  </div>
                  
                  <i class="ml-auto pi pi-times-circle close" (click)="hideRatingDiv()"></i>
                </div>
            </div>

            <div class="lesson-color-icon flex align-items-center justify-content-center"
                [ngClass]="getLessonStatusColor(lesson.status) + '-gradient-lesson-bg'">
                
                <img [src]="getIcon(lesson.status)" class="pi text-white font-sm" width="16" />
                <!-- <i class="pi text-white font-sm" [ngClass]="getIcon(lesson.status)"></i> -->
            </div>
            <div class=" w-11  font-medium main-text flex flex-column align-items-start gap-1 sm:pr-1">
                <span class="lesson-heading flex w-100">
                    <div class="flex w-full font-sm align-items-center justify-content-start">
                       <span class=" file-path-text max-w-16rem pointer hover:text-gray-700" 
                       tooltipPosition="top" [pTooltip]="getLessonRowTooltipName(classroom)"
                       (click)="lessonCardClicked()"> {{ getLessonRowName(classroom) }}
                        </span>
                        </div>

                    <div class="flex flex-row justify-content-end gap-1 sm:pr-2">
                  <!-- TODO rate on the spot functionality -->
                   <!-- <img *ngIf="lesson.status === LessonStatus.COMPLETED" class="lesson-more-icon pointer hvr-grow mr-5" 
                    src="/assets/icons/lessons/rating-stars.svg" height="16" (click)="onMouseEnter()" /> -->
   
                

                    <i class="ml-auto pi pi-ellipsis-h absolute lession-more-icon pointer hvr-grow" (click)="lessonCardClicked()"></i>
                    </div>
                    <p-menu #menu3 popup="popup" [model]="items" appendTo="body"></p-menu>
                </span>
                <div class="flex gap-3 bottom-info mb-1">
                    <span class="flex flex-row align-items-center gap-1 font-xs capitalize">
                        <g-level-circle [name]="!activeLevel ? lesson.classroom?.activeLevel : activeLevel" [size]="'xs'"  [textSize]="'2xs'" ></g-level-circle>
                        {{lesson.classroom?.language}}</span>
                    <span class="flex align-items-center font-xs"><img src="/assets/icons/calendar-sm.svg" /> {{ lesson.startingDate | date:'MMM d, y' }}</span>
                    <span class="flex align-items-center font-xs"><img src="/assets/icons/wall-clock.svg" /> {{ generalService.getLessonTime(lesson.startingDate) }}</span>
                    <span class="flex align-items-center font-xs"><img src="/assets/icons/hourglass.svg" /> {{generalService.convertHoursToMinutesWithSuffix(lesson.duration)}}</span>
                </div>
            </div>
            <!-- <div class="w-full lg:w-2 lg:flex-order-0 flex-order-1 date">{{lesson.createdAt |
                date:'dd/MM/yyyy h:mm a'}}</div> -->
            <!-- <div class="w-full lg:w-1 flex justify-content-end text-primary">
            </div> -->
        </li>
    </ul>
</ng-container>
</div>