import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Classroom, ClassroomType } from 'src/app/core/models/classroom.model';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-classrooms-check-list',
  templateUrl: './classrooms-check-list.component.html',
  styleUrls: ['./classrooms-check-list.component.scss']
})
export class ClassroomsCheckListComponent implements OnInit {
  @ViewChild('list') public list: any;
  @Output() checkedList = new EventEmitter<{ usersChecked: User[], classroomsChecked: Classroom[], classroom: Classroom, map: Map<string, User[]>, classroomUsers: Map<string, { classroom: Classroom, users: User[] }> }>();
  @Output() close = new EventEmitter<{}>();
  @Input() usersAssignedTo: User[] = [];
  @Input() classroomsAssignedTo: Classroom[] = [];
  @Input() preselectedClassroom: Classroom = {} as Classroom;
  @Input() showBlur: boolean = false;
  @Input() note: boolean = false;
  private subs = new SubSink();
  public classrooms: Classroom[] = [];
  public term: any;
  public firstOpen: boolean = true;

  @Input() public test: Map<string, User[]> = new Map()
  @Input() public classroomUsers: Map<string, { classroom: Classroom, users: User[] }> = new Map()

  @Input() public already: User[] = []

  @Input() disapear: boolean = false;

  constructor(
    private classroomService: ClassroomService,
    private authService: AuthService,
    private generalService: GeneralService,
  ) { }
  ngOnInit(): void {
    this.classrooms = this.classroomService.sessionUserClassrooms
    this.subs.sink = this.classroomService.getLMSUserClassrooms(this.authService.getUserId()!).subscribe(res => {
      this.classrooms = res;
      this.classroomService.sessionUserClassrooms = res;

      if (this.preselectedClassroom && !this.generalService.isNullishObject(this.preselectedClassroom)) {
        this.classrooms = this.classrooms.filter((el: Classroom) => { return this.preselectedClassroom.id === el.id })
      }
    })
  }
  ngAfterViewInit() {
    this.closeOnClickOutside();
  }

  ngAfterContentInit() {

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getClassroomTitle(classroom: Classroom) {
    if (this.authService.isStudent) {
      return this.generalService.getShortFullName(classroom.teacher);
    } else {
      return this.classroomService.getFormattedStudentNames(classroom.classroomStudents);
    }
  }

  addClassroom(event: any, classroom: Classroom) {
    for (let user of classroom.classroomStudents) {
      this.checkUser({ checked: false }, user, classroom);
    }
    if (event.checked) {
      for (let user of classroom.classroomStudents) {
        this.checkUser({ checked: true }, user, classroom);
      }
    }
  }

  checkUser(event: any, user: User, classroom: Classroom) {
    // let already = this.usersAssignedTo.some(el => el.aspUserId == user.id);
    // if (!already) {
    let al = this.already.find(el => el.id == user.id)
    let checked = event.checked;
    if (checked) {
      this.usersAssignedTo.push(user)
    } else {
      this.usersAssignedTo = this.usersAssignedTo.filter((el: User) => { return user.id !== el.id })
    }
    let foundUnchecked = false;
    for (let user of classroom.classroomStudents) {
      if (!this.isUserChecked(user, classroom)) {
        foundUnchecked = true;
      }
    }
    if (foundUnchecked) {
      this.classroomsAssignedTo = this.classroomsAssignedTo.filter((el: Classroom) => { return classroom.id !== el.id })
    } else {
      this.classroomsAssignedTo.push(classroom);
    }
    let classroomUsers: User[] = [];


    if (this.test.has(classroom.id)) {
      classroomUsers = this.test.get(classroom.id)!;
    }

    if (checked) {
      classroomUsers?.push(user)
    } else {
      classroomUsers = classroomUsers?.filter((el: User) => { return user.id !== el.id })
    }

    console.log(classroomUsers)

    if (!checked) {
      this.test.delete(classroom.id);
    } else {
      this.test.set(classroom.id, classroomUsers!)
    }


    if (classroomUsers.length > 0) {
      this.classroomUsers.set(classroom.id, { classroom: classroom, users: classroomUsers! })
    } else {
      this.classroomUsers.delete(classroom.id)
    }
    this.isClassroomChecked(classroom)
    this.checkedList.emit({ usersChecked: this.usersAssignedTo, classroomsChecked: this.classroomsAssignedTo, classroom: classroom, map: this.test, classroomUsers: this.classroomUsers })
    // }
  }

  alreadyAssigned(user: User) {
    if (this.already) {
      return this.already.some(el => el.aspUserId == user.id || el.id == user.id);
    }
    return false;
  }

  alreadyAssignedFromUsers(users: User[]) {
    if (this.already) {
      for (let user of users) {
        if (this.already.some(el => el.aspUserId == user.id || el.id == user.id))
          return true
      }
    }
    return false
  }

  isUserChecked(user: User, classroom: Classroom) {
    let isChecked = false;
    if (this.test.has(classroom.id)) {
      let classroomUsers = this.test.get(classroom.id)!;
      if (classroomUsers.some((el: User) => el.id == user.id)) {
        isChecked = true;
      }
    }
    return isChecked
  }

  isClassroomChecked(classroom: Classroom) {
    let isChecked = false;
    if (this.test.has(classroom.id)) {
      let classroomUsers = this.test.get(classroom.id)!;
      if (classroomUsers.length == classroom.classroomStudents.length) {
        isChecked = true;
      }
    }
    return isChecked
  }

  closeList() {
    this.close.emit();
  }

  closeOnClickOutside() {
    document.addEventListener('click', (event: any) => {
      setTimeout(() => {// i dont know why..
        let ignoreClickOnMeElement = this.list;
        var isClickInsideElement = ignoreClickOnMeElement.nativeElement.contains(event.target);
        if (!isClickInsideElement && !this.firstOpen) {
          // this.closeList();
        }
        this.firstOpen = false;
      }, 0);
    });
  }

  getuniqueClassroomStudents(classroomStudents: User[]) {
    return Array.from(new Set(classroomStudents.map((student: any) => student.aspUserId)))
      .map(aspUserId => classroomStudents.find((student: any) => student.aspUserId === aspUserId)) as User[];
  }

}
