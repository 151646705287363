import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TopMenuContainerLayoutComponent } from 'src/app/shared/layout/top-menu-container-layout/top-menu-container-layout.component';
import { NotificationOverviewComponent } from './components/notification-overview/notification-overview.component';


const routes: Routes = [
  { path: '', component: TopMenuContainerLayoutComponent,
  children: [
    {
      path: '',
      component: NotificationOverviewComponent,
    },
  ]
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationsRoutingModule { }
