import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Teacher, TeacherApplicationReview, TeacherApplicationStepsStatus, UserAvailabilityStep5 } from 'src/app/core/models/teacher.model';
import { UserAvailability, UserAvailabilityType } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { TeacherApplicationService } from 'src/app/core/services/teacher-application.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit, OnDestroy {
  public teacherApplication: TeacherApplicationReview = {} as TeacherApplicationReview;
  public isAgreement1Checked: boolean = false;
  public isAgreement2Checked: boolean = false;
  private subs = new SubSink();
  private submitTeacher: Teacher = {} as Teacher;
  hideBottomLayout = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private teacherService: TeacherApplicationService,
    public generalService: GeneralService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.teacherService.setCurrentStepIndex(5);
    this.checkQueryParamsForBottomLayout();
    this.subs.add(this.teacherService.getTeacherApplicationStep6().subscribe((teacherApplicationReview: TeacherApplicationReview) => {
      this.teacherApplication = teacherApplicationReview;
      console.log(this.teacherService.stepsStatuses);
    }));

    // this.subs.add(this.teacherService.getTeacherApplicationStepsStatus().subscribe((stepsStatus: TeacherApplicationStepsStatus) => {
    //   console.log(stepsStatus);
    // }));

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  updateAgreement1(event: any) {
    this.isAgreement1Checked = event.checked;
  }

  updateAgreement2(event: any) {
    this.isAgreement2Checked = event.checked;
  }

  onSubmit() {
    if (!this.isAgreement1Checked || !this.isAgreement2Checked) {
      
      this.toastService.setShowToastmessage({
        severity: 'warn',
        summary: '',
        detail: 'Please check the terms and conditions and personal information agreement.'
      });
      return;
    }

    this.teacherService.registerTeacher({ teacherId: this.authService.getUserId() });
  }

  goBack() {
    this.router.navigateByUrl('/teacher/availability', { replaceUrl: true });
  }

  /**
   * Subscribe to query parameters in the current route to determine if bottom layout should be hidden.
   * 
   * @param {Params} queryparams - Query parameters in the current route.
   */
  private checkQueryParamsForBottomLayout(): void {
    this.subs.add(this.route.queryParams.subscribe((queryparams: Params) => {
      if (queryparams && queryparams.buttons) {
        this.hideBottomLayout = queryparams.buttons === '1';
      }
    }));
  }

}
