import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Level } from 'src/app/core/models/classroom.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';

@Component({
  selector: 'app-classroom-level-filters',
  templateUrl: './classroom-level-filters.component.html',
  styleUrls: ['./classroom-level-filters.component.scss'],
})
export class ClassroomLevelFiltersComponent implements OnInit {
  @Input() selectedLevel: string = 'All';
  @Input() showFirstTitle: boolean = true;
  @Input() size = 'sm';
  @Input() scale = 1;
  @Output() levelSelected = new EventEmitter<string>();
  levels: Level[] = [Level.ALL, Level.A1, Level.A2, Level.B1, Level.B2, Level.C1, Level.C2, Level.BS, Level.NT21, Level.NT22];
  filteredLevels: Level[] = [] as Level[];
  constructor(
    private authService: AuthService,
    private classroomService: ClassroomService,
    ) { }

  ngOnInit(): void {
    if (this.authService.isTeacher) {

      this.filteredLevels = this.classroomService.filterLevelsByTeachingLanguage(this.levels, this.authService.teachingLanguage);
    } else if (this.authService.isStudent) {
      this.filteredLevels = this.filterLevelsWithoutDutchLevels();
    }
  }

  setSelectedLevel(level: string): void {
    this.selectedLevel = level;
    this.levelSelected.emit(this.selectedLevel);
    console.log(this.selectedLevel)
  }

  isDutchLevel(level: Level): boolean {
    return level === Level.NT21 || level === Level.NT22;
  }

  checkIfHasDutchClassrooms(levels: Level[], teachingLanguage: string): Level[] {
    if (!teachingLanguage) {
      return levels;
    }
    if (teachingLanguage.toLowerCase() !== 'dutch') {
      return levels.filter(level => level !== Level.NT21 && level !== Level.NT22);
    } else {
      return levels;
    }
  }

  hasClassroomWithDutchlanguage(): boolean {
    for (const classroom of this.classroomService.getUserSessionClassrooms()) {
      if (classroom.language!.toLowerCase() === "Dutch".toLowerCase()) {
        return true;
      }
    }

    return false;
  }

  filterLevelsWithoutDutchLevels() {
    let foundLevels = this.levels.filter(level => level !== Level.NT21 && level !== Level.NT22);
    console.log(this.hasClassroomWithDutchlanguage());
    if (this.hasClassroomWithDutchlanguage()) {
      foundLevels = this.levels;
    }
    return foundLevels;
  }

}
