import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastMessage } from '../models/general.model';
import { AvailabilityTime, LanguageLevel, Teacher, TeacherApplicationReview, TeacherApplicationStepsStatus, TeacherStatus, TeacherStatusResponse } from '../models/teacher.model';
import { UserAvailability, UserAvailabilityType } from '../models/user.model';
const BACKEND_URL = environment.apiUrl + "/Teacher/";
const BACKEND_LMS_TEACHER_URL = environment.apiUrl + "/LMS/";
const BACKEND_LMS_URL = environment.apiUrl + "/LMS/";

@Injectable({
  providedIn: 'root'
})
export class TeacherApplicationService {

  private step$: BehaviorSubject<string> = new BehaviorSubject<string>("Create your account");
  public readonly step: Observable<string> = this.step$.asObservable();

  private currentStepIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly currentStepIndex: Observable<number> = this.currentStepIndex$.asObservable();

  private submitOnMenuClickListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly submitOnMenuClickListener: Observable<boolean> = this.submitOnMenuClickListener$.asObservable();

  private updateAvailabilityListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly updateAvailabilityListener: Observable<boolean> = this.updateAvailabilityListener$.asObservable();

  private updateAvailabilityForSchedulerListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly updateAvailabilityForSchedulerListener: Observable<boolean> = this.updateAvailabilityForSchedulerListener$.asObservable();

  private showWithdrawConfirmationDialog$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly showWithdrawConfirmationDialog: Observable<boolean> = this.showWithdrawConfirmationDialog$.asObservable();

  private showApplicationProgressMenu$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly showApplicationProgressMenu: Observable<boolean> = this.showApplicationProgressMenu$.asObservable();

  private shouldRetrieveTeacherStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly shouldRetrieveTeacherStatus: Observable<boolean> = this.shouldRetrieveTeacherStatus$.asObservable();

  private compareStepvalues$: BehaviorSubject<{}> = new BehaviorSubject<{}>({});
  public readonly compareStepvalues: Observable<{}> = this.compareStepvalues$.asObservable();

  // private stepsValid$: BehaviorSubject<boolean[]> = new BehaviorSubject<boolean[]>([false, false, false, false, false, false, false,]);
  // public readonly stepsValid: Observable<boolean[]> = this.stepsValid$.asObservable();

  public stepsValid: boolean[] = [false, false, false, false, false, true,];
  public stepsStatuses: TeacherApplicationStepsStatus = {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false
  }

  public dummyTeacher: Teacher = {
    id: "",
    // username: "",
    preffered_name: "",
    // password: "",
    first_name: "",
    last_name: "",
    city: "",
    country: {
      name: "", flagUrl: ""
    },
    country_origin: {
      name: "", flagUrl: ""
    },
    country_residency: {
      name: "", flagUrl: ""
    },
    native_language: "",
    native_languages: [],
    birth: '' + new Date(),
    timezone: "",
    email: "",
    phone: "",
    skype: "",
    education: [],
    language_speak: [],
    language_teach: [],
    teaching_methods: "",
    years_experience: 0,
    cv: "",
    photo: "",
    hours_per_week: "",
    status: "",
    availability: undefined,
    date_applied: new Date()
  };

  public teacher: Teacher = {
    id: "",
    // username: "ibekiaris",
    preffered_name: "iraklis",
    // password: "e",
    first_name: "iraklis",
    last_name: "bekiaris",
    city: "serres",
    country: {
      name: "Wales", flagUrl: ""
    },
    country_origin: {
      name: "", flagUrl: ""
    },
    country_residency: {
      name: "", flagUrl: ""
    },
    native_language: "Afar",
    native_languages: [],
    birth: '' + new Date(),
    timezone: "(UTC-12:00) International Date Line West",
    email: "ibekiaris@outlook.com",
    phone: "00306988582965",
    skype: "iraklis.bekiaris",
    education: [
      {
        name: "Degree 1",
        institute: "inst",
        fileUploads: [{name: 'path1'}],
      }
    ],
    language_speak: [
      {
        language: {code: 'el', nativeName: 'greek', name: "greek"},
        level: "C1"
      },
      {
        language: {code: 'el', nativeName: 'greek', name: "greek"},
        level: "C1"
      }
    ],
    language_teach: [
      {
        language: {code: 'el', nativeName: 'greek', name: "greek"},
        level: "C1"
      },
      {
        language: {code: 'el', nativeName: 'greek', name: "greek"},
        level: "C1"
      }
    ],
    teaching_methods: "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in thes",
    years_experience: 6,
    cv: "CV",
    photo: "Photo",
    hours_per_week: "5-10",
    status: "Pending",
    availability: {
      id: "availability_2",
      type: UserAvailabilityType.AVAILABLE,
      mon: [{ from: "10:30", to: "14:00" }, { from: "15:00", to: "18:00" }, { from: "19:00", to: "20:30" }],
      tue: [{ from: "10:30", to: "14:00" }, { from: "15:00", to: "18:00" }, { from: "19:00", to: "20:30" }],
      wed: [{ from: "10:00", to: "18:00" }],
      thu: [{ from: "12:30", to: "14:30" }, { from: "16:00", to: "20:00" }],
      fri: [{ from: "10:30", to: "14:00" }, { from: "15:00", to: "18:00" }],
      sat: [{ from: "10:30", to: "14:00" }],
      sun: [],
    },
    date_applied: new Date()
  };

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  public setStep(section: string) {
    let step = '';
    switch (section) {
      case 'info':
        step = 'Create your account';
        break;
      case 'contact':
        step = 'Enter your contact info';
        break;
      case 'education':
        step = 'Enter your educational skills';
        break;
      case 'experience':
        step = 'Enter your teaching experience & skills';
        break;
      case 'resume':
        step = 'Upload your CV & Photo';
        break;
      case 'availability':
        step = 'Add availability';
        break;
      case 'review':
        step = 'Review & Submit';
        break;
      case 'status':
        step = 'status';
        break;
      default:
        step = '';
    }
    this.step$.next(step);
  }
  
  public setCurrentStepIndex(index: number) {
    this.currentStepIndex$.next(index);
  }

  public setStepValid(index: number, form: UntypedFormGroup, id: string) {
    if (!form.invalid) {
      this.stepsValid[index] = true;
    } else {
      this.stepsValid[index] = false;
    }
  }

  public setStepValidWithBoolean(index: number, isNotValid: boolean, id: string) {
    if (!isNotValid) {
      this.stepsValid[index] = true;
      // document.getElementById(id)!.querySelector('img')!.style.display = 'block';;
      // document.getElementById(id)!.classList.remove('inactive-item');
    } else {
      this.stepsValid[index] = false;
      // document.getElementById(id)!.querySelector('img')!.style.display = 'none';;
      // document.getElementById(id)!.classList.add('inactive-item');
    }
  }

  public setSubmitOnMenuClickListener(submitted: boolean) {
    this.submitOnMenuClickListener$.next(submitted);
  }

  public setUpdateAvailabilityClickListener(update: boolean) {
    this.updateAvailabilityListener$.next(update);
  }

  public setUpdateAvailabilityForSchedulerClickListener(update: boolean) {
    this.updateAvailabilityForSchedulerListener$.next(update);
  }

  public setShowWithdrawConfirmationDialog(update: boolean) {
    this.showWithdrawConfirmationDialog$.next(update);
  }

  public setShowApplicationProgressMenu(update: boolean) {
    this.showApplicationProgressMenu$.next(update);
  }

  public setShouldRetrieveTeacherStatus(update: boolean) {
    this.shouldRetrieveTeacherStatus$.next(update);
  }

  public setCompareStepvalues(update: {}) {
    this.compareStepvalues$.next(update);
  }

  public setStepStatuses(stepsStatuses: TeacherApplicationStepsStatus) {
    this.stepsStatuses = stepsStatuses;
  }
  
  public registerTeacher(teacher: {}): void {
    this.http.post<Response>(BACKEND_LMS_TEACHER_URL + "SubmitTeacherApplication", teacher)
      .subscribe((res) => {
        console.log("[+] Success Registration: ");
        this.router.navigateByUrl('/teacher/status', { replaceUrl: true });
      }, (error) => {
        console.error(error);
      });
  }  

  public updateAvailability(availability: UserAvailability): Observable<any> {
    return this.http.post(BACKEND_URL + "UpdateTeacherAvailability", availability)
  }

  public updateAPITeacherApplicationStep1(form: {}): Observable<any> {
    return this.http.post(BACKEND_LMS_TEACHER_URL + 'TeacherApplcationSaveStep1', form);
  }

  public getTeacherApplicationStep1(teacherId: string): Observable<any> {
    return this.http.get(BACKEND_LMS_TEACHER_URL + 'GetTeacherApplicationStep1?teacherId=' + teacherId);
  }

  public getTeacherApplicationStep2(): Observable<any> {
    return this.http.get(BACKEND_LMS_TEACHER_URL + 'GetTeacherApplicationStep2');
  }

  public updateTeacherApplicationStep2(form: {}): Observable<any> {
    return this.http.post(BACKEND_LMS_TEACHER_URL + 'TeacherApplcationSaveStep2', form);
  }

  public getTeacherApplicationStep3(): Observable<any> {
    return this.http.get(BACKEND_LMS_TEACHER_URL + 'GetTeacherApplicationStep3');
  }

  public updateAPITeacherApplicationStep3(form: {}): Observable<any> {
    return this.http.post(BACKEND_LMS_TEACHER_URL + 'TeacherApplcationSaveStep3', form);
  }

  public getTeacherApplicationStep4(): Observable<any> {
    return this.http.get(BACKEND_LMS_TEACHER_URL + 'GetTeacherApplicationStep4') as Observable<any>;
  }

  public updateAPITeacherApplicationStep4(form: {}): Observable<any> {
    return this.http.post(BACKEND_LMS_TEACHER_URL + 'TeacherApplcationSaveStep4', form) as Observable<any>;
  }

  public getTeacherApplicationStep5(): Observable<AvailabilityTime> {
    return this.http.get(BACKEND_LMS_TEACHER_URL + 'GetTeacherApplicationStep5') as Observable<AvailabilityTime>;
  }

  public updateAPITeacherApplicationStep5(form: {}): Observable<AvailabilityTime> {
    return this.http.post(BACKEND_LMS_TEACHER_URL + 'TeacherApplcationSaveStep5', form) as Observable<AvailabilityTime>;
  }

  public getTeacherApplicationStep6(): Observable<TeacherApplicationReview> {
    return this.http.get(BACKEND_LMS_TEACHER_URL + 'GetTeacherApplicationStep6') as Observable<TeacherApplicationReview>;
  }

  public getTeacherApplicationStepsStatus(): Observable<TeacherApplicationStepsStatus> {
    return this.http.get(BACKEND_LMS_TEACHER_URL + 'GetApplicationStepsStatus') as Observable<TeacherApplicationStepsStatus>;
  }

  public getLingoTeachingLanguages(): Observable<[]> {
    return this.http.get(BACKEND_LMS_TEACHER_URL + 'GetLingoTeachingLanguages') as Observable<[]>;
  }

  public deleteTeacherApplicationFiles(filename: string): Observable<any> {
    return this.http.post(BACKEND_LMS_TEACHER_URL + 'DeleteTeacherApplicationFiles?fileName=' + filename, {}) as Observable<any>;
  }

  public withdrawnTeacherApplication(): Observable<boolean> {
    return this.http.post(BACKEND_LMS_TEACHER_URL + 'WithdrawnTeacherApplication', {}) as Observable<boolean>;
  }

  public restartTeacherApplication(): Observable<boolean> {
    return this.http.post(BACKEND_LMS_TEACHER_URL + 'RestartTeacherApplication', {}) as Observable<boolean>;
  }

  public updateTeacherAvailability(params: {}): Observable<boolean> {
    return this.http.post(BACKEND_LMS_URL + 'UpdateTeacherAvailability', params) as Observable<boolean>;
  }

  public getStepsTitle(stepIndex: number): string {
    const stepTitles = [
      'Create your account',
      'Enter your education info',
      'Enter your teaching experience & skills',
      'Upload your Resume',
      'Add Availability',
      'General Info'
    ];
  
    return stepTitles[stepIndex] || '';
  }

  public getTeacherStatusNavigationUrl(status: TeacherStatusResponse) {
    let navigateUrl = '/dashboard';

    switch (status.status.toLowerCase()) {
      case TeacherStatus.CONSIDERATION.toLowerCase():
      case TeacherStatus.NO_LONGER_CONSIDERED.toLowerCase():
      case TeacherStatus.REJECTED.toLowerCase():
      case TeacherStatus.NEW.toLowerCase():
      case TeacherStatus.RECEIVED.toLowerCase():
      case TeacherStatus.INCOMPLETE.toLowerCase():
      case TeacherStatus.WITHDRAWN.toLowerCase():
        navigateUrl = '/teacher/status';
        break;
    }

    return navigateUrl;
  }

  public isTeacherStatusApproved(status: string) {
    if (status.toLowerCase() === TeacherStatus.APPROVED.toLowerCase()) {
      return false;
    }

    return true;
  }
}
