import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Classroom, ClassroomSizesStats, ClassroomsLevelFilter, ClassroomsStatusFilter, ClassroomStudentsResponse, ClassroomType, Level, LevelClassroomsStats, PackageClassroomsStats, Status, StatusClassroomsStats } from '../models/classroom.model';
import { Lesson, LessonStatus } from '../models/lesson.model';
import { Note } from '../models/note.model';
import { User, UserAvailability, UserAvailabilityHours, UserAvailabilityOff, UserAvailabilityType, UserRole } from '../models/user.model';
import { AuthService } from './auth.service';
import { NoteService } from './note.service';
import { PackageService } from './package.service';
import { UserService } from './user.service';
import { dummyClassrooms } from '../models/data';
import { Package, PackageState, PackageType } from '../models/package.model';
import { GeneralService } from './general.service';
const BACKEND_URL = environment.apiUrl + "";
const BACKEND_URL_LMS = environment.apiUrl + "/LMS";

@Injectable({
  providedIn: 'root'
})
export class ClassroomService {

  ClassroomTypes = ClassroomType;
  ClassroomStatuses = Status;
  private classroomsLevelFilter$: BehaviorSubject<ClassroomsLevelFilter> = new BehaviorSubject<ClassroomsLevelFilter>({ level: "All", domLevelID: "all-levels" });
  public readonly classroomsLevelFilter: Observable<ClassroomsLevelFilter> = this.classroomsLevelFilter$.asObservable();

  private classroomsStatusFilter$: BehaviorSubject<ClassroomsStatusFilter> = new BehaviorSubject<ClassroomsStatusFilter>({ status: "All", domStatusID: "all-kinds" });
  public readonly classroomsStatusFilter: Observable<ClassroomsStatusFilter> = this.classroomsStatusFilter$.asObservable();

  private selectedAgendaDate$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  public readonly selectedAgendaDate: Observable<Date> = this.selectedAgendaDate$.asObservable();

  private classroomUpdateListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly classroomUpdateListener: Observable<boolean> = this.classroomUpdateListener$.asObservable();

  private classroomPackagesUpdateListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly classroomPackagesUpdateListener: Observable<boolean> = this.classroomPackagesUpdateListener$.asObservable();

  private userId = this.authService.getLoggedInUser()?.id;

  public sessionUserClassrooms: Classroom[] = [];
  public sessionUserTrialClassrooms: Classroom[] = [];
  public classroomToArrangeLesson: Classroom = {} as Classroom
  dummyClassrooms = dummyClassrooms;
  public currentSelectedClassroom: Classroom = {} as Classroom;

  constructor(
    private http: HttpClient,
    public userService: UserService,
    private authService: AuthService,
    private packageService: PackageService,
    private generalService: GeneralService,
  ) { }

  setSelectedClassroom(classroom: Classroom) {
    this.currentSelectedClassroom = classroom;
  }

  setSelectedAgendaDate(date: Date) {
    this.selectedAgendaDate$.next(date);
  }

  setSelectedClassroomUpdate(val: boolean) {
    this.classroomUpdateListener$.next(val);
  }

  setSelectedClassroomPackagesUpdate(val: boolean) {
    this.classroomPackagesUpdateListener$.next(val);
  }

  setUserClassrooms(classrooms: Classroom[]) {
    this.sessionUserClassrooms = classrooms;
  }

  getUserSessionClassrooms(): Classroom[] {
    return this.sessionUserClassrooms;
  }

  getSelectedClassroom(): Classroom {
    return this.currentSelectedClassroom;
  }

  getClassroom(classroomId: string): Observable<Classroom> {
    return this.http.get<any>(BACKEND_URL_LMS + '/GetClassroomById?classroomId=' + classroomId)
  }

  getUserClassrooms(type?: ClassroomType): Observable<any> {
    let endpoint = this.authService.getUserRole() === UserRole.TEACHER ? '/Classrooms/GetTeacherClassrooms' : '/Classrooms/GetUserClassrooms';

    return this.http.get<any>(BACKEND_URL + endpoint)
      .pipe(
        switchMap((classrooms: Classroom[]) => {
          if (type)
            classrooms = classrooms.filter(el => el.type === type)
          if (classrooms.length == 0) {
            return of([])
          }
          return forkJoin(classrooms.map((classroom: Classroom) => {
            return this.getClassroomExtras(classroom.id).pipe(
              map(x => {
                let classroomDetails: Classroom = ({ ...classroom, ...{ users: x[0].usersDTOResult, title: this.getUsersTitle(x[0].usersDTOResult), activePackage: x[1], lessons: x[2], teacher: x[3], packages: x[4] } });
                return classroomDetails;
              }))
          }))
        }), tap((res) => {
          // console.log("[+] getUserClassrooms:")
          // console.log(res)
        })
      )
  }

  changeTrialLevel(trialId: string, newLevel: string) {
    return this.http.post<any>(BACKEND_URL + '/Package/ChangeTrialLevel?classroomId=' + trialId + '&newLevel=' + newLevel, {})
  }

  changeClassLevel(trialId: string, newLevel: string) {
    return this.http.post<any>(BACKEND_URL + '/Package/ChangeClassLevel?classroomId=' + trialId + '&newLevel=' + newLevel, {})
  }

  getClassroomStudents(classroomId: string): Observable<any> {
    return this.http.get<any>(BACKEND_URL + '/Classrooms/GetClassroomStudents?classroomId=' + classroomId)
  }

  getClassroomTeacher(classroomId: string): Observable<User> {
    return this.http.get<User>(BACKEND_URL + '/Classrooms/GetClassroomTeacher?classroomId=' + classroomId)
      .pipe(
        switchMap((res: any) => {
          Object.keys((res.availability)).forEach((key: string) => {
            if (res.availability[key] == null) {
              res.availability[key] = []
            }
          })
          for (let dayOff of res.off) {
            dayOff.period.from = new Date(new Date(dayOff.period.from).setHours(0, 0));
            dayOff.period.to = new Date(new Date(dayOff.period.to).setHours(0, 0));
          }
          return of(res)
        })
      )

  }

  getClassroomExtras(classroomId: string): Observable<any> {
    return forkJoin([
      this.getClassroomStudents(classroomId),
      this.packageService.getClassroomActivePackage(classroomId),
      this.getClassroomLessons2(classroomId),
      this.getClassroomTeacher(classroomId),
      this.packageService.getClassroomPackages(classroomId)
    ])
  }

  public getClassroomLessons2(classroomId: string): Observable<Lesson[]> {
    return this.http.get<Lesson[]>(BACKEND_URL + "/Lesson/GetClassroomLessons?classroomId=" + classroomId).pipe(
      map(lesson => lesson.map(l => {
        l.startingDate = new Date(l.startingDate);
        return l;
      }))
    )
  }

  changeClassroomsLevelFilter(level: ClassroomsLevelFilter) {
    this.classroomsLevelFilter$.next(level);
  }

  changeClassroomsStatusFilter(status: ClassroomsStatusFilter) {
    this.classroomsStatusFilter$.next(status);
  }

  getClassroomTitle(classroom: Classroom) {
    let title = "";
    let i = 0;
    for (let user of classroom.classroomStudents) {
      let comma = i < classroom.classroomStudents.length - 1 ? ", " : "";
      title += user.lastName + comma
      i++;
    }
    return title;
  }

  getUsersTitle(users: User[]) {
    let title = "";
    let i = 0;
    for (let user of users) {
      let comma = i < users.length - 1 ? ", " : "";
      title += user.lastName + comma
      i++;
    }
    return title;
  }

  getClassroomType(users: User[]) {
    if (users.length == 1) return "1 on 1"
    if (users.length == 2) return "group of 2"
    if (users.length == 3) return "group of 3"
    return "group of many"
  }

  getStatusClassroomStats(): Promise<StatusClassroomsStats | undefined> {
    return this.http.get<StatusClassroomsStats>(BACKEND_URL + "/Classrooms/GetClassroomsStatusStats?userid=" + this.userId).toPromise()
  }

  getClassroomSizesStats(): Promise<ClassroomSizesStats | undefined> {
    return this.http.get<ClassroomSizesStats>(BACKEND_URL + "/Classrooms/GetClassroomSizesStats?classroomid=2").toPromise()
  }

  getStudentsHoursStatsOnEachLevelStats(): Promise<LevelClassroomsStats[] | undefined> {
    return this.http.get<LevelClassroomsStats[]>(BACKEND_URL + "/Classrooms/GetStudentsAndHoursOnEachLevel").toPromise()
  }

  getPackageStats(): Promise<PackageClassroomsStats[] | undefined> {
    return this.http.get<PackageClassroomsStats[]>(BACKEND_URL + "/Classrooms/GetClassroomPackagesStatus").toPromise()
  }

  getTrialClassrooms(): Observable<any[]> {
    return this.http.get<PackageClassroomsStats[]>(BACKEND_URL_LMS + "/GetTrialClassrooms")
  }

  getClassroomsTeacher(): Observable<any[]> {
    return this.http.get<PackageClassroomsStats[]>(BACKEND_URL_LMS + "/GetTeacherClassrooms")
  }

  public getClassroomLessons(classroomId: string): Observable<Lesson[]> {
    return this.http.get<Lesson[]>(BACKEND_URL_LMS + "/GetClassroomLessons?classroomId=" + classroomId)
  }

  public gePendingLessons(classroomId: string) {
    return this.http.get<any>(BACKEND_URL_LMS + "/GetLessonsPending?ClassroomId=" + classroomId)
  }

  public acceptLessonRequest(lessonId: number) {
    return this.http.post<any>(BACKEND_URL_LMS + "/AcceptLessonRequest?LessonId=" + lessonId, {});
  }

  public rejectLessonRequest(lessonId: number) {
    return this.http.post<any>(BACKEND_URL_LMS + "/RejectLessonRequest?LessonId=" + lessonId, {});
  }

  getLMSUserClassrooms(userId: string): Observable<any> {
    return this.http.get<any>(BACKEND_URL_LMS + '/GetUserClassrooms?userId=' + userId);
  }

  changeClassroomStatus(classroomId: number, newStatus: string): Observable<any> {
    const body = { classroomId, status: newStatus };
    return this.http.post<any>(BACKEND_URL_LMS + '/ChangeClassroomStatus?classroomId=' + classroomId + '&status=' + newStatus + '', body);
  }

  getLevelIndex(level: Level) {
    let levels = [Level.A1, Level.A2, Level.B1, Level.B2, Level.C1, Level.C2];
    return levels.indexOf(level)
  }

  isTrialClassroomWithCompletedFirstLesson(classroom: any): boolean {
    if (classroom.type === this.ClassroomTypes.TRIAL && classroom.lessons.length > 0) {
      const firstLesson = classroom.lessons[0];
      return firstLesson.status.toLowerCase().includes(LessonStatus.COMPLETED);
    }
    return false;
  }

  isTrialClassroomWithCompletedLesson(classroom: any): boolean {
    if (classroom.type === this.ClassroomTypes.TRIAL && classroom.lessons.length > 0) {
      return classroom.lessons.some((lesson: any) => {
        // console.log('Checking lesson:', lesson);
        const isCompleted = lesson.status.toLowerCase().includes(LessonStatus.COMPLETED_TRIAL.toLowerCase());
        // console.log('Is lesson completed?', isCompleted);
        return isCompleted;
      });
    }
    return false;
  }

  isTrialClassroomDismissed(classroom: Classroom): boolean {
    if (classroom.type === this.ClassroomTypes.TRIAL && classroom.status === Status.DISMISSED_TRIAL) {
      return true;
    }
    return false;
  }

  isFirstLessonStatusInTrialClassroom(classroom: any, status: LessonStatus): boolean {
    if (classroom.type === this.ClassroomTypes.TRIAL && classroom.lessons.length > 0) {
      const firstLesson = classroom.lessons[0];
      return firstLesson.status === status;
    }
    return false;
  }

  isTrialClassroom(classroom: Classroom): boolean {
    if (classroom.type === this.ClassroomTypes.TRIAL) {
      return true;
    }
    return false;
  }

  /**
   * Checks if the last lesson of a given classroom is within one month from the current date.
   *
   * @param {Classroom} classroom - The classroom to check the last lesson of.
   * @return {boolean} Returns true if the last lesson is within one month, false otherwise.
   */
  isLastLessonWithinOneMonth(classroom: Classroom): boolean {
    // Check if the classroom has lessons
    if (classroom.lessons!.length === 0) {
      return false;
    }

    if ((classroom.type !== this.ClassroomTypes.TRIAL)) {
      return false;
    }

    // Get the date of the most recent lesson
    const lastLesson = classroom.lessons!.reduce((latest: any, lesson: any) => {
      const lessonDate = new Date(lesson.startingDate);
      return lessonDate > new Date(latest.startingDate) ? lesson : latest;
    });

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in time (in milliseconds)
    const timeDifference = currentDate.getTime() - new Date(lastLesson.startingDate).getTime();

    // Convert time difference from milliseconds to days
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    // Check if the difference is less than or equal to 30 days
    return daysDifference <= 520;
  }

  getAvailableHoursWithTeacher(teacherId: string): Observable<any> {
    return this.http.get<any>(BACKEND_URL_LMS + '/StudentHasHoursWithTeacher?teacherId=' + teacherId);
  }

  filterAvailableClassrooms(classrooms: Classroom[]): Classroom[] {
    const availableClassrooms: Classroom[] = [];

    classrooms.forEach((classRoom: Classroom) => {
      if (classRoom.packages) {
        if (this.getAccumulatedHoursLeft(classRoom.packages!) > 0) {
          availableClassrooms.push(classRoom);
        }
      }
    });

    return availableClassrooms;
  }

  hasClassroomPackageHoursLeft(classroom: Classroom): boolean {
    if (this.getAccumulatedHoursLeft(classroom.packages!) > 0) {
      return true;
    }
    return false;
  }

  /**
  * Returns an array of student names for a given classroom object.
  * @param {Classroom} classroom - The classroom object to get student names from.
  * @returns {string[]} An array of student names.
  */
  getStudentNamesWithHTML(classroom: Classroom, showLastName: boolean = false): string[] {

    const uniqueStudents = Array.from(new Set(classroom.classroomStudents.map(student => student.aspUserId)))
      .map(aspUserId => classroom.classroomStudents.find(student => student.aspUserId === aspUserId)) as User[];
    const remainingStudents: number = uniqueStudents.length - 1; // Subtract 1 to exclude the first student

    const studentNames: string[] = [];

    if (uniqueStudents.length > 0) {
      studentNames.push(`${uniqueStudents[0].firstName} ${showLastName ? uniqueStudents[0].lastName : ''}`);
    }

    if (remainingStudents > 0) {
      const circleHtml = `<span class="student-number-circle">+${remainingStudents}</span>`;
      const div = document.createElement('div');
      div.innerHTML = circleHtml;
      studentNames.push((div.firstChild as HTMLElement).outerHTML);
    }

    return studentNames;
  }

  getFormattedStudentNames(students: any[]): string {
    const uniqueStudents: User[] = Object.values(students.reduce((acc, student) => {
      if (!acc[student.aspUserId]) {
        acc[student.aspUserId] = student;
      }
      return acc;
    }, {}));

    const names = uniqueStudents.map(student => `${this.generalService.replaceImportedWithDash(student.firstName)} ${this.generalService.replaceImportedWithDash(student.lastName) ? this.generalService.replaceImportedWithDash(student.lastName).charAt(0) + '.' : ''}`);
    return names.join(', ');
  }

  getFullStudentNames(students: any[]): string {
    const uniqueStudents: User[] = Object.values(students.reduce((acc, student) => {
      if (!acc[student.aspUserId]) {
        acc[student.aspUserId] = student;
      }
      return acc;
    }, {}));

    const names = uniqueStudents.map(student => `${this.generalService.replaceImportedWithDash(student.firstName)} ${this.generalService.replaceImportedWithDash(student.lastName) ? this.generalService.replaceImportedWithDash(student.lastName) + '' : ''}`);
    return names.join(', ');
  }

  getClassroomUrl(classroomId: number) {
    return `/dashboard/classrooms/lessons/${classroomId}/details`;
  }

  getClassroomRateUrl(classroom: Classroom) {
    if (classroom.type.includes('Trial')) {
      return `/dashboard/classrooms/lessons/${classroom.id}/info/rate-trial`;
    } else {

      return `/dashboard/classrooms/lessons/${classroom.id}/info/teacher-rate`;
    }
  }

  getClassroomTypeSmallIconPath(classroom: Classroom): string {
    if (classroom.type.includes('Trial')) {
      return `/assets/icons/paper-plane-blue.svg`;
    } else {
      return `/assets/icons/student.svg`;
    }
  }

  isClassroomOngoing(classroom: Classroom): boolean {
    if (classroom.status.toLowerCase().includes(this.ClassroomStatuses.ON_GOING.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  isStudentActive(classrooms: any[], hasPayment: boolean): boolean {
    return classrooms.length > 0 && hasPayment;
  }

  getOngoingPackage(packages: Package[]): Package | undefined {
    return packages.find((pkg) => pkg.state.toLowerCase() === PackageState.ONGOING.toLowerCase());
  }

  getAccumulatedHoursLeft(packages: Package[]): number {
    let accumulatedHoursLeft = 0;

    for (const pack of packages) {
      // check all ongoing for hours left
      // TODO:  || pack.state.toLowerCase() === PackageState.PURCHASED.toLowerCase()
      if (pack.state.toLowerCase() === PackageState.ONGOING.toLowerCase() || pack.state.toLowerCase() === PackageState.PURCHASED.toLowerCase()) {
        accumulatedHoursLeft += pack.hoursLeft;
      }
    }

    return accumulatedHoursLeft;
  }

  getAccumulatedTotalHours(packages: Package[]): number {
    let accumulatedTotalHours = 0;

    for (const pack of packages) {
      // check all ongoing, completed or gift packages for hours left
      if (pack.state.toLowerCase() !== PackageState.REFUNDED.toLowerCase()
        || pack.state.toLowerCase() !== PackageState.CANCELED.toLowerCase()
        || pack.state.toLowerCase() !== PackageState.CANCELLED.toLowerCase()
        || pack.state.toLowerCase() !== PackageState.TRANSFERED.toLowerCase()) {
        accumulatedTotalHours += pack.totalHours;
      }
    }

    return accumulatedTotalHours;
  }

  filterLevelsByTeachingLanguage(levels: Level[], teachingLanguage: string): Level[] {
    if (!teachingLanguage) {
      return levels;
    }
    if (teachingLanguage.toLowerCase() !== 'dutch') {
      return levels.filter(level => level !== Level.NT21 && level !== Level.NT22);
    } else {
      return levels;
    }
  }

  sortClassroomsByFirstName(classrooms: Classroom[]): Classroom[] {
    try {
      return classrooms.sort((a: Classroom, b: Classroom) => {
        const firstNameA = a.classroomStudents && a.classroomStudents.length > 0 ? a.classroomStudents[0].firstName : '';
        const firstNameB = b.classroomStudents && b.classroomStudents.length > 0 ? b.classroomStudents[0].firstName : '';
        return firstNameA.localeCompare(firstNameB);
      });
    } catch (error) {
      // Handle the error appropriately (e.g., log, display error message, fallback behavior)
      console.error('An error occurred while sorting classrooms:', error);
      return classrooms; // or return an empty array, depending on the desired behavior
    }
  }

  /**
   * Returns the name of the teacher or classroom students for a given classroom.
   *
   * @param {Classroom} classroom - The classroom object.
   * @returns {string} The name of the teacher or classroom students.
   */
  getLessonRowName(classroom: Classroom): string {
    if (this.authService.isStudent) {
      return this.generalService.getShortFullName(classroom.teacher);
    } else {
      return this.getFormattedStudentNames(classroom.classroomStudents);
    }
  }

  /**
   * Returns a deduplicated array of classroom students.
   *
   * @param classroomStudents - The array of classroom students which may contain duplicates.
   * @returns An array of unique classroom students.
   */
  getuniqueClassroomStudents(classroomStudents: User[]) {
    return Array.from(
      new Set(classroomStudents.map((student: any) => student.aspUserId))
    ).map((aspUserId) =>
      classroomStudents.find((student: any) => student.aspUserId === aspUserId)
    ) as User[];
  }

}
