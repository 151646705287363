<div [formGroup]="parentForm" class="relative">
  <!-- autocomplete="autocomplete_off_hack_xfr4!k" -->
  <p-dropdown (onChange)="onValueChange($event)"
  [options]="countries" [optionLabel]="optionLabel" 
  [filterBy]="filterBy"
    [filter]="filter" 
    [placeholder]="placeholder" 
    [ngModel]="selectedItemValue ?? null" 
    formControlName="{{inputName}}" 
    [class]="className"
    [appendTo]="'body'" [ngClass]="{
    'ng-invalid ng-dirty invalid-dropdown-input': isInvalidControl,
    'valid-dropdown-input': isValidControl
  }" [styleClass]="styleClass">

    <ng-container *ngIf="selectedItemValue">
      <ng-template pTemplate="selectedItem">
        <div class="country-item-value flex" *ngIf="selectedItemValue">
          <div class="flex  align-items-center justify-content-center gap-1">
            <img *ngIf="withFlags" [src]="findCountryImage(selectedItemValue[flagFilter])" width="22">

            <div *ngIf="flagFilter" class="country-name">{{selectedItemValue[flagFilter]}}</div>
            <div *ngIf="!flagFilter" class="country-name">{{selectedItemValue}}</div>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-template let-option pTemplate="item">
      <div class="country-item">
        <ng-container *ngIf="withFlags">
          <img [src]="findCountryImage(option[templateValue])">
        </ng-container>
        <div class="country-name">{{ templateValue ? option[templateValue] : option }}</div>
      </div>
    </ng-template>
  </p-dropdown>
</div>