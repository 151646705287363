import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { ToastMessage } from '../models/general.model';

@Injectable()
export class ToastService implements OnDestroy {

  private subs = new SubSink();
  private showToastmessage$: BehaviorSubject<ToastMessage> = new BehaviorSubject<ToastMessage>({} as ToastMessage);
  public readonly showToastmessage: Observable<ToastMessage> = this.showToastmessage$.asObservable();

  constructor() {
  }

  public setShowToastmessage(toastObject: ToastMessage) {
    this.showToastmessage$.next(toastObject);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
