import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CircleLine, StudentGoal } from 'src/app/core/models/goal.model';

@Component({
  selector: 'app-circle-line',
  templateUrl: './circle-line.component.html',
  styleUrls: ['./circle-line.component.scss']
})
export class CircleLineComponent implements OnInit {
  @ViewChild('circlesElement') public circlesElement: ElementRef = {} as ElementRef;
  @Input() circles: CircleLine[] = [];
  @Input() allowHover: boolean = false;
  @Input() allowHoverMain: boolean = false;
  @Output() circleChoice = new EventEmitter<{ circle: CircleLine }>()

  constructor() { }

  ngOnInit(): void {
  }

  chooseCircle(circle: CircleLine) {
    this.circleChoice.emit({ circle: circle });
  }
}
