import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {


  transform(value: any[], args: any): any[] {
    if (!value || !args || !args['term']) {
      return value;
    }

    const term: string = args['term'].toLocaleLowerCase();

    console.log('Search term:', term);

    const filteredValues = value.filter(item => {
      const contains = this.isContain(term, item);
      if (contains) {
        console.log('Match found in item:', item);
      }
      return contains;
    });

    return filteredValues;
  }

  isContain(term: string, item: any): boolean {
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        const propertyValue = item[key];
        if (typeof propertyValue === 'string') {
          if (propertyValue.toLocaleLowerCase().indexOf(term) !== -1) {
            return true;
          }
        } else if (typeof propertyValue === 'object' && propertyValue !== null) {
          if (this.isContain(term, propertyValue)) {
            return true;
          }
        }
      }
    }
    return false;
  }
}