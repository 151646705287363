
<app-block-viewer header="{{title}}" headerBlockClass="bg-cover " [headerBackgroundImage]="reverseGradient ? '' : '/assets/images/dashboard/gradient-sm-1.png'" blockClass="card"
[headerClass]="'justify-content-start' + (reverseGradient ? ' text-primary' : '') " >
  <div class="p-datepicker  shadow-none p-0" [ngClass]="{ 'reversed-gradient': reverseGradient, 'block-gradient' : !reverseGradient, 'availability-cal': showAvailability }">
    <div [class]="calHeaderClass">
      <div class="relative md:mr-2 font-sm">{{ getCurrentMonth() }}</div>
      <ng-container *ngIf="enableButtons">
        <button type="button" (click)="goBackwardMonth()" pripple="" class="p-ripple p-element p-datepicker-prev p-link ng-star-inserted">
          <span class="p-datepicker-prev-icon pi pi-chevron-left"></span>
          <span class="p-ink"></span>
        </button>
        <button (click)="goForwardMonth()" type="button" class="p-ripple p-element p-datepicker-next p-link">
          <span class="p-datepicker-next-icon pi pi-chevron-right"></span>
        </button>
      </ng-container>
    </div>
    <div class="table-container" #daysTableWrapper>
      <table class="p-datepicker-calendar">
        <thead>
          <tr>
            <ng-container *ngFor="let day of weekdays; let i = index;">
              <th class="cal-font-size">{{ day }}</th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="loaded">
            <ng-container *ngFor="let week of getWeeksInMonth(currentDate)">
              <tr>
                <ng-container *ngFor="let day of week">
                  <td class="cal-font-size cursor-pointer" (click)="onDayClicked(day.number)">
                    <ng-container *ngIf="isTodayDate(day.number); else elseBlock">
                      <span 
                      [ngClass]="{ 'active': selectedDay === day.number, 
                      'high-bg': isHighAvailability(day.number) && !isDayInThePast(day.number),
                      'low-bg': !isHighAvailability(day.number) && showAvailability && !isDayInThePast(day.number), 
                      'is-past': showAvailability && isDayInThePast(day.number), 
                      'no-bg': isDayOff(day.number), 
                      'circle': showAvailability ,
                      'today': !showAvailability,
                      'day-circle': getStatusArray(getTodaysLessons(day.number)).length > 0 || !showAvailability }" 
                      [class]="day.availability">
                        <div class="text" >{{day.number}}</div>
                        <ng-container *ngIf="(selectedDay === day.number) && showAvailability">
                          <div class="selected-check border-circle low-bg flex align-items-center justify-content-center absolute">
                          <i class="pi pi-check font-3xs"></i>
                        </div>
                        </ng-container>
                      </span>
                      
                    <ng-container *ngFor="let status of getStatusArray(getTodaysLessons(day.number)); let i = index;">
                      <div class="circle-me absolute" [style.top.px]="getItemsPositionTop(i)" [style.right.px]="getItemsPositionRight(i)">
                        <div class="circle-me counts border-round-3xl">
                          <div class="count border-circle" [ngClass]="status.status + '-gradient-lesson-bg'">
                            <div class="count-status"> {{status.count}}</div>
                          </div>
                        </div>
                      </div>
                  </ng-container>
                    </ng-container>
                    <ng-template #elseBlock>
                      <span *ngIf="day.number" 
                      [ngClass]="{ 'active': selectedDay === day.number, 
                      'high-bg': isHighAvailability(day.number) && !isDayInThePast(day.number),
                      'low-bg': !isHighAvailability(day.number) && showAvailability && !isDayInThePast(day.number), 
                      'is-past': showAvailability && isDayInThePast(day.number), 
                      'no-bg': isDayOff(day.number), 
                      'circle': showAvailability ,
                      'day-circle': getStatusArray(getTodaysLessons(day.number)).length > 0}" 
                      [class]="day.availability">
                      <div>{{ day.number }}</div>
                      <ng-container *ngIf="(selectedDay === day.number) && showAvailability">
                        <div class="selected-check border-circle low-bg flex align-items-center justify-content-center absolute">
                        <i class="pi pi-check font-3xs"></i>
                      </div>
                      </ng-container>
                    </span>
                    <ng-container *ngIf="day.number" >
                    <ng-container *ngFor="let status of getStatusArray(getTodaysLessons(day.number)); let i = index;">
                      <div class="circle-me absolute" [style.top.px]="getItemsPositionTop(i)" [style.right.px]="getItemsPositionRight(i)">
                        <div class="circle-me counts border-round-3xl">
                          <div class="count border-circle" [ngClass]="status.status + '-gradient-lesson-bg'">
                            <div class="count-status"> {{status.count}}</div>
                          </div>
                        </div>
                      </div>
                  </ng-container>
                </ng-container>
                    </ng-template>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</app-block-viewer>