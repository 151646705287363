<div class="content" [id]="componentId">
  <button class="shareButton main" (click)="toggleShareButtons()">
    <img src="/assets/icons/share-certificate.svg">
    <svg class="close" style="width:24px;height:24px" viewBox="0 0 24 24">
      <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
    </svg>
  </button>
  <button class="shareButton fb" (click)="share('FACEBOOK')">
    <img src="/assets/icons/facebook-share.svg" class="w-100">
  </button>
  <button class="shareButton tw ml-1" (click)="share('TWITTER')">
    <img src="/assets/icons/twitter.svg" class="w-100">
  </button>
  <button class="shareButton ig ml-1" (click)="share('LINKEDIN')">
    <img src="/assets/icons/linkedin-share.svg" class="w-100">
  </button>
</div>
