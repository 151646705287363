export interface AuthData {
    username: string;
    password: string;
}

export interface User {
    id: string,
    aspUserId?: string,
    email: string,
    personalEmail?: string,
    skype: string,
    firstName: string,
    lastName: string,
    phone?: string, // TODO MAKE STRING
    bdate?: string,
    dateOfBirth?: string,
    photo?: string,
    avatarUrl?: string,
    token?: string,
    role?: string,
    availability?: UserAvailability, // TODO API CALL
    off?: UserAvailabilityOff[], // TODO API CALL
    origin?: string,
    residence?: string,
    timeZone?: string,
    introduce?: string,
    hours_per_week: string, // TODO GET FROM AVAILABILITY (ADD TO UserAvailability)
    status: UserStatus.ACTIVE,
    teachingLanguage?: string,
    userName?: string,
    accSlitPayments?: boolean,
    lingoletteId: string
}

export enum UserStatus {
    ACTIVE = "Active"
}

export enum UserRole {
    TEACHER = "Teacher",
    STUDENT = "Student",
    NONE = "" // for initialization
}

export interface UserAvailability {
    id: string,
    type: UserAvailabilityType.OFF | UserAvailabilityType.AVAILABLE,
    mon: UserAvailabilityHours[],
    tue: UserAvailabilityHours[],
    wed: UserAvailabilityHours[],
    thu: UserAvailabilityHours[],
    fri: UserAvailabilityHours[],
    sat: UserAvailabilityHours[],
    sun: UserAvailabilityHours[],
}

export interface UserAvailabilityOff {
    id?: number,
    reason: string,
    period: UserAvailabilityOffDate,
    status: OffStatus
}

export enum OffStatus {
    PENDING = 'Pending',
    REJECTED = 'Rejected',
    ACCEPTED = 'Accepted'
}

export enum UserAvailabilityType {
    OFF = "off",
    AVAILABLE = "Available"
}

export interface UserAvailabilityHours {
    from: string,
    to : string
}

export interface UserAvailabilityOffDate {
    from: Date,
    to : Date
}

export interface RequestTrialRequest {
    firstName: string;
    lastName: string;
    contactNumber: string;
    email: string;
    nativeLanguage: string;
    wantToLearnLanguage: string;
    country: string;
    timeZone: string;
    numberOfStudents: number;
    findUs: string;
    availability: string;
    message: string;
}
