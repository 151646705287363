import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import { Language } from 'src/app/core/models/general.model';
import { LanguageLevel, Teacher, TeacherApplicationStep3 } from 'src/app/core/models/teacher.model';
import { GeneralService } from 'src/app/core/services/general.service';
import { TeacherApplicationService } from 'src/app/core/services/teacher-application.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent  implements OnInit {
  private subs = new SubSink();
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public teacherLanguagesSpeak: LanguageLevel[] = [];
  public teacherLanguagesTeach: LanguageLevel[] = [];
  public languageSpeakError: boolean = false;
  public languageTeachError: boolean = false;
  public teacher?: Teacher;
  public isLoading: boolean = true;
  public tryToSave: boolean = false;
  public languages: Language[] = this.generalService.languages;
  public availableLanguageLevels: any[] = this.generalService.getLanguageLevels();
  public filteredLanguageSpeakLevels: any[] = [];
  public filteredLanguageTeachLevels: any[] = [];
  disableSpeakLevelsDropdown: boolean[] = [true];
  disableTeachLevelsDropdown: boolean[] = [true];
  formChanged = false;

  teachLevelOptions = [
    {
      levelName: "I teach only beginner-level students (A1 - B1)",
      "showOnlyOnCode": [],
      "visible": true
    },
    {
      levelName: "I teach only advanced-level students (B2 - C2)",
      "showOnlyOnCode": [],
      "visible": true
    },
    {
      levelName: "I teach all levels (A1 - C2)",
      "showOnlyOnCode": [],
      "visible": true
    },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private teacherService: TeacherApplicationService,
    private generalService: GeneralService,
    private toast: ToastService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.teacherService.setCurrentStepIndex(2);

    this.subs.add(this.teacherService.getTeacherApplicationStep3().subscribe(res => {
      this.isLoading = false;
      this.createForm();
      this.updateStepFormValues(res);
      this.initFormChangedListener();
    }));

    this.subs.add(this.teacherService.submitOnMenuClickListener.subscribe(res => {
      if (res) {
        this.onSubmit();
      }
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.teacherService.setSubmitOnMenuClickListener(false);
  }

  get languagesSpeakForm(): UntypedFormArray {
    return this.form.get('languagesSpeakForm') as UntypedFormArray;
  }

  get languagesTeachForm(): UntypedFormArray {
    return this.form.get('languagesTeachForm') as UntypedFormArray;
  }

  /**
   * Initializes the form for the application
   *
   */
  createForm() {
    this.form = new UntypedFormGroup({
      methods: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      years: new UntypedFormControl(1, {
        validators: [Validators.required]
      }),
      languagesSpeakForm: new UntypedFormArray([]),
      languagesTeachForm: new UntypedFormArray([])
    });
  }

  /**
 * Updates the form values for the step 3 of the teacher application process
 *
 * @param {TeacherApplicationStep3} object - Object containing the updated values for the form
 *
 */
  updateStepFormValues(object: TeacherApplicationStep3) {

    this.form.get('methods')?.patchValue(object.teachingMethods);
    this.form.get('years')?.patchValue(object.yearsExperience);
    if (this.generalService.isNullishObject(object.languageSpeak)) {
      this.addLanguageSpeak()
    } else {
      this.filteredLanguageSpeakLevels = [];

      object.languageSpeak.forEach((element, index) => {
        const elementFound = this.availableLanguageLevels.filter(i => {
          return ((i.levelName === element.level.levelName));
        });
        element.level = elementFound[0];
        this.disableSpeakLevelsDropdown[index] = false;
        this.setfilteredLanguageSpeakLevels(index, element.language.code);
        this.languagesSpeakForm.push(this.fb.group(element))
      });
    }
    if (this.generalService.isNullishObject(object.languageTeach)) {
      this.addLanguageTeach();
    } else {

      // this.filteredLanguageTeachLevels = this.teachLevelOptions;
      object.languageTeach.forEach((element, index) => {

        const elementFound = this.teachLevelOptions.filter(i => {
          return ((i.levelName === element.level.levelName));
        });
        element.level = elementFound[0];

        // element.level = {
        //   levelName: element.levelName,
        // }

        console.log(element);
        this.disableTeachLevelsDropdown[index] = false;
        this.setfilteredLanguageTeachLevels(index);
        this.languagesTeachForm.push(this.fb.group(element))
      });
    }
  }

  /**
   * Sets the filtered language speak levels based on the language code
   *
   * @param {number} index - Index of the language in the array of languages
   * @param {string} [languageCode] - Code of the language to filter the levels for
   *
   */
  setfilteredLanguageSpeakLevels(index: number, languageCode?: string) {
    this.filteredLanguageSpeakLevels[index] = this.availableLanguageLevels.
      filter(i => {
        return ((i.showOnlyOnCode.length === 0) || (i.showOnlyOnCode.includes(languageCode)));
      });
      console.log(index, this.filteredLanguageSpeakLevels[index]);
  }

  /**
   * Sets the filtered language teach levels
   *
   * @param {number} index - Index of the language in the array of languages
   *
   */
  setfilteredLanguageTeachLevels(index: number, languageCode?: string) {
    
    this.filteredLanguageTeachLevels[index] = this.teachLevelOptions.
      filter(i => {
        return (i);
      });
      console.log(index, this.filteredLanguageTeachLevels[index]);
    // this.filteredLanguageTeachLevels[index] = this.availableLanguageLevels.
    //   filter(i => {
    //     return (i.visible);
    //   });
  }

  /**
   * Adds a new form group for spoken languages
   *
   * @throws {Error} If FormGroup or FormControl classes are not defined
   */
  addLanguageSpeak() {
    if (!UntypedFormGroup || !UntypedFormControl) {
      throw new Error('FormGroup or FormControl classes are not defined');
    }
    const group = new UntypedFormGroup({
      language: new UntypedFormControl('', Validators.required),
      level: new UntypedFormControl('', Validators.required),
      hasBusinessLevel: new UntypedFormControl(false)
    });
    this.languagesSpeakForm.push(group);
    this.disableSpeakLevelsDropdown[this.languagesSpeakForm.length - 1] = true;
  }

  /**
   * Removes a form group for spoken languages
   *
   * @param {number} index - Index of the form group in the form array
   */
  removeLanguageSpeak(index: number) {
    this.languagesSpeakForm.removeAt(index);
  }

  /**
   * Adds a new form group for taught languages
   *
   * @throws {Error} If FormGroup or FormControl classes are not defined
   */
  addLanguageTeach() {
    if (!UntypedFormGroup || !UntypedFormControl) {
      throw new Error('FormGroup or FormControl classes are not defined');
    }
    const group = new UntypedFormGroup({
      language: new UntypedFormControl('', Validators.required),
      level: new UntypedFormControl('', Validators.required),
      hasBusinessLevel: new UntypedFormControl(false)
    });
    this.languagesTeachForm.push(group);
    this.disableTeachLevelsDropdown[this.languagesTeachForm.length - 1] = true;
  }

  /**
   * Removes a form group for taught languages
   *
   * @param {number} index - Index of the form group in the form array
   */
  removeLanguageTeach(index: number) {
    this.languagesTeachForm.removeAt(index);
  }

  onSubmit() {
    this.tryToSave = true;
    this.languageSpeakError = this.form.controls.languagesSpeakForm.status === 'INVALID' ? true : false;
    this.languageTeachError = this.form.controls.languagesTeachForm.status === 'INVALID' ? true : false;

    if (!this.form.valid) {
      return;
    }

    const experienceValuesToSubmit = {
      languageSpeak: this.form.value.languagesSpeakForm,
      languageTeach: this.form.value.languagesTeachForm,
      yearsExperience: this.form.value.years,
      teachingMethods: this.form.value.methods,
    };

    this.subs.add(this.teacherService.updateAPITeacherApplicationStep3(experienceValuesToSubmit).subscribe(res => {
      console.log(res);
      if (res) {
        this.toast.setShowToastmessage({
          severity: 'success',
          summary: '',
          detail: 'Your Experience info were updated.'
        });
        this.router.navigateByUrl('/teacher/resume', { replaceUrl: true });
      }
    }));
  }

  ifFieldValid(field: string) {
    this.teacherService.setStepValid(2, this.form, 'teacher-experience-route');
    return ((this.form.get(field)?.invalid && this.form.get(field)?.touched) || (this.tryToSave && this.form.get(field)?.invalid))
  }

  goBack() {
    this.router.navigateByUrl('/teacher/education', { replaceUrl: true });
  }

  /**
  Handles the change in language selection for speaking.
  @param {Object} event - The event object containing the selected language code.
  @param {number} languageIndex - The index of the selected language in the list.
  @returns {void}
  */
  handleSpeakLanguageChange(event: { value: { code: string } }, languageIndex: number) {
    this.disableSpeakLevelsDropdown[languageIndex] = false;
    this.filteredLanguageSpeakLevels[languageIndex] = this.availableLanguageLevels.
      filter(i => {
        return (i.showOnlyOnCode.length === 0) || (i.showOnlyOnCode.includes(event.value.code));
      });
  }

  /**
  Handles the change in language selection for teaching.
  @param {Object} event - The event object containing the selected language code.
  @param {number} languageIndex - The index of the selected language in the list.
  @returns {void}
  */
  handleTeachLanguageChange(event: { value: { code: string } }, languageIndex: number) {
    this.disableTeachLevelsDropdown[languageIndex] = false;
    // this.filteredLanguageTeachLevels[languageIndex] = this.availableLanguageLevels.
    //   filter(i => {
    //     return (i.showOnlyOnCode.length === 0) || (i.showOnlyOnCode.includes(event.value.code));
    //   });
  }

  initFormChangedListener() {
    this.subs.add(this.form.valueChanges.subscribe(val => {
      if(this.form.dirty) {
        console.log(val);
        this.formChanged = true;
      }
    }));
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.formChanged && !this.tryToSave) {
      return new Observable((observer: any) => {
        this.confirmationService.confirm({
          header: '',
          key: 'stepLeaveConfirmation',
          message: 'Are you sure you want to leave this page? Your changes will be lost.',
          accept: () => {
            observer.next(true);
            observer.complete();
          },
          reject: () => {
            observer.next(false);
            observer.complete();
          }
        });
      });
    } else {
      return (true);
    }
  }
}
