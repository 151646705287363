import { Location } from '@angular/common';
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general.service';
import { TeacherApplicationService } from 'src/app/core/services/teacher-application.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { WindowService } from 'src/app/core/services/window.service';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';

@Component({
  selector: 'app-teacher-application',
  templateUrl: './teacher-application.component.html',
  styleUrls: ['./teacher-application.component.scss'],
  providers: []
})
export class TeacherApplicationComponent implements OnInit, AfterViewInit, AfterContentChecked {

  @ViewChild('leftSideTeacherApplication') public leftSideTeacherApplication: any;
  private subs = new SubSink();
  public step: string = "";
  public stepIndex: number = 0;
  public totalSteps: number = 0;
  public applicationProgress: boolean = false;
  public isTablet = false;
  headerText = 'Withdraw your Application';
  canDisplayConfirmDialog = false;

  constructor(
    private location: Location,
    private teacherService: TeacherApplicationService,
    private windowService: WindowService,
    private generalService: GeneralService,
    private confirmationService: ConfirmationService,
    private changeDetector: ChangeDetectorRef,
    private element: ElementRef,
    private router: Router,
  ) { }

  ngOnInit(): void {
    const width = this.windowService.windowRef.innerWidth;
    this.totalSteps = this.teacherService.stepsValid.length;

    this.subs.add(this.generalService.deviceKind.subscribe(res => {
      this.isTablet = res.is768;
      // this.prepareStepsCounterText();
    }));

    this.subs.add(this.teacherService.currentStepIndex.subscribe(res => {
      this.stepIndex = res + 1;
      this.step = this.teacherService.getStepsTitle(res);
    }));

    this.subs.add(this.teacherService.showApplicationProgressMenu.subscribe((shouldShow: boolean) => {
      if (shouldShow) {
        this.applicationProgress = true;
      } else {
        this.applicationProgress = false;
      }
    }));

    this.subs.add(this.teacherService.showWithdrawConfirmationDialog.subscribe((shouldShowDialog: boolean) => {
      if (shouldShowDialog) {
        this.canDisplayConfirmDialog = true;
        this.confirmWithdraw();
      }
    }));
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  prepareStepsCounterText() {
    // put div contents into another divs contents for mobile view
    const firstDivContent = this.element.nativeElement.querySelector(".step-heading-count-steps") as HTMLDivElement;
    const firstDivConten = this.element.nativeElement.querySelector('.item-active-teacher');
    if (firstDivConten && this.isTablet) {
      firstDivConten.appendChild(firstDivContent)
    } else {
      firstDivConten.removeChild(firstDivConten.firstDivContent);
    }
  }

  confirmWithdraw() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to withdraw your application?',
      dismissableMask: true,
      accept: () => {
        this.afterAcceptWithDrawApplication();
      },
      reject: () => {
        console.log('rejected');
      }
    });
  }

  afterAcceptWithDrawApplication() {
    this.subs.add(this.teacherService.withdrawnTeacherApplication().subscribe((response: boolean) => {
      if (response) {
        this.teacherService.setShouldRetrieveTeacherStatus(true);
        this.router.navigate(['/teacher/status']);
      }
    }));
  }

}
