<div class="certificates">
    <div class="text-0 font-2xl font-bold">Certificates</div>
    <div class="my-5"></div>
    <div class="flex">
        <div class="col-fixed">
            <button pbutton="" type="button" class="p-element p-button-outlined w-7rem p-button-rounded mb-2 button-outlined p-button-sm p-button p-component py-1 pl-2"><div class="flex align-items-center gap-1" (click)="selectLanguage('all')"><img height="18" src="/assets/icons/flag.svg" class="ng-star-inserted"><span class="font-sm mx-4">All</span></div></button>
        </div>
        <p-carousel [value]="languages" [numVisible]="5" [numScroll]="5" [circular]="false" [responsiveOptions]="responsiveOptions" class="w-100">
            <ng-template let-language pTemplate="item">
                <button pbutton="" type="button" class="p-element p-button-outlined w-7rem p-button-rounded mt-2 button-outlined p-button-sm p-button p-component py-1 pl-1"><div class="flex align-items-center gap-1" (click)="selectLanguage(language)"><img height="20" src="/assets/icons/languages/{{ language }}.svg" class="ng-star-inserted"><span class="font-sm mx-3">{{ language }}</span></div></button>
            </ng-template>
        </p-carousel>
    </div>

    <div class="card block-gradient mt-3">
        <div class="text-primary font-lg font-semibold pl-4">Latest</div>
        <p-carousel *ngIf="certificates && certificates.length > 0; else noLatest" [value]="certificates.slice(-3)" [numVisible]="1" [numScroll]="1" [circular]="false">
            <ng-template let-certificate pTemplate="item">
                <div class="m-2 px-3 flex align-items-center">
                    <div class="relative w-6 mb-3">
                        <img [src]="certificate.type == 'completion'? '/assets/images/dashboard/completion.png': '/assets/images/dashboard/attendance.png'" class="w-full"/>
                        <img src="/assets/images/dashboard/new.svg" alt="" class="new-tag">
                    </div>
                    <div class="text-primary font-bold font-2xl">
                        {{ certificate.language }} {{ certificate.level }} Certificate 
                        <br> of {{ certificate.type | titlecase }}
                        <br>
                        <div class="mt-4 pill-bg pt-1" style="opacity: 0;">
                            <button class="mr-2 shareButton" (click)="viewPdf(certificate.level, certificate.language, certificate.type)">
                                <img src="/assets/icons/view-file.svg">
                            </button>
                            <app-share-button [topic]="'MyLingoTrip Certificate'"  [componentId]="'Certificate'+certificate.id"></app-share-button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-carousel>
        <ng-template #noLatest>
            <div class="flex justify-content-center align-items-center py-5">
                <img src="/assets/icons/certificate-gradient.svg" class="">
                <div class="text-primary font-bold font-3xl ml-3">You haven’t received<br>
                    any certifications yet</div>
            </div>
        </ng-template>
    </div>
    <div class="card block-gradient relative">
        <p-tabView>
            <p-tabPanel header="All">
                <p-table [value]="certificates" *ngIf="certificates.length > 0; else noCertificates">
                    <ng-template pTemplate="header">
                        <tr class="font-sm text-blue">
                            <th>Level</th>
                            <th>Hours</th>
                            <th>Language</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-certificate>
                        <tr class="font-sm">
                            <td class="flex py-2">
                                <div>
                                    <img src="/assets/icons/certificate-gradient.svg" class="icon-cer">
                                    <span style="vertical-align: super;">{{certificate.level}}</span>
                                </div>
                            </td>
                            <td class="py-2">{{certificate.hours}} hours</td>
                            <td class="py-2">{{certificate.language}}</td>
                            <td class="py-2">{{ certificate.type | titlecase }}</td>
                            <td class="py-2">
                                <button class="mr-2 shareButton" (click)="viewPdf(certificate.level, certificate.language, certificate.type, i)">
                                    <!-- show spinner when isDownloadLoading = true -->
                                    <ng-container *ngIf="isDownloadLoading[i]; else downloadButton">
                                        <img src="/assets/images/load.gif" width="30">
                                    </ng-container>
                                    <ng-template #downloadButton>
                                        <img src="/assets/icons/view-file.svg">
                                    </ng-template>
                                </button>
                                <app-share-button class="hidden" [topic]="'MyLingoTrip Certificate'" [link]="shareUrl+certificate.urlParam" [componentId]="'certificate'+certificate.id"></app-share-button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <ng-template #noCertificates>
                    <div class="flex justify-content-center align-items-center py-5">
                        <img src="/assets/icons/certificate-gradient.svg" class="">
                        <div class="text-primary font-bold font-base ml-3">No Certifications to show</div>
                    </div>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="of Completion">
                <p-table [value]="completionCertificates" *ngIf="completionCertificates.length > 0; else noCompletionCertificates">
                    <ng-template pTemplate="header">
                        <tr class="font-sm text-blue">
                            <th>Level</th>
                            <th>Hours</th>
                            <th>Language</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-certificate>
                        <tr class="font-sm">
                            <td class="flex py-2">
                                <div>
                                    <img src="/assets/icons/certificate-gradient.svg" class="icon-cer">
                                    <span style="vertical-align: super;">{{certificate.level}}</span>
                                </div>
                            </td>
                            <td class="py-2">{{certificate.hours}} hours</td>
                            <td class="py-2">{{certificate.language}}</td>
                            <td class="py-2">{{certificate.type}}</td>
                            <td class="py-2">
                                <button class="mr-2 shareButton" (click)="view(certificate.level, certificate.language)">
                                    <img src="/assets/icons/view-file.svg">
                                </button>
                                <div class="content">
                                    <button class="shareButton main" (click)="toggleShareButtons()">
                                        <img src="/assets/icons/share-certificate.svg">
                                        <svg class="close" style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
                                    </button>
                                    <button class="shareButton fb" (click)="toggleShareButtons()">
                                        <img src="/assets/icons/facebook-share.svg" class="w-100">
                                    </button>
                                    <button class="shareButton tw ml-1" (click)="toggleShareButtons()">
                                        <img src="/assets/icons/twitter.svg" class="w-100">
                                    </button>
                                    <button class="shareButton ig ml-1" (click)="toggleShareButtons()">
                                        <img src="/assets/icons/linkedin-share.svg" class="w-100">
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <ng-template #noCompletionCertificates>
                    <div class="flex justify-content-center align-items-center py-5">
                        <img src="/assets/icons/certificate-gradient.svg" class="">
                        <div class="text-primary font-bold font-base ml-3">No Certifications to show</div>
                    </div>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="of Attendance">
                <p-table [value]="attendanceCertificates" *ngIf="attendanceCertificates.length > 0; else noAttendanceCertificates">
                    <ng-template pTemplate="header">
                        <tr class="font-sm text-blue">
                            <th>Level</th>
                            <th>Hours</th>
                            <th>Language</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-certificate>
                        <tr class="font-sm">
                            <td class="flex py-2">
                                <div>
                                    <img src="/assets/icons/certificate-gradient.svg" class="icon-cer">
                                    <span style="vertical-align: super;">{{certificate.level}}</span>
                                </div>
                            </td>
                            <td class="py-2">{{certificate.hours}} hours</td>
                            <td class="py-2">{{certificate.language}}</td>
                            <td class="py-2">{{certificate.type}}</td>
                            <td class="py-2">
                                <button class="mr-2 shareButton" (click)="view(certificate.level, certificate.language)">
                                    <img src="/assets/icons/view-file.svg">
                                </button>
                                <div class="content">
                                    <button class="shareButton main" (click)="toggleShareButtons()">
                                        <img src="/assets/icons/share-certificate.svg">
                                        <svg class="close" style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
                                    </button>
                                    <button class="shareButton fb" (click)="toggleShareButtons()">
                                        <img src="/assets/icons/facebook-share.svg" class="w-100">
                                    </button>
                                    <button class="shareButton tw ml-1" (click)="toggleShareButtons()">
                                        <img src="/assets/icons/twitter.svg" class="w-100">
                                    </button>
                                    <button class="shareButton ig ml-1" (click)="toggleShareButtons()">
                                        <img src="/assets/icons/linkedin-share.svg" class="w-100">
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <ng-template #noAttendanceCertificates>
                    <div class="flex justify-content-center align-items-center py-5">
                        <img src="/assets/icons/certificate-gradient.svg" class="">
                        <div class="text-primary font-bold font-base ml-3">No Certifications to show</div>
                    </div>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
        <!-- <button pbutton="" type="button" class="p-element p-button-outlined p-button-rounded button-outlined p-button-sm p-button p-component py-1 btn-modify"><div class="flex align-items-center gap-1" (click)="showDialog()">
            <span class="font-sm">Request Modification</span></div></button> -->
    </div>
</div>
<p-dialog header="Header" [(visible)]="visible" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>
</p-dialog>