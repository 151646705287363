<ng-container>
    <div class="content content--canvas"></div>
    <div class="animated-background w-embed">
        <div class="animatedwrapper">
            <div class="dot dot--one"></div>
            <div class="dot dot--two"></div>
        </div>
    </div>
    <div class="auth-container">
        <div
            class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
            <div class="auth-route">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</ng-container>