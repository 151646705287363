import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Import Firebase core functions
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider, ReCaptchaV3Provider } from 'firebase/app-check';
const firebaseApp = initializeApp(environment.firebase);
// Initialize Firebase App Check
const provider = new ReCaptchaV3Provider("6Le24q4hAAAAALsdgBnyhXQxTU_Nqe1r-Jj3kHyX"); // Replace XXXXXXXXXXXXX with your registered debug token

// const appCheck = initializeAppCheck(undefined, { provider, isTokenAutoRefreshEnabled: true });;
// const provider = new ReCaptchaEnterpriseProvider("6Le24q4hAAAAALsdgBnyhXQxTU_Nqe1r-Jj3kHyX"); // Replace XXXXXXXXXXXXX with your registered debug token
// initializeAppCheck(firebaseApp, { appCheckProvider: appCheck, isTokenAutoRefreshEnabled: true });
// E9EA4811-F061-40E8-9728-345C5650C67A
if (environment.production) {
  enableProdMode();
  window.console.log = () => {};
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
