<div class="" *ngIf="show()">
    <div class="shadow-2 border-round  mb-3 h-full flex-column justify-content-between flex border-round-lg border-2 
    bg-blue-50 border-blue-500">
        <div class="p-3">
            <div class="flex align-items-center justify-content-between line-height-1 mb-3">
                <div class="flex align-items-center justify-content-center ">
                    <span class="inline-flex border-circle align-self-start align-items-start justify-content-center bg-green-100 mr-3">
                        <img src="/assets/icons/switch-premium.png" class="w-2rem">
                    </span>
                    <div class="flex flex-column gap-2">
                        <span class="text-900 font-medium text-2xl">Consider upgrading?</span>
                        <span class="text-sm">Unlock Exclusive Access to our <span
                            class="font-semibold">AI Chat</span>, your personal learning
                            assistant! </span>
                    </div>
                </div>

                <button pbutton="" (click)="show.set(false)" pripple="" type="button" icon="pi pi-times text-sm"
                    class="p-element p-ripple align-self-start w-2rem h-2rem p-button-rounded p-button-secondary p-button-text text-600 p-button p-component p-button-icon-only"><span
                        class="p-button-icon pi pi-times text-sm" aria-hidden="true"></span><span class="p-ink"
                        style="height: 32px; width: 32px; top: -5px; left: 9.8125px;"></span></button>
            </div>



            <div class="flex flex-column sm:flex-row align-items-center justify-content-between gap-2">
                <ng-container *ngFor="let pkg of getPackages(); let i = index">
                    <div class="surface-card border-round-lg w-full mx-1 border-2 p-1 line-height-1 flex 
                    align-items-center cursor-pointer border-blue-500"
                        [ngClass]="{'surface-border': selectedPackageType !== pkg.type, 'border-primary': selectedPackage === pkg.type}"
                        (click)="selectPackageType(pkg.type)">
                        <p-radioButton name="packageType" [value]="pkg.type" [(ngModel)]="selectedPackageType"
                            styleClass="mr-3"></p-radioButton>
                        <div class="mr-4 md:mr-8 line-height-1">
                            <div class="font-medium text-base">{{ pkg.type }}</div>
                            <!-- <span class="text-sm">Cost: {{ pkg.costPlus }} USD</span> -->
                        </div>
                        <!-- <div class="border-round border-1 p-1 ml-auto flex w-min"
                            [ngClass]="{'bg-green-400 text-green-900': selectedPackageType !== pkg.type, 'bg-primary-reverse': selectedPackageType === pkg.type}">
                            
                            <span class=" font-bold font-2xs py-1 px-2" style="border-radius: 10px;">Best Value</span>
                        </div> -->
                    </div>
                </ng-container>
            </div>

            <div class="flex flex-column justify-content-between md:flex-row mt-3">
                <div class="flex-grow-1 lg:w-min">
                    <div class="text-900 font-bold text-xl mb-2">

                        <ng-container
                            *ngIf="getSelectedPackage()?.type === packageTypeEnum.FLEX; else notFlexFirstTitle">

                            Switch to {{ getSelectedPackage()?.type }} Package

                        </ng-container>

                        <ng-template #notFlexFirstTitle>
                            Go Premium for the Ultimate Learning Experience
                        </ng-template>

                    </div>
                    <!-- <div class="text-500 text-xl">Id diam vel quam elementum.</div> -->
                </div>
                <div
                    class="flex align-items-center w-auto lg:w-10rem justify-content-center lg:justify-content-end my-3 lg:mt-0">
                    <span class="font-bold text-2xl price-blue">+{{ getSelectedPackage()?.costPlus }}€</span>
                    <!-- <span class="text-700 text-xl ml-2 line-height-3">per month</span> -->
                </div>
            </div>

            <!-- <div class="text-900 my-3 text-xl font-medium">{{getSelectedPackage()?.costPlus}} €.</div>
            <p class="mt-0 mb-3 text-700 line-height-3">
                Eget sit amet tellus cras adipiscing enim. At quis risus sed vulputate odio. Proin libero nunc consequat
                interdum varius sit amet.
            </p> -->

            <!-- <div class="text-900 font-medium text-xl pb-1">Features</div> -->


            <div class="flex flex-column align-items-center justify-space-between md:flex-row">

                <div class="flex flex-column justify-space-between md:flex-row">
                    <ul class="list-none p-0 m-0 flex-grow-1 font-sm font-medium">
                        <li class="flex align-items-center my-1"><img src="/assets/images/dashboard/check.svg" alt=""
                                class="w-1rem mr-2">
                            <span *ngIf="getSelectedPackage()?.type!='Regular'">Expires in
                                +{{getSelectedPackage()?.expiresPlus}}</span>
                        </li>
                        <li class="flex align-items-center my-1"><img src="/assets/images/dashboard/check.svg" alt=""
                                class="w-1rem mr-2">
                            <span>
                                {{getSelectedPackage()?.cancelation}} hours cancellation policy
                            </span>
                        </li>
                        <li class="flex align-items-center my-1"><img src="/assets/images/dashboard/check.svg" alt=""
                                class="w-1rem mr-2">
                            <span *ngIf="getSelectedPackage()?.type!='Regular'">Exclusive Access to our <span
                                    class="font-semibold">AI Chat</span></span>
                        </li>
                    </ul>
                </div>

                <div
                    class="mb-1 mt-2 sm:mt-0 sm:ml-auto text-right border-round-lg align-self-center sm:align-self-end">
                    <button pbutton="" pripple="" (click)="switchBackToPackageType()" type="button"
                        icon="pi pi-arrow-right" iconpos="right" label="More"
                        class="p-element p-ripple p-button-rounded blue-gradient-btn p-button p-component">
                        <span class="p-button-icon p-button-icon-right pi pi-arrow-right" aria-hidden="true"></span>
                        <span class="p-button-label">Switch to {{ getSelectedPackage()?.type }}</span>
                        <span class="p-ink"></span>
                    </button>
                </div>

            </div>

        </div>

    </div>
</div>