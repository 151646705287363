import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, AfterViewInit {

  @ViewChild('notFoundEl', { static: true }) notFoundEl: ElementRef = {} as ElementRef;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.addLottiePlayerGraphic(this.notFoundEl);
  }

  private addLottiePlayerGraphic(el: ElementRef): void {
    const lottiePlayer = `<div  class="animation-wrapper"><lottie-player src="https://assets3.lottiefiles.com/packages/lf20_aaesnvcw.json" background="transparent" 
    speed="1" style="width: 100%; max-height: 100%;" autoplay loop></lottie-player></div>`;
    el.nativeElement.innerHTML = lottiePlayer;
  }
}
