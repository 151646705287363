

  <ul class="list-none p-0 m-0">
    <li class="flex flex-column align-items-start md:align-items-center md:flex-row question-wrapper py-1 px-2 border-bottom-2 surface-border flex-wrap">
      <div class="md:col-8"><p class="question-title"><span *ngIf="questionIndexNumber" class="mr-4">{{questionIndexNumber}}.</span>{{questionText}}</p></div>
      <div class="md:col-4 mt-2 md:mt-0 flex justify-content-end gap-3">
        <img (click)="onRatingSelected(1)" [ngClass]="{'selected': rating === 1}" class="rating-icon" src="/assets/icons/classroom/rate/sad.svg" />
        <img (click)="onRatingSelected(2)" [ngClass]="{'selected': rating === 2}" class="rating-icon" src="/assets/icons/classroom/rate/angry.svg" />
        <img (click)="onRatingSelected(3)" [ngClass]="{'selected': rating === 3}" class="rating-icon" src="/assets/icons/classroom/rate/bad-mood.svg" />
        <img (click)="onRatingSelected(4)" [ngClass]="{'selected': rating === 4}" class="rating-icon" src="/assets/icons/classroom/rate/smile.svg" />
        <img (click)="onRatingSelected(5)" [ngClass]="{'selected': rating === 5}" class="rating-icon" src="/assets/icons/classroom/rate/happy.svg" />
      </div>
    </li>
  </ul>
  