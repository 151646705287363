<div id="lesson-{{lesson.id}}" style="position: relative;">
    <div id="lesson-content-{{lesson.id}}">
        <app-lesson-popup (lessonDeleted)="deleteLesson($event)" (closeLessonPopup)="closePopup()" *ngIf="showLessonPopup"  [lesson]="lesson"></app-lesson-popup>
    </div>
    <div *ngIf="showMain" class="lesson hvr-grow-shadow" (click)="showLessonPopup = !showLessonPopup">
        <div class="title" [style.background]="getLessonColor(lesson.status)">
            {{lesson.status}}
        </div>
        <div class="lesson-date">
            {{lesson.startingDate | date: 'dd/MM/yyyy'}}
        </div>
        <div class="seperator-horizontal"></div>
        <div class="hours-duration">
            <div>{{lesson.startingDate.getHours()}}:{{lesson.startingDate.getMinutes()}}</div>
            <div class="seperator-vertical"></div>
            <div>{{convertDuration(lesson.duration)}}</div>
        </div>
        <div class="copy-lesson" [style.background]="getLessonColor(lesson.status)"></div>
        <div class="copy-lesson-2"></div>
    </div>
</div>