<div  *ngIf="applicationProgress">
    <div class="profile-header" (click)="backClicked()">
        <div class="back-button">
        </div>
    </div>
    <div id="teacher" class="profile grid grid-nogutter md:mt-3 pb-6 flex-column md:flex-row">
        <div class="col md:col-3" #leftSideTeacherApplication>
            <strong class="step-heading-left fl-typo s14-24">Application Progress</strong>
            <app-teacher-menu></app-teacher-menu>
        </div>
        <div class="col md:col-9 px-3 pt-3  sm:pt-0 sm:pl-3 ">
            <div class="heading-steps flex justify-content-between">
                <strong class="step-heading-right fl-typo s18-24" *ngIf="step">{{step}}</strong>
                <div class="step-heading-count-steps relative">{{stepIndex}} <span>/{{totalSteps}}</span> 
                    <span class="step-heading-count-steps-subtitle"> Steps</span></div>
            </div>
            <router-outlet [routerOutletHelperClass]="'blue-border'"></router-outlet>
        </div>
    </div>
</div>
<div  *ngIf="!applicationProgress">
    <app-status></app-status>
</div>
<!-- primeng confirmation dialog -->
<p-confirmDialog #cd class="cd-confirm-dialog" 
defaultFocus="none"
[breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '640px'}" 
[baseZIndex]="10000"
rejectIcon="pi pi-times">
<ng-template pTemplate="header">
    <div class="text-center flex align-items-center flex-column">
    <img src="/assets/icons/warning-sign.svg" class="pr-3" />
    <h3>{{headerText}}</h3>
    </div>
</ng-template>
<ng-template pTemplate="footer">
    <p-button styleClass="cd-dialog-btn-reject" icon="pi pi-times" label="No" (click)="cd.reject()"></p-button>
    <p-button styleClass="cd-dialog-btn-accept" icon="pi pi-check" label="Yes" (click)="cd.accept()"></p-button>
</ng-template>
</p-confirmDialog>
<!-- primeng confirmation dialog -->


<p-confirmDialog #cda key="stepLeaveConfirmation" class="cd-confirm-dialog" 
defaultFocus="none"
[breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '640px'}" 
[baseZIndex]="10000"
rejectIcon="pi pi-times"
[closable]="false">
<ng-template pTemplate="footer">
    <p-button styleClass="cd-dialog-btn-reject" icon="pi pi-times" label="No" (click)="cda.reject()"></p-button>
    <p-button styleClass="cd-dialog-btn-accept" icon="pi pi-check" label="Yes" (click)="cda.accept()"></p-button>
</ng-template>
</p-confirmDialog>


