import { Homework } from "./homework.model";
import { LessonBreakdown } from "./rating.model";
import { User } from "./user.model";

export interface Report {
    id: string,
    title: string,
    student: User[],
    period: ReportPeriod,
    lessonRatings: LessonBreakdown[],
    correspondingHomeworks: Homework[],
    note: string,
    dateCreated: Date
}

export interface ReportPeriod {
    from: Date,
    to: Date
}

export enum Statistic {
    DIVISION = 'Division',
    OVERVIEW = 'Overview',
    PROGRESS = 'Progress',
    HOMEWORK = 'Homework'
}

export interface getReportRequest {
    classId: number,
    studentIds: string[],
    start: string,
    end: string
}

export interface CreateReportRequest {
    classroomId: number,
    title: string,
    level: string,
    studentIds: string[],
    period: {
        from: string,
        to: string
    },
    note: string,
    dateCreated: string
}

export const dummyReportRequests: any[] = [
    {
      classroomId: 123,
      title: "Report 1 #KI16",
      level: "A1",
      studentIds: ["1", "2", "3"],
      period: {
        from: "2022-01-01",
        to: "2022-01-31"
      },
      note: "This is a sample report",
      dateCreated: "2023-02-01"
    },
    {
      classroomId: 456,
      title: "Report 2 #KI15",
      level: "A2",
      studentIds: ["4", "5", "6"],
      period: {
        from: "2022-02-01",
        to: "2022-02-28"
      },
      note: "This is another sample report",
      dateCreated: "2022-03-01"
    }
  ];