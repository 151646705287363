@defer(on viewport) {
<app-block-viewer [header]="'Send from Library to Chat'"
    headerBackgroundImage="/assets/images/dashboard/gradient-sm-1.png" blockClass="border-radius-bottom-10 mb-0"
    headerBlockClass="py-1 border-round-lg bg-cover " containerClass="bg-white px-3 py-2 relative"
    [headerClass]="'justify-content-center my-0'"
    [headerTextClass]="'font-base font-semibold justify-content-center capitalize'" [showCloseDialogIcon]="true"
    (closeDialogEvent)="onDialogClose()">


    <app-library></app-library>


   
    <div class="dialog-footer fixed bottom-0 left-0 w-full h-3rem">

      
        <div class="relative  h-full">
            <div class="surface-section h-full flex align-items-center justify-content-center">

                @if (checkedFilesToUpload().length === 0) {
                    <div class=" surface-section ng-star-inserted">
                        <div class="blue-bg-gradient-1 text-white p-1 px-3 flex justify-content-center align-items-center flex-wrap shadow-2"
                            style="border-radius: 8px;">
                            <div class="font-bold"></div>
                            <div class="align-items-center flex"><span class="line-height-3 hidden lg:block">
                                Please select files to send to chat.
                            </span>
                            </div>
                        </div>
                    </div>
                } @else {
                    <button pButton pRipple [rounded]="true" severity="info" type="button" label="Send to Chat"
                    icon="pi pi-file" iconPos="right"
                    class="p-button-sm p-button-raised p-button-rounded blue-gradient-btn" styleClass=""
                    (click)="onSubmitFilesToUpload()"></button>
                }

               
            </div>
        </div>
    </div>

</app-block-viewer>
}@placeholder {
<span>loading...</span>
}