<div class="circles" #circlesElement>
    <div *ngFor="let circle of circles; let i =index; let isLast = last"
        [ngClass]="allowHover? 'circle hoverit': allowHoverMain?'hoverit-main circle': 'circle'"
        [style.color]="circle.color" [style.background]="circle.background" (click)="chooseCircle(circle)">
        {{circle.text}}
        <div *ngIf="!isLast" class="line" [style.width]="circle.width" [style.borderTop]="circle.lineStyle">

        </div>
        <div class="extra-text" [style.marginLeft]="circle.extraTextML">
            {{circle.extraText}}
        </div>
    </div>
</div>