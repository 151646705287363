import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LeftMenuItem } from 'src/app/core/models/general.model';
import { LayoutService } from 'src/app/core/services/layout.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-mobile-left-menu',
  templateUrl: './mobile-left-menu.component.html',
  styleUrls: ['./mobile-left-menu.component.scss']
})
export class MobileLeftMenuComponent implements OnInit {

  private subs = new SubSink();
  @Input() menuItems: LeftMenuItem[] = [];
  @Input() hideRightSideButton = false;
  @Output() onMenuUrlChange = new EventEmitter();
  currentIndex = 0;
  currentTitle = '';
  constructor(
    private router: Router,
    private layoutService: LayoutService,
    ) { }

  ngOnInit(): void {
    
    this.subs.add(this.layoutService.currentMenuIndex.subscribe(res => {

      console.log(res);
    }));
  }

  prevMenuItem() {
    this.currentIndex = Math.max(0, this.currentIndex - 1);
    this.layoutService.setCurrentMenuIndex(this.currentIndex);
    // this.router.navigateByUrl(this.menuItems[this.currentIndex].url);
    this.onMenuUrlChange.emit(this.menuItems[this.currentIndex].url);
  }
  
  nextMenuItem() {
    const nextIndex = Math.min(this.menuItems.length - 1, this.currentIndex + 1)
    if (this.menuItems[nextIndex].disabled) {
      return false;
    } else {

      this.currentIndex = nextIndex;
      this.layoutService.setCurrentMenuIndex(this.currentIndex);
      this.currentTitle = (this.menuItems[this.currentIndex].title);
      this.onMenuUrlChange.emit(this.menuItems[this.currentIndex].url);
      return false;
    }
    // this.router.navigateByUrl(this.menuItems[this.currentIndex].url);
  }

  hasPreviousItem() {
    return this.currentIndex !== 0;
  }
}
