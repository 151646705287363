<div class="review md:mt-3">
    <div class="review-section section-bg-gradient p-3">
      <div class="review-section-title">General Info</div>
      <div class="review-section-content flex flex-column md:flex-row" *ngIf="teacherApplication.step1">
        <div class="col-25">
          <div class="info-title subtitle">First Name</div>
          <div class="info">
            {{teacherApplication.step1.firstName}}
          </div>
        </div>
        <div class="col-25">
          <div class="info-title subtitle">Last Name</div>
          <div class="info">
            {{teacherApplication.step1.lastName}}
          </div>
        </div>
        <div class="col-50">
          <div class="info-title subtitle">Preffered Name</div>
          <div class="info">
            {{teacherApplication.step1.prefferedName}}
          </div>
        </div>
        <div class="col-25">
          <div class="info-title subtitle">Country of Origin</div>
          <div class="info" *ngIf="teacherApplication.step1.countryOrigin">
            {{teacherApplication.step1.countryOrigin.name}}
          </div>
        </div>
        <div class="col-25">
          <div class="info-title subtitle">Country of Residency</div>
          <div class="info" *ngIf="teacherApplication.step1.countryResidency">
            {{teacherApplication.step1.countryResidency.name}}
          </div>
        </div>
        <div class="col-25">
          <div class="info-title subtitle">Date of Birth</div>
          <div class="info">
            {{teacherApplication.step1.birth | date: 'dd-MM-yyy'}}
          </div>
        </div>
        <div class="col-25">
          <div class="info-title subtitle">Timezone</div>
          <div class="info">
            {{teacherApplication.step1.timeZone}}
          </div>
        </div>
        <div class="grid"></div>
        <div class="grid grid-nogutter flex-column">
          <div class="col-12 info-title subtitle">Native Languages</div>
          <div class="info col-12 grid-nogutter">
            <div
              class="col"
              *ngFor="let nativeLanguage of teacherApplication.step1.nativeLanguages"
            >
              {{nativeLanguage['native'].name}}
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="review-section section-bg-gradient p-3">
      <div class="review-section-title">Contact Info</div>
      <div class="review-section-content flex flex-column md:flex-row" *ngIf="teacherApplication.step1">
        <div class="grid w-100">
        <div class="col md:col-5">
          <div class="subtitle">E-mail</div>
          <div class="info">
            {{teacherApplication.step1.email}}
          </div>
        </div>
        <div class="col md:col-4">
          <div class="subtitle">Phone</div>
          <div class="info">
            {{teacherApplication.step1.phoneCode}}
            {{teacherApplication.step1.phone}}
          </div>
        </div>
        <div class="col md:col-3">
          <div class="subtitle">Skype</div>
          <div class="info">
            {{teacherApplication.step1.skype}}
          </div>
        </div>
      </div>
      </div>
      <div class="review-section">
        <div class="review-section-title">Education</div>
        <div class="review-section-content" *ngIf="teacherApplication.step2">
          <div
            *ngFor="let education of teacherApplication.step2.education; let ind = index;"
            class="flex flex-column md:flex-row flex-auto grid w-100"
          >
            <div class="col md:col-4">
              <div class="subtitle">Degree</div>
              <div class="info">
                {{education.name}}
              </div>
            </div>
            <div class="col md:col-4">
              <div class="subtitle">Institute</div>
              <div class="info">
                {{education.institute}}
              </div>
            </div>
  
            <div class="col md:col-4">
              <div class="subtitle">Degree / Diploma</div>
              <div class="info">
                <div
                  class="file-path"
                  *ngFor="let file of education.fileUploads;"
                >
                  <img
                    src="/assets/icons/education-degree-white.svg"
                    class="close-img close-img-abs hvr-glow"
                  />
                  <span
                    class="file-path-text font-base"
                    >{{(this.generalService.getFileName2(file.filePath))}}</span
                  >
                  <!-- <img src="/assets/icons/trash-white.svg" alt="trash-white.svg" class="delete-file-icon-img hvr-glow"> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="review-section section-bg-gradient p-3">
      <div class="review-section-title">Experience & Skills</div>
      <div class="review-section-content grid" *ngIf="teacherApplication.step3">
        <div class="col-12 sm:col-6">
          <div class="subtitle">Teaching Language</div>
          <div
            class="info flex"
            *ngFor="let experience of teacherApplication.step3.languageTeach"
          >
            <div class="col-50">
              {{experience.language.name}}
            </div>
            <div class="col-50">
              {{experience.level.levelName}}
            </div>
          </div>
        </div>
        <div class="col-12 sm:col-6">
          <div class="subtitle">Speaking Language</div>
          <div
            class="info flex"
            *ngFor="let experience of teacherApplication.step3.languageSpeak"
          >
            <div class="col-50">
              {{experience.language.name}}
            </div>
            <div class="col-50">
              {{experience.level.levelName}}
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="col md:col-12">
            <div class="subtitle">Years of teaching experience</div>
            <div class="info">
              {{teacherApplication.step3.yearsExperience}}
            </div>
          </div>
          <div class="col md:col-12">
            <div class="subtitle">Teaching Method</div>
            <div class="info">
              {{teacherApplication.step3.teachingMethods}}
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div
      class="review-section section-bg-gradient p-3"
      *ngIf="teacherApplication.step4"
    >
      <div class="review-section-title">Resume</div>
      <div class="grid flex-column sm:flex-row">
        <div class="col-12 sm:col-6 review-section-content flex-column">
          <div class="subtitle">CV</div>
          <div class="section-bg-gradient p-2 flex gap-2">
            <div>
              <img
                src="/assets/icons/teacher-application/cv-file-uploaded-icon.svg"
              />
            </div>
            <div *ngIf="teacherApplication.step4.cv">
              <a class="text-primary" [href]="this.generalService.getDomainFileNamePath(teacherApplication.step4.cv)" target="_blank">
                
                {{this.generalService.getFileNameAndExtension(teacherApplication.step4.cv)}}
              </a>
          
            </div>
          </div>
        </div>
        <div class="col-12 sm:col-6 review-section-content flex-column">
          <div class="subtitle">Photo</div>
          <div class="section-bg-gradient p-2 flex gap-2">
            <div>
              <img
                src="/assets/icons/teacher-application/cv-image-uploaded.svg"
              />
            </div>
            <div *ngIf="teacherApplication.step4.profilePicture">
              <a class="text-primary" [href]="this.generalService.getDomainFileNamePath(teacherApplication.step4.profilePicture)" target="_blank">
                {{this.generalService.getFileNameAndExtension(teacherApplication.step4.profilePicture)}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="review-section section-bg-gradient p-3">
      <div class="review-section-title">Availability</div>
      <div class="review-section-content" *ngIf="teacherApplication.step5">
        <div class="grid w-full" style="margin-top:5px;">
          <div class="col-12 sm:col-8" style="margin-top:5px;">
          <div class="availability flex sm:gap-2">
            <div class="col-50">Days</div>
            <div class="col-50">Time</div>
          </div>
          <div
            *ngFor="let available of teacherApplication.step5?.mon"
            class="availability flex sm:gap-2"
            style="font-weight: bold"
          >
            <div class="col-50" style="margin-top:5px">Monday</div>
            <div class="col-50" style="margin-top:5px">
              {{available.from}} - {{available.to}}
            </div>
          </div>
          <div
            *ngFor="let available of teacherApplication.step5?.tue"
            class="availability flex sm:gap-2"
            style="font-weight: bold"
          >
            <div class="col-50" style="margin-top:5px">Tuesday</div>
            <div class="col-50" style="margin-top:5px">
              {{available.from}} - {{available.to}}
            </div>
          </div>
          <div
            *ngFor="let available of teacherApplication.step5?.wed"
            class="availability flex sm:gap-2"
            style="font-weight: bold"
          >
            <div class="col-50" style="margin-top:5px">Wednesday</div>
            <div class="col-50" style="margin-top:5px">
              {{available.from}} - {{available.to}}
            </div>
          </div>
          <div
            *ngFor="let available of teacherApplication.step5?.thu"
            class="availability flex sm:gap-2"
            style="font-weight: bold"
          >
            <div class="col-50" style="margin-top:5px">Thursday</div>
            <div class="col-50" style="margin-top:5px">
              {{available.from}} - {{available.to}}
            </div>
          </div>
          <div
            *ngFor="let available of teacherApplication.step5?.fri"
            class="availability flex sm:gap-2"
            style="font-weight: bold"
          >
            <div class="col-50" style="margin-top:5px">Friday</div>
            <div class="col-50" style="margin-top:5px">
              {{available.from}} - {{available.to}}
            </div>
          </div>
          <div
            *ngFor="let available of teacherApplication.step5?.sat"
            class="availability flex sm:gap-2"
            style="font-weight: bold"
          >
            <div class="col-50" style="margin-top:5px">Saturday</div>
            <div class="col-50" style="margin-top:5px">
              {{available.from}} - {{available.to}}
            </div>
          </div>
          <div
            *ngFor="let available of teacherApplication.step5?.sun"
            class="availability flex sm:gap-2"
            style="font-weight: bold"
          >
            <div class="col-50" style="margin-top:5px">Sunday</div>
            <div class="col-50" style="margin-top:5px">
              {{available.from}} - {{available.to}}
            </div>
          </div>
          <div class="availability mt-4">
            <div class="col-50">Hours/Week</div>
          </div>
          <div class="availability flex sm:gap-2">
            <div class="col-50" style="margin-top:5px;">
              <strong>{{teacherApplication.step5.hours}}</strong>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <ng-container *ngIf="!hideBottomLayout">
    <div class="checkboxes-wrap">
      <div>
        <div class="review-section flex align-items-center">
          
          <p-checkbox styleClass="primary-blue outlined bigger" binary="true" label="* Agree with the terms and conditions" (onChange)="updateAgreement1($event)"></p-checkbox>

          <span class="sm:pl-2">
            
          </span>
        </div>
        <div class="review-section flex align-items-start">
          <p-checkbox styleClass="primary-blue outlined bigger" binary="true" label="* All personal information collected by MyLingoTrip is done so exclusively with your consent, by means of a form posted
          on our website, an email received from you or by telephone. No information is collected automatically." (onChange)="updateAgreement2($event)"></p-checkbox>
          <span class="sm:pl-2">
        </span>
        </div>
      </div>
    </div>
  
    <div class="btns md:ml-5 mt-4">
      <button pRipple (click)="goBack()" class="rounded-blue-button transparent">
        <img src="/assets/icons/arrow-left-blue.svg" /> Back
      </button>
      <button pRipple (click)="onSubmit()" class="rounded-blue-button submit-btn">
        Submit <img src="/assets/icons/teacher-application/checked.svg" />
      </button>
    </div>
  </ng-container>
  </div>
  