

<ng-container *ngIf="cardType === 'request'; else scheduleCard">
    <div class="card-height shadow-2 request-card-bg pointer hover:text-gray-900" (click)="requestlessonCardClicked()">
      <div class="p-2 text-center font-sm mx-5">
        <span class="inline-flex justify-content-center align-items-center border-circle mb-1 p-1">
          <i class="pi pi-plus-circle text-4xl text-white"></i>
        </span>
        <div class="text-md font-xs text-white line-height-1">Request Lesson</div>
      </div>
    </div>
  </ng-container>

  <ng-template #scheduleCard>
    <div class="relative overflow-hidden card-height surface-card schedule-card shadow-2 pointer" (click)="lessonCardClicked()">


      <svg xmlns="http://www.w3.org/2000/svg"  class="grad-group-svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
      width="315.754" height="126.01" viewBox="0 0 315.754 126.01">
        <defs>
          <!--  [attr.stop-color]="getStopColorByStatus(lesson.status)[0]" 
          [attr.class]="'stop-1-'+lesson.status.toLowerCase()"
          -->
          <linearGradient [attr.id]="'linear-gradient'+convertStatusStringToSlug(lesson.status)" x1="0.5" x2="0.529" y2="0.953" gradientUnits="objectBoundingBox">
            <stop offset="0" [ngStyle]="{'stop-color': getStopColorByStatus(lesson.status)[0]}" />
            <stop offset="1"  [ngStyle]="{'stop-color': getStopColorByStatus(lesson.status)[1]}" />
          </linearGradient>
        </defs>
        <g id="Group_4200{{lesson.id}}" data-name="Group 4200{{lesson.id}}" transform="translate(-900.623 -490.391)">
          <path id="Path_4654{{lesson.id}}" data-name="Path 4654{{lesson.id}}" d="M6338,16122.322s9.9,23.213,116.033-11.912,191.156-9.033,191.156-9.033l-.3-24.115s-.441-5.428-3.148-8.766c-1.331-1.646-5.028-18.027-7.678-17.566-7.635,1.32-292.554,15.408-292.554,15.408s-3.583,3.865-3.513,13.514S6338,16122.322,6338,16122.322Z" transform="translate(-2836.078 -16354.396) rotate(9)" 
          [attr.fill]="'url(#linear-gradient'+convertStatusStringToSlug(lesson.status)+')'"/>
        </g>
      </svg>

      
      <!--  [ngClass]="lessonStatusColor + '-gradient-lesson-bg'" -->
    
        <span class="flex align-items-center text-white my-2 text-center lg:text-center ml-1 capitalize icon-text z-3" style="font-size: 0.69rem!important;">
          <div class="icon-badge inverted z-6">
            <img src="/assets/icons/lessons/{{lessonStatusIcon}}.svg" />
          </div> <span class="max-w-3rem text-left line-height-1" > {{lesson.status}} </span>
        </span>
    
    
        <div class="grid grid-nogutter bottom-icons w-100 align-items-center justify-content-center h-5rem">
          <div class="flex flex-column fl-typo s10-14">
    
            <div class="flex text-primary align-items-center justify-content-center gap-1 font-xs">
              <div class="col-icon">
                <i class="pi  pi-calendar font-xs"></i>
              </div>
              <div class="col-text">{{lesson.startingDate | date: 'dd/MM/yyyy'}}</div>
            </div>
    
            <div class="flex text-primary gap-1 font-xs">
              <div class="col-icon">
                <i class="pi  pi-clock font-xs"></i>
              </div>
              <div class="col-text">{{generalService.getLessonTime(lesson.startingDate)}}</div>
            </div>
    
            <div class="flex text-primary gap-1 font-xs">
              <div class="col-icon">
                <i class="pi  pi-hourglass font-xs"></i>
              </div>
              <div class="col-text">{{generalService.convertHoursToMinutesWithSuffix(lesson.duration)}}</div>
            </div>
          </div>
        </div>
      </div>
  </ng-template>

