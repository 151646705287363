<div class="accordion border-round-3xl bg-white" [class.active]="isActive">
    <div class="header" (click)="toggle()">
        <div class="package-header w-100 justify-content-between align-items-center font-xs font-bold">
            <ng-content select="[title]"></ng-content>
        </div>
        <div class="flex">
            <div class="circle ml-1">
                <i [ngClass]="{'pi-chevron-down': !isActive, 'pi-chevron-up': isActive}" class="down-arrow pi"></i>
            </div>
        </div>
        <!-- <i class="pi" [ngClass]="{'pi pi-chevron-down': !isActive, 'pi pi-chevron-up': isActive}"></i> -->
    </div>
    <div class="accordion-content accordion-content-transition" [ngClass]="{'open': isActive}">
        <ng-content select="[content]"></ng-content>
    </div>
</div>