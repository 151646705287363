import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastService } from 'src/app/core/services/toast.service';

type TextFieldType = "email" | "password" | "confirm";
@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  confirmError: boolean = false;
  resetEmail: string = "";
  resetCode: string = "";
  ismyTextFieldType: Record<TextFieldType, boolean> = {
    email: false,
    password: false,
    confirm: false,
  };
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      this.resetEmail = params.email;
      this.resetCode = params.code
    });
  }

  onSignup(form: NgForm) {
    this.confirmError = this.confirmPassword(form.value.password, form.value.confirm);
    if (form.invalid || this.confirmError) {
      this.toastService.setShowToastmessage({
        severity: 'warn',
        summary: '',
        detail: 'Form is invalid. Password must contain at least one capital letter, one special case (e.g. @), and one number.'
      });
      return;
    }
    this.authService.confirmForgot(this.resetEmail, this.resetCode, form.value.password)
    .subscribe(async response => {
      
      this.toastService.setShowToastmessage({
        severity: 'success',
        summary: '',
        detail: 'The password reset was successful.'
      });
      this.router.navigateByUrl('/auth', { replaceUrl: true })

    })
  }

  confirmPassword(password: string, confirm: string) {
    return password !== confirm
  }

  togglemyPasswordFieldType(key: TextFieldType): void {
    this.ismyTextFieldType[key] = !this.ismyTextFieldType[key];
  }

  getKeyValueFieldType(key: TextFieldType): boolean {
    return this.ismyTextFieldType[key];
  }
}
