import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Level } from '../models/classroom.model';
import { CircleLine, CircleType, GoalReason, StudentGoal } from '../models/goal.model';
import { Category } from '../models/homework.model';
import { GeneralService } from './general.service';
import { UserService } from './user.service';
const BACKEND_URL = environment.apiUrl
const BACKEND_URL_LMS = environment.apiUrl + "/LMS";

@Injectable({
  providedIn: 'root'
})
export class GoalService {

  private updateListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly updateListener: Observable<boolean> = this.updateListener$.asObservable();

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private generalService: GeneralService
  ) { }

  setUpdateListener(listen: boolean) {
    this.updateListener$.next(true)
  }

  getGoalLevelsInCircle(studentGoals: StudentGoal[], elForWidth: string) {
    let circleLines: Map<string, CircleLine[]> = new Map();

    for (let goal of studentGoals) {
      let startingLevel = goal.startingLevel;
      let currentLevel = goal.currentLevel;
      let desiredLevel = goal.desiredLevel;
      let levels: Level[] = [Level.A1, Level.A2, Level.B1, Level.B2, Level.C1, Level.C2];
      let startingLevelIndex = levels.findIndex(el => el === startingLevel);
      let currentLevelIndex = levels.findIndex(el => el === currentLevel);
      let desiredLevelIndex = levels.findIndex(el => el === desiredLevel);
      let newCircleLines: CircleLine[] = [];
      for (let i = startingLevelIndex; i <= currentLevelIndex; i++) {
        let lineStyle = i == currentLevelIndex ? '1px dashed lightgray' : '1px solid lightgray'
        let circleLine: CircleLine = {
          text: levels[i],
          lineStyle,
          background: "#a4a2e6",
          color: "white",
          type: CircleType.GENERAL
        }
        newCircleLines.push(circleLine)
        circleLines.set(goal.id!, newCircleLines);
      }
      for (let i = currentLevelIndex + 1; i <= desiredLevelIndex; i++) {
        let circleLine: CircleLine = {
          text: levels[i],
          lineStyle: '1px dashed lightgray',
          background: "white",
          color: "#a4a2e6",
          type: CircleType.GENERAL
        }
        newCircleLines.push(circleLine)
        circleLines.set(goal.id!, newCircleLines);
      }
      for (let [key, value] of circleLines) {
        for (let val of value) {
          val.width = document.getElementById(elForWidth)!.clientWidth / (value.length - 1) - 21 + 'px'
        }
      }
    }
    return circleLines;
  }

  createGoal(goal: StudentGoal) {
    let studentGoal = {
      studentId: goal.studentId,
      startingLevel: goal.startingLevel,
      currentLevel: goal.currentLevel,
      desiredLevel: goal.desiredLevel,
      desiredAchieveDate: this.generalService.toIsoString(goal.desiredAchieveDate),
      hoursWeekly: goal.hoursWeekly,
      // totalWeeks: goal.totalWeeks,
      language: goal.language,
      teacherId: goal.teacher.aspUserId,
      // classroom: goal.classroom?.id,
      reasons: goal.reasons,
      areas: goal.areas
    }
    console.log(studentGoal)
    return this.http.post(BACKEND_URL + "/User/CreateStudentGoal", studentGoal)
  }

  getGoals(): Observable<StudentGoal[]> {
    return this.http.get<StudentGoal[]>(BACKEND_URL + "/User/GetStudentGoals").pipe(
      map(goals => goals.map(g => {
        g.desiredAchieveDate = new Date(g.desiredAchieveDate)
        return g
      }))
    )
  }
  public getClassroomGoals(): Observable<StudentGoal[]> {
    return this.http.get<StudentGoal[]>(BACKEND_URL + "/User/GetStudentGoals");
  }
  updateGoal(newGoal: StudentGoal, id: string) {
    let goalToSend = {
      startingLevel: newGoal.startingLevel,
      currentLevel: newGoal.currentLevel,
      desiredLevel: newGoal.desiredLevel,
      desiredAchieveDate: this.generalService.toIsoString(newGoal.desiredAchieveDate),
      hoursWeekly: newGoal.hoursWeekly,
      // totalWeeks: newGoal.totalWeeks,
      language: newGoal.language,
      reasons: newGoal.reasons,
      areas: newGoal.areas
    }
    return this.http.post(BACKEND_URL + "/User/UpdateStudentGoal?studentGoalId=" + id, goalToSend)
  }
}
