import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { Lesson, LessonStatus } from 'src/app/core/models/lesson.model';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { LessonService } from 'src/app/core/services/lesson.service';
import { RatingAndReportService } from 'src/app/core/services/rating-and-report.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { slideInOut, slideInOut2 } from 'src/app/helpers/my-animations';

@Component({
  selector: 'app-lesson-details',
  templateUrl: './lesson-details.component.html',
  styleUrls: ['./lesson-details.component.scss'],
  animations: [slideInOut, slideInOut2]
})
export class LessonDetailsComponent implements OnInit {
  @Input() lesson: Lesson = {} as Lesson;
  @Input() showMain?: boolean = true;
  @Output() onDeleteLesson = new EventEmitter<{ lesson: Lesson }>();
  showLessonPopup: boolean = false;
  tryTodelete: boolean = false;
  tryToChangeStatus: boolean = false;
  recMsg: string = "";

  constructor(
    private toastService: ToastService,
    private generalService: GeneralService,
    private calendarService: CalendarService,
    private lessonService: LessonService,
    private ratingAndReportService: RatingAndReportService
  ) { }

  ngOnInit(): void {
    this.closeLessonPopupOnClickOutside()
  }

  closePopup() {
    this.showLessonPopup = false;
  }

  toggleDelete(rec?: boolean) {
    this.tryTodelete = !this.tryTodelete
    this.recMsg = rec ? "All recurrance lessons will also be deleted" : "";
  }

  showChangeStatus() {
    this.tryToChangeStatus = !this.tryToChangeStatus
  }

  getLessonColor(status: LessonStatus): string {
    return this.calendarService.getEventGradientColor(status);
  }

  closeLessonPopupOnClickOutside() {
    document.addEventListener('click', (event: any) => {
      setTimeout(() => {// i dont know why..
        let ignoreClickOnMeElement = document.getElementById('lesson-' + this.lesson.id);
        var isClickInsideElement = ignoreClickOnMeElement?.contains(event.target);
        if (!isClickInsideElement && !this.ratingAndReportService.ratingIsOpen) {
          // this.generalService.slideOutElement('blur_bg');
          this.showLessonPopup = false;
        }
      }, 0);
    });
  }

  deleteLesson(event: any) {
    this.lessonService.delete(event.lesson.id).pipe(take(1)).subscribe(res => {
      this.onDeleteLesson.emit({ lesson: this.lesson })
      
      this.toastService.setShowToastmessage({
        severity: 'info',
        summary: '',
        detail: 'Lesson has been deleted successfully'
      });
      this.toggleDelete();
      this.lessonService.setDeleteListener(true);
    })
  }

  convertDuration(duration: number) {
    if (duration < 1) {
      return duration * 60 + "m"
    }
    return duration + "h"
  }
}
