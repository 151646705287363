import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertificateComponent } from './certificate.component';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { CarouselModule } from 'primeng/carousel';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'src/app/shared/shared.module';
@NgModule({
  declarations: [
    CertificateComponent
  ],
  imports: [
    CommonModule,
    TabViewModule,
    TableModule,
    CarouselModule,
    DialogModule,
    ButtonModule,
    SharedModule
  ]
})
export class CertificateModule { }
