<div class="lesson-popup" [style.background]="getLessonColor(lesson.status)" [style.right]="right==='' ? 'initial': '0'"
    [style.position]="position==='' ? 'absolute': 'relative'">
    <div class="lesson-popup-content">
        <div class="popup-title" style="padding:6px;">
            <div>Lesson Details</div>
            <img (click)="closePopup()" src="/assets/icons/close.png" class="close-img close-img-abs hvr-glow">
        </div>
        <div class="lesson-popup-info">
            <div class="lesson-popup-info-row">
                <div class="item">Status:</div>
                <div class="item right">{{lesson.status}}</div>
            </div>
            <div class="lesson-popup-info-row">
                <div class="item">Participants:</div>
                <div class="item right">{{lesson.title}}</div>
            </div>
            <div class="lesson-popup-info-row">
                <div class="item">Language:</div>
                <div class="item right">{{lesson.classroom?.language}}</div>
            </div>
            <div class="lesson-popup-info-row">
                <div class="item">Level:</div>
                <div class="item right">{{lesson.level}}</div>
            </div>
            <div class="lesson-popup-info-row">
                <div class="item">Starts at:</div>
                <div class="item right">{{ lesson.startingDate | date:'h:mm a':'UTC' }}
                </div>
            </div>
            <div class="lesson-popup-info-row">
                <div class="item">Duration:</div>
                <div class="item right">{{lesson.duration}}</div>
            </div>
            <div class="lesson-popup-info-row">
                <div class="item">Date:</div>
                <div class="item right">{{lesson.startingDate | date: 'dd/MM/yyyy'}}</div>
            </div>
        </div>
        <div class="lesson-popup-buttons">
            <div *ngIf="lesson.status==='arranged'" class="light-purple-button" (click)="join()">Join Lesson</div>
            <div *ngIf="!(router.url.includes('isTrial') || router.url.includes('classrooms/lessons/')) && (lesson.status==='arranged' || 
            lesson.status==='completed')" class="light-purple-button"
                (click)="navigateToClassroom()">Go to
                Classroom</div>
            <!-- <div *ngIf="lesson.status==='completed'" class="gray-border-button">Edit Rating</div> -->
            <div *ngIf="lesson.status==='completed'" class="gray-border-button" (click)="viewRating()">View Rating</div>
            <div class="gray-border-button border-purple" (click)="onReschedule()">
                <span *ngIf="role=='Student'">Request&nbsp;</span>Reschedule
            </div>
            <div *ngIf="lesson.status!=='completed' && role!='Student'" class="gray-border-button change-status"
                (click)="openChangeStatusPopup(changeStatusPopUp)">
                Change Status
            </div>
            <div #changeStatusPopUp class="change-status-popup" [attr.id]="'lesson-change-status-'+lesson.id">
                <div class="popup-title" style="padding: 6px;">
                    <div>Update Status</div>
                    <img (click)="closeChangeStatusPopup(changeStatusPopUp)" src="/assets/icons/close.png"
                        class="close-img close-img-abs hvr-glow">
                </div>
                <div #changeStatusText *ngFor="let status of getChangeStatuses(lesson.status)"
                    class="text link-main-color" (click)="changeToStatus(status)"
                    [attr.id]="'lesson-change-status-text-'+lesson.id">
                    {{status}}
                </div>
            </div>
            <div *ngIf="role!='Student'" class="error-button" (click)="toggleDelete()">
                Delete</div>
        </div>
        <div [ngStyle]="{'display':!tryTodelete ? 'none' : 'inline-block'}" class="delete">
            Are you sure you want to delete this class?
            <br>
            {{recMsg}}
            <br>
            You will not be able to undo this action
            <div class="btns">
                <div class="error-button" style="width:50%" (click)="deleteLesson()">Delete</div>
                <div class="white-button" style="width:50%" (click)="toggleDelete()">Cancel</div>
            </div>
        </div>
    </div>
</div>

<div #lessonRating id="lesson-rating-{{lesson.id}}" class="modal no-visibility p-0" style=" top : 54.5%">
    <div class="popup-title p-20">
        <div>Lesson Rating</div>
        <img (click)="closeRating({rated: false})" src="/assets/icons/close.png"
            class="close-img close-img-abs hvr-glow">
    </div>
    <div class="p-0-20">
        <app-lesson-rating *ngIf="showRatingView" [users]="classroom.users" [lesson]="ratingToView.lesson"
            [lessonRatings]="ratingToView.lessonBreakdown" [lessonUserRatings]="ratingToView.userRatings"
            [mode]="'view'">
        </app-lesson-rating>
        <app-lesson-rating *ngIf="showRating" [mode]="'create'" (ratingSubmitted)="closeRating($event)"
            [lesson]="lesson">
        </app-lesson-rating>
    </div>
</div>