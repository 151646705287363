<div class="h-full w-full flex align-items-center justify-content-center flex-column md:flex-row">

    <img src="/assets/icons/security.png" alt="security" />
<form (submit)="onSignup(signupForm)" #signupForm="ngForm" class="forgot">

    <div class="input-field">
        <div class="input-element-title">Password *</div>
        <span class="p-input-icon-right" style="width: 100%;">
          <i class="pi" (click)="togglemyPasswordFieldType('password')"  [ngClass]="getKeyValueFieldType('password')? 'pi-eye': 'pi-eye-slash'" 
          style="margin-top:-2px;"></i>
          <input name="password"  [type]="getKeyValueFieldType('password') ? 'text' : 'password'" ngModel placeholder="Password" #passwordInput="ngModel" required
                    class="input-element"  pattern="^(?=.*[A-Z])(?=.*[0-9]).*$" />
      </span> 
      <div style="font-size:12px; margin-top:10px;">Password must contain at least one capital letter, one
        special case (e.g. &#64;), and one number.</div>

      </div>

    <div class="input-field mb-3">
        <div class="input-element-title">Confirm Password</div>

        <span class="p-input-icon-right" style="width: 100%;">
            <i class="pi" (click)="togglemyPasswordFieldType('confirm')"  [ngClass]="getKeyValueFieldType('confirm')? 'pi-eye': 'pi-eye-slash'" 
            style="margin-top:-2px;"></i>
            <input name="confirm" pattern="^(?=.*[A-Z])(?=.*[0-9]).*$" 
            [type]="getKeyValueFieldType('confirm') ? 'text' : 'password'" ngModel type="password" placeholder="Confirm Password" #passwordInput="ngModel" required
            class="input-element" />
        </span>
     
        <div *ngIf="confirmError" class="input-error">Passwords do not much</div>
    </div>
    <button pButton type="submit" styleClass="bg-primary" label="Reset Password" class="block relative mt-3" 
          class="sign-in-btn hvr-glow"></button>
    <!-- <input type="submit" value="Reset Password" class="auth-btn-right hvr-glow" /> -->
</form>
</div>