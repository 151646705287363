<div class="profile-info">
    <form [formGroup]="form" *ngIf="!isLoading">
        <div class="profile-info-section" style="padding-top:0">
            <div class="input-fields">
                <div class="input-field">
                    <div class="input-element-title">* E-mail</div>
                    <input class="input-element" formControlName="email" type="text">
                    <div *ngIf="ifFieldValid('email')" class="input-error">* Email is required</div>
                </div>
                <div class="input-field">
                    <div class="input-element-title">* Phone</div>
                    <input class="input-element" formControlName="phone" type="text">
                    <div *ngIf="ifFieldValid('phone')" class="input-error">* Phone is required</div>
                </div>
                <div class="input-field">
                    <div class="input-element-title">* Skype</div>
                    <input class="input-element" formControlName="skype" type="text">
                    <div *ngIf="ifFieldValid('skype')" class="input-error">* Skype is required</div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="btns">
    <div class="link-main-color" (click)="goBack()">Back</div>
    <div (click)="onSubmit()" class="light-purple-button">Next</div>
</div>