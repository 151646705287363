import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequestTrialRequest, StudentTrialStatus, ContactUsRequest } from "../models/student.model";
const BACKEND_LMS_URL = environment.apiUrl + "/LMS/";

@Injectable({
  providedIn: 'root'
})
export class StudentTrialService {
  isTrialRequested: boolean;
  private studentStatusSubject = new BehaviorSubject<StudentTrialStatus>(StudentTrialStatus.NONE);
  studentStatus$ = this.studentStatusSubject.asObservable();

  constructor(
    private http: HttpClient,
  ) {
    // load the trial request status from sessionStorage
    this.isTrialRequested = JSON.parse(localStorage.getItem('isTrialRequested') as string) || false;
  }

  setTrialRequested(value: boolean) {
    // set the trial request status and save to localStorage
    this.isTrialRequested = value;
    localStorage.setItem('isTrialRequested', JSON.stringify(this.isTrialRequested));
    // localStorage.setItem('isTrialRequested', JSON.stringify(value));
  }

  hasTrialRequested() {
    return localStorage.getItem('isTrialRequested');
  }

  updateStudentTrialStatus(status: StudentTrialStatus) {
    this.studentStatusSubject.next(status);
  }

  public getStudentHasTrialRequest(): Observable<any> {
    return this.http.get(BACKEND_LMS_URL + 'StudentHasTrialRequest');
  }

  public getStudentTrialStatus(headers?: HttpHeaders): Observable<any> {
    return this.http.get(BACKEND_LMS_URL + 'GetStatusTrial', { headers, responseType: 'text' });
  }
  
  public postRequestTrial(params: RequestTrialRequest): Observable<RequestTrialRequest> {
    return this.http.post<RequestTrialRequest>(BACKEND_LMS_URL + 'RequestTrial', params);
  }

  public postRequestTrialInner(params: RequestTrialRequest): Observable<RequestTrialRequest> {
    return this.http.post<RequestTrialRequest>(BACKEND_LMS_URL + 'RequestFreeTrialInner', params);
  }
  
  public postContactUs(params: ContactUsRequest): Observable<ContactUsRequest> {
    return this.http.post<ContactUsRequest>(BACKEND_LMS_URL + 'ContactUs', params);
  }
}
