import { Component, OnInit } from '@angular/core';
import { LanguageToLearn, SocialMedia } from 'src/app/core/models/general.model';
import { GeneralService } from 'src/app/core/services/general.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public languagesToLearn: LanguageToLearn[] = []
  public socialMedia: SocialMedia[] = []
  constructor(
    private generalService: GeneralService
  ) { }

  ngOnInit(): void {
    this.languagesToLearn = this.generalService.getLanguagesToLearn();
    this.socialMedia = this.generalService.getSocialMedia();
  }

}
