import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { UserService } from 'src/app/core/services/user.service';
import { SubSink } from 'subsink';

var LottieInteractivity: any;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() menuItems: string = "";
  public userPhoto: string = "";
  public isTrial: boolean = false;
  public isClassroom: boolean = false;
  public isTrialRoute: boolean = false;
  public role: UserRole = {} as UserRole;
  currentIndex = 0;
  isBelow768 = false;
  private subs = new SubSink();

  constructor(
    private router: Router,
    public authService: AuthService,
    public userService: UserService,
    public generalService: GeneralService,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
    this.role = this.authService.getUserRole();
    this.userPhoto = this.userService.getUserPhoto(this.authService.getLoggedInUser());
    this.subs.add(this.generalService.deviceKind.subscribe(res => {
      this.isBelow768 = res.is768;
    }));
    this.subs.add(this.userService.update.subscribe(res => {
      // this.userPhoto = this.authService.getUserPhoto();
    }));
  }

  ngAfterViewInit(): void {
    const playerContainers = this.elementRef.nativeElement.querySelectorAll('.hoverEffects');
    playerContainers.forEach((container: any) => {
      container.addEventListener('mouseover', () => {
        if (container) {

          const player = container.querySelector('lottie-player') as any;
          if (player) {
            player.setDirection(1);
            player.play();
            player.setLooping(false);
          }
        }
      });
      container.addEventListener('mouseleave', () => {
        const player = container.querySelector('lottie-player') as any;
        player.setDirection(-1);
        player.play();
        player.setLooping(false);
      });
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
