<div class="md:mt-3"></div>
<div class="profile-info">
    <!-- <ng-container *ngIf="isLoading">
        <div class="abs-centered">
        <p-progressSpinner></p-progressSpinner>
        </div>
    </ng-container> -->

<ng-container *ngIf="!isLoading">
    <form [formGroup]="form">
        <div class="profile-info-section" style="padding-top:0">
            <div class="input-fields">

                <div class="input-field">
                    <div class="input-element-title">First Name *</div>
                    <input class="input-element" formControlName="firstName" type="text">
                    <div *ngIf="ifFieldValid('fname')" class="input-error">* First Name is required</div>
                </div>
                <div class="input-field">
                    <div class="input-element-title">Last Name *</div>
                    <input class="input-element" formControlName="lastName" type="text">
                    <div *ngIf="ifFieldValid('lname')" class="input-error">* Last Name is required</div>
                </div>
                <!-- <div class="input-field">
                    <div class="input-element-title">* Username</div>
                    <input value="fds" class="input-element" formControlName="username" type="text">
                    <div *ngIf="ifFieldValid('username')" class="input-error">* Username is required</div>
                </div> -->
                
                <div class="w-100">
                <div class="input-field">
                    <div class="input-element-title">Preffered Name *</div>
                    <input class="input-element" formControlName="prefferedName" type="text">
                    <div *ngIf="ifFieldValid('preffered')" class="input-error">* Preffered name is required</div>
                </div>
                </div>



                <div class="input-field">
                    <div class="input-element-title">Country of Origin *</div>
                    <p-dropdown autocomplete="autocomplete_off_hack_xfr4!k" [options]="countries"
                        (onChange)="onCountryChange($event)" optionLabel="name" [filter]="!isTablet" filterBy="name"
                         placeholder="Select Country" formControlName="countryOrigin" styleClass="dropdown-blue small-dropdown-items">
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <img [src]="country.image">
                                <div class="country-name">{{country.name}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <div *ngIf="ifFieldValid('countryOrigin')" class="input-error">* Country of Origin is required</div>
                </div>


                <div class="input-field">
                    <div class="input-element-title">Country of Residency *</div>
                    <p-dropdown  autocomplete="autocomplete_off_hack_xfr4!k" [options]="countries"
                        (onChange)="onCountryChange($event)" optionLabel="name" [filter]="!isTablet" filterBy="name"
                         placeholder="Select Country" formControlName="countryResidency" styleClass="dropdown-blue small-dropdown-items">
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <img [src]="country.image">
                                <div class="country-name">{{country.name}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <div *ngIf="ifFieldValid('countryResidency')" class="input-error">* Country of Residency is required</div>
                </div>

                <div class="input-field">
                    <div class="input-element-title">Timezone *</div>
                    <p-dropdown autocomplete="autocomplete_off_hack_xfr4!k" [options]="timezones"
                        (onChange)="onTimeZoneChange($event)" optionLabel="text" [filter]="!isTablet" filterBy="text"
                        placeholder="Select Zone" formControlName="timeZone" styleClass="dropdown-blue">
                        <ng-template let-timezone pTemplate="item">
                            <div class="country-item">
                                <div class="country-name">{{timezone.text}}</div>
                                <div *ngIf="ifFieldValid('timezone')" class="input-error">* Timezone is required</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <div *ngIf="ifFieldValid('zone')" class="input-error">* Timezone is required</div>
                </div>
                <div class="input-field">
                    <div class="input-element-title">Date of Birth *</div>

                    <p-calendar 
                    [maxDate]="maxBirthdateTime"
                    [defaultDate]="maxBirthdateTime"
                    dateFormat="dd/mm/yy"
                    styleClass="date-element" 
                    inputStyleClass="input-element"
                    [keepInvalid]="true"
                    formControlName="birth"
                    [monthNavigator]="true"
                    [yearNavigator]="false"
                    [yearRange]="'2019:2021'"
                    [firstDayOfWeek]="1"
                    [readonlyInput]="true"
                    (onSelect)="onBirthDateSelected($event)"
                    ></p-calendar>
                    <div *ngIf="ifFieldValid('birth')" class="input-error">* Date of Birth is required</div>
                </div>
                <div class="input-field"> 
                    
                <div class="input-element-title">Native Speaker in * 
                  </div>
                    <ng-container formArrayName="nativeLanguages">
                        <div *ngFor="let _ of nativeLanguages.controls; index as i" style="position: relative; display: flex;flex-direction: column;">
                           <div style="flex-direction: row; display: flex;  align-items: center">
                            <ng-container [formGroupName]="i">
                                <div class="display-flex align-center  m-t-10" style="gap: 10px;     width: 100%;">
                                <p-dropdown autocomplete="off" [options]="languages" (onChange)="onOriginChange($event)"
                                optionLabel="name" [filter]="!isTablet" filterBy="name" 
                                placeholder="Select Native" formControlName="native" styleClass="dropdown-blue m-t-0">
                                <ng-template let-language pTemplate="item">
                                    <div class="country-item">
                                        <div class="country-name">{{language.name}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <div *ngIf=" i === 0">
                                <button pButton type="button" (click)="addnativeLanguage()" class="p-button-raised p-button-rounded plus-btn-circle" icon="pi pi-plus"></button>
                            </div> 
                            <ng-container *ngIf=" i > 0">
                                <button pButton type="button" (click)="removeNativeLanguage(i)" class="p-button-raised p-button-rounded minus-btn-circle" icon="pi pi-minus"></button>
                            </ng-container>
                        </div>
    
                            </ng-container>
                            </div>
                        </div>
                        <div *ngIf="ifFieldValid('nativeLanguages')" class="input-error w-100">* Please select your Native languages
                        </div>
                    </ng-container>
                    <!-- <div *ngIf="ifFieldValid('native')" class="input-error">* Native is required</div> -->
                </div>

                <!-- <div class="input-field">
                    <div class="input-element-title">* Password</div>
                    <input class="input-element" formControlName="password" type="password">
                    <div *ngIf="ifFieldValid('password')" class="input-error">* Password is required</div>
                </div>
                <div class="input-field">
                    <div class="input-element-title">* Confirm Password</div>
                    <input class="input-element" formControlName="confirm" type="password">
                    <div *ngIf="isConfirm('confirm')" class="input-error">* Passwords do not much</div>
                </div> -->
                <!-- <div class="input-field">
                    <div class="input-element-title">* City</div>
                    <input class="input-element" formControlName="city" type="text">
                    <div *ngIf="ifFieldValid('city')" class="input-error">* City is required</div>
                </div> -->
                <div class="w-100">
            <div class="step-heading-right m-t-30" style="padding: 10px;"><strong>Enter your contact info</strong></div>
        </div>
                    <div class="input-field">
                        <div class="input-element-title">E-mail *</div>
                        <input class="input-element" formControlName="email" type="email">
                        <div *ngIf="ifFieldValid('email')" class="input-error">* Email is required</div>
                    </div>
                    <div class="input-field">
                        <div class="input-element-title">Phone *</div>
                        <div class="ui-g ui-fluid">
                              <div class="display-flex">
                                <div fxFlex class="col--2of3">
                              
                        <app-prime-input-dropdown 
                        optionLabel="code"
                        [parentForm]="form"
                        [countries]="phoneCodes"
                        filterBy="code"
                        [filter]="true"
                        [inputName]="'phoneCode'"
                        [showTextAfterImage]="false"
                        [withFlags]="true"
                        flagFilter="code"
                        [selectedItemValue]="selectedPhoneCode"
                        templateValue="code"
                        styleClass="dropdown-blue phone-code-input small-dropdown-items"
                        (valueSelected)="onPhoneCodeChange($event)">
                        </app-prime-input-dropdown>
                                    
                                </div>
                                <div fxFlex class="col--1of1">
                                    <input class="input-element phone-input" formControlName="phone" type="text">
                                </div>
                              </div>
                          </div>
                        <div *ngIf="ifFieldValid('phone')" class="input-error">* Phone is required</div>
                    </div>
                    <div class="input-field">
                        <div class="input-element-title">Skype *</div>
                        <input class="input-element" formControlName="skype" type="text">
                    </div>
            </div>
        </div>
    </form>
</ng-container>
</div>
<div class="btns md:ml-5 mt-4">
    <button pRipple class="rounded-blue-button transparent" disabled> <img src="/assets/icons/arrow-left-blue.svg" /> Back</button>
    <button pRipple (click)="onSubmit()" class="rounded-blue-button">Next <img src="/assets/icons/arrow-right.svg" /></button>
</div>
