import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { TeacherStatus, TeacherStatusResponse } from "../models/teacher.model";
import { AuthService } from "../services/auth.service";
@Injectable()
export class ProfileGuard  {
    constructor(
        private authService: AuthService,
        private router: Router,
    ) { }
    canActivate(route: ActivatedRouteSnapshot): true | Observable<boolean> {
        const user= this.authService.getLoggedInUser();
        const userRole = this.authService.getUserRole();

        // checks if the user role is 'teacher' and their status is 'active'.
        // If both conditions are true, it returns true. Otherwise, it calls the method checkTeacherAccessApproval().

        if (userRole && userRole.toLowerCase() === 'teacher') {
            if (user.status.toLowerCase() === 'active') {
                return true;
            }
            return this.checkTeacherAccessApproval();
        }
        // If the user role is not 'teacher', it simply returns true.
        else {
            return true;
        }
    }

    checkTeacherAccessApproval() {
        return this.authService.getTeacherStatus().pipe(
            map((data: TeacherStatusResponse) => {
                if (data.status === TeacherStatus.APPROVED) {
                    return true;
                } else {
                    this.authService.setDisabledProfileRoutes(true);
                    this.router.navigateByUrl('/user-profile/settings', { replaceUrl: true });
                    return false;
                }
            })
        )
    }
}