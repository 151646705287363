<ng-container *ngIf="deleteFolderConfirmData.showDialog">
    <app-confirm-dialog (rejectSelected)="onRejectFolderDelete($event)" (confirmSelected)="onAcceptFolderDelete($event)"
        dialogKey="key1" headerClass="text-center flex align-items-center flex-column bg-white" [rejectBtnIcon]=""
        acceptBtnLabel="Yes" [confirmMessage]="deleteFolderConfirmData.message"
        rejectBtnLabel="No"></app-confirm-dialog>
</ng-container>
<ng-container *ngIf="deleteFileConfirmData.showDialog">
    <app-confirm-dialog (rejectSelected)="onRejectFileDelete($event)" (confirmSelected)="onAcceptFileDelete($event)"
        dialogKey="key2" headerClass="text-center flex align-items-center flex-column bg-white" [rejectBtnIcon]=""
        acceptBtnLabel="Yes" [confirmMessage]="deleteFileConfirmData.message" rejectBtnLabel="No"></app-confirm-dialog>
</ng-container>
<ng-container *ngIf="deleteMultipleFilesConfirmData.showDialog">
    <app-confirm-dialog (rejectSelected)="onRejectFileDelete($event)" (confirmSelected)="onAcceptFileDeleteMultiple($event)"
        dialogKey="key2" headerClass="text-center flex align-items-center flex-column bg-white" [rejectBtnIcon]=""
        acceptBtnLabel="Yes" [confirmMessage]="deleteMultipleFilesConfirmData.message" rejectBtnLabel="No"></app-confirm-dialog>
</ng-container>



<div class="notes" #mainWrapper>

    
<div class="modal  p-0 max-w-12rem" [ngClass]="{ 'no-visibility' : !this.showAddLibrary}">
    <app-block-viewer [header]="'Upload files in folder ' + libraryToAdd.folder.name"
    headerBackgroundImage="/assets/images/dashboard/calendar/calendar-dialog-lesson-info-arranged.png"
    blockClass="border-radius-bottom-10" headerBlockClass="py-1 border-round-lg bg-cover sticky top-0 z-5 "
    containerClass="block-gradient-reverse px-3 py-2 relative" [headerClass]="'justify-content-center my-0'"
    [headerTextClass]="'font-base font-semibold justify-content-center capitalize'" [showCloseDialogIcon]="true"
    (closeDialogEvent)="onShowAddLibrary()">
    <div class="block-gradient p-3">
        <!-- <app-upload-files (onSubmit)="onSubmit()" class="p-20" title="Library"
        [fileDropImage]="'/assets/icons/library/upload-file-lib.svg'" [fileDropImageWidth]="10"
        [isProfile]="false"
        [teacherFilesLength]="libraryToAdd.libraryFiles.length" [dndUI]="true" [showSubmitButton]="true"
        [checkboxUI]="true" postUrl="/Upload/UploadLibraryFile" (onUploadFinished)="uploadFinished($event)"
        (tagChoosen)="onTagChoosen($event)">
        </app-upload-files> -->
    </div>
    </app-block-viewer>
</div>

    <div class="block-gradient-reverse {{extraGradientClass}} py-2 md:py-0 border-round-xl px-1"  [style.height]="!isSmallScreen ? leftSideHeight +(authService.isStudent ? -15 : 0) + 'px' : '500px'">
        
        <ng-container *ngIf="!withClassroom || authService.isStudent || classroomStudents.length === 1">
            <ng-container *ngTemplateOutlet="classInfoGeneralTemplate; context: { classroom: classroom }">
            </ng-container>
        </ng-container>

        <ng-container *ngIf="classroomStudents && withClassroom && authService.isTeacher && classroomStudents.length > 1">
            <p-tabView [scrollable]="classroomStudents.length > 4" (onChange)="onTabChangeFilterFilesPerStudent($event)" class="w-full">
                <p-tabPanel header="All">
                    <div id="classroom-info" class="">
                        <ng-container *ngTemplateOutlet="classInfoGeneralTemplate; context: { classroom: classroom, uniqueFiles: true }">
                        </ng-container>

                    </div>
                </p-tabPanel>
                <ng-container *ngFor="let selectedStudent of classroomStudents; index as i">
                    <p-tabPanel [header]="selectedStudent.firstName">
                        <ng-container
                            *ngTemplateOutlet="classInfoGeneralTemplate; context: { classroom: classroom, student: selectedStudent }">
                        </ng-container>
                    </p-tabPanel>
                </ng-container>
            </p-tabView>
        </ng-container>

        <ng-template #classInfoGeneralTemplate let-classroom="classroom" let-student="student" let-uniqueFiles="uniqueFiles">
            <div class="notes-header mb-2 lg:absolute gap-3 abs-header-top lg:flex" [ngClass]="{'more': isInTab}">
                <div class="notes-header-title hidden md:block font-2xl opaque-box-rtl {{extraGradientClass}} sm:col-4">
                    {{libraryTitle}}
                </div>
                <div class="flex align-items-center justify-content-end notes-header-actions gap-2" style="margin-right: 5px;">
                    <span class="p-input-icon-right w-full">
                        <input type="text" [(ngModel)]="term" pInputText placeholder="Search"
                            class="h-2rem w-full input-blue gradient-blue rounded" />
                        <i class="pi pi-search text-primary"></i>
                    </span>
                    <!-- <button pButton type="button" label="" class="p-button-text " styleClass="min-w-6rem"
                        style="min-width: 6rem;">
                        <span class="p-button-icon p-button-icon-right" aria-hidden="true"></span>
                        <span class="p-button-label mr-2">Filters</span>
                        <img class="contact-icon-img" src="/assets/icons/notifications/notif-filter.svg" />
                    </button> -->

                    <img *ngIf="canShare" title="Group Actions" src="/assets/icons/library/group-actions.svg" (click)="setGroupActions()" class="hvr-grow pointer"
                    [ngClass]="{'filter-blue': showGroupActions}">
                    <img *ngIf="canUpload" title="Upload File" src="/assets/icons/library/add-lib.svg" (click)="onShowUploadDialog()" class="add hvr-grow">
                </div>
            </div>

            <div class="flex align-items-center justify-content-center">
                <div *ngIf="showGroupActions" class="note-menu-row block-gradient border-round-xl font-sm my-2 p-1 px-3 gap-2">
                    <!--  (click)="showShare = !showShare" -->
                    <div class="note-menu-col">
                        <div class="note-menu-icon">
                            <img src="/assets/icons/library/share-sm-icon.svg" height="14">
                        </div>
                        <div class="note-menu-text link-main-color" (click)="openShareDialog()">
                            Share
                        </div>
                        <!-- <div class="note-menu-icon" style="margin-left:auto">
                            <img src="/assets/icons/arrow-note.svg">
                        </div> -->
                    </div>
                    <div class="note-menu-col" (click)="moveMultipleFiles()">
                        <div class="note-menu-icon">
                            <img src="/assets/icons/library/move.svg" height="14">
                        </div>
                        <div class="note-menu-text link-main-color">
                            Move
                        </div>
                    </div>
                    <div class="note-menu-col" (click)="deleteMultipleFiles()">
                        <div class="note-menu-icon">
                            <img src="/assets/icons/library/delete.svg" height="14">
                        </div>
                        <div class="note-menu-text link-main-color">
                            Delete
                        </div>
                    </div>
                    <div class="send" *ngIf="showShare">
                        <app-classrooms-check-list (close)="showShare = !showShare" (checkedList)="onCheckedList($event)">
                        </app-classrooms-check-list>
                        <div class="main-color-button share-btn" (click)="shareLibraryFiles()">
                            Share
                        </div>
                    </div>
                </div>
            </div>

            <div class="my-folders">
                <div class="flex flex-row">
                    <div class="flex flex-column p-4 my-folder align-items-center"  [ngClass]="{'purple-folder-bg': isInTab}">
                        <img src="/assets/icons/my-folder.svg" width="40">
                        <h4 class="text-primary m-0 font-base text-center font-semibold pt-1 capitalize">
                            {{libraryToAdd.folder.name}}
                        </h4>
                    </div>

                    <div class="flex flex-row gap-4 ml-4 align-items-center horizontal-scroll-menu px-3">
                        <div class="flex flex-column relative" *ngFor="let innerFolder of innerFolders">
                            <app-single-library-folder-item 
                            [folder]="innerFolder"
                            [hasLimitedOptions]="hasLimitedOptions" 
                            (deleteFolder)="deleteFolderSelected($event)"
                            (openFolder)="openFolderSelected($event)"
                            ></app-single-library-folder-item>
                        </div>
                    </div>

                    <img *ngIf="canUpload" title="Create new subfolder" (click)="createFolder()" class="ml-auto px-3 pointer hvr-grow" src="/assets/icons/folder-add.svg" width="60">



                    <div class="inner-folders">

                        <!-- <div *ngFor="let innerFolder of innerFolders" class="hvr-grow" style=" position:relative; margin:15px; cursor: pointer">
        <img src="/assets/icons/fol.svg" style="width:100px; height:100px" (click)="openFolder(innerFolder)">
        <div style="position:absolute; top:50px; width:100px; text-align: center; font-weight: bold" (click)="openFolder(innerFolder)">
            {{innerFolder.name}}
        </div>
        <img src="/assets/icons/close.png" class="close-img close-img-abs hvr-glow" (click)="showDelete(innerFolder.folderId)">
        <img src="/assets/icons/close.png" class="close-img close-img-abs hvr-glow" (click)="showDelete(innerFolder.folderId)">
        <div id="inner-{{innerFolder.folderId}}" style="display: none; background: white; z-index:10;">
            Are you sure you want to delete folder?
            <div class="btns">
                <div (click)="deleteFolder(innerFolder.folderId)" class="white-button">Yes</div>
                <div (click)="cancelDelete(innerFolder.folderId)" class="main-color-button">No</div>
            </div>
        </div>
    </div> -->

                        <!-- <div class="main-color-button" (click)="onCancelCreateFolder()">Cancel</div> -->
                    </div>

                </div>
            </div>
            <div class="folder-path">
<!--  *ngIf="showInput" -->


<div class="flex align-items-center justify-content-between">
                <div class="folder-nav-buttons">

                    <img *ngIf="folderPath.length > 1" (click)="openFolder(folderPath[folderPath.length-2])"
                        src="/assets/icons/arr-left.svg" height="18" class="pointer">
                    &nbsp;

                    <!-- <img src="/assets/icons/arr-right.svg" height="18"> -->
                </div>

                <img src="/assets/icons/folder-start.png">
                &nbsp;

                <img src="/assets/icons/arr-right.svg">
                &nbsp;
                <div *ngFor="let folder of folderPath" (click)="openFolder(folder)" class="link-main-color font-sm capitalize">
                    &nbsp;{{folder.name}}&nbsp;/
                </div>
            </div>
                <div class="ml-auto">
                    <ng-container *ngIf="showInput">
                        <div class="flex w-auto align-items-center">
                            <i class="pi pi-times-circle mr-2 pointer icon-times-blue" (click)="createFolder()"></i>
                            <input type="text"  [(ngModel)]="folderName" pinputtext="" placeholder="Name your folder..." 
                                class="h-2rem input-blue gradient-blue rounded btn-right mr-2">
                            <button type="button" (click)="onCreateFolder()" pbutton="" pripple="" label="Search" class="p-element p-button p-button-xs p-component btn-gradient-blue"
                            style="height: 32px;"><span
                                    class="p-button-label">Create</span></button>
                        </div>
    <!-- 
                        <input [(ngModel)]="folderName" placeholder="Name your folder..." class="h-2rem input-blue gradient-blue rounded" type="text">
                        <div class="light-purple-button" (click)="onCreateFolder()">Create</div> -->
                    </ng-container>
                    <!-- <button pButton type="button" label="" 
            iconPos="right" class="p-button-sm p-button-outlined p-button-rounded">
        <div class="flex gap-1">
           <span> Create subfolder </span> <img src="/assets/icons/folder-add.svg" height="16" />
        </div>
        </button> -->
                </div>
            </div>
            <ng-container *ngIf="files.length === 0 && !canUpload">
                <div class="flex flex-column gap-1 align-items-center justify-content-center">
                    <img src="/assets/icons/library/file_manager_emp.png" alt="file manager empty icon" class="add empty hvr-grow">
                    <p class="font-base max-w-14rem text-center">
                        <ng-container *ngIf="authService.isStudent">
                            No files found.
                        </ng-container>
                        <ng-container *ngIf="authService.isTeacher">
                            No files found.
                        </ng-container>
                    </p>
                </div>
            </ng-container>

            <ng-container *ngIf="files.length === 0 && canUpload">
                <div class="flex flex-column gap-1 align-items-center justify-content-center">
                    <img src="/assets/icons/library/add-lib.svg" (click)="onShowUploadDialog()" class="add lg hvr-grow">
                    <p class="font-base max-w-14rem text-center">
                        <!-- There are currently no files {{withClassroom ? 'in this classroom' : ''}}. -->
                        Upload your first file!
                    </p>
                </div>
            </ng-container>
            <div class="allFiles" id="allFiles" *ngIf="getFilteredFiles(uniqueFiles).length > 0">
                    <ul class="library-file-list"  [style.height]="fileListHeight + 'px'">
                        <!--  | filter: term -->
                        <ng-container *ngFor="let libFiles of getFilteredFiles(uniqueFiles) | filter: { term: term }">
                            <app-library-file-row-item [showGroupActions]="showGroupActions"
                                [showSharedWithUsers]="withClassroom"
                                appendDialogActionsTo="allFiles" [classroom]="classroom" [classroomFile]="libFiles"
                                [hasLimitedOptions]="hasLimitedOptions"
                                [isSmallScreen]="isSmallScreen"
                                [isInTab]="isInTab"
                                (fileDeleted)="onFileDeleted($event)" [folders]="folders"
                                [availableActions]="availableActions"
                                (libraryChecked)="onLibraryChecked($event)"
                                (fileUnshared)="onFileUnshared($event)"
                                [classroomStudents]="classroomStudents"></app-library-file-row-item>
                        </ng-container>

                    </ul>
            </div>
        </ng-template>

    </div>
    <!-- ends block-gradient -->
</div>

<ng-template #classInfoStudentTemplate let-student="student" let-classroom="classroom">
    hi
</ng-template>