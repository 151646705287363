<div *ngIf="dndUI">
    <div class="files-container" appDnd (fileDropped)="onFileDropped($event)">
        <div>{{title}}</div>
        <input type="file" id="{{title}}" (change)="fileBrowseHandler($event.target.files)" [multiple]="multiple" />
        <ng-container *ngIf="fileDropImage else defaultImage" >
            <img [src]="fileDropImage" [style.width.%]="fileDropImageWidth ? fileDropImageWidth : 100">
        </ng-container>
        <ng-template #defaultImage>
            <img src="/assets/icons/drop.svg" style="width:60%;">
        </ng-template>
        <div class="drop-text-subtitle">
            Drag and drop files here or
            <label for="{{title}}">Browse</label>
        </div>
    </div>
</div>

<div *ngIf="isSick">
    <div>
        <input type="file" id="{{title}}" (change)="fileBrowseHandler($event.target.files)" [multiple]="multiple" />
        <label for="{{title}}" class="light-purple-button" style="color:white !important;">Browse</label>
    </div>
</div>

<div *ngIf="isProfile">
    <div class="flex align-items-center">

    <div class="flex align-items-center justify-content-center">
          <button pButton type="button" 
          (click)="filePicker.click()"
          class="p-button-outlined p-button-rounded gradient-input-like-btn no-label text-center flex align-items-center justify-content-center px-6">
            <span>Choose file</span> <img src="/assets/icons/profile-upload-icon.svg" class="pl-3" height="20" />
        </button></div>
    <input type="file" #filePicker (change)="fileBrowseHandlerProfile($event)" />
    <div *ngIf="croppedImage === ''" class="flex align-items-center z-1"><img src="/assets/icons/warning-sign.svg" class="pr-3" height="24" />No file chosen</div>
    </div>
    <ng-container *ngIf="showCropper">
    <div class="flex flex-row align-items-center">
        <div>
    <!-- <image-cropper [hidden]="croppedImage === ''" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
        [containWithinAspectRatio]="false" [aspectRatio]="1 / 1" [resizeToWidth]="256" [cropperMinWidth]="128"
        [onlyScaleDown]="true" [roundCropper]="false" [alignImage]="'left'" format="png"
        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()" class="test">
    </image-cropper> -->
</div>
    <img id="cropped-img" class="cropped-img" [src]="croppedImage" style="border-color: var(--primary-color)" />
</div>

<input type="file" (change)="fileChangeEvent($event)" />

<image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="4 / 3"
    [aspectRatio]="1 / 1" [resizeToWidth]="256" [cropperMinWidth]="128"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
></image-cropper>

</ng-container>
</div>

<!-- <div *ngIf="buttonUI">
    <input type="file" id="a{{title}}" (change)="fileBrowseHandler($event.target.files)" [multiple]="multiple" />
    <label *ngIf="uploadedFile===''" for="a{{title}}">
        Upload File
    </label>
    <div *ngIf="uploadedFile!==''">
        {{uploadedFile}}
    </div>
</div> -->

<div *ngIf="dndUI" class="files-list lg:mt-2">
    <div *ngFor="let file of files; let i = index">
        <div *ngIf="file.file.name" class="single-file section-bg-gradient flex">
            <ng-container *ngIf="fileAfterImage else defaultAfterImage" >
            <img class="mr-2" [style.width.%]="fileAfterImageWidth ? fileAfterImageWidth : null" [src]="fileAfterImage" />
            </ng-container>
            
        <ng-template #defaultAfterImage>
            <div class="file-icon" style="width: 50px">
                <img src="/assets/icons/library/surface1.svg">
            </div>
        </ng-template>
            <!--  -->
            <div class="info">
                <p class="name">
                    <a class="text-primary" [href]="this.generalService.getDomainFileNamePath(file?.file.name)" target="_blank">
                
                        {{ this.generalService.getFileNameAndExtension(file?.file.name) }}
                      </a>
                </p>
                <p class="size" *ngIf="file.file.size" >
                    {{ formatBytes(file?.file.size) }}
                </p>
                <div class="progress-cont" *ngIf="file.progress">
                    <div class="progress" [style.width]="file.progress + '%'">
                    </div>
                </div>

            </div>

            <div class="delete" (click)="deleteFile(i)">
                <i class="pi pi-times-circle text-xl text-primary"></i>
            </div>
        </div>
    </div>
</div>

<div *ngIf="checkboxUI" class="library-uploads">
    <div *ngIf="files.length > 1">
        You have selected {{files.length}} files
        <div class="library-upload">
            <div class="font-sm p-3">
                <strong>Tags&nbsp;</strong> apply to all
            </div>
            <!-- <hr> -->
            <app-choose-tags [applyToAll]="true" (chooseTag)="onChooseTag($event)"></app-choose-tags>
        </div>
    </div>
    <div *ngFor="let file of files" class="library-upload p-3">
        <div class="library-upload-title">
            <img src="/assets/icons/library/surface1.svg">
            <label class="font-sm text-800 in-title p-2">
                {{file.file.name}}
            </label>
        </div>
        <hr>
        <app-choose-tags [applyToAll]="false" [file]="file" (chooseTag)="onChooseTag($event)"></app-choose-tags>
    </div>
</div>

<div *ngIf="showSubmitButton" class="flex justify-content-center align-items-center mt-2">
    <button pButton pRipple (click)="submit()" type="button"
        class="p-button p-button-sm p-button-rounded link-main-color-active">
        <div class="flex align-items-center px-3">
            <span class="text-white py-1">Upload</span>
            <img class="contact-icon-img" src="/assets/icons/library/upload-file-lib.svg" width="26" class="pl-2" />
        </div>
    </button>
</div>