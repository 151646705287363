import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastMessage } from 'src/app/core/models/general.model';
import { ToastService } from 'src/app/core/services/toast.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-prime-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent implements OnInit, OnDestroy {

  private subs = new SubSink();
  constructor(
    private toastService: ToastService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.subs.add(this.toastService.showToastmessage.subscribe((toastMsg: ToastMessage) => {
      if (toastMsg) {
        const toast = {
          key: toastMsg.key || 'tc', life: toastMsg.life || 5000, severity: toastMsg.severity, summary: toastMsg.summary,
          detail: toastMsg.detail
        };
        this.messageService.add(toast);
      }
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
