<div *ngIf="studentGoals.length > 0" class="goals" id="view-goals" #viewGoals>
    <div style="display:flex; align-items: center">
        <strong>
            Choose Goal
        </strong>
        <div style="margin-left:15px">
            <p-dropdown placeholder="Choose Goal" [options]="studentGoals" (onChange)="changeIndex($event)"
                optionLabel="language">
                <ng-template let-goal pTemplate="item">
                    <div class="country-item">
                        <div class="country-name">{{goal.language}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>
    <!-- <div *ngFor="let goal of studentGoals; let indexToShow =index" class="goal"> -->
    <div class="weekly-goal">
        <div style="padding:20px 0;">
            Weekly Goals
        </div>
        <div class="weeks-counter">
            <img src="/assets/icons/left-week.svg" (click)="showWeekLeft(indexToShow)">
            <div style="margin: 0 15px;">
                Week {{this.currentWeek[indexToShow]+1}}/{{goalToShow.totalWeeks}}
            </div>
            <img src="/assets/icons/right-week.svg" (click)="showWeekRight(indexToShow)">
        </div>

        <div *ngFor="let item of [].constructor(goalToShow.totalWeeks); let w = index">
            <app-circle-line *ngIf="showWeek[indexToShow][w]" [circles]="weeklyHoursCircle[indexToShow][w]">
            </app-circle-line>
        </div>

    </div>
    <div class="weekly-goal">
        <div style="padding:20px 0;">
            Desired Level
        </div>
    </div>
    <app-circle-line style="width:100%" [circles]="circleLines.get(goalToShow.id!)"></app-circle-line>
    <div class="goal-chart">
        <div class="goal-chart-title">
            Goal Insights
        </div>



        <canvas baseChart [datasets]="barChartData[indexToShow]" [labels]="barChartLabels[indexToShow]"
            [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType"
            [colors]="colors">
        </canvas>


        <div class="indicators">
            <div class="indicator">
                <div class="circle" style="background-color: #F27769;"></div>
                <div class="text">OUTSTANDING!</div>
            </div>
            <div class="indicator">
                <div class="circle" style="background-color: #7209B7;"></div>
                <div class="text">Goal Met!</div>
            </div>
            <div class="indicator">
                <div class="circle" style="background-color: #A4A2E6;"></div>
                <div class="text">Almost There</div>
            </div>
            <div class="indicator">
                <div class="circle" style="background-color: #D7DAEC;"></div>
                <div class="text">Didn't Start</div>
            </div>
        </div>
    </div>

    <hr>
    <div class="section" style="margin-bottom:15px;">
        <div class="section-content">
            <strong>Language: </strong>
        </div>
        <div class="section-content">
            {{goalToShow.language}}
        </div>
    </div>
    <div class="section" style="margin-bottom:15px;">
        <div class="section-content">
            <strong>Teacher: </strong>
        </div>
        <div class="section-content">
            {{goalToShow.teacher.firstName}} {{goalToShow.teacher.lastName}}
        </div>
    </div>
    <div class="section">
        <div class="section-content">
            <strong>Starting {{goalToShow.language}} Level</strong>
        </div>
        <div class="section-content">
            {{goalToShow.startingLevel}}
        </div>
    </div>
    <div class="section">
        <div class="section-content">
            <strong>Current {{goalToShow.language}} Level</strong>
        </div>
        <div class="section-content">
            {{goalToShow.currentLevel}}
        </div>
    </div>
    <div class="section">
        <div class="section-content">
            <strong>{{goalToShow.language}} Level Goal</strong>
        </div>
        <div class="section-content">
            {{goalToShow.desiredLevel}}
        </div>
    </div>
    <div class="section">
        <div class="section-content">
            <strong>When</strong>
        </div>
        <div class="section-content">
            {{goalToShow.desiredAchieveDate | date: 'MMMM, y'}}
        </div>
    </div>
    <div class="section">
        <div class="section-content">
            <strong>Why</strong>
        </div>
        <div class="section-content">
            <div *ngFor="let reason of goalToShow.reasons">
                {{reason}}
            </div>
        </div>
    </div>
    <div class="section">
        <div class="section-content">
            <strong>What</strong>
        </div>
        <div class="section-content">
            <div *ngFor="let area of goalToShow.areas">
                {{area}}
            </div>
        </div>
    </div>
    <!-- </div> -->
</div>