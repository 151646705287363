<div class="range-slider relative">
    <span class="rs-bullet rs-label">{{rating}}</span>
    <div class="rs-range-wrap relative w-full">
        <input class="rs-range rs-range-line" type="range"
        [value]="rating"
            [(ngModel)]="rating" min="0" step="1" max="10"
            (input)="showSliderValue(
            $event.target.parentElement.parentElement.querySelector('.rs-bullet'), 
            $event.target)">
    </div>
</div>