import { Classroom } from "./classroom.model";
import { User } from "./user.model";

export interface Lesson {
    id: number,
    classroomId: number,
    status: LessonStatus,
    startingDate: Date,
    duration: number,
    isRecccuring: boolean,
    answered: string,
    requestedByName: string,
    recccuringGroupId?: string,
    requestedByAspUserId?: string,
    title?: string,
    level?: string,
    classroom?: Classroom,
    bbbLink: string,
    googleMeetURL?: string | null,
    bellBirdMeetingURL?: string | null
}

export interface LessonPostRequest {
    classroomId: number,
    status?: LessonStatus,
    startingDate: string,
    duration: number,
    isRecccuring: boolean,
}

export interface LessonUpdateRequest {
    id: number,
    classroomId: number,
    status: LessonStatus,
    startingDate: string,
    duration: number,
    isRecccuring: boolean,
    answered: string | boolean
}

export interface LessonRequest {
    requestedBy: User | null,
    answered: boolean
}

export enum LessonStatus {
    ARRANGED = "arranged",
    ARRANGED_TRIAL = "trial arranged",
    COMPLETED_TRIAL = "trial completed",
    CANCELED_TRIAL = "trial canceled",
    REQUESTED_TRIAL = "trial requested",
    COMPLETED = "completed",
    CANCELED = "canceled",
    NO_SHOW = "No Show",
    REARRANGE = "rearrange",
    REQUESTED = "requested"
}

// COMPLETED_TRIAL = "trial completed",

export interface BBB {
    teacherName: string,
    studentNames: string[],
    startDateTime: string,
    roomName: string,
    message: string,
    duration: number,
    lessonId: number
}