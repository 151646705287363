import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { TeacherGuard } from './core/guards/teacher.guard';
import { UserInfoGuard } from './core/guards/user.guard';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { TrialComponent } from './modules/trial/trial.component';
import { ProfileGuard } from './core/guards/profile.guard';
import { AppLayoutType } from './core/models/general.model';
import { LoggedInGuard } from './core/guards/logged-in.guard';
// import { LibraryComponent } from './modules/library_old/library.component';

const routes: Routes = [
  { path: "", redirectTo: "/dashboard", pathMatch: "full" },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard, TeacherGuard],
    data: { 
      layout: AppLayoutType.Default,
      isDashboard: true },
  },
  {
    path: "classrooms",
    loadChildren: () =>
      import("./modules/classroom/classroom.module").then(
        (m) => m.ClassroomModule
      ),
    canActivate: [AuthGuard, TeacherGuard],
    data: { gradient: "classrooms-gradient.png" },
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
    canActivate: [LoggedInGuard],
    data: { layout: AppLayoutType.Center, hideLeftMenu: true, isAuth: true },
  },
  {
    path: "certificates",
    loadChildren: () =>
      import("./modules/shared-link/shared-link.module").then(
        (m) => m.SharedLinkModule
      ),
  },
  {
    path: "trials",
    component: TrialComponent,
    canActivate: [AuthGuard, TeacherGuard],
    data: { gradient: "dashboard-gradient.png" },
  },
  // {
  //   path: "calendar",
  //   component: CalendarComponent,
  //   canActivate: [AuthGuard, TeacherGuard],
  //   data: { gradient: "calendar-gradient.png" },
  // },
  
  {
    path: "calendar",
    loadChildren: () =>
      import("./modules/calendar/calendar.module").then(
        (m) => m.CalendarModule
      ),
    canActivate: [AuthGuard],
    data: {
      layout: AppLayoutType.Default,
    },
  },
  {
    path: "teacher",
    loadChildren: () =>
      import("./modules/teacher-application/teacher-application.module").then(
        (m) => m.TeacherApplicationModule
      ),
    canActivate: [AuthGuard, TeacherGuard],
    data: {
      layout: AppLayoutType.Default,
      isTeacher: true,
      hideLeftMenu: true,
      gradient: "profile-gradient.png",
    },
  },
  {
    path: "notifications",
    loadChildren: () =>
      import("./modules/notifications/notifications.module").then(
        (m) => m.NotificationsModule
      ),
    canActivate: [AuthGuard],
    data: {
      layout: AppLayoutType.Default,
      animation: "Basic",
      hideLeftMenu: true,
      gradient: "profile-gradient.png",
    },
  },
  {
    path: "not-found",
    component: NotFoundComponent,
    canActivate: [AuthGuard],
    data: { gradient: "profile-gradient.png" },
  },
  {
    path: "user-profile",
    loadChildren: () =>
      import("./modules/user-profile/user-profile.module").then(
        (m) => m.UserProfileModule
      ),
    canActivate: [AuthGuard],
    data: { animation: "Basic", hideLeftMenu: true },
  },
  {
    path: "meet",
    loadChildren: () =>
      import("./modules/jitsi-meet/jitsi-meet.module").then(
        (m) => m.JitsiMeetModule
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "chat",
    loadChildren: () =>
      import("./modules/chat/chat.module").then(
        (m) => m.ChatModule
      ),
    canActivate: [AuthGuard],
    data: {
      layout: AppLayoutType.Default,
      animation: "Basic",
      hideLeftMenu: true,
      gradient: "profile-gradient.png",
    },
  },
  {
    path: "ai-chat",
    loadComponent: () =>
      import("./modules/dashboard/lingolette-room/lingolette-room.component").then(
        (m) => m.LingoletteRoomComponent
      ),
    canActivate: [AuthGuard],
  },
  // { path: 'dashboard', component: DashboardComponent, data: { "isDashboard": true, "gradient": "dashboard-gradient.png" } },
  // { path: 'classrooms', loadChildren: () => import('./modules/classroom/classroom.module').then(m => m.ClassroomModule), data: { "gradient": "classrooms-gradient.png" } },
  // { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule), data: { "hideLeftMenu": true, "isAuth": true } },
  // { path: 'trials', component: TrialComponent, data: { "gradient": "dashboard-gradient.png" } },
  // { path: 'notes', component: NoteComponent, data: { "gradient": "notes-gradient.png" } },
  // { path: 'library', component: LibraryComponent, data: { "gradient": "library-gradient.png" } },
  // { path: 'calendar', component: CalendarComponent, data: { "gradient": "calendar-gradient.png" } },
  // { path: 'homework', component: HomeworkComponent, data: { "gradient": "homework-gradient.png" } },
  // { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule), data: { animation: 'Basic', "isProfile": true, "hideLeftMenu": true, "gradient": "profile-gradient.png" } },
  // { path: 'teacher', loadChildren: () => import('./modules/teacher-application/teacher-application.module').then(m => m.TeacherApplicationModule), data: { "isTeacher": true, "hideLeftMenu": true, "gradient": "profile-gradient.png" } },
  // { path: 'not-found', component: NotFoundComponent, data: { "gradient": "profile-gradient.png" } },
  { path: "**", redirectTo: "/not-found", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
  providers: [AuthGuard, UserInfoGuard, TeacherGuard, ProfileGuard]
})
export class AppRoutingModule { }