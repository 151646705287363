<a [routerLink]="generalService.getLessonDetailsLink(classroom, this.authService.getUserRole())" 
   [state]="{ classRoom: classroom }" 
   [ngClass]="{ 'teacher-role': authService.isTeacher, 'trial-classroom': classroom.type === 'Trial' }">
<app-block-viewer [header]="classroomService.getFormattedStudentNames(classroom.classroomStudents)" headerBackgroundImage="" 
    [headerBlockClass]="getStatusBgColor(classroom.status) + ' '"
    headerClass="justify-content-center"
    [tooltipLabel]="classroomService.getFullStudentNames(classroom.classroomStudents)"
    headerTextClass="white-space-nowrap overflow-hidden text-overflow-ellipsis font-sm"
    blockClass="block-gradient border-round-xl cursor-pointer" containerClass=" relative mb-3">
    <div class="col-12">
    <ng-container *ngIf="isTrial">

        <div class="grid relative mt-1  mx-3 align-items-center justify-content-between border-line-bottom">

            <div class="col-6 grid align-items-center justify-content-center flex-column">

                <div class="title-text mb-2 font-sm">Date</div>
                <div class="flex flex-row gap-1">
                    <div><img *ngIf="!arrangedTrialLesson" src="assets/images/classrooms/total-hours-clock-blue.svg" alt="clock" class="icon"></div>
                    <div class="font-bold font-xs text-blue-dark-color"> {{arrangedTrialLesson ? (startingDate | date:
                        'dd MMM'): 'TBC'}} </div>
                </div>
            </div>
            <div class="divider"></div> <!-- New element for the divider -->
            <div class="col-6 grid align-items-center justify-content-center flex-column">
                <div class="title-text mb-2 font-sm">Time</div>
                <div class="flex flex-row gap-1">
                    <div><img *ngIf="!arrangedTrialLesson" src="assets/images/classrooms/total-times-clock-blue.svg" alt="clock" class="icon"></div>
                    <div class="font-bold font-xs text-blue-dark-color"> {{arrangedTrialLesson ? (generalService.getLessonTime(startingDate) ):
                        'TBC'}}</div>
                </div>
            </div>
        </div>

        <div class="flex  align-items-center justify-content-center flex-row gap-2  mt-2 mb-3">
            <div><img [src]="getStatusIcon(classroom.status)" alt="classroom"
                    class="img-fluid"></div>
            <div class="class-status text-blue-dark-color font-sm capitalize">{{classroom.status}}</div>
        </div>
        </ng-container>

        <ng-container *ngIf="!isTrial">
        <div class="grid relative mt-1  mx-3 align-items-center justify-content-between border-line-bottom">

            <div class="col-6 grid align-items-center justify-content-center flex-column">

                <div class="title-text mb-2 font-sm">Total</div>
                <div class="flex flex-row gap-1">
                    <div><img src="assets/images/classrooms/total-hours-clock-blue.svg" alt="clock" class="icon"></div>
                    <div class="font-bold font-xs text-blue-dark-color"> {{this.classroomService.getAccumulatedTotalHours(classroom.packages!) || 0 }}h </div>
                </div>
            </div>
            <div class="divider"></div> <!-- New element for the divider -->
            <div class="col-6 grid align-items-center justify-content-center flex-column">
                <div class="title-text mb-2 font-sm">Remaining</div>
                <div class="flex flex-row gap-1">
                    <div><img src="assets/images/classrooms/total-times-clock-blue.svg" alt="clock" class="icon"></div>
                    <div class="font-bold font-xs text-blue-dark-color"> {{this.classroomService.getAccumulatedHoursLeft(classroom.packages!) || 0 }}h </div>
                </div>
            </div>
        </div>

        <div class="flex  align-items-center justify-content-center flex-row gap-2  mt-2 mb-3">
            <div><img [src]="getStatusIcon(classroom.status)" alt="classroom"
                    class="img-fluid"></div>
            <div class="class-status text-blue-dark-color font-sm capitalize">{{classroom.status}}</div>
        </div>
        </ng-container>
    </div>
    <div class="absolute centered bottom-0 level-icon-position">

        <g-level-circle [name]="classroom.activeLevel"></g-level-circle>

    </div>
</app-block-viewer>
</a>