
<app-block-viewer header="Library" headerBackgroundImage="/assets/images/library/library-header-bg.png"
    blockClass="border-radius-bottom-10 mb-6 pb-2" containerClass="bg-white px-2 sm:px-3 py-2"
    [headerClass]="'justify-content-center my-2'" [headerTextClass]="'font-xl font-semibold justify-content-center'">

    <div class="notes grid flex-column md:flex-row" >
        <div #leftSide id="notes-left-side" *ngIf="!hasSelectedClassroom" class="notes-left-side lg:mt-2" [ngClass]="{'collapsed': isLeftsideCollapsed}">
            <ng-container *ngIf="(teacherClassrooms$ | async) as teacherClassrooms">
                <app-library-left-sidebar [leftSideHeight]="leftSideHeight" [classrooms]="teacherClassrooms" [autoSelectFirstClassroom]="authService.isStudent" 
                  (myFilesSelected)="getLibFolders()"
                  (classroomSelected)="onClassRoomSelected($event)" 
                  (collapsed)="oncollapseNotesLeftSideChanged($event)">
                </app-library-left-sidebar>
              </ng-container>
        </div>
        <div id="notes-right-side" [style.height]="'100%'" class="notes-right-side mt-2 pl-1" [ngClass]="{'expanded': isLeftsideCollapsed, 'w-full': hasSelectedClassroom}">

            <ng-container *ngIf="authService.isTeacher">
                
                <div class="relative h-20rem" *ngIf="isLoading">
                    <div class="abs-centered">
                        <app-loader [scale]="1.6"></app-loader>
                    </div>
                </div>
                <ng-container *ngIf="isMyFiles && !isLoading">
                    <!-- <div class="notes-header mt-4 mb-2">
                        <div class="notes-header-title">
                            Classroom Files
                        </div>
                    </div> -->
                    <div class="mb-2">
                        <div class="tabs lg:mt-7 mb-5">
                            <div class="tab">
                                <input type="radio" name="css-tabs" id="tab-1" checked class="tab-switch">
                                <label for="tab-1"
                                    class="tab-label flex align-items-center sm:gap-2 line-height-1">
                                    <img src="/assets/icons/file_manager.png" class="w-3rem">
                                    <div class="flex align-items-center gap-2"><span class="font-sm lg:w-4">Teacher Files</span> 
                                        <ng-container *ngIf="isLarge">
                                            <span class="font-xs">Files shared 
                                            by me </span>
                                        </ng-container>
                                    </div> 
                                        
                                    
                                </label>
                                <div class="tab-content border-round-xl" id="sharedWithAllFiles">
                                    <app-single-library *ngIf="!isLoading" [leftSideHeight]="leftSideHeight / 1.20" [allLibrariesWithFiles]="libsSharedBy"
                                        [classroomStudents]="classroomStudents" [classroomIdToAddLib]="classroomId"
                                        [withClassroom]="true" [canShare]="false" 
                                        [canUpload]="true"
                                        [availableActions]="[ 'View', 'Copy URL', 'Move', 'Unshare', 'Download']"
                                        [isInTab]="true"
                                        [fileListHeight]="leftSideHeight - 255"
                                        libraryTitle="Classroom Files">
                                    </app-single-library>
                                </div>
                            </div>
                            <div class="tab">
                                <input type="radio" name="css-tabs" id="tab-2" class="tab-switch">
                                <label for="tab-2"
                                    class="tab-label flex align-items-center sm:gap-2 line-height-1">
                                    <img src="/assets/icons/3d_graduation_cap_7.png" class="w-3rem">
                                    <div class="flex align-items-center gap-2"><span class="font-sm lg:w-4">Student Files</span> 
                                        <ng-container *ngIf="isLarge">
                                            <span class="font-xs">Files shared 
                                            by the students </span>
                                        </ng-container>
                                    </div> 
                                </label>
                                <div class="tab-content">
                                    <app-single-library [leftSideHeight]="leftSideHeight / 1.20" [allLibrariesWithFiles]="libsSharedWith"
                                        [classroomStudents]="classroomStudents" [withClassroom]="false"
                                        [availableActions]="['View', 'Copy URL', 'Download']"
                                        [canUpload]="false" [canShare]="false"
                                        [isInTab]="true"
                                        [fileListHeight]="leftSideHeight - 255"
                                        libraryTitle="Classroom Files"></app-single-library>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="lib-content pb-6">
                <app-single-library *ngIf="!isLoading" [allLibrariesWithFiles]="myLibs" 
                [availableActions]="['View', 'Copy URL', 'Download', 'Edit', 'Move', 'Share', 'Delete']"
                [extraGradientClass]="'blue'"
                [leftSideHeight]="!isMyFiles ? leftSideHeight / 1.17 : leftSideHeight / 1.20"
                [fileListHeight]="!isMyFiles ? (leftSideHeight - 170) : (leftSideHeight - 255)"
                ></app-single-library>
                </div>
            </ng-container>


            <ng-container *ngIf="authService.isStudent">
                <div class="relative h-20rem" *ngIf="isLoading">
                    <div class="abs-centered">
                        <app-loader [scale]="1.6"></app-loader>
                    </div>
                </div>
                <ng-container *ngIf="isMyFiles && !isLoading">
                    <!-- <div class="notes-header mt-4 mb-2">
                        <div class="notes-header-title font-2xl opaque-box-rtl col-4">
                            Classroom Files
                        </div>
                        <app-single-library-button-actions 
                        [canShare]="canShare"
                        [canUpload]="canUpload"
                        ></app-single-library-button-actions>
                    </div> -->
                    <div class="lib-content">
                        <div class="tabs mb-5">
                            <div class="tab">
                                <input type="radio" name="css-tabs" id="tab-1" (change)="onTabChange($event)" checked class="tab-switch">
                                <label for="tab-1"
                                    class="tab-label flex align-items-center sm:gap-2 line-height-1">
                                    <img src="/assets/icons/file_manager.png" class="w-3rem"> 
                                    <div class="flex align-items-center gap-2"><span class="font-sm lg:w-4">Teacher Files</span> 
                                        <ng-container *ngIf="isLarge">
                                            <span class="font-sm">Files shared 
                                                by the teacher </span>
                                        </ng-container>
                                    </div> 
                                </label>
                                <div class="tab-content border-round-xl" id="sharedWithAllFiles">

                                    <app-single-library *ngIf="!isLoading" [allLibrariesWithFiles]="libsSharedWith"
                                        [classroomStudents]="classroomStudents" [classroomIdToAddLib]="classroomId"
                                        [withClassroom]="false" [canShare]="false"
                                        [canUpload]="false" [hasLimitedOptions]="true"
                                        [availableActions]="['View', 'Copy URL', 'Download']"
                                        [isInTab]="true"
                                        [leftSideHeight]="leftSideHeight / 1.15"
                                        [fileListHeight]="authService.isStudent ? leftSideHeight - 255 : leftSideHeight - 160"
                                        libraryTitle="Classroom Files"></app-single-library>
                                </div>
                            </div>
                            <div class="tab">
                                <input type="radio" name="css-tabs" (change)="onTabChange($event)" id="tab-2" class="tab-switch">
                                <label for="tab-2"
                                    class="tab-label flex align-items-center sm:gap-2 line-height-1">
                                    <img src="/assets/icons/3d_graduation_cap_7.png" class="w-3rem">
                                    <div class="flex align-items-center gap-2"><span class="font-sm lg:w-4">Student Files</span> 
                                        <ng-container *ngIf="isLarge">
                                            <span class="font-sm">Files shared 
                                            by me </span>
                                        </ng-container>
                                    </div> 
                                </label>
                                <div class="tab-content">
                                    <app-single-library [allLibrariesWithFiles]="libsSharedBy"
                                        [classroomIdToAddLib]="classroomId" [classroomStudents]="classroomStudents"
                                        [availableActions]="['View', 'Copy URL', 'Download', 'Edit', 'Move', 'Delete']"
                                        [withClassroom]="true"
                                        [isInTab]="true"
                                        [fileListHeight]="authService.isStudent ? leftSideHeight - 255 : leftSideHeight - 160"
                                        [leftSideHeight]="leftSideHeight / 1.15"></app-single-library>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

        </div>
    </div>
</app-block-viewer>