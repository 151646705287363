<div #goalsWrapper>
<ng-container *ngIf="!isSmall">
    <ng-container *ngIf="showGoalSteps; else elseBlock">
        <app-goal-steps></app-goal-steps>
    </ng-container>
    <ng-template #elseBlock class="h-full">
        <app-block-viewer header="My Trip" headerBackgroundImage="/assets/images/dashboard/goals-title-gradient.png"
        blockClass="block-gradient-reverse overflow-auto border-round-xl bigger-header relative"
        [headerClass]="'justify-content-center'" [containerClass]="'py-5'">
            <div class="steps-full-wrapper flex align-items-center justify-content-center flex-column py-5">
                <img src="/assets/images/dashboard/target.png" class="target-img" alt="target" />
                <h3 class="text-primary font-semibold m-0 mt-3">Welcome to MyLingoTrip!</h3>
                <p class="mt-2 ">
                    Set your goals and we’ll help you keep
                    track and fulfill them!
                </p>
                <p-dropdown *ngIf="classrooms.length > 0" [options]="classrooms" [(ngModel)]="studentSelectedClassroom" optionLabel="classroomLabel" optionValue="id"></p-dropdown>
                <!-- <span style="display: none;">{{ studentSelectedClassroom.language }}</span> -->

                <button disabled pRipple class="rounded-blue-button mt-3" (click)="toggleGoalSteps()">SET GOALS <img  src="/assets/icons/arrow-right.svg" /></button>
            </div>
        </app-block-viewer>
    </ng-template>
</ng-container>
</div>

<ng-container *ngIf="isSmall">
    <app-block-viewer header="Goals" headerBackgroundImage="/assets/images/dashboard/gradient-sm-1.png"
    blockClass="block-gradient border-radius-bottom-10" containerClass="">
    <p-scrollPanel [style]="{width: '100%', height: '118px'}" styleClass="custombar1">
        <div class="flex align-items-center justify-content-evenly flex-row h-full">

            <img src="/assets/images/dashboard/target.png" class="target-img max-w-4rem" alt="target" />

            <div class="flex align-items-center justify-content-start flex-column text-center">
                <h4 class="text-primary font-semibold m-0 mt-0">Welcome to MyLingoTrip!</h4>
                <p class="mb-0">
                    Set your goals and we’ll help you keep

                </p>
                <button disabled pRipple class="rounded-blue-button sm p-button-sm mt-1 font-normal">SET GOALS
                    <img src="/assets/icons/arrow-right.svg" class="max-h-1rem" /></button>
            </div>
        </div>
        </p-scrollPanel>
    </app-block-viewer>
</ng-container>