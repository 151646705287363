import { Component, OnDestroy, OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { GeneralService } from 'src/app/core/services/general.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-notification-overview',
  templateUrl: './notification-overview.component.html',
  styleUrls: ['./notification-overview.component.scss']
})
export class NotificationOverviewComponent implements OnInit, OnDestroy {

  filterTypes = [
    {
      name: 'All',
      type: '',
    },
    {
      name: 'Lessons',
      type: 'Lessons',
    },
    {
      name: 'Packages',
      type: 'Packages',
    },
    {
      name: 'Availability',
      type: 'DaysOff',
    },
    {
      name: 'Trials',
      type: 'Trials',
    },
    {
      name: 'Student Actions',
      type: 'Student',
    },
    {
      name: 'Homework',
      type: 'Homework',
    },
  ];
  fromDate = '';
  toDate = '';
  currentDate = new Date();
  notifications: any[] = [];
  searchValue: string = '';
  currentFilterType: string = '';
  filteredNotifications: any[] = [];
  isTablet = false;
  showFiltersTablet = false;
  unreadNotifications: any[] = [];
  isLoadingNotifications = true;
  private subs = new SubSink();
  constructor(
    private notificationService: NotificationService,
    private generalService: GeneralService,
  ) { }

  ngOnInit(): void {

    this.subs.sink = this.notificationService.get()
      .pipe(switchMap((notifications: any) => {
        this.notifications = notifications;
        this.filteredNotifications = this.notifications.reverse();

        // Filter notifications to get only the unread ones
        this.unreadNotifications = this.notifications.filter(
          (notification) => !notification.isRead
        );

        // Get an array of IDs for the unread notifications
        const unreadNotificationIds = this.unreadNotifications.map(
          (notification) => notification.id
        );

        // Use the IDs of the unread notifications to mark them as read
        return this.notificationService.markAsReadBulk(unreadNotificationIds);
      })).subscribe(
        () => {
          this.isLoadingNotifications = false;
        },
        (error) => {
          this.isLoadingNotifications = false;
        }
      );

    this.subs.sink = this.generalService.deviceKind.subscribe(res => {
      this.isTablet = res.is1024;
      console.log(this.isTablet);
      this.showFiltersTablet = this.isTablet;
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  applyFilter(filterType: string) {
    console.log(`Filter type: ${filterType}`);
    // Update the current filter type
    this.currentFilterType = filterType;
    // Filter the notifications based on the selected filter type and search value
    this.filteredNotifications = this.notifications.filter(notification => {
      let dateCheck = true;

      // If a fromDate and toDate were selected, check if the notification's creation date is in range
      if (this.fromDate && this.toDate) {
        // Add 1 to the from and to dates to ensure that notifications created on the exact date are included
        const dateFrom = new Date(this.fromDate).setDate(new Date(this.fromDate).getDate() + 1);
        const dateTo = new Date(this.toDate).setDate(new Date(this.toDate).getDate() + 1);

        // Get the creation date of the notification
        const createdAt = new Date(this.generalService.convertWithoutTimeAndZ(notification.createdAt));

        // Check if the creation date falls within the selected range
        dateCheck = createdAt >= new Date(dateFrom) && createdAt <= new Date(dateTo);
      }
      // Check if the notification's description includes the search value (case-insensitive)
      let searchCheck = this.searchValue === '' || (notification.description.toLowerCase().includes(this.searchValue.toLowerCase()));

      // Check if the notification's type matches the selected filter type
      let filterCategoryCheck = !filterType || notification.type === this.currentFilterType;
      // Return the notification if it passes all three checks (date, search, and filter type)
      return dateCheck && searchCheck && filterCategoryCheck;
    });
  }

  onSearchInput(event: any): void {
    this.searchValue = event.target.value;
    this.applyFilter(this.currentFilterType);
  }

  onFromDateSelection(event: any): void {
    this.fromDate = this.generalService.convertToIsoDate(event);
    this.applyFilter('');
  }

  onToDateSelection(event: any): void {
    this.toDate = this.generalService.convertToIsoDate(event);
    this.applyFilter('');
  }

  removeNotificationSelected(notificationId: number) {
    this.subs.sink = this.notificationService.delete(notificationId).subscribe((res) => {
      if (res) {
        this.filteredNotifications = this.filteredNotifications.filter(item => item.id !== notificationId);
      }
    });
  }

  onCalendarClose(event: any) {
    console.log(event);
  }

  toggleFilterVisibility() {
    this.showFiltersTablet = !this.showFiltersTablet;
  }
}
