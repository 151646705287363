import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Classroom, Status } from 'src/app/core/models/classroom.model';
import { GeneralService } from 'src/app/core/services/general.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { Lesson } from 'src/app/core/models/lesson.model';
import { SubSink } from 'subsink';
import { RatingAndReportService } from 'src/app/core/services/rating-and-report.service';
import * as moment from 'moment';
import { PackageState } from 'src/app/core/models/package.model';
type ColorType = 'primary' | 'trial' | 'trial-completed' | 'disabled' | 'expired' | 'frozen';
@Component({
  selector: 'app-classroom-card',
  templateUrl: './classroom-card.component.html',
  styleUrls: ['./classroom-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClassroomCardComponent implements OnInit, AfterViewInit {
  private subs = new SubSink();
  @Input() percentage: number | null = null;
  @Input() color: ColorType = 'trial-completed';
  @Input() showRateButton = false;
  @Input() canRate = false;
  @Input() canBuy = false;
  @Input() canExtend = false;
  @Input() extraClass = '';
  // TODO add real lesson
  @Input() classRoom = {} as Classroom;
  classStatus = Status;
  isTablet = false;
  hasRated = false;
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    public generalService: GeneralService,
    public classroomService: ClassroomService,
    public ratingAndReportService: RatingAndReportService,
  ) { }

  ngOnInit(): void {
    if (this.isTrial) {
      this.showRateButton = true;

      this.subs.add(this.ratingAndReportService.getTeacherTrialRating(+this.classRoom.id).subscribe((res) => {
        console.log(res);
        if (res) {
          this.hasRated = !this.generalService.isObjectEmpty(res);
          console.log(this.hasRated);
          this.cdr.detectChanges();
        }
      }));
    }
  }

  ngAfterViewInit(): void {
    this.subs.sink = this.generalService.deviceKind.pipe(
    ).subscribe((res) => {
      this.isTablet = !res.w992up!;
    });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // Method to navigate to the lesson details page
  goToLessonDetails() {
    const navigationExtras: NavigationExtras = {
      state: { classRoom: this.classRoom }
    };
    if (this.isTrial) {
      // TODO add real lesson
      this.router.navigate(['dashboard/classrooms/lessons', this.classRoom.id, 'info', 'teacher'], {
        state: { classRoom: this.classRoom }
      });
    } else {
      this.router.navigate(['/dashboard/classrooms/lessons', this.classRoom.id, 'details'], navigationExtras);
    }
  }

  get ongoingOrLastPackageWithHoursLeft(): any {
    let ongoingPackage = null;
    let lastPackage = null;
  
    for (const pkg of this.classRoom.packages!) {
      if (pkg.state.toLowerCase() === PackageState.ONGOING.toLowerCase() && pkg.hoursLeft > 0) {
        ongoingPackage = pkg;
        break;
      } else {
        lastPackage = pkg;
      }
    }
  
    return ongoingPackage || lastPackage;
  }

  get isTrial(): boolean {
    return this.classRoom.type === 'Trial';
  }

  get getStatusColor(): string {
    if (this.classroomService.isTrialClassroomWithCompletedLesson(this.classRoom)) {
      return 'trial-completed';
    }
    // if (this.isTrial) {
    //   return 'trial';
    // }
    switch (this.classRoom.status.toLowerCase()) {
      case Status.EXPIRED.toLowerCase():
        return 'expired';
      case Status.INACTIVE.toLowerCase():
        return 'inactive-icon';
      case Status.ON_GOING.toLowerCase():
        return 'pending';
      case Status.ON_HOLD.toLowerCase():
        return 'on-hold-icon';
      case Status.PENDING.toLowerCase():
        return 'pending-icon';
      case Status.TRIAL.toLowerCase():
        return 'trial-icon';
      case Status.COMPLETED.toLowerCase():
        return 'completed';
      case Status.COMPLETED_TRIAL.toLowerCase():
        return 'trial-completed';
      case Status.ARRANGED_TRIAL.toLowerCase():
        return 'trial';
      case Status.ONGOING_TRIAL.toLowerCase():
        return 'trial';
      case Status.PENDING_TRIAL.toLowerCase():
        return 'trial';
      case Status.DISMISSED.toLowerCase():
        return 'frozen';
      case Status.DISMISSED_TRIAL.toLowerCase():
        return 'dismissed';
      default:
        return 'primary';
    }
  }

  getStatusIcon(): string {
    if (this.classroomService.isTrialClassroomWithCompletedFirstLesson(this.classRoom)) {
      return 'completed-icon';
    }

    switch (this.classRoom.status.toLowerCase()) {
      case Status.EXPIRED.toLowerCase():
        return 'expired';
      case Status.INACTIVE.toLowerCase():
        return 'inactive-icon';
      case Status.ON_GOING.toLowerCase():
        return 'ongoing';
      case Status.ON_HOLD.toLowerCase():
        return 'on-hold-icon';
      case Status.PENDING.toLowerCase():
        return 'pending-icon';
      case Status.TRIAL.toLowerCase():
        return 'trial-icon';
      case Status.COMPLETED.toLowerCase():
        return 'completed';
      case Status.DISMISSED.toLowerCase():
        return 'frozen';
      default:
        return 'default-icon';
    }
  }

  getFirstThreeRecentLessons(): Lesson[] {
    const now = moment();
    return this.classRoom.lessons!.sort(this.sortByDate).slice(0, 3);
  }

  get classroomPercentage() {
    let percentage = (
      (this.classroomService.getAccumulatedTotalHours(this.classRoom.packages!) - this.classroomService.getAccumulatedHoursLeft(this.classRoom.packages!)) /
      this.classroomService.getAccumulatedTotalHours(this.classRoom.packages!)
    ) * 100;
  
    if (percentage > 100) {
      percentage = 100;
    } else if (percentage < 0) {
      percentage = 0;
    }
  
    // Limit the number of decimal places to 2
    percentage = +percentage.toFixed(2);
  
    // Round up the percentage
    percentage = Math.ceil(percentage);
  
    // Remove the decimal and ensure the result ends in 0
    percentage = Math.floor(percentage / 10) * 10;
  
    return percentage;
  }

  hasAvailableHours() {
    return this.classroomService.getAccumulatedHoursLeft(this.classRoom.packages!) > 0;
  }

  private sortByDate(a: Lesson, b: Lesson) {
    const dateB = moment(b.startingDate);
    const dateA = moment(a.startingDate);
    return dateB.diff(dateA);
  }

}
