<app-block-viewer [header]="lessonBlockTitle"
headerBackgroundImage="/assets/images/dashboard/calendar/calendar-dialog-lesson-info-arranged.png"
blockClass="border-radius-bottom-10" headerBlockClass="py-1 border-round-lg bg-cover "
containerClass="bg-white relative" [headerClass]="'justify-content-center my-0'"
[headerTextClass]="'font-base font-semibold justify-content-center capitalize'" [showCloseDialogIcon]="true"
(closeDialogEvent)="onDialogClose()">

<app-lesson-rating (ratingSubmitted)="onDialogClose()" [users]="this.dialogData.classroom.classroomStudents" 
[lesson]="this.dialogData.lesson"
[classroom]="classroom"
[mode]="ratingMode">
</app-lesson-rating>
</app-block-viewer>