import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Classroom, Status } from 'src/app/core/models/classroom.model';
import { Lesson, LessonStatus } from 'src/app/core/models/lesson.model';
import { Package } from 'src/app/core/models/package.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { GeneralService } from 'src/app/core/services/general.service';

@Component({
  selector: 'app-teacher-classroom-mini-card',
  templateUrl: './teacher-classroom-mini-card.component.html',
  styleUrls: ['./teacher-classroom-mini-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeacherClassroomMiniCardComponent implements OnInit {

  @Input() isTrial: boolean = false;
  @Input() classroom = {} as Classroom;
  arrangedTrialLesson = {} as Lesson | undefined;
  startingDate!: Date;
  ongoingClassroomPackage = {} as Package;
  constructor(
    public generalService: GeneralService,
    public authService: AuthService,
    public classroomService: ClassroomService,
    ) { }

  ngOnInit(): void {
    if (!this.generalService.isNullishObject(this.classroom)) {
    }
    if (this.isTrial) {
      this.arrangedTrialLesson = this.classroom.lessons!.find(lesson => lesson.status.toLowerCase().includes("arranged") || lesson.status.toLowerCase().includes("completed"));
      if (this.arrangedTrialLesson) {
        this.startingDate = this.arrangedTrialLesson.startingDate;
      } else {
      }
    }

  }

  getOngoingClassroomPackage(): Package {
    this.ongoingClassroomPackage = this.classroomService.getOngoingPackage(this.classroom.packages!) as Package;
    console.log(this.ongoingClassroomPackage);
    if (this.classroom.packages && this.classroom.packages!.length > 0) {
      if (this.ongoingClassroomPackage) {

        if (this.generalService.isNullishObject(this.ongoingClassroomPackage)) {
          this.ongoingClassroomPackage = this.classroom.packages![0];
          return this.ongoingClassroomPackage;
        }
        return this.ongoingClassroomPackage;
      }
    }
    return {} as Package;
  }

  goToClassRoom() {
    this.generalService.navigateToLessonDetails(this.classroom, this.authService.getUserRole());
  }

  getTrialStatusIcon(status: string): string {
    if (status.toLowerCase().includes("arranged")) {
      return "assets/icons/lessons/paper-plane-blue.svg";
    } else if (status.toLowerCase().includes("dismissed")) {
      return "assets/icons/lessons/dismissed-blue.svg";
    } else if (status.toLowerCase().includes("completed")) {
      return "assets/icons/lessons/completed-blue.svg";
    } else {
      return "assets/icons/lessons/paper-plane-blue.svg";
    }
  }

  getStatusBgColor(status: string): string {

    switch (status.toLowerCase()) {
      case Status.COMPLETED.toLowerCase():
        return 'blue-completed';
      case Status.COMPLETED_TRIAL.toLowerCase():
        return 'blue-completed-trial';
      case Status.ON_GOING.toLowerCase() && !this.isTrial:
        return 'blue-dark';
        case Status.EXPIRED.toLowerCase() && !this.isTrial:
          return 'expired-gradient-lesson-bg';
          case Status.DISMISSED_TRIAL.toLowerCase():
            return 'dismissed-gradient-lesson-bg';
    }
    if (this.isTrial) {
      return 'purple-gradient';
    }
    return 'blue-dark';
  }

  getStatusIcon(status: string): string {
    if (this.isTrial) {
      return 'assets/icons/lessons/paper-plane-blue.svg';
    }
    switch (status.toLowerCase()) {
      case Status.COMPLETED.toLowerCase():
      case Status.COMPLETED_TRIAL.toLowerCase():
        return 'assets/icons/classroom/blue-completed.svg';
      case Status.ON_GOING.toLowerCase():
        return 'assets/icons/classroom/blue-dark.svg';
      case Status.EXPIRED.toLowerCase():
        return '/assets/icons/classroom/expired.svg';
    }
    return 'assets/icons/classroom/blue-dark.svg';
  }

}
