import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MenuItem } from "primeng/api";
import { Folder } from "src/app/core/models/library.model";

@Component({
  selector: "app-single-library-folder-item",
  templateUrl: "./single-library-folder-item.component.html",
  styleUrls: ["./single-library-folder-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleLibraryFolderItemComponent implements OnInit {
  @Input() hasLimitedOptions = false;
  @Input() folder = {} as Folder;
  @Output() deleteFolder = new EventEmitter<Folder>();
  @Output() openFolder = new EventEmitter<Folder>();
  constructor() {}

  ngOnInit(): void {}

  getFolderContextMenu(item: any): MenuItem[] {
    const context = item;
    return [
      {
        label: "Delete folder",
        icon: "pi pi-fw pi-trash",
        command: (event) => {
          this.deleteFolder.emit(context);
        },
      },
    ];
  }

  openFolderClicked() {
    this.openFolder.emit(this.folder);
  }
}
