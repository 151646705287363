<div #list class="list w-full">
    <div class="flex" style="padding: 10px">
        <div class="font-xs">Students List</div>
        <!-- <img (click)="closeList()" src="/assets/icons/close.png" class="close-img close-img-abs hvr-glow"> -->
    </div>
    <div class="p-0-10">
        <!-- <span class="p-input-icon-right w-full">
            <input type="text" [(ngModel)]="term" pInputText placeholder="Search" class="h-2rem w-full input-blue gradient-blue rounded" />
            <i class="pi pi-search text-primary"></i>
        </span> -->
        <!--  | filter: term -->
        <div *ngFor="let classroom of classrooms" class="mt-2 single-classroom-wrapper">
            <div class="send-classroom-title w-full justify-content-between surface-border">
                <div class="title">
                    {{getClassroomTitle(classroom)}}</div>
                    <p-checkbox styleClass="primary-blue" binary="true" label="" (onChange)="addClassroom($event, classroom)"
                    [ngModel]="isClassroomChecked(classroom) || (alreadyAssignedFromUsers(classroom.classroomStudents) && showBlur)"></p-checkbox>
            </div>
            <div *ngIf="getuniqueClassroomStudents(classroom.classroomStudents).length > 1">
                <div *ngFor="let user of getuniqueClassroomStudents(classroom.classroomStudents)">
                    <div class="classroom-users-list  mt-3 w-full justify-content-between surface-border" style="margin-top: 5px;">
                        <div class="m-l-15">
                            {{user.firstName}} {{user.lastName}}</div>
                            <div>
                                <p-checkbox styleClass="primary-blue" binary="true" label="" (onChange)="checkUser($event, user, classroom)"
                                [ngModel]="isUserChecked(user, classroom) || (alreadyAssigned(user) && showBlur)"></p-checkbox>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>