import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { Level } from 'src/app/core/models/classroom.model';
import { CircleLine, CircleType, StudentGoal } from 'src/app/core/models/goal.model';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { GoalService } from 'src/app/core/services/goal.service';
// import { ChartOptions, Chart } from 'chart.js';
import "../../../../assets/ts/chartjs-rounded-corners";

@Component({
  selector: 'app-view-goals',
  templateUrl: './view-goals.component.html',
  styleUrls: ['./view-goals.component.scss']
})
export class ViewGoalsComponent implements OnInit {
  @ViewChild('viewGoals') public viewGoals: any;
  @Input() student: User = this.authService.getLoggedInUser();
  public weeklyHoursCircle: CircleLine[][][] = []
  public showWeek: boolean[][] = []
  public studentGoals: StudentGoal[] = []
  public currentWeek: number[] = [];
  public circleLines: Map<string, CircleLine[]> = new Map();

  // Note: we MUST define colors for custom types
  // see https://github.com/valor-software/ng2-charts/issues/876
  public colors: any[][] = [];
  goalToShow: StudentGoal = {} as StudentGoal;


  constructor(
    private authService: AuthService,
    private goalService: GoalService
  ) { }

  ngOnInit(): void {
    // this.goalService.updateListener.subscribe(()=>{
    //   this.goalService.getGoals().pipe(take(1)).subscribe(res => {
    //     console.log(res)
    //     this.studentGoals = res;
    //     if(res.length>0){
    //       this.circleLines = this.goalService.getGoalLevelsInCircle(this.studentGoals, 'goals');
    //       this.goalToShow = this.studentGoals[0];
    //       for (let i = 0; i < this.studentGoals.length; i++) {
    //         this.barChartData[i] = []
    //         this.barChartData[i].push({
    //           data: this.studentGoals[i].hoursCompleted,
    //           barThickness: 30,
    //           barPercentage: 1,
    //           borderColor: (context) => {
    //             const index = context.dataIndex!;
    //             return this.getBarBorderColor(this.studentGoals[i], index) + " !important"
    //           },
    //           backgroundColor: (context) => {
    //             const index = context.dataIndex!;
    //             return this.getBarColor(this.studentGoals[i], index)
    //           },
    //           borderWidth: 2,
    //         })
    //         this.barChartLabels[i] = [];
    //         this.colors[i] = [];
    //         for (let j = 0; j < this.studentGoals[i].hoursCompleted.length; j++) {
    //           this.barChartLabels[i].push("Week " + (j + 1))
    //           this.colors[i].push({
    //             backgroundColor: "hack",
    //             borderColor: "hack",
    //             hoverBackgroundColor: "hack",
    //             hoverBorderColor: "hack"
    //           })
    //         }
    //       }
    //       this.circleLines = this.goalService.getGoalLevelsInCircle(this.studentGoals, 'view-goals');
    //       let g = 0;
    //       for (let goal of this.studentGoals) {
    //         setTimeout(() => {
      
    //           // this.createStats(goal)
    //         }, 1000);
    //         this.weeklyHoursCircle[g] = []
    //         this.showWeek[g] = []
    //         for (let w = 0; w < goal.totalWeeks; w++) {
    //           this.weeklyHoursCircle[g][w] = []
    //           if (!goal.hoursCompleted[w]) {
    //             for (let i = 0; i < goal.hoursWeekly; i++) {
    //               this.showWeek[g][i] = false;
    //               let circleLine: CircleLine = {
    //                 text: (i + 1).toString() + "h",
    //                 lineStyle: '1px dashed lightgray',
    //                 background: 'white',
    //                 color: '#a4a2e6',
    //                 width: document.getElementById('view-goals')!.clientWidth / (goal.hoursWeekly - 1) - 42 + 'px',
    //                 type: CircleType.GENERAL
    //               }
    //               this.weeklyHoursCircle[g][w].push(circleLine)
    //             }
    //           } else {
    //             let iMax = goal.hoursCompleted[w] > goal.hoursWeekly ? goal.hoursWeekly: goal.hoursCompleted[w]
    //             for (let i = 0; i < iMax; i++) {
    //               this.showWeek[g][i] = false;
    //               let line = i < iMax - 1 ? '1px solid lightgray' : '1px dashed lightgray'
      
    //               let circleLine: CircleLine = {
    //                 text: (i + 1).toString() + "h",
    //                 lineStyle: line,
    //                 background: '#a4a2e6',
    //                 color: 'white',
    //                 width: document.getElementById('view-goals')!.clientWidth / (goal.hoursWeekly - 1) - 42 + 'px',
    //                 type: CircleType.GENERAL
    //               }
    //               this.weeklyHoursCircle[g][w].push(circleLine)
    //             }
    //             for (let i = iMax; i < goal.hoursWeekly; i++) {
    //               let circleLine: CircleLine = {
    //                 text: (i + 1).toString() + "h",
    //                 lineStyle: '1px dashed lightgray',
    //                 background: 'white',
    //                 color: '#a4a2e6',
    //                 width: document.getElementById('view-goals')!.clientWidth / (goal.hoursWeekly - 1) - 42 + 'px',
    //                 type: CircleType.GENERAL
    //               }
    //               this.weeklyHoursCircle[g][w].push(circleLine)
    //               this.showWeek[g][i] = false;
    //             }
    //           }
    //         }
    //         this.showWeek[g][goal.hoursCompleted.length - 1] = true
    //         this.currentWeek[g] = goal.hoursCompleted.length - 1
    //         g++;
    //       }
    //     }
    //   })
    // })
  }

  ngAfterContentInit() {


  }
  indexToShow: number = 0;
  changeIndex(event: any) {
    // this.indexToShow = this.studentGoals.findIndex(((el: StudentGoal) => el.language == event.value.language));
    // this.goalToShow = this.studentGoals[this.indexToShow];
  }

  getBarColor(goal: StudentGoal, hoursCompletedIndex: number) {
    // if (goal.hoursCompleted[hoursCompletedIndex] / goal.hoursWeekly <= 0.1) {
    //   return "#D7DAEC"
    // }
    // if (goal.hoursCompleted[hoursCompletedIndex] / goal.hoursWeekly <= 0.9) {
    //   return "#A4A2E6"
    // }
    // if (goal.hoursCompleted[hoursCompletedIndex] / goal.hoursWeekly <= 1) {
    //   return "#7209B7"
    // }
    return "#F27769"
  }

  getBarBorderColor(goal: StudentGoal, hoursCompletedIndex: number) {
    // if (goal.hoursCompleted[hoursCompletedIndex] / goal.hoursWeekly <= 0.25) {
    //   return "#c5cbf1"
    // }
    // if (goal.hoursCompleted[hoursCompletedIndex] / goal.hoursWeekly <= 0.5) {
    //   return "#8b87f0"
    // }
    // if (goal.hoursCompleted[hoursCompletedIndex] / goal.hoursWeekly <= 0.75) {
    //   return "#6301a5"
    // }
    return "#e66456"
  }

  createStats(goal: StudentGoal) {


    // return new Promise((resolve, reject) => {
    //   const doughnut: Chart = new Chart("stats-" + goal.id!, {
    //     type: "bar",
    //     data: {
    //       labels: labels,
    //       datasets: [{
    //         borderColor: (context) => {
    //           const index = context.dataIndex!;
    //           return this.getBarBorderColor(goal, index)
    //         },
    //         backgroundColor: (context) => {
    //           const index = context.dataIndex!;
    //           return this.getBarColor(goal, index)
    //         },
    //         borderWidth: 2,
    //         data: goal.hoursCompleted,
    //       }]
    //     },
    //     options: {
    //       // cornerRadius: 20,
    //       scales: {
    //         yAxes: [{
    //           display: true,
    //           stacked: true,
    //           ticks: {
    //             min: 0, // minimum value
    //             max: 10 // maximum value
    //           }
    //         }]
    //       },
    //       responsive: true,
    //       legend: {
    //         display: false,
    //         position: "right",
    //         labels: {
    //           usePointStyle: true,
    //           boxWidth: 8,
    //           fontSize: 15,
    //           fontColor: "black",
    //           // padding: 9
    //         },
    //       }
    //     },
    //   });
    //   if (doughnut) {
    //     resolve(doughnut);
    //   } else {
    //     reject('No doughnut found for: ' + goal.id);
    //   }
    // });
  }

  showWeekLeft(i: number) {
    this.showWeek[i][this.currentWeek[i]] = false
    this.showWeek[i][this.currentWeek[i] - 1] = true
    this.currentWeek[i]--
  }

  showWeekRight(i: number) {
    this.showWeek[i][this.currentWeek[i]] = false
    this.showWeek[i][this.currentWeek[i] + 1] = true
    this.currentWeek[i]++
  }

}
