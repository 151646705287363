import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Classroom, Level } from '../models/classroom.model';
import { Category } from '../models/homework.model';
import { Folder, FoldersWithFiles, Library, LibraryFile, UploadFilesToFolderRequest } from '../models/library.model';
import { User } from '../models/user.model';
import { UserService } from './user.service';
const BACKEND_URL = environment.apiUrl
@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  private refs: any[] = [];
  private appendDialogActionsTo = 'some-id';

  private showGroupActions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showGroupActions: Observable<boolean> = this.showGroupActions$.asObservable();

  private currentSelectedClassroomId$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public currentSelectedClassroomId: Observable<number> = this.currentSelectedClassroomId$.asObservable();

  private myLibrariesListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public myLibrariesListener: Observable<boolean> = this.myLibrariesListener$.asObservable();

  private updateListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public updateListener: Observable<boolean> = this.updateListener$.asObservable();

  private libraryUpdatedListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public libraryUpdatedListener: Observable<boolean> = this.libraryUpdatedListener$.asObservable();

  private updateFolderListener$: BehaviorSubject<{ libraryFile: LibraryFile, folder: Folder }> = new BehaviorSubject<{ libraryFile: LibraryFile, folder: Folder }>({ libraryFile: {} as LibraryFile, folder: {} as Folder });
  public updateFolderListener: Observable<{ libraryFile: LibraryFile, folder: Folder }> = this.updateFolderListener$.asObservable();

  private deleteListener$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public deleteListener: Observable<number> = this.deleteListener$.asObservable();

  private deleteFolderListener$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public deleteFolderListener: Observable<number> = this.deleteFolderListener$.asObservable();

  private showGroupActionsListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public showGroupActionsListener: Observable<boolean> = this.showGroupActionsListener$.asObservable();

  private showUploadActionsListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showUploadActionsListener: Observable<boolean> = this.showUploadActionsListener$.asObservable();

  checkedLibraries: LibraryFile[] = [];
  libraryToAdd: Library = {} as Library;
  folderToDelete: Folder = {} as Folder;
  selectedlibraryFile: LibraryFile = {} as LibraryFile;
  currentSelectedClassroom: Classroom = {} as Classroom;

  isPreviewingFromChat = signal(false);
  checkedLibraryFiles = signal<LibraryFile[]>([]);

  setUpdateFolderListener(libraryFile: LibraryFile, folder: Folder) {
    this.updateFolderListener$.next({ libraryFile: libraryFile, folder: folder });
  }

  setLibraryUpdatedListener(val: boolean) {
    this.libraryUpdatedListener$.next(val);
  }

  moveFileToFolder(oldId: number, newId: number) {
    return this.http.post<LibraryFile>(BACKEND_URL + "/Library/MoveFileToFolder?fileId=" + oldId + "&newFolderId=" + newId, {})

  }

  setDeleteListener(id: number) {
    this.deleteListener$.next(id);
  }

  setDeleteFolderListener(id: number) {
    this.deleteFolderListener$.next(id);
  }

  setGroupActions(showGroupActions: boolean) {
    this.showGroupActions$.next(showGroupActions);
  }

  setshowGroupAction(showGroupActions: boolean) {
    this.showGroupActionsListener$.next(showGroupActions);
  }

  setshowUploadAction(showGroupActions: boolean) {
    this.showUploadActionsListener$.next(showGroupActions);
  }

  setCheckedNotes(checkedLibraries: LibraryFile[]) {
    this.checkedLibraries = checkedLibraries;
  }

  public rootFolder: Folder = {
    parent: "",
    name: "home"
  }

  constructor(
    private userService: UserService,
    private http: HttpClient,
  ) { }

  initializeEmptyLibraryObject(): Library {
    return {
      classroom: 0,
      libraryFiles: [],
      folder: this.rootFolder,
    }
  }

  getFileFullPath(file: string) {
    return environment.apiUrl + "/" + file;
  }

  getLibraryTitle(library: Library) {
    let title = "";
    let i = 0;
    let comma = ", "
    for (let file of library.libraryFiles) {
      i++;
      if (i == library.libraryFiles.length) {
        comma = ""
      }
      title += this.getFileName(file.path) + comma;
    }
    return title;
  }

  getFileName(path: string) {
    if (path) {
      let n = path.lastIndexOf('/');
      let result = path.substring(n + 1);
      return result;
    }
    return path
  }

  getLibraryFiles(library: Library) {
    let fileNames = [];
    for (let file of library.libraryFiles) {
      fileNames.push({ file: { name: this.getFileName(file.path) }, progress: 100 });
    }
    return fileNames;
  }

  getFolderPathOfFolder(folder: Folder, path: Folder[], folders: Folder[]) {
    path.push(folder);
    if (folder.parent === "") {
      return []
    }
    if (folder.parent === this.rootFolder.name) {
      return path.reverse()
    } else {
      let parentFolder = folders.filter(el => el.name === folder.parent)[0]
      this.getFolderPathOfFolder(parentFolder, path, folders);
    }
    return path
  }

  getInnerFoldersOfLibraries(foldersWithFiles: FoldersWithFiles[], folders: Folder[]): Folder[] {
    let innerFolders: Folder[] = [];
    for (let folderWithFiles of foldersWithFiles) {
      let folder: Folder = {
        name: folderWithFiles.name,
        parent: folderWithFiles.parent
      }
      let frontFolders = this.getInnerFoldersOfFolder(folder, folders);
      innerFolders = innerFolders.concat(frontFolders);
    }
    return innerFolders;
  }

  getInnerFoldersOfFolder(folder: Folder, folders: Folder[]) {
    let frontFolders = folders.filter(el => {
      return el.parent == folder.name
    })
    return frontFolders.reverse()
  }

  getLibrariesOnRootFolders(foldersWithFiles: FoldersWithFiles[]): FoldersWithFiles[] {
    return foldersWithFiles.filter((library: FoldersWithFiles) => {
      return library.name === this.rootFolder.name
    });
  }

  getLibrariesOfFolder(folder: Folder, foldersWithFiles: FoldersWithFiles[]): FoldersWithFiles[] {
    return foldersWithFiles.filter((library: FoldersWithFiles) => library.name == folder.name && library.parent == folder.parent)
  }

  createLibrary(library: Library) {
    return this.http.post(BACKEND_URL + "/Library/CreateLibrary", library)
  }

  getUserLibraries(userId: string): Observable<Library[]> {
    return this.http.get<Library[]>(BACKEND_URL + "/Library/GetLibrariesUserCreated?UserId=" + userId)
  }

  createFolder(folder: Folder) {
    return this.http.post<Folder>(BACKEND_URL + "/Library/CreateFolder", folder)
  }

  uploadFilesToFolder(files: UploadFilesToFolderRequest) {
    return this.http.post<LibraryFile[]>(BACKEND_URL + "/Library/UploadFilesToFolder", files)
  }

  shareFileWithClassUsers(files: LibraryFile[]) {
    return this.http.post<LibraryFile[]>(BACKEND_URL + "/Library/ShareFileWithClassUsers", files)
  }

  unShareLibrary(files: LibraryFile[]) {
    return this.http.post<LibraryFile[]>(BACKEND_URL + "/Library/UnShareLibrary", files)
  }

  getUserCreatedLibFolders() {
    return this.http.get<FoldersWithFiles[]>(BACKEND_URL + "/Library/GetUserCreatedLibFolders")
  }

  getSharedByMeLibFolders(classroomId: number) {
    return this.http.get<FoldersWithFiles[]>(BACKEND_URL + "/Library/GetShareByMe?ClassId=" + classroomId)
  }

  getShareWithMeLibFolders(classroomId: number) {
    return this.http.get<FoldersWithFiles[]>(BACKEND_URL + "/Library/GetShareWithMe?ClassId=" + classroomId)
  }

  setCurrentSelectedClassroomId(id: number) {
    this.currentSelectedClassroomId$.next(id);
  }

  setCurrentSelectedClassroom(classroom: Classroom) {
    this.currentSelectedClassroom = classroom;
  }

  getCurrentSelectedClassroom() {
    return this.currentSelectedClassroom;
  }

  setMyLibrariesListener(listen: boolean) {
    this.myLibrariesListener$.next(listen);
  }

  setUpdateListener(listen: boolean) {
    this.updateListener$.next(listen);
  }

  setFolderToDelete(folder: Folder) {
    this.folderToDelete = folder;
  }

  getFolderToDelete(): Folder {
    return this.folderToDelete;
  }

  setLibraryFile(libFile: LibraryFile) {
    this.selectedlibraryFile = libFile;
  }

  getLibraryFile(): LibraryFile {
    return this.selectedlibraryFile;
  }

  public openDialogWithComponent(dialogService: any, component: any, width = 360, dialogData?: any, appendDialogActionsTo?: any, resultHandler?: (result: any) => void) {
    this.refs.forEach(ref => ref.close());
    this.refs = [];
    let centerX = '50%';
    let centerY = '50%';
    if (appendDialogActionsTo) {
      if (Object.keys(appendDialogActionsTo).length > 0) {
        const element = document.querySelector('#' + appendDialogActionsTo);
        const rect = element!.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2 - width / 2;
        const centerY = rect.top;
      }
    }
    const dialogRef = (dialogService.open(component, {
      header: '',
      width: width + 'px',
      showHeader: false,
      dismissableMask: true,
      modal: true,
      contentStyle: {
        "max-width": "100%", "max-height": "400px", "overflow": "auto", "border-radius": "10px", "padding": "0px",
      },
      style: appendDialogActionsTo ? { 'left': centerX, 'top': centerY, 'position': `fixed` } : {},
      baseZIndex: 10000,
      maskStyleClass: 'transparent-mask',
      data: { dialogData: dialogData },
    }));
    this.refs.push(dialogRef);
    dialogRef.onClose.subscribe((data: any) => {
      console.log('Dialog closed with data:', data);
      if (resultHandler) {
        resultHandler(data);
      }
    });
  }

  // getClassroomFiles(classroomId: number) {
  //   return this.http.get<any>(BACKEND_URL + "/Library/GetClassroomFiles?classroomid=" + classroomId)
  // }

  getClassroomFiles(classroomId: number) {
    return this.http.get<any>(BACKEND_URL + "/Library/GetMyClassroomFiles?classroomId=" + classroomId)
  }

  uploadClassroomFilesToFolder(req: any) {
    return this.http.post<any>(BACKEND_URL + "/Library/UploadClassroomFilesToFolder", req)
  }

  getUsersLibraryHasBeenSharedWith(fileId: any) {
    return this.http.get<any>(BACKEND_URL + "/Library/GetUsersLibraryHasBeenSharedWith?fileId=" + fileId)
  }

  delete(fileId: any) {
    return this.http.post<any>(BACKEND_URL + "/Library/DeleteLibrary?libraryId=" + fileId, {})
  }

  deleteFolder(folderId: any) {
    return this.http.post<any>(BACKEND_URL + "/Library/DeleteFolder?FolderId=" + folderId, {})
  }

  updateLibraryFileName(fileId: any, newName: string) {
    return this.http.post<any>(BACKEND_URL + "/Library/UpdateLibraryFileName?fileId=" + fileId + "&newName=" + newName, {});
  }

  getTeacherClassRooms() {
    return this.http.get<any>(BACKEND_URL + "/Classrooms/GetTeacherClassrooms/");
  }

  editFileLevels(newLevels: any) {
    return this.http.post<any>(BACKEND_URL + "/Library/EditFileLevels", newLevels);
  }

  editFileCategories(newFileCategories: any) {
    return this.http.post<any>(BACKEND_URL + "/Library/EditFileCategories", newFileCategories);
  }

}
