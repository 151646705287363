<app-block-viewer [header]="this.dialogData.dialogTitle ? this.dialogData.dialogTitle : this.dialogData.action"
    headerBackgroundImage="/assets/images/dashboard/calendar/calendar-dialog-lesson-info-arranged.png"
    blockClass="border-radius-bottom-10" headerBlockClass="py-1 border-round-lg bg-cover sticky top-0 z-5 "
    containerClass="block-gradient-reverse px-3 py-2 relative" [headerClass]="'justify-content-center my-0'"
    [headerTextClass]="'font-base font-semibold justify-content-center capitalize'" [showCloseDialogIcon]="true"
    (closeDialogEvent)="onDialogClose()">


    <ng-container *ngIf="this.dialogData.action === 'rename'">
        <div class="library-info grid">
            <div class="w-full flex align-items-center justify-content-center py-2">
                <div class="library-file-details py-1 font-sm flex flex-column">
                    <input id="fileName" class="input-element" [(ngModel)]="this.dialogData.libraryFile.name">
                    <div class="flex align-items-center justify-content-center text-center mt-2">
                        <button (click)="onDialogClose({action: 'rename', libraryFile: this.dialogData.libraryFile})"
                            pRipple pbutton label="" icon="pi pi-discord" class="element ripple p-button-raised p-button-rounded white-space-nowrap p-button btn-gradient-blue p-component w-10rem 
                        font-lg flex justify-content-center">
                            <span class="p-button-icon p-button-icon-right" aria-hidden="true"></span>
                            Submit
                            <img src="/assets/icons/forward-arrow.svg" width="18" class="ml-2" />
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="this.dialogData.action === 'shareWith' || this.dialogData.action === 'unShareWith' || this.dialogData.action === 'send' || this.dialogData.action === 'send-and-share'">
        <h4 class="font-base">{{this.dialogData.dialogsubTitle}}</h4>
        <app-classrooms-check-list [preselectedClassroom]="this.dialogData.preselectedClassroom" [showBlur]="true" (checkedList)="onCheckedList($event)">
        </app-classrooms-check-list>
        <div class="py-2 sticky bottom-0 flex  justify-content-center align-items-center">
            <button pButton pRipple type="button" label="Ok" (click)="onSendSharedWith()" icon="pi pi-check"
                iconPos="right" class="p-button-sm p-button-raised p-button-rounded blue-teal mt-3"></button>
        </div>
    </ng-container>

    <ng-container *ngIf="this.dialogData.action === 'shareWithMultiple'">
        <h4 class="font-base">{{this.dialogData.dialogsubTitle}}</h4>
        <app-classrooms-check-list [showBlur]="true" (checkedList)="onCheckedList($event)">
        </app-classrooms-check-list>
        <div class="py-2 sticky bottom-0 flex  justify-content-center align-items-center">
            <button pButton pRipple type="button" label="Ok" (click)="onSendSharedWith()" icon="pi pi-check"
                iconPos="right" class="p-button-sm p-button-raised p-button-rounded blue-teal mt-3"></button>
        </div>
    </ng-container>

    <ng-container *ngIf="this.dialogData.action === 'move' || this.dialogData.action === 'moveMultiple'">
        <h4 class="font-base">{{this.dialogData.dialogsubTitle}}</h4>
        <span class="p-input-icon-right w-full">
            <input type="text" [(ngModel)]="term" pInputText placeholder="Search" class="h-2rem w-full input-blue gradient-blue rounded" />
            <i class="pi pi-search text-primary"></i>
        </span>

        <ul class="p-0 mx-0 mt-2 mb-1 list-none overflow-y-scroll h-11rem">
            <!--  | filter: term -->
            <ng-container *ngFor="let innerFolder of (this.dialogData.folders)">
            <li class="flex align-items-center py-1 pr-1 border-bottom-1 surface-border folder-hover pointer"
                 (click)="moveSelected(innerFolder)">
                <div class="w-2rem h-1rem flex align-items-center justify-content-center mr-3 flex-shrink-0">
                    <i class="pi pi pi-folder text-blue-500"></i>
                </div><span class="text-900 line-height-3">
                    <span class="text-700" [style.marginLeft.px]="innerFolder.marginLeft"> {{innerFolder.name}}</span></span>
                <div class="circle ml-auto border-circle w-1rem h-1rem cursor-pointer"
                    [ngClass]="{'blue-teal': selectedFolder === innerFolder}">
                </div>
            </li>
        </ng-container>
        </ul>
        <div class="bg-white py-1 sticky bottom-0 flex  justify-content-center align-items-center">
            <button pButton pRipple type="button" [disabled]="!selectedFolder" label="Ok" (click)="onMoveSubmitted()" icon="pi pi-check"
                iconPos="right" class="p-button-sm p-button-raised p-button-rounded blue-teal mt-3"></button>
        </div>
    </ng-container>

    <ng-container *ngIf="this.dialogData.action === 'upload'">
        <h4 class="font-base">{{this.dialogData.dialogsubTitle}}</h4>
        <app-upload-files (onSubmit)="onUploadedFilesSubmitted()" class="p-20" title="Library"
        [fileDropImage]="'/assets/icons/library/upload-file-lib.svg'" [fileDropImageWidth]="10"
        [isProfile]="false"
        [files]="files"
        [teacherFilesLength]="dialogData.libraryToAdd.libraryFiles.length" [dndUI]="true" [showSubmitButton]="true"
        [checkboxUI]="true" postUrl="/Upload/UploadLibraryFile" (onUploadFinished)="uploadFinished($event)"
        (tagChoosen)="onTagChoosen($event)">
        </app-upload-files>
    </ng-container>
    
    <ng-container *ngIf="this.dialogData.action === 'editFileLevelsCategories'">
        <div class="bordered-tags">
            <div class="library-edit-title justify-content-center my-2" *ngIf="!showEditRename">
                <img src="/assets/icons/library/surface1.svg">
                <label class="font-sm text-800 in-title p-2 flex gap-3">
                    <span class="file-path-text w-15rem">{{getFileName(modifiedFile.name)}}</span>
                    <i class="pi pi-pencil pointer" (click)="showEditRename = !showEditRename"></i>
                </label>
            </div>
            <ng-container *ngIf="showEditRename">
                <div class="library-info">
                    <div class="w-full flex align-items-center justify-content-center py-1">
                        <div class="library-file-details py-1 font-sm flex flex-column">
                            
                            
            <div class="library-edit-title justify-content-center align-items-center">
                <img src="/assets/icons/library/surface1.svg">
                <label class="font-sm text-800 in-title flex gap-3 px-2 align-items-center">
                    <input id="fileName" class="input-element new-filename-input file-path-text w-15rem" [ngModel]="getFileName(modifiedFile.name)"
                        (ngModelChange)="newFileName = $event">
                    <i class="pi pi-pencil pointer" (click)="showEditRename = !showEditRename"></i>
                </label>
            </div>

                
                            <!-- <div class="flex align-items-center justify-content-center text-center mt-2">
                                <button
                                    (click)="onDialogClose({action: 'rename', libraryFile: this.dialogData.libraryFile})"
                                    pRipple pbutton label="" icon="pi pi-discord" class="element ripple p-button-raised p-button-rounded white-space-nowrap p-button btn-gradient-blue p-component w-10rem 
                            font-lg flex justify-content-center">
                                    <span class="p-button-icon p-button-icon-right" aria-hidden="true"></span>
                                    Submit
                                    <img src="/assets/icons/forward-arrow.svg" width="18" class="ml-2" />
                                </button>
                            </div> -->
                        </div>
    
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="bordered-tags">
            <app-choose-tags [applyToAll]="false" [libraryFile]="this.dialogData.libraryFile"
                (chooseTag)="onChooseTag($event)"></app-choose-tags>
        </div>
    
        <div class="py-1 sticky bottom-0 flex  justify-content-center align-items-center">
            <button pButton pRipple type="button" (click)="onTagsSubmitted()" label="Save" icon="pi pi-check" iconPos="right"
                class="p-button-sm p-button-raised p-button-rounded blue-teal mt-3"></button>
        </div>
    </ng-container>

</app-block-viewer>