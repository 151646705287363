import { Classroom, Level } from "./classroom.model";
import { Category } from "./homework.model";
import { User } from "./user.model";

export interface StudentGoal {
    id?: string,
    studentId?: string,
    startingLevel: Level,
    currentLevel: Level,
    desiredLevel: Level,
    desiredAchieveDate: Date,
    hoursWeekly: number,
    reasons: GoalReason[],
    areas: Category[],
    language: string,
    teacher: User,
}

export enum GoalReason {
    EDUCATION = "For educational / academic reasons",
    BOOST = "For work, to boost my career",
    CERTIFICATE = "To obtain a certificate / pass an exam",
    COMMUNICATE = "To communicate with friends or family members",
    MOVING = "I’m moving abroad / fresh start in another country",
    HOBBY = "As a hobby",
    TRAVELING = "For traveling reasons (communicating with the locals)",
    CULTURE = "To understand other cultures better"
}

export interface CircleLine {
    text: string,
    extraText?: string,
    extraTextML?: string,
    lineStyle: string,
    background: string,
    color: string,
    width?: string,
    type: CircleType
}

export enum CircleType {
    CURRENT_LEVEL = "Current Level",
    DESIRED_LEVEL = "Desired Leve",
    GENERAL = "General"
}