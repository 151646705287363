import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class LoaderComponent implements OnInit, AfterViewInit {
  @Input() size = '320';
  @Input() scale = '1';
  @ViewChild('loaderEl', { static: true }) loaderEl: ElementRef = {} as ElementRef;
  constructor() { }

  ngOnInit(): void {
    this.addLottiePlayerToStep(this.loaderEl);
  }

  ngAfterViewInit() {
  }

  private addLottiePlayerToStep(el: ElementRef): void {
    const lottiePlayer = `<div  class="animation-wrapper"><lottie-player src="/assets/js/lottie-json/loader.json" background="transparent" 
    speed="1" style="width: `+ this.size + `px; height: ` + this.size + `px; transform: scale(` + this.scale + `);" autoplay loop></lottie-player></div>`;
    el.nativeElement.innerHTML = lottiePlayer;
  }

}
