import { Classroom, Level } from "./classroom.model"
import { Library, LibraryFile } from "./library.model"
import { User } from "./user.model"

export interface Homework {
    id?: string,
    title: string,
    description: string,
    homeWorkTasks: HomeworkTask[],
    pinned: boolean,
    deadLine: Date | null | string,
    status: string,
    state: string,
    submitted: Date | null,
    classroom: number,
    assignedTo: User[],
    levels: string[],
    categories: string[]
}

export interface HomeworkTask {
    id?: string,
    title: string,
    finished: boolean,
    teacherFiles: HomeworkTaskFile[],
    studentFiles?: string[],
    assignedTo?: User,
    assignedToUserInClassroom?: string,
    assignedToClassroom?: Classroom,
    libraries?: LibraryFile[],
    link?: string,
    rate?: number,
    proportion: string,
    dismissed?: boolean
}

export interface HomeworkTaskFile {
    filePath: string,
    levels: (Level.A1 | Level.A2 | Level.B1 | Level.B2 | Level.C1 | Level.C2)[],
    categories: (Category.VOCABULARY | Category.GRAMMAR | Category.WRITING | Category.READING | Category.LISTENING | Category.SPEAKING | Category.REVISION | Category.TEST)[]
}

export enum Category {
    VOCABULARY = "Vocabulary",
    GRAMMAR = "Grammar",
    WRITING = "Writing",
    READING = "Reading",
    LISTENING = "Listening",
    SPEAKING = "Speaking",
    REVISION = "Revision",
    TEST = "Test",
}

export enum HomeworkStatus {
    COMPLETED_RATED = "Completed Rated",
    COMPLETED_UNRATED = "Completed Unrated",
    PARTIALLY_COMPLETED_PARTIALLY_RATED = "Completed Partially Rated",
    COMPLETED_PARTIALLY_RATED = "Partially Completed Partially Rated",
    COMPLETED_PARTIALLY_UNRATED = "Partially Completed Unrated",
    PARTIALLY_COMPLETED_RATED = "Partially Completed Rated",
    NO_COMPLETED_RATED = "Partially Completed Rated",
    PENDING = "Pending",
}

export enum Proportion {
    NOT_DONE = "Not done",
    SLIGHTLY_DONE = "Slightly done",
    MOSTLY_DONE = "Mostly done",
    COMPLETED = "Completed"
}

export interface HomeworkPostRequest {
    title: string,
    description: string,
    deadLine: string | null,
    status: string,
    state: string,
    levels: string[],
    categories: string[],
    homeWorkTasks: HomeworkTaskToSend[]
}

export interface HomeworkTaskToSend {
    id?: string,
    title: string,
    finished: boolean,
    assignedTo: string,
    studentFiles: string[],
    classroomId: number,
    teacherFiles: HomeworkTaskFile[],
    link?: string,
    rate?: number,
    proportion: string,
    dismissed?: boolean
    libraryFileIds: number[]
}

export interface UpdateHomeworkReq {
    id: number,
    title: string,
    description: string,
    deadLine: string | null,
    status: string,
    state: string,
    levels: string[],
    categories: string[]
}