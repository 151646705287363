import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'g-level-circle',
  templateUrl: './g-level-circle.component.html',
  styleUrls: ['./g-level-circle.component.scss'],
})
export class GLevelCircleComponent implements OnInit {

  @Input() name = 'A1';
  @Input() size = 'base';
  @Input() textSize!: string;
  @Input() extraClass = '';
  @Input() scale = 1;
  constructor() { }

  ngOnInit(): void {
  }

  get extraDutchCssClass() {
    return this.name ? this.name.includes('NT') ? 'dutch' : '' : null;
  }
}
