import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClassroomsFiltersModalComponent } from './modules/classroom/classrooms/classrooms-filters-modal/classrooms-filters-modal.component';
import { AuthModule } from './modules/auth/auth.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth-interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { TeacherApplicationModule } from './modules/teacher-application/teacher-application.module';
import { NotFoundModule } from './modules/not-found/not-found.module';
import { CertificateModule } from './modules/certificate/certificate.module';
import { ToastService } from './core/services/toast.service';
import { SharedModule } from './shared/shared.module';
import { NotificationsModule } from './modules/notifications/notifications.module';
import {RippleModule} from 'primeng/ripple';
import { MenuModule } from 'primeng/menu';
import {BadgeModule} from 'primeng/badge';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { DatePipe } from '@angular/common';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { ChatComponent } from './modules/chat/chat.component';
import { FirebaseAppModule } from '@angular/fire/app';
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    ClassroomsFiltersModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    NotFoundModule,
    TeacherApplicationModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CertificateModule,
    SharedModule,
    NotificationsModule,
    RippleModule,
    MenuModule,
    BadgeModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // for firestore
    FirebaseAppModule,
    ChatComponent,
  ],
  providers: [
    // provideAppCheck(() =>
    //   initializeAppCheck(undefined, {
    //     provider: new ReCaptchaV3Provider('6Le24q4hAAAAALsdgBnyhXQxTU_Nqe1r-Jj3kHyX'),
    //     isTokenAutoRefreshEnabled: true,
    //   })
    // ),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY,
    //   useValue: RECAPTCHA_V3_STACKBLITZ_KEY
    // },
    DatePipe,
    ToastService
  //TODO GOOGLE LOGIN

    // SocialAuthService,
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: GoogleLoginProvider.PROVIDER_ID,
    //         provider: new GoogleLoginProvider(
    //           '883654869277-m2mlgsroh8vsmt0tg82lmo4cnt0cuqs3.apps.googleusercontent.com'
    //         )
    //       },
    //     ],
    //   } as SocialAuthServiceConfig
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
