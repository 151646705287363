import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { SubSink } from 'subsink';
import * as moment from 'moment';

interface Day {
  number: number;
  name: string;
  availability: string;
}

@Component({
  selector: 'app-calendar-agenda',
  templateUrl: './calendar-agenda.component.html',
  styleUrls: ['./calendar-agenda.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarAgendaComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('daysTableWrapper', {static: true}) public daysTableWrapper: any;
  private subs = new SubSink();
  @Input() enableButtons = true;
  @Input() reverseGradient = false;
  @Input() title = 'Agenda';
  @Input() lessons: any = [];
  @Input() calHeaderClass = 'cal-header flex justify-content-center align-items-center';
  @Input() currentSelectedDay = null;
  @Input() userId = '';
  @Input() showAvailability: boolean = false;
  @Output() dayClicked = new EventEmitter<Date>();
  @Output() agendaHeight = new EventEmitter<number>();
  @Output() currentMonthChanged = new EventEmitter<number>();
  weekdays: string[] = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  currentDate: Date = new Date();
  selectedDays: { [key: string]: number } = {};
  user: any;
  availability: any;
  monthAvailability: any[] = [];
  daysOff: any;
  loaded = false;
  
  constructor(
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private calendarService: CalendarService,   
    private authService: AuthService, 
    private classroomService: ClassroomService, 
    ) { }

  ngOnInit(): void {

    if (this.currentSelectedDay) {
      this.currentDate = new Date(this.currentSelectedDay);
    } else {
      this.currentDate = new Date();
    }
    this.user = this.authService.getLoggedInUser();
    if (this.showAvailability) {
      // this.loadAvailability();
    } else {
      this.loaded = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.lessons.length > 0) {
      this.groupLessonsByDateAndStatus();

    }
    if (changes.userId && !changes.userId.firstChange) {
      this.userId = changes.userId.currentValue;
      if (changes.currentSelectedDay && changes.currentSelectedDay.currentValue) {
        this.currentDate = (changes.currentSelectedDay.currentValue);
      }
      this.loadAvailability();
      this.cdr.detectChanges();
    }
    if (changes.currentSelectedDay && !changes.currentSelectedDay.firstChange) {
      this.currentDate = (changes.currentSelectedDay.currentValue);
      this.currentDate.setMonth(changes.currentSelectedDay.currentValue.getMonth());
      this.setSelectedDay(this.currentDate.getDate());
      if (this.showAvailability) {
        this.loadAvailability();
      }
      this.cdr.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    this.calculateAgendaHeight();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getDaysInMonth(date: Date): number {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0).getDate();
  }
  
  getWeeksInMonth(date: Date): Day[][] {
    const weeks: Day[][] = [];
    const startOfMonthDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstDayOfMonth = (startOfMonthDate.getDay() + 6) % 7; // 0 for Sunday, 1 for Monday, etc.
    const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  
    const startOfCalendarDate = new Date(date.getFullYear(), date.getMonth(), 1 - firstDayOfMonth);
    const numWeeksInMonth = Math.ceil((firstDayOfMonth + daysInMonth) / 7); // Round up to ensure we show all days
    const daysInCalendar = numWeeksInMonth * 7;
  
    for (let i = 0; i < daysInCalendar; i += 7) {
      const week: any[] = [];
      for (let j = 0; j < 7; j++) {
        const dayNumber = (i + j + 1) - firstDayOfMonth;
        const dayDate = new Date(date.getFullYear(), date.getMonth(), dayNumber);
        const isPrevMonth = dayNumber < 1;
        const isNextMonth = dayNumber > daysInMonth;
        const isCurrentMonth = !isPrevMonth && !isNextMonth;

        const day = {
          number: isCurrentMonth ? dayNumber : '',
          name: this.weekdays[j],
          isPrevMonth,
          isNextMonth,
          isCurrentMonth,
        };
        week.push(day);
      }
      weeks.push(week);
    }
    return weeks;
  } 
  isTodayDate(day: number): boolean {
    const currentDate = moment();
    const clickedDate = moment(this.currentDate).date(day);
  
    return (
      clickedDate.isSame(currentDate, 'day') &&
      clickedDate.isSame(this.currentDate, 'month')
    );
  }

  getCurrentMonth(): string {
    this.calendarService.setCalendarAgendaMonthListener(moment(this.currentDate).toDate());
    this.currentMonthChanged.emit(moment(this.currentDate).month());
    return moment(this.currentDate).format('MMMM');
  }

  goForwardMonth(): void {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    this.checkAvailability();
    this.calculateAgendaHeight();
  }

  goBackwardMonth(): void {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    this.checkAvailability();
    this.calculateAgendaHeight();
  }

  setSelectedDay(day: number): void {
    this.selectedDays = {};
    const key = `${this.currentDate.getFullYear()}-${this.currentDate.getMonth()}`;
    this.selectedDays[key] = day;
  }

  get selectedDay(): number {
    const key = `${this.currentDate.getFullYear()}-${this.currentDate.getMonth()}`;
    return this.selectedDays[key];
  }

  onDayClicked(day: number): void {
    if (this.authService.isStudent) {
      if (this.showAvailability && this.isDayInThePast(day)) {
        return;
      }
    }
    const clickedDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), day);
    this.dayClicked.emit(clickedDate);
    this.classroomService.setSelectedAgendaDate(clickedDate);
    this.setSelectedDay(day);
  }

  isCurrentMonth(dayNumber: number): boolean {
    return new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), dayNumber).getMonth() === this.currentDate.getMonth();
  }

  isNextMonth(dayNumber: number): boolean {
    return new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), dayNumber).getMonth() === this.currentDate.getMonth() + 1;
  }

  loadAvailability(): void {
    if (!this.userId) {
      return;
    }
    if(this.showAvailability){
      const data = {
        ...this.calculateMonthBounds(this.currentDate),
        userId: this.userId
      };
      this.subs.sink = this.getAvailabilityFromRange(data).subscribe((res) => {
        this.monthAvailability = res.calendarAvailability;
        this.daysOff = res.daysOff;
        this.loaded = true;
        this.cdr.detectChanges();
      });
    }
    else {
      this.loaded = true;
    }
  }

  isDayOff(dayNumber: number): boolean {
    if(this.showAvailability){
      const dateString = this.currentDate.getFullYear() + '-' + (this.currentDate.getMonth() + 1) + '-' + dayNumber;
      const currentDate = new Date(dateString);
    
      for (const dayOff of this.daysOff) {
        const from = new Date(dayOff.period.from);
        const to = new Date(dayOff.period.to);
    
        if (currentDate >= from && currentDate <= to) {
          return true;
        }
      }
    }
    return false;
  }

  isDayInThePast(dayNumber: number): boolean {
    const dateString = this.currentDate.getFullYear() + '-' + (this.currentDate.getMonth() + 1) + '-' + dayNumber;
    const today = new Date();
    const currentDate = new Date(dateString);

    // Set hours, minutes, seconds, and milliseconds to 0
    today.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    return currentDate < today;
  }

  isHighAvailability(dayNumber: number): boolean {
    if (this.showAvailability) {
      const dateString = `${this.currentDate.getFullYear()}-${this.currentDate.getMonth() + 1}-${dayNumber}`;
      const currentDate = new Date(dateString);
  
      const availability = this.monthAvailability.find((availability) => {
        const availabilityDateParts = availability.date.split('/').map(Number);
        const availabilityDate = new Date(availabilityDateParts[2], availabilityDateParts[1] - 1, availabilityDateParts[0]);
  
        return currentDate.toDateString() === availabilityDate.toDateString();
      });
  
      if (availability) {
        return Math.abs(availability.availableHours) >= 4;
      }
    }
    
    return false;
  }

   
  getTodaysLessons(day: number): any[] {
    const foundDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), day);
    const lessonGroups = this.lessons.reduce((groups: any, lesson: any) => {
      const date = new Date(lesson.startingDate).toLocaleDateString();
      const status = lesson.status;
      if (!groups[date]) {
        groups[date] = {};
      }
      if (!groups[date][status]) {
        groups[date][status] = { count: 0 };
      }
      groups[date][status].count++;
      return groups;
    }, {});
    const todaysLessons = lessonGroups[foundDate.toLocaleDateString()] || {};
    const todaysLessonCounts = Object.keys(todaysLessons).reduce((counts: any, status: string) => {
      counts[status] = todaysLessons[status].count;
      return counts;
    }, {});

    return todaysLessonCounts;
  }

  /**
   * Converts a status object to an array of objects with status and count properties.
   * @param {Object} status - The status object to convert.
   * @returns {Object[]} An array of objects with status and count properties.
   */
  getStatusArray(status: {}): Object[] {
    return Object.entries(status).map(([key, value]) => {
      return { status: key.toLowerCase().replace(' ', '-'), count: value };
    });
  }

  getItemsPositionTop(i: number) {
    if (i === 0) {
      return 2;
    } else if (i === 1) {
      return 15;
    } else if (i === 2) {
      return 29;
    } else if (i === 3) {
      return 40;
    } else if (i === 4) {
      return 38;
    }
    return 10;
  }

  getItemsPositionRight(i: number) {
    if (i === 0) {
      return 11;
    } else if (i === 1) {
      return 3;
    } else if (i === 2) {
      return 3;
    } else if (i === 3) {
      return 12;
    } else if (i === 4) {
      return 26;
    }
    return 10;
  }
  
  private getAvailabilityFromRange(requestData: any): Observable<any> {
    return this.calendarService.retrieveCalendarAvailability(requestData);
  }

  /**
   * Checks the availability of the retrieve availability endpoint.
   * If showAvailability is true, it will load the availability data.
   * Otherwise, it will mark the calendar as loaded.
   *
   * @returns {void}
   */
  private checkAvailability(): void {
    if (this.showAvailability) {
      this.loaded = false;
      this.loadAvailability();
    } else {
      this.loaded = true;
    }
  }
  
  private calculateMonthBounds(date: Date): { fromDate: string, toDate: string } {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    firstDay.setDate(firstDay.getDate() + 1);

    lastDay.setDate(lastDay.getDate() + 1);
    const firstDayFormatted = firstDay.toISOString();
    const lastDayFormatted = lastDay.toISOString();
  
    return { fromDate: firstDayFormatted, toDate: lastDayFormatted };
  }

  private groupLessonsByDateAndStatus() {
    this.lessons = [].concat(...this.lessons) as [];
    console.log(this.lessons);
    const lessonGroups = this.lessons.reduce((groups: any, lesson: any) => {
      const startingDate = moment(lesson.startingDate);
      const dateString = startingDate.format('YYYY-MM-DD');
      const status = lesson.status;
      if (!groups[dateString]) {
        groups[dateString] = {};
      }
      if (!groups[dateString][status]) {
        groups[dateString][status] = { count: 0 };
      }
      groups[dateString][status].count++;
      return groups;
    }, {});
  }

  private calculateAgendaHeight() {
    setTimeout(() => {
    const elementHeight = this.elementRef.nativeElement.offsetHeight;
    console.log('Element height:', elementHeight);
    // this.agendaHeight.emit(elementHeight);
    this.calendarService.setCalendarAgendaHeightListener(elementHeight);
    }, 10); // Wait for 1 second before calculating the height

  }
}