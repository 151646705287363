<ng-container *ngIf="slimStyle">
    <li class="notification-item-wrapper flex align-items-center  
                flex-wrap mb-1 text-md text-primary"
        [ngClass]="{'slim px-2 py-1': slimStyle, 'px-2 py-2': !slimStyle, 'is-read': !notification.isRead}">
        <div class=" w-full lg:w-11 text-sm font-medium main-text flex align-items-center gap-3">
            <img [src]="getNotifIcon(notification.type)" class="pl-2" (error)="onNotificationIconError($event)"
                style="max-height: 20px;" />
            <span [class]="textSizeClass">{{notification.description}}</span>
        </div>
        <!-- <div class="w-full lg:w-2 lg:flex-order-0 flex-order-1 date">{{notif.createdAt |
                    date:'dd/MM/yyyy h:mm a'}}</div> -->
        <div class="w-full lg:w-1  justify-content-end text-primary" [ngClass]="slimStyle ? 'hidden' : 'flex'">
            <i class="pi pi-trash font-bold" (click)="onRemoveNotification(notification.id!)"></i>
        </div>
    </li>
</ng-container>

<ng-container *ngIf="!slimStyle">
    <li class="notification-item-wrapper flex align-items-center justify-content-between py-1 px-3 border-top-1 surface-border flex-wrap mb-2 font-sm"
        [ngClass]="{'is-read': notification.isRead}">
        <div class=" w-full lg:w-7 main-text flex align-items-center gap-2">
            <img [src]="getNotifIcon(notification.type)" (error)="onNotificationIconError($event)" class="w-1rem" />
            <span>{{notification.description}}.</span>
        </div>
        <div class="w-full lg:w-3 lg:flex-order-0 flex-order-1 date justify-content-center font-xs">
            {{notification.createdAt |
            date:'dd/MM/yyyy h:mm a'}}</div>
        <div class="w-full lg:w-1 flex justify-content-end text-primary">
            <i class="pi pi-trash pointer" title="Remove Notification"
                (click)="onRemoveNotification(notification.id)"></i>
        </div>
    </li>
</ng-container>