<!-- <div *ngIf="choosenClassroom && choosenClassroom.id && lessons.length > 0"> -->
<div *ngIf="choosenClassroom && choosenClassroom.id">
    <div class="back">
        <div class="back-button" (click)="goBack()">
            <img src="/assets/icons/back-main-color.svg">
            <div style="margin-left:20px">Back</div>
        </div>
        <div style="width:100%; text-align: center; font-weight: bold;">
            <div *ngIf="lessonToReschedule.id">
                <span *ngIf="role=='Teacher'">
                    Rescheduling Lesson at: {{getLessonDate()}}
                </span>
                <span *ngIf="role=='Student'">
                    Request Reschedule for Lesson at: {{getLessonDate()}}
                </span>
                <span style="font-size:15px">
                    &nbsp;(you can drag and drop the lesson)
                </span>
            </div>
            <div *ngIf="!lessonToReschedule.id">
                <span *ngIf="role=='Teacher'">
                    Create new Lesson
                    <span *ngIf="choosenClassroom?.id">
                        for {{getClassroomTitle()}}
                    </span>
                </span>
                <span *ngIf="role=='Student'">
                    Request new Lesson
                </span>
            </div>

        </div>
    </div>
    <div style="display:flex; flex-wrap:wrap; background-color: white; padding:20px; border-radius: 0 0 24px 24px;">
        <div style="position: absolute;width: 36%;top: 77px;z-index: 1;">
            <div *ngIf="!lessonToReschedule.id && classroomService.classroomToArrangeLesson?.id==undefined" style="display: flex; flex-direction: column; align-items: center">
                <strong>
                    Choose classroom
                </strong>
                <div style="margin:15px">
                    <p-dropdown [(ngModel)]="choosenClassroom" class="dropdown" [options]="classrooms" (onChange)="onChooseClassroomByStudent($event)"
                        optionLabel="title">
                        <ng-template let-classroom pTemplate="item">
                            <div class="country-item">
                                <div class="country-name">{{getClassroomTitle2(classroom)}} <span *ngIf="classroom.type=='Trial'">({{classroom.type}})</span></div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center">
                <strong>
                    Choose Duration
                </strong>
                <div style="margin:15px">
                    <p-dropdown [(ngModel)]="durationSelect" placeholder="Choose Duration" class="dropdown" [options]="duration"
                        (onChange)="onChooseDuration($event)">
                    </p-dropdown>
                </div>
            </div>
            <app-agenda [classroom]="choosenClassroom" style="width:100%" [isRescheduling]="true" [lessons]="lessons">
            </app-agenda>
            <div style="width:86%; display: flex; justify-content: space-between; font-size: 12px; margin: 20px 30px;">
                <div style="display:flex; align-items: center;">
                    <div style="width:15px; height:15px; border-radius: 50%; background: #DAD9FF; margin:0 5px">
                    </div>
                    <div>
                        High Availability
                    </div>
                </div>
                <div style="display:flex; align-items: center;">
                    <div style="width:15px; height:15px; border-radius: 50%; background: #9C99FF; margin:0 5px">
                    </div>
                    <div>
                        Mid Availability
                    </div>
                </div>
                <div style="display:flex; align-items: center;">
                    <div style="width:15px; height:15px; border-radius: 50%; background: #2D2A4B; margin:0 5px">
                    </div>
                    <div>
                        No Availability
                    </div>
                </div>
            </div>
            <div class="btns" style="margin-top:30px">
                <div class="light-purple-button" (click)="schedule()">
                    <span *ngIf="role=='Student'">
                        Request
                    </span>
                    <span *ngIf="role=='Teacher'">
                        Schedule
                    </span>
                </div>
            </div>
        </div>
        <app-scheduler [duration]="durationSelect" [studentSelectedClassroom]="choosenClassroom" style="width:70%; margin-left:30%"
            [showIndicators]="false" [isRequest]="true" [showHeader]="false"></app-scheduler>
    </div>
</div>
<!-- <div *ngIf="!(choosenClassroom && choosenClassroom.id && lessons.length > 0)" -->
<div *ngIf="!(choosenClassroom && choosenClassroom.id)"
    style="width:100%; display: flex; justify-content: center; height:700px; background-color:white; border-radius:24px;">
    <mat-spinner class="spinner" style="margin-top:250px;"></mat-spinner>
</div>