import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Level } from 'src/app/core/models/classroom.model';
import { CircleLine, CircleType, StudentGoal } from 'src/app/core/models/goal.model';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { GoalService } from 'src/app/core/services/goal.service';

@Component({
  selector: 'app-goal',
  templateUrl: './goal.component.html',
  styleUrls: ['./goal.component.scss']
})
export class GoalComponent implements OnInit {
  @Input() student: User = this.authService.getLoggedInUser();
  @Input() showDetails: boolean = true;
  public studentGoals: StudentGoal[] = []
  public circleLines: Map<string, CircleLine[]> = new Map();

  constructor(
    private authService: AuthService,
    private goalService: GoalService
  ) { }

  ngOnInit(): void {
    this.goalService.updateListener.subscribe(res=>{
      this.goalService.getGoals().pipe(take(1)).subscribe(res => {
        this.studentGoals = res;
        this.circleLines = this.goalService.getGoalLevelsInCircle(this.studentGoals, 'goals');
      })
    })
  }

}
