import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Classroom } from 'src/app/core/models/classroom.model';
import { Lesson } from 'src/app/core/models/lesson.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { GeneralService } from 'src/app/core/services/general.service';
import * as moment from 'moment';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { skip } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { ScrollPanel } from 'primeng/scrollpanel';
import { User } from 'src/app/core/models/user.model';

interface DateOptions {
  weekday: 'short' | 'long' | 'narrow' | undefined;
  day: 'numeric' | '2-digit';
  month: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
}

@Component({
  selector: 'app-lesson-schedule-box',
  templateUrl: './lesson-schedule-box.component.html',
  styleUrls: ['./lesson-schedule-box.component.scss']
})
export class LessonScheduleBoxComponent implements OnInit, OnChanges {
  private subs = new SubSink();
  @ViewChild('scrollPanel', {static: false}) public scrollPanel: ScrollPanel = {} as ScrollPanel;
  @Input() reverseGradient = false;
  @Input() lessons = [];
  @Input() classroom = {} as Classroom;
  @Input() classRooms: Classroom[] = [];
  @Input() disableAddNewLessonButton = false;
  @Input() currentDate = new Date();
  @Input() scrollHeight = 300;
  formattedDate = '';
  filteredLessons = [] as Lesson[];
  selectedMonthDate = new Date();
  selectedFilterType: string = 'upcoming';
  public user: User = {} as User;
  constructor(
    public generalService: GeneralService,
    public classroomService: ClassroomService,
    public authService: AuthService,
    public calendarService: CalendarService,
    public cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    const todayDate = new Date();
    this.formattedDate = this.formatShownDate(todayDate); // e.g. THU 15 OCT
    this.user = this.authService.getLoggedInUser();

    this.filteredLessons = this.lessons;
    this.filterLessonsBy(this.selectedFilterType);
    this.initDateListener();
    this.initCalendarAgendaMonthListener();
  }

  ngAfterViewInit() {
    this.initCalendarAgendaHeightListener();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // remove empty arrays
    this.lessons = this.lessons.flatMap(arr => arr);
    this.filterLessonsBy(this.selectedFilterType);
    if (changes.currentDate && !changes.currentDate.firstChange) {
    }
  }

  trackById(index: number, item: Lesson): number {
    return +item.id;
  }

  /**
   * Formats a date as a string in the format "WEEKDAY DAY MONTH", e.g. "THU 15 OCT".
   * @param {Date} date - The date to format.
   * @returns {string} The formatted date string.
   */
  formatShownDate(date: Date): string {
    return moment(date).format('ddd DD MMM').toUpperCase();
  }

  onGoToBookingSystemClicked() {
    this.generalService.navigateToBookingSystem();
  }

  getSelectedFilterType(type: string) {
    return this.selectedFilterType === type;
  }

  filterLessonsBy(type: string) {
    const now = moment.tz(moment(), this.user.timeZone!);

    switch (type) {
      case 'today':
        this.selectedFilterType = 'today';
        this.filteredLessons = this.lessons.filter((lesson: Lesson) => {
          const lessonDate = moment(lesson.startingDate);
          return lessonDate.isSame(now, 'day');
        });
        break;
      case 'upcoming':
        this.selectedFilterType = 'upcoming';
        this.filteredLessons = this.lessons.filter((lesson: Lesson) => {
          const lessonDate = moment(lesson.startingDate);
          return lessonDate.isSameOrAfter(now, 'day');
        }).sort(this.sortByDate);
        break;
      case 'past':
        this.selectedFilterType = 'past';
        this.filteredLessons = this.lessons.filter((lesson: Lesson) => {
          const lessonDate = moment(lesson.startingDate);
          return lessonDate.isBefore(now, 'day');
        }).sort(this.sortByDate);
        break;
      case 'month':
        this.selectedFilterType = 'month';
        this.filteredLessons = this.lessons.filter((lesson: Lesson) => {
          const lessonDate = moment(lesson.startingDate);
          return lessonDate.month() === moment(this.selectedMonthDate).month() && 
          lessonDate.year() === moment(this.selectedMonthDate).year();
        }).sort(this.sortByDate);
        break;
    }
  }

  private sortByDate(a: Lesson, b: Lesson) {
    const dateA = moment(a.startingDate);
    const dateB = moment(b.startingDate);
    return dateA.diff(dateB);
  }

  private initDateListener() {
    this.subs.add(this.classroomService.selectedAgendaDate.pipe(skip(1)).subscribe((res) => {
      const date = new Date(res);

      const dateMormat = moment(date).format("YYYY-MM-DD");
      this.filteredLessons = this.lessons.filter((lesson: Lesson) => {
        const lessonDate = moment(lesson.startingDate).format("YYYY-MM-DD");
        return dateMormat === lessonDate;
      });
      this.formattedDate = this.formatShownDate(date);
    }));
  }

  private initCalendarAgendaMonthListener() {
    this.subs.add(this.calendarService.calendarAgendaMonthListener.pipe(skip(1)).subscribe((res) => {
      this.selectedMonthDate = res;
    }));
  }


  private initCalendarAgendaHeightListener() {
    this.subs.add(this.calendarService.calendarAgendaHeightListener.subscribe((res) => {
      if (res) {
        this.scrollHeight = res - 80;
        if (this.scrollHeight) {
          this.scrollPanel.refresh();
          this.cdr.detectChanges();
        }
      }

    }));
  }

  
}
