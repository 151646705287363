<div class="goals-steps-wrapper px-5 py-4 mt-8" #goalsStepsWrapper>
    <h4 class="font-semibold mb-1 flex">Set Goals <span class="mr-0 ml-auto"><i class="pi pi-times-circle"></i></span></h4>
    <p-steps [model]="steps" [activeIndex]="activeIndex" [styleClass]="'completed-'"></p-steps>
    <!-- <form [formGroup]="formGroup"> -->
        <div class="inner-wrapper">
        <div *ngIf="activeIndex === 0" class="step-wrapper">
            <p class="font-lg">Choose your current 
                <!-- <p-tag value="English"></p-tag>  -->
                level
            </p>
            <div class="flex flex-wrap gap-3 mt-3 mb-5">
                <div *ngFor="let currentLevel of levels" class="field-checkbox ">
                    <label [for]="currentLevel.key" class="ml-0">
                        <p-radioButton [inputId]="currentLevel.key" name="currentLevel" [value]="currentLevel" [(ngModel)]="selectedCurrentLevel" formControlName="currentLevel" ></p-radioButton>
                        <span>{{ currentLevel.name }}</span>
                    </label>
                </div>
            </div>
            <button pButton type="button" (click)="nextStep()" class="next-btn"><span class="p-button-label">next</span><img src="/assets/icons/arrow-right.svg" class="btn-icon-img"><span class="p-ink"></span></button>
        </div>

        <div *ngIf="activeIndex === 1" class="step-wrapper">
            <p class="font-lg">Choose your desired 
                <!-- <p-tag value="English"></p-tag>  -->
                level
            </p>
            <div class="flex flex-wrap gap-3 mt-3 mb-5">
                <div *ngFor="let desiredLevel of levels" class="field-checkbox">
                    <label [for]="desiredLevel.key" class="ml-0">
                        <p-radioButton [inputId]="desiredLevel.key" name="desiredLevel" [value]="desiredLevel" [(ngModel)]="selectedCurrentLevel" [gModelOptions]="{standalone: true}">
                            ></p-radioButton>
                        <span>{{ desiredLevel.name }}</span>
                    </label>
                </div>
            </div>
            <div class="flex flex-wrap">
                <button pButton type="button" (click)="prevStep()" class="prev-btn mr-3"><img src="/assets/icons/arrow-left-blue.svg" class="btn-icon-img"><span class="p-ink"></span><span class="p-button-label">Back</span></button>
                <button pButton type="button" (click)="nextStep()" class="next-btn"><span class="p-button-label">next</span><img src="/assets/icons/arrow-right.svg" class="btn-icon-img"><span class="p-ink"></span></button>
            </div>
        </div>

        <div *ngIf="activeIndex === 2" class="step-wrapper">
            <p class="font-lg">When would you like to achieve your goal?</p>
            <div class="flex flex-wrap mt-3 mb-5">
                <p-calendar [(ngModel)]="date" view="month" dateFormat="mm/yy" [readonlyInput]="true" [showIcon]="true"></p-calendar>
            </div>
            <div class="flex flex-wrap">
                <button pButton type="button" (click)="prevStep()" class="prev-btn mr-3"><img src="/assets/icons/arrow-left-blue.svg" class="btn-icon-img"><span class="p-ink"></span><span class="p-button-label">Back</span></button>
                <button pButton type="button" (click)="nextStep()" class="next-btn"><span class="p-button-label">next</span><img src="/assets/icons/arrow-right.svg" class="btn-icon-img"><span class="p-ink"></span></button>
            </div>
        </div>

        <div *ngIf="activeIndex === 3" class="step-wrapper">
            <p class="font-lg"> How many lesson hours are you willing to take per week?</p>
            <div class="flex flex-wrap mt-3 mb-5">
                <p-dropdown [options]="hours" [(ngModel)]="selectedHours" placeholder="Select "></p-dropdown>
            </div>
            <div class="flex flex-wrap">
                <button pButton type="button" (click)="prevStep()" class="prev-btn mr-3"><img src="/assets/icons/arrow-left-blue.svg" class="btn-icon-img"><span class="p-ink"></span><span class="p-button-label">Back</span></button>
                <button pButton type="button" (click)="nextStep()" class="next-btn"><span class="p-button-label">next</span><img src="/assets/icons/arrow-right.svg" class="btn-icon-img"><span class="p-ink"></span></button>
            </div>
        </div>

        <div *ngIf="activeIndex === 4" class="step-wrapper">
            <p class="font-lg">Why do you want to study <p-tag value="English"></p-tag> ?</p>
            <div class="grid mt-3 mb-5">
                <div class="col">
                    <div *ngFor="let reason_1st_col of reasons_1st_col" class="field-checkbox origin">
                        <p-checkbox name="group" [value]="reason_1st_col" [(ngModel)]="selectedAreas" class="styled-checkbox"></p-checkbox>
                        <label class="font-semibold font-sm">{{ reason_1st_col }}</label>
                    </div>
                </div>
                <div class="col">
                    <div *ngFor="let reason_2nd_col of reasons_2nd_col" class="field-checkbox origin">
                        <p-checkbox name="group" [value]="reason_2nd_col" [(ngModel)]="selectedAreas" class="styled-checkbox"></p-checkbox>
                        <label class="font-semibold font-sm">{{ reason_2nd_col }}</label>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap">
                <button pButton type="button" (click)="prevStep()" class="prev-btn mr-3"><img src="/assets/icons/arrow-left-blue.svg" class="btn-icon-img"><span class="p-ink"></span><span class="p-button-label">Back</span></button>
                <button pButton type="button" (click)="nextStep()" class="next-btn"><span class="p-button-label">next</span><img src="/assets/icons/arrow-right.svg" class="btn-icon-img"><span class="p-ink"></span></button>
            </div>
        </div>

        <div *ngIf="activeIndex === 5" class="step-wrapper">
            <p class="font-lg">Which areas would you like to improve?</p>
            <div class="grid mt-3 mb-5">
                <div class="col">
                    <div *ngFor="let area_1st_col of areas_1st_col" class="field-checkbox origin">
                        <p-checkbox name="group" [value]="area_1st_col" [(ngModel)]="selectedAreas" class="styled-checkbox"></p-checkbox>
                        <label class="font-semibold font-sm">{{ area_1st_col }}</label>
                    </div>
                </div>
                <div class="col">
                    <div *ngFor="let area_2nd_col of areas_2nd_col" class="field-checkbox origin">
                        <p-checkbox name="group" [value]="area_2nd_col" [(ngModel)]="selectedAreas" class="styled-checkbox"></p-checkbox>
                        <label class="font-semibold font-sm">{{ area_2nd_col }}</label>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap gap-3 align-items-center">
                <button pButton type="button" (click)="prevStep()" class="prev-btn "><img src="/assets/icons/arrow-left-blue.svg" class="btn-icon-img"><span class="p-ink"></span><span class="p-button-label">Back</span></button>
                <button pButton type="button" (click)="submit()" class="submit-btn"><span class="p-button-label">submit</span><img src="/assets/icons/arrow-right.svg" class="btn-icon-img"><span class="p-ink"></span></button>
            </div>
        </div>
    </div>
    <!-- </form> -->
</div>