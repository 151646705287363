import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-top-menu-container-layout',
  templateUrl: './top-menu-container-layout.component.html',
  styleUrls: ['./top-menu-container-layout.component.scss'],
})
export class TopMenuContainerLayoutComponent implements OnInit, OnDestroy {

  isBelow768 = false;
  @ViewChild('scale',{static:true}) public ratingDetails: any;
  @ViewChild('heada',{static:true}) public heada: any;
  private subs = new SubSink();
  constructor(private generalService: GeneralService) { }

  ngOnInit(): void {

    this.subs.add(this.generalService.deviceKind.subscribe(res => {
     let zoomLevel = 1;
     if (res.w992up && !res.w1366up) {
      zoomLevel = 0.9;
     }
     this.isBelow768 = res.is768;
     console.log(res.is768)
      // if (res.w992up && !res.w1500up) {

      //   this.generalService.scaleElementDown(this.heada.elementRef, "unset", zoomLevel, header);
      //   this.generalService.scaleElementDown(this.ratingDetails, "left top", zoomLevel);
      // } else {
      //   this.generalService.scaleElementReset(this.ratingDetails);
      //   this.generalService.scaleElementReset(this.heada.elementRef, header);
      // }
    }));
    
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // console.log(event);
    // this.updateZoom();
    // this.smaller();
  }

  private updateZoom() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      document.body.classList.add('small-screen');
    } else {
      document.body.classList.remove('small-screen');
    }
  }

}
