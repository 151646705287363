import { Location } from '@angular/common';
import { Component, HostListener, inject, Injector, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { AppLayoutType } from './core/models/general.model';
import { AuthService } from './core/services/auth.service';
import { GeneralService } from './core/services/general.service';
import { slideInAnimation } from './helpers/route-animation';
import { ClassroomService } from './core/services/classroom.service';
import { UserService } from './core/services/user.service';
import { LayoutService } from './core/services/layout.service';
import { ConfirmDialogService } from './core/services/confirm-dialog.service';
import { ChatService } from './core/services/chat.service';
import { Classroom } from './core/models/classroom.model';
import { toObservable } from '@angular/core/rxjs-interop';
import { TooltipModule } from 'primeng/tooltip';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation],
  providers: [
    TooltipModule
  ]
})
export class AppComponent implements OnInit {

  private injector = inject(Injector);
  public title = 'frontend';
  public mt: number = 0;
  public routeIsDashboard: boolean = false;
  public routeIsProfile: boolean = false;
  public routeIsTeacher: boolean = false;
  public routeIsAuth: boolean = false;
  public hideLeftMenu: boolean = true;
  public oldValue: number = 0;
  public newValue: number = 0;
  public url: string = "";
  public showLeftMenu: boolean = false;
  public gradient: string = "";
  public routeData: Data = {} as Data;
  layout$: Observable<AppLayoutType> = this.getLayoutType$();
  readonly AppLayoutType = AppLayoutType;
  private previousUrl: string = '';
  loggedIn = signal(false);
  chatService = inject(ChatService);
  user = this.authService.getLoggedInUser();
  userClassrooms = [] as Classroom[];
  constructor(
    private router: Router,
    private location: Location,
    private generalService: GeneralService,
    public authService: AuthService,
    private primengConfig: PrimeNGConfig,
    private activatedRoute: ActivatedRoute,
    public classroomService: ClassroomService,
    private userService: UserService,
    private layoutService: LayoutService,
    public confirmDialogService: ConfirmDialogService,
  ) {

  }

  ngOnInit() {
    this.primengConfig.ripple = true;

    this.router.events.subscribe((evt) => {

      if (evt instanceof NavigationStart) {
        window.scrollTo(0, 0);
      }

      if (evt instanceof NavigationEnd) {
        this.previousUrl = this.router.url;
        console.log(' url = this .router.url ', evt.url);
        if (evt.url.includes('chat')) {
          // this.chatService.showBubbleChat.set(false);
        } else {
          // this.chatService.showBubbleChat.set(true);
        }
        if (this.authService.getIsAuth()) {
          if (this.authService.isStudent) {
            this.handleStudentClassroomDataAndMenu();
          } else {
            this.layoutService.updateAllMenuItemsDisabledStatus(false);
          }
        }
        if (this.previousUrl !== evt.url) {
          console.log(evt.url);
          window.scrollTo(0, 0);
        }
      }



    });

    setTimeout(() => {
      [].forEach.call(document.getElementsByClassName('tawk-card-primary'), (el: any) => {
        el.style.setProperty("backgroundColor", "black", "important");
        console.log(el)
      })
    }, 10000);
    this.authService.autoAuthUser();
    this.generalService.setDevice();
    this.getLayoutType$();

    this.layout$.subscribe((res) => {
      // console.log(res);
    });

    // this.getLayoutType$().subscribe((res)=>{
    //   console.log(res);
    // });

    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        // console.log(data.state.root.firstChild!.data);

        this.routeData = data.state.root.firstChild!.data;
      }
    });

    this.authService.authStatusListener.subscribe(res => {
      console.log(res);
      this.loggedIn.set(res);
      if (res) {
      }
    });
    this.listenLoggedInUser();

  }

  getLayoutType$(): Observable<AppLayoutType> {
    return this.router.events.pipe(
      filter((event) => event instanceof RoutesRecognized),
      map((ev: Data) => {
        // console.log(this.activatedRoute);
        return this.activatedRoute
      }),
      map((route) => {
        while (route.firstChild) route = route.firstChild

        // console.log(route);
        return route
      }),
      mergeMap((route) => route.data),
      map(({ layout }) => layout),
    )
  }

  getTopLeftGradientClass() {
    if (this.router.url.includes('dashboard')) {
      this.routeIsDashboard = true;
      return "top-left-gradient";
    }
    return "top-left-gradient-big"
  }

  checkOffset() {
    // var leftMenu = document.getElementById('left-menu');
    // var a = window.scrollY + leftMenu!?.clientHeight + document.getElementById('footer')!?.clientHeight + 100;
    // var body = document.body;
    // var html = document.documentElement;
    // var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    // if (leftMenu !== null) {
    //   if (a < height) {
    //     leftMenu.style.position = "fixed";
    //     leftMenu.style.top = html.clientHeight > 850 ? "86px" : "70px"; // see marginTop in .app-content class and check @media (max-height: 850px)
    //     this.mt = window.scrollY;
    //   } else {
    //     leftMenu.style.position = "absolute";
    //     leftMenu.style.top = this.mt + "px";
    //   }
    // }
  }

  onActivate(event: Event) {
    window.scrollTo(0, 0);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any) {
    this.checkOffset();
    this.newValue = window.pageYOffset;
    this.generalService.gradientScale(this.newValue, this.oldValue, 'top-left-gradient')
    this.oldValue = this.newValue;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.generalService.setDevice();
  }

  backClicked() {
    this.location.back();
  }

  addActiveClassToProfileInfoInSillyWay(data: RoutesRecognized, url: string, id: string) {
    // Sorry :( but routerLinkActive is not working in profile-menu.component.ts
    if (data.url === url) {
      setTimeout(() => {
        document.getElementById(id)?.classList.add('item-active')
      }, 100);
    } else {
      document.getElementById(id)?.classList.remove('item-active')
    }
  }

  addActiveClassToTeacherInfoInSillyWay(data: RoutesRecognized, url: string, id: string) {
    // Sorry :( but routerLinkActive is not working in profile-menu.component.ts
    if (data.url === url) {
      setTimeout(() => {
        document.getElementById(id)?.classList.add('item-active-teacher')
        document.getElementById(id)?.classList.remove('inactive-item')
      }, 100);
    } else {
      document.getElementById(id)?.classList.remove('item-active-teacher')
      document.getElementById(id)?.classList.add('inactive-item')
    }
  }

  private handleStudentClassroomDataAndMenu() {
    this.userService.getUserHasPayment()
      .pipe(
        take(1),
        switchMap((res: any) => {
          return this.classroomService.getLMSUserClassrooms(this.authService.getLoggedInUser().id).pipe(
            tap((classrooms) => {
              this.classroomService.setUserClassrooms(classrooms);
              const isStudentActive = this.classroomService.isStudentActive(classrooms, res);
              this.layoutService.updateMenuItemDisabledStatus("Classrooms", classrooms.length === 0);
              this.layoutService.updateMenuItemDisabledStatus("Guides", true);
              if (isStudentActive) {
                this.layoutService.updateAllMenuItemsDisabledStatus(false);
              } else {
                this.layoutService.updateMenuItemClassroomToTrialsForStudent(classrooms);
              }
              console.log(classrooms);
            }),
            take(1)
          );
        })
      ).subscribe();
  }

  private listenChatFromClassrooms() {
    this.classroomService.getLMSUserClassrooms(this.user.id).subscribe(res => {
      let totalUnreadCount = 0; // Initialize a variable to accumulate the total unread count
      console.log(res);
      this.classroomService.setUserClassrooms(res);
      const classroomObservables = res.map((element: Classroom) => {
        return this.chatService.getUnreadMessagesCount(element.id, this.user.aspUserId).pipe(
          tap(count => {
            console.log(`Unread messages count for classroom ${element.id} for user ${this.user.aspUserId}: ${count}`);
          })
        );
      });

      combineLatest(classroomObservables).subscribe(counts => {
        totalUnreadCount = (counts as number[]).reduce((acc, count) => acc + count, 0);
        this.chatService.unreadCount.set(totalUnreadCount); // Update the total count
      });
    });
  }

  private listenLoggedInUser() {
    toObservable(this.loggedIn, {
      injector: this.injector
    }).subscribe({
      next: (data) => {
        if (data) {
          // this.listenChatFromClassrooms();
        }
      }
    });

    toObservable(this.authService.firebaseLoggedIn, {
      injector: this.injector
    }).subscribe({
      next: (data) => {
        if (data) {
        }
      }
    });

    toObservable(this.authService.firebaseLogoutEvent, {
      injector: this.injector
    }).subscribe({
      next: (data) => {
        if (data) {
          this.chatService.signOutFromFirebase();
        }
      }
    });



    this.chatService.getFireBaseUser().subscribe({
      next: (user) => {
        if (user) {
          this.authService.firebaseLoggedIn.set(true);
          this.listenChatFromClassrooms();
        }
      },
      error: (error) => {
        console.log(error);
        alert(error);
      }
    });

  }

}