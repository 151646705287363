import {
    transition,
    trigger,
    style,
    animate,
} from '@angular/animations';
export const slideUpDownAnimation = trigger('slideUpDown', [
    transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
    ]),
    transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
    ])
]);
export const slideInOut = trigger('slideInOut', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
    ]),
    transition(':leave', [
        animate('300ms', style({ opacity: 0 }))
    ])
]);

export const slideInOut2 =
    trigger('slideInOut2', [
        transition(':enter', [
            style({ transform: 'translateY(-100%)' }),
            animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
        ]),
        transition(':leave', [
            animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
        ])
    ])