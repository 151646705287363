import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Level } from '../models/classroom.model';
import { PackageFilter, Package, PackageExtention, PackageFilterHelper, PackageLessonsLevel, PackageState, PackageType, PackageToBuy, PackageToExtend, PackageToBuyNew, SplitPackagesToBuyResponse } from '../models/package.model';
import { UserRole } from '../models/user.model';
import { AuthService } from './auth.service';
import { GeneralService } from './general.service';
import { UserService } from './user.service';
const BACKEND_URL = environment.apiUrl;
const BACKEND_LMS_URL = environment.apiUrl + "/LMS/";

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  public packageFilterHelper: PackageFilterHelper = {
    totalHours: ['Any', 5, 10, 20, 50],
    hoursLeft: ['Any', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
    level: ['Any', Level.A1, Level.A2, Level.B1, Level.B2, Level.C1, Level.C2, Level.TBD, Level.BS],
    type: ['Any', PackageType.PAID, PackageType.REGULAR, PackageType.FLEX, PackageType.BUSINESS, PackageType.GIFT, PackageType.TRIAL],
    state: ['Any', PackageState.ACTIVE, PackageState.COMPLETED, PackageState.PAUSED, PackageState.EXPIRED, PackageState.CANCELED, PackageState.TRANSFERED],
    hasExtention: ['Any', 'Yes', 'No']
  }

  public packageFilter: PackageFilter = {
    totalHours: 'Any',
    hoursLeft: 'Any',
    level: 'Any',
    type: 'Any',
    state: 'Any',
    hasExtention: 'Any',
    expiresOn_from: {} as Date,
    expiresOn_to: {} as Date,
    stratedAt_to: {} as Date,
    stratedAt_from: {} as Date,
    purchasedAt_from: {} as Date,
    purchasedAt_to: {} as Date,
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  public getPackageFilter(): PackageFilterHelper {
    return this.packageFilterHelper;
  }

  public getCurrentPackageFilter(): PackageFilter {
    return this.packageFilter;
  }

  public setCurrentPackageFilter(packageFilter: PackageFilter) {
    this.packageFilter = packageFilter;
  }

  public getPackageFilterWithoutTheCurrent(): PackageFilterHelper {
    let current = this.getCurrentPackageFilter();
    let remainingTotalHours = this.packageFilterHelper.totalHours.filter(el => { return el !== current.totalHours });
    let hoursLeft = this.packageFilterHelper.hoursLeft.filter(el => { return el !== current.hoursLeft });
    let level = this.packageFilterHelper.level.filter(el => { return el !== current.level });
    let type = this.packageFilterHelper.type.filter(el => { return el !== current.type });
    let state = this.packageFilterHelper.state.filter(el => { return el !== current.state });
    let hasExtention = this.packageFilterHelper.hasExtention.filter(el => { return el !== current.hasExtention });
    return {
      totalHours: remainingTotalHours,
      hoursLeft: hoursLeft,
      level: level,
      type: type,
      state: state,
      hasExtention: hasExtention
    }
  }

  getClassroomPackages(classroomId: string) {
    return this.http.get<Package[] | any[]>(BACKEND_URL + "/Package/GetClassroomPackages?ClassroomId=" + classroomId)
      .pipe(
        switchMap((packages: Package[]) => {
          return this.gerPackageExtraDetails(packages);
        }),
        tap((res) => {
        })
      )
  }

  getUserPackages(userId: string) {
    let endpoint = this.authService.getUserRole() === UserRole.TEACHER ? "/Package/GetTeacherPackages?TeacherId=" + userId : "/Package/GetStudentPackages?StudentId=" + userId;
    return this.http.get<Package[] | any[]>(BACKEND_URL + endpoint)
      .pipe(
        switchMap((packages: Package[]) => {
          return this.gerPackageExtraDetails(packages);
        })
      )
  }
  // new endpoint
  getAllUserPackages(userId: string) {
    let endpoint = this.authService.getUserRole() === UserRole.TEACHER ? "GetAllTeacherPackages?userId=" + userId : "GetStudentPackages?StudentId=" + userId;
    return this.http.get<Package[] | any[]>(BACKEND_LMS_URL + endpoint)
    // .pipe(
    // switchMap((packages: Package[]) => {
    // return this.gerPackageExtraDetails(packages);
    // })
    // )
  }

  getPackageExtentions(packageId: string) {
    return this.http.get<PackageExtention[]>(BACKEND_URL + `/Package/GetPackageExtentions?packageId=${packageId}`)
  }

  getPackageLessonLevels(packageId: string) {
    return this.http.get<PackageLessonsLevel[]>(BACKEND_URL + `/Package/GetPackageLessonsLevels?packageId=${packageId}`)
  }

  getClassroomActivePackage(classroomId: string) {
    return this.http.get<Package>(BACKEND_URL + "/Package/GetClassroomActivePackage?ClassroomId=" + classroomId)
  }

  getPackagesToBuy() {
    return this.http.get<PackageToBuy[]>(BACKEND_URL + "/Package/PackagesToBuy")
  }
  // new endpoint
  getUpdatedPackagesToBuy(req: any) {
    return this.http.post<PackageToBuyNew[]>(BACKEND_LMS_URL + "PackagesToBuy", req)
  }

  getSplitPackagesToBuy(req: any) {
    return this.http.post<SplitPackagesToBuyResponse[]>(BACKEND_LMS_URL + "SplitPackagesToBuy", req)
  }

  convertPackageDates(pack: Package) {
    return {
      stratedAt: new Date(pack.stratedAt),
      purchasedAt: new Date(pack.purchasedAt),
      expiresOn: new Date(pack.expiresOn)
    }
  }

  findLessonPackageById(packages: Package[], lessonPackageId: string): Package | undefined {
    return packages.find((pckage: Package) => pckage.id === lessonPackageId);
  }

  public getPackageExtras(packageId: string) {
    return forkJoin(
      this.getPackageExtentions(packageId),
      this.getPackageLessonLevels(packageId)
    )
  }

  gerPackageExtraDetails(packages: Package[]) {
    if (packages.length == 0) {
      return []
    }
    return forkJoin(packages.map((pack: Package) => {

      let convertedDates = this.convertPackageDates(pack)
      pack.stratedAt = convertedDates.stratedAt
      pack.purchasedAt = convertedDates.purchasedAt
      pack.expiresOn = convertedDates.expiresOn
      return this.getPackageExtras(pack.id).pipe(
        map(x => {
          for (let extension of x[0]) {
            extension.fromDate = new Date(extension.fromDate);
            extension.toDate = new Date(extension.toDate);
          }
          for (let lessonLevel of x[1]) {
            lessonLevel.lessonsStarted = new Date(lessonLevel.lessonsStarted);
            lessonLevel.lessonsEnded = new Date(lessonLevel.lessonsEnded);
          }
          let packageWithExtras: Package = ({ ...pack, ...{ extensions: x[0], lessons_level: x[1] } });
          return packageWithExtras;
        }))
    }))
  }

  requestTrial(req: any) {
    return this.http.post(BACKEND_URL + "/Package/RequestTrial", req)
  }

  requestbuyPackage(req: any) {
    return this.http.post(BACKEND_URL + "/Package/BuyPackage", req)
  }

  upgradePackage(packageId: any, newLevel: string) {
    return this.http.post(BACKEND_URL + "/Package/UpgradePackage?packageId=" + packageId + "&newLevel=" + newLevel, {})
  }

  getExtentionOptions(): Observable<PackageToExtend[]> {
    return this.http.get<PackageToExtend[]>(BACKEND_URL + "/Package/GetExtentionOptions");
  }

  buyExtension(req: any) {
    return this.http.post(BACKEND_URL + "/Package/BuyExtension", req);
  }

  createRevolutOrder(req: any) {
    return this.http.post(BACKEND_LMS_URL + "CreateRevolutOrder", req);
  }

  createSplitRevolutOrder(req: any) {
    return this.http.post(BACKEND_LMS_URL + "CreateSplitRevolutOrder", req);
  }

  completeOrder(req: any) {
    return this.http.post(BACKEND_LMS_URL + "CompleteOrder", req);
  }

  completeSplitOrder(req: any) {
    return this.http.post(BACKEND_LMS_URL + "CompleteSplitOrder", req);
  }

  addPurchaseEvent(purchaseData: any) {
    const url = 'https://api.leaddyno.com/v1/purchases';
    const headers = { 'Content-Type': 'application/json', 'X-Bypass-Error-Interceptor': 'true' };

    return this.http.post(url, purchaseData, { headers });
  }

  leadDynoGetAffiliateEvent(data?: any) {
    const url = 'https://api.leaddyno.com/v1/affiliates';
    const headers = {
      'X-Bypass-Error-Interceptor': 'true',
      "Content-Type": "application/json",
      key: "165d71696287208d956a45cffb6d8112b66f0e81",
      affiliate_code: "CMSSLN",
    };

    return this.http.get(url, { headers });
  }

  getLeadDynoAffiliateByAffiliateCode(affiliateCode: string, privateKey: string): Observable<any> {
    const url = 'https://api.leaddyno.com/v1/affiliates/by_affiliate_code';

    // Constructing request headers
    const headers = new HttpHeaders({
      'X-Bypass-Error-Interceptor': 'true',
      'Content-Type': 'application/json',
      'key': privateKey
    });

    // Constructing request parameters
    const params = {
      'affiliate_code': affiliateCode
    };

    console.log(url, headers, params);

    // Making the HTTP GET request
    return this.http.get(url, { headers, params });
  }

  /**
   * checks if a package can be frozen based on its state and type.
   * The function iterates through an array of packages and checks if any package is in the ongoing or purchased state and has a type of premium or flex.
   * If such a package is found, the enableFreeze variable is set to true.
   * The function returns the value of enableFreeze, indicating whether any package can be frozen.
   */
  checkIfPackageCanBeFrozen(packages: Package[]): boolean {
    let enableFreeze = false;

    for (const pack of packages) {
      if ((pack.state.toLowerCase() === PackageState.ONGOING.toLowerCase() || pack.state.toLowerCase() === PackageState.PURCHASED.toLowerCase())
        && (pack.type === PackageType.PREMIUM || pack.type === PackageType.FLEX)) {
        enableFreeze = true;;
      }
    }

    return enableFreeze;
  }


  userIsOnFlexOrPremiumPackage(packages: Package[]): boolean {
    // Check if any package is of type "Flex" or "Premium"
    return packages.some((iterPackage: Package) => (iterPackage.type === PackageType.FLEX || iterPackage.type === PackageType.PREMIUM) &&
      (iterPackage.state.toLowerCase() === PackageState.ONGOING.toLowerCase() || iterPackage.state.toLowerCase() === PackageState.PURCHASED.toLowerCase()));
  }

}
