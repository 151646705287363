import { Lesson } from "./lesson.model"
import { User } from "./user.model"

export interface LessonUserRating {
    id: string,
    user?: User,
    studentId?: string,
    teacherId?: string,
    lessonId?: number,
    absent: boolean,
    vocabulary: number,
    listening: number,
    grammar: number,
    speaking: number,
    writing: number,
    revision: number,
    reading: number,
    test: number,
    student?: User
}

export interface LessonBreakdown {
    id: string,
    lessonId?: number,
    vocabulary: number,
    listening: number,
    grammar: number,
    speaking: number,
    writing: number,
    revision: number,
    reading: number,
    test: number,
    dateCreated: Date
}

export interface LessonFullRating {
    title: string,
    lesson: Lesson,
    userRatings: LessonUserRating[],
    lessonBreakdown: LessonBreakdown
} 

export interface RatingItem {
    id?: string,
    color?: string,
    category: string,
    checked?: boolean,
}

export const dummylessonFullRatings: any[] = [
    {
      title: "Lesson 1",
      lesson: {
        id: 1,
        classroomId: 1,
        status: "Completed",
        startingDate: new Date(),
        duration: 1,
        isRecccuring: false,
        recccuringGroupId: "",
        bbbLink: "",
        answered: true,
        result: "By Teacher",
        requestedByAspUserId: "",
        requestedByName: "",
        classroom: null,
        errorMessage: ""
      },
      userRatings: [
        {
          id: "118",
          studentId: "1",
          teacherId: "2",
          lessonId: 1,
          absent: false,
          vocabulary: 4,
          listening: 3,
          grammar: 5,
          speaking: 2,
          writing: 4,
          revision: 3,
          reading: 5,
          test: 4
        }
      ],
      lessonBreakdown: {
        id: "1",
        lessonId: 1,
        vocabulary: 4,
        listening: 3,
        grammar: 5,
        speaking: 2,
        writing: 4,
        revision: 3,
        reading: 5,
        test: 4,
        dateCreated: new Date()
      }
    },
    {
      title: "Lesson 2",
      lesson: {
        id: 2,
        classroomId: 2,
        status: "COMPLETED",
        startingDate: new Date(),
        duration: 1,
        isRecccuring: false,
        recccuringGroupId: "",
        bbbLink: "",
        answered: "",
        result: "By Teacher",
        requestedByAspUserId: "",
        requestedByName: "",
        "classroom": {
            "id": "5678",
            "title": "Spanish 101",
            "status": "ON_GOING",
            "totalHours": 20,
            "hoursLeft": 16,
            "activeLevel": "A1",
            "users": [
              {
                "id": 9876,
                "fname": "Jane",
                "lname": "Doe",
                "email": "jane.doe@example.com",
                "phone": "+123456789",
                "timezone": "Europe/Paris"
              },
              {
                "id": 8765,
                "fname": "Alice",
                "lname": "Smith",
                "email": "alice.smith@example.com",
                "phone": "+987654321",
                "timezone": "America/Los_Angeles"
              }
            ],
            "classroomStudents": [
              {
                "id": 9876,
                "fname": "Jane",
                "lname": "Doe",
                "email": "jane.doe@example.com",
                "phone": "+123456789",
                "timezone": "Europe/Paris"
              }
            ],
            "type": "Paid",
            "language": "Spanish",
            "teacher": {
              "id": 3456,
              "fname": "Juan",
              "lname": "Garcia",
              "email": "juan.garcia@example.com",
              "phone": "+345678901",
              "timezone": "Europe/Madrid"
            },
            "activePackage": {
              "id": "package-1234",
              "lessons_level": [
                {
                  "level": "A1",
                  "hoursCompleted": 4,
                  "lessonsStarted": "2023-03-01T09:00:00Z",
                  "lessonsEnded": "present"
                }
              ],
              "totalHours": 20,
              "currentPackageLevel": "A1",
              "hoursLeft": 16,
              "type": "REGULAR",
              "expiresOn": "2023-09-15T23:59:59Z",
              "stratedAt": "2023-02-15T09:00:00Z",
              "purchasedAt": "2023-02-15T12:00:00Z",
              "state": "ACTIVE",
              "extensions": []
            }
          },
        errorMessage: ""
      },
      userRatings: [
        {
          id: "2",
          studentId: "2",
          teacherId: "3",
          lessonId: 2,
          absent: false,
          vocabulary: 3,
          listening: 4,
          grammar: 5,
          speaking: 3,
          writing: 4,
          revision: 4,
          reading: 5,
          test: 5
        }
      ],
      lessonBreakdown: {
        id: "2",
        lessonId: 2,
        vocabulary: 3,
        listening: 4,
        grammar: 5,
        speaking: 3,
        writing: 4,
        revision: 4,
        reading: 5,
        test: 5,
        dateCreated: new Date()
      }
    }
  ];