import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit, AfterViewInit {

  @ViewChild('confirmCircleLottie', { static: true }) firstStep: ElementRef = {} as ElementRef;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.addLottiePlayerToStep(this.firstStep);
    const params = this.activatedRoute.snapshot.queryParams;
    this.authService.confirmUser(params.userId, params.code)
    // this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: Params) => {
      
    // });
  }

  private addLottiePlayerToStep(step: ElementRef): void {
    const lottiePlayer = `<div  class="animation-wrapper"><lottie-player src="https://assets-v2.lottiefiles.com/a/a5aa5c06-117e-11ee-bad5-4b080a01fe62/kiQH7jlOEB.json" background="transparent" 
    speed="1" style="max-width: 310px; max-height: 310px;" autoplay loop></lottie-player>
    <img src="assets/icons/user-wait.png" class="max-w-22rem img-centered" style="
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    " />
    </div>`;
    step.nativeElement.innerHTML = lottiePlayer;
  }

}
