import { Country, Language, Timezone } from "./general.model";
import { LessonStatus } from "./lesson.model";

export let languages: Language[] = [
    { "code": "ab", "name": "Abkhaz", "nativeName": "аҧсуа" },
    { "code": "aa", "name": "Afar", "nativeName": "Afaraf" },
    { "code": "af", "name": "Afrikaans", "nativeName": "Afrikaans" },
    { "code": "ak", "name": "Akan", "nativeName": "Akan" },
    { "code": "sq", "name": "Albanian", "nativeName": "Shqip" },
    { "code": "am", "name": "Amharic", "nativeName": "አማርኛ" },
    { "code": "ar", "name": "Arabic", "nativeName": "العربية" },
    { "code": "an", "name": "Aragonese", "nativeName": "Aragonés" },
    { "code": "hy", "name": "Armenian", "nativeName": "Հայերեն" },
    { "code": "as", "name": "Assamese", "nativeName": "অসমীয়া" },
    { "code": "av", "name": "Avaric", "nativeName": "авар мацӀ, магӀарул мацӀ" },
    { "code": "ae", "name": "Avestan", "nativeName": "avesta" },
    { "code": "ay", "name": "Aymara", "nativeName": "aymar aru" },
    { "code": "az", "name": "Azerbaijani", "nativeName": "azərbaycan dili" },
    { "code": "bm", "name": "Bambara", "nativeName": "bamanankan" },
    { "code": "ba", "name": "Bashkir", "nativeName": "башҡорт теле" },
    { "code": "eu", "name": "Basque", "nativeName": "euskara, euskera" },
    { "code": "be", "name": "Belarusian", "nativeName": "Беларуская" },
    { "code": "bn", "name": "Bengali", "nativeName": "বাংলা" },
    { "code": "bh", "name": "Bihari", "nativeName": "भोजपुरी" },
    { "code": "bi", "name": "Bislama", "nativeName": "Bislama" },
    { "code": "bs", "name": "Bosnian", "nativeName": "bosanski jezik" },
    { "code": "br", "name": "Breton", "nativeName": "brezhoneg" },
    { "code": "bg", "name": "Bulgarian", "nativeName": "български език" },
    { "code": "my", "name": "Burmese", "nativeName": "ဗမာစာ" },
    { "code": "ca", "name": "Catalan; Valencian", "nativeName": "Català" },
    { "code": "ch", "name": "Chamorro", "nativeName": "Chamoru" },
    { "code": "ce", "name": "Chechen", "nativeName": "нохчийн мотт" },
    { "code": "ny", "name": "Chichewa; Chewa; Nyanja", "nativeName": "chiCheŵa, chinyanja" },
    { "code": "zh", "name": "Chinese", "nativeName": "中文 (Zhōngwén), 汉语, 漢語" },
    { "code": "cv", "name": "Chuvash", "nativeName": "чӑваш чӗлхи" },
    { "code": "kw", "name": "Cornish", "nativeName": "Kernewek" },
    { "code": "co", "name": "Corsican", "nativeName": "corsu, lingua corsa" },
    { "code": "cr", "name": "Cree", "nativeName": "ᓀᐦᐃᔭᐍᐏᐣ" },
    { "code": "hr", "name": "Croatian", "nativeName": "hrvatski" },
    { "code": "cs", "name": "Czech", "nativeName": "česky, čeština" },
    { "code": "da", "name": "Danish", "nativeName": "dansk" },
    { "code": "dv", "name": "Divehi; Dhivehi; Maldivian;", "nativeName": "ދިވެހި" },
    { "code": "nl", "name": "Dutch", "nativeName": "Nederlands, Vlaams" },
    { "code": "en", "name": "English", "nativeName": "English" },
    { "code": "eo", "name": "Esperanto", "nativeName": "Esperanto" },
    { "code": "et", "name": "Estonian", "nativeName": "eesti, eesti keel" },
    { "code": "ee", "name": "Ewe", "nativeName": "Eʋegbe" },
    { "code": "fo", "name": "Faroese", "nativeName": "føroyskt" },
    { "code": "fj", "name": "Fijian", "nativeName": "vosa Vakaviti" },
    { "code": "fi", "name": "Finnish", "nativeName": "suomi, suomen kieli" },
    { "code": "fr", "name": "French", "nativeName": "français, langue française" },
    { "code": "ff", "name": "Fula; Fulah; Pulaar; Pular", "nativeName": "Fulfulde, Pulaar, Pular" },
    { "code": "gl", "name": "Galician", "nativeName": "Galego" },
    { "code": "ka", "name": "Georgian", "nativeName": "ქართული" },
    { "code": "de", "name": "German", "nativeName": "Deutsch" },
    { "code": "el", "name": "Greek, Modern", "nativeName": "Ελληνικά" },
    { "code": "gn", "name": "Guaraní", "nativeName": "Avañeẽ" },
    { "code": "gu", "name": "Gujarati", "nativeName": "ગુજરાતી" },
    { "code": "ht", "name": "Haitian; Haitian Creole", "nativeName": "Kreyòl ayisyen" },
    { "code": "ha", "name": "Hausa", "nativeName": "Hausa, هَوُسَ" },
    { "code": "he", "name": "Hebrew (modern)", "nativeName": "עברית" },
    { "code": "hz", "name": "Herero", "nativeName": "Otjiherero" },
    { "code": "hi", "name": "Hindi", "nativeName": "हिन्दी, हिंदी" },
    { "code": "ho", "name": "Hiri Motu", "nativeName": "Hiri Motu" },
    { "code": "hu", "name": "Hungarian", "nativeName": "Magyar" },
    { "code": "ia", "name": "Interlingua", "nativeName": "Interlingua" },
    { "code": "id", "name": "Indonesian", "nativeName": "Bahasa Indonesia" },
    { "code": "ie", "name": "Interlingue", "nativeName": "Originally called Occidental; then Interlingue after WWII" },
    { "code": "ga", "name": "Irish", "nativeName": "Gaeilge" },
    { "code": "ig", "name": "Igbo", "nativeName": "Asụsụ Igbo" },
    { "code": "ik", "name": "Inupiaq", "nativeName": "Iñupiaq, Iñupiatun" },
    { "code": "io", "name": "Ido", "nativeName": "Ido" },
    { "code": "is", "name": "Icelandic", "nativeName": "Íslenska" },
    { "code": "it", "name": "Italian", "nativeName": "Italiano" },
    { "code": "iu", "name": "Inuktitut", "nativeName": "ᐃᓄᒃᑎᑐᑦ" },
    { "code": "ja", "name": "Japanese", "nativeName": "日本語 (にほんご／にっぽんご)" },
    { "code": "jv", "name": "Javanese", "nativeName": "basa Jawa" },
    { "code": "kl", "name": "Kalaallisut, Greenlandic", "nativeName": "kalaallisut, kalaallit oqaasii" },
    { "code": "kn", "name": "Kannada", "nativeName": "ಕನ್ನಡ" },
    { "code": "kr", "name": "Kanuri", "nativeName": "Kanuri" },
    { "code": "ks", "name": "Kashmiri", "nativeName": "कश्मीरी, كشميري‎" },
    { "code": "kk", "name": "Kazakh", "nativeName": "Қазақ тілі" },
    { "code": "km", "name": "Khmer", "nativeName": "ភាសាខ្មែរ" },
    { "code": "ki", "name": "Kikuyu, Gikuyu", "nativeName": "Gĩkũyũ" },
    { "code": "rw", "name": "Kinyarwanda", "nativeName": "Ikinyarwanda" },
    { "code": "ky", "name": "Kirghiz, Kyrgyz", "nativeName": "кыргыз тили" },
    { "code": "kv", "name": "Komi", "nativeName": "коми кыв" },
    { "code": "kg", "name": "Kongo", "nativeName": "KiKongo" },
    { "code": "ko", "name": "Korean", "nativeName": "한국어 (韓國語), 조선말 (朝鮮語)" },
    { "code": "ku", "name": "Kurdish", "nativeName": "Kurdî, كوردی‎" },
    { "code": "kj", "name": "Kwanyama, Kuanyama", "nativeName": "Kuanyama" },
    { "code": "la", "name": "Latin", "nativeName": "latine, lingua latina" },
    { "code": "lb", "name": "Luxembourgish, Letzeburgesch", "nativeName": "Lëtzebuergesch" },
    { "code": "lg", "name": "Luganda", "nativeName": "Luganda" },
    { "code": "li", "name": "Limburgish, Limburgan, Limburger", "nativeName": "Limburgs" },
    { "code": "ln", "name": "Lingala", "nativeName": "Lingála" },
    { "code": "lo", "name": "Lao", "nativeName": "ພາສາລາວ" },
    { "code": "lt", "name": "Lithuanian", "nativeName": "lietuvių kalba" },
    { "code": "lu", "name": "Luba-Katanga", "nativeName": "" },
    { "code": "lv", "name": "Latvian", "nativeName": "latviešu valoda" },
    { "code": "gv", "name": "Manx", "nativeName": "Gaelg, Gailck" },
    { "code": "mk", "name": "Macedonian", "nativeName": "македонски јазик" },
    { "code": "mg", "name": "Malagasy", "nativeName": "Malagasy fiteny" },
    { "code": "ms", "name": "Malay", "nativeName": "bahasa Melayu, بهاس ملايو‎" },
    { "code": "ml", "name": "Malayalam", "nativeName": "മലയാളം" },
    { "code": "mt", "name": "Maltese", "nativeName": "Malti" },
    { "code": "mi", "name": "Māori", "nativeName": "te reo Māori" },
    { "code": "mr", "name": "Marathi (Marāṭhī)", "nativeName": "मराठी" },
    { "code": "mh", "name": "Marshallese", "nativeName": "Kajin M̧ajeļ" },
    { "code": "mn", "name": "Mongolian", "nativeName": "монгол" },
    { "code": "na", "name": "Nauru", "nativeName": "Ekakairũ Naoero" },
    { "code": "nv", "name": "Navajo, Navaho", "nativeName": "Diné bizaad, Dinékʼehǰí" },
    { "code": "nb", "name": "Norwegian Bokmål", "nativeName": "Norsk bokmål" },
    { "code": "nd", "name": "North Ndebele", "nativeName": "isiNdebele" },
    { "code": "ne", "name": "Nepali", "nativeName": "नेपाली" },
    { "code": "ng", "name": "Ndonga", "nativeName": "Owambo" },
    { "code": "nn", "name": "Norwegian Nynorsk", "nativeName": "Norsk nynorsk" },
    { "code": "no", "name": "Norwegian", "nativeName": "Norsk" },
    { "code": "ii", "name": "Nuosu", "nativeName": "ꆈꌠ꒿ Nuosuhxop" },
    { "code": "nr", "name": "South Ndebele", "nativeName": "isiNdebele" },
    { "code": "oc", "name": "Occitan", "nativeName": "Occitan" },
    { "code": "oj", "name": "Ojibwe, Ojibwa", "nativeName": "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
    // { "code": "cu", "name": "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic", "nativeName": "ѩзыкъ словѣньскъ" },
    { "code": "om", "name": "Oromo", "nativeName": "Afaan Oromoo" },
    { "code": "or", "name": "Oriya", "nativeName": "ଓଡ଼ିଆ" },
    { "code": "os", "name": "Ossetian, Ossetic", "nativeName": "ирон æвзаг" },
    { "code": "pa", "name": "Panjabi, Punjabi", "nativeName": "ਪੰਜਾਬੀ, پنجابی‎" },
    { "code": "pi", "name": "Pāli", "nativeName": "पाऴि" },
    { "code": "fa", "name": "Persian", "nativeName": "فارسی" },
    { "code": "pl", "name": "Polish", "nativeName": "polski" },
    { "code": "ps", "name": "Pashto, Pushto", "nativeName": "پښتو" },
    { "code": "pt", "name": "Portuguese", "nativeName": "Português" },
    { "code": "qu", "name": "Quechua", "nativeName": "Runa Simi, Kichwa" },
    { "code": "rm", "name": "Romansh", "nativeName": "rumantsch grischun" },
    { "code": "rn", "name": "Kirundi", "nativeName": "kiRundi" },
    { "code": "ro", "name": "Romanian, Moldavian, Moldovan", "nativeName": "română" },
    { "code": "ru", "name": "Russian", "nativeName": "русский язык" },
    { "code": "sa", "name": "Sanskrit (Saṁskṛta)", "nativeName": "संस्कृतम्" },
    { "code": "sc", "name": "Sardinian", "nativeName": "sardu" },
    { "code": "sd", "name": "Sindhi", "nativeName": "सिन्धी, سنڌي، سندھی‎" },
    { "code": "se", "name": "Northern Sami", "nativeName": "Davvisámegiella" },
    { "code": "sm", "name": "Samoan", "nativeName": "gagana faa Samoa" },
    { "code": "sg", "name": "Sango", "nativeName": "yângâ tî sängö" },
    { "code": "sr", "name": "Serbian", "nativeName": "српски језик" },
    { "code": "gd", "name": "Scottish Gaelic; Gaelic", "nativeName": "Gàidhlig" },
    { "code": "sn", "name": "Shona", "nativeName": "chiShona" },
    { "code": "si", "name": "Sinhala, Sinhalese", "nativeName": "සිංහල" },
    { "code": "sk", "name": "Slovak", "nativeName": "slovenčina" },
    { "code": "sl", "name": "Slovene", "nativeName": "slovenščina" },
    { "code": "so", "name": "Somali", "nativeName": "Soomaaliga, af Soomaali" },
    { "code": "st", "name": "Southern Sotho", "nativeName": "Sesotho" },
    { "code": "es", "name": "Spanish; Castilian", "nativeName": "español, castellano" },
    { "code": "su", "name": "Sundanese", "nativeName": "Basa Sunda" },
    { "code": "sw", "name": "Swahili", "nativeName": "Kiswahili" },
    { "code": "ss", "name": "Swati", "nativeName": "SiSwati" },
    { "code": "sv", "name": "Swedish", "nativeName": "svenska" },
    { "code": "ta", "name": "Tamil", "nativeName": "தமிழ்" },
    { "code": "te", "name": "Telugu", "nativeName": "తెలుగు" },
    { "code": "tg", "name": "Tajik", "nativeName": "тоҷикӣ, toğikī, تاجیکی‎" },
    { "code": "th", "name": "Thai", "nativeName": "ไทย" },
    { "code": "ti", "name": "Tigrinya", "nativeName": "ትግርኛ" },
    { "code": "bo", "name": "Tibetan Standard, Tibetan, Central", "nativeName": "བོད་ཡིག" },
    { "code": "tk", "name": "Turkmen", "nativeName": "Türkmen, Түркмен" },
    { "code": "tl", "name": "Tagalog", "nativeName": "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔" },
    { "code": "tn", "name": "Tswana", "nativeName": "Setswana" },
    { "code": "to", "name": "Tonga (Tonga Islands)", "nativeName": "faka Tonga" },
    { "code": "tr", "name": "Turkish", "nativeName": "Türkçe" },
    { "code": "ts", "name": "Tsonga", "nativeName": "Xitsonga" },
    { "code": "tt", "name": "Tatar", "nativeName": "татарча, tatarça, تاتارچا‎" },
    { "code": "tw", "name": "Twi", "nativeName": "Twi" },
    { "code": "ty", "name": "Tahitian", "nativeName": "Reo Tahiti" },
    { "code": "ug", "name": "Uighur, Uyghur", "nativeName": "Uyƣurqə, ئۇيغۇرچە‎" },
    { "code": "uk", "name": "Ukrainian", "nativeName": "українська" },
    { "code": "ur", "name": "Urdu", "nativeName": "اردو" },
    { "code": "uz", "name": "Uzbek", "nativeName": "zbek, Ўзбек, أۇزبېك‎" },
    { "code": "ve", "name": "Venda", "nativeName": "Tshivenḓa" },
    { "code": "vi", "name": "Vietnamese", "nativeName": "Tiếng Việt" },
    { "code": "vo", "name": "Volapük", "nativeName": "Volapük" },
    { "code": "wa", "name": "Walloon", "nativeName": "Walon" },
    { "code": "cy", "name": "Welsh", "nativeName": "Cymraeg" },
    { "code": "wo", "name": "Wolof", "nativeName": "Wollof" },
    { "code": "fy", "name": "Western Frisian", "nativeName": "Frysk" },
    { "code": "xh", "name": "Xhosa", "nativeName": "isiXhosa" },
    { "code": "yi", "name": "Yiddish", "nativeName": "ייִדיש" },
    { "code": "yo", "name": "Yoruba", "nativeName": "Yorùbá" },
    { "code": "za", "name": "Zhuang, Chuang", "nativeName": "Saɯ cueŋƅ, Saw cuengh" }
]

export let  countries: Country[] = [{
    "name": "Ascension Island",
    "code": "AC",
    "emoji": "🇦🇨",
    "unicode": "U+1F1E6 U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AC.svg"
  }, {
    "name": "Andorra",
    "code": "AD",
    "emoji": "🇦🇩",
    "unicode": "U+1F1E6 U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg"
  }, {
    "name": "United Arab Emirates",
    "code": "AE",
    "emoji": "🇦🇪",
    "unicode": "U+1F1E6 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg"
  }, {
    "name": "Afghanistan",
    "code": "AF",
    "emoji": "🇦🇫",
    "unicode": "U+1F1E6 U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg"
  }, {
    "name": "Antigua & Barbuda",
    "code": "AG",
    "emoji": "🇦🇬",
    "unicode": "U+1F1E6 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg"
  }, {
    "name": "Anguilla",
    "code": "AI",
    "emoji": "🇦🇮",
    "unicode": "U+1F1E6 U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg"
  }, {
    "name": "Albania",
    "code": "AL",
    "emoji": "🇦🇱",
    "unicode": "U+1F1E6 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg"
  }, {
    "name": "Armenia",
    "code": "AM",
    "emoji": "🇦🇲",
    "unicode": "U+1F1E6 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg"
  }, {
    "name": "Angola",
    "code": "AO",
    "emoji": "🇦🇴",
    "unicode": "U+1F1E6 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg"
  }, {
    "name": "Antarctica",
    "code": "AQ",
    "emoji": "🇦🇶",
    "unicode": "U+1F1E6 U+1F1F6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg"
  }, {
    "name": "Argentina",
    "code": "AR",
    "emoji": "🇦🇷",
    "unicode": "U+1F1E6 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg"
  }, {
    "name": "American Samoa",
    "code": "AS",
    "emoji": "🇦🇸",
    "unicode": "U+1F1E6 U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg"
  }, {
    "name": "Austria",
    "code": "AT",
    "emoji": "🇦🇹",
    "unicode": "U+1F1E6 U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg"
  }, {
    "name": "Australia",
    "code": "AU",
    "emoji": "🇦🇺",
    "unicode": "U+1F1E6 U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg"
  }, {
    "name": "Aruba",
    "code": "AW",
    "emoji": "🇦🇼",
    "unicode": "U+1F1E6 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg"
  }, {
    "name": "Åland Islands",
    "code": "AX",
    "emoji": "🇦🇽",
    "unicode": "U+1F1E6 U+1F1FD",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AX.svg"
  }, {
    "name": "Azerbaijan",
    "code": "AZ",
    "emoji": "🇦🇿",
    "unicode": "U+1F1E6 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg"
  }, {
    "name": "Bosnia & Herzegovina",
    "code": "BA",
    "emoji": "🇧🇦",
    "unicode": "U+1F1E7 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg"
  }, {
    "name": "Barbados",
    "code": "BB",
    "emoji": "🇧🇧",
    "unicode": "U+1F1E7 U+1F1E7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg"
  }, {
    "name": "Bangladesh",
    "code": "BD",
    "emoji": "🇧🇩",
    "unicode": "U+1F1E7 U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg"
  }, {
    "name": "Belgium",
    "code": "BE",
    "emoji": "🇧🇪",
    "unicode": "U+1F1E7 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg"
  }, {
    "name": "Burkina Faso",
    "code": "BF",
    "emoji": "🇧🇫",
    "unicode": "U+1F1E7 U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg"
  }, {
    "name": "Bulgaria",
    "code": "BG",
    "emoji": "🇧🇬",
    "unicode": "U+1F1E7 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg"
  }, {
    "name": "Bahrain",
    "code": "BH",
    "emoji": "🇧🇭",
    "unicode": "U+1F1E7 U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
  }, {
    "name": "Burundi",
    "code": "BI",
    "emoji": "🇧🇮",
    "unicode": "U+1F1E7 U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg"
  }, {
    "name": "Benin",
    "code": "BJ",
    "emoji": "🇧🇯",
    "unicode": "U+1F1E7 U+1F1EF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg"
  }, {
    "name": "St. Barthélemy",
    "code": "BL",
    "emoji": "🇧🇱",
    "unicode": "U+1F1E7 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg"
  }, {
    "name": "Bermuda",
    "code": "BM",
    "emoji": "🇧🇲",
    "unicode": "U+1F1E7 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg"
  }, {
    "name": "Brunei",
    "code": "BN",
    "emoji": "🇧🇳",
    "unicode": "U+1F1E7 U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg"
  }, {
    "name": "Bolivia",
    "code": "BO",
    "emoji": "🇧🇴",
    "unicode": "U+1F1E7 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg"
  }, {
    "name": "Caribbean Netherlands",
    "code": "BQ",
    "emoji": "🇧🇶",
    "unicode": "U+1F1E7 U+1F1F6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg"
  }, {
    "name": "Brazil",
    "code": "BR",
    "emoji": "🇧🇷",
    "unicode": "U+1F1E7 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg"
  }, {
    "name": "Bahamas",
    "code": "BS",
    "emoji": "🇧🇸",
    "unicode": "U+1F1E7 U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg"
  }, {
    "name": "Bhutan",
    "code": "BT",
    "emoji": "🇧🇹",
    "unicode": "U+1F1E7 U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg"
  }, {
    "name": "Bouvet Island",
    "code": "BV",
    "emoji": "🇧🇻",
    "unicode": "U+1F1E7 U+1F1FB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BV.svg"
  }, {
    "name": "Botswana",
    "code": "BW",
    "emoji": "🇧🇼",
    "unicode": "U+1F1E7 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg"
  }, {
    "name": "Belarus",
    "code": "BY",
    "emoji": "🇧🇾",
    "unicode": "U+1F1E7 U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg"
  }, {
    "name": "Belize",
    "code": "BZ",
    "emoji": "🇧🇿",
    "unicode": "U+1F1E7 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg"
  }, {
    "name": "Canada",
    "code": "CA",
    "emoji": "🇨🇦",
    "unicode": "U+1F1E8 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg"
  }, {
    "name": "Cocos (Keeling) Islands",
    "code": "CC",
    "emoji": "🇨🇨",
    "unicode": "U+1F1E8 U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg"
  }, {
    "name": "Congo - Kinshasa",
    "code": "CD",
    "emoji": "🇨🇩",
    "unicode": "U+1F1E8 U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg"
  }, {
    "name": "Central African Republic",
    "code": "CF",
    "emoji": "🇨🇫",
    "unicode": "U+1F1E8 U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg"
  }, {
    "name": "Congo - Brazzaville",
    "code": "CG",
    "emoji": "🇨🇬",
    "unicode": "U+1F1E8 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg"
  }, {
    "name": "Switzerland",
    "code": "CH",
    "emoji": "🇨🇭",
    "unicode": "U+1F1E8 U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg"
  }, {
    "name": "Côte d’Ivoire",
    "code": "CI",
    "emoji": "🇨🇮",
    "unicode": "U+1F1E8 U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg"
  }, {
    "name": "Cook Islands",
    "code": "CK",
    "emoji": "🇨🇰",
    "unicode": "U+1F1E8 U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg"
  }, {
    "name": "Chile",
    "code": "CL",
    "emoji": "🇨🇱",
    "unicode": "U+1F1E8 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg"
  }, {
    "name": "Cameroon",
    "code": "CM",
    "emoji": "🇨🇲",
    "unicode": "U+1F1E8 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg"
  }, {
    "name": "China",
    "code": "CN",
    "emoji": "🇨🇳",
    "unicode": "U+1F1E8 U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg"
  }, {
    "name": "Colombia",
    "code": "CO",
    "emoji": "🇨🇴",
    "unicode": "U+1F1E8 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg"
  }, {
    "name": "Clipperton Island",
    "code": "CP",
    "emoji": "🇨🇵",
    "unicode": "U+1F1E8 U+1F1F5",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CP.svg"
  }, {
    "name": "Costa Rica",
    "code": "CR",
    "emoji": "🇨🇷",
    "unicode": "U+1F1E8 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg"
  }, {
    "name": "Cuba",
    "code": "CU",
    "emoji": "🇨🇺",
    "unicode": "U+1F1E8 U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg"
  }, {
    "name": "Cape Verde",
    "code": "CV",
    "emoji": "🇨🇻",
    "unicode": "U+1F1E8 U+1F1FB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg"
  }, {
    "name": "Curaçao",
    "code": "CW",
    "emoji": "🇨🇼",
    "unicode": "U+1F1E8 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg"
  }, {
    "name": "Christmas Island",
    "code": "CX",
    "emoji": "🇨🇽",
    "unicode": "U+1F1E8 U+1F1FD",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg"
  }, {
    "name": "Cyprus",
    "code": "CY",
    "emoji": "🇨🇾",
    "unicode": "U+1F1E8 U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg"
  }, {
    "name": "Czechia",
    "code": "CZ",
    "emoji": "🇨🇿",
    "unicode": "U+1F1E8 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg"
  }, {
    "name": "Germany",
    "code": "DE",
    "emoji": "🇩🇪",
    "unicode": "U+1F1E9 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg"
  }, {
    "name": "Diego Garcia",
    "code": "DG",
    "emoji": "🇩🇬",
    "unicode": "U+1F1E9 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DG.svg"
  }, {
    "name": "Djibouti",
    "code": "DJ",
    "emoji": "🇩🇯",
    "unicode": "U+1F1E9 U+1F1EF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg"
  }, {
    "name": "Denmark",
    "code": "DK",
    "emoji": "🇩🇰",
    "unicode": "U+1F1E9 U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg"
  }, {
    "name": "Dominica",
    "code": "DM",
    "emoji": "🇩🇲",
    "unicode": "U+1F1E9 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg"
  }, {
    "name": "Dominican Republic",
    "code": "DO",
    "emoji": "🇩🇴",
    "unicode": "U+1F1E9 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg"
  }, {
    "name": "Algeria",
    "code": "DZ",
    "emoji": "🇩🇿",
    "unicode": "U+1F1E9 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg"
  }, {
    "name": "Ceuta & Melilla",
    "code": "EA",
    "emoji": "🇪🇦",
    "unicode": "U+1F1EA U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EA.svg"
  }, {
    "name": "Ecuador",
    "code": "EC",
    "emoji": "🇪🇨",
    "unicode": "U+1F1EA U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg"
  }, {
    "name": "Estonia",
    "code": "EE",
    "emoji": "🇪🇪",
    "unicode": "U+1F1EA U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg"
  }, {
    "name": "Egypt",
    "code": "EG",
    "emoji": "🇪🇬",
    "unicode": "U+1F1EA U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg"
  }, {
    "name": "Western Sahara",
    "code": "EH",
    "emoji": "🇪🇭",
    "unicode": "U+1F1EA U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EH.svg"
  }, {
    "name": "Eritrea",
    "code": "ER",
    "emoji": "🇪🇷",
    "unicode": "U+1F1EA U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg"
  }, {
    "name": "Spain",
    "code": "ES",
    "emoji": "🇪🇸",
    "unicode": "U+1F1EA U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg"
  }, {
    "name": "Ethiopia",
    "code": "ET",
    "emoji": "🇪🇹",
    "unicode": "U+1F1EA U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg"
  }, {
    "name": "European Union",
    "code": "EU",
    "emoji": "🇪🇺",
    "unicode": "U+1F1EA U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EU.svg"
  }, {
    "name": "Finland",
    "code": "FI",
    "emoji": "🇫🇮",
    "unicode": "U+1F1EB U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg"
  }, {
    "name": "Fiji",
    "code": "FJ",
    "emoji": "🇫🇯",
    "unicode": "U+1F1EB U+1F1EF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg"
  }, {
    "name": "Falkland Islands",
    "code": "FK",
    "emoji": "🇫🇰",
    "unicode": "U+1F1EB U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg"
  }, {
    "name": "Micronesia",
    "code": "FM",
    "emoji": "🇫🇲",
    "unicode": "U+1F1EB U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg"
  }, {
    "name": "Faroe Islands",
    "code": "FO",
    "emoji": "🇫🇴",
    "unicode": "U+1F1EB U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg"
  }, {
    "name": "France",
    "code": "FR",
    "emoji": "🇫🇷",
    "unicode": "U+1F1EB U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg"
  }, {
    "name": "Gabon",
    "code": "GA",
    "emoji": "🇬🇦",
    "unicode": "U+1F1EC U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg"
  }, {
    "name": "United Kingdom",
    "code": "GB",
    "emoji": "🇬🇧",
    "unicode": "U+1F1EC U+1F1E7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg"
  }, {
    "name": "Grenada",
    "code": "GD",
    "emoji": "🇬🇩",
    "unicode": "U+1F1EC U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg"
  }, {
    "name": "Georgia",
    "code": "GE",
    "emoji": "🇬🇪",
    "unicode": "U+1F1EC U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg"
  }, {
    "name": "French Guiana",
    "code": "GF",
    "emoji": "🇬🇫",
    "unicode": "U+1F1EC U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg"
  }, {
    "name": "Guernsey",
    "code": "GG",
    "emoji": "🇬🇬",
    "unicode": "U+1F1EC U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg"
  }, {
    "name": "Ghana",
    "code": "GH",
    "emoji": "🇬🇭",
    "unicode": "U+1F1EC U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg"
  }, {
    "name": "Gibraltar",
    "code": "GI",
    "emoji": "🇬🇮",
    "unicode": "U+1F1EC U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg"
  }, {
    "name": "Greenland",
    "code": "GL",
    "emoji": "🇬🇱",
    "unicode": "U+1F1EC U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg"
  }, {
    "name": "Gambia",
    "code": "GM",
    "emoji": "🇬🇲",
    "unicode": "U+1F1EC U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg"
  }, {
    "name": "Guinea",
    "code": "GN",
    "emoji": "🇬🇳",
    "unicode": "U+1F1EC U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg"
  }, {
    "name": "Guadeloupe",
    "code": "GP",
    "emoji": "🇬🇵",
    "unicode": "U+1F1EC U+1F1F5",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg"
  }, {
    "name": "Equatorial Guinea",
    "code": "GQ",
    "emoji": "🇬🇶",
    "unicode": "U+1F1EC U+1F1F6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg"
  }, {
    "name": "Greece",
    "code": "GR",
    "emoji": "🇬🇷",
    "unicode": "U+1F1EC U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg"
  }, {
    "name": "South Georgia & South Sandwich Islands",
    "code": "GS",
    "emoji": "🇬🇸",
    "unicode": "U+1F1EC U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GS.svg"
  }, {
    "name": "Guatemala",
    "code": "GT",
    "emoji": "🇬🇹",
    "unicode": "U+1F1EC U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg"
  }, {
    "name": "Guam",
    "code": "GU",
    "emoji": "🇬🇺",
    "unicode": "U+1F1EC U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg"
  }, {
    "name": "Guinea-Bissau",
    "code": "GW",
    "emoji": "🇬🇼",
    "unicode": "U+1F1EC U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg"
  }, {
    "name": "Guyana",
    "code": "GY",
    "emoji": "🇬🇾",
    "unicode": "U+1F1EC U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg"
  }, {
    "name": "Hong Kong SAR China",
    "code": "HK",
    "emoji": "🇭🇰",
    "unicode": "U+1F1ED U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg"
  }, {
    "name": "Heard & McDonald Islands",
    "code": "HM",
    "emoji": "🇭🇲",
    "unicode": "U+1F1ED U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HM.svg"
  }, {
    "name": "Honduras",
    "code": "HN",
    "emoji": "🇭🇳",
    "unicode": "U+1F1ED U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg"
  }, {
    "name": "Croatia",
    "code": "HR",
    "emoji": "🇭🇷",
    "unicode": "U+1F1ED U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg"
  }, {
    "name": "Haiti",
    "code": "HT",
    "emoji": "🇭🇹",
    "unicode": "U+1F1ED U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg"
  }, {
    "name": "Hungary",
    "code": "HU",
    "emoji": "🇭🇺",
    "unicode": "U+1F1ED U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg"
  }, {
    "name": "Canary Islands",
    "code": "IC",
    "emoji": "🇮🇨",
    "unicode": "U+1F1EE U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IC.svg"
  }, {
    "name": "Indonesia",
    "code": "ID",
    "emoji": "🇮🇩",
    "unicode": "U+1F1EE U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg"
  }, {
    "name": "Ireland",
    "code": "IE",
    "emoji": "🇮🇪",
    "unicode": "U+1F1EE U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg"
  }, {
    "name": "Israel",
    "code": "IL",
    "emoji": "🇮🇱",
    "unicode": "U+1F1EE U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg"
  }, {
    "name": "Isle of Man",
    "code": "IM",
    "emoji": "🇮🇲",
    "unicode": "U+1F1EE U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg"
  }, {
    "name": "India",
    "code": "IN",
    "emoji": "🇮🇳",
    "unicode": "U+1F1EE U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg"
  }, {
    "name": "British Indian Ocean Territory",
    "code": "IO",
    "emoji": "🇮🇴",
    "unicode": "U+1F1EE U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg"
  }, {
    "name": "Iraq",
    "code": "IQ",
    "emoji": "🇮🇶",
    "unicode": "U+1F1EE U+1F1F6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg"
  }, {
    "name": "Iran",
    "code": "IR",
    "emoji": "🇮🇷",
    "unicode": "U+1F1EE U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg"
  }, {
    "name": "Iceland",
    "code": "IS",
    "emoji": "🇮🇸",
    "unicode": "U+1F1EE U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg"
  }, {
    "name": "Italy",
    "code": "IT",
    "emoji": "🇮🇹",
    "unicode": "U+1F1EE U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg"
  }, {
    "name": "Jersey",
    "code": "JE",
    "emoji": "🇯🇪",
    "unicode": "U+1F1EF U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg"
  }, {
    "name": "Jamaica",
    "code": "JM",
    "emoji": "🇯🇲",
    "unicode": "U+1F1EF U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg"
  }, {
    "name": "Jordan",
    "code": "JO",
    "emoji": "🇯🇴",
    "unicode": "U+1F1EF U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg"
  }, {
    "name": "Japan",
    "code": "JP",
    "emoji": "🇯🇵",
    "unicode": "U+1F1EF U+1F1F5",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg"
  }, {
    "name": "Kenya",
    "code": "KE",
    "emoji": "🇰🇪",
    "unicode": "U+1F1F0 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg"
  }, {
    "name": "Kyrgyzstan",
    "code": "KG",
    "emoji": "🇰🇬",
    "unicode": "U+1F1F0 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg"
  }, {
    "name": "Cambodia",
    "code": "KH",
    "emoji": "🇰🇭",
    "unicode": "U+1F1F0 U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg"
  }, {
    "name": "Kiribati",
    "code": "KI",
    "emoji": "🇰🇮",
    "unicode": "U+1F1F0 U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg"
  }, {
    "name": "Comoros",
    "code": "KM",
    "emoji": "🇰🇲",
    "unicode": "U+1F1F0 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg"
  }, {
    "name": "St. Kitts & Nevis",
    "code": "KN",
    "emoji": "🇰🇳",
    "unicode": "U+1F1F0 U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg"
  }, {
    "name": "North Korea",
    "code": "KP",
    "emoji": "🇰🇵",
    "unicode": "U+1F1F0 U+1F1F5",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg"
  }, {
    "name": "South Korea",
    "code": "KR",
    "emoji": "🇰🇷",
    "unicode": "U+1F1F0 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg"
  }, {
    "name": "Kuwait",
    "code": "KW",
    "emoji": "🇰🇼",
    "unicode": "U+1F1F0 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg"
  }, {
    "name": "Cayman Islands",
    "code": "KY",
    "emoji": "🇰🇾",
    "unicode": "U+1F1F0 U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg"
  }, {
    "name": "Kazakhstan",
    "code": "KZ",
    "emoji": "🇰🇿",
    "unicode": "U+1F1F0 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg"
  }, {
    "name": "Laos",
    "code": "LA",
    "emoji": "🇱🇦",
    "unicode": "U+1F1F1 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg"
  }, {
    "name": "Lebanon",
    "code": "LB",
    "emoji": "🇱🇧",
    "unicode": "U+1F1F1 U+1F1E7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg"
  }, {
    "name": "St. Lucia",
    "code": "LC",
    "emoji": "🇱🇨",
    "unicode": "U+1F1F1 U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg"
  }, {
    "name": "Liechtenstein",
    "code": "LI",
    "emoji": "🇱🇮",
    "unicode": "U+1F1F1 U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg"
  }, {
    "name": "Sri Lanka",
    "code": "LK",
    "emoji": "🇱🇰",
    "unicode": "U+1F1F1 U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg"
  }, {
    "name": "Liberia",
    "code": "LR",
    "emoji": "🇱🇷",
    "unicode": "U+1F1F1 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg"
  }, {
    "name": "Lesotho",
    "code": "LS",
    "emoji": "🇱🇸",
    "unicode": "U+1F1F1 U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg"
  }, {
    "name": "Lithuania",
    "code": "LT",
    "emoji": "🇱🇹",
    "unicode": "U+1F1F1 U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg"
  }, {
    "name": "Luxembourg",
    "code": "LU",
    "emoji": "🇱🇺",
    "unicode": "U+1F1F1 U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg"
  }, {
    "name": "Latvia",
    "code": "LV",
    "emoji": "🇱🇻",
    "unicode": "U+1F1F1 U+1F1FB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg"
  }, {
    "name": "Libya",
    "code": "LY",
    "emoji": "🇱🇾",
    "unicode": "U+1F1F1 U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg"
  }, {
    "name": "Morocco",
    "code": "MA",
    "emoji": "🇲🇦",
    "unicode": "U+1F1F2 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg"
  }, {
    "name": "Monaco",
    "code": "MC",
    "emoji": "🇲🇨",
    "unicode": "U+1F1F2 U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg"
  }, {
    "name": "Moldova",
    "code": "MD",
    "emoji": "🇲🇩",
    "unicode": "U+1F1F2 U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg"
  }, {
    "name": "Montenegro",
    "code": "ME",
    "emoji": "🇲🇪",
    "unicode": "U+1F1F2 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg"
  }, {
    "name": "St. Martin",
    "code": "MF",
    "emoji": "🇲🇫",
    "unicode": "U+1F1F2 U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg"
  }, {
    "name": "Madagascar",
    "code": "MG",
    "emoji": "🇲🇬",
    "unicode": "U+1F1F2 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg"
  }, {
    "name": "Marshall Islands",
    "code": "MH",
    "emoji": "🇲🇭",
    "unicode": "U+1F1F2 U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg"
  }, {
    "name": "North Macedonia",
    "code": "MK",
    "emoji": "🇲🇰",
    "unicode": "U+1F1F2 U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg"
  }, {
    "name": "Mali",
    "code": "ML",
    "emoji": "🇲🇱",
    "unicode": "U+1F1F2 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg"
  }, {
    "name": "Myanmar (Burma)",
    "code": "MM",
    "emoji": "🇲🇲",
    "unicode": "U+1F1F2 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg"
  }, {
    "name": "Mongolia",
    "code": "MN",
    "emoji": "🇲🇳",
    "unicode": "U+1F1F2 U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg"
  }, {
    "name": "Macao SAR China",
    "code": "MO",
    "emoji": "🇲🇴",
    "unicode": "U+1F1F2 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg"
  }, {
    "name": "Northern Mariana Islands",
    "code": "MP",
    "emoji": "🇲🇵",
    "unicode": "U+1F1F2 U+1F1F5",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg"
  }, {
    "name": "Martinique",
    "code": "MQ",
    "emoji": "🇲🇶",
    "unicode": "U+1F1F2 U+1F1F6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg"
  }, {
    "name": "Mauritania",
    "code": "MR",
    "emoji": "🇲🇷",
    "unicode": "U+1F1F2 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg"
  }, {
    "name": "Montserrat",
    "code": "MS",
    "emoji": "🇲🇸",
    "unicode": "U+1F1F2 U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg"
  }, {
    "name": "Malta",
    "code": "MT",
    "emoji": "🇲🇹",
    "unicode": "U+1F1F2 U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg"
  }, {
    "name": "Mauritius",
    "code": "MU",
    "emoji": "🇲🇺",
    "unicode": "U+1F1F2 U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg"
  }, {
    "name": "Maldives",
    "code": "MV",
    "emoji": "🇲🇻",
    "unicode": "U+1F1F2 U+1F1FB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg"
  }, {
    "name": "Malawi",
    "code": "MW",
    "emoji": "🇲🇼",
    "unicode": "U+1F1F2 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg"
  }, {
    "name": "Mexico",
    "code": "MX",
    "emoji": "🇲🇽",
    "unicode": "U+1F1F2 U+1F1FD",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg"
  }, {
    "name": "Malaysia",
    "code": "MY",
    "emoji": "🇲🇾",
    "unicode": "U+1F1F2 U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg"
  }, {
    "name": "Mozambique",
    "code": "MZ",
    "emoji": "🇲🇿",
    "unicode": "U+1F1F2 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg"
  }, {
    "name": "Namibia",
    "code": "NA",
    "emoji": "🇳🇦",
    "unicode": "U+1F1F3 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg"
  }, {
    "name": "New Caledonia",
    "code": "NC",
    "emoji": "🇳🇨",
    "unicode": "U+1F1F3 U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg"
  }, {
    "name": "Niger",
    "code": "NE",
    "emoji": "🇳🇪",
    "unicode": "U+1F1F3 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg"
  }, {
    "name": "Norfolk Island",
    "code": "NF",
    "emoji": "🇳🇫",
    "unicode": "U+1F1F3 U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg"
  }, {
    "name": "Nigeria",
    "code": "NG",
    "emoji": "🇳🇬",
    "unicode": "U+1F1F3 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg"
  }, {
    "name": "Nicaragua",
    "code": "NI",
    "emoji": "🇳🇮",
    "unicode": "U+1F1F3 U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg"
  }, {
    "name": "Netherlands",
    "code": "NL",
    "emoji": "🇳🇱",
    "unicode": "U+1F1F3 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg"
  }, {
    "name": "Norway",
    "code": "NO",
    "emoji": "🇳🇴",
    "unicode": "U+1F1F3 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg"
  }, {
    "name": "Nepal",
    "code": "NP",
    "emoji": "🇳🇵",
    "unicode": "U+1F1F3 U+1F1F5",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg"
  }, {
    "name": "Nauru",
    "code": "NR",
    "emoji": "🇳🇷",
    "unicode": "U+1F1F3 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg"
  }, {
    "name": "Niue",
    "code": "NU",
    "emoji": "🇳🇺",
    "unicode": "U+1F1F3 U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg"
  }, {
    "name": "New Zealand",
    "code": "NZ",
    "emoji": "🇳🇿",
    "unicode": "U+1F1F3 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg"
  }, {
    "name": "Oman",
    "code": "OM",
    "emoji": "🇴🇲",
    "unicode": "U+1F1F4 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg"
  }, {
    "name": "Panama",
    "code": "PA",
    "emoji": "🇵🇦",
    "unicode": "U+1F1F5 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg"
  }, {
    "name": "Peru",
    "code": "PE",
    "emoji": "🇵🇪",
    "unicode": "U+1F1F5 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg"
  }, {
    "name": "French Polynesia",
    "code": "PF",
    "emoji": "🇵🇫",
    "unicode": "U+1F1F5 U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg"
  }, {
    "name": "Papua New Guinea",
    "code": "PG",
    "emoji": "🇵🇬",
    "unicode": "U+1F1F5 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg"
  }, {
    "name": "Philippines",
    "code": "PH",
    "emoji": "🇵🇭",
    "unicode": "U+1F1F5 U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg"
  }, {
    "name": "Pakistan",
    "code": "PK",
    "emoji": "🇵🇰",
    "unicode": "U+1F1F5 U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg"
  }, {
    "name": "Poland",
    "code": "PL",
    "emoji": "🇵🇱",
    "unicode": "U+1F1F5 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg"
  }, {
    "name": "St. Pierre & Miquelon",
    "code": "PM",
    "emoji": "🇵🇲",
    "unicode": "U+1F1F5 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg"
  }, {
    "name": "Pitcairn Islands",
    "code": "PN",
    "emoji": "🇵🇳",
    "unicode": "U+1F1F5 U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg"
  }, {
    "name": "Puerto Rico",
    "code": "PR",
    "emoji": "🇵🇷",
    "unicode": "U+1F1F5 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg"
  }, {
    "name": "Palestinian Territories",
    "code": "PS",
    "emoji": "🇵🇸",
    "unicode": "U+1F1F5 U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg"
  }, {
    "name": "Portugal",
    "code": "PT",
    "emoji": "🇵🇹",
    "unicode": "U+1F1F5 U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg"
  }, {
    "name": "Palau",
    "code": "PW",
    "emoji": "🇵🇼",
    "unicode": "U+1F1F5 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg"
  }, {
    "name": "Paraguay",
    "code": "PY",
    "emoji": "🇵🇾",
    "unicode": "U+1F1F5 U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg"
  }, {
    "name": "Qatar",
    "code": "QA",
    "emoji": "🇶🇦",
    "unicode": "U+1F1F6 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg"
  }, {
    "name": "Réunion",
    "code": "RE",
    "emoji": "🇷🇪",
    "unicode": "U+1F1F7 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg"
  }, {
    "name": "Romania",
    "code": "RO",
    "emoji": "🇷🇴",
    "unicode": "U+1F1F7 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg"
  }, {
    "name": "Serbia",
    "code": "RS",
    "emoji": "🇷🇸",
    "unicode": "U+1F1F7 U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg"
  }, {
    "name": "Russia",
    "code": "RU",
    "emoji": "🇷🇺",
    "unicode": "U+1F1F7 U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg"
  }, {
    "name": "Rwanda",
    "code": "RW",
    "emoji": "🇷🇼",
    "unicode": "U+1F1F7 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg"
  }, {
    "name": "Saudi Arabia",
    "code": "SA",
    "emoji": "🇸🇦",
    "unicode": "U+1F1F8 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg"
  }, {
    "name": "Solomon Islands",
    "code": "SB",
    "emoji": "🇸🇧",
    "unicode": "U+1F1F8 U+1F1E7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg"
  }, {
    "name": "Seychelles",
    "code": "SC",
    "emoji": "🇸🇨",
    "unicode": "U+1F1F8 U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg"
  }, {
    "name": "Sudan",
    "code": "SD",
    "emoji": "🇸🇩",
    "unicode": "U+1F1F8 U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg"
  }, {
    "name": "Sweden",
    "code": "SE",
    "emoji": "🇸🇪",
    "unicode": "U+1F1F8 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg"
  }, {
    "name": "Singapore",
    "code": "SG",
    "emoji": "🇸🇬",
    "unicode": "U+1F1F8 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg"
  }, {
    "name": "St. Helena",
    "code": "SH",
    "emoji": "🇸🇭",
    "unicode": "U+1F1F8 U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg"
  }, {
    "name": "Slovenia",
    "code": "SI",
    "emoji": "🇸🇮",
    "unicode": "U+1F1F8 U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg"
  }, {
    "name": "Svalbard & Jan Mayen",
    "code": "SJ",
    "emoji": "🇸🇯",
    "unicode": "U+1F1F8 U+1F1EF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg"
  }, {
    "name": "Slovakia",
    "code": "SK",
    "emoji": "🇸🇰",
    "unicode": "U+1F1F8 U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg"
  }, {
    "name": "Sierra Leone",
    "code": "SL",
    "emoji": "🇸🇱",
    "unicode": "U+1F1F8 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg"
  }, {
    "name": "San Marino",
    "code": "SM",
    "emoji": "🇸🇲",
    "unicode": "U+1F1F8 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg"
  }, {
    "name": "Senegal",
    "code": "SN",
    "emoji": "🇸🇳",
    "unicode": "U+1F1F8 U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg"
  }, {
    "name": "Somalia",
    "code": "SO",
    "emoji": "🇸🇴",
    "unicode": "U+1F1F8 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg"
  }, {
    "name": "Suriname",
    "code": "SR",
    "emoji": "🇸🇷",
    "unicode": "U+1F1F8 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg"
  }, {
    "name": "South Sudan",
    "code": "SS",
    "emoji": "🇸🇸",
    "unicode": "U+1F1F8 U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg"
  }, {
    "name": "São Tomé & Príncipe",
    "code": "ST",
    "emoji": "🇸🇹",
    "unicode": "U+1F1F8 U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg"
  }, {
    "name": "El Salvador",
    "code": "SV",
    "emoji": "🇸🇻",
    "unicode": "U+1F1F8 U+1F1FB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg"
  }, {
    "name": "Sint Maarten",
    "code": "SX",
    "emoji": "🇸🇽",
    "unicode": "U+1F1F8 U+1F1FD",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg"
  }, {
    "name": "Syria",
    "code": "SY",
    "emoji": "🇸🇾",
    "unicode": "U+1F1F8 U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg"
  }, {
    "name": "Eswatini",
    "code": "SZ",
    "emoji": "🇸🇿",
    "unicode": "U+1F1F8 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg"
  }, {
    "name": "Tristan da Cunha",
    "code": "TA",
    "emoji": "🇹🇦",
    "unicode": "U+1F1F9 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TA.svg"
  }, {
    "name": "Turks & Caicos Islands",
    "code": "TC",
    "emoji": "🇹🇨",
    "unicode": "U+1F1F9 U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg"
  }, {
    "name": "Chad",
    "code": "TD",
    "emoji": "🇹🇩",
    "unicode": "U+1F1F9 U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg"
  }, {
    "name": "French Southern Territories",
    "code": "TF",
    "emoji": "🇹🇫",
    "unicode": "U+1F1F9 U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TF.svg"
  }, {
    "name": "Togo",
    "code": "TG",
    "emoji": "🇹🇬",
    "unicode": "U+1F1F9 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg"
  }, {
    "name": "Thailand",
    "code": "TH",
    "emoji": "🇹🇭",
    "unicode": "U+1F1F9 U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg"
  }, {
    "name": "Tajikistan",
    "code": "TJ",
    "emoji": "🇹🇯",
    "unicode": "U+1F1F9 U+1F1EF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg"
  }, {
    "name": "Tokelau",
    "code": "TK",
    "emoji": "🇹🇰",
    "unicode": "U+1F1F9 U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg"
  }, {
    "name": "Timor-Leste",
    "code": "TL",
    "emoji": "🇹🇱",
    "unicode": "U+1F1F9 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg"
  }, {
    "name": "Turkmenistan",
    "code": "TM",
    "emoji": "🇹🇲",
    "unicode": "U+1F1F9 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg"
  }, {
    "name": "Tunisia",
    "code": "TN",
    "emoji": "🇹🇳",
    "unicode": "U+1F1F9 U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg"
  }, {
    "name": "Tonga",
    "code": "TO",
    "emoji": "🇹🇴",
    "unicode": "U+1F1F9 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg"
  }, {
    "name": "Turkey",
    "code": "TR",
    "emoji": "🇹🇷",
    "unicode": "U+1F1F9 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg"
  }, {
    "name": "Trinidad & Tobago",
    "code": "TT",
    "emoji": "🇹🇹",
    "unicode": "U+1F1F9 U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg"
  }, {
    "name": "Tuvalu",
    "code": "TV",
    "emoji": "🇹🇻",
    "unicode": "U+1F1F9 U+1F1FB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg"
  }, {
    "name": "Taiwan",
    "code": "TW",
    "emoji": "🇹🇼",
    "unicode": "U+1F1F9 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg"
  }, {
    "name": "Tanzania",
    "code": "TZ",
    "emoji": "🇹🇿",
    "unicode": "U+1F1F9 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg"
  }, {
    "name": "Ukraine",
    "code": "UA",
    "emoji": "🇺🇦",
    "unicode": "U+1F1FA U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg"
  }, {
    "name": "Uganda",
    "code": "UG",
    "emoji": "🇺🇬",
    "unicode": "U+1F1FA U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg"
  }, {
    "name": "U.S. Outlying Islands",
    "code": "UM",
    "emoji": "🇺🇲",
    "unicode": "U+1F1FA U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UM.svg"
  }, {
    "name": "United Nations",
    "code": "UN",
    "emoji": "🇺🇳",
    "unicode": "U+1F1FA U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UN.svg"
  }, {
    "name": "United States",
    "code": "US",
    "emoji": "🇺🇸",
    "unicode": "U+1F1FA U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg"
  }, {
    "name": "Uruguay",
    "code": "UY",
    "emoji": "🇺🇾",
    "unicode": "U+1F1FA U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg"
  }, {
    "name": "Uzbekistan",
    "code": "UZ",
    "emoji": "🇺🇿",
    "unicode": "U+1F1FA U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg"
  }, {
    "name": "Vatican City",
    "code": "VA",
    "emoji": "🇻🇦",
    "unicode": "U+1F1FB U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg"
  }, {
    "name": "St. Vincent & Grenadines",
    "code": "VC",
    "emoji": "🇻🇨",
    "unicode": "U+1F1FB U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg"
  }, {
    "name": "Venezuela",
    "code": "VE",
    "emoji": "🇻🇪",
    "unicode": "U+1F1FB U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg"
  }, {
    "name": "British Virgin Islands",
    "code": "VG",
    "emoji": "🇻🇬",
    "unicode": "U+1F1FB U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg"
  }, {
    "name": "U.S. Virgin Islands",
    "code": "VI",
    "emoji": "🇻🇮",
    "unicode": "U+1F1FB U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg"
  }, {
    "name": "Vietnam",
    "code": "VN",
    "emoji": "🇻🇳",
    "unicode": "U+1F1FB U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg"
  }, {
    "name": "Vanuatu",
    "code": "VU",
    "emoji": "🇻🇺",
    "unicode": "U+1F1FB U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg"
  }, {
    "name": "Wallis & Futuna",
    "code": "WF",
    "emoji": "🇼🇫",
    "unicode": "U+1F1FC U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg"
  }, {
    "name": "Samoa",
    "code": "WS",
    "emoji": "🇼🇸",
    "unicode": "U+1F1FC U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg"
  }, {
    "name": "Kosovo",
    "code": "XK",
    "emoji": "🇽🇰",
    "unicode": "U+1F1FD U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg"
  }, {
    "name": "Yemen",
    "code": "YE",
    "emoji": "🇾🇪",
    "unicode": "U+1F1FE U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg"
  }, {
    "name": "Mayotte",
    "code": "YT",
    "emoji": "🇾🇹",
    "unicode": "U+1F1FE U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg"
  }, {
    "name": "South Africa",
    "code": "ZA",
    "emoji": "🇿🇦",
    "unicode": "U+1F1FF U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg"
  }, {
    "name": "Zambia",
    "code": "ZM",
    "emoji": "🇿🇲",
    "unicode": "U+1F1FF U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg"
  }, {
    "name": "Zimbabwe",
    "code": "ZW",
    "emoji": "🇿🇼",
    "unicode": "U+1F1FF U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg"
  }, {
    "name": "England",
    "code": "ENGLAND",
    "emoji": "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
    "unicode": "U+1F3F4 U+E0067 U+E0062 U+E0065 U+E006E U+E0067 U+E007F",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ENGLAND.svg"
  }, {
    "name": "Scotland",
    "code": "SCOTLAND",
    "emoji": "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
    "unicode": "U+1F3F4 U+E0067 U+E0062 U+E0073 U+E0063 U+E0074 U+E007F",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SCOTLAND.svg"
  }, {
    "name": "Wales",
    "code": "WALES",
    "emoji": "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
    "unicode": "U+1F3F4 U+E0067 U+E0062 U+E0077 U+E006C U+E0073 U+E007F",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WALES.svg"
  }];

export let timezones: any[] = [
  {
    "value": "Dateline Standard Time",
    "abbr": "DST",
    "offset": -12,
    "isdst": false,
    "text": "(UTC-12:00) International Date Line West",
    "utc": [
      "Etc/GMT+12"
    ],
    "countries": [
      "UM"
    ]
  },
  {
    "value": "UTC-11",
    "abbr": "U",
    "offset": -11,
    "isdst": false,
    "text": "(UTC-11:00) Coordinated Universal Time-11",
    "utc": [
      "Etc/GMT+11",
      "Pacific/Midway",
      "Pacific/Niue",
      "Pacific/Pago_Pago"
    ],
    "countries": [
      "AS",
      "NU",
      "UM"
    ]
  },
  {
    "value": "Hawaiian Standard Time",
    "abbr": "HST",
    "offset": -10,
    "isdst": false,
    "text": "(UTC-10:00) Hawaii",
    "utc": [
      "Etc/GMT+10",
      "Pacific/Honolulu",
      "Pacific/Johnston",
      "Pacific/Rarotonga",
      "Pacific/Tahiti"
    ],
    "countries": [
      "UM",
      "US"
    ]
  },
  {
    "value": "Alaskan Standard Time",
    "abbr": "AKDT",
    "offset": -8,
    "isdst": true,
    "text": "(UTC-09:00) Alaska",
    "utc": [
      "America/Anchorage",
      "America/Juneau",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat"
    ],
    "countries": [
      "US"
    ]
  },
  {
    "value": "Pacific Standard Time (Mexico)",
    "abbr": "PDT",
    "offset": -7,
    "isdst": true,
    "text": "(UTC-08:00) Baja California",
    "utc": [
      "America/Santa_Isabel"
    ],
    "countries": [
      "MX"
    ]
  },
  {
    "value": "Pacific Daylight Time",
    "abbr": "PDT",
    "offset": -7,
    "isdst": true,
    "text": "(UTC-07:00) Pacific Time (US & Canada)",
    "utc": [
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver"
    ],
    "countries": [
      "CA",
      "MX",
      "US"
    ]
  },
  {
    "value": "Pacific Standard Time",
    "abbr": "PST",
    "offset": -8,
    "isdst": false,
    "text": "(UTC-08:00) Pacific Time (US & Canada)",
    "utc": [
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "PST8PDT"
    ],
    "countries": [
      "CA",
      "MX",
      "US"
    ]
  },
  {
    "value": "US Mountain Standard Time",
    "abbr": "UMST",
    "offset": -7,
    "isdst": false,
    "text": "(UTC-07:00) Arizona",
    "utc": [
      "America/Creston",
      "America/Dawson",
      "America/Dawson_Creek",
      "America/Hermosillo",
      "America/Phoenix",
      "America/Whitehorse",
      "Etc/GMT+7"
    ],
    "countries": [
      "US"
    ]
  },
  {
    "value": "Mountain Standard Time (Mexico)",
    "abbr": "MDT",
    "offset": -6,
    "isdst": true,
    "text": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    "utc": [
      "America/Chihuahua",
      "America/Mazatlan"
    ],
    "countries": [
      "MX"
    ]
  },
  {
    "value": "Mountain Standard Time",
    "abbr": "MDT",
    "offset": -6,
    "isdst": true,
    "text": "(UTC-07:00) Mountain Time (US & Canada)",
    "utc": [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT"
    ],
    "countries": [
      "CA",
      "MX",
      "US"
    ]
  },
  {
    "value": "Central America Standard Time",
    "abbr": "CAST",
    "offset": -6,
    "isdst": false,
    "text": "(UTC-06:00) Central America",
    "utc": [
      "America/Belize",
      "America/Costa_Rica",
      "America/El_Salvador",
      "America/Guatemala",
      "America/Managua",
      "America/Tegucigalpa",
      "Etc/GMT+6",
      "Pacific/Galapagos"
    ],
    "countries": [
      "BZ",
      "CR",
      "GT",
      "HN",
      "NI",
      "SV"
    ]
  },
  {
    "value": "Central Standard Time",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "text": "(UTC-06:00) Central Time (US & Canada)",
    "utc": [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT"
    ],
    "countries": [
      "MX",
      "US"
    ]
  },
  {
    "value": "Central Standard Time (Mexico)",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "text": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    "utc": [
      "America/Bahia_Banderas",
      "America/Cancun",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey"
    ],
    "countries": [
      "MX"
    ]
  },
  {
    "value": "Canada Central Standard Time",
    "abbr": "CCST",
    "offset": -6,
    "isdst": false,
    "text": "(UTC-06:00) Saskatchewan",
    "utc": [
      "America/Regina",
      "America/Swift_Current"
    ],
    "countries": [
      "CA"
    ]
  },
  {
    "value": "SA Pacific Standard Time",
    "abbr": "SPST",
    "offset": -5,
    "isdst": false,
    "text": "(UTC-05:00) Bogota, Lima, Quito",
    "utc": [
      "America/Bogota",
      "America/Cayman",
      "America/Coral_Harbour",
      "America/Eirunepe",
      "America/Guayaquil",
      "America/Jamaica",
      "America/Lima",
      "America/Panama",
      "America/Rio_Branco",
      "Etc/GMT+5"
    ],
    "countries": [
      "CO",
      "EC",
      "JM",
      "KY",
      "PA",
      "PE"
    ]
  },
  {
    "value": "Eastern Standard Time",
    "abbr": "EST",
    "offset": -5,
    "isdst": false,
    "text": "(UTC-05:00) Eastern Time (US & Canada)",
    "utc": [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto"
    ],
    "countries": [
      "BS",
      "CA",
      "CU",
      "HT",
      "JM",
      "KY",
      "US"
    ]
  },
  {
    "value": "Eastern Daylight Time",
    "abbr": "EDT",
    "offset": -4,
    "isdst": true,
    "text": "(UTC-04:00) Eastern Daylight Time (US & Canada)",
    "utc": [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto"
    ],
    "countries": [
      "BS",
      "CA",
      "CU",
      "HT",
      "JM",
      "KY",
      "US"
    ]
  },
  {
    "value": "US Eastern Standard Time",
    "abbr": "UEDT",
    "offset": -5,
    "isdst": false,
    "text": "(UTC-05:00) Indiana (East)",
    "utc": [
      "America/Indiana/Marengo",
      "America/Indiana/Vevay",
      "America/Indianapolis"
    ],
    "countries": [
      "US"
    ]
  },
  {
    "value": "Venezuela Standard Time",
    "abbr": "VST",
    "offset": -4.5,
    "isdst": false,
    "text": "(UTC-04:30) Caracas",
    "utc": [
      "America/Caracas"
    ],
    "countries": [
      "VE"
    ]
  },
  {
    "value": "Paraguay Standard Time",
    "abbr": "PYT",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Asuncion",
    "utc": [
      "America/Asuncion"
    ],
    "countries": [
      "PY"
    ]
  },
  {
    "value": "Atlantic Standard Time",
    "abbr": "ADT",
    "offset": -3,
    "isdst": true,
    "text": "(UTC-04:00) Atlantic Time (Canada)",
    "utc": [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
      "America/Thule",
      "Atlantic/Bermuda"
    ],
    "countries": [
      "CA"
    ]
  },
  {
    "value": "Central Brazilian Standard Time",
    "abbr": "CBST",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Cuiaba",
    "utc": [
      "America/Campo_Grande",
      "America/Cuiaba"
    ],
    "countries": [
      "BR"
    ]
  },
  {
    "value": "SA Western Standard Time",
    "abbr": "SWST",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    "utc": [
      "America/Anguilla",
      "America/Antigua",
      "America/Aruba",
      "America/Barbados",
      "America/Blanc-Sablon",
      "America/Boa_Vista",
      "America/Curacao",
      "America/Dominica",
      "America/Grand_Turk",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guyana",
      "America/Kralendijk",
      "America/La_Paz",
      "America/Lower_Princes",
      "America/Manaus",
      "America/Marigot",
      "America/Martinique",
      "America/Montserrat",
      "America/Port_of_Spain",
      "America/Porto_Velho",
      "America/Puerto_Rico",
      "America/Santo_Domingo",
      "America/St_Barthelemy",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/St_Thomas",
      "America/St_Vincent",
      "America/Tortola",
      "Etc/GMT+4"
    ],
    "countries": [
      "AI",
      "AN",
      "AW",
      "BB",
      "BQ",
      "CW",
      "DM",
      "DO",
      "GD",
      "GP",
      "GY",
      "KN",
      "LC",
      "MF",
      "MQ",
      "MS",
      "PR",
      "SX",
      "TC",
      "TT",
      "VC",
      "VG",
      "VI"
    ]
  },
  {
    "value": "Pacific SA Standard Time",
    "abbr": "PSST",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Santiago",
    "utc": [
      "America/Santiago",
      "Antarctica/Palmer"
    ]
  },
  {
    "value": "Newfoundland Standard Time",
    "abbr": "NDT",
    "offset": -2.5,
    "isdst": true,
    "text": "(UTC-03:30) Newfoundland",
    "utc": [
      "America/St_Johns"
    ]
  },
  {
    "value": "E. South America Standard Time",
    "abbr": "ESAST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Brasilia",
    "utc": [
      "America/Sao_Paulo"
    ]
  },
  {
    "value": "Argentina Standard Time",
    "abbr": "AST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Buenos Aires",
    "utc": [
      "America/Argentina/La_Rioja",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Buenos_Aires",
      "America/Catamarca",
      "America/Cordoba",
      "America/Jujuy",
      "America/Mendoza"
    ],
    "countries": [
      "AR"
    ]
  },
  {
    "value": "SA Eastern Standard Time",
    "abbr": "SEST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Cayenne, Fortaleza",
    "utc": [
      "America/Araguaina",
      "America/Belem",
      "America/Cayenne",
      "America/Fortaleza",
      "America/Maceio",
      "America/Paramaribo",
      "America/Recife",
      "America/Santarem",
      "Antarctica/Rothera",
      "Atlantic/Stanley",
      "Etc/GMT+3"
    ],
    "countries": [
      "BR",
      "GF",
      "SR"
    ]
  },
  {
    "value": "Greenland Standard Time",
    "abbr": "GDT",
    "offset": -3,
    "isdst": true,
    "text": "(UTC-03:00) Greenland",
    "utc": [
      "America/Godthab"
    ],
    "countries": [
      "GL"
    ]
  },
  {
    "value": "Montevideo Standard Time",
    "abbr": "MST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Montevideo",
    "utc": [
      "America/Montevideo"
    ],
    "countries": [
      "UY"
    ]
  },
  {
    "value": "Bahia Standard Time",
    "abbr": "BST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Salvador",
    "utc": [
      "America/Bahia"
    ],
    "countries": [
      "BR"
    ]
  },
  {
    "value": "UTC-02",
    "abbr": "U",
    "offset": -2,
    "isdst": false,
    "text": "(UTC-02:00) Coordinated Universal Time-02",
    "utc": [
      "America/Noronha",
      "Atlantic/South_Georgia",
      "Etc/GMT+2"
    ],
    "countries": [
      "GS"
    ]
  },
  {
    "value": "Mid-Atlantic Standard Time",
    "abbr": "MDT",
    "offset": -1,
    "isdst": true,
    "text": "(UTC-02:00) Mid-Atlantic - Old",
    "utc": [],
    "countries": []
  },
  {
    "value": "Azores Standard Time",
    "abbr": "ADT",
    "offset": 0,
    "isdst": true,
    "text": "(UTC-01:00) Azores",
    "utc": [
      "America/Scoresbysund",
      "Atlantic/Azores"
    ],
    "countries": [
      "PT"
    ]
  },
  {
    "value": "Cape Verde Standard Time",
    "abbr": "CVST",
    "offset": -1,
    "isdst": false,
    "text": "(UTC-01:00) Cape Verde Is.",
    "utc": [
      "Atlantic/Cape_Verde",
      "Etc/GMT+1"
    ],
    "countries": [
      "CV"
    ]
  },
  {
    "value": "Morocco Standard Time",
    "abbr": "MDT",
    "offset": 1,
    "isdst": true,
    "text": "(UTC) Casablanca",
    "utc": [
      "Africa/Casablanca",
      "Africa/El_Aaiun"
    ],
    "countries": [
      "MA"
    ]
  },
  {
    "value": "UTC",
    "abbr": "UTC",
    "offset": 0,
    "isdst": false,
    "text": "(UTC) Coordinated Universal Time",
    "utc": [
      "America/Danmarkshavn",
      "Etc/GMT"
    ],
    "countries": []
  },
  {
    "value": "GMT Standard Time",
    "abbr": "GMT",
    "offset": 0,
    "isdst": false,
    "text": "(UTC) Edinburgh, London",
    "utc": [
      "Etc/UTC", // default on backend
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London"
    ],
    "countries": [
      "GB"
    ]
  },
  {
    "value": "GMT Standard Time",
    "abbr": "GDT",
    "offset": 1,
    "isdst": true,
    "text": "(UTC) Dublin, Lisbon",
    "utc": [
      "Atlantic/Canary",
      "Atlantic/Faeroe",
      "Atlantic/Madeira",
      "Europe/Dublin",
      "Europe/Lisbon"
    ],
    "countries": [
      "IE",
      "PT"
    ]
  },
  {
    "value": "Greenwich Standard Time",
    "abbr": "GST",
    "offset": 0,
    "isdst": false,
    "text": "(UTC) Monrovia, Reykjavik",
    "utc": [
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Bamako",
      "Africa/Banjul",
      "Africa/Bissau",
      "Africa/Conakry",
      "Africa/Dakar",
      "Africa/Freetown",
      "Africa/Lome",
      "Africa/Monrovia",
      "Africa/Nouakchott",
      "Africa/Ouagadougou",
      "Africa/Sao_Tome",
      "Atlantic/Reykjavik",
      "Atlantic/St_Helena"
    ],
    "countries": [
      "SH"
    ]
  },
  {
    "value": "W. Europe Standard Time",
    "abbr": "WEDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "utc": [
      "Arctic/Longyearbyen",
      "Europe/Amsterdam",
      "Europe/Andorra",
      "Europe/Berlin",
      "Europe/Busingen",
      "Europe/Gibraltar",
      "Europe/Luxembourg",
      "Europe/Malta",
      "Europe/Monaco",
      "Europe/Oslo",
      "Europe/Rome",
      "Europe/San_Marino",
      "Europe/Stockholm",
      "Europe/Vaduz",
      "Europe/Vatican",
      "Europe/Vienna",
      "Europe/Zurich"
    ],
    "countries": [
      "AT",
      "BE",
      "CH",
      "DE",
      "IT",
      "LI",
      "LU",
      "NL",
      "NO",
      "SE"
    ]
  },
  {
    "value": "Central Europe Standard Time",
    "abbr": "CEDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "utc": [
      "Europe/Belgrade",
      "Europe/Bratislava",
      "Europe/Budapest",
      "Europe/Ljubljana",
      "Europe/Podgorica",
      "Europe/Prague",
      "Europe/Tirane"
    ],
    "countries": [
      "AL",
      "BA",
      "CZ",
      "HU",
      "ME",
      "RS",
      "SK",
      "SI"
    ]
  },
  {
    "value": "Romance Standard Time",
    "abbr": "RDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    "utc": [
      "Africa/Ceuta",
      "Europe/Brussels",
      "Europe/Copenhagen",
      "Europe/Madrid",
      "Europe/Paris"
    ],
    "countries": [
      "BE",
      "DK",
      "FR",
      "ES"
    ]
  },
  {
    "value": "Central European Standard Time",
    "abbr": "CEDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    "utc": [
      "Europe/Sarajevo",
      "Europe/Skopje",
      "Europe/Warsaw",
      "Europe/Zagreb"
    ],
    "countries": [
      "BA",
      "HR",
      "MK",
      "PL"
    ]
  },
  {
    "value": "W. Central Africa Standard Time",
    "abbr": "WCAST",
    "offset": 1,
    "isdst": false,
    "text": "(UTC+01:00) West Central Africa",
    "utc": [
      "Africa/Algiers",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Douala",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Luanda",
      "Africa/Malabo",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Porto-Novo",
      "Africa/Tunis",
      "Etc/GMT-1"
    ],
    "countries": [
      "AO",
      "BJ",
      "CD",
      "CF",
      "CG",
      "CM",
      "DZ",
      "GA",
      "GQ",
      "NE",
      "NG",
      "TN"
    ]
  },
  {
    "value": "Namibia Standard Time",
    "abbr": "NST",
    "offset": 1,
    "isdst": false,
    "text": "(UTC+01:00) Windhoek",
    "utc": [
      "Africa/Windhoek"
    ],
    "countries": [
      "NA"
    ]
  },
  {
    "value": "GTB Standard Time",
    "abbr": "GDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Athens, Bucharest",
    "utc": [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau"
    ],
    "countries": [
      "CY",
      "GR",
      "RO"
    ]
  },
  {
    "value": "Middle East Standard Time",
    "abbr": "MEDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Beirut",
    "utc": [
      "Asia/Beirut"
    ],
    "countries": [
      "LB"
    ]
  },
  {
    "value": "Egypt Standard Time",
    "abbr": "EST",
    "offset": 2,
    "isdst": false,
    "text": "(UTC+02:00) Cairo",
    "utc": [
      "Africa/Cairo"
    ],
    "countries": [
      "EG"
    ]
  },
  {
    "value": "Syria Standard Time",
    "abbr": "SDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Damascus",
    "utc": [
      "Asia/Damascus"
    ],
    "countries": [
      "SY"
    ]
  },
  {
    "value": "E. Europe Standard Time",
    "abbr": "EEDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) E. Europe",
    "utc": [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Nicosia",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye"
    ],
    "countries": [
      "BG",
      "EE",
      "FI",
      "LV",
      "LT",
      "MD",
      "RO",
      "UA"
    ]
  },
  {
    "value": "South Africa Standard Time",
    "abbr": "SAST",
    "offset": 2,
    "isdst": false,
    "text": "(UTC+02:00) Harare, Pretoria",
    "utc": [
      "Africa/Blantyre",
      "Africa/Bujumbura",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Kigali",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Etc/GMT-2"
    ],
    "countries": [
      "BW",
      "LS",
      "MW",
      "MZ",
      "RW",
      "ZA",
      "SZ",
      "ZM",
      "ZW"
    ]
  },
  {
    "value": "FLE Standard Time",
    "abbr": "FDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "utc": [
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye"
    ],
    "countries": [
      "EE",
      "FI",
      "LV",
      "LT",
      "UA"
    ]
  },
  {
    "value": "Turkey Standard Time",
    "abbr": "TDT",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Istanbul",
    "utc": [
      "Europe/Istanbul"
    ],
    "countries": [
      "TR"
    ]
  },
  {
    "value": "Israel Standard Time",
    "abbr": "JDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Jerusalem",
    "utc": [
      "Asia/Jerusalem"
    ]
  },
  {
    "value": "Libya Standard Time",
    "abbr": "LST",
    "offset": 2,
    "isdst": false,
    "text": "(UTC+02:00) Tripoli",
    "utc": [
      "Africa/Tripoli"
    ]
  },
  {
    "value": "Jordan Standard Time",
    "abbr": "JST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Amman",
    "utc": [
      "Asia/Amman"
    ]
  },
  {
    "value": "Arabic Standard Time",
    "abbr": "AST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Baghdad",
    "utc": [
      "Asia/Baghdad"
    ]
  },
  {
    "value": "Kaliningrad Standard Time",
    "abbr": "KST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+02:00) Kaliningrad",
    "utc": [
      "Europe/Kaliningrad"
    ]
  },
  {
    "value": "Arab Standard Time",
    "abbr": "AST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Kuwait, Riyadh",
    "utc": [
      "Asia/Aden",
      "Asia/Bahrain",
      "Asia/Kuwait",
      "Asia/Qatar",
      "Asia/Riyadh"
    ]
  },
  {
    "value": "E. Africa Standard Time",
    "abbr": "EAST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Nairobi",
    "utc": [
      "Africa/Addis_Ababa",
      "Africa/Asmera",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Khartoum",
      "Africa/Mogadishu",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Etc/GMT-3",
      "Indian/Antananarivo",
      "Indian/Comoro",
      "Indian/Mayotte"
    ],
    "countries": [
      "DJ",
      "ER",
      "ET",
      "KE",
      "KM",
      "MG",
      "SO",
      "TZ",
      "UG",
      "YT"
    ]
  },
  {
    "value": "Moscow Standard Time",
    "abbr": "MSK",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
    "utc": [
      "Europe/Kirov",
      "Europe/Moscow",
      "Europe/Simferopol",
      "Europe/Volgograd",
      "Europe/Minsk"
    ],
    "countries": [
      "BY",
      "RU"
    ]
  },
  {
    "value": "Samara Time",
    "abbr": "SAMT",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Samara, Ulyanovsk, Saratov",
    "utc": [
      "Europe/Astrakhan",
      "Europe/Samara",
      "Europe/Ulyanovsk"
    ],
    "countries": [
      "RU"
    ]
  },
  {
    "value": "Iran Standard Time",
    "abbr": "IDT",
    "offset": 4.5,
    "isdst": true,
    "text": "(UTC+03:30) Tehran",
    "utc": [
      "Asia/Tehran"
    ],
    "countries": [
      "IR"
    ]
  },
  {
    "value": "Arabian Standard Time",
    "abbr": "AST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Abu Dhabi, Muscat",
    "utc": [
      "Asia/Dubai",
      "Asia/Muscat",
      "Etc/GMT-4"
    ],
    "countries": [
      "AE",
      "OM"
    ]
  },
  {
    "value": "Azerbaijan Standard Time",
    "abbr": "ADT",
    "offset": 5,
    "isdst": true,
    "text": "(UTC+04:00) Baku",
    "utc": [
      "Asia/Baku"
    ],
    "countries": [
      "AZ"
    ]
  },
  {
    "value": "Mauritius Standard Time",
    "abbr": "MST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Port Louis",
    "utc": [
      "Indian/Mahe",
      "Indian/Mauritius",
      "Indian/Reunion"
    ],
    "countries": [
      "MU"
    ]
  },
  {
    "value": "Georgian Standard Time",
    "abbr": "GET",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Tbilisi",
    "utc": [
      "Asia/Tbilisi"
    ],
    "countries": [
      "GE"
    ]
  },
  {
    "value": "Caucasus Standard Time",
    "abbr": "CST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Yerevan",
    "utc": [
      "Asia/Yerevan"
    ],
    "countries": [
      "AM"
    ]
  },
  {
    "value": "Afghanistan Standard Time",
    "abbr": "AST",
    "offset": 4.5,
    "isdst": false,
    "text": "(UTC+04:30) Kabul",
    "utc": [
      "Asia/Kabul"
    ],
    "countries": [
      "AF"
    ]
  },
  {
    "value": "West Asia Standard Time",
    "abbr": "WAST",
    "offset": 5,
    "isdst": false,
    "text": "(UTC+05:00) Ashgabat, Tashkent",
    "utc": [
      "Antarctica/Mawson",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Ashgabat",
      "Asia/Dushanbe",
      "Asia/Oral",
      "Asia/Samarkand",
      "Asia/Tashkent",
      "Etc/GMT-5",
      "Indian/Kerguelen",
      "Indian/Maldives"
    ]
  },
  {
    "value": "Yekaterinburg Time",
    "abbr": "YEKT",
    "offset": 5,
    "isdst": false,
    "text": "(UTC+05:00) Yekaterinburg",
    "utc": [
      "Asia/Yekaterinburg"
    ]
  },
  {
    "value": "Pakistan Standard Time",
    "abbr": "PKT",
    "offset": 5,
    "isdst": false,
    "text": "(UTC+05:00) Islamabad, Karachi",
    "utc": [
      "Asia/Karachi"
    ]
  },
  {
    "value": "India Standard Time",
    "abbr": "IST",
    "offset": 5.5,
    "isdst": false,
    "text": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "utc": [
      "Asia/Kolkata",
      "Asia/Calcutta"
    ]
  },
  {
    "value": "Sri Lanka Standard Time",
    "abbr": "SLST",
    "offset": 5.5,
    "isdst": false,
    "text": "(UTC+05:30) Sri Jayawardenepura",
    "utc": [
      "Asia/Colombo"
    ]
  },
  {
    "value": "Nepal Standard Time",
    "abbr": "NST",
    "offset": 5.75,
    "isdst": false,
    "text": "(UTC+05:45) Kathmandu",
    "utc": [
      "Asia/Kathmandu"
    ]
  },
  {
    "value": "Central Asia Standard Time",
    "abbr": "CAST",
    "offset": 6,
    "isdst": false,
    "text": "(UTC+06:00) Nur-Sultan (Astana)",
    "utc": [
      "Antarctica/Vostok",
      "Asia/Almaty",
      "Asia/Bishkek",
      "Asia/Qyzylorda",
      "Asia/Urumqi",
      "Etc/GMT-6",
      "Indian/Chagos"
    ]
  },
  {
    "value": "Bangladesh Standard Time",
    "abbr": "BST",
    "offset": 6,
    "isdst": false,
    "text": "(UTC+06:00) Dhaka",
    "utc": [
      "Asia/Dhaka",
      "Asia/Thimphu"
    ]
  },
  {
    "value": "Myanmar Standard Time",
    "abbr": "MST",
    "offset": 6.5,
    "isdst": false,
    "text": "(UTC+06:30) Yangon (Rangoon)",
    "utc": [
      "Asia/Rangoon",
      "Indian/Cocos"
    ]
  },
  {
    "value": "SE Asia Standard Time",
    "abbr": "SAST",
    "offset": 7,
    "isdst": false,
    "text": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    "utc": [
      "Antarctica/Davis",
      "Asia/Bangkok",
      "Asia/Hovd",
      "Asia/Jakarta",
      "Asia/Phnom_Penh",
      "Asia/Pontianak",
      "Asia/Saigon",
      "Asia/Vientiane",
      "Etc/GMT-7",
      "Indian/Christmas"
    ],
    "countries": [
      "ID",
      "KH",
      "LA",
      "MY",
      "TH",
      "VN"
    ]
  },
  {
    "value": "N. Central Asia Standard Time",
    "abbr": "NCAST",
    "offset": 7,
    "isdst": false,
    "text": "(UTC+07:00) Novosibirsk",
    "utc": [
      "Asia/Novokuznetsk",
      "Asia/Novosibirsk",
      "Asia/Omsk"
    ],
    "countries": [
      "RU"
    ]
  },
  {
    "value": "China Standard Time",
    "abbr": "CST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    "utc": [
      "Asia/Hong_Kong",
      "Asia/Macau",
      "Asia/Shanghai"
    ],
    "countries": [
      "CN"
    ]
  },
  {
    "value": "North Asia Standard Time",
    "abbr": "NAST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Krasnoyarsk",
    "utc": [
      "Asia/Krasnoyarsk"
    ],
    "countries": [
      "RU"
    ]
  },
  {
    "value": "Singapore Standard Time",
    "abbr": "MPST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Kuala Lumpur, Singapore",
    "utc": [
      "Asia/Brunei",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Makassar",
      "Asia/Manila",
      "Asia/Singapore",
      "Etc/GMT-8"
    ],
    "countries": [
      "MY",
      "SG"
    ]
  },
  {
    "value": "W. Australia Standard Time",
    "abbr": "WAST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Perth",
    "utc": [
      "Antarctica/Casey",
      "Australia/Perth"
    ],
    "countries": [
      "AU"
    ]
  },
  {
    "value": "Taipei Standard Time",
    "abbr": "TST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Taipei",
    "utc": [
      "Asia/Taipei"
    ],
    "countries": [
      "TW"
    ]
  },
  {
    "value": "Ulaanbaatar Standard Time",
    "abbr": "UST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Ulaanbaatar",
    "utc": [
      "Asia/Choibalsan",
      "Asia/Ulaanbaatar"
    ],
    "countries": [
      "MN"
    ]
  },
  {
    "value": "North Asia East Standard Time",
    "abbr": "NAEST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Irkutsk",
    "utc": [
      "Asia/Irkutsk"
    ],
    "countries": [
      "RU"
    ]
  },
  {
    "value": "Japan Standard Time",
    "abbr": "JST",
    "offset": 9,
    "isdst": false,
    "text": "(UTC+09:00) Osaka, Sapporo, Tokyo",
    "utc": [
      "Asia/Dili",
      "Asia/Jayapura",
      "Asia/Tokyo",
      "Etc/GMT-9",
      "Pacific/Palau"
    ],
    "countries": [
      "JP"
    ]
  },
  {
    "value": "Korea Standard Time",
    "abbr": "KST",
    "offset": 9,
    "isdst": false,
    "text": "(UTC+09:00) Seoul",
    "utc": [
      "Asia/Pyongyang",
      "Asia/Seoul"
    ],
    "countries": [
      "KR"
    ]
  },
  {
    "value": "Cen. Australia Standard Time",
    "abbr": "CAST",
    "offset": 9.5,
    "isdst": false,
    "text": "(UTC+09:30) Adelaide",
    "utc": [
      "Australia/Adelaide",
      "Australia/Broken_Hill"
    ],
    "countries": [
      "AU"
    ]
  },
  {
    "value": "AUS Central Standard Time",
    "abbr": "ACST",
    "offset": 9.5,
    "isdst": false,
    "text": "(UTC+09:30) Darwin",
    "utc": [
      "Australia/Darwin"
    ],
    "countries": [
      "AU"
    ]
  },
  {
    "value": "E. Australia Standard Time",
    "abbr": "EAST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Brisbane",
    "utc": [
      "Australia/Brisbane",
      "Australia/Lindeman"
    ],
    "countries": [
      "AU"
    ]
  },
  {
    "value": "AUS Eastern Standard Time",
    "abbr": "AEST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Canberra, Melbourne, Sydney",
    "utc": [
      "Australia/Melbourne",
      "Australia/Sydney"
    ],
    "countries": [
      "AU"
    ]
  },
  {
    "value": "West Pacific Standard Time",
    "abbr": "WPST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Guam, Port Moresby",
    "utc": [
      "Antarctica/DumontDUrville",
      "Etc/GMT-10",
      "Pacific/Guam",
      "Pacific/Port_Moresby",
      "Pacific/Saipan",
      "Pacific/Truk"
    ],
    "countries": [
      "PG"
    ]
  },
  {
    "value": "Tasmania Standard Time",
    "abbr": "TST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Hobart",
    "utc": [
      "Australia/Currie",
      "Australia/Hobart"
    ],
    "countries": [
      "AU"
    ]
  },
  {
    "value": "Yakutsk Standard Time",
    "abbr": "YST",
    "offset": 9,
    "isdst": false,
    "text": "(UTC+09:00) Yakutsk",
    "utc": [
      "Asia/Chita",
      "Asia/Khandyga",
      "Asia/Yakutsk"
    ],
    "countries": [
      "RU"
    ]
  },
  {
    "value": "Central Pacific Standard Time",
    "abbr": "CPST",
    "offset": 11,
    "isdst": false,
    "text": "(UTC+11:00) Solomon Is., New Caledonia",
    "utc": [
      "Antarctica/Macquarie",
      "Etc/GMT-11",
      "Pacific/Efate",
      "Pacific/Guadalcanal",
      "Pacific/Kosrae",
      "Pacific/Noumea",
      "Pacific/Ponape"
    ],
    "countries": [
      "NC",
      "SB"
    ]
  },
  {
    "value": "Vladivostok Standard Time",
    "abbr": "VST",
    "offset": 11,
    "isdst": false,
    "text": "(UTC+11:00) Vladivostok",
    "utc": [
      "Asia/Sakhalin",
      "Asia/Ust-Nera",
      "Asia/Vladivostok"
    ],
    "countries": [
      "RU"
    ]
  },
  {
    "value": "New Zealand Standard Time",
    "abbr": "NZST",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Auckland, Wellington",
    "utc": [
      "Antarctica/McMurdo",
      "Pacific/Auckland"
    ],
    "countries": [
      "NZ"
    ]
  },
  {
    "value": "UTC+12",
    "abbr": "U",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Coordinated Universal Time+12",
    "utc": [
      "Etc/GMT-12",
      "Pacific/Funafuti",
      "Pacific/Kwajalein",
      "Pacific/Majuro",
      "Pacific/Nauru",
      "Pacific/Tarawa",
      "Pacific/Wake",
      "Pacific/Wallis"
    ]
  },
  {
    "value": "Fiji Standard Time",
    "abbr": "FST",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Fiji",
    "utc": [
      "Pacific/Fiji"
    ],
    "countries": [
      "FJ"
    ]
  },
  {
    "value": "Magadan Standard Time",
    "abbr": "MST",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Magadan",
    "utc": [
      "Asia/Anadyr",
      "Asia/Kamchatka",
      "Asia/Magadan",
      "Asia/Srednekolymsk"
    ],
    "countries": [
      "RU"
    ]
  },
  {
    "value": "Kamchatka Standard Time",
    "abbr": "KDT",
    "offset": 13,
    "isdst": true,
    "text": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    "utc": [
      "Asia/Kamchatka"
    ],
    "countries": [
      "RU"
    ]
  },
  {
    "value": "Tonga Standard Time",
    "abbr": "TST",
    "offset": 13,
    "isdst": false,
    "text": "(UTC+13:00) Nuku'alofa",
    "utc": [
      "Etc/GMT-13",
      "Pacific/Enderbury",
      "Pacific/Fakaofo",
      "Pacific/Tongatapu"
    ],
    "countries": [
      "TO"
    ]
  },
  {
    "value": "Samoa Standard Time",
    "abbr": "SST",
    "offset": 13,
    "isdst": false,
    "text": "(UTC+13:00) Samoa",
    "utc": [
      "Pacific/Apia"
    ],
    "countries": [
      "WS"
    ]
  }
  ];

export let  phoneCodes: any[] = [
  {"country":"Afghanistan","code":"+93","iso":"AF"},
  {"country":"Albania","code":"+355","iso":"AL"},
  {"country":"Algeria","code":"+213","iso":"DZ"},
  {"country":"American Samoa","code":"+1-684","iso":"AS"},
  {"country":"Andorra","code":"+376","iso":"AD"},
  {"country":"Angola","code":"+244","iso":"AO"},
  {"country":"Anguilla","code":"+1-264","iso":"AI"},
  {"country":"Antarctica","code":"+672","iso":"AQ"},
  {"country":"Antigua and Barbuda","code":"+1-268","iso":"AG"},
  {"country":"Argentina","code":"+54","iso":"AR"},
  {"country":"Armenia","code":"+374","iso":"AM"},
  {"country":"Aruba","code":"+297","iso":"AW"},
  {"country":"Australia","code":"+61","iso":"AU"},
  {"country":"Austria","code":"+43","iso":"AT"},
  {"country":"Azerbaijan","code":"+994","iso":"AZ"},
  {"country":"Bahamas","code":"+1-242","iso":"BS"},
  {"country":"Bahrain","code":"+973","iso":"BH"},
  {"country":"Bangladesh","code":"+880","iso":"BD"},
  {"country":"Barbados","code":"+1-246","iso":"BB"},
  {"country":"Belarus","code":"+375","iso":"BY"},
  {"country":"Belgium","code":"+32","iso":"BE"},
  {"country":"Belize","code":"+501","iso":"BZ"},
  {"country":"Benin","code":"+229","iso":"BJ"},
  {"country":"Bermuda","code":"+1-441","iso":"BM"},
  {"country":"Bhutan","code":"+975","iso":"BT"},
  {"country":"Bolivia","code":"+591","iso":"BO"},
  {"country":"Bosnia and Herzegovina","code":"+387","iso":"BA"},
  {"country":"Botswana","code":"+267","iso":"BW"},
  {"country":"Brazil","code":"+55","iso":"BR"},
  {"country":"British Indian Ocean Territory","code":"+246","iso":"IO"},
  {"country":"British Virgin Islands","code":"+1-284","iso":"VG"},
  {"country":"Brunei","code":"+673","iso":"BN"},
  {"country":"Bulgaria","code":"+359","iso":"BG"},
  {"country":"Burkina Faso","code":"+226","iso":"BF"},
  {"country":"Burundi","code":"+257","iso":"BI"},
  {"country":"Cambodia","code":"+855","iso":"KH"},
  {"country":"Cameroon","code":"+237","iso":"CM"},
  {"country":"Canada","code":"+1","iso":"CA"},
  {"country":"Cape Verde","code":"+238","iso":"CV"},
  {"country":"Cayman Islands","code":"+1-345","iso":"KY"},
  {"country":"Central African Republic","code":"+236","iso":"CF"},
  {"country":"Chad","code":"+235","iso":"TD"},
  {"country":"Chile","code":"+56","iso":"CL"},
  {"country":"China","code":"+86","iso":"CN"},
  {"country":"Christmas Island","code":"+61","iso":"CX"},
  {"country":"Cocos Islands","code":"+61","iso":"CC"},
  {"country":"Colombia","code":"+57","iso":"CO"},
  {"country":"Comoros","code":"+269","iso":"KM"},
  {"country":"Cook Islands","code":"+682","iso":"CK"},
  {"country":"Costa Rica","code":"+506","iso":"CR"},
  {"country":"Croatia","code":"+385","iso":"HR"},
  {"country":"Cuba","code":"+53","iso":"CU"},
  {"country":"Curacao","code":"+599","iso":"CW"},
  {"country":"Cyprus","code":"+357","iso":"CY"},
  {"country":"Czech Republic","code":"+420","iso":"CZ"},
  {"country":"Democratic Republic of the Congo","code":"+243","iso":"CD"},
  {"country":"Denmark","code":"+45","iso":"DK"},
  {"country":"Djibouti","code":"+253","iso":"DJ"},
  {"country":"Dominica","code":"+1-767","iso":"DM"},
  {"country":"Dominican Republic","code":"+1","iso":"DO"},
  {"country":"East Timor","code":"+670","iso":"TL"},
  {"country":"Ecuador","code":"+593","iso":"EC"},
  {"country":"Egypt","code":"+20","iso":"EG"},
  {"country":"El Salvador","code":"+503","iso":"SV"},
  {"country":"Equatorial Guinea","code":"+240","iso":"GQ"},
  {"country":"Eritrea","code":"+291","iso":"ER"},
  {"country":"Estonia","code":"+372","iso":"EE"},
  {"country":"Ethiopia","code":"+251","iso":"ET"},
  {"country":"Falkland Islands","code":"+500","iso":"FK"},
  {"country":"Faroe Islands","code":"+298","iso":"FO"},
  {"country":"Fiji","code":"+679","iso":"FJ"},
  {"country":"Finland","code":"+358","iso":"FI"},
  {"country":"France","code":"+33","iso":"FR"},
  {"country":"French Polynesia","code":"+689","iso":"PF"},
  {"country":"Gabon","code":"+241","iso":"GA"},
  {"country":"Gambia","code":"+220","iso":"GM"},
  {"country":"Georgia","code":"+995","iso":"GE"},
  {"country":"Germany","code":"+49","iso":"DE"},
  {"country":"Ghana","code":"+233","iso":"GH"},
  {"country":"Gibraltar","code":"+350","iso":"GI"},
  {"country":"Greece","code":"+30","iso":"GR"},
  {"country":"Greenland","code":"+299","iso":"GL"},
  {"country":"Grenada","code":"+1-473","iso":"GD"},
  {"country":"Guam","code":"+1-671","iso":"GU"},
  {"country":"Guatemala","code":"+502","iso":"GT"},
  {"country":"Guernsey","code":"+44-1481","iso":"GG"},
  {"country":"Guinea","code":"+224","iso":"GN"},
  {"country":"Guinea-Bissau","code":"+245","iso":"GW"},
  {"country":"Guyana","code":"+592","iso":"GY"},
  {"country":"Haiti","code":"+509","iso":"HT"},
  {"country":"Honduras","code":"+504","iso":"HN"},
  {"country":"Hong Kong","code":"+852","iso":"HK"},
  {"country":"Hungary","code":"+36","iso":"HU"},
  {"country":"Iceland","code":"+354","iso":"IS"},
  {"country":"India","code":"+91","iso":"IN"},
  {"country":"Indonesia","code":"+62","iso":"ID"},
  {"country":"Iran","code":"+98","iso":"IR"},
  {"country":"Iraq","code":"+964","iso":"IQ"},
  {"country":"Ireland","code":"+353","iso":"IE"},
  {"country":"Isle of Man","code":"+44-1624","iso":"IM"},
  {"country":"Israel","code":"+972","iso":"IL"},
  {"country":"Italy","code":"+39","iso":"IT"},
  {"country":"Ivory Coast","code":"+225","iso":"CI"},
  {"country":"Jamaica","code":"+1-876","iso":"JM"},
  {"country":"Japan","code":"+81","iso":"JP"},
  {"country":"Jersey","code":"+44-1534","iso":"JE"},
  {"country":"Jordan","code":"+962","iso":"JO"},
  {"country":"Kazakhstan","code":"+7","iso":"KZ"},
  {"country":"Kenya","code":"+254","iso":"KE"},
  {"country":"Kiribati","code":"+686","iso":"KI"},
  {"country":"Kosovo","code":"+383","iso":"XK"},
  {"country":"Kuwait","code":"+965","iso":"KW"},
  {"country":"Kyrgyzstan","code":"+996","iso":"KG"},
  {"country":"Laos","code":"+856","iso":"LA"},
  {"country":"Latvia","code":"+371","iso":"LV"},
  {"country":"Lebanon","code":"+961","iso":"LB"},
  {"country":"Lesotho","code":"+266","iso":"LS"},
  {"country":"Liberia","code":"+231","iso":"LR"},
  {"country":"Libya","code":"+218","iso":"LY"},
  {"country":"Liechtenstein","code":"+423","iso":"LI"},
  {"country":"Lithuania","code":"+370","iso":"LT"},
  {"country":"Luxembourg","code":"+352","iso":"LU"},
  {"country":"Macao","code":"+853","iso":"MO"},
  {"country":"Macedonia","code":"+389","iso":"MK"},
  {"country":"Madagascar","code":"+261","iso":"MG"},
  {"country":"Malawi","code":"+265","iso":"MW"},
  {"country":"Malaysia","code":"+60","iso":"MY"},
  {"country":"Maldives","code":"+960","iso":"MV"},
  {"country":"Mali","code":"+223","iso":"ML"},
  {"country":"Malta","code":"+356","iso":"MT"},
  {"country":"Marshall Islands","code":"+692","iso":"MH"},
  {"country":"Mauritania","code":"+222","iso":"MR"},
  {"country":"Mauritius","code":"+230","iso":"MU"},
  {"country":"Mayotte","code":"+262","iso":"YT"},
  {"country":"Mexico","code":"+52","iso":"MX"},
  {"country":"Micronesia","code":"+691","iso":"FM"},
  {"country":"Moldova","code":"+373","iso":"MD"},
  {"country":"Monaco","code":"+377","iso":"MC"},
  {"country":"Mongolia","code":"+976","iso":"MN"},
  {"country":"Montenegro","code":"+382","iso":"ME"},
  {"country":"Montserrat","code":"+1-664","iso":"MS"},
  {"country":"Morocco","code":"+212","iso":"MA"},
  {"country":"Mozambique","code":"+258","iso":"MZ"},
  {"country":"Myanmar","code":"+95","iso":"MM"},
  {"country":"Namibia","code":"+264","iso":"NA"},
  {"country":"Nauru","code":"+674","iso":"NR"},
  {"country":"Nepal","code":"+977","iso":"NP"},
  {"country":"Netherlands","code":"+31","iso":"NL"},
  {"country":"Netherlands Antilles","code":"+599","iso":"AN"},
  {"country":"New Caledonia","code":"+687","iso":"NC"},
  {"country":"New Zealand","code":"+64","iso":"NZ"},
  {"country":"Nicaragua","code":"+505","iso":"NI"},
  {"country":"Niger","code":"+227","iso":"NE"},
  {"country":"Nigeria","code":"+234","iso":"NG"},
  {"country":"Niue","code":"+683","iso":"NU"},
  {"country":"North Korea","code":"+850","iso":"KP"},
  {"country":"Northern Mariana Islands","code":"+1-670","iso":"MP"},
  {"country":"Norway","code":"+47","iso":"NO"},
  {"country":"Oman","code":"+968","iso":"OM"},
  {"country":"Pakistan","code":"+92","iso":"PK"},
  {"country":"Palau","code":"+680","iso":"PW"},
  {"country":"Palestine","code":"+970","iso":"PS"},
  {"country":"Panama","code":"+507","iso":"PA"},
  {"country":"Papua New Guinea","code":"+675","iso":"PG"},
  {"country":"Paraguay","code":"+595","iso":"PY"},
  {"country":"Peru","code":"+51","iso":"PE"},
  {"country":"Philippines","code":"+63","iso":"PH"},
  {"country":"Pitcairn","code":"+64","iso":"PN"},
  {"country":"Poland","code":"+48","iso":"PL"},
  {"country":"Portugal","code":"+351","iso":"PT"},
  {"country":"Puerto Rico","code":"+1-787, 1-939","iso":"PR"},
  {"country":"Qatar","code":"+974","iso":"QA"},
  {"country":"Republic of the Congo","code":"+242","iso":"CG"},
  {"country":"Reunion","code":"+262","iso":"RE"},
  {"country":"Romania","code":"+40","iso":"RO"},
  {"country":"Russia","code":"+7","iso":"RU"},
  {"country":"Rwanda","code":"+250","iso":"RW"},
  {"country":"Saint Barthelemy","code":"+590","iso":"BL"},
  {"country":"Saint Helena","code":"+290","iso":"SH"},
  {"country":"Saint Kitts and Nevis","code":"+1-869","iso":"KN"},
  {"country":"Saint Lucia","code":"+1-758","iso":"LC"},
  {"country":"Saint Martin","code":"+590","iso":"MF"},
  {"country":"Saint Pierre and Miquelon","code":"+508","iso":"PM"},
  {"country":"Saint Vincent and the Grenadines","code":"+1-784","iso":"VC"},
  {"country":"Samoa","code":"+685","iso":"WS"},
  {"country":"San Marino","code":"+378","iso":"SM"},
  {"country":"Sao Tome and Principe","code":"+239","iso":"ST"},
  {"country":"Saudi Arabia","code":"+966","iso":"SA"},
  {"country":"Senegal","code":"+221","iso":"SN"},
  {"country":"Serbia","code":"+381","iso":"RS"},
  {"country":"Seychelles","code":"+248","iso":"SC"},
  {"country":"Sierra Leone","code":"+232","iso":"SL"},
  {"country":"Singapore","code":"+65","iso":"SG"},
  {"country":"Sint Maarten","code":"+1-721","iso":"SX"},
  {"country":"Slovakia","code":"+421","iso":"SK"},
  {"country":"Slovenia","code":"+386","iso":"SI"},
  {"country":"Solomon Islands","code":"+677","iso":"SB"},
  {"country":"Somalia","code":"+252","iso":"SO"},
  {"country":"South Africa","code":"+27","iso":"ZA"},
  {"country":"South Korea","code":"+82","iso":"KR"},
  {"country":"South Sudan","code":"+211","iso":"SS"},
  {"country":"Spain","code":"+34","iso":"ES"},
  {"country":"Sri Lanka","code":"+94","iso":"LK"},
  {"country":"Sudan","code":"+249","iso":"SD"},
  {"country":"Suriname","code":"+597","iso":"SR"},
  {"country":"Svalbard and Jan Mayen","code":"+47","iso":"SJ"},
  {"country":"Swaziland","code":"+268","iso":"SZ"},
  {"country":"Sweden","code":"+46","iso":"SE"},
  {"country":"Switzerland","code":"+41","iso":"CH"},
  {"country":"Syria","code":"+963","iso":"SY"},
  {"country":"Taiwan","code":"+886","iso":"TW"},
  {"country":"Tajikistan","code":"+992","iso":"TJ"},
  {"country":"Tanzania","code":"+255","iso":"TZ"},
  {"country":"Thailand","code":"+66","iso":"TH"},
  {"country":"Togo","code":"+228","iso":"TG"},
  {"country":"Tokelau","code":"+690","iso":"TK"},
  {"country":"Tonga","code":"+676","iso":"TO"},
  {"country":"Trinidad and Tobago","code":"+1-868","iso":"TT"},
  {"country":"Tunisia","code":"+216","iso":"TN"},
  {"country":"Turkey","code":"+90","iso":"TR"},
  {"country":"Turkmenistan","code":"+993","iso":"TM"},
  {"country":"Turks and Caicos Islands","code":"+1-649","iso":"TC"},
  {"country":"Tuvalu","code":"+688","iso":"TV"},
  {"country":"U.S. Virgin Islands","code":"+1-340","iso":"VI"},
  {"country":"Uganda","code":"+256","iso":"UG"},
  {"country":"Ukraine","code":"+380","iso":"UA"},
  {"country":"United Arab Emirates","code":"+971","iso":"AE"},
  {"country":"United Kingdom","code":"+44","iso":"GB"},
  {"country":"United States","code":"+1","iso":"US"},
  {"country":"Uruguay","code":"+598","iso":"UY"},
  {"country":"Uzbekistan","code":"+998","iso":"UZ"},
  {"country":"Vanuatu","code":"+678","iso":"VU"},
  {"country":"Vatican","code":"+379","iso":"VA"},
  {"country":"Venezuela","code":"+58","iso":"VE"},
  {"country":"Vietnam","code":"+84","iso":"VN"},
  {"country":"Wallis and Futuna","code":"+681","iso":"WF"},
  {"country":"Western Sahara","code":"+212","iso":"EH"},
  {"country":"Yemen","code":"+967","iso":"YE"},
  {"country":"Zambia","code":"+260","iso":"ZM"},
  {"country":"Zimbabwe","code":"+263","iso":"ZW"}];

export let dummyLessons: any[] = [
  {
    "id": 1234,
    "classroomId": 5678,
    "status": "Completed",
    "startingDate": "2023-03-15T09:00:00Z",
    "duration": 60,
    "isRecccuring": false,
    "answered": "no",
    "requestedByName": "John Doe",
    "bbbLink": "https://example.com/bbb-room-1234",
    "title": "Introduction to Spanish",
    "level": "A1",
    "classroom": {
      "id": "5678",
      "title": "Spanish 101",
      "status": "ON_GOING",
      "totalHours": 20,
      "hoursLeft": 16,
      "activeLevel": "A1",
      "users": [
        {
          "id": 9876,
          "fname": "Jane",
          "lname": "Doe",
          "email": "jane.doe@example.com",
          "phone": "+123456789",
          "timezone": "Europe/Paris"
        },
        {
          "id": 8765,
          "fname": "Alice",
          "lname": "Smith",
          "email": "alice.smith@example.com",
          "phone": "+987654321",
          "timezone": "America/Los_Angeles"
        }
      ],
      "classroomStudents": [
        {
          "id": 9876,
          "fname": "Jane",
          "lname": "Doe",
          "email": "jane.doe@example.com",
          "phone": "+123456789",
          "timezone": "Europe/Paris"
        }
      ],
      "type": "Paid",
      "language": "Spanish",
      "teacher": {
        "id": 3456,
        "fname": "Juan",
        "lname": "Garcia",
        "email": "juan.garcia@example.com",
        "phone": "+345678901",
        "timezone": "Europe/Madrid"
      },
      "activePackage": {
        "id": "package-1234",
        "lessons_level": [
          {
            "level": "A1",
            "hoursCompleted": 4,
            "lessonsStarted": "2023-03-01T09:00:00Z",
            "lessonsEnded": "present"
          }
        ],
        "totalHours": 20,
        "currentPackageLevel": "A1",
        "hoursLeft": 16,
        "type": "REGULAR",
        "expiresOn": "2023-09-15T23:59:59Z",
        "stratedAt": "2023-02-15T09:00:00Z",
        "purchasedAt": "2023-02-15T12:00:00Z",
        "state": "ACTIVE",
        "extensions": []
      }
    }
  },
  {
    "id": 513,
    "classroomId": 729,
    "status": "CANCELED",
    "startingDate": "2022-09-14T16:00:00.000Z",
    "duration": 60,
    "isRecccuring": false,
    "answered": "false",
    "requestedByName": "Alice",
    "title": "Intermediate French",
    "level": "B1",
    "classroom": {
      "id": "729",
      "title": "French 202",
      "status": "ON_GOING",
      "totalHours": 20,
      "hoursLeft": 10,
      "activeLevel": "B1",
      "users": [
        {
          "id": 123,
          "firstName": "Bob",
          "lastName": "Smith",
          "email": "bob@example.com",
          "phone": "123-456-7890",
          "role": "student",
          "status": "ACTIVE"
        },
        {
          "id": 456,
          "firstName": "Charlie",
          "lastName": "Brown",
          "email": "charlie@example.com",
          "phone": "555-555-5555",
          "role": "student",
          "status": "ACTIVE"
        },
        {
          "id": 789,
          "firstName": "David",
          "lastName": "Jones",
          "email": "david@example.com",
          "phone": "111-111-1111",
          "role": "teacher",
          "status": "ACTIVE"
        }
      ],
      "classroomStudents": [
        {
          "id": 123,
          "firstName": "Bob",
          "lastName": "Smith",
          "email": "bob@example.com",
          "phone": "123-456-7890",
          "role": "student",
          "status": "ACTIVE"
        },
        {
          "id": 456,
          "firstName": "Charlie",
          "lastName": "Brown",
          "email": "charlie@example.com",
          "phone": "555-555-5555",
          "role": "student",
          "status": "ACTIVE"
        }
      ],
      "type": "PAID",
      "teacher": {
        "id": 789,
        "firstName": "David",
        "lastName": "Jones",
        "email": "david@example.com",
        "phone": "111-111-1111",
        "role": "teacher",
        "status": "ACTIVE"
      },
    }},
  {
    "id": 2,
    "classroomId": 456,
    "status": "REARRANGE",
    "startingDate": new Date('2023-03-01T18:00:00.000Z'),
    "duration": 60,
    "isRecccuring": false,
    "answered": "accepted",
    "requestedByName": "Jane Smith",
    "title": "Advanced Calculus",
    "level": "Expert",
    "classroom": undefined,
    "bbbLink": "https://example.com/bbb/2"
  },
  {
    "id": 3,
    "classroomId": 789,
    "status": "REQUESTED",
    "startingDate": new Date('2023-03-01T18:00:00.000Z'),
    "duration": 45,
    "isRecccuring": false,
    "answered": "rejected",
    "requestedByName": "Bob Johnson",
    "title": 'sasa',
    "level": 'A1',
    "classroom": undefined,
    "bbbLink": "https://example.com/bbb/3"
  },
  {
    "id": 4,
    "classroomId": 123,
    "status": "COMPLETED",
    "startingDate": new Date('2023-03-01T18:00:00.000Z'),
    "duration": 30,
    "isRecccuring": true,
    "answered": "canceled",
    "requestedByName": "Alice Lee",
    "title": "English Conversation",
    "level": 'A2',
    "classroom": undefined,
    "bbbLink": "https://example.com/bbb/4"
  },
  {
    "id": 5,
    "classroomId": 456,
    "status": "NO_SHOW",
    "startingDate": new Date('2023-03-01T18:00:00.000Z'),
    "duration": 60,
    "isRecccuring": false,
    "answered": "attended",
    "requestedByName": "David Kim",
    "title": "Business Writing",
    "level": "Advanced",
    "classroom": undefined,
    "bbbLink": "https://example.com/bbb/5"
  }
];

export let dummyClassrooms: any[] =
  [
    {
      "id": "987654",
      "title": "Spanish Course for Beginners",
      "status": "ON_GOING",
      "totalHours": 20,
      "hoursLeft": 12,
      "activeLevel": "A1",
      "users": [
        {
          "id": "123456",
          "name": "John Doe",
          "email": "john.doe@example.com"
        },
        {
          "id": "789012",
          "name": "Jane Doe",
          "email": "jane.doe@example.com"
        }
      ],
      "classroomStudents": [
        {
          "id": "123456",
          "name": "John Doe",
          "email": "john.doe@example.com"
        },
        {
          "id": "789012",
          "name": "Jane Doe",
          "email": "jane.doe@example.com"
        }
      ],
      "type": "PAID",
      "language": "Spanish",
      "teacher": {
        "id": "345678",
        "name": "Maria Garcia",
        "email": "maria.garcia@example.com"
      },
      "activePackage": {
        "id": "234567",
        "title": "Spanish Beginner Package",
        "price": 99.99,
        "hours": 20
      },
      "lessons": [
        {
          "id": "345678",
          "title": "Greetings and Introductions",
          "description": "Learn basic greetings and how to introduce yourself",
          "duration": 2
        },
        {
          "id": "456789",
          "title": "Numbers and Time",
          "description": "Learn to tell the time and count to 100",
          "duration": 2
        }
      ],
      "packages": [
        {
          "id": "234567",
          "title": "Spanish Beginner Package",
          "price": 99.99,
          "hours": 20
        }
      ]
    },
    {
      "id": "classroom001",
      "title": "English Speaking Practice for Beginners",
      "status": "ON_GOING",
      "totalHours": 24,
      "hoursLeft": 6,
      "activeLevel": "A1",
      "users": [
          {
              "id": "user001",
              "name": "John Doe",
              "email": "john.doe@example.com",
              "role": "STUDENT"
          },
          {
              "id": "user002",
              "name": "Jane Doe",
              "email": "jane.doe@example.com",
              "role": "STUDENT"
          }
      ],
      "classroomStudents": [
          {
              "id": "user001",
              "name": "John Doe",
              "email": "john.doe@example.com",
              "role": "STUDENT"
          },
          {
              "id": "user002",
              "name": "Jane Doe",
              "email": "jane.doe@example.com",
              "role": "STUDENT"
          }
      ],
      "type": "PAID",
      "language": "English",
      "teacher": {
          "id": "user003",
          "name": "Mary Smith",
          "email": "mary.smith@example.com",
          "role": "TEACHER"
      },
      "activePackage": {
          "id": "package001",
          "name": "Basic Package",
          "hours": 24,
          "price": 200
      },
      "lessons": [
          {
              "id": "lesson001",
              "title": "Introduction to English",
              "description": "Basic English phrases and greetings",
              "duration": 2
          },
          {
              "id": "lesson002",
              "title": "Talking about yourself",
              "description": "Describing yourself and your interests",
              "duration": 2
          }
      ],
      "packages": [
          {
              "id": "package001",
              "name": "Basic Package",
              "hours": 24,
              "price": 200
          },
          {
              "id": "package002",
              "name": "Intermediate Package",
              "hours": 48,
              "price": 350
          }
      ]
  }
  ];

export let dummyLesson = {
  "id": 1234,
  "classroomId": 5678,
  "status": "ARRANGED_TRIAL",
  "startingDate": "2023-05-07T09:00:00Z",
  "duration": 60,
  "isRecccuring": false,
  "answered": "no",
  "requestedByName": "John Doe",
  "bbbLink": "https://example.com/bbb-room-1234",
  "title": "Introduction to Spanish",
  "level": "A1",
  "classroom": {
    "id": "5678",
    "title": "Spanish 101",
    "status": "ON_GOING",
    "totalHours": 20,
    "hoursLeft": 16,
    "activeLevel": "A1",
    "users": [
      {
        "id": 9876,
        "fname": "Jane",
        "lname": "Doe",
        "email": "jane.doe@example.com",
        "phone": "+123456789",
        "timezone": "Europe/Paris"
      },
      {
        "id": 8765,
        "fname": "Alice",
        "lname": "Smith",
        "email": "alice.smith@example.com",
        "phone": "+987654321",
        "timezone": "America/Los_Angeles"
      }
    ],
    "classroomStudents": [
      {
        "id": 9876,
        "fname": "Jane",
        "lname": "Doe",
        "email": "jane.doe@example.com",
        "phone": "+123456789",
        "timezone": "Europe/Paris"
      }
    ],
    "type": "Paid",
    "language": "Spanish",
    "teacher": {
      "id": 3456,
      "fname": "Juan",
      "lname": "Garcia",
      "email": "juan.garcia@example.com",
      "phone": "+345678901",
      "timezone": "Europe/Madrid"
    },
    "activePackage": {
      "id": "package-1234",
      "lessons_level": [
        {
          "level": "A1",
          "hoursCompleted": 4,
          "lessonsStarted": "2023-03-01T09:00:00Z",
          "lessonsEnded": "present"
        }
      ],
      "totalHours": 20,
      "currentPackageLevel": "A1",
      "hoursLeft": 16,
      "type": "REGULAR",
      "expiresOn": "2023-09-15T23:59:59Z",
      "stratedAt": "2023-02-15T09:00:00Z",
      "purchasedAt": "2023-02-15T12:00:00Z",
      "state": "ACTIVE",
      "extensions": []
    }
  }
};