import { Injectable } from '@angular/core';
import { Lesson, LessonStatus } from '../models/lesson.model';
import * as moment from 'moment';
import { UserAvailability, UserAvailabilityHours, UserAvailabilityOff } from '../models/user.model';
import { MyCalendarEvent, RetrieveCalendarAvailabilityRequestData } from '../models/calendar.model';
import { GeneralService } from './general.service';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Classroom } from '../models/classroom.model';
import { LessonService } from './lesson.service';
import { ClassroomService } from './classroom.service';
const BACKEND_URL = environment.apiUrl;
const BACKEND_LMS_URL = environment.apiUrl + "/LMS/";

const dayOfWeekMap: { [key: string]: number } = {
  'mon': 1,
  'tue': 2,
  'wed': 3,
  'thu': 4,
  'fri': 5,
  'sat': 6,
  'sun': 0
};
@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  private chooseClassroomListener$: BehaviorSubject<Classroom> = new BehaviorSubject<Classroom>({} as Classroom);
  public readonly chooseClassroomListener: Observable<Classroom> = this.chooseClassroomListener$.asObservable();

  private selectedDateListener$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  public readonly selectedDateListener: Observable<Date> = this.selectedDateListener$.asObservable();

  private durationListener$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly durationListener: Observable<number> = this.durationListener$.asObservable();

  private scheduleListener$: BehaviorSubject<{ listen: boolean, isUpdating: boolean }> = new BehaviorSubject<{ listen: boolean, isUpdating: boolean }>({ listen: false, isUpdating: false });
  public readonly scheduleListener: Observable<{ listen: boolean, isUpdating: boolean }> = this.scheduleListener$.asObservable();

  private updateListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly updateListener: Observable<boolean> = this.updateListener$.asObservable();
  private toggleOverlayListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly toggleOverlayListener: Observable<boolean> = this.toggleOverlayListener$.asObservable();

  private teacherChangeListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly teacherChangeListener: Observable<boolean> = this.teacherChangeListener$.asObservable();

  private calendarAgendaMonthListener$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  public readonly calendarAgendaMonthListener: Observable<Date> = this.calendarAgendaMonthListener$.asObservable();

  private calendarAgendaHeightListener$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly calendarAgendaHeightListener: Observable<number> = this.calendarAgendaHeightListener$.asObservable();

  public scrollTo: number = 0;
  public eventSettings: { dataSource: Record<string, any>[] } = {} as { dataSource: Record<string, any>[] }
  public classoomEventSettings: { dataSource: Record<string, any>[] } = {} as { dataSource: Record<string, any>[] }
  private calendarLessons: MyCalendarEvent[] = [];
  constructor(
    private http: HttpClient,
    private generalService: GeneralService,
    private authService: AuthService,
    private lessonService: LessonService,
    private classroomService: ClassroomService
  ) { }

  public setChooseClassroom(classroom: Classroom) {
    this.chooseClassroomListener$.next(classroom);
  }

  public setSelectedDateListener(date: Date) {
    this.selectedDateListener$.next(date);
  }

  public setDurationListener(duration: number) {
    this.durationListener$.next(duration);
  }

  public setScheduleListener(listen: boolean, isUpdating: boolean,) {
    this.scheduleListener$.next({ listen: listen, isUpdating: isUpdating });
  }

  public setUpdateListener(val: boolean) {
    this.updateListener$.next(val);
  }

  public setToggleOverlayListener(val: boolean) {
    this.toggleOverlayListener$.next(val);
  }

  public setTeacherChangeListener(val: boolean) {
    this.teacherChangeListener$.next(val);
  }

  public setCalendarAgendaMonthListener(val: Date) {
    this.calendarAgendaMonthListener$.next(val);
  }

  public setCalendarAgendaHeightListener(val: number) {
    this.calendarAgendaHeightListener$.next(val);
  }

  public convertLessonsToCallendarObjects(lessons: Lesson[], language: string): MyCalendarEvent[] {
    let eventSettings: MyCalendarEvent[] = [];
    let currentEventSettings: MyCalendarEvent;
    for (let lesson of lessons) {
      currentEventSettings = this.createLessonEvent(lesson, lesson.title!, language);
      eventSettings.push(currentEventSettings);
    }
    return eventSettings;
    // return { dataSource: extend([], eventSettings, undefined, true) as Record<string, any>[] };
  }

  createLessonEvent(lesson: Lesson, title: string, language: string) {
    // let recRule = lesson.isRecccuring ? 'FREQ=WEEKLY;INTERVAL=1;COUNT=10' : ''
    return {
      Id: lesson.id,
      Subject: title,
      Language: language.substring(0, 2).toUpperCase(),
      StartTime: lesson.startingDate,
      EndTime: moment(lesson.startingDate).add(lesson.duration * 60, 'm').toDate(),
      CategoryColor: "white",
      Duration: lesson.duration,
      Status: lesson.status,
      RecurrenceRule: "",
      Type: "appointment",
      Lesson: lesson,
      Padding: ((lesson.duration * 2) * 36 - 35 + 12) + "px 10px"
    }
  }

  public convertAvailabilityToCalendarObjects(times: UserAvailability, type: string): MyCalendarEvent[] {
    let currentWeek = this.getWeek();
    let mon = new Date(currentWeek[0]);
    let tue = new Date(currentWeek[1]);
    let wed = new Date(currentWeek[2]);
    let thu = new Date(currentWeek[3]);
    let fri = new Date(currentWeek[4]);
    let sat = new Date(currentWeek[5]);
    let sun = new Date(currentWeek[6]);;
    let eventSettings: MyCalendarEvent[] = [];
    this.createAvailabilityEventsByDay(mon, 'mon', times, type, eventSettings)
    this.createAvailabilityEventsByDay(tue, 'tue', times, type, eventSettings)
    this.createAvailabilityEventsByDay(wed, 'wed', times, type, eventSettings)
    this.createAvailabilityEventsByDay(thu, 'thu', times, type, eventSettings)
    this.createAvailabilityEventsByDay(fri, 'fri', times, type, eventSettings)
    this.createAvailabilityEventsByDay(sat, 'sat', times, type, eventSettings)
    this.createAvailabilityEventsByDay(sun, 'sun', times, type, eventSettings)
    return eventSettings;
  }

  createAvailabilityEventsByDay(day: Date, dayString: 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun', times: UserAvailability, type: string, eventSettings: MyCalendarEvent[]) {
    let currentEventSettings: MyCalendarEvent;
    let id: number = 0;
    let hourFrom = 0;
    let minutesFrom = 0;
    let hourTo = 0;
    let minutesTo = 0;
    let splitTimeFrom = [];
    let splitTimeTo = [];
    let from: number = 0;
    let to: number = 0;
    let nextFrom: number = 0;
    let nextTo: number = 0;
    let array: UserAvailabilityHours[] = times[dayString];
    let escapeFrom: boolean = false;
    if (array == null) {
      array = []
    }
    for (let i = 0; i < array.length; i++) {
      splitTimeFrom = array[i].from.split(':');
      splitTimeTo = array[i].to.split(':');
      hourFrom = parseInt(splitTimeFrom[0]);
      minutesFrom = parseInt(splitTimeFrom[1]);
      hourTo = parseInt(splitTimeTo[0]);
      minutesTo = parseInt(splitTimeTo[1]);
      if (!escapeFrom) {
        from = day.setHours(hourFrom, minutesFrom);
      }
      escapeFrom = false;
      to = day.setHours(hourTo, minutesTo);
      if (i + 1 < array.length) {
        splitTimeFrom = array[i + 1].from.split(':');
        splitTimeTo = array[i + 1].to.split(':');
        hourFrom = parseInt(splitTimeFrom[0]);
        minutesFrom = parseInt(splitTimeFrom[1]);
        hourTo = parseInt(splitTimeTo[0]);
        minutesTo = parseInt(splitTimeTo[1]);
        nextFrom = day.setHours(hourFrom, minutesFrom);
        nextTo = day.setHours(hourTo, minutesTo);

        if (to == nextFrom) {
          escapeFrom = true;
        }
      }
      if (!escapeFrom) {
        currentEventSettings = this.crateAvailabilityEvent(from, to, id, type);
        eventSettings.push(currentEventSettings);
      }
    }
  }

  crateAvailabilityEvent(from: number, to: number, id: number, type: string) {
    return {
      Id: id,
      StartTime: moment(from).add(0, 'm').toDate(),
      EndTime: moment(to).add(0, 'm').toDate(),
      Subject: "",
      CategoryColor: "",
      Duration: this.generalService.getDatesDiff(moment(from).add(0, 'm').toDate(), moment(to).add(0, 'm').toDate()).minutes / 60,//helps to set the height. View getAvailabilityEventHeight in scheduler.ts
      Status: LessonStatus.ARRANGED,
      RecurrenceRule: 'FREQ=WEEKLY;INTERVAL=1;COUNT=10',
      Type: type
    }
  }

  getEventGradientColor(status: LessonStatus): string {
    if (status === LessonStatus.ARRANGED) {
      return 'linear-gradient( #c056f5 0%,  #5d5085 100%)'
    } else if (status === LessonStatus.COMPLETED) {
      return 'linear-gradient( #3b9bed 0%,  #5d5085 100%)'
    } else {
      return 'linear-gradient( #F27769 0%,  #5d5085 100%)'
    }
  }

  getWeek() {
    let curr = new Date();
    let week = []

    for (let i = 1; i <= 7; i++) {
      let first = curr.getUTCDate() - curr.getUTCDay() + i
      let day = new Date(curr.setUTCDate(first)).toISOString().slice(0, 10)
      week.push(day)
    }
    return week
  }

/**
 * The function checks if a given time slot is available in a list of user availability hours.
 * @param {any} data - The `data` parameter is of type `any`, which means it can be any data type. It
 * is used to pass in the data for which availability needs to be checked.
 * @param {UserAvailabilityHours[]} dayAvailability - An array of objects representing the availability
 * hours for a user. Each object has two properties: "from" and "to", which represent the start and end
 * times of the availability period.
 * @returns an object with two properties: "add" and "block".
 */
  checkIfAvailabilityExists(data: any, dayAvailability: UserAvailabilityHours[]) {
    if (dayAvailability == null) {
      return {
        add: true,
        block: false
      }
    }
    let aTo: string = "";
    let aFrom: string = "";
    aFrom = data.StartTime.getHours().toString() + ":" + data.StartTime.getMinutes().toString();
    let splitTimeFrom = aFrom.split(':');
    if (splitTimeFrom[1].toString().length === 1) {
      aFrom = aFrom + "0";
    }
    if (splitTimeFrom[0].length === 1) {
      aFrom = "0" + aFrom
    }
    // let end = moment(data.EndTime).subtract(15, 'm').toDate(); // dont know why but it works
    aTo = data.EndTime.getHours().toString() + ":" + data.EndTime.getMinutes().toString();
    let splitTimeTo = aTo.split(':');
    if (splitTimeTo[1].toString().length === 1) {
      aTo = aTo + "0";
    }
    if (splitTimeTo[0].length === 1) {
      aTo = "0" + aTo
    }

    if (dayAvailability.length === 0) {
      return {
        add: true,
        block: false
      }
    }

    for (let available of dayAvailability) {
      let splitAvailabilityFrom = available.from.split(":");
      if (splitAvailabilityFrom[0].length === 1) {
        splitAvailabilityFrom[0] = "0" + splitAvailabilityFrom[0]
      }
      let from = splitAvailabilityFrom[0] + ":" + splitAvailabilityFrom[1];

      let splitAvailabilityTo = available.to.split(":");
      if (splitAvailabilityTo[0].length === 1) {
        splitAvailabilityTo[0] = "0" + splitAvailabilityTo[0]
      }
      let to = splitAvailabilityTo[0] + ":" + splitAvailabilityTo[1];

      if (from <= aFrom && to >= aTo) {
        return {
          add: false,
          block: true
        }
      }
    }

    for (let available of dayAvailability) {
      if ((available.from < aFrom && available.to > aFrom) || (aTo > available.from && aTo < available.to)) {
        return {
          add: false,
          block: false
        }
      }
    }

    for (let available of dayAvailability) {
      if (available.from > aFrom || available.to < aTo) {
        return {
          add: true,
          block: false
        }
      }
    }

    return {
      add: false,
      block: false
    }
  }

  addDaysOff(dayOff: UserAvailabilityOff) {
    return this.http.post<UserAvailabilityOff>(BACKEND_URL + "/Teacher/AddUserDayOff", dayOff)
  }

  cancelDaysOff(dayOffId: number) {
    return this.http.post(BACKEND_URL + "/Teacher/DeleteUserDayOff?id=" + dayOffId, {})
  }

  getPrettyLessonDate(date: Date) {
    return date.getHours() + ":" + date.getMinutes() + " on " + this.generalService.getDayFromNumber(date.getDay()) + " " + this.generalService.getMonthFromNumber(date.getMonth()) + " " + date.getDate()
  }

  createSpecificTeacherAvailability(availability: {}) {
    return this.http.post(BACKEND_LMS_URL + "CreateSpecificTeacherAvailability", availability)
  }

  blockSpecificTeacherAvailability(availability: {}) {
    return this.http.post(BACKEND_LMS_URL + "BlockSpecificTeacherAvailability", availability)
  }

  retrieveCalendarAvailability(availability: RetrieveCalendarAvailabilityRequestData, headers?: HttpHeaders): Observable<RetrieveCalendarAvailabilityRequestData> {
    return (this.http.post(BACKEND_LMS_URL + "RetrieveCalendarAvailability", availability, { headers }) as Observable<RetrieveCalendarAvailabilityRequestData>)
  }

  getBusinessHours(calendarAvailability: any) {
    const businessHours = [];
  
    for (const availability of calendarAvailability) {
      const dateParts = availability.date.split('/');
      const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
  
      for (const interval of availability.availability) {
        const startTimeParts = interval.from.split(':');
        const endTimeParts = interval.to.split(':');
        const startHour = parseInt(startTimeParts[0]);
        const startMinute = parseInt(startTimeParts[1]);
        const endHour = parseInt(endTimeParts[0]);
        const endMinute = parseInt(endTimeParts[1]);
  
        // Check if the end time is greater than the start time,
        // indicating that it goes until the next day
        if (endHour < startHour || (endHour === startHour && endMinute < startMinute)) {
          // Calculate the duration until midnight
          const midnightDuration = (24 - startHour) * 60 - startMinute;
  
          console.log(midnightDuration);
          // Add the availability for the current day until midnight
          businessHours.push({
            daysOfWeek: [date.getDay()],
            startTime: interval.from,
            endTime: '24:00'
          });
  
          // Increment the date to the next day
          date.setDate(date.getDate() + 1);
  
          // Calculate the duration from midnight until the end time
          const nextDayDuration = endHour * 60 + endMinute;
  
          // Add the availability for the next day from midnight
          businessHours.push({
            daysOfWeek: [date.getDay()],
            startTime: '00:00',
            endTime: interval.to
          });
        } else {
          // Add the availability for the current day
          businessHours.push({
            daysOfWeek: [date.getDay()],
            startTime: interval.from,
            endTime: interval.to
          });
        }
      }
    }
  
    console.log(businessHours);
    return businessHours;
  }

  createDayOffEvents(daysOff: any[]): any[] {
    return daysOff.map((dayOff: any) => {
      const from = new Date(dayOff.period.from);
      const to = new Date(dayOff.period.to);
      return {
        title: dayOff.reason,
        start: from,
        display: 'background',
        extendedProps: {
          type: 'dayOff',
          rendering: 'background'
        },
        selectable: false,
        editable: false,
        allDay: true
      };
    });
  }

}
