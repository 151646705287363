import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'g-accordion-item',
  templateUrl: './g-accordion-item.component.html',
  styleUrls: ['./g-accordion-item.component.scss']
})
export class GAccordionItemComponent implements OnInit {
  @Input() title: string = '';
  isActive: boolean = false;

  toggle() {
    this.isActive = !this.isActive;
  }
  constructor() { }

  ngOnInit(): void {
  }

}
