import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { Classroom } from 'src/app/core/models/classroom.model';
import { Lesson, LessonStatus } from 'src/app/core/models/lesson.model';
import { GeneralService } from 'src/app/core/services/general.service';
import { LibraryService } from 'src/app/core/services/library.service';
import { LessonInfoCalendarDialogComponent } from 'src/app/modules/calendar/calendar-dialogs/lesson-info-calendar-dialog/lesson-info-calendar-dialog.component';
type CardType = 'lesson' | 'request';

@Component({
  selector: 'app-mini-lesson-info-card',
  templateUrl: './mini-lesson-info-card.component.html',
  styleUrls: ['./mini-lesson-info-card.component.scss'],
  providers: [DialogService]
})

export class MiniLessonInfoCardComponent implements OnInit {

  @Input() cardType: CardType = 'lesson';
  @Input() statusColor: string = 'trial-completed';
  @Input() lesson: Lesson = {} as Lesson;
  @Input() classroom: Classroom = {} as Classroom;
  lessonStatus = LessonStatus;
  constructor(
    private libraryService: LibraryService,
    private dialogService: DialogService,
    public generalService: GeneralService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  get lessonStatusColor() {
    let status = this.lesson.status as LessonStatus;
    switch (status.toLowerCase()) {
      case this.lessonStatus.COMPLETED.toLowerCase():
        return 'completed';
        break;
      case this.lessonStatus.ARRANGED.toLowerCase():
        return 'arranged';
        break;
      case this.lessonStatus.NO_SHOW.toLowerCase():
        return 'no-show';
        break;
      case this.lessonStatus.CANCELED.toLowerCase():
        return 'canceled';
        break;
      default:
        // Do nothing if the status string is not recognized
        break;
    }
    return 'arranged';
  }

  get lessonStatusIcon() {
    let status = this.lesson.status as LessonStatus;
    switch (status.toLowerCase()) {
      case this.lessonStatus.COMPLETED.toLowerCase():
      case this.lessonStatus.COMPLETED_TRIAL.toLowerCase():
        return 'completed';
      case this.lessonStatus.ARRANGED.toLowerCase():
      case this.lessonStatus.ARRANGED_TRIAL.toLowerCase():
        return 'arranged';
      case this.lessonStatus.NO_SHOW.toLowerCase():
        return 'no-show';
      case this.lessonStatus.CANCELED.toLowerCase():
      case this.lessonStatus.CANCELED_TRIAL.toLowerCase():
        return 'canceled';
      default:
        // Do nothing if the status string is not recognized
        break;
    }
    return 'arranged';
  }

  getStopColorByStatus(status: string): string[] {

    if (status.toLowerCase().includes('trial')) {
      // trial arranged
      if (status.toLowerCase().includes(this.lessonStatus.ARRANGED.toLowerCase())) {
        return ['#7F74F4', '#7F74F4'];
      }
    }


    if (status.toLowerCase().includes(this.lessonStatus.ARRANGED.toLowerCase())) {
      return ['#6F6DDE', '#583CB3'];
    } else if (status.toLowerCase().includes('show')) {
      return ['#B4CAFB', '#457CF5'];
    } else if (status.toLowerCase().includes(this.lessonStatus.ARRANGED_TRIAL.toLowerCase())) {
      return ['#6F6DDE', '#583CB3'];
    } else if (status.toLowerCase().includes(this.lessonStatus.CANCELED_TRIAL.toLowerCase())) {
      return ['#ED6C74', '#EF7B6E'];
    } else if (status.toLowerCase().includes(this.lessonStatus.CANCELED.toLowerCase())) {
      return ['#E3557C', '#E47272'];
    } else if (status.toLowerCase().includes(this.lessonStatus.COMPLETED_TRIAL.toLowerCase())) {
      return ['#58B5DD', '#468DDD'];
    } else if (status.toLowerCase().includes(this.lessonStatus.COMPLETED.toLowerCase())) {
      return ['#529BDD', '#315DAF'];
    } else if (status.toLowerCase().includes(this.lessonStatus.REQUESTED.toLowerCase())) {
      return ['#9C50CA', '#6C5194'];
    }
    return ['#000', '#AAA'];

  }

  lessonCardClicked() {
    const dData = {
      type: 'lesson',
      lesson: this.lesson,
      classroom: this.classroom,
      dialogService: this.dialogService
    }
    this.libraryService.openDialogWithComponent(this.dialogService, LessonInfoCalendarDialogComponent, 290,
      dData, null, (result: any) => {
        console.log(result);
        // if (result && result.action === 'shareWithMultiple') {
        //   this.shareLibraryFiles(result.map.map);
        // }
      });
  }

  requestlessonCardClicked() {
    // this.router.navigateByUrl('/dashboard/calendar/booking-system');
    const navigationExtras: NavigationExtras = {
      queryParams: { 'classroom': JSON.stringify(this.classroom) }
    };
    this.router.navigate(['/dashboard/calendar/booking-system'], navigationExtras);
    // return this.generalService.navigateToBookingSystem();
  }

  convertStatusStringToSlug(word: string) {
    return word.toLowerCase().replace(" ", "-");
  }

}
