<app-header *ngIf="!isBelow768"></app-header>
<app-mobile-menu></app-mobile-menu>
<div #scale class="scale sm:pb-4">
    <div class="container p-0 sm:pt-5" id="mainContainerSideMenu">
        <div class="padding-top-section mt-0 md:mt-2"  id="mainContainerSideMenuBelow">
            <div class="sm:block md:flex grid-nogutter ">
                <ng-container *ngIf="isBelow768">
                    <!-- <app-mobile-left-menu [menuItems]="menuItems"
                    [hideRightSideButton]="true"
                        (onMenuUrlChange)="menuUrlChanged($event)"></app-mobile-left-menu> -->
                </ng-container>
                <div class="col-fixed si">
                    <div class="sticky-menu-wrapper" #menuOuterDiv>
                    <app-menu [menuItems]="menuItems" class="menu-column full-width"></app-menu>
                    </div>
                </div>
                <div class="col md:pl-2 content-column" id="mainContentColumn">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>