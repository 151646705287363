import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, Injector, QueryList, Signal, ViewChild, ViewChildren, computed, inject, input, signal, viewChild } from '@angular/core';
import * as firebase from "firebase/compat";
import * as moment from "moment";
import { BehaviorSubject, Observable, distinct, filter, forkJoin, map, of, switchMap, take } from "rxjs";
import { Classroom } from "src/app/core/models/classroom.model";
import { ChatMessage, FireDBChat } from "src/app/core/models/firebase-chat.model";
import { AuthService } from "src/app/core/services/auth.service";
import { ChatService, ChatUserStatus, Message } from "src/app/core/services/chat.service";
import { ClassroomService } from "src/app/core/services/classroom.service";
import { UserService } from "src/app/core/services/user.service";
import { SubSink } from "subsink";
import { collection, addDoc, serverTimestamp, FieldValue } from 'firebase/firestore';
import { User } from "src/app/core/models/user.model";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ButtonModule } from "primeng/button";
import { GeneralService } from "src/app/core/services/general.service";
import { LibraryFile } from "src/app/core/models/library.model";
import { SharedModule } from "src/app/shared/shared.module";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ChatUploadFileDialogComponent } from "./components/chat-upload-file-dialog/chat-upload-file-dialog.component";
import { LibraryService } from "src/app/core/services/library.service";
import { FileUploadModule, UploadEvent } from 'primeng/fileupload';
import { HttpHeaders, HttpEventType, HttpClient } from "@angular/common/http";
import { MyFile } from "src/app/shared/upload-files/upload-files.component";
import { environment } from "src/environments/environment";
import { InputTextModule } from "primeng/inputtext";
import { FormControl } from "@angular/forms";
import { ToastService } from "src/app/core/services/toast.service";
import { ChatMessagesDisplayComponent } from "./components/chat-messages-display/chat-messages-display.component";
import { BadgeModule } from "primeng/badge";
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SpeedDialModule } from 'primeng/speeddial';
import { Dialog } from "primeng/dialog";
import { toObservable } from "@angular/core/rxjs-interop";
import { TooltipModule } from "primeng/tooltip";
import { TabViewModule } from "primeng/tabview";

interface LastMessage {
    lastMessageFromImage: string;
    lastMessage: string;
    lastMessageAt: { seconds: number };
    // add other properties if necessary
}

interface LastMessagesState {
    [key: string]: LastMessage;
}

enum ChatType {
    CLASSROOM = "classroom",
    USER = "user",
}

@Component({
    selector: 'app-chat',
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        SharedModule,
        FileUploadModule,
        InputTextModule,
        InputTextareaModule,
        SpeedDialModule,
        BadgeModule,
        TooltipModule,
        TabViewModule,
        ChatMessagesDisplayComponent,
    ],

    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    animations: [
        trigger('scaleToggle', [
            transition('shown <=> hidden', [animate('0.3s ease-in-out')]),
        ]),
    ],
    providers: [DialogService]
})
export class ChatComponent {
    @ViewChild('autoResizeTextArea', { static: false }) autoResizeTextArea!: ElementRef;
    @ViewChild('textareaWrapper', { static: false }) textareaWrapper!: ElementRef;
    @ViewChild('editForbitDialog', { static: false }) editForbitDialog!: Dialog;

    private injector = inject(Injector);
    bubbleMode = input(false);
    private subs = new SubSink();
    term = signal('');
    tutorials?: any[];
    currentTutorial?: any;
    currentIndex = -1;
    title = '';
    chatService = inject(ChatService);
    libraryService = inject(LibraryService);
    authService = inject(AuthService);
    classroomService = inject(ClassroomService);
    userService = inject(UserService);
    generalService = inject(GeneralService);
    dialogService = inject(DialogService);
    toastService = inject(ToastService);
    http = inject(HttpClient);
    loading = false;
    files = [] as LibraryFile[];
    onlineUsers: ChatUserStatus[] = [];

    chatListsLoaded = signal(false);

    messageTimestamps: number[] = [];
    activeUser!: any;
    editMessageObj!: any;
    editMessageisLastMessage = false;
    editMessageMode = signal(false);
    messages = signal([] as Message[]);
    lastMessages = signal([] as any[]);
    private lastMessagesSubject = new BehaviorSubject<any[]>([]);
    lastMessages$ = this.lastMessagesSubject.asObservable();
    expandStatuses = false;
    expanded = false;
    searchControl = new FormControl('');
    chatMessages$!: Observable<ChatMessage[]>;
    user = this.authService.getLoggedInUser();

    isTabletOrMobile = false;
    myScrollContainer: Signal<ElementRef | undefined> = viewChild('scrollMe');
    // @ViewChild('scrollMe') private myScrollContainer: ElementRef = {} as ElementRef;
    currentChatId: string = '';
    chatsList = signal([] as any[]);
    filteredChats = computed(() => {
        console.log(this.term());
        if (!this.term()) {
            return this.chatsList();
        } else {
            return this.chatsList().filter(chat =>
                this.getChatGroupName(chat).toLowerCase().includes(this.term().toLowerCase())
            );
        }
    });
    currentChatSubscription: any;
    showInnerChat = false;

    chatCircleState: string = 'shown';
    chatBoxState: string = 'hidden';
    chatBoxVisible = false;
    chats = signal([] as any[]);
    uploadProgress = 0;
    leftTooltipItems = [] as any[];
    pastedImage: any = undefined;
    imageInfo: string = '';
    chatType = ChatType;

    onSearchChange(event: any) {
        const input = event.target as HTMLInputElement;
        this.term.set(input.value);
    }

    toggleChat() {
        this.chatCircleState = this.chatCircleState === 'shown' ? 'hidden' : 'shown';
        this.chatBoxState = this.chatBoxState === 'shown' ? 'shown' : 'hidden';
        this.chatBoxVisible = !this.chatBoxVisible;
        this.activeUser = undefined;
        this.showInnerChat = false;
        if (this.chatBoxVisible) {
            this.initChatFunctionality();
        }
    }

    ngOnInit(): void {

        this.chatService.getFireBaseUser().subscribe((res) => {
            console.log(res);
        })
        // this.chatService.createFirebaseUser('asd@sasa.cc', '12213213231').subscribe({
        //     next: (data: any) => {
        //         console.log(data);
        //     },
        //     error: (err: any) => {
        //         console.log(err);
        //     }
        // })
        // this.chatService.signInAnonymously().subscribe({
        //     next: (res) => {
        //         console.log(res);
        //     },
        //     error: (err) => {
                
        //     }
        // })
        this.user = this.authService.getLoggedInUser();

        if (!this.bubbleMode()) {

            this.initChatFunctionality();

        }
        this.searchControl.valueChanges.subscribe((res) => {
            console.log()
        });

        this.loadOnlineStatus();
        this.listenChatListsLoaded();
        this.loadMobileLayoutCheck();

        this.leftTooltipItems = [
            {
                tooltipOptions: {
                    tooltipLabel: 'Add',
                    tooltipPosition: 'left'
                },
                icon: 'pi pi-pencil',
                command: () => {
                }
            },
            {
                tooltipOptions: {
                    tooltipLabel: 'Update',
                    tooltipPosition: 'left'
                },
                icon: 'pi pi-refresh',
                command: () => {
                }
            },
            {
                tooltipOptions: {
                    tooltipLabel: 'Delete',
                    tooltipPosition: 'left'
                },
                icon: 'pi pi-trash',
                command: () => {
                }
            },
            {
                icon: 'pi pi-upload',
                tooltipOptions: {
                    tooltipLabel: 'Upload',
                    tooltipPosition: 'left'
                }
            },
            {
                tooltipOptions: {
                    tooltipLabel: 'Angular Website',
                    tooltipPosition: 'left'
                },
                icon: 'pi pi-external-link',
                url: 'http://angular.io'
            }
        ];

    }
    ngAfterViewChecked() {
    }

    ngAfterViewInit(): void {
        // Initially set the height of each textarea
        // this.textareas.forEach(textarea => {
        //     this.adjustHeight(textarea.nativeElement);
        // });

        // // Listen for input event on each textarea to adjust the height
        // this.textareas.forEach(textarea => {
        //     console.log(textarea);
        //     textarea.nativeElement.addEventListener('input', this.onInput.bind(this));
        // });
    }
    adjustParentHeight() {
        // Assuming you have the implementation for this method to adjust the textarea height
        const element = this.autoResizeTextArea.nativeElement;
        element.style.height = 'auto';
        element.style.height = `${element.scrollHeight}px`;
    }

    onInput(event: any): void {
        this.adjustHeight(event.target);
    }

    adjustHeight(textarea: HTMLTextAreaElement): void {
        textarea.style.height = 'auto'; // Reset height to auto to get the actual scrollHeight
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on scrollHeight
    }

    onBasicUploadAuto(event: any, fromClipboard = false) {
        console.log(event);
        let selectedFile = {} as File;
        if (fromClipboard) {
            selectedFile = event;
        } else {
            selectedFile = event.currentFiles[0] as File;
        }

        if (selectedFile) {
            let headers = new HttpHeaders();  // Add any headers if needed
            const formData = new FormData();
            formData.append('file', selectedFile);
            headers = new HttpHeaders({
                'documentType': selectedFile.name.split('.').pop()!,
                "Authorization": "Bearer " + this.authService.getToken(),
            });
            const myFile: MyFile = {
                progress: 0,
                file: selectedFile,
                index: 0
            };
            this.uploadRequest(headers, '', formData, myFile);
        }
    }

    uploadRequest(headers: HttpHeaders, endpointExtras: string, formData: FormData, myFile: MyFile) {
        this.subs.add(this.http.post(environment.apiUrl + '/Upload/UploadLibraryFile' + endpointExtras, formData, {
            headers: headers,
            reportProgress: true,
            observe: 'events'
        })
            .subscribe((event: any) => {

                if (event.type === HttpEventType.UploadProgress) {
                    myFile.progress = Math.round(100 * event.loaded / event.total!);
                    this.uploadProgress = myFile.progress;
                } else if (event.type === HttpEventType.Response) {
                    console.log('Upload complete:', event.body);
                    this.sendMessage(event.body.dbPath, false, true);
                    setTimeout(() => {
                        this.scrollToBottom();
                    }, 800);
                    this.uploadProgress = 0;
                    // Handle the response from the server, e.g., store the file path or show a success message
                }

                if (event.loaded === event.total) {
                    console.log(HttpEventType);
                }
                if (event.type === HttpEventType.UploadProgress) {
                }
                else if (event.type === HttpEventType.Response) {

                    // this.uploadedFile = event.body;
                    // if (this.isProfile) {
                    //     console.log('GEGEG');
                    //     this.showCropper = false;
                    // }
                    // this.onUploadFinished.emit({ filePath: event.body, task: this.task });
                } else {
                }
            }, (err: any) => {
                console.log(err)
            }));
    }

    getChatGroupName(chatClassroom: Classroom) {
        if (!chatClassroom) {
            return '';
        }
        if (this.authService.isTeacher) {
            return this.classroomService.getFormattedStudentNames(chatClassroom.classroomStudents);
        } else {
            return chatClassroom.language + ' - ' + chatClassroom.teacher?.firstName;
        }
    }

    getChatOppositeName(chatClassroom: Classroom) {
        if (!chatClassroom) {
            return '';
        }
        if (this.authService.isTeacher) {
            return this.classroomService.getFormattedStudentNames(chatClassroom.classroomStudents);
        } else {
            return chatClassroom.teacher?.firstName;
        }
    }

    getChatGroupAvatarUrl(chatClassroom: Classroom) {
        if (!chatClassroom) {
            return '';
        }
        if (this.authService.isTeacher) {
            return chatClassroom.classroomStudents[0].avatarUrl;
        } else {
            return chatClassroom.teacher?.avatarUrl;
        }
    }

    getChatGroupAspUserId(chatClassroom: Classroom) {
        if (!chatClassroom) {
            return '';
        }
        if (this.authService.isTeacher) {
            return chatClassroom.classroomStudents[0].aspUserId;
        } else {
            return chatClassroom.teacher?.aspUserId;
        }
    }

    initChatFunctionality() {
        this.subs.sink = this.classroomService.getLMSUserClassrooms(this.user.id).subscribe(res => {
            if (this.authService.isTeacher) {
                this.initTeacherChats(res);
            } else if (this.authService.isStudent) {
                this.initStudentChats(res);
            }
            this.retrieveChats(res);

        });

    }

    initTeacherChats(classrooms: Classroom[]) {
        const uniqueStudentIds = new Set<number>();
        const chats = classrooms.map(classroom => {
            const classroomChat = this.createClassroomChat(classroom);
            // TODO: this needed to prevent repeated student chats 
            if (classroom.classroomStudents.length === 1) {
                return [classroomChat];
            }
            const studentChats = classroom.classroomStudents
                .filter(student => !uniqueStudentIds.has(+student.id))
                .map(student => {
                    uniqueStudentIds.add(+student.id);
                    return this.createStudentChat(classroom, student);
                });
            return [classroomChat, ...studentChats];
        });
        this.chatsList.set(chats.flat());
    }

    initStudentChats(classrooms: Classroom[]) {
        const uniqueTeacherIds = new Set<number>();
        const chats = classrooms.map(classroom => {
            const classroomChat = this.createClassroomChat(classroom);
            const individualChat = this.createIndividualChat(classroom);


            if (classroom.classroomStudents.length > 1) {
                return [classroomChat, individualChat];
            }
            // return [classroomChat];
            if (!uniqueTeacherIds.has(+individualChat.teacherId!)) {
                uniqueTeacherIds.add(+individualChat.teacherId!);
                return [classroomChat];
            } else {
                return [classroomChat];
            }
        });
        this.chatsList.set(chats.flat());
    }

    createClassroomChat(classroom: Classroom) {

        let name = '';

        if (this.authService.isTeacher) {
            name = this.classroomService.getFormattedStudentNames(classroom.classroomStudents);
        } else {
            // student
            if (classroom.classroomStudents.length > 1) {
                name = this.generalService.getShortFullName(classroom.teacher) + ' - ' + this.classroomService.getFormattedStudentNames(classroom.classroomStudents);
            } else {
                name = this.generalService.getShortFullName(classroom.teacher);
            }
        }

        return {
            classroomId: classroom.id,
            classroomLevel: classroom.activeLevel,
            classroomLanguage: classroom.language,
            teacher: classroom.teacher,
            chatType: ChatType.CLASSROOM,
            chatName: name,
            chatHistory: [],
            classroomStudents: classroom.classroomStudents,
            relatedName: this.classroomService.getFormattedStudentNames(classroom.classroomStudents),
        };
    }

    createStudentChat(classroom: Classroom, student: User) {

        let name = '';

        if (this.authService.isTeacher) {
            name = this.classroomService.getFormattedStudentNames(classroom.classroomStudents);
        } else {
            // student
            if (classroom.classroomStudents.length > 1) {
                name = this.generalService.getShortFullName(classroom.teacher) + ' - ' + this.classroomService.getFormattedStudentNames(classroom.classroomStudents);
            } else {
                name = this.generalService.getShortFullName(classroom.teacher);
            }
        }
        return {
            classroomId: `teacher-${classroom.teacher?.id}-student-${student.aspUserId}`,
            studentId: student.id,
            relatedName: name,
            chatType: ChatType.USER,
            chatName: this.generalService.getShortFullName(student),
            chatHistory: [],
            teacher: classroom.teacher,
            classroomStudents: classroom.classroomStudents,
        };
    }

    createIndividualChat(classroom: Classroom) {
        let name = '';

        if (this.authService.isTeacher) {
            name = this.classroomService.getFormattedStudentNames(classroom.classroomStudents);
        } else {
            // student
            if (classroom.classroomStudents.length > 1) {
                name = this.generalService.getShortFullName(classroom.teacher);
            } else {
                name = this.generalService.getShortFullName(classroom.teacher);
            }
        }

        return {
            classroomId: `teacher-${classroom.teacher?.id}-student-${this.user.id}`,
            teacherId: classroom.teacher?.id,
            studentId: this.user.id,
            chatType: ChatType.USER,
            chatName: name,
            chatHistory: [],
            teacher: classroom.teacher,
            classroomStudents: classroom.classroomStudents,
            relatedName: this.classroomService.getFormattedStudentNames(classroom.classroomStudents),
        };
    }

    loadChatMessages(chatId: string) {
        // Unsubscribe from the previous subscription if it exists
        if (this.currentChatSubscription) {
            this.currentChatSubscription.unsubscribe();
        }

        // Set the new current chatId
        this.currentChatId = chatId;

        this.chatService.markMessagesAsRead(this.currentChatId, this.user.aspUserId);
        // Subscribe to the new chat messages
        this.currentChatSubscription = this.chatService.getChatMessages(chatId).subscribe((messages: Message[]) => {

            this.messages.set(messages);
            console.log(this.filterOutOldMessages());
            this.scrollToBottom();
        });
    }

    filterOutOldMessages() {
        console.log(this.messages());
        // Define the cutoff date (5 days ago from now)
        const cutoffDate = moment().subtract(5, 'minutes');

        const filteredMessages = this.messages().filter((message: any) => {
            const messageTimestamp = moment.unix(message.timestamp.seconds);
            return messageTimestamp.isAfter(cutoffDate);
        });

        console.log(filteredMessages);
    }

    addNewMessage(event: Event, inputField: HTMLInputElement): void {
        const textarea = event.target as HTMLTextAreaElement;
        const now = Date.now();
        // Remove timestamps older than 10 seconds
        this.messageTimestamps = this.messageTimestamps.filter(timestamp => now - timestamp < 10000);

        if (this.messageTimestamps.length > 3) {

            const oldestTimestamp = Math.min(...this.messageTimestamps);
            const waitTime = 10 - Math.floor((now - oldestTimestamp) / 1000);

            this.toastService.setShowToastmessage({
                severity: 'warn',
                summary: '',
                detail: `You can only send 3 messages every 10 seconds. Please wait ${waitTime} seconds to send the next message.`,
            });

            return;
        } else {
            this.messageTimestamps.push(now); // Add current timestamp
        }

        let val = inputField.value?.trim();

        if (val.length) {
            // Convert line breaks (\n) from textarea into <br> tags for HTML rendering
            val = val.replace(/\n/g, '<br>');

            // Create a temporary div element to parse the HTML string
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = val; // Set the HTML input (with <br>) directly into the div

            if (this.editMessageMode()) {
                this.editMessageObj.content = tempDiv.innerHTML;
                this.chatService.editMessage(this.currentChatId, this.editMessageObj.id, this.editMessageObj, this.user, this.editMessageisLastMessage);
            } else {

                // Send the sanitized HTML content with line breaks and formatting
                this.sendMessage(tempDiv.innerHTML);
            }
            this.editMessageMode.set(false);
        }

        inputField.value = '';

        setTimeout(() => {
            textarea.rows = 1;
            textarea.value = '';
            // textarea.style.height = '24px'; // Reset the height to its original value
            this.adjustParentHeight();
        }, 30);
    }

    deleteMessage(message: [Message, boolean]) {
        console.log(message);
        if(message[0].isUploadFile) {
            this.chatService.deleteChatFilesFromLibraryFolder(message[0].content).subscribe({
                next: (res) => {
                    console.log(res);
                    this.chatService.deleteMessage(this.currentChatId, message[0], this.user, message[1]);
                },
                error: () => {
                    this.chatService.deleteMessage(this.currentChatId, message[0], this.user, message[1]);
                }
            });
        } else {
            this.chatService.deleteMessage(this.currentChatId, message[0], this.user, message[1]);
        }

    }

    editMessage(message: any) {
        this.editMessageMode.set(true);
        console.log(message);
        this.editMessageObj = message[0];
        this.editMessageisLastMessage = message[1];
        if (this.autoResizeTextArea) {
            const formattedContent = this.editMessageObj.content.replace(/<br\s*\/?>/gi, '\n');
            this.autoResizeTextArea.nativeElement.value = formattedContent; // Set the textarea value
            this.adjustParentHeight(); // Adjust the height of the textarea if necessary
        }
    }

    scrollToBottom(): void {
        try {
            setTimeout(() => {
                if (this.myScrollContainer() && this.myScrollContainer()!.nativeElement) {
                    this.myScrollContainer()!.nativeElement.scrollTop = this.myScrollContainer()!.nativeElement.scrollHeight;
                }
            }, 10);
        } catch (err) { }
    }

    setUserActive(user: any) {
        this.activeUser = user;
        if (!this.activeUser) {
            return;
        }
        this.showInnerChat = true;
        this.chatService.currentRoom.set(this.activeUser);
        this.loadChatMessages(this.activeUser.classroomId);
        this.chatService.markLastMessagesAsRead(this.currentChatId, this.user.aspUserId);


        this.disableEditAndTextarea();
        // this.chatService.getChatMessages(this.activeUser.aspUserId);
    }

    refreshList(): void {
        this.currentTutorial = undefined;
        this.currentIndex = -1;
        // this.retrieveChats();
    }

    private getUnreadMessages() {
        // Reset unread count before starting the accumulation process
        this.chatService.unreadCount.set(0);

        const chatListsWithUnreadCounts = {} as any;

        this.chatsList().forEach((classroom: any) => {
            this.chatService.getLastUnreadMessagesCountForUser(classroom.classroomId, this.user.aspUserId)
                .subscribe(count => {
                    const chatListWithUnreadCount = {
                        classroom: classroom,
                        unreadCount: count
                    };

                    chatListsWithUnreadCounts[classroom.classroomId] = chatListWithUnreadCount;

                    console.log(`Unread messages count for classroom ${classroom.classroomId} for user 
                ${this.user.firstName + ' ' + this.user.lastName} with aspUserId ${this.user.aspUserId}: ${count}`);
                    const totalUnreadCount = Object.values(chatListsWithUnreadCounts).reduce((acc, current: any) => acc + current.unreadCount, 0) as number;
                    this.chatService.unreadCount.set(totalUnreadCount);
                });

        });
    }

    retrieveChats(classrooms: any[]): void {

        // Wait for all the subscriptions to complete
        this.getUnreadMessages();

        // Initialize an object to hold the last messages for sorting
        const lastMessages = this.lastMessagesSubject.getValue();

        classrooms.forEach(classroom => {
            // Retrieve unread message count for the classroom
            // this.chatService.getUnreadMessagesCount(classroom.classroomId, this.user.aspUserId).subscribe(count => {
            //     console.log(`Total getUnreadMessagesCount unread messages count for user ${this.user.aspUserId}: ${count}`);
            // });
            // Retrieve the last message for the classroom itself
            this.chatService.getLastMessage(classroom.id).subscribe({
                next: (message: any) => {
                    lastMessages[classroom.id] = message;
                    this.updateChatsListSorting(lastMessages);
                },
                error: (err) => {
                    console.error(`Failed to retrieve last message for classroom ${classroom.id}`, err);
                }
            });

            // If the classroom has more than one student, retrieve last messages for individual chats
            classroom.classroomStudents.forEach((student: any) => {
                let chatId = `teacher-${classroom.teacher.id}-student-${student.aspUserId}`;
                if (classroom.chatType === ChatType.CLASSROOM) {
                    chatId = `${classroom.id}`;
                }
                if (classroom.chatType === ChatType.USER) {
                    if (this.authService.isStudent) {
                        chatId = `teacher-${classroom.teacher.id}-student-${this.user.id}`;
                    }
                }

                console.log(`Retrieving  ${chatId}`);
                this.chatService.getLastMessage(chatId).subscribe({
                    next: (message: any) => {
                        lastMessages[chatId as any] = message;
                        this.updateChatsListSorting(lastMessages);
                    },
                    error: (err) => {
                        console.error(`Failed to retrieve last message for student ${student.id} in classroom ${classroom.id}`, err);
                    }
                });
            });
        });
    }

    updateChatsListSorting(lastMessages: any): void {
        this.chatsList.set(this.chatsList().sort((a: any, b: any) => {
            const aLastMessage = lastMessages[a.classroomId]?.lastMessageAt?.seconds || 0;
            const bLastMessage = lastMessages[b.classroomId]?.lastMessageAt?.seconds || 0;
            return bLastMessage - aLastMessage;
        }));

        this.lastMessagesSubject.next(lastMessages);
        this.chatListsLoaded.set(true);
    }

    private listenChatListsLoaded() {
        toObservable(this.chatListsLoaded, {
            injector: this.injector
        }).subscribe({
            next: (chatListsLoaded) => {
                console.log(chatListsLoaded);
                if (chatListsLoaded) {
                    setTimeout(() => {
                        if (!this.bubbleMode() && !this.isTabletOrMobile) {
                            this.setUserActive(this.filteredChats()[0]);
                        }
                    }, 100);

                }
            }
        });
    }

    private sortUserListByChats(chats: any) {

        // Create a map of userId to the latest timestamp of their chats
        const userTimestampMap: { [key: string]: string } = {};

        chats.forEach((chat: any) => {
            chat.participants.forEach((participant: string) => {
                // Update the map with the latest timestamp for each user
                if (!userTimestampMap[participant] || userTimestampMap[participant] < chat.lastMessageAt) {
                    userTimestampMap[participant] = chat.lastMessageAt;
                }
            });
        });

        // Sort the chatsList by the latest timestamp of their chats

    }

    findAvatarUrl(aspUserId: string): string | null {
        for (let classroom of this.chatsList()) {
            // Check if the teacher matches the aspUserId
            if (classroom.teacher && classroom.teacher.aspUserId === aspUserId) {
                return classroom.teacher;
            }

            // Check if any student matches the aspUserId
            for (let student of classroom.classroomStudents) {
                if (student.aspUserId === aspUserId) {
                    return student;
                }
            }
        }
        return null; // Return null if no match is found
    }


    getUserFullNameAndInitials(aspUserId: string): any | null {
        for (let classroom of this.chatsList()) {
            // Check if the teacher matches the aspUserId
            if (classroom.teacher && classroom.teacher.aspUserId === aspUserId) {
                const fullName = classroom.teacher.firstName + ' ' + classroom.teacher.lastName;
                const initials = classroom.teacher.firstName.charAt(0) + classroom.teacher.lastName.charAt(0);
                return { fullName, initials };
            }

            // Check if any student matches the aspUserId
            for (let student of classroom.classroomStudents) {
                if (student.aspUserId === aspUserId) {
                    const fullName = student.firstName + ' ' + student.lastName;
                    const initials = student.firstName.charAt(0) + student.lastName.charAt(0);
                    return { fullName, initials };
                }
            }
        }
        return null; // Return null if no match is found
    }

    sendMessage(content: string, isFileFromLibrary: boolean = false, isUploadFile: boolean = false) {
        const message: Message = {
            senderId: this.user.aspUserId,
            content: content,
            timestamp: serverTimestamp(),
            readBy: [this.user.aspUserId],
            isFileFromLibrary: isFileFromLibrary,
            isUploadFile: isUploadFile,
            readStatus: { [this.user.aspUserId]: true }

        };

        console.log(this.user);
        this.chatService.addMessage(this.currentChatId, message, this.user);
        this.chatService.markMessagesAsRead(this.currentChatId, this.user.aspUserId);
    }

    isImage(filePath: string): boolean {
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.svg', '.tiff', '.ico', '.jfif', '.avif'];
        const fileExtension = filePath.split('.').pop()?.toLowerCase();
        return imageExtensions.includes(`.${fileExtension}`);
    }

    goToChat() {
        this.showInnerChat = true;
    }

    openFileUploadDialog() {

        const divToShowDialog = this.bubbleMode() ? 'mainContainerSideMenuBelow' : 'containerLayout';
        this.libraryService.isPreviewingFromChat.set(true);
        this.userService.setNewFileUpload({});
        setTimeout(() => {
            const myElement = document.getElementById(divToShowDialog);
            const dialogWidth = myElement!.getBoundingClientRect().width - 10;
            this.generalService.openDialogWithComponent(this.dialogService, ChatUploadFileDialogComponent, dialogWidth.toFixed(),
                {
                    action: 'upload',
                    dialogsubTitle: '',
                    dialogTitle: 'Upload to library...',
                }, divToShowDialog, (result: any) => {
                    console.log(result);
                    this.chatService.showUploadFile.set(false);
                    this.libraryService.checkedLibraryFiles.set([]);
                    this.libraryService.isPreviewingFromChat.set(false);
                    // this.dialogService.getInstance(result).close();
                    // this.generalService.destroyComponent(ChatUploadFileDialogComponent);;
                    if (result && Array.isArray(result)) {
                        result.forEach((item: any) => {
                            this.sendMessage(item.path, true, true);
                        });
                        setTimeout(() => {
                            this.scrollToBottom();
                        }, 1000);
                        console.log(result);
                        // this.moveFile(result.map.map);
                    }
                }, myElement!.getBoundingClientRect().height - 290 + 'px');
        }, 100);

    }


    onPaste(event: any) {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        let blob = null;
        for (const item of items) {
            if (item.type.indexOf('image') === 0) {
                blob = item.getAsFile();
                this.onBasicUploadAuto(blob, true);
                console.log(blob); // Prints your files
            }
        }
    }

    recognizeImage(imageData: string | ArrayBuffer | null) {
        // Simulate an API call
        this.imageInfo = 'Recognized Text: "Example text from image"'; // Mock recognition result

        // Example of a real API call
        /*
        this.http.post('YOUR_API_ENDPOINT', { image: imageData }).subscribe(response => {
          this.imageInfo = response.recognizedText; // Update with actual response
        });
        */
    }

    loadOnlineStatus() {

        this.chatService.setUserStatus(this.user.aspUserId, 'online');

        // Set the user status to offline when the component is destroyed
        window.addEventListener('beforeunload', () => {
            this.chatService.setUserStatus(this.user.aspUserId, 'offline');
        });

        // Subscribe to online users
        this.chatService.getOnlineUsers().subscribe(users => {
            this.onlineUsers = users;

            console.log(this.onlineUsers);
        });
    }

    isUserOnline(userId: string) {
        return this.onlineUsers.some(user => user.id === userId);
    }

    loadMobileLayoutCheck() {
        this.subs.sink = this.generalService.deviceKind.pipe(
            switchMap(res => {
                this.isTabletOrMobile = !res.w992up;
                if (!res.w1024up) {
                    return of(0);
                } else {
                    return of(null);
                }
            })
        ).subscribe((res) => {
            if (res === 0) {
            } else if (res !== 0) {
                const menuHeight = res;
            }
        });
    }

    showLeftSideMobile() {
        if (this.isTabletOrMobile && this.showInnerChat) {
            return false;
        } else {
            return true;
        }
    }

    showRightSideMobile() {
        if (this.isTabletOrMobile && !this.showInnerChat) {
            return false;
        } else {
            return true;
        }
    }

    showBackUsers() {
        this.showInnerChat = false;
    }

    autoResize(textArea: HTMLTextAreaElement): void {
        textArea.style.height = 'auto';  // Reset height to auto to shrink when deleting text
        textArea.style.height = textArea.scrollHeight + 'px';  // Set height to the scrollHeight of the content
    }

    disableEditAndTextarea() {
        this.editMessageMode.set(false);
    }

    resetTextarea() {
        this.autoResizeTextArea.nativeElement.value = '';
        // this.adjustHeight(this.autoResizeTextArea.nativeElement);
        this.adjustParentHeight();
    }

    countLastMessageCount(lastMessage: any) {
        if (!lastMessage) {
            return;
        }
        if (lastMessage.unreadCounts) {
            return lastMessage.unreadCounts[this.user.aspUserId];
        } else {
            return 0;
        }
    }
}
