import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogService } from 'src/app/core/services/confirm-dialog.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent implements OnInit {

  @ViewChild('lottieAnim', { static: false }) lottieAnim: ElementRef = {} as ElementRef;
  @Input() width = {'max-width': '640px'};
  @Input() headerText?: string;
  @Input() confirmMessage?: string;
  @Input() confirmIcon?: string;
  @Input() acceptBtnLabel?: string;
  @Input() rejectBtnLabel?: string;
  @Input() rejectBtnIcon?: string | undefined = undefined;
  @Input() acceptBtnIcon?: string | undefined = undefined;
  @Input() showHeader?: boolean = false;
  @Input() dismissableMask?: boolean = true;
  @Input() dialogType?: string = 'header-with-image'; // Set the default dialog type
  @Input() headerClass?: string = 'text-center flex align-items-center flex-column '; //text-center flex align-items-center flex-row 
  @Input() dialogKey = 'key1';
  @Input() acceptBtnImage = '';
  @Input() acceptBtnClass = 'cd-dialog-btn-accept';
  @Input() rejectBtnClass = 'cd-dialog-btn-reject';
  @Input() showConfirmIcon = true;
  
  @Output() confirmSelected = new EventEmitter();
  @Output() rejectSelected = new EventEmitter();
  constructor(
    private confirmationService: ConfirmationService,
    private confirmDialogService: ConfirmDialogService,
  ) { }

  ngOnInit(): void {
    this.confirmationService.confirm({
      message: this.confirmMessage,
      dismissableMask: this.dismissableMask,
      accept: () => {
        this.confirmSelected.emit();
        console.log('accepted');
        this.confirmDialogService.onAcceptSelection(null);
        this.acceptBtnClass = 'cd-dialog-btn-accept';
        this.rejectBtnClass = 'cd-dialog-btn-reject';
        this.confirmDialogService.hide();
        this.confirmDialogService.resetProperties();
      },
      reject: () => {
        this.rejectSelected.emit();
        this.confirmDialogService.onRejectSelection(null);
        this.acceptBtnClass = 'cd-dialog-btn-accept';
        this.rejectBtnClass = 'cd-dialog-btn-reject';
        this.confirmDialogService.hide();
        this.confirmDialogService.resetProperties();
        console.log('rejected');
      }
    });
  }

  ngOnDestroy() {
    this.acceptBtnClass = 'cd-dialog-btn-accept';
    this.rejectBtnClass = 'cd-dialog-btn-reject';
  }

  ngAfterContentInit() {
    if (this.dialogType === 'header-with-lottie' && this.lottieAnim) {
    setTimeout(() => {
      this.addLottiePlayerToStep(this.lottieAnim);
    }, 200); // Delay the loading of the animation by 1 second

  }
  }

  onClearSelected() {
    this.confirmDialogService.hide();
    this.confirmDialogService.resetProperties();
  }

  private addLottiePlayerToStep(step: ElementRef): void {
    const lottiePlayer = `<div  class="animation-wrapper"><lottie-player src="https://assets9.lottiefiles.com/packages/lf20_ur7sluxh.json" background="transparent" 
    speed="1" style="width: 172px; height: 172px; margin-right:15px;" autoplay loop></lottie-player></div>`;
    step.nativeElement.innerHTML = lottiePlayer;
  }

}
