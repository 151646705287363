<div class="lesson-rating p-2">
    <div #allRating>
        <div class="lesson-rating-header text-primary block-gradient font-sm">
            <div>
                Lesson Date - {{ lesson.startingDate | date:'MMM d, y' }}
            </div>
            <div>
                Lesson Started at: {{ generalService.getLessonTime(lesson.startingDate) }}
            </div>
            <div>
                Lesson Duration: {{generalService.convertHoursToMinutesWithSuffix(lesson.duration, true)}}
            </div>
        </div>
        <div class="lesson-rating-content block-gradient border-round-xl p-2">
            <div class="lesoon-rating-title font-base">
                Ratings
            </div>
            <div class="rating mt-1">
                <div class="rating-content">
                    <div class="left-side font-sm hidden sm:block">
                        CLASSROOM
                    </div>
                    <div class="right-side">
                        <h5 class="right-side-title font-base">
                            Lesson was divided in... 
                            <!-- <button (click)="resetVariants()"></button> -->
                        </h5>
                        <div class="ratings flex-column sm:flex-row">
                            <!-- [disabled]="mode === 'view' ? true : false" -->
                            <div *ngFor="let rating of ratings; let i = index" class="checkbox col-3 flex gap-1">
                                <p-checkbox [binary]="true" name="groupname" [value]="ratings[i].checked"
                                    [(ngModel)]="ratings[i].checked"
                                    [disabled]="mode === 'view' ? true : false"
                                    (onChange)="onCheckboxChange($event,rating)"></p-checkbox>
                                <span class="font-sm">{{rating.category}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="ratings" style="width: 350px">
                        <canvas id="1" width="1" height="1"></canvas>
                    </div> -->
                </div>
                <div class="classroom-rating-bar" [ngClass]="{'pointer-events-none opacity-70': mode === 'view'}">
                    <app-multi-handle-slider [variants]="variants" [prop]="'value'" [colorList]="colors">
                    </app-multi-handle-slider>
                </div>
                <div *ngIf="mode!='view'" class="classroom-rating-bar">
                </div>
            </div>
            <hr class="style-three">
            <!-- {{arrayValue | json}} -->
            <div *ngFor="let value of arrayValue; let i = index" class="rating">
                <div class="rating-content mt-2">
                    <div class="left-side font-sm">
                        {{value.user.firstName}} {{value.user.lastName | slice: 0:1}}.
                    </div>
                    <div class="right-side">
                        <div class="font-sm" [hidden]="arrayValue.length === 1">
                            <div [ngClass]="{'pointer-events-none': mode === 'view'}" class="right-side-title">
                                Absent
                            </div>
                            <div [ngClass]="{'pointer-events-none opacity-70': mode === 'view'}" class="block-gradient inline-flex px-3 py-1 border-round-xl">

                                <div class=" flex gap-2 font-xs">
                                <div class="p-field-radiobutton flex gap-2">
                                    <p-radioButton [value]="true"
                                        [(ngModel)]="value.lessonUserRating.absent"></p-radioButton>
                                    <label for="city3">Yes</label>
                                </div>
                                <div class="p-field-radiobutton flex gap-2">
                                    <p-radioButton [value]="false"
                                        [(ngModel)]="value.lessonUserRating.absent"></p-radioButton>
                                    <label for="city3">No</label>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="right-side-title m-t-30 font-sm" [ngClass]="{'hidden': mode === 'view' && value.lessonUserRating.absent}">
                            Lesson Rating
                        </div>
                        <!-- {{value.lessonUserRating | json}} -->
                        <div *ngIf="mode=='view'" class="flex flex-column">
                        </div>
                        <div class="student-ratings" *ngIf="!value.lessonUserRating.absent">
                            <div *ngFor="let variant of variants" class="student-rating">
                                <ng-container>
                                    <div class="student-rating-title font-xs">
                                        {{variant.rating.category}}
                                    </div>
                                    <div class="w-100" style="position: relative;" [ngClass]="{'pointer-events-none  opacity-70': mode === 'view'}">
                                        <span>{{variant.category}}</span>

 <app-lesson-rating-range-slider 
 [rating]="value.lessonUserRating[variant.rating.category.toLowerCase()]"
 [category]="variant.rating.category.toLowerCase()"
 (categoryRatingChanged)="onCategoryRatingChanged($event, arrayValue[i], i)">
</app-lesson-rating-range-slider>
<!-- <div class="range-slider relative">
    <span class="rs-bullet rs-label">{{value.lessonUserRating[variant.rating.category.toLowerCase()]}}</span>
<div class="rs-range-wrap relative w-full">
    <input class="rs-range rs-range-line" type="range" 
            [(ngModel)]="value.lessonUserRating[variant.rating.category.toLowerCase()]" min="0" 
            step="1" max="10" 
            (input)="showSliderValue(
            $event.target.parentElement.parentElement.querySelector('.rs-bullet'), 
            $event.target)">

</div>
</div> -->
<div class="slider-values">
    <div class="slider-value text-left font-2xs ml-1">1</div>
    <div class="slider-value font-2xs">5</div>
    <div class="slider-value text-right font-2xs">10</div>
</div>                  

                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="(variants.length===0) && mode !== 'view'" class="guides font-xs">
                                Please check the checkboxes in the CLASSROOM rating section so you can rate your
                                student.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        <div *ngIf="mode!='view'" class="submit-button" (click)="saveRating()">
            <button pButton pRipple type="button" label="Submit" icon="pi pi-check-circle" iconPos="right"
                    class="p-button-raised p-button-rounded"></button>
            <!-- <div class="light-purple-button"></div> -->
        </div>
        </div>
    </div>
    <div *ngIf="" class="submit-button" (click)="download(allRating)">
        <div class="light-purple-button">Download</div>
    </div>

</div>