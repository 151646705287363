<div class="px-4 py-8 md:px-6 lg:px-8 text-center">
    <div class="mb-4 font-bold text-xl"><span class="text-900">For any inquiries please contact us</span></div>
    <div class="text-700 mb-6">

        <button (click)="generalService.openExternalLink('https://www.mylingotrip.com/contact')" pRipple pbutton="" pripple="" label="Join Now" icon="pi pi-discord"
            class="p-element p-ripple font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap p-button p-component">
            <span class="p-button-icon p-button-icon-right" aria-hidden="true"></span>
            <span class="p-button-label" >Get in touch</span>
            <img class="contact-icon-img" src="/assets/icons/contact-us-face-icon.svg" />
        </button>

    </div>
</div>