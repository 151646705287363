import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LayoutService } from 'src/app/core/services/layout.service';
import { FormControl, FormGroup } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-goal-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class StepsComponent implements OnInit {
  formGroup?: FormGroup;
  public form: UntypedFormGroup = new UntypedFormGroup({});

  @ViewChild('goalsStepsWrapper', { static: true }) goalsStepsWrapper: ElementRef = {} as ElementRef;
  constructor(private layoutService: LayoutService) { }

  activeIndex = 0;
  completed=0;
  steps = [
    {label: 'Your level'},
    {label: 'Desired level'},
    {label: 'When'},
    {label: 'How'},
    {label: 'Why'},
    {label: 'Areas of improvement'}
  ];

  selectedCurrentLevel: any = null;
  levels: any[] = [
    { name: 'A1', key: 'A1' },
    { name: 'A2', key: 'A2' },
    { name: 'B1', key: 'B1' },
    { name: 'B2', key: 'B2' },
    { name: 'C1', key: 'C1' },
    { name: 'C2', key: 'C2' },
    { name: 'BS', key: 'BS' }
  ];

  hours: any[] = [];
  selectedHours: any = null;

  selectedReasons: any[] = [];
  reasons_1st_col: any[] = [
    "For educational / academic reasons", 
    "For work, to boost my career", 
    "To obtain a certificate / pass an exam", 
    "Business English", 
  ]
  reasons_2nd_col: any[] = [
    "I’m moving abroad / fresh start in another country",
    "As a hobby", 
    "For traveling reasons (communicating with the locals)",  
    "To understand other cultures better"
  ]

  selectedAreas: any[] = [];
  areas_1st_col: any[] = [
    "Reading", 
    "Writing", 
    "Listening", 
    "Speaking", 
  ]
  areas_2nd_col: any[] = [
    "Pronunciation",
    "Grammar", 
    "Vocabulary",  
    "Business English"
  ]
  ngOnInit(): void {
    this.layoutService.sideMenuHeight.subscribe((res) => {
      this.goalsStepsWrapper.nativeElement.style.height = (res + 'px');
    });
    this.hours = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10+"];
  }
  ngAfterViewInit(): void {
    const lastStepItem = document.querySelector('ul[role="tablist"] li:last-child a');
    if (lastStepItem instanceof HTMLElement) {
      lastStepItem.innerHTML = '<span class="p-steps-number">6</span><span class="p-steps-title text-center ng-star-inserted">Areas of<br> improvement</span>';
      lastStepItem.style.paddingTop = '20px';
    }
  }

  //? Methods
  nextStep() {
    if (this.activeIndex < this.steps.length - 1) {
      this.activeIndex++;
    }
  }

  prevStep() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
  }

  initializeForm() {
    this.form = new UntypedFormGroup({
      currentLevel: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      desiredLevel: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      desiredAchieveDate: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      hoursWeekly: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      language: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      areas: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      reasons: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      teacherId: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
    });
  }

  private validate(): void {
    if (this.form.invalid) {
      for (const control of Object.keys(this.form.controls)) {
        this.form.controls[control].markAsTouched();
      }
      return;
    }
  }

  submit() {
    console.log(this.form.value);
  }
}
