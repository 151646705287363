
<ng-container *ngIf="!isMobile">
    <button (click)="buttonClicked()" pButton pRipple type="button" [label]="label" icon="pi pi-angle-left"
iconPos="left" class="p-button-outlined p-button-sm p-2 px-3 p-button-rounded text-white"
styleClass=""></button>
</ng-container>
<ng-container *ngIf="isMobile">
    <button (click)="buttonClicked()" pButton pRipple type="button" [label]="" icon="pi pi-angle-left"
    iconPos="left" class="p-button-outlined p-button-sm p-1 px-3 p-button-rounded text-white"
    styleClass=""></button>
</ng-container>