<div class="classroom-card relative w-100 " [ngClass]="getStatusColor + '-gradient-lesson-bg' + ' ' + extraClass">
  <div class="top-title relative border-round-2xl " [ngClass]="getStatusColor + '-gradient-lesson-bg'">
    <div class="circle-icon" [ngClass]="getStatusColor + '-gradient-lesson-bg'">
      <img [src]="'/assets/icons/lessons/' + getStatusIcon() + '.svg'" alt="Classroom Icon">
    </div>
    <div>
      <div class="center-title">
        <h4 class="font-semiBold m-0">{{ isTrial ? '' : 'Online' }} {{ generalService.capitalize(classRoom.language!) }} {{ isTrial ? ' Trial' : '' }} {{isTrial ? 'Lesson' : 'Course'}}</h4>
      </div>
      <div class="left-text left-text-mb">
        <div class="left-text-inner" tooltipPosition="top" [pTooltip]="generalService.getPersonFullName(classRoom.teacher)">
          <p>Teacher <b>{{generalService.getShortFullName(classRoom.teacher)}}</b></p>
        </div>
      </div>
    </div>
    <div class="right-text" *ngIf="classRoom.status === classStatus.DISMISSED">
      <div class="left-text-inner">
        <div class="gray-border-button px-2">Unfreeze Package  
          <img class="blue-filter ml-2 max-w-1rem" src="/assets/icons/lessons/frozen-blue.svg" />
        </div>
      </div>
    </div>
  </div>

  <div class="mx-3 mb-3 pb-2">
    <div class="grid pt-2 white-wrapper">
      <div class="col-12 md:col-4 px-0 lg:ml-1 lg:mr-2 px-2 lg:px-0">
        <div class="card column-bg-gradient text-center">
          <div class="card-title font-base py-2">Class Overview</div>
          <div class="mob-flex flex-column md:flex-row">
            <div class="card-header">
              <ng-container *ngIf="!isTrial">
              <div class="flex justify-content-center">
                <div class="progress {{getStatusColor}} " [attr.data-percentage]="classroomPercentage">
                  <span class="progress-left">
                    <span class="progress-bar"></span>
                  </span>
                  <span class="progress-right">
                    <span class="progress-bar"></span>
                  </span>
                  <div class="progress-value">
                    <div class="text-primary">
                      {{classroomPercentage}}%<br>
                      <span>completed</span>
                    </div>
                  </div>
                </div>
            </div>
            </ng-container>

          <ng-container *ngIf="isTrial">
              <div class=" flex justify-content-center">
                <div class="avatar-circle">
                  <img [src]="generalService.getDomainFileNamePath(classRoom.teacher.avatarUrl)"  (error)="generalService.setDefaultUserAvatar($event)" width="130" alt="Classroom Image">
                </div>
              </div>
          </ng-container>
            </div>
            <div class="card-body ">
              <div class="grid">
                <div class="col-12 flex flex-wrap flex-column justify-content-center align-items-center lg:px-4">
                  <ul class="list-none p-0 m-0 mt-2 w-100">
                    <li class="flex align-items-center mb-1 surface-border flex-wrap">
                      <div class="font-sm w-2 md:w-4 text-right pr-1">Status</div>
                      <div class="flex md:w-8 md:flex-order-0 flex-order-1">
                        <p-chip class=" info ml-2 mb-chip">
                          <div class="content font-sm capitalize">{{ isTrial ? ' ' : '' }} {{classRoom.status}}</div>
                        </p-chip>
                      </div>
                    </li>
                    <li class="flex align-items-center mb-1 surface-border flex-wrap">
                      <div class="font-sm w-2 md:w-4 text-right pr-1">Level</div>
                      <div class="flex md:w-8 md:flex-order-0 flex-order-1">
                        <p-chip class=" info ml-2 mb-chip">
                          <div class="content font-sm capitalize">
                            {{classRoom.language ? generalService.capitalize(classRoom.language!) : ''}} 
                            {{classRoom.activeLevel ? classRoom.activeLevel : 'Pending'}}</div>
                        </p-chip>
                      </div>
                    </li>
                    <li *ngIf="!isTrial" class="flex align-items-center mb-1 surface-border flex-wrap">
                      <div class="font-sm w-2 md:w-4 text-right pr-1">Total Hours</div>
                      <div class="flex md:w-8 md:flex-order-0 flex-order-1">
                        <p-chip class=" info ml-2 mb-chip">
                          <div class="content font-sm">{{classroomService.getAccumulatedTotalHours(this.classRoom.packages!)}} hours</div>
                        </p-chip>
                      </div>
                    </li>
                    <li *ngIf="!isTrial" class="flex align-items-center mb-1 surface-border flex-wrap">
                      <div class="font-sm w-2 md:w-4 text-right pr-1">Hours Left</div>
                      <div class="flex md:w-8 md:flex-order-0 flex-order-1">
                        <p-chip class=" info ml-2 mb-chip">
                          <div class="content font-sm">{{
                            generalService.convertHoursToMinutesWithSuffix(classroomService.getAccumulatedHoursLeft(this.classRoom.packages!), true)}}</div>
                        </p-chip>
                      </div>
                    </li>
                    <li *ngIf="isTrial" class="flex align-items-center mb-1 surface-border flex-wrap">
                      <div class="font-sm w-2 md:w-4 text-right pr-1">Teacher</div>
                      <div class="flex md:w-8 md:flex-order-0 flex-order-1">
                        <p-chip class=" info ml-2 mb-chip">
                          <div class="content font-sm">{{generalService.getShortFullName(classRoom.teacher)}}</div>
                        </p-chip>
                      </div>
                    </li>
                    <li *ngIf="isTrial" class="flex align-items-center mb-1 surface-border flex-wrap">
                      <div class="font-sm w-2 md:w-4 text-right pr-1">Duration</div>
                      <div class="flex md:w-8 md:flex-order-0 flex-order-1">
                        <p-chip class="info ml-2 mb-chip">
                          <div class="content font-xs">{{classroomService.getAccumulatedHoursLeft(classRoom.packages!)}}h</div>
                        </p-chip>
                        
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 md:col lg:px-0">
        <div class="card column-bg-gradient">
          <div class="card-header">
            <div class="card-title font-base py-2" *ngIf="ongoingOrLastPackageWithHoursLeft">
              {{ ongoingOrLastPackageWithHoursLeft.type === classStatus.TRIAL ? 'Trial' : 'Package' }} Summary</div>
          </div>
          <div class="card-body" *ngIf="ongoingOrLastPackageWithHoursLeft">
            <ul class="list-none px-0 m-0 info-list-middle mx-1 lg:mx-3 py-2">
              <li class="flex align-items-center py-1 surface-border flex-wrap font-xs">
                <div class="w-6 md:w-6 text-right lg:text-center font-xs">Package Type</div>
                <div class="pl-2 md:w-6 md:flex-order-0 flex-order-1 text-primary font-semiBold capitalize">
                  {{ ongoingOrLastPackageWithHoursLeft.type === classStatus.TRIAL ? 'Free Trial' : ongoingOrLastPackageWithHoursLeft.type }}</div>
              </li>
              <li class="flex align-items-center py-1 surface-border flex-wrap font-xs">
                <div class="w-6 md:w-6 text-right lg:text-center font-xs">Purchase Date</div>
                <div class="pl-2 md:w-6 md:flex-order-0 flex-order-1 text-primary font-semiBold">{{ ongoingOrLastPackageWithHoursLeft.purchasedAt | date: 'MMM d, y' }}</div>
              </li>
              <li class="flex align-items-center py-1 surface-border font-xs flex-wrap">
                <div class="w-6 md:w-6 text-right lg:text-center">Expiration Date</div>
                <div class="pl-2 md:w-6 md:flex-order-0 flex-order-1 text-primary font-semiBold">{{ ongoingOrLastPackageWithHoursLeft.expiresOn | date: 'MMM d, y' }}</div>
              </li>
            </ul>
            <div class="py-3 flex align-items-center justify-content-center">
              <ng-container  *ngIf="showRateButton">
              <button [disabled]="!classroomService.isTrialClassroomWithCompletedLesson(classRoom)" pButton type="button" 
              class="p-button-outlined p-button-rounded button-outlined p-button-sm"
              [ngClass]="{'disabled-link':  hasRated}" 
              [pTooltip]="hasRated ? 'Already rated by student' : ''"
              tooltipPosition="top"
              [routerLink]="classroomService.getClassroomRateUrl(classRoom)">
                <div class="flex align-items-center gap-1">
                  <span class="text-base s12-16">Rate your experience</span>  <img src="/assets/icons/lessons/rating-stars.svg" height="16" />
                </div>
               
              </button>
            </ng-container>
            </div>
            <!-- <div class="flex w-100">
              <button class="extend-backage w-100 border-noround" pButton type="button" label="EXTEND Package"></button>
            </div> -->
            <div class="flex w-100">
              <button (click)="generalService.navigateToBuyPackage(classRoom)" class="gradient-blue-package w-100 border-noround font-xs mt-2" pButton type="button" label="Buy Package"></button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 md:col-4 lg:mr-1 lg:ml-2 lg:px-0 mb-height">
        <div class="card column-bg-gradient h-full pr-0 sm:pr-0 lessons-height mb-4 sm:mb-0">
          <div class="card-body flex justify-content-center grid grid-nogutter w-full">
            <!-- <p-scrollPanel [style]="{width: '100%', height: '300px'}" styleClass="custombar1"> -->
            <div class=" flex flex-wrap pt-1">
              <div class="col-6 sm:col-4 md:col-12 lg:col-6 p-2 flex justify-content-center lg:justify-content-start"
              [pTooltip]="!classroomService.hasClassroomPackageHoursLeft(classRoom) ? 'No available hours left in classroom': ''" tooltipPosition="top"
              >
              <app-mini-lesson-info-card [ngClass]="{'disabled-link': classroomService.getAccumulatedHoursLeft(classRoom.packages!) <= 0 }" cardType="request" [classroom]="classRoom"></app-mini-lesson-info-card>
              </div>
              <ng-container *ngIf="classRoom.lessons!.length > 0">
                <ng-container *ngFor="let lesson of getFirstThreeRecentLessons(); let i = index;">
                  <div class="col-6 sm:col-4 md:col-12 lg:col-6 p-2 flex justify-content-center lg:justify-content-start">
                    <app-mini-lesson-info-card [lesson]="lesson" [classroom]="classRoom"></app-mini-lesson-info-card>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <!-- </p-scrollPanel> -->
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-button">
      <!-- <button pButton type="button" styleClass="" class="p-button-rounded button-rounded action-btn" [ngClass]="color + '-gradient-lesson-bg'">
        Go to Classroom
      </button> -->
      <g-button class="font-base w-10 width-30 py-1 m-auto {{ getStatusColor + '-gradient-lesson-bg' }} " text="Go to Classroom" (click)="goToLessonDetails()"></g-button>    </div>
  </div>
</div>