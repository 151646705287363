import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, Injector, signal } from '@angular/core';
import { ButtonModule } from "primeng/button";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { ChatService } from "src/app/core/services/chat.service";
import { LibraryModule } from "src/app/modules/library/library.module";
import { SingleLibraryComponent } from "src/app/modules/library/library/components/single-library/single-library.component";
import { SharedModule } from "src/app/shared/shared.module";
import { toObservable } from '@angular/core/rxjs-interop';
import { LibraryService } from "src/app/core/services/library.service";

@Component({
    selector: 'app-chat-upload-file-dialog',
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        SharedModule,
        LibraryModule,
    ],
    templateUrl: './chat-upload-file-dialog.component.html',
    styleUrl: './chat-upload-file-dialog.component.css',
})
export class ChatUploadFileDialogComponent {
    chatService = inject(ChatService);
    libraryService = inject(LibraryService);
    dialogData: any = {};
    showUploadFile = false;
    checkedFilesToUpload = signal([] as any[]);
    private injector = inject(Injector);
    constructor(
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
    ) { }

    ngOnInit() {
        this.chatService.showUploadFile.set(true);
        this.dialogData = this.config.data.dialogData;
        console.log(this.dialogData);
        this.checkedFilesToUpload.set([]);

        toObservable(this.libraryService.checkedLibraryFiles, {
            injector: this.injector
          }).subscribe({
            next: (data) => {
              console.log(data);
              this.checkedFilesToUpload.set(data);
            }
          });
    }

    onSubmitFilesToUpload() {
        this.ref.close(this.checkedFilesToUpload());
        this.checkedFilesToUpload.set([]);
        this.ref.destroy();
        this.chatService.showUploadFile.set(false);
    }

    onDialogClose(data?: any) {
        alert(data);
        this.chatService.showUploadFile.set(false);
        this.libraryService.checkedLibraryFiles.set([]);
        this.checkedFilesToUpload.set([]);
        this.ref.close(this.ref);
        this.ref.destroy();
    }

    ngOnDestroy() {
        this.chatService.showUploadFile.set(false);
        this.checkedFilesToUpload.set([]);
        this.ref.destroy();
    }


}
