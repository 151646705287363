import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, ObservableInput, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Classroom, ClassroomStudentsResponse, ClassroomTeacherRating, RequestNewTeacher, TrialTeacherRating } from '../models/classroom.model';
import { StudentExperience } from '../models/experience.model';
import { Category } from '../models/homework.model';
import { Lesson } from '../models/lesson.model';
import { LessonFullRating, LessonBreakdown, LessonUserRating, RatingItem } from '../models/rating.model';
import { CreateReportRequest, getReportRequest, Report, Statistic } from '../models/report.model';
import { User } from '../models/user.model';
import { ClassroomService } from './classroom.service';
import { GeneralService } from './general.service';
import { HomeworkService } from './homework.service';
import { LessonService } from './lesson.service';
import { UserService } from './user.service';
const BACKEND_URL = environment.apiUrl;
const BACKEND_URL_LMS = environment.apiUrl + "/LMS/";


@Injectable({
  providedIn: 'root'
})
export class RatingAndReportService {
  public readonly ratings: RatingItem[] = [
    {
      id: "r1",
      color: "#362FBB",
      category: Category.VOCABULARY
    },
    {
      id: "r2",
      color: "#758BFA",
      category: Category.GRAMMAR
    },
    {
      id: "r3",
      color: "#00B2AE",
      category: Category.WRITING
    },
    {
      id: "r4",
      color: "#9DC6B6",
      category: Category.READING
    },
    {
      id: "r5",
      color: "#0076CD",
      category: Category.LISTENING
    },
    {
      id: "r6",
      color: "#00639A",
      category: Category.SPEAKING
    },
    {
      id: "r7",
      color: "#D094EA",
      category: Category.REVISION
    },
    {
      id: "r8",
      color: "#A44FD0",
      category: Category.TEST
    },
  ]

  public readonly statistics = [Statistic.DIVISION, Statistic.OVERVIEW, Statistic.PROGRESS, Statistic.HOMEWORK]

  public readonly presets = [
    "Student seemed interested in the lesson.",
    "Student did not seem interested into the lesson.",
    "Student looked motivated.",
    "Student did not look motivated.",
    "Student has made progress.",
    "Student did not make a lot of progress.",
    "Student had studied for the lesson.",
    "Student hadn’t studied enough for the lesson.",
    "Student did his / her homework.",
    "Student didn’t do his / her homework.",
    "Student did some of his / her homework.",
    "Student was better at Grammar than he / she was at Vocabulary.",
    "Student was better at Grammar than he / she was at Reading.",
    "Student needs to do more Listening tests, but he / she has improved his / her speaking skills.",
    "Student is able to understand and respond to simple questions.",
    "Student is able to structure sentences for everyday conversation.",
    "Student can identify and explain key information, but lacks few details depending on the difficulty of the conversation.",
    "Student takes a lot of notes during the lesson, but sometimes seems to lose focus.",
    "Student listens and interacts during the lesson, but shows slower progress than expected.",
    "Student demonstrates accurate, detailed knowledge of the topic he / she is talking about.",
    "Student has made significant progress in Listening, and is showing great effort in Writing.",
    "Student can now write a relevant, inclusive, informative text with rich vocabulary.",
    "Student had been inconsistent with his / her homework at first, but now he / she has made a lot of progress and never misses an assignment.",
    "Student is more fluent in topics such as Culture and Traveling, and less fluent in topics such as Business and Economics.",
    "Student is able to listen to understand what the teacher is saying, and is making progress in forming a proper, coherent reply.",
    "Student has expressed that he / she wants to focus more on Grammar and Vocabulary because he / she feels there is a bigger gap there.",
    "Student seems to be better at quiz tests than long answer tests.",
    "Student has achieved a very satisfactory level of Listening and Speaking, and can now engage fully in a conversation about different topics.",
    "Student is still in need of intensive studying, but is showing great focus and motivation during class and is able to comprehend basic phrases."
  ]

  private updateListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public updateListener: Observable<boolean> = this.updateListener$.asObservable();

  public ratingIsOpen: boolean = false;
  constructor(
    private http: HttpClient,
    private classroomService: ClassroomService,
  ) { }

  setUpdateListener(listen: boolean) {
    this.updateListener$.next(listen)
  }

  getRatingColor(rating: string) {
    let color = this.ratings.filter((el: RatingItem) => { return el.category == rating })[0].color;
    return color
  }

  createEndOfLessonRatings(lessonUserRatings: LessonUserRating[], LessonBreakdown: LessonBreakdown) {
    return this.http.post(BACKEND_URL + "/Lesson/CreateEndOfLessonRatings", { userRatings: lessonUserRatings, lessonBreakdown: LessonBreakdown })
  }

  updateEndOfLessonRatings(lessonUserRatings: LessonUserRating[], LessonBreakdown: LessonBreakdown) {
    return this.http.post(BACKEND_URL + "/Lesson/UpdateEndOfLessonRatings", { userRatings: lessonUserRatings, lessonBreakdown: LessonBreakdown })
  }

  deleteEndOfLessonRatings(userRatingsIds: number[], lessonBreakdownId: number) {
    return this.http.post(BACKEND_URL + "/Lesson/DeleteEndOfLessonRatings", { userRatingsIds: userRatingsIds, lessonBreakdownId: lessonBreakdownId })
  }

  getClassroomRatings(classroom: Classroom): Observable<any> {
    const lessons = classroom.lessons;
    return forkJoin(lessons!.map(lesson => {
      return this.getRatingExtras(lesson, classroom) as any;
    }))
  }

  getRatingExtras(lesson: Lesson, classroom: Classroom) {
    return this.getLessonBreakDown(lesson.id)
      .pipe(
        switchMap((LessonBreakdown: LessonBreakdown[]) => {
          let lessonBreakdown = LessonBreakdown[0];
          if (lessonBreakdown) {
            lessonBreakdown.dateCreated = new Date(lessonBreakdown.dateCreated);
          }
          let t: LessonFullRating = {
            title: this.classroomService.getUsersTitle(classroom.classroomStudents),
            lesson: lesson,
            lessonBreakdown: lessonBreakdown,
            userRatings: []
          }
          return forkJoin(classroom.classroomStudents.map((user: User) => {
            return this.getUserLessonRatings(user.id, lesson.id)
              .pipe(
                map((el: LessonUserRating[]) => {
                  for (let e of el) {
                    if (e.studentId == user.id) {
                      e.student = user
                      t.userRatings.push(e)
                    }
                  }
                  return t
                })
              )
          }))
        })
      )
  }

  getUserLessonRatings(userId: string, lessonId: number): Observable<LessonUserRating[]> {
    return this.http.get<LessonUserRating[]>(BACKEND_URL + `/Lesson/GetUserLessonRatings?userId=${userId}&lessonId=${lessonId}`)
  }

  getLessonBreakDown(lessonId: number): Observable<LessonBreakdown[]> {
    return this.http.get<LessonBreakdown[]>(BACKEND_URL + `/Lesson/GetLessonBreakdown?lessonId=${lessonId}`)
  }

  submitTeacherRating(rating: ClassroomTeacherRating) {
    return this.http.post<ClassroomTeacherRating>(BACKEND_URL_LMS + `SubmitClassroomTeacherRating`, rating)
  }

  submitTeacherTrialRating(rating: TrialTeacherRating) {
    return this.http.post<TrialTeacherRating>(BACKEND_URL + `/LMS/SubmitTrialTeacherRating`, rating)
  }

  getTeacherTrialRating(ClassroomId: number) {
    return this.http.get<TrialTeacherRating>(BACKEND_URL + `/LMS/GetTrialTeacherRating?ClassroomId=` + ClassroomId);
  }

  submitStudentExperience(experience: StudentExperience) {
    return this.http.post<ClassroomTeacherRating>(BACKEND_URL + `/Reports/SubmitStudentExperience`, experience)
  }

  submitRequestTeacher(request: RequestNewTeacher) {
    return this.http.post<ClassroomTeacherRating>(BACKEND_URL + `/Teacher/RequestNewTeacher`, request)
  }

  getTeacherRatingEvaluations(classroomId: string) {
    return this.http.get<any[]>(BACKEND_URL_LMS + `GetClassroomTeacherRating?ClassroomId=${classroomId}`)
      .pipe(map(ratings => {
        return ratings.map(rating => {
          return rating
        })
      }))
  }

  getLMSClassroomTeacherRating(classroomId: string) {
    return this.http.get<any[]>(BACKEND_URL_LMS + `GetClassroomTeacherRating?ClassroomId=${classroomId}`);
  }

  getClassroomReport(reportReq: getReportRequest) {
    return this.http.post(BACKEND_URL + "/Reports/GetClassroomReportData", reportReq)
  }

  getClassroomReports(classroom: Classroom): Observable<CreateReportRequest[]> {
    return this.http.post<CreateReportRequest[]>(BACKEND_URL + "/Reports/GetClassroomReports?ClassroomId=" + classroom.id, {})
  }

  saveReport(request: CreateReportRequest) {
    return this.http.post(BACKEND_URL + "/Reports/CreateClassroomReport", request)
  }

  addPreset(preset: string) {
    return this.http.post(BACKEND_URL + '/Reports/AddPreset', {preset: preset})
  }

  getPresets(){
    return this.http.get(BACKEND_URL +"/Reports/GetReportPresets")
  }
}
