import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BBB, Lesson, LessonPostRequest, LessonStatus } from '../models/lesson.model';
import { LessonBreakdown, LessonUserRating } from '../models/rating.model';
import { UserService } from './user.service';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { User, UserRole } from '../models/user.model';
import { Classroom, ClassroomType, Level, Status } from '../models/classroom.model';
import { ClassroomService } from './classroom.service';
import { AuthService } from './auth.service';
import { GeneralService } from './general.service';

const BACKEND_URL = environment.apiUrl;
const BACKEND_URL_LMS = environment.apiUrl + "/LMS";

@Injectable({
  providedIn: 'root'
})
export class LessonService {

  private addListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly addListener: Observable<boolean> = this.addListener$.asObservable();

  private updateListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly updateListener: Observable<boolean> = this.updateListener$.asObservable();

  private deleteListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly deleteListener: Observable<boolean> = this.deleteListener$.asObservable();

  private lessonToRateListener$: BehaviorSubject<Lesson> = new BehaviorSubject<Lesson>({} as Lesson);
  public readonly lessonToRateListener: Observable<Lesson> = this.lessonToRateListener$.asObservable();

  public sessionAllUserLessons: Lesson[] = [];

  public lessonToReschedule: Lesson = {} as Lesson

  constructor(
    private http: HttpClient,
    private classroomService: ClassroomService,
    private authService: AuthService,
    private generalService: GeneralService
  ) {

  }

  create(lesson: LessonPostRequest) {
    return this.http.post<Lesson>(BACKEND_URL_LMS + "/CreateLesson", lesson)
  }

  setAddListener(add: boolean) {
    this.addListener$.next(add);
  }

  createRecurring(lessons: LessonPostRequest[]) {
    return this.http.post<LessonPostRequest[]>(BACKEND_URL + "/Lesson/CreateLessons", lessons)
  }

  update(lesson: LessonPostRequest) {
    return this.http.post<Lesson>(BACKEND_URL_LMS + "/UpdateLesson", lesson)
  }

  setUpdateListener(update: boolean) {
    this.updateListener$.next(update);
  }

  setLessonRatingListener(lesson: Lesson) {
    this.lessonToRateListener$.next(lesson);
  }

  delete(lessonId: number) {
    return this.http.post<boolean>(BACKEND_URL + "/Lesson/DeleteLesson?lessonId=" + lessonId, {})
  }

  setDeleteListener(del: boolean) {
    this.deleteListener$.next(del);
  }

  // public getClassroomLessons2(classroomId: string): Observable<Lesson[]> {
  //   return this.http.get<Lesson[]>(BACKEND_URL + "/Lesson/GetClassroomLessons?classroomId=" + classroomId).pipe(
  //     map(lesson => lesson.map(l => {
  //       l.startingDate = new Date(l.startingDate);
  //       return l;
  //     }))
  //   )
  // }

  public getClassroomLessons(classroomId: string): Observable<Lesson[]> {
    return this.http.get<Lesson[]>(BACKEND_URL + "/Lesson/GetClassroomLessons?classroomId=" + classroomId)
      .pipe(
        switchMap((lessons: Lesson[]) => {
          return this.getLessonExtraDetails(lessons, "accepted")
        })
      )
  }

  public getAllUserLessons(): Observable<Lesson[]> {
    return this.http.get<Lesson[]>(BACKEND_URL_LMS + "/GetUserLessonsFullHistory")
      .pipe(
        switchMap((lessons: Lesson[]) => {
          return this.getLessonExtraDetails(lessons, "accepted")
        })
      )
  }

  public getLMSAllUserLessons(headers: HttpHeaders): Observable<Lesson[]> {
    return this.http.get<Lesson[]>(BACKEND_URL_LMS + "/GetUserLessonsFullHistory", {headers});
  }

  public getUserLessonsFullHistory(): Observable<Lesson[]> {
    return this.http.get<Lesson[]>(BACKEND_URL + "/Lesson/GetUserLessonsFullHistory");
  }

  public getUserLessonsHistory(classroomId: string): Observable<Lesson[]> {
    return this.http.get<Lesson[]>(BACKEND_URL_LMS + "/GetUserLessonsHistory?ClassroomId=" + classroomId);
  }

  public getTodaysLessons(): Observable<Lesson[]> {
    return this.http.get<Lesson[]>(BACKEND_URL + "/Lesson/GetTodaysLessons")
  }

  public getRequestedLessons(): Observable<Lesson[]> {
    return this.http.get<Lesson[]>(BACKEND_URL + "/Lesson/GetUserLessonsFullHistory")
      .pipe(
        switchMap((lessons: Lesson[]) => {
          return this.getLessonExtraDetails(lessons, "requested")
        })
      )
  }

  public getAllReccuringLessons(): Observable<Lesson[]> {
    return this.http.get<Lesson[]>(BACKEND_URL + "/Lesson/GetAllUserReccurringLessons")
      .pipe(
        switchMap((lessons: Lesson[]) => {
          return this.getLessonExtraDetails(lessons, "accepted")
        })
      )
  }

  public getClassroomReccuringLessons(classroomId: string): Observable<Lesson[]> {
    return this.http.get<Lesson[]>(BACKEND_URL + "/Lesson/GetClassroomReccurringLessons?classroomId=" + classroomId)
      .pipe(
        switchMap((lessons: Lesson[]) => {
          return this.getLessonExtraDetails(lessons, "accepted")
        })
      )
  }

  compareFn(a: Lesson, b: Lesson) {
    if (a.startingDate.getTime() < b.startingDate.getTime())
      return -1;
    if (a.startingDate.getTime() > b.startingDate.getTime())
      return 1;
    return 0;
  };

  public getClassroomsWithReccuringLessons() {
    return this.classroomService.getUserClassrooms(ClassroomType.PAID)
      .pipe(
        switchMap((classrooms: Classroom[]) => {
          return forkJoin(classrooms.map((classroom: Classroom) => {
            return this.getClassroomReccuringLessons(classroom.id).pipe(
              map(x => {
                x.sort(this.compareFn)
                let classroomDetails: Classroom = ({ ...classroom, ...{ reccurringLessons: x } });
                return classroomDetails;
              }))
          }))
        })
      )
  }

  public getDaysOfRecurringLessonsOfClassroom(classroom: any) {
    let days: any[] = []
    for (let lesson of classroom.reccurringLessons) {
      let day = this.generalService.getDayFromNumber(lesson.startingDate.getDay())
      if (!days.some(el => el == day)) {
        days.push(day)
      }
    }
    return days
  }

  public getLessonExtrasValues(extras: any) {
    return {
      level: extras[0], title: extras[1].result, language: extras[2]
    }
  }

  public getLessonExtras(lessonId: number) {
    return forkJoin(
      this.getLessonLevel(lessonId),
      this.getLessonTitle(lessonId),
      this.getLessonLanguage(lessonId),
      // this.getLessonClassroom(lessonId),
    )
  }
  getLessonExtraDetailsPiped(lessons: Lesson[], type: string) {
    if (lessons.length === 0) {
      return of([])
    }
    if (this.authService.getUserRole() == UserRole.TEACHER) // will get the "requested in the modal"
      lessons = lessons.filter((lesson: Lesson) => lesson.answered === type)
    return forkJoin(lessons.map((lesson: Lesson) => {
      return this.getLessonExtras(lesson.id).pipe(
        map(x => {
          lesson.startingDate = new Date(lesson.startingDate);
          let lessonWithExtras: Lesson = ({ ...lesson, ...{ level: x[0], title: x[1].result, language: x[2] } });
          return lessonWithExtras;
        }))
    }))
  }

  getLessonExtraDetails(lessons: Lesson[], type: string) {
    if (lessons.length === 0) {
      return of([])
    }
    if (this.authService.getUserRole() == UserRole.TEACHER) // will get the "requested in the modal"
      lessons = lessons.filter((lesson: Lesson) => lesson.answered === type)
    for (let lesson of lessons) {
      lesson.startingDate = new Date(lesson.startingDate);
      lesson.classroom!.users = lesson.classroom!.classroomStudents
      lesson.level = lesson.classroom?.activeLevel
      lesson.title = this.classroomService.getClassroomTitle(lesson.classroom!)
    }
    return of(lessons)
  }

  public getLessonLevel(lessonId: number) {
    return this.http.get(BACKEND_URL + "/Lesson/GetLessonLevel?lessonId=" + lessonId, { responseType: 'text' })
  }

  public getLessonTitle(lessonId: number): Observable<{ result: string }> {
    return this.http.get<{ result: string }>(BACKEND_URL + "/Lesson/GetLessonTitle?lessonId=" + lessonId)
  }

  public getLessonLanguage(lessonId: number) {
    return this.http.get(BACKEND_URL + "/Lesson/GetLessonLanguage?lessonId=" + lessonId, { responseType: 'text' })
  }

  public getLessonClassroom(lessonId: number): Observable<[Classroom]> {
    return this.http.get<any>(BACKEND_URL + "/Lesson/GetLessonClassroom?lessonId=" + lessonId)
      .pipe(
        switchMap((classroom: Classroom) => {
          return forkJoin(
            this.classroomService.getClassroomStudents(classroom.id)
              .pipe(
                map((res) => {
                  return { ...classroom, users: res.usersDTOResult }
                })
              )
          )
        })
      )
  }

  public getLessonHoursForEachLevel(classroomId: string) {
    return this.http.get<any>(BACKEND_URL + "/Lesson/GetLessonHoursForEachLevel?ClassroomId=" + classroomId)
  }

  public getLessonHoursForEachStatus(classroomId: string) {
    return this.http.get<any>(BACKEND_URL + "/Lesson/GetLessonHoursForEachStatus?ClassroomId=" + classroomId)
  }

  getAllLessonsByLevelAndStatus(status: LessonStatus, level: Level, classroomId?: string) {
    let endpoint = classroomId
      ?
      "/Lesson/GetLessonByLevelAndStatus?ClassroomId=" + classroomId + "&Status=" + status + "&Level=" + level
      :
      "/Lesson/GetAllLessonsByLevelAndStatus?Status=" + status + "&Level=" + level
    return this.http.get<Lesson[]>(BACKEND_URL + endpoint)
      .pipe(
        switchMap((lessons: Lesson[]) => {
          return this.getLessonExtraDetails(lessons, "accepted")
        })
      )
  }

  public getStatusesNames(): string[] {
    return [LessonStatus.ARRANGED, LessonStatus.COMPLETED, LessonStatus.CANCELED, LessonStatus.NO_SHOW]
  }

  public getNextLesson(): Observable<Lesson> {
    return this.http.get<Lesson>(BACKEND_URL + "/Lesson/GetOverallNextLesson")
  }

  public createBBB(bbb: BBB) {
    return this.http.post<Lesson>(BACKEND_URL + "/BBB/CreateBBBClass", bbb)
  }

  public chargeLessonTime(lessonId: number, answer: boolean) {
    return this.http.post<any[]>(BACKEND_URL_LMS + "/ChargeLessonTime?lessonId=" + lessonId + "&answer=" + answer + "", {});
  }

  public getTimeToNextLesson(lesson: Lesson, timeZone: string) {
    const eventDay = moment(lesson.startingDate);
    let countdown;
      const today = moment(this.generalService.getCurrentTimeInTimezone(timeZone));
      const timeSpan = eventDay.diff(today);

      if (timeSpan <= 0) {
        countdown = {
          days: 0,
          hours: 0,
          minutes: 0
        };
        return countdown;
      }

      const duration = moment.duration(timeSpan);
      countdown = {
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes()
      };
      return countdown;
  }

  /**
   * Checks if the given status string contains the word "trial" (case-insensitive).
   * @param {string} statusString - The status string to check.
   * @returns {boolean} Whether the status string contains the word "trial".
   */
  hasTrialStatus(statusString: string): boolean {
    const lowercaseStatusString = statusString.toLowerCase();
    if (lowercaseStatusString.includes('trial')) {
      return true;
    }
    return false;
  }

  /**
   * Returns the corresponding status name for a given status string.
   * @param {string} statusString - The status string to get the name for.
   * @returns {string} The corresponding status name.
   */
  getStatusNameFromStatusString(statusString: string): string {
    const lowercaseStatusString = statusString.toLowerCase();
    if (lowercaseStatusString.includes('trial completed')) {
      return 'trial completed';
    } else if (lowercaseStatusString.includes('completed')) {
      return 'completed';
    } else if (lowercaseStatusString.includes('trial arranged')) {
      return 'trial arranged';
    } else if (lowercaseStatusString.includes('arranged')) {
      return 'arranged';
    } else if (lowercaseStatusString.includes('show')) {
      return 'no show';
    } else if (lowercaseStatusString.includes('canceled')) {
      return 'canceled';
    } else if (lowercaseStatusString.includes('request')) {
      return 'requested';
    } else {
      return 'arranged';
    }
  }

  sortLessonByDate(a: Lesson, b: Lesson, sortOrder: 'asc' | 'desc') {
    const dateA = moment(a.startingDate);
    const dateB = moment(b.startingDate);
  
    if (sortOrder === 'asc') {
      return dateA.diff(dateB);
    } else {
      return dateB.diff(dateA);
    }
  }

  /**
 * Returns the tooltip name of the teacher or classroom students for a given classroom.
 *
 * @param {Classroom} classroom - The classroom object.
 * @returns {string} The tooltip name of the teacher or classroom students.
 */
  getLessonRowTooltipName(classroom: Classroom): string {
    if (this.authService.isStudent) {
      return this.generalService.getPersonFullName(classroom.teacher);
    } else {
      return this.classroomService.getFullStudentNames(classroom.classroomStudents);
    }
  }

}
