<div id="notifications-overview" class="notifications-overview">
    <div class="surface-section">
        <div class="block-header relative justify-content-center  lg:flex">

            <div class="absolute pl-1 md:pl-2 left-0">
                
                <g-header-back-button routerLink="/dashboard" label="Back to Dashboard"></g-header-back-button>
                <!-- <button routerLink="/dashboard" pButton pRipple type="button" label="Back to Dashboard" icon="pi pi-angle-left" iconPos="left"
                     class="p-button-outlined p-button-sm p-button-rounded text-white" styleClass=""></button> -->
            </div>
            <span class="block-title">
                <span class="text-0">Notifications</span>
            </span>
        </div>
        <div class="surface-section px-2 py-2 md:px-26 lg:px-2 text-center">
            <button *ngIf="isTablet" (click)="toggleFilterVisibility()" pButton type="button" label=""
                class="p-button-text">
                <span class="p-button-icon p-button-icon-right" aria-hidden="true"></span>
                <span class="p-button-label mr-2">Filters</span>
                <img class="contact-icon-img" src="/assets/icons/notifications/notif-filter.svg" />
            </button>
        </div>
        <div class="grid w-full">
            <div class="col-12 lg:col-3 lg:pr-5">
                <div [hidden]="showFiltersTablet">
                    <span class="p-input-icon-right w-full">
                        <input type="text" pInputText placeholder="Search" class=" w-full input-blue rounded"
                            (input)="onSearchInput($event)" />
                        <i class="pi pi-search text-primary"></i>
                    </span>
                </div>
                <div class="lg:px-3 lg:pt-4" [hidden]="showFiltersTablet">
                    <h6 class="font-bold">Filter by category</h6>
                    <ul class="list-none p-0 m-0 flex-grow-1">
                        <ng-container *ngFor="let filterType of filterTypes;">
                            <li class="flex align-items-center mb-3 text-base" [hidden]="showFiltersTablet">
                                <span class="filter-link"
                                    [ngClass]="{'text-primary active font-bold': currentFilterType === filterType.type}"
                                    (click)="applyFilter(filterType.type)">
                                    {{filterType.name}}</span>
                            </li>
                        </ng-container>
                        <li class="flex align-items-start mb-3 text-base flex-column">
                            <span class="filter-link cursor-auto">Extras</span>
                            <span class="filter-link cursor-auto">From:</span>
                            <p-calendar [maxDate]="currentDate" [showIcon]="true" placeholder="DD/MM/YY"
                                dateFormat="dd/mm/yy" (onClose)="onCalendarClose($event)"
                                (onSelect)="onFromDateSelection($event)"></p-calendar>

                            <span class="filter-link cursor-auto">To:</span>
                            <p-calendar [maxDate]="currentDate" [showIcon]="true" placeholder="DD/MM/YY"
                                dateFormat="dd/mm/yy" (onSelect)="onToDateSelection($event)"></p-calendar>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 lg:col-9">
                <ul class="list-none p-0 m-0 max-h-30rem overflow-y-auto">
                    <ng-container *ngIf="(filteredNotifications.length === 0) && !isLoadingNotifications">
                        <div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
                            <img src="/assets/icons/notifications/bell.png" alt="bell" width="150" />
                            <div class="mt-3 font-bold text-xl">
                                <span class="text-900">You don’t have any notifications yet</span>
                            </div>
                        </div>
                    </ng-container>
                    
                    <ng-container *ngFor="let notif of filteredNotifications; let i=index;">
                        <app-notification-row-item 
                        [notification]="notif" 
                        [slimStyle]="false"
                        (removeNotification)="removeNotificationSelected(notif.id)">
                    </app-notification-row-item>
                    </ng-container>

                    
                </ul>
            </div>
        </div>
    </div>
</div>