import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface ConfirmDialogProperties {
  confirmMessage?: string;
  confirmIcon?: string;
  acceptBtnLabel?: string;
  rejectBtnLabel?: string;
  rejectBtnIcon?: string;
  acceptBtnIcon?: string;
  showHeader?: boolean;
  dismissableMask?: boolean;
  dialogType?: string;
  headerClass?: string;
  dialogKey?: string;
  action?: string;
  [key: string]: any;
};

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {
  isVisible = false;
  confirmMessage?: string;
  confirmIcon?: string;
  acceptBtnLabel?: string;
  rejectBtnLabel?: string;
  action?: string;
  rejectBtnIcon?: string = undefined;
  acceptBtnIcon?: string = undefined;
  showHeader?: boolean = true;
  dismissableMask?: boolean = true;
  dialogType?: string = 'header-with-image';
  headerClass?: string = 'text-center flex align-items-center flex-column ';
  dialogKey = 'key1';
  headerText?: string;
  acceptBtnClass?: string = ' '
  rejectBtnClass?: string = 'cd-dialog-btn-reject';
  extraData: any;
  private rejectSelectionSource = new Subject<any>();
  rejectSelected$ = this.rejectSelectionSource.asObservable();
  acceptSelectionSource = new Subject<any>();
  acceptSelected$ = this.acceptSelectionSource.asObservable();

  constructor() { }

  setProperties(props: ConfirmDialogProperties) {
    Object.keys(props).forEach((key) => {
      (this as any)[key as keyof ConfirmDialogService] = props[key];
    });
  }

  getProperties(): ConfirmDialogProperties {
    const properties: ConfirmDialogProperties = {
      isVisible: this.isVisible,
      confirmMessage: this.confirmMessage,
      confirmIcon: this.confirmIcon,
      acceptBtnLabel: this.acceptBtnLabel,
      rejectBtnLabel: this.rejectBtnLabel,
      rejectBtnIcon: this.rejectBtnIcon,
      acceptBtnIcon: this.acceptBtnIcon,
      showHeader: this.showHeader,
      dismissableMask: this.dismissableMask,
      dialogType: this.dialogType,
      headerClass: this.headerClass,
      dialogKey: this.dialogKey,
      action: this.action,
      headerText: this.headerText,
      acceptBtnClass: this.acceptBtnClass,
      rejectBtnClass: this.rejectBtnClass,
      extraData: this.extraData
    };
  
    return properties;
  }

  resetProperties() {
    this.isVisible = false;
    this.confirmMessage = undefined;
    this.confirmIcon = undefined;
    this.acceptBtnLabel = undefined;
    this.rejectBtnLabel = undefined;
    this.action = undefined;
    this.rejectBtnIcon = undefined;
    this.acceptBtnIcon = undefined;
    this.showHeader = true;
    this.dismissableMask = true;
    this.dialogType = 'header-with-image';
    this.headerClass = 'text-center flex align-items-center flex-column ';
    this.dialogKey = 'key1';
    this.headerText = undefined;
    this.acceptBtnClass = ' ';
    this.rejectBtnClass = 'cd-dialog-btn-reject';
    this.extraData = null;
  }  

  onRejectSelection(event: any) {
    this.rejectSelectionSource.next(this.getProperties());
  }

  onAcceptSelection(event: any) {
    this.acceptSelectionSource.next(this.getProperties());
  }

  show() {
    this.isVisible = true;
  }

  hide() {
    this.isVisible = false;
  }

}

