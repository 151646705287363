import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Classroom, ClassroomType, Level } from 'src/app/core/models/classroom.model';
import { CircleLine, CircleType, GoalReason, StudentGoal } from 'src/app/core/models/goal.model';
import { Category } from 'src/app/core/models/homework.model';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { GoalService } from 'src/app/core/services/goal.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { SubSink } from 'subsink';
export interface Month {
  number: string,
  month: string
}
@Component({
  selector: 'app-create-goal',
  templateUrl: './create-goal.component.html',
  styleUrls: ['./create-goal.component.scss']
})
export class CreateGoalComponent implements OnInit {
  @Input() goals: StudentGoal[] = []
  @ViewChild('createGoal') public createGoal: any;
  editIndex: number = 0;
  showConfirm: boolean = false;
  private subs = new SubSink();
  public stepCircleLines: CircleLine[] = [];
  public userClassrooms: Classroom[] = [];
  public currentLevelCircleLines: CircleLine[] = [];
  public currentStep: number = 0;
  public levels: Level[] = [];
  public months: Month[] = [{ number: "0", month: "January" }, { number: "1", month: "February" }, { number: "2", month: "March" }, { number: "3", month: "April" }, { number: "4", month: "May" }, { number: "5", month: "June" }, { number: "6", month: "July" }, { number: "7", month: "August" }, { number: "8", month: "September" }, { number: "9", month: "October" }, { number: "10", month: "November" }, { number: "11", month: "December" }];
  public years = ["2022", "2023", "2024"];
  public hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public reasons = [GoalReason.BOOST, GoalReason.CERTIFICATE, GoalReason.COMMUNICATE, GoalReason.CULTURE, GoalReason.EDUCATION, GoalReason.HOBBY, GoalReason.MOVING, GoalReason.TRAVELING];
  public areas = [Category.GRAMMAR, Category.LISTENING, Category.READING, Category.REVISION, Category.SPEAKING, Category.TEST, Category.VOCABULARY, Category.WRITING]
  private currentLevelChosen: Level = {} as Level
  private desiredLevelChosen: Level = {} as Level
  private monthChosen: string = "01";
  monthSelected: Month = {} as Month
  private yearChosen: string = "";
  private dateChosen: Date = new Date();
  private hoursWeeklyChosen: number = 0;
  private reasonsChosen: GoalReason[] = [];
  private areasChosen: Category[] = [];
  private languageChosen: string = "";
  private classroomChoosen: Classroom = {} as Classroom;
  private choosenGoalId: string  = "";
  constructor(
    private toastService: ToastService,
    private generalService: GeneralService,
    private classroomService: ClassroomService,
    private goalService: GoalService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {


  }

  ngAfterViewInit() {
    if (this.goals.length > 0) {
      this.initiateGoalToEdit(0);
    }
    this.subs.sink = this.getUserClassrooms().subscribe(res => {
      this.userClassrooms = res;
    })
    let extraTexts = ["Classroom", "Desired Level", "When", "Lesson Hours", "Why", "Improvement"]
    let extraTextsml = ["-30px", "-38px", "-9px", "-37px", "-1px", "-43px"]
    this.levels = [Level.A1, Level.A2, Level.B1, Level.B2, Level.C1, Level.C2];
    for (let i = 0; i < 6; i++) {
      let background = i == 0 ? '#a4a2e6' : 'white';
      let color = i == 0 ? 'white' : '#a4a2e6';

      let circleLine: CircleLine = {
        text: (i + 1).toString(),
        extraText: extraTexts[i],
        extraTextML: extraTextsml[i],
        lineStyle: '1px dashed lightgray',
        background: background,
        color: color,
        width: this.createGoal.nativeElement!.clientWidth / (5) - 21 + 'px',
        type: CircleType.GENERAL
      }
      this.stepCircleLines.push(circleLine)
      circleLine = this.levelsEmpty(i);
      if (this.goals.length == 0)
        this.currentLevelCircleLines.push(circleLine)
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initiateGoalToEdit(editIndex: number) {
    this.choosenGoalId = this.goals[editIndex].id!
    this.currentLevelChosen = this.goals[editIndex].currentLevel;
    this.desiredLevelChosen = this.goals[editIndex].desiredLevel
    this.dateChosen = this.goals[editIndex].desiredAchieveDate;
    this.hoursWeeklyChosen = this.goals[editIndex].hoursWeekly
    this.reasonsChosen = this.goals[editIndex].reasons
    this.areasChosen = this.goals[editIndex].areas;
    this.languageChosen = this.goals[editIndex].language
    this.monthSelected = this.months.filter(el => el.number == this.goals[editIndex]?.desiredAchieveDate.getMonth().toString())[0]
    this.yearChosen = this.goals[editIndex]?.desiredAchieveDate.getFullYear().toString()
    let currentLevel = this.goals[editIndex].currentLevel;
    let desiredLevel = this.goals[editIndex].desiredLevel;
    let levels: Level[] = [Level.A1, Level.A2, Level.B1, Level.B2, Level.C1, Level.C2];
    let currentLevelIndex = levels.findIndex(el => el === currentLevel);
    let desiredLevelIndex = levels.findIndex(el => el === desiredLevel);
    let newCircleLines: CircleLine[] = [];
    this.currentLevelCircleLines = []
    for (let i = 0; i <= currentLevelIndex; i++) {
      let lineStyle = i == currentLevelIndex ? '1px dashed lightgray' : '1px solid lightgray'
      let circleLine: CircleLine = {
        text: levels[i],
        lineStyle,
        background: "#a4a2e6",
        color: "white",
        type: CircleType.GENERAL,
        width: this.createGoal.nativeElement!.clientWidth / (5) - 21 + 'px',

      }
      newCircleLines.push(circleLine)
      this.currentLevelCircleLines.push(circleLine)
    }
    for (let i = currentLevelIndex + 1; i <= desiredLevelIndex; i++) {
      let circleLine: CircleLine = {
        text: levels[i],
        lineStyle: '1px dashed lightgray',
        background: "#2d2a4b",
        color: "white",
        type: CircleType.GENERAL,
        width: this.createGoal.nativeElement!.clientWidth / (5) - 21 + 'px',
      }
      newCircleLines.push(circleLine)
      this.currentLevelCircleLines.push(circleLine)
    }
    for (let i = desiredLevelIndex + 1; i < 6; i++) {
      let circleLine: CircleLine = {
        text: levels[i],
        lineStyle: '1px dashed lightgray',
        background: "white",
        color: "#a4a2e6",
        type: CircleType.GENERAL,
        width: this.createGoal.nativeElement!.clientWidth / (5) - 21 + 'px',
      }
      newCircleLines.push(circleLine)
      this.currentLevelCircleLines.push(circleLine)
    }
  }

  getIsReasonChecked(reason: GoalReason) {
    return this.reasonsChosen.some(el => el == reason)
  }

  getIsAreaChecked(area: Category) {
    return this.areasChosen.some(el => el == area)
  }

  getUserClassrooms() {
    return this.classroomService.getUserClassrooms(ClassroomType.PAID)
  }

  levelsEmpty(i: number) {
    return {
      text: this.levels[i],
      lineStyle: '1px dashed lightgray',
      background: "white",
      color: '#a4a2e6',
      width: this.createGoal.nativeElement!.clientWidth / (5) - 21 + 'px',
      type: CircleType.CURRENT_LEVEL
    }
  }

  changeStep(next: boolean) {
    if (this.currentStep == 0 && this.currentLevelChosen.length == undefined) {
      this.toastService.setShowToastmessage({
        severity: 'info',
        summary: '',
        detail: 'Please choose a language to set a goal'
      });
      return;
    }
    if (this.currentStep == 1 && this.desiredLevelChosen.length == undefined) {
      this.toastService.setShowToastmessage({
        severity: 'info',
        summary: '',
        detail: 'Please choose a level to continue'
      });
      return;
    }
    if (next) {
      if (this.currentStep < 5) {
        this.stepCircleLines[this.currentStep].lineStyle = '1px solid lightgray';
        this.currentStep++
        this.stepCircleLines[this.currentStep].background = '#a4a2e6';
        this.stepCircleLines[this.currentStep].color = 'white';
      }
    } else {
      if (this.currentStep > 0) {
        this.stepCircleLines[this.currentStep].background = 'white';
        this.stepCircleLines[this.currentStep].color = '#a4a2e6';
        this.currentStep--;
        this.stepCircleLines[this.currentStep].lineStyle = '1px dashed lightgray';
      }
    }
  }

  chooseClassroom(event: { value: Classroom }) {
    this.currentLevelChosen = event.value.activeLevel
    this.languageChosen = event.value.language!
    this.classroomChoosen = event.value
    this.currentLevelChoice()
    // TODO GETCLASSROOMTEACHER
  }

  currentLevelChoice() {
    let currentLevelIndex = this.levels.findIndex(el => el == this.currentLevelChosen);
    for (let i = 0; i < 6; i++) {
      this.currentLevelCircleLines[i] = this.levelsEmpty(i);
    }
    for (let i = 0; i <= currentLevelIndex; i++) {
      let line = i < currentLevelIndex ? '1px solid lightgray' : '1px dashed lightgray'
      this.currentLevelCircleLines[i].background = '#a4a2e6'
      this.currentLevelCircleLines[i].color = 'white'
      this.currentLevelCircleLines[i].lineStyle = line
    }
  }

  desiredLevelChoice(event: { circle: CircleLine }) {
    if (event.circle.text <= this.currentLevelChosen) {
      
      this.toastService.setShowToastmessage({
        severity: 'info',
        summary: '',
        detail: 'Please choose level greater than the current one'
      });
    } else {
      this.desiredLevelChosen = event.circle.text as Level;
      let desiredLevelIndex = this.levels.findIndex(el => el === event.circle.text);
      let currentLevelIndex = this.levels.findIndex(el => el === this.currentLevelChosen);
      for (let i = currentLevelIndex + 1; i < 6; i++) {
        this.currentLevelCircleLines[i] = this.levelsEmpty(i);
      }
      for (let i = currentLevelIndex + 1; i <= desiredLevelIndex; i++) {
        let line = i < desiredLevelIndex ? '1px solid lightgray' : '1px dashed lightgray'
        this.currentLevelCircleLines[i].background = '#2d2a4b'
        this.currentLevelCircleLines[i].color = 'white'
        this.currentLevelCircleLines[i].lineStyle = line
      }
    }
  }

  chooseMonth(event: any) {
    this.monthChosen = (parseInt(event.value.number) + 1).toString()
  }

  chooseYear(event: any) {
    this.yearChosen = event.value
  }

  chooseHours(event: any) {
    this.hoursWeeklyChosen = event.value;
  }

  chooseReason(reason: GoalReason) {
    if (this.reasonsChosen.some(el => el == reason)) {
      this.reasonsChosen = this.reasonsChosen.filter(el => el !== reason)
    } else {
      this.reasonsChosen.push(reason)
    }
  }

  chooseArea(area: Category) {
    if (this.areasChosen.some(el => el == area)) {
      this.areasChosen = this.areasChosen.filter(el => el !== area)
    } else {
      this.areasChosen.push(area)
    }
  }
  indexToEdit: number = 0;
  changeIndex(event: any) {
    this.indexToEdit = this.goals.findIndex(((el: StudentGoal) => el.language == event.value.language));
    if (this.currentStep == 0) {
      this.editIndex = this.indexToEdit;
      this.initiateGoalToEdit(this.indexToEdit);
    } else {
      this.showConfirm = true;
      this.createGoal.nativeElement!.style.opacity = '0.5';
    }
  }

  answerConfirm(confirm: boolean) {
    this.createGoal.nativeElement!.style.opacity = '1';
    this.showConfirm = false;
    if (confirm) {
      this.submit(true)
    } else {
      
      this.toastService.setShowToastmessage({
        severity: 'info',
        summary: '',
        detail: 'Your edit has not been saved'
      });
    }
    this.currentStep = 0;
    this.editIndex = this.indexToEdit;
    this.initiateGoalToEdit(this.indexToEdit);
  }

  submit(update?: boolean) {
    this.currentStep = 6;
    console.log(this.monthChosen + "/01/" + this.yearChosen)
    this.dateChosen = new Date(this.monthChosen + "/01/" + this.yearChosen);
    console.log(this.dateChosen)

    let goal: StudentGoal = {
      startingLevel: this.currentLevelChosen,
      currentLevel: this.currentLevelChosen,
      desiredLevel: this.desiredLevelChosen,
      desiredAchieveDate: this.dateChosen,
      // totalWeeks: this.generalService.getDatesDiff(new Date(), this.dateChosen).weeks,
      hoursWeekly: this.hoursWeeklyChosen,
      // hoursCompleted: [],
      reasons: this.reasonsChosen,
      areas: this.areasChosen,
      language: this.languageChosen,
      teacher: this.classroomChoosen.teacher!,
      // classroom: this.classroomChoosen,
      studentId: this.authService.getUserId()!
    }
    console.log(goal)
    if (this.goals.length > 0) {
      this.goalService.updateGoal(goal, this.choosenGoalId).subscribe(res => {
        console.log(res)
        this.goalService.setUpdateListener(true)
      })
      
      this.toastService.setShowToastmessage({
        severity: 'info',
        summary: '',
        detail: 'Your goal has been successfully updated.'
      });
    } else {
      //create
      this.goalService.createGoal(goal).subscribe(res => {
        console.log(res)
        this.goalService.setUpdateListener(true)
      })
      
      this.toastService.setShowToastmessage({
        severity: 'info',
        summary: '',
        detail: 'Your goal has been successfully created. Good luck achieving it!'
      });
    }
  }

}
