import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ClassroomsLevelFilter, ClassroomsStatusFilter } from 'src/app/core/models/classroom.model';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-classrooms-filters-modal',
  templateUrl: './classrooms-filters-modal.component.html',
  styleUrls: ['./classrooms-filters-modal.component.scss']
})
export class ClassroomsFiltersModalComponent implements OnInit, OnDestroy {

  constructor(
    private generalService: GeneralService,
    private classroomService: ClassroomService
  ) { }

  @Input() levelElements?: HTMLCollectionOf<Element>;
  @Input() statusElements?: HTMLCollectionOf<Element>;
  @Output() closeMeEvent = new EventEmitter();
  @Output() confirmEvent = new EventEmitter();
  public level: string = "All";
  public domLevelID: string = "All";
  public status: string = "All";
  public domStatusID: string = "All";
  private subs = new SubSink();

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.generalService.slideInElement('blur_bg');
    this.subs.sink = this.classroomService.classroomsLevelFilter.subscribe((res: ClassroomsLevelFilter) => {
      this.level = res.level;
      this.domLevelID = res.domLevelID;
      [].forEach.call(this.levelElements, (el: any) => {
        this.changeFilterDomElementClass(el.id, res.domLevelID, 'classrooms-kinds-active')
      });
    })
    this.subs.sink = this.classroomService.classroomsStatusFilter.subscribe((res: ClassroomsStatusFilter) => {
      this.status = res.status;
      this.domStatusID = res.domStatusID;
      [].forEach.call(this.statusElements, (el: any) => {
        this.changeFilterDomElementClass(el.id, res.domStatusID, 'classrooms-status-active')
      });
    })
  }

  ngOnDestroy(): void {
    this.generalService.slideOutElement('blur_bg');
    this.subs.unsubscribe();
  }

  closeMe() {
    this.closeMeEvent.emit();
  }
  confirm() {
    this.confirmEvent.emit();
  }

  filterLevel(eventTarget: any) {
    this.level = eventTarget.attributes.level.nodeValue;
    this.domLevelID = eventTarget.attributes.id.nodeValue;
    [].forEach.call(this.levelElements, (el: any) => {
      this.changeFilterDomElementClass(el.id, this.domLevelID, 'classrooms-kinds-active')
    });
  }

  filterStatus(eventTarget: any) {
    this.status = eventTarget.attributes.status.nodeValue;
    this.domStatusID = eventTarget.attributes.id.nodeValue;
    [].forEach.call(this.statusElements, (el: any) => {
      this.changeFilterDomElementClass(el.id, this.domStatusID, 'classrooms-status-active')
    });
  }

  changeFilterDomElementClass(id: string, targetId: string, className: string) {
    if (id === targetId) {
      document.getElementById(id)?.classList.add(className);
    } else {
      document.getElementById(id)?.classList.remove(className);
    }
  }
}
