import { Component, OnInit } from '@angular/core';
import { co } from '@fullcalendar/core/internal-common';
import { Certificate} from 'src/app/core/models/certificate.model';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { CertificateService } from 'src/app/core/services/certificate.service';
import { SubSink } from 'subsink';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {
  userCertificates: Certificate[] = [];
  certificates: Certificate[] = [];
  completionCertificates: Certificate[] = [];
  attendanceCertificates: Certificate[] = [];
  languages: string[] = [];
  shareButtons: HTMLButtonElement[] = [];
  visible: boolean = false;
  responsiveOptions?: any[];
  base64data: string = "";
  pdfSrc = "";
  shareUrl = "https://www.mylingotrip.com/certificates?id=";
  isDownloadLoading: boolean[] = []; 
  user: any = {} as User;

  private subs = new SubSink();
  constructor(
    private certificaterService: CertificateService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.responsiveOptions = [
      {
          breakpoint: '1220px',
          numVisible: 4,
          numScroll: 2
      },
      {
          breakpoint: '1100px',
          numVisible: 1,
          numScroll: 1
      }
    ];
    this.getCertifications();
    this.user = this.authService.getLoggedInUser();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  viewPdf(level: any, language: any, type: any, index: number) {
    this.isDownloadLoading[index] = true;
    this.subs.sink =  this.certificaterService.generateCertificationPdf(level,language,type).subscribe((pdfResponse: any) =>{
      this.isDownloadLoading[index] = false;
      const byteCharacters = atob(pdfResponse);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob); 
      let anchor = document.createElement("a");
      anchor.href = url;
      anchor.target = "_blank"
      anchor.click();
    },
    error => {
      console.error('Error generating PDF:', error);
    });
  }
  
  ngAfterViewInit(): void {
    this.setupShareButton();
  }

  setupShareButton(): void {
    this.shareButtons = Array.from(document.querySelectorAll("button.shareButton")) as HTMLButtonElement[];
  }

  toggleShareButtons(): void {
    for (let i = 0; i < this.shareButtons.length; i++) {
      this.shareButtons[i].classList.toggle("open");
    }
  }
  showDialog() {
    this.visible = true;
  }
  selectLanguage(lang: string) {
    this.certificates = this.userCertificates;
    this.attendanceCertificates = this.certificates.filter((certificate: { type: string; }) => certificate.type === "attendance");
    this.completionCertificates = this.certificates.filter((certificate: { type: string; }) => certificate.type === "completion");
    if (lang !== "all") {
      this.certificates = this.certificates.filter((certificate: { language: string; }) => certificate.language === lang);
      this.attendanceCertificates = this.attendanceCertificates.filter((certificate: { language: string; }) => certificate.language === lang);
      this.completionCertificates = this.completionCertificates.filter((certificate: { language: string; }) => certificate.language === lang);
    }
  }

  getCertifications(){
    this.subs.sink = (this.certificaterService.getListOfCertifications().subscribe((response)=>{
      this.userCertificates = response;
      this.userCertificates.forEach((certificate) => {
        certificate.urlParam = btoa(`type=${certificate.type}&name=${this.user.firstName}_${this.user.lastName}&language=${certificate.language}&level=${certificate.level}&hours=${certificate.hours}&date=${this.convertDate(certificate.datetime)}`); 
      });
      this.languages = this.userCertificates.map((certificate) => certificate.language) // Extract the language property from each certificate
      .filter((language, index, array) => array.indexOf(language) === index); // Filter out duplicate languages
      this.certificates = this.userCertificates;
      this.completionCertificates = this.userCertificates.filter((certificate) => certificate.type === "completion");
      this.attendanceCertificates = this.userCertificates.filter((certificate) => certificate.type === "attendance");
    }));
  }

  public b64toBlob(b64Data: string, contentType: string) {
    contentType = contentType || '';
    let sliceSize = 512;
  
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
  
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
  
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
  
        var byteArray = new Uint8Array(byteNumbers);
  
        byteArrays.push(byteArray);
    }
  
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  getImage() {
    var blob = this.b64toBlob(this.base64data, "application/pdf");
    let a = document.createElement("a");
    document.body.appendChild(a);
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = String("MyLingoTrip_Certificate.pdf");
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  convertDate(dateString: string) {
    const date = new Date(dateString);
    let formattedDate = this.datePipe.transform(date, 'dd MMMM yyyy');
    return formattedDate;  
  }
}