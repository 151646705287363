import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-classroom-status-filters',
  templateUrl: './classroom-status-filters.component.html',
  styleUrls: ['./classroom-status-filters.component.scss']
})
export class ClassroomStatusFiltersComponent implements OnInit {
  @Input() switches: any;
  @Input() switchesWithLabels!: { label: string, value: boolean }[];
  @Input() isTrial = false;
  @Input() isLessonsFilter = false;
  @Output() switchToggled = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
    console.log(this.switchesWithLabels);
  }

  ngOnChanges() {
    console.log(this.switches);
  }

  toggleSwitch(switchName: string) {
    this.switchToggled.emit(switchName);
  }

}
