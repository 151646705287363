<div class="grid ">
    <!--  style="display: flex; justify-content: center; gap: 20px; flex-wrap: wrap;" -->
    <app-upload-files class="col-12 sm:col-6" [multiple]="false" title="CV *" 
    [postUrl]="UPLOAD_TEACHER_CV_URL" [dndUI]="true" 
    [fileDropImage]="'/assets/icons/teacher-application/resume-cv-file.svg'" 
    [fileDropImageWidth]="60" 
    [isTeacherApplication]="true"
    [fileAfterImage]="'/assets/icons/teacher-application/cv-file-uploaded-icon.svg'" 
    (onUploadFinished)="uploadFinished($event, 'cv')"
    [files]="[{file: {size: null, name: submittedFiles.cv }}]"
    (onDeleteFileClicked)="deleteFileClicked($event, 'cv')"></app-upload-files>
    <app-upload-files class="col-12 sm:col-6" [multiple]="false" title="Profile Picture *" 
    [postUrl]="UPLOAD_TEACHER_PICTURE_URL" 
    [fileDropImage]="'/assets/icons/teacher-application/resume-photo-file.svg'" 
    [fileAfterImage]="'/assets/icons/teacher-application/cv-image-uploaded.svg'" 
    [fileDropImageWidth]="86" 
    [isTeacherApplication]="true"
    [dndUI]="true"
    [files]="[{file: {size: null, name: (submittedFiles.profilePicture) }}]"
    (onDeleteFileClicked)="deleteFileClicked($event, 'photo')"
    (onUploadFinished)="uploadFinished($event, 'photo')"></app-upload-files>
</div>


<div class="btns md:ml-5 mt-4">
    <button pRipple class="rounded-blue-button transparent" (click)="goBack()"> <img src="/assets/icons/arrow-left-blue.svg" />
        Back</button>
    <button pRipple (click)="submitResume()" class="rounded-blue-button">Next <img src="/assets/icons/arrow-right.svg" /></button>
</div>