import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationOverviewComponent } from './components/notification-overview/notification-overview.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    NotificationOverviewComponent,
  ],
  imports: [
    CommonModule,
    NotificationsRoutingModule,
    FormsModule,
    SharedModule,
    InputTextModule,
    CalendarModule,
  ]
})
export class NotificationsModule { }
