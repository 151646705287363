import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Level } from '../models/classroom.model';
import { Category, Homework, HomeworkPostRequest, HomeworkStatus, HomeworkTask, HomeworkTaskFile, HomeworkTaskToSend, Proportion, UpdateHomeworkReq } from '../models/homework.model';
import { LibraryFile } from '../models/library.model';
import { User } from '../models/user.model';
import { GeneralService } from './general.service';
import { LibraryService } from './library.service';
import { UserService } from './user.service';
const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class HomeworkService {
  public homeworkToAdd: Homework = {} as Homework;

  private tagToAll$: BehaviorSubject<{ tag: string, add: boolean }> = new BehaviorSubject<{ tag: string, add: boolean }>({} as { tag: string, add: boolean });
  public readonly tagToAll: Observable<{ tag: string, add: boolean }> = this.tagToAll$.asObservable();

  private submitListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly submitListener: Observable<boolean> = this.submitListener$.asObservable();

  private isTaskFormValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isTaskFormValid: Observable<boolean> = this.isTaskFormValid$.asObservable();

  private updateListener$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly updateListener: Observable<boolean> = this.updateListener$.asObservable();

  private showFormListener$: BehaviorSubject<{ show: boolean, homework: Homework }> = new BehaviorSubject<{ show: boolean, homework: Homework }>({ show: false, homework: {} as Homework });
  public readonly showFormListener: Observable<{ show: boolean, homework: Homework }> = this.showFormListener$.asObservable();

  setTagToAll(tagToAll: { tag: string, add: boolean }) {
    this.tagToAll$.next(tagToAll)
  }

  constructor(
    private userService: UserService,
    private libraryService: LibraryService,
    private http: HttpClient,
    private generalService: GeneralService
  ) { }

  setSubmitListener(listen: boolean) {
    this.submitListener$.next(listen);
  }

  setShowFormListener(listen: boolean, homework: Homework) {
    this.showFormListener$.next({ show: listen, homework: homework });
  }

  setIsTaskFormValid(listen: boolean) {
    this.isTaskFormValid$.next(listen);
  }

  setUpdateListener(listen: boolean) {
    this.updateListener$.next(listen);
  }

  createHomework(homework: Homework) {
    let homeworkTasksToSend: HomeworkTaskToSend[] = []
    let assignedToIds: string[] = [];
    for (let user of homework.assignedTo) {
      assignedToIds.push(user.id)
    }
    for (let task of homework.homeWorkTasks) {
      let libraryFileIds: number[] = []
      for (let library of task.libraries!) {
        libraryFileIds.push(library.fileId!)
      }
      let homeworkTaskToSend = {
        title: task.title,
        finished: false,
        assignedTo: task.assignedTo!.id,
        studentFiles: [],
        teacherFiles: task.teacherFiles,
        link: task.link,
        rate: task.rate,
        proportion: task.proportion,
        dismissed: false,
        libraryFileIds: libraryFileIds,
        classroomId: parseInt(task.assignedToUserInClassroom!)
      }
      homeworkTasksToSend.push(homeworkTaskToSend)
    }
    let deadline = homework.deadLine;
    if(homework.deadLine == null){
      deadline = "No Deadline"
    }
    let homeworkToSend: HomeworkPostRequest = {
      title: homework.title,
      description: homework.description,
      deadLine: deadline as string,
      status: homework.status,
      state: homework.state,
      levels: homework.levels,
      categories: homework.categories,
      homeWorkTasks: homeworkTasksToSend
    };

    return this.http.post(BACKEND_URL + "/Homework/CreateHomeWork", homeworkToSend)
  }

  updateHomeworkState(homework: Homework, state: string) {
    return this.http.post(BACKEND_URL + `/HomeWork/UpdateHomeworkState?HomeworkId=${homework.id}`, state)
  }

  updateHomeworkStatus(homework: Homework, status: string) {
    return this.http.post(BACKEND_URL + `/HomeWork/UpdateHomeworkStatus?HomeworkId=${homework.id}`, { status: status })
  }

  deleteHomework(homework: Homework) {
    return this.http.post(BACKEND_URL + `/HomeWork/DeleteHomework?hwId=${homework.id}`, {})
  }

  deleteTaskFromHomework(taskId: string) {
    return this.http.post(BACKEND_URL + `/HomeWork/DeleteTaskFromHomework?homeWorkTaskId=${taskId}`, {})
  }

  compareFn(a: Homework, b: Homework) {
    if (a.state == 'Pending' && b.state != 'Pending')
      return -1;
    return 1;
  };

  getHomeworks(): Observable<Homework[]> {
    return this.http.get<any[]>(BACKEND_URL + `/HomeWork/GetHomeworks`).pipe(
      map(x => {
        let sortByPending: Homework[] = [];
        for (let hw of x) {
          hw.deadLine = hw.deadLine != 'No Deadline' ? new Date(hw.deadLine) : "No Deadline"
          for (let task of hw.homeWorkTasks) {
            for (let lib of task.libraries!) {
              let f: HomeworkTaskFile = {
                filePath: lib.path,
                levels: lib.levels,
                categories: lib.categories
              }
              task.teacherFiles.push(f)
            }
          }
          if (hw.status == 'Pending') {
            sortByPending.push(hw)
          }
        }
        for (let hw of x) {
          if (hw.status != 'Pending') {
            sortByPending.push(hw)
          }
        }
        return sortByPending;
      }))
  }

  updateTaskFinished(finished: boolean, taskId: string) {
    return this.http.post(BACKEND_URL + `​/HomeWork/UpdateTaskFinished?taskId=${taskId}&finished=${finished}`, {})
  }

  updateTaskRate(rate: number, taskId: string) {
    return this.http.post(BACKEND_URL + `​/HomeWork/UpdateTaskRate?taskId=${taskId}&rate=${rate}`, {})
  }

  addStudentFileToTask(filePath: number, taskId: string) {
    return this.http.post(BACKEND_URL + `​/HomeWork/UpdateTaskRate?taskId=${taskId}&filePath=${filePath}`, {})
  }

  updateTaskProportion(proportion: string, taskId: string) {
    return this.http.post(BACKEND_URL + `​/HomeWork/UpdateTaskProportion?taskId=${taskId}&proportion=${proportion}`, {})
  }

  updateHomework(updateReq: UpdateHomeworkReq) {
    return this.http.post(BACKEND_URL + `​/HomeWork/UpdateHomework?homeWorkId=${updateReq.id}`, updateReq)
  }

  addTaskToHomework(task: HomeworkTaskToSend, hwId: string) {
    return this.http.post(BACKEND_URL + `​/HomeWork/AddTaskToHomework?homeWorkId=${hwId}`, task)
  }

  updateTaskDetails(task: HomeworkTaskToSend, hwId: string) {
    return this.http.post(BACKEND_URL + `​/HomeWork/UpdateTaskDetails?homeWorkTaskId=${task.id}`, task)
  }

  getHomeworkColor(homework: Homework) {
    if (homework.status.includes(HomeworkStatus.COMPLETED_RATED)) {
      return "#3F37C9"
    }
    if (homework.status.includes(HomeworkStatus.COMPLETED_UNRATED)) {
      return "#3F37C9"
    }
    if (homework.status.includes(HomeworkStatus.COMPLETED_PARTIALLY_RATED)) {
      return "#3F37C9"
    }
    if (homework.status.includes(HomeworkStatus.PARTIALLY_COMPLETED_PARTIALLY_RATED)) {
      return "#4895EF"
    }
    if (homework.status.includes(HomeworkStatus.COMPLETED_PARTIALLY_UNRATED)) {
      return "#4895EF"
    }
    if (homework.status.includes(HomeworkStatus.PENDING)) {
      return "#7209B7"
    }
    return "#4895EF"
  }

  getStatusOnUpdateFinishedTaskOrRate(tasks: HomeworkTask[]) {
    let status = "";
    let countRatedTasks: number = 0;
    let countCompleted: number = 0;
    let countDismissed: number = 0;
    for (let task of tasks) {
      if (task.rate! > 0) {
        countRatedTasks++
      }
      if (task.finished) {
        countCompleted++
      }
      if (task.dismissed) {
        countCompleted++
      }
    }
    if (countCompleted == 0) {
      status = HomeworkStatus.PENDING
    }
    if (countCompleted == tasks.length && countRatedTasks == tasks.length) {
      status = HomeworkStatus.COMPLETED_RATED
    }
    if (countCompleted == tasks.length && countRatedTasks == 0) {
      status = HomeworkStatus.COMPLETED_UNRATED
    }
    if (countCompleted >= 0 && countCompleted < tasks.length && countRatedTasks == tasks.length) {
      status = HomeworkStatus.PARTIALLY_COMPLETED_RATED
    }
    if (countCompleted == 0 && countCompleted < tasks.length && countRatedTasks == tasks.length) {
      status = HomeworkStatus.NO_COMPLETED_RATED
    }
    if (countCompleted == tasks.length && countRatedTasks > 0 && countRatedTasks < tasks.length) {
      status = HomeworkStatus.COMPLETED_PARTIALLY_RATED
    }
    if (countCompleted >= 0 && countCompleted < tasks.length && countRatedTasks > 0 && countRatedTasks < tasks.length) {
      status = HomeworkStatus.PARTIALLY_COMPLETED_PARTIALLY_RATED
    }
    if (countCompleted >= 0 && countCompleted < tasks.length && countRatedTasks == 0) {
      status = HomeworkStatus.COMPLETED_PARTIALLY_UNRATED
    }
    if (countDismissed > 0) {
      status += " Dismissed"
    }
    return status;
  }

  initiateHomeworkObject(): Homework {
    this.homeworkToAdd = {
      title: "",
      description: "",
      homeWorkTasks: [],
      pinned: false,
      deadLine: new Date,
      status: "",
      submitted: null,
      assignedTo: [],
      levels: [],
      categories: [],
      state: "",
      classroom: 0,
    }
    return this.homeworkToAdd
  }

  initiateHomeworkTaskObject(): HomeworkTask {
    return {
      title: "",
      finished: false,
      teacherFiles: [],
      studentFiles: [],
      link: "",
      assignedTo: undefined,
      assignedToClassroom: undefined, // TODO THIS IS FOR ME ONLY
      libraries: [],
      rate: 0,
      proportion: ""
    }
  }


}
