import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap, filter, take, catchError, tap } from 'rxjs/operators';
import { LeftMenuItem } from 'src/app/core/models/general.model';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClassroomService } from 'src/app/core/services/classroom.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { UserService } from 'src/app/core/services/user.service';
import { SubSink } from 'subsink';



@Component({
  selector: 'app-side-menu-container-layout',
  templateUrl: './side-menu-container-layout.component.html',
  styleUrls: ['./side-menu-container-layout.component.scss'],
})
export class SideMenuContainerLayoutComponent implements OnInit {

  isBelow768 = false;
  private subs = new SubSink();
  @ViewChild('scale',{static:true}) public ratingDetails: any;
  @ViewChild('menuOuterDiv', {static: true}) menuOuterDiv: ElementRef = {} as ElementRef;
  menuItems: LeftMenuItem[] = [];
  public loggedInUser: User = {} as User;

  constructor(
    private generalService: GeneralService,
    private layoutService: LayoutService,
    private classroomService: ClassroomService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.loggedInUser = this.authService.getLoggedInUser();
    this.menuItems = this.layoutService.getMenuItems();

    this.subs.add(this.userService.getUserById(this.authService.getLoggedInUser().aspUserId!).subscribe((res: User) => {
      console.log(res);
      // this.loggenInUser = res;
      this.userService.updateAvatarUrl(res.photo!);
    }));

    this.subs.add(this.generalService.deviceKind.subscribe(res => {

      // Check if the height of the source div element is greater than 0
      
      this.isBelow768 = res.is768;
      // TODO check if rescaling is needed...
      // if (res.w992up && !res.w1366up) {

      //   this.generalService.scaleElementDown(this.ratingDetails, "left top", 0.8);
      // } else {
      //   this.generalService.scaleElementReset(this.ratingDetails);
      // }
    }));

    // if (this.authService.isStudent) {
    //   this.handleStudentClassroomDataAndMenu();
    // }

  }

  ngAfterViewChecked() {
    if (this.menuOuterDiv.nativeElement.offsetHeight > 0) {
        this.layoutService.setSideMenuHeight(this.menuOuterDiv.nativeElement.offsetHeight);
    }

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.menuItems = [];
  }

  menuUrlChanged(event: any) {
    this.router.navigateByUrl(event);
  }

  private handleStudentClassroomDataAndMenu() {
    this.subs.add(
      this.classroomService.getLMSUserClassrooms(this.loggedInUser.id).pipe(
        switchMap((response) => {
          return this.userService.getUserHasPayment().pipe(
            tap((res: any) => {
              response = { response, res } as any;
              const isStudentActive = this.classroomService.isStudentActive(response.response, response.res);
              this.layoutService.updateMenuItemDisabledStatus("Classrooms", response.length === 0);
              if (isStudentActive) {
                this.layoutService.updateAllMenuItemsDisabledStatus(false);
              } else {
                this.layoutService.updateMenuItemClassroomToTrialsForStudent(res);
              }
              console.log(response);
            })
          );
        }),
        take(1)
      ).subscribe()
    );
  }

}
