<!--  [style.position]="'sticky'" -->
<div id="notes-left-side" class="notes-left-side relative h-full" [ngClass]="{'collapsed': isCollapsed}">
    <div *ngIf="isLarge" class="icon-container pointer hvr-grow">
        <img src="/assets/icons/library/arrow-left.svg" [ngClass]="{'rotate-180 cola': isCollapsed}"
            style="width:1.45rem; height:1.45rem" (click)="collapseNotesLeftSide()">
    </div>

    <p-overlayPanel #op styleClass="w-full">
        <ng-template pTemplate="content">
            <div *ngIf="authService.isTeacher" id="my-files" (click)="onMyFilesSelected()"
             class="classroom-title link-main-color mx-2"
            [ngClass]="{'link-main-color-active': (selectedClassroom) === null || !hasSelectedClassroom}">
            <span class="font-semibold">{{firstListItemTitle}}</span>
        </div>
            <div *ngFor="let classroom of filteredClassRooms" class="mx-2">
                <div (click)="classRoomSelected(classroom)" #classTitleElem
                    class="classroom-title one-line link-main-color text-left" 
                    [ngClass]="{'link-main-color-active': selectedClassroom.id === classroom.id}" id={{classroom.id}}>

                    <span class="hovered-item">
                        <img *ngIf="classroomService.isClassroomOngoing(classroom)" title="active"
                            src="/assets/icons/library/active-icon.svg" width="12" class="mr-1">
                        <img [src]="classroomService.getClassroomTypeSmallIconPath(classroom)"
                            [ngClass]="{'filter-white': classTitleElem.classList.contains('link-main-color-active') }"
                            height="12" class="mr-1" />
                        <span
                            *ngIf="authService.isTeacher">{{classroomService.getFormattedStudentNames(classroom.classroomStudents)}}</span>
                        <span *ngIf="authService.isStudent">{{classroom.language}} - {{classroom.teacher.firstName}}</span>
                    </span>
                </div>
                <div class="seperator"></div>
            </div>
            <div class="text-center mt-4">
                <div class="field-checkbox my-0 py-1 font-2xs bottom-toggles sm:absolute bottom-0 flex justify-content-center">
                    <label class="lesson-filter-label font-xs mr-2">Show Active</label>
                    <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="showActiveClassrooms"
                        (onChange)="toggleSwitch($event)"></p-inputSwitch>
                    <label class="lesson-filter-label font-xs mr-2">Show All</label>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>

    <p-button *ngIf="!isLarge" class="w-full" styleClass="p-button-outlined p-button-rounded p-button-sm w-full" (click)="op.toggle($event)" 
    [label]="(hasSelectedClassroom ? this.selectedClassroom.language + ' - '  + classroomService.getLessonRowName(this.selectedClassroom) : 'Select Classroom')"><img src="/assets/icons/library/arrow-down.svg" [ngClass]="{'hidden': isLarge}"
    style="width:1.15rem; height:1.15rem; position: absolute; right: 0; margin-right: 0.5rem;">
    </p-button>
    <p-overlayPanel #classroomOverlayPanel [styleClass]="'overlay-panel-width'">
        <ng-template class="p-0" pTemplate="content">
            <ul class="p-0">
                    <li *ngFor="let classroom of filteredClassRooms"
                        class="flex align-items-center py-0 border-bottom-1 surface-border mx-2 line-height-3">
                        <span class="col-6 p-0 no-gutter flex align-items-center gap-1 text-700  font-xs">
                            <img src="/assets/images/dashboard/student.svg" height="8" /> {{classroom.language}} -
                        </span>
                        <span class="col-6 p-0 gap-1 no-gutter flex align-items-center justify-content-start font-xs">
                            <img src="/assets/images/dashboard/calendar/check-circled.svg" height="8" />
                            {{classroom.totalHours}} hours available </span>
                    </li>
            </ul>
        </ng-template>
    </p-overlayPanel>

    <div  *ngIf="isLarge" class="h-full" [ngClass]="{'no-visibility': isCollapsed}">
        <span class="p-input-icon-right w-full mb-1">
            <input type="text" [(ngModel)]="term" pInputText placeholder="Search"
                class="h-2rem w-full input-blue gradient-blue rounded" />
            <i class="pi pi-search text-primary"></i>
        </span>

        <div class="overflow-y-auto custom-scrollbar lg:pt-3 lg:pb-2" [style.height]="leftSideHeight + 'px'">
        <div *ngIf="authService.isTeacher" id="my-files" (click)="onMyFilesSelected()"
            #myFilesDiv class="classroom-title link-main-color mx-2"
            [ngClass]="{'link-main-color-active': (selectedClassroom) === null || !hasSelectedClassroom}">
            <span class="font-semibold">{{firstListItemTitle}}</span>
        </div>
        <div class="seperator  max-w-8rem" style="border-bottom: 1px solid #d9ddf0; width: 85%;"></div>

        <ng-container *ngIf="filteredClassRooms && selectedClassroom">
        <div *ngFor="let classroom of filteredClassRooms | filter: { term: term }" class="mx-2">
            <div (click)="classRoomSelected(classroom)" #classTitleElem [pTooltip]="classroom.type == 'Trial' ? 'Trial Classroom' : ''"
                class="classroom-title one-line link-main-color text-left" 
                [ngClass]="{'link-main-color-active': selectedClassroom.id === classroom.id}" id={{classroom.id}}>

                <span class="hovered-item"><img *ngIf="classroomService.isClassroomOngoing(classroom)" title="active"
                        src="/assets/icons/library/active-icon.svg" width="12" class="mr-1">
                    <img [src]="classroomService.getClassroomTypeSmallIconPath(classroom)"
                        [ngClass]="{'filter-white': classTitleElem.classList.contains('link-main-color-active') }"
                        height="12" class="mr-1" />
                    <span
                        *ngIf="authService.isTeacher">{{classroomService.getFormattedStudentNames(classroom.classroomStudents)}}</span>
                    <span *ngIf="authService.isStudent">{{classroom.language}} - {{classroom.teacher.firstName}}</span>
                </span>
            </div>
            <div class="seperator max-w-8rem"></div>
        </div>
        </ng-container>

        <div class="text-center mt-4 sm:absolute bottom-0">
            <div class="field-checkbox my-0 py-1 font-2xs bottom-toggles  flex justify-content-center">
                <label class="lesson-filter-label font-xs mr-2">Show Active</label>
                <p-inputSwitch class="flex small-input-switch p-mr-2" [ngModel]="showActiveClassrooms"
                    (onChange)="toggleSwitch($event)"></p-inputSwitch>
                <label class="lesson-filter-label font-xs mr-2">Show All</label>
            </div>
        </div>
    </div>
    </div>
</div>