import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { switchMap, take } from 'rxjs/operators';
import { Level } from 'src/app/core/models/classroom.model';
import { Category } from 'src/app/core/models/homework.model';
import { Folder, Library, LibraryFile } from 'src/app/core/models/library.model';
import { UserRole } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { LibraryService } from 'src/app/core/services/library.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserService } from 'src/app/core/services/user.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-library-file-actions-dialog',
  templateUrl: './library-file-actions-dialog.component.html',
  styleUrls: ['./library-file-actions-dialog.component.scss']
})
export class LibraryFileActionsDialogComponent implements OnInit {

  private subs = new SubSink();
  role: UserRole = UserRole.NONE;
  UserRoles = UserRole;
  dialogData: any = {};
  sharedWithMap: any;
  selectedFolder!: Folder;
  term: any;
  libraryToAdd: Library = {} as Library;
  files = [] as LibraryFile[];
  modifiedFile = {} as LibraryFile;
  showEditRename = false;
  newFileName = '';
  constructor(
    private ref: DynamicDialogRef,
    private authService: AuthService,
    private userService: UserService,
    private generalService: GeneralService,
    private libraryService: LibraryService,
    private toastService: ToastService,
    private config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
    this.role = this.authService.getLoggedInUser().role;
    this.dialogData = this.config.data.dialogData;
    console.log(this.dialogData);
    this.userService.setUploadFiles(true);
    this.files = [];
    if (this.dialogData.libraryFile) {
      this.newFileName = this.dialogData.libraryFile.name;
      this.modifiedFile = this.dialogData.libraryFile;
    }
    // this.sortFolders(this.dialogData.folders);
    // console.log((this.sortFolders(this.dialogData.folders)))

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.ref.destroy();
  }

  onDialogClose(data?: any) {
    console.log(data);
    this.ref.close(data);
    this.ref.destroy();
  }

  onCheckedList(event: any) {
    console.log(event);
    this.sharedWithMap = event;
  }

  onSendSharedWith() {
    this.onDialogClose({
      action: this.dialogData.action,
      map: this.sharedWithMap
    })
  }

  moveSelected(folder: Folder) {
    this.selectedFolder = folder;
    console.log(folder);
  }

  /**
   * Executes when the move is submitted.
   *
   * @param {type} paramName - description of parameter
   * @return {type} description of return value
   */
  onMoveSubmitted() {
    console.log(this.selectedFolder);
    this.onDialogClose({
      action: this.dialogData.action,
      libraryFile: this.dialogData.libraryFile,
      folder: this.selectedFolder
    })
  }

  /**
   * Retrieves the parent folders from the dialogData object.
   *
   * @return {Array} An array containing objects representing the parent folders,
   * their child folders, and the library files associated with each parent folder.
   */
  getParentFolders() {
    const parentFolders = this.dialogData.folders.filter((f: any) => !f.parent);
    return parentFolders.map((parent: any) => {
      const childFolders = this.dialogData.folders.filter((f: any) => f.parent === parent.name);
      const libraryFiles = parent.libraryFiles;
      return { parent, childFolders, libraryFiles };
    }).concat(this.dialogData.folders.filter((f: any) => f.parent));
  }

  /**
   * Sorts an array of folders by separating them into parent folders and child folders,
   * sorting the parent folders by name, and adding the child folders to their respective parent folders.
   *
   * @param {any[]} folders - The array of folders to be sorted.
   * @return {any[]} - The sorted array of folders.
   */
  sortFolders(folders: any[]): any[] {
    const sortedFolders: any[] = [];
    const parentFolders: any[] = [];
    const childFolders: any[] = [];
  
    // Separate parent folders and child folders
    folders.forEach(folder => {
      if (folder.parent === '') {
        parentFolders.push(folder);
      } else {
        childFolders.push(folder);
      }
    });
  
    // Sort parent folders by name
    parentFolders.sort((a, b) => a.name.localeCompare(b.name));
  
    // Add parent folders to sorted array
    parentFolders.forEach(folder => {
      sortedFolders.push(folder);
      const children = childFolders.filter(child => child.parent === folder.name);
      const sortedChildren = this.sortFolders(children);
      sortedChildren.forEach(child => {
        sortedFolders.push({...child, marginLeft: 10 });
      });
    });
  
    // Add child folders to sorted array
    childFolders.forEach(folder => {
      if (!sortedFolders.some(f => f.folderId === folder.folderId)) {
        sortedFolders.push({...folder, marginLeft: 10 });
      }
    });
  
    return sortedFolders;
  }

  /**
   * Uploads the finished event.
   *
   * @param {any} event - The event object.
   * @return {void} This function does not return anything.
   */
  uploadFinished(event: any) {
    this.files.push({ 
      path: event.filePath.dbPath, 
      levels: [], 
      categories: [], 
      name: event.filePath.dbPath, 
      fileId: event.filePath.dbPath });
      console.log(this.files);
  }

  /**
   * Handles the event when a tag is chosen.
   *
   * @param {any} event - The event object containing information about the tag selection.
   */
  onTagChoosen(event: any) {
    let isLevel = event.isLevel;
    if (event.checked) {
      if (event.applyToAll) {
        for (let file of this.files) {
          let arrLibrary: string[] = isLevel ? file.levels : file.categories;
          arrLibrary.push(event.tag)
        }
      } else {
        let file = this.files[event.file.index]
        let arrLibrary: string[] = isLevel ? file.levels : file.categories;
        arrLibrary.push(event.tag)
      }
    } else {
      if (event.applyToAll) {
        for (let file of this.files) {
          let arrLibrary: string[] = isLevel ? file.levels : file.categories;
          let index2 = arrLibrary.findIndex(((el: string) => el == event.tag));
          arrLibrary.splice(index2, 1);
        }
      } else {
        let file = this.files[event.file.index]
        let arrLibrary: string[] = isLevel ? file.levels : file.categories;
        let index2 = arrLibrary.findIndex(((el: string) => el == event.tag));
        arrLibrary.splice(index2, 1);
      }
    }
  }

  onUploadedFilesSubmitted() {
    const params = {
      action: this.dialogData.action,
      libraryFiles: this.files,
      folder: this.dialogData.folder
    };
    console.log(params);
    this.onDialogClose(params)
  }

  /**
   * Retrieves the file name from a given file path.
   *
   * @param {string} filePath - The file path.
   * @return {string | undefined} The file name without extension if it exists, otherwise the entire file name.
   */
  getFileName(filePath: string): string | undefined {
    const fileNameWithPath = filePath.split('\\').pop();
    const fileNameParts = fileNameWithPath!.split('.');
    
    if (fileNameParts.length > 1) {
      const fileNameWithoutExtension = fileNameParts.slice(0, -1).join('.');
      return fileNameWithoutExtension;
    }
    
    return fileNameWithPath;
  }

  /**
   * A function that creates a deep copy of an object.
   *
   * @param {any} obj - The object to be deep copied.
   * @return {any} The deep copy of the object.
   */
  deepCopy(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
  
    let copy: any;
  
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0; i < obj.length; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
    } else {
      copy = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          copy[key] = this.deepCopy(obj[key]);
        }
      }
    }
  
    return copy;
  }

  onChooseTag(checkbox: any) {
    const libraryFile = this.deepCopy(this.modifiedFile);

    if (checkbox.checked) {
      if (checkbox.isCategory) {
        // Update categories in libraryFile
        libraryFile.categories.push(checkbox.tag);
      }
      if (checkbox.isLevel) {
        // Update levels in libraryFile
        libraryFile.levels.push(checkbox.tag);
      }
    } else {
      if (checkbox.isCategory) {
        // Remove category from libraryFile
        libraryFile.categories = libraryFile.categories.filter((category: Category) => category !== checkbox.tag);
      }
      if (checkbox.isLevel) {
        // Remove level from libraryFile
        libraryFile.levels = libraryFile.levels.filter((level: Level) => level !== checkbox.tag);
      }
    }

    this.modifiedFile = libraryFile;
  }

  onTagsSubmitted() {
    const libraryFile = this.deepCopy(this.modifiedFile);
    const newLevels = {
      fileId: libraryFile.fileId,
      levels: this.modifiedFile.levels
    }
    const newCategories = {
      fileId: libraryFile.fileId,
      categories: this.modifiedFile.categories
    }
    this.rename(this.modifiedFile.fileId!, this.newFileName);
    this.subs.add(this.libraryService.editFileLevels(newLevels).pipe(
      switchMap((res) => {
        console.log(res);
        return this.libraryService.editFileCategories(newCategories);
      })
    ).subscribe((res) => {
      console.log(res);
      const params = {
        action: this.dialogData.action,
        result: this.modifiedFile,
      };
      this.toastService.setShowToastmessage({
        severity: 'success',
        summary: '',
        detail: 'Library file saved successfully.'
      });
      console.log(this.modifiedFile);
      this.onDialogClose(params);
    }));
  }

  rename(fileId: number, newName: string) {
    this.subs.add(this.libraryService.updateLibraryFileName(fileId, newName).pipe(take(1)).subscribe(res => {
      this.modifiedFile.name = this.newFileName;
    }));
  }
}
