<li class="library-file-row" [ngClass]="{'checked': checked && showGroupActions, 'purple-hover': isInTab}">
    {{classroom?.id}}
    <div
        class="w-3rem h-3rem flex align-items-center justify-content-center bg-grad-blue border-circle mr-1 flex-shrink-0"
        [ngClass]="{'bg-grad-purple': isInTab, 'bg-grad-blue': !isInTab}">
        <img src="/assets/icons/file-white.svg" height="24">
    </div>
    <div class="w-full flex sm:flex-row align-items-center justify-content-between">
        <div class="library-file-details py-1 font-sm flex flex-column">
            <span (click)="openFileInNewTab(classroomFile.path)" class="file-name file-path-text max-w-10rem sm:max-w-15rem pointer">{{getFileName(classroomFile.name)}}</span>
            <div class="flex gap-1">
                <div *ngFor="let level of classroomFile.levels">
                    <g-level-circle size="2xs" [name]="level"></g-level-circle>
                </div>
                <img src="/assets/icons/library/share-sm-icon.svg" *ngIf="this.sharedWithUsers.length > 0" width="10">
                <span class="font-xs file-path-text max-w-10rem">
                    {{ getUniqueSharedUsers() }}
                </span>
            </div>
        </div>
        <div class="library-file-meta">
            <div class="text-900 w-full md:flex-order-0 flex-order-1 flex align-items-end justify-content-end gap-1">
                <p-chip *ngFor="let category of classroomFile.categories | slice:0:hasMultipleCategoriesSlice"
                    class="class-rating {{category.toLowerCase()}} border-round-xl px-0"><span
                        class="font-xs text-white  py-1 px-0 line-height-1">{{category}}</span></p-chip>
                        <!-- <span *ngIf="classroomFile.categories.length > 1"  class="font-xs text-muted mt-2 more-circle"
                            [pTooltip]="(classroomFile.categories | slice:hasMultipleCategoriesSlice).join(', ')"
                            tooltipPosition="top">
                            +{{classroomFile.categories.length - hasMultipleCategoriesSlice}}
                          </span> -->
                          <div *ngIf="classroomFile.categories.length > 1">
                            <ng-container *ngIf="!isLarge; else notMobileBlock" >
                                <span class="font-xs text-muted mt-2 more-circle"
                                    [pTooltip]="(classroomFile.categories | slice:1).join(', ')"
                                    tooltipPosition="top">
                                    +{{classroomFile.categories.length - 1}}
                                  </span>
                            </ng-container>
                            <ng-template #notMobileBlock>
                                <span *ngIf="classroomFile.categories.length > 2"  class="font-xs text-muted mt-2 more-circle"
                                    [pTooltip]="(classroomFile.categories | slice:2).join(', ')"
                                    tooltipPosition="top">
                                    +{{classroomFile.categories.length - 2}}
                                </span>
                            </ng-template>
                        </div>
            </div>
        </div>
    </div>
    <div class="library-actions-button mx-2" id="lib-file-burger-{{classroomFile.fileId}}" (click)="!showGroupActions ? menu3.toggle($event) : null">

        <div *ngIf="showGroupActions || this.libraryService.isPreviewingFromChat()" (click)="checkLibraryItem(checkLibMenu)"
            style="position: relative;">
            <img *ngIf="!checked" src="/assets/icons/library/group-item-empty.svg" class="pointer" style="width:1.75rem; height:1.75rem">
            <img *ngIf="checked" src="/assets/icons/library/group-item-full.svg" class="pointer" style="width:1.75rem; height:1.75rem">
            <!-- <div id="check-lib-{{libraryFile.filePath}}-1" #checkLibMenu class="inner-circle-group"></div> -->
        </div>
        <img *ngIf="!showGroupActions && !this.libraryService.isPreviewingFromChat()" src="/assets/icons/library/library-more-actions.svg" class="pointer hvr-grow" style="width:1.75rem; height:1.75rem">
        <!-- <i class="ml-auto pi pi-ellipsis-h lession-more-icon center text-primary"></i> -->
    </div>

</li>
<p-menu #menu3 popup="popup" [model]="menuItems" appendTo="body"></p-menu>
