import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User, UserAvailability, UserAvailabilityOff, UserAvailabilityType, UserStatus } from '../models/user.model';
import { ConfirmationService } from 'primeng/api';
const BACKEND_URL = environment.apiUrl + "/User/";
const BACKEND_URL_LMS = environment.apiUrl + "/LMS/";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private update$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly update: Observable<boolean> = this.update$.asObservable();

  private updateProfile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly updateProfile: Observable<boolean> = this.updateProfile$.asObservable();

  private uploadFiles$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly uploadFiles: Observable<boolean> = this.uploadFiles$.asObservable();

  private newFileUpload$: BehaviorSubject<{}> = new BehaviorSubject<{}>({});
  public readonly newFileUpload: Observable<{}> = this.newFileUpload$.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  public updateUserListener() {
    this.update$.next(true);
  }

  public updateUserProfile(user: User) {
    this.update$.next(true);
    return this.http.post<User>(BACKEND_URL_LMS + "UpdateUserProfile", user)
  }

  public setUpdateProfile(update: boolean) {
    this.updateProfile$.next(update);
  }

  public setUploadFiles(update: boolean) {
    this.uploadFiles$.next(update);
  }

  public setNewFileUpload(update: {}) {
    this.newFileUpload$.next(update);
  }

  public setUserPassword(user: {}) {
    return this.http.post<any>(BACKEND_URL_LMS + "SetPassword", user);
  }

  public getUserPhoto(user: User) {
    let photo = (user?.avatarUrl && user?.avatarUrl !== 'Not Given') ? environment.apiUrl + "/" + user?.avatarUrl : "/assets/images/default-user-avatar.png";
    return photo;
  }

  public getUserAvatar(user: User) {
    let photo = (user?.photo && user?.photo !== 'Not Given') ? environment.apiUrl + "/" + user?.photo : "/assets/images/avatar.svg";
    return photo;
  }

  public updateAvatarUrl(newAvatarUrl: string): void {
    // Step 1: Retrieve the existing value from localStorage
    const existingValue = localStorage.getItem('user');

    if (existingValue) {
      // Step 2: Modify the retrieved value
      const parsedValue = JSON.parse(existingValue);
      parsedValue.avatarUrl = newAvatarUrl;

      // Step 3: Store the updated value back into localStorage
      localStorage.setItem('user', JSON.stringify(parsedValue));
    }
  }

  public getUserAvailability(userId: string) {
    return this.http.get<UserAvailability>(BACKEND_URL_LMS + "GetUserAvailability?userId=" + userId)
      .pipe(
        map((res: any) => {
          Object.keys((res)).forEach((key) => {
            if (res[key] == null)
              res[key] = []
          })
          return res
        })
      )
  }

  public getUserDaysOff(userId: string) {
    return this.http.get<UserAvailabilityOff[]>(environment.apiUrl + "/Teacher/GetUserDaysOff?userId=" + userId)
      .pipe(
        map((res: any) => {
          for (let dayOff of res) {
            dayOff.period.from = new Date(new Date(dayOff.period.from).setHours(0, 0));
            dayOff.period.to = new Date(new Date(dayOff.period.to).setHours(0, 0));
          }
          return res
        })
      )
  }

  public getUserAbsences(userId: string) {
    console.log(userId)
    return this.http.get<number>(environment.apiUrl + "/Lesson/GetUserAbsences?userId=" + userId)
  }

  public getUserHasPayment() {
    return this.http.get<{}>(BACKEND_URL_LMS + 'StudentHasPayment');
  }

  public getUserById(userId: string): Observable<User> {
    return this.http.get<{}>(BACKEND_URL + 'GetUserById?userId=' + userId) as Observable<User>;
  }

  calculateAge(birthday: Date) { // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  getEmptyAvailability() {
    return {
      id: "availability_2",
      type: UserAvailabilityType.AVAILABLE,
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    }
  }

  /**
   * Checks if there are any unsaved changes and prompts the user with a confirmation message if there are.
   * @param {function} hasUnsavedChanges - A function that returns a boolean value indicating whether there are unsaved changes.
   * @param {object} confirmationService - An object that provides methods for displaying confirmation messages to the user.
   * @returns {Observable|boolean} - An Observable that emits a boolean value indicating whether the user accepts or rejects the confirmation message, or a boolean value of true if there are no unsaved changes.
   */
  comfirmDiscardUnsavedChanges(hasUnsavedChanges: () => boolean, confirmationService: ConfirmationService): Observable<boolean> | boolean {
    if (hasUnsavedChanges()) {
      return new Observable((observer: any) => {
        confirmationService.confirm({
          header: '',
          key: 'stepLeaveConfirmation',
          message: 'Unsaved changes will be discarded. <br> Do you still wish to navigate away from this page?.',
          accept: () => {
            observer.next(true);
            observer.complete();
          },
          reject: () => {
            observer.next(false);
            observer.complete();
          }
        });
      });
    } else {
      return (true);
    }
  }

  generateReferralCodeFromUser(user: any): string {
    const firstNamePart = user.firstName.substring(0, 3).toLowerCase().replace(/\s+/g, '');
    const lastNamePart = user.lastName.toLowerCase().replace(/\s+/g, '');
    return `${firstNamePart}.${lastNamePart}.ref`;
  }
  

}
